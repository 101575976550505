import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';

import { format } from 'date-fns';
import { Form, Formik, useFormikContext } from 'formik';
import {
  first as _first, toNumber as _toNumber,
} from 'lodash';
import NumberFormat from 'react-number-format';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { Checkbox, Divider, FormControlLabel, Grid, InputLabel } from '@material-ui/core';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import { useUI } from '../../../app/context/ui';
import { updateProperty } from '../../../app/store/mm/customerSlice';
import { WizardStyles } from '../../../assets/css';
import GoogleAutocomplete from '../../../components/shared/GoogleAutocomplete';
import { Box } from '../../../components/shared/MaterialUI';
import { AutoSave, InputField, SelectField } from '../../../forms';
import DatePickerField from '../../../forms/DatePickerField';
import { Utils } from '../../../helpers';
import AppHelper from '../../../helpers/AppHelper';
import { FormatWeekYear } from '../../../helpers';
import { weekDays } from '../../../models/db';
import ValidationFields from '../../../models/validationFields';
import { CustomerService, HelperService, MarketService,
  SrvService, StateService, LogicService,
} from '../../../services';
import MapsService from '../../../services/MapsService';

const marketService = new MarketService();
const srvService = new SrvService();
const stateService = new StateService();
const customerService = new CustomerService();
const helperService = new HelperService();
const mapsService = new MapsService();
const logicService = new LogicService();

const Step1 = (props) => {
  const [isValidZip, setIsValidZip] = useState(null);
  const [isWeekEdit, setIsWeekEdit] = useState(false);
  const [markets, setMarkets] = useState([]);
  const [options, setOptions] = useState({
    citys: [],
    states: [],
  });

  const {
    formField: {
      serviceAddress,
      zipcode,
      state,
      market,
      serviceDate,
      week,
      weekYear,
      weekDay,
      isServiceAddressManual,
    },
  } = props;

  const dispatch = useDispatch();
  const { blockUI, snackbarUI } = useUI();
  const formikInformation = useRef();
  const [formIsFocused, setFormIsFocused] = useState(false);
  const { values: formValues } = useFormikContext();
  const { id, costService } = formValues;
  const wizardStyle = WizardStyles();
  const initialValues = {
    serviceAddress: formValues?.serviceAddress,
    srvFrecuency: formValues?.srvFrecuency,
    zipcode: formValues?.zipcode,
    city: formValues?.city,
    cityName: formValues?.cityName,
    state: formValues?.state,
    market: formValues?.market,
    useCornerLot: formValues?.useCornerLot,
    taxRate: formValues?.taxRate,
    calculatedTax: formValues?.calculatedTax,
    totalMowing: formValues?.totalMowing,
    serviceDate: formValues?.serviceDate,
    week: formValues?.week,
    weekDay: formValues?.weekDay || 1,
    isServiceAddressManual: formValues?.isServiceAddressManual,
  };

  const validationSchema = Yup.object({
    serviceAddress: ValidationFields.address(),
    srvFrecuency: ValidationFields.srvFrecuency(),
    zipcode: Yup.string()
      .required(`${zipcode.requiredErrorMsg}`)
      .matches(/^[0-9]{5}$/, `${zipcode.invalidErrorMsg}`)
      .test('len', `${zipcode.invalidErrorMsg}`, (val) => val && val !== isValidZip),
    cityName: ValidationFields.city(),
    state: ValidationFields.state(),
    market: ValidationFields.market(),
  });

  const getSrvZipCode = async (zip) => {
    try {
      srvService.getAccessToken();
      const r1 = await srvService.zipCode(`zip-code/${zip}`);
      if (formikInformation.current) {
        const marketId = r1.data.serviceMarketsId;
        await getListMarket(marketId, true);
        await getZipCodeState(zip, true);
      }
    } catch (e) {
      setIsValidZip(zip);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const getZipCodeState = useCallback(
    async (zip, option = false) => {
      try {
        stateService.getAccessToken();
        const r1 = await stateService.zip(zip);
        const arrCity = Utils.updateArrayObjectItem(r1.data, [], 'id', 'city');
        const arrState = Utils.updateArrayObjectItem(r1.data, [], 'stateId', 'stateName');
        setOptions({ citys: arrCity, states: arrState });
        if (option) {
          const objCity = _first(arrCity);
          const objState = _first(arrState);
          if (formikInformation.current) {
            formikInformation.current?.setFieldValue('city', objCity.value);
            formikInformation.current?.setFieldValue('state', objState.value);
          }
        }
      } catch (e) {
        setIsValidZip(zip);
        AppHelper.checkError(e, snackbarUI);
      }
    },
    [snackbarUI]
  );

  const calculateWeekAndWeekDay = async (date) => {
    try {
      const formatDate = format(date, 'yyyy-MM-dd');
      helperService.getAccessToken();
      const info = await helperService.dateInfo(formatDate);
      formikInformation.current?.setFieldValue('week', info.data.week);
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const getListMarket = useCallback(
    async (marketId, option = false) => {
      try {
        marketService.getAccessToken();
        const r1 = await marketService.read(marketId);
        let arr = [];
        const obj = Utils.updateObjectKey(r1.data);
        arr.push(obj);
        setMarkets(arr);
        if (option) {
          const taxRate = _toNumber(obj.taxRate);
          if (formikInformation.current) {
            formikInformation.current?.setFieldValue('taxRate', taxRate);
            formikInformation.current?.setFieldValue('useCornerLot', taxRate > 0);
            formikInformation.current?.setFieldValue('market', obj.value);

            await setUpdatePrice(taxRate, costService);
          }
        }
      } catch (e) {
        AppHelper.checkError(e, snackbarUI);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [costService, snackbarUI]
  );

  const updateAddress = async (data) => {
    mapsService.getAccessToken();
    const r1 = await mapsService.getAddress(data);
    formikInformation.current?.setFieldValue('cityName', r1.data.city);
    formikInformation.current?.setFieldValue('zipcode', r1.data.zipCode);
    await getSrvZipCode(r1.data.zipCode);
    return r1.data;
  };

  const handleSubmit = useCallback(
    async (values) => {
      try {
        blockUI.current.open(true);
        customerService.getAccessToken();

        if (formikInformation.current.initialValues.serviceAddress !== values.serviceAddress) {
          await updateAddress(values.serviceAddress).then((res) => {
            customerService.update(
              { ...values, cityName: res.city, state: res.state, zipcode: res.zipCode },
              id
            );
          });
        } else {
          await customerService.update(values, id);
        }

        dispatch(updateProperty(values));

        blockUI.current.open(false);
      } catch (e) {
        blockUI.current.open(false);
        AppHelper.checkError(e, snackbarUI);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [blockUI, id, dispatch, snackbarUI]
  );

  const onKeyPress = (e) => {
    if (e.key === 'Enter' || e.target.value.length === 5) {
      // write your functionality here
      (async function init() {
        await getSrvZipCode(e.target.value);
      })();
    }
  };

  const setUpdatePrice = useCallback(
    async (taxRate, costService) => {
      if(formValues.costService && formValues.srvFrecuency){
        logicService.getAccessToken();
        let find = '';
        find += `costService=${Number(costService)}`;
        find += `&lotSize=${_toNumber(formValues.lotSize)}`;
        find += `&turfSize=${_toNumber(formValues.turfSize)}`;
        find += `&taxRate=${_toNumber(taxRate)}`;
        find += `&srvFrequency=${formValues.srvFrecuency}`;
        find += `&isBagging=${formValues.mowCut.includes('BagGrass')}`;
        find += `&businesslogicId=${_toNumber(formValues.billingLotSize)}`;

        const { data: dataBaggrass } = await logicService.proccessBaggrass(find);

        formikInformation.current?.setFieldValue('subtotalMowing', dataBaggrass.newSubtotal);
        formikInformation.current?.setFieldValue('totalMowing', dataBaggrass.total);
        formikInformation.current?.setFieldValue('calculatedTax', dataBaggrass.tax);
      }
    },
    [formValues]
  );

  const handleChangeServiceAddressManualEnabled = (e) => {
    if (formikInformation.current) {
      formikInformation.current.setFieldValue(
        "isServiceAddressManual",
        e.target.checked
      );
    }
  };

  useEffect(() => {
    (async function init() {
      formValues.zipcode && (await getZipCodeState(formValues.zipcode));
    })();
  }, [formValues.zipcode, getZipCodeState]);

  useEffect(() => {
    (async function init() {
      formValues?.market && (await getListMarket(formValues?.market));
    })();
  }, [formValues?.market, getListMarket]);

  useEffect(() => {
    setIsWeekEdit(false);
    formikInformation.current?.setFieldValue('week', formValues?.week);
    formikInformation.current?.setFieldValue('serviceDate', formValues?.serviceDate);
  }, [props, formValues?.week, formValues?.serviceDate]);

  return (
    <>
      <Formik
        innerRef={formikInformation}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        reinitialize={true}
      >
        {({values}) => (
          <Form autoComplete='off' onFocus={() => setFormIsFocused(true)} onBlur={() => setFormIsFocused(false)}>
            <Box my={2} />
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} className={wizardStyle.mt0}>
                <div className={wizardStyle.labelIcon}>
                  <InputLabel className={wizardStyle.label}>Street address: </InputLabel>
                  {formValues.serviceAddress === formValues.mailingAddress && (
                    <MailOutlineIcon className={wizardStyle.mt0} />
                  )}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.isServiceAddressManual}
                        onChange={handleChangeServiceAddressManualEnabled}
                        name={isServiceAddressManual.name}
                      />
                    }
                    label="Manual"
                    className={wizardStyle.controlLabel}
                  />
                </div>

                {
                  (values.isServiceAddressManual)
                    ?
                      <InputField
                        name="serviceAddress"
                        fullWidth
                      />
                    :
                      <GoogleAutocomplete
                        formikRef={formikInformation}
                        name={serviceAddress.name}
                      />
                }

              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel className={wizardStyle.label}>Zipcode:</InputLabel>
                <InputField
                  name={zipcode.name}
                  onKeyUp={onKeyPress}
                  inputProps={{
                    min: 0,
                    style: { textAlign: 'center' },
                    minLength: 1,
                    maxLength: 5,
                  }}
                  InputProps={{
                    inputComponent: NumberFormat,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel className={wizardStyle.label}>City:</InputLabel>
                <InputField name='cityName' fullWidth />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel className={wizardStyle.label}>State:</InputLabel>
                <SelectField name={state.name} data={options.states} autoFocus fullWidth />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel className={wizardStyle.label}>Market:</InputLabel>
                <SelectField name={market.name} data={markets} autoFocus fullWidth />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={12}>
                <span className={wizardStyle.title}>Start date:</span>
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel className={wizardStyle.label}>Date:</InputLabel>
                <DatePickerField
                  disableToolbar
                  name={serviceDate.name}
                  format='MM/dd/yyyy'
                  maxDate={new Date('2050/12/31')}
                  onAccept={(e) => calculateWeekAndWeekDay(e)}
                  fullWidth
                />
              </Grid>
              {
                !isWeekEdit ? (
                  <Grid item xs={12} sm={4} style={{ cursor: 'pointer' }} onClick={() => setIsWeekEdit(true)}>
                    <InputLabel style={{ cursor: 'pointer' }} className={wizardStyle.label}>Week:</InputLabel>
                    <InputField
                      name={week.name}
                      InputProps={{ inputComponent: NumberFormat }}
                      fullWidth
                      disabled
                      style={{ cursor: 'pointer' }}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={4}>
                    <InputLabel className={wizardStyle.label}>Week:</InputLabel>
                    <InputField
                      name={weekYear.name}
                      placeholder='Type here'
                      InputProps={{
                        inputComponent: FormatWeekYear,
                      }}
                      isNumericString
                      fullWidth
                    />
                  </Grid>
                )
              }
              <Grid item xs={12} sm={4}>
                <InputLabel className={wizardStyle.label}>Week day::</InputLabel>
                <SelectField name={weekDay.name} data={weekDays} autoFocus fullWidth />
              </Grid>
              <Grid item xs={12}>
                <AutoSave debounceMs={500} id={id} isFocused={formIsFocused} />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Step1;
