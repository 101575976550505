import { makeStyles } from '@material-ui/core/styles';

export const SignUpStyles = makeStyles(() => ({
  container: {
    padding: '56px 90px',
    margin: '0 auto',
  },
  formCtn: {
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '24px',
    '& .textLabels': {
      marginBottom: '18px',
      fontSize: '20px',
      fontWeight: 700,
      lineHeight: '32px',
      color: '#676767',
      fontFamily: 'Roboto Condensed',
      '& span': {
        color: 'red',
      },
    },
    '& .billingInputs': {
      marginBottom: '8px',
    },
    '& .checkTitle': {
      margin: '24px 0',
      color: '#676767',
      fontFamily: 'Open Sans',
    },
    '& .submitOrder': {
      marginTop: '15px',
      width: '35%',
      textTransform: 'uppercase',
      letterSpacing: '2px',
      fontFamily: 'Open Sans',
    },
    '& .MuiFormControl-root': {
      width: '80%',
    },
    '& .MuiFilledInput-root': {
      width: '90%',
      maxWidth: '620px',
      height: '40px',
      marginBottom: '24px',
      border: '1px solid rgba(0,0,0,0.1)',
      borderRadius: '4px',
      transition: 'all ease 0.5s',
      backgroundColor: '#f5f5f5',
      '&::before': {
        content: 'unset',
      },
      '&::after': {
        content: 'unset',
      },
      '& .MuiFilledInput-input': {
        padding: '0 10px',
      },
      '& input': {
        height: '100%',
        transition: 'all ease 0.5s',
        '&:focus-visible': {
          boxShadow: '0px 0px 10px rgb(0 0 0 / 18%)',
          outline: 'none',
        },
      },
      '&:focus-visible': {
        boxShadow: '0px 0px 10px rgb(0 0 0 / 18%)',
        outline: 'none',
      },
    },
    '& .phoneNumber': {
      width: '80%',
      maxWidth: '620px',
      height: '40px',
      marginBottom: '24px',
      border: '1px solid rgba(0,0,0,0.1)',
      borderRadius: '4px',
      transition: 'all ease 0.5s',
      backgroundColor: '#f5f5f5',
      fontSize: '16px',
      padding: '0 10px',
      '&:focus-visible': {
        boxShadow: '0px 0px 10px rgb(0 0 0 / 18%)',
        outline: 'none',
      },
    },
  },
  select: {
    // margin: '12px 0',
    marginBottom: '24px',
    width: '71%',
    backgroundColor: '#FFF',
    '&::before, &::after': {
      content: 'unset',
    },
    '& .MuiSelect-root': {
      boxShadow: '0 1px 3px rgb(0 0 0 / 40%)',
      padding: '10px',
    },
    '& .MuiSelect-select:focus': {
      background: 'white',
    },
  },
  text: {
    width: '65%',
    '& .MuiTypography-root': {
      fontFamily: 'Open Sans',
      fontSize: '1rem',
      lineHeight: '30px',
    },
  },
  textArea: {
    maxWidth: '71%',
    minHeight: '70px',
    marginBottom: '24px',
    padding: '10px',
    borderRadius: '4px',
    backgroundColor: '#f5f5f5',
    border: '1px solid rgba(0,0,0,0.1)',
    transition: 'all ease 0.5s',
    '&:focus-visible': {
      boxShadow: '0px 0px 10px rgb(0 0 0 / 18%)',
      outline: 'none',
    },
  },
  button: {
    width: '20%',
    height: '55px',
    marginTop: '32px',
    borderRadius: '50px',
    backgroundColor: '#00652d',
    color: '#FFF',
    fontSize: '1rem',
    padding: '10px 35px',
    letterSpacing: '1.5px',
    fontWeight: 600,
    '&:hover': {
      color: '#FFF',
      backgroundColor: '#277d4d',
    },
  },
  titles: {
    fontSize: '35px',
    fontFamily: 'Roboto Condensed',
    color: '#00652D',
    marginBottom: '24px',
    '&.uppercase': {
      textTransform: 'uppercase',
    },
    '&.billingTitle': {
      margin: '24px 0 0 0',
    },
  },
  trustwaves: {
    marginLeft: '16px',
    fontSize: '25px',
    fontFamily: 'Roboto Condensed',
    color: '#00652D',
  },
  checkboxLabel: {
    '& .MuiCheckbox-root': {
      '& .MuiSvgIcon-root': {
        width: '20px',
        fontSize: '1.5rem',
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    '& .Mui-checked': {
      '& .MuiSvgIcon-root': {
        color: '#005cc8',
      },
      '&:hover .MuiSvgIcon-root': {
        color: '#007cba',
      },
    },
    '& .Mui-disabled': {
      '& .MuiSvgIcon-root': {
        color: '#aaa !important',
      },
    },
    '& .MuiTypography-root': {
      fontFamily: 'Open Sans',
      color: '#101010',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '30px',
    },
  },
  tabs: {
    '& .MuiTabs-flexContainer': {
      transition: 'all 1s ease',
      '& .MuiTab-textColorInherit': {
        fontFamily: 'Open Sans',
        color: '#676767',
      },
      '& .MuiTab-textColorInherit.Mui-selected': {
        fontWeight: 700,
        color: '#00652d',
      },
    },
  },
  billingSelect: {
    margin: '12px 0',
    marginBottom: '24px',
    width: '48%',
    backgroundColor: '#FFF',
    '&::before, &::after': {
      content: 'unset',
    },
    '& .MuiSelect-root': {
      boxShadow: '0 1px 3px rgb(0 0 0 / 40%)',
      padding: '10px',
    },
    '& .MuiSelect-select:focus': {
      background: 'white',
    },
  },
}));
