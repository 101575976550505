import React, { useCallback, useEffect, useState } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { Grid } from '@material-ui/core';

import { Formik, Form, useFormikContext } from 'formik';
import { isEmpty as _isEmpty } from 'lodash';
import * as Yup from 'yup';

import { WizardStyles } from '../../../assets/css';
import { AutoSave } from '../../../forms';
import { CompleteServiceModal } from './components/CompleteServiceModal';
import { CustomerService } from '../../../services';
import { useUI } from '../../../app/context/ui';
import AppHelper from '../../../helpers/AppHelper';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

const columns = [
  {field: 'id', headerName: 'Service ID', flex: 0.2},
  {field: 'workday', headerName: 'Date', flex: 0.2},
  {field: 'crewNumber', headerName: 'Crew Number', flex: 0.2},
  {field: 'notes', headerName: 'Notes', flex: 0.5,  renderCell: (params) => (
    <div style={{whiteSpace: 'pre-wrap'}}>{params.value?.map((note) => note.description).join(', ')}</div>
  )}
];

const customerService = new CustomerService();

export default function Step9() {
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [filter, setFilter] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [openCompleteServiceModal, setOpenCompleteServiceModal] = useState(false);
  const {blockUI, snackbarUI} = useUI();

  const wizardStyle = WizardStyles();
  const formRef = React.useRef();
  const {values: formValues} = useFormikContext();
  const {id} = formValues;
  const pageSize = 10;
  const [idJob, setIdJob] = useState(null);

  const search = '&render=paginate' +
  '&limit=' + pageSize +
  '&sort=-id';

  const initialValues = {
    dateStart: null,
    dateEnd: null
  };

  const validationSchema = Yup.object({
    dateStart: Yup.date()
      .nullable()
      .typeError(`Date is not valid`),
    dateEnd: Yup.date()
      .nullable()
      .typeError(`Date is not valid`)
  });

  const getJobs = useCallback(async (newPage) => {
    try {
      const filterPage = (newPage > 0) ? '&page=' + (newPage + 1) : '';
      const filterDate = !_isEmpty(filter) ? filter : '';
      const find = search + filterPage + filterDate

      blockUI.current.open(true);
      customerService.getAccessToken();
      const r1 = await customerService.listJobs(id, find);
      setRows(r1.data.data);
      setRowCount(r1.data.total);
      setPage(newPage);
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  }, [search, filter, blockUI, snackbarUI, id]);

  useEffect(() => {
    getJobs(0);
  }
  , [filter, getJobs]);

  useEffect(() => {
    if (startDate && endDate) {
      setFilter('&filter[date_start]=' + startDate.toLocaleDateString('af-ZA', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }) + '&filter[date_end]=' + endDate.toLocaleDateString('af-ZA', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }));
    } else if (!_isEmpty(startDate) && _isEmpty(endDate)) {
      setFilter(null);
    } else {
      setRows([]);
    }
  }
  , [startDate, endDate]);

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid item xs={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            innerRef={formRef}
          >
            {() => (
              <Form autoComplete="off" id='History' >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={12} sm={1} >
                    <AutoSave debounceMs={500} id={id} />
                  </Grid>
                  <Grid item xs={12} sm={2} >
                    <Grid container>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          disableToolbar
                          inputVariant="outlined"
                          size="small"
                          format="MM/dd/yyyy"
                          keyboardIcon={<DateRangeIcon/>}
                          InputAdornmentProps={{position: 'start'}}
                          maxDate={new Date()}
                          value={startDate}
                          placeholder="Date from"
                          fullWidth
                          onChange={(date) => setStartDate(date)}
                          className={wizardStyle.datePicker}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={2} >
                    <Grid container>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          disableToolbar
                          inputVariant="outlined"
                          size="small"
                          format="MM/dd/yyyy"
                          keyboardIcon={<DateRangeIcon/>}
                          InputAdornmentProps={{position: 'start'}}
                          maxDate={new Date()}
                          value={endDate}
                          placeholder="Date to"
                          fullWidth
                          onChange={(date) => setEndDate(date)}
                          className={wizardStyle.datePicker}
                        />
                      </MuiPickersUtilsProvider>
                      </Grid>
                  </Grid>

                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
        <Grid item xs={12}>
          <div style={{width: '100%'}}>
            <DataGrid
              autoHeight
              pagination
              rows={rows}
              columns={columns}
              pageSize={pageSize}
              page={page}
              paginationMode="server"
              rowCount={rowCount}
              className={wizardStyle.dataGrid}
              onPageChange={(newPage) => getJobs(newPage)}
              onCellClick={(params) => {
                setIdJob(params.row.id);
                setOpenCompleteServiceModal(true);
              }}
            />
          </div>
        </Grid>
      </Grid>
      <CompleteServiceModal
        open={openCompleteServiceModal}
        onClose={() => setOpenCompleteServiceModal(false)}
        customerId={id}
        idJob={idJob}
        job={rows.find((row) => row.id === idJob)}
      />
    </>
  );
}
