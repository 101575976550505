import React, { useCallback, useEffect, useRef, useState } from 'react';

import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  isEmpty as _isEmpty,
  isUndefined as _isUndefined,
  split as _split,
  words as _words,
  toNumber as _toNumber,
  size as _size,
} from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import { useUI } from '../../app/context/ui';
import AppHelper from '../../helpers/AppHelper';
import { ExceptionService, MarketService } from '../../services';
import { connect } from 'react-redux';
import { AdminPanelStyle } from '../admin/styles/AdminPanelStyle';
import { Box, IconButton, Modal } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import { ReportStyles } from '../reports/components/report-style';
import { ListStyles } from '../../assets/css';
import ZipManager from './modals/ZipManager';
import { Button } from '@mui/material';

let dlgSettings = {
  confirm: true,
  btn: {
    close: 'Cancel',
    confirm: 'Yes',
  },
  onConfirm: () => {},
};

const marketService = new MarketService();
const exceptionService = new ExceptionService();

const ListZip = () => {
  const history = useHistory();
  const location = useLocation();
  const locationState = location.state || [];
  let dataProcess;
  const classes = AdminPanelStyle();
  const {blockUI, snackbarUI, dialogUI} = useUI();
  const [paginationURL, setPaginationURL] = useState('');
  const [markets, setMarkets] = useState([]);
  const [optionSelected, setOptionSelected] = useState('');
  const reportStyle = ReportStyles();
  const listStyle = ListStyles();
  const query = new URLSearchParams(useLocation().search);
  const [openModal, setOpenModal] = useState(false);
  const [typeModal, setTypeModal] = useState('');
  const [dataEdit, setDataEdit] = useState({});
  const previousSortRef = useRef(null);

  const [rowsState, setRowsState] = useState({
    pageSize: 10,
    rows: [],
    rowCount: 0,
    page: _toNumber(query.get('page')) || 0,
  });

  const link = _isUndefined(locationState)
    ? ''
    : _isEmpty(locationState.condition)
    ?
    _isEmpty(locationState.state)
      ? ''
      : '&' + locationState.state
    : '&' + locationState.condition

  const getModalStyle = () => {
    return {
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%, -50%)`,
    };
  };

  const search = `?render=paginate&limit=${rowsState.pageSize}&page=
  ${rowsState.page + 1}`;

  const [modalStyle] = useState(getModalStyle);

  const handleOpenModal = (type, dataEdit={}) => {
    setOpenModal(true);
    setTypeModal(type);
    setDataEdit(dataEdit);
  };

  const handleChangeSelect = (e) => {
    insertUrlParam('page', 0);
    setRowsState({
      ...rowsState,
      page: 0,
    })
    setOptionSelected(e.target.value);
  };

  const handleSetterIdDelete = (row) => {
    dlgSettings = {
      ...dlgSettings,
      onConfirm: () => {
        onDelete(row);
      },
    };
    dialogUI.current.open(
      'Are you sure you want to delete this Service Zone?',
      'This action cannot be undone',
      dlgSettings
    );
  };

  const onDelete = async (row) => {
    try {
      blockUI.current.open(true);
      exceptionService.getAccessToken();
      const { status } = await exceptionService.delete(row.id);
      if (status === 200) {
        await getListExceptions();
        const confirmSettings = {
          confirm: false,
          btn: {
            close: 'Close',
          },
        };
        dialogUI.current.close();
        dialogUI.current.open('', '', confirmSettings, 'Service Zone Successfully deleted');
      }
      blockUI.current.open(false);
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const renderColumns = [
    {
      field: 'zip',
      headerName: 'Zipcode',
      flex: 0.04,
      disableColumnMenu: true,
    },
    {
      field: 'exceptions',
      headerName: 'Exceptions',
      flex: 0.1,
      minWidth: 300,
      disableColumnMenu: true,
    },
    {
      field: 'user',
      headerName: 'User',
      width: 150,
      headerAlign: 'center',
      align: 'center',
      disableColumnMenu: true,
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      width: 150,
      headerAlign: 'center',
      align: 'center',
      disableColumnMenu: true,
    },
    {
      field: 'updatedAt',
      headerName: 'Edited',
      width: 150,
      headerAlign: 'center',
      align: 'center',
      disableColumnMenu: true,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 290,
      headerAlign: 'center',
      align: 'center',
      disableColumnMenu: true,
      renderCell: (params) => (
        <>
          <IconButton
            className={reportStyle.wrapperButtonDelete}
            size="small"
            onClick={
              ()=>{
                handleOpenModal('Edit', params.row);
              }
            }
          >
            <EditIcon/>
          </IconButton>
          <IconButton
            className={reportStyle.wrapperButtonDelete}
            size="small"
            onClick={
              ()=>{
                handleSetterIdDelete(params.row)
              }
            }
          >
            <DeleteIcon/>
          </IconButton>
        </>
      ),
    },
  ];

  const getListMarket = useCallback(async () => {
    try {
      blockUI.current.open(true);
      marketService.getAccessToken();
      const r1 = await marketService.list();
      setMarkets(r1.data);
      setOptionSelected(r1.data[0].id);
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  }, [blockUI, snackbarUI]);

  const handleOnPageChange = async (page) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const sort = urlSearchParams.get("sort");
    const newSort = !_isEmpty(sort) ? `&sort=${sort}` : '';
    const query = _words(paginationURL, /[^&]+/g)
      .map( (n)=> {
        return n.substring(0,4) === 'sort' &&  !_isEmpty(sort) ? `sort=${sort}` : n
      })
      .slice(0, -1).join('&');
    const find = query.indexOf('sort=') === -1 ? `${query}${newSort}` : query;
    try {
      blockUI.current.open(true);
      exceptionService.getAccessToken();
      const response = await exceptionService.paginationExceptions(optionSelected, `${find}`, page + 1);
      let dataProcess = response.data;
      setRowsState((prev) => ({
        ...prev,
        rows: dataProcess.data,
        rowCount: dataProcess.total,
        page: dataProcess.currentPage - 1,
      }));
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const getListExceptions = useCallback(async (pageSize) => {
    try {
      blockUI.current.open(true);
      exceptionService.getAccessToken();
      const query = _words(search, /[^&]+/g)
        .map( (n)=> {
          return n.substring(0,5) === 'limit' &&  pageSize ? `limit=${pageSize}` : n
        })
        .slice(0, -1).join('&');
      const r1 = link
        ? await exceptionService.listExceptions(`${query}`)
        : await exceptionService.getExceptions({
            marketId: optionSelected,
            page: rowsState.page + 1,
            limit: pageSize || rowsState.pageSize
          })
      dataProcess = r1.data;
      setPaginationURL(_split(dataProcess.firstPageUrl, '?')[1]);
      setRowsState((prev) => ({
        pageSize: dataProcess.perPage || prev.pageSize,
        rows: dataProcess.data,
        rowCount: dataProcess.total,
        page: dataProcess.currentPage - 1,
      }));
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  }, [optionSelected, blockUI, snackbarUI]);

  const handlePageSizeChange = async (pageSize) => {
    await getListExceptions(pageSize);
  };

  const insertUrlParam = (key, value) => {
    let searchParams = new URLSearchParams(window.location.search);
    searchParams.set(key, value);
    history.push({ ...location, search: searchParams.toString() });
  };

  const handleSortModelChange = (field, sort = 'asc') => {
    let newOrder = `${field},id`;
    let newSort = sort === 'asc' ? newOrder : `-${newOrder}`
    if (previousSortRef.current === newSort) {
      return;
    }
    previousSortRef.current = newSort;
    const camelToSnakeCase = field.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
    let order = camelToSnakeCase;
    order = order + ',id';
    insertUrlParam('sort', sort === 'asc' ? order : `-${order}`);
    handleOnPageChange(rowsState.page);
  };

  const bodyModal = (
    <div style={modalStyle} className={classes.paperModal}>
      <br />
      <Grid container spacing={1}>
        <ZipManager
          typeModal={typeModal}
          setOpenModal={setOpenModal}
          dataEdit={dataEdit}
          marketId={optionSelected}
          getListExceptions={getListExceptions}
        />
      </Grid>
    </div>
  );

  useEffect(() => {
    (async function init() {
      await getListMarket();
    })();
  }, [getListMarket]);

  useEffect(() => {
    (async function init() {
      if(optionSelected){
        await getListExceptions();
      }
    })();
  }, [optionSelected]);

  return (
    <Container maxWidth='lg' disableGutters>
      <Grid container>
        <Grid item xs={12} sm={11}>
          <h2 className={classes.h2}>Service Zones</h2>
        </Grid>
      </Grid>
      <Container className={classes.container}>
        <p>Information</p>
        <Divider/>

        <Grid container>
          <Grid item md={6} xs={12}>
            <div className='market'>Market:</div>
              <div>
                <FormControl variant='outlined' className={classes.formControl}>
                  <InputLabel id='marketSelect' className={classes.inputLabel}>
                    Select an option
                  </InputLabel>
                  <Select
                    labelId='marketSelect'
                    id='demo-simple-select-outlined'
                    value={optionSelected}
                    onChange={handleChangeSelect}
                    label='Select an option'
                  >
                    {markets.map((e) => (
                      <MenuItem key={`market${e.id}`} value={e.id}>
                        {e.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
          </Grid>
          <Grid item md={6} xs={12} className={classes.wrapperAddZip}>
            <div>
              <Button
                className={classes.btnAddZip}
                variant='contained'
                onClick={() => {
                  handleOpenModal('Add');
                }}
                startIcon={<AddCircleIcon />}
              >
                ZIP
              </Button>
            </div>
          </Grid>
        </Grid>
        <Box mt={4}>
          <DataGrid
            autoHeight
            headerHeight={56}
            rowHeight={56}
            columns={renderColumns}
            {...rowsState}
            rowsPerPageOptions={[10, 15, 30]}
            paginationMode='server'
            sortingMode='server'
            className={listStyle.dataGrid}
            onPageChange={(page) => {
              insertUrlParam('page', page)
              handleOnPageChange(page)
            }}
            onPageSizeChange={(pageSize) => {
              handlePageSizeChange(pageSize)
            }}
            onSortModelChange={(field) => {
              if (_size(field) > 0) {
                handleSortModelChange(field[0].field, field[0].sort);
              }
            }}
            hideFooterSelectedRowCount={true}
            />
        </Box>
      </Container>
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
      >
        { bodyModal }
      </Modal>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(ListZip);
