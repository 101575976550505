import {useCallback, useEffect, useMemo, useState} from "react";
import {
  Box,
  Button,
  TextField,
  FormControlLabel,
  Switch,
  Typography
} from "@material-ui/core";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useUI } from "../../../app/context/ui";
import store from "../../../redux/store";
import AppHelper from "../../../helpers/AppHelper";
import { TaskService } from "../../../services";

export const Update = ({getData}) => {

  const [initialValues] = useState({
    price: "",
    increasePrice: true,
  });

  const [disabled , setDisabled] = useState(false);
  const { blockUI, snackbarUI } = useUI();
  const state = store.getState();
  const accessToken = state.user.accessToken;
  const id = state.user.id;

  if (!accessToken) {
    history.push("/login");
  }

  const taskService = useMemo(() => new TaskService(), []);


  const onSubmit = async (values) => {
    try {
      blockUI.current.open(true);
      taskService.init(accessToken);
      const indicator = values.increasePrice ? "increase" : "decrease";
      await taskService.updateBulk(values.price, indicator, id);
      setTimeout(() => {
        getData();
        getList();
      }, 1000);
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const getList = useCallback(async () => {
    try {
      blockUI.current.open(true);
      taskService.getAccessToken();
      const {data: count} =
        await taskService.list('?render=count&filter[status]=in_progress&filter[type]=update-bulk-prices');
      setDisabled(count > 0)
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  },[blockUI, snackbarUI, taskService]);

  const validationSchema = Yup.object({
    price: Yup.string("Enter a valid price")
      .required("Price is required")
      .test(
        "price",
        "Price must be a number",
        (value) => {
          return !isNaN(value);
        }
        )
        .test(
          "price",
          "Price must be greater than 0",
          (value) => {
            return value > 0;
          }
        )
  });

  useEffect(() => {
    (async function init() {
      await getList();
    })();
  }, [getList]);

  return (
	<Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      paddingX="48px"
      style={{border: '1px solid grey', borderRadius: '5px'}}
      minHeight="300px"
    >
      <Typography
        variant='h6'
        style={{fontWeight: 'bold', marginTop: '12px'}}
      >
        Update
      </Typography>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {onSubmit(values)}}
        enableReinitialize={true}
        validationSchema={validationSchema}
      >
        {(props) => {
		const { values, touched, errors, handleChange, handleSubmit } = props;
		return (
          <Form autoComplete="off" style={{width: '100%'}}>
            <Box display="flex" marginY="12px" justifyContent="space-between">
              <FormControlLabel
                control={
                  <Switch
                    checked={values.increasePrice}
                    onChange={handleChange}
                    name="increasePrice"
                    disabled={false}
                  />
                }
                label="Increase the price to all users"
                labelPlacement="start"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={!values.increasePrice}
                    onChange={
                      () => props.setFieldValue('increasePrice', !values.increasePrice)
                    }
                    name="decreasePrice"
                    disabled={false}
                  />
                }
                label="Lower the price to all users"
                labelPlacement="start"
              />
            </Box>
            <Box display="flex" flexDirection="column" width="100%">
              <span style={{fontWeight: 'bold'}}>Amount</span>
              <TextField
                variant="outlined"
                autoFocus
                disabled={false}
                size="small"
                id="price"
                name="price"
                value={values.price}
                onChange={handleChange}
                error={touched.price && Boolean(errors.price)}
                helperText={
                  errors.price
                  && touched.price
                  ? errors.price
                  : ""
                }
                style={{margin: '16px 0px 16px 0px'}}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              width="100%"
              justifyContent="center"
              style={{
                gap: '24px',
                padding: '12px 64px 12px 64px'
              }}
            >
              <Button
                disabled={false}
                variant='contained'
                color='default'
                style={{width: '320px'}}
              >
                Cancel
              </Button>
              {!disabled ?
                <Button
                  disabled={disabled}
                  variant='contained'
                  color='primary'
                  style={{width: '320px'}}
                  onClick={handleSubmit}
                >
                  Apply
                </Button>
                : (
                    <Button
                      disabled={disabled}
                      variant='contained'
                      color='primary'
                      style={{width: '320px'}}
                    >
                      Apply
                    </Button>
                )
              }
            </Box>
          </Form>
		);}}
      </Formik>
	</Box>
  )
}
