import React, { useState, useCallback } from 'react';
import { Grid, Modal, IconButton, InputLabel } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { Form, Formik } from 'formik';
import clsx from 'clsx';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import {
  find as _find,
  includes as _includes,
  isEmpty as _isEmpty,
  toNumber as _toNumber,
  isUndefined as _isUndefined,
} from 'lodash';

import { WizardStyles } from '../../../assets/css';
import { AppStyle } from '../../../assets/css/app/AppStyle';
import { SelectField } from '../../../forms';
import lookupDb from '../../../models/db/lookup-db';
import Header from './Header';
import { useUI } from '../../../app/context/ui';
import AppHelper from '../../../helpers/AppHelper';
import { CustomerService } from '../../../services';
import { lookupBasicDb } from '../../../models/db';
import Lookup1 from './Lookup1';
import Lookup2 from './Lookup2';
import Lookup9 from './Lookup9';
import Lookup10 from './Lookup10';
import Lookup11 from './Lookup11';
import Lookup12 from './Lookup12';
import Lookup13 from './Lookup13';
import Lookup14 from './Lookup14';
import Lookup15 from './Lookup15';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const customerService = new CustomerService();

const CustomerLookup = (props) => {
  const {open, setOpen, type} = props;
  const [modalStyle] = useState(getModalStyle);
  const wizardStyle = WizardStyles();
  const appStyle = AppStyle();
  const {dialogUI, blockUI, snackbarUI} = useUI();

  const history = useHistory();

  const initialValues = {
    search: '',
    searchBasic: '',
    parentBasic: '',
    fields: [],
    startDate: '',
    endDate: '',
    about: ''
  };

  const validationSchema = Yup.object({
    search: Yup.string().nullable().required('Field is required'),
    searchBasic: Yup.string()
      .nullable()
      .when('search', (search) => {
        if (_includes(['1', '2', '9'], search)) {
          return Yup.string().nullable().required('Field is required')
        }
      }),
    parentBasic: Yup.string()
      .nullable()
      .when(['search', 'searchBasic'], (search, searchBasic) => {
        if ( Number(searchBasic) !== 19){
          if (_includes(['1'], search) && _includes(['1', '2'], searchBasic)) {
            return Yup.string()
              .min(3, 'Min. 3 characters')
              .nullable().required('Field is required')
          } else if (_includes(['1'], search)) {
            return Yup.string().nullable().required('Field is required')
          }
        }
      }),
    fields: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().nullable().required('Field is required')
          .when('tag', (tag) => {
            if (tag === 'calendar_select') {
              return Yup.date()
                .nullable()
                .typeError('Date is not valid')
            }
          })
      })
    ),
    about: Yup.string()
      .nullable()
      .when(['searchBasic'], (searchBasic) => {
        if ( Number(searchBasic) === 19){
          return Yup.string().nullable().required('Field is required')
        }
      }),
    startDate: Yup.string()
      .nullable()
      .when(['searchBasic'], (searchBasic) => {
        if ( Number(searchBasic) === 19){
          return Yup.string().nullable().required('Field is required')
        }
      }),
    endDate: Yup.string()
      .nullable()
      .when(['searchBasic'], (searchBasic) => {
        if ( Number(searchBasic) === 19){
          return Yup.string().nullable().required('Field is required')
        }
      }),
  });

  const onSend = useCallback(async (values) => {
    try {
      let find = `filter[${values.field}]=${values.parentBasic}`;
      let search = '';
      let label = '';
      blockUI.current.open(true);
      let findFinal = [];


      if (values.search === 12 || values.search === 13 )  {

        for (let key in values) {
          if (key !== 'search' &&
            key !== 'searchBasic' &&
            key !== 'parentBasic' &&
            key !== 'fields' &&
            key !== 'field' &&
            values[key] !== '') {
            if (key === 'week') {
              let mowWeek = values[key].split('/');
              mowWeek.map((val, index) => {
                index === 0
                  ? findFinal.push(`filter[work_weeks.weeks]=${_toNumber(val)}`)
                  : findFinal.push(`filter[work_weeks.year]=${val}`);
              });
            } else if (key === 'week_day') {
              findFinal.push(`filter[work_weeks.week_day]=${values[key]}`);
            } else {
              findFinal.push(`filter[${key}]=${values[key]}`);
            }
          }

        }
        if (values.search === 13 || values.search === 12 ) {
          findFinal.push(`filter[status_orders]=1&filter[status]=1`);
        }
        if (values.search === 13) {
          findFinal.push(`filter[lookup]=reconcilation_report`);
        }
        if (findFinal.length > 0) {
          find = findFinal.join('&');
        }else{
          throw new Error('Select a parameter');
        }
      } else if (values.search === 14) {
        for (let key in values) {
          if (key !== 'search' &&
            key !== 'searchBasic' &&
            key !== 'parentBasic' &&
            key !== 'fields' &&
            key !== 'field' &&
            values[key] !== '') {
            if (key === 'bb') {
              findFinal.push(`filter[bb.work_weeks]=${values[key]['work_weeks']}`);
              if (values[key]['week_day'])
                findFinal.push(`filter[bb.week_day]=${values[key]['week_day']}`);
              if (values[key]['crew'])
              findFinal.push(`filter[bb.crew]=${values[key]['crew']}`);
              if (values[key]['market'])
                findFinal.push(`filter[market]=${values[key]['market']}`);
            }
          }
        }
        if (findFinal.length > 0) {
          find = findFinal.join('&');
        } else {
          throw new Error('Select a parameter');
        }
      } else if (values.search === 15) {
        find = "filter[status]=1&filter[status_orders]=1&filter[has_phone]=1&filter[isCellphone]=1&include=userEdit,userNote";
      }
      else if (values.search === 1 && values.searchBasic === 19) {
        find = `filter[about]=${values.about}`;
        find += `&filter[start_date]=${values.startDate}`;
        find += `&filter[end_date]=${values.endDate}`;
        if (values.aboutOption) {
          find += `&filter[about_option]=${values.aboutOption}`;
        }
      }
      else {
        values.fields.map(function (values, index) {
          if(!_isUndefined(values.name)){
            const filter = `filter[${values.field}]=${values.name}`;
            search += (index > 0) ? '&' + filter : filter;
            label += (index > 0) ? ', ' + values.label : values.label;
          }
        });
        if (values.search === 9) {
          const filter = _find(values.fields, {'field': 'status'});
          if (_isUndefined(filter)) {
            search +=`&filter[status_orders]=1&filter[status]=1`;
          }
        }

        if (!_isEmpty(search)) {
          find = search;
        } else {
          const message = _find(lookupBasicDb, {'value': values.searchBasic});
          label = message?.label || '';
        }
      }

      search = find + `&render=count&sort=-id`;
      customerService.getAccessToken();
      const r1 = await customerService
        .list(`?` + search);
      blockUI.current.open(false);

      // redirect
      if (_toNumber(r1.data) > 0) {
        setOpen(false);
        if (values.search === 13) {
          history.push('/reconciliation-report', {state: `&` + find});
        }
        else if(values.search === 12) {
          history.push('/operations-report', {state: `&` + find});
        }
        else if(values.search === 14){
          history.push('/bush-bed-report', {state: `&` + find});
        }
        else if(values.search === 1 && values.searchBasic === 19){
          history.push('/customer', {state: `&` + find , message: label, lookup: true, type: 'lookup-source'});
        }
        else {
          history.push('/customer', {state: `&` + find, message: label, lookup: true});
        }
      } else {
        dialogUI.current.open('oops', 'No customer profiles match your search parameters');
      }
    } catch (e) {
      setOpen(false);
      dialogUI.current.open('oops', 'Has had an error, try again later.');
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  }, [blockUI, dialogUI, setOpen, snackbarUI, history]);

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
    >
      <div style={modalStyle} className={wizardStyle.paperModal}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => onSend(values)}
        >
          {(props) => {
            const {values} = props;
            return (
              <Form autoComplete="off">
                <Grid container alignItems="center" spacing={0}>
                  <Grid container item xs={12}>
                    <Grid item xs={10}>
                      <Header type={type}/>
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton
                        color="primary"
                        aria-label="close"
                        className={clsx(appStyle.pdg0, appStyle.btnRight)}
                        onClick={() => setOpen(false)}
                      >
                        <CloseIcon/>
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel id="demo-multiple-checkbox-label" className={wizardStyle.label}>
                      Select your search method
                    </InputLabel>
                    <SelectField
                      name="search"
                      data={lookupDb}
                      autoFocus
                      fullWidth
                      placeholder={'-- Select a Lookup --'}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {(() => {
                      switch (values.search) {
                        case 1:
                          return <Lookup1 />;
                        case 2:
                          return <Lookup2 />;
                        case 9:
                          return <Lookup9 />;
                        case 10:
                          return <Lookup10 />;
                        case 11:
                          return <Lookup11 />;
                        case 12:
                          return <Lookup12 />;
                        case 13:
                          return <Lookup13 />;
                        case 14:
                          return <Lookup14 />;
                        case 15:
                          return <Lookup15 />;
                        default:
                          return null;
                      }
                    })()}
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  )
}

export default CustomerLookup;
