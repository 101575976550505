import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { DataGrid } from '@material-ui/data-grid';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { Grid } from '@material-ui/core';

import { Formik, Form, useFormikContext } from 'formik';
import { isEmpty as _isEmpty } from 'lodash';
import * as Yup from 'yup';

import { WizardStyles } from '../../../assets/css';
import { useUI } from '../../../app/context/ui';
import {LogService} from '../../../services';
import AppHelper from '../../../helpers/AppHelper';
import DatePickerField from '../../../forms/DatePickerField';
import moment from 'moment';

const columns = [
  {field: 'joinDate', headerName: 'Service day', flex: 0.2},
  {field: 'userFullName', headerName: 'MM User Name', flex: 0.25},
  {field: 'description', headerName: 'Action performed', flex: 0.55}
];

export default function Step3() {
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [filter, setFilter] = useState(null);
  const {blockUI, snackbarUI} = useUI();

  const wizardStyle = WizardStyles();
  const {values: formValues} = useFormikContext();
  const {id} = formValues;
  const pageSize = 10;

  const formikRef = useRef(null);
  const logService = useMemo(() => new LogService(), []);

  const search = '?render=paginate&nested=false&include=user' +
    '&filter[model]=customer' +
    '&filter[model_id]=' + id +
    '&limit=' + pageSize +
    '&sort=-id';

  const initialValues = {
    dateStart: null,
    dateEnd: null
  };

  const validationSchema = Yup.object({
    dateStart: Yup.date()
      .nullable()
      .typeError(`Date is not valid`),
    dateEnd: Yup.date()
      .nullable()
      .typeError(`Date is not valid`)
  });


  const convertTime = (date) => {
    return moment.utc(date, 'MM/DD/YYYY hh:mm A').local().format('MM/DD/YYYY hh:mm A');
  }

  const getListLogs = useCallback(async (newPage) => {
    try {
      const filterPage = (newPage > 0) ? '&page=' + (newPage + 1) : '';
      const filterDate = !_isEmpty(filter) ? filter : '';
      const find = search + filterPage + filterDate

      blockUI.current.open(true);
      logService.getAccessToken();
      const r1 = await logService.list(find);
      let rowData = r1.data.data;
      if (rowData) {
        rowData = rowData.map((row)=>{
          let joinDate = convertTime(row.joinDate);
          return {
            ...row,
            joinDate
          }
        });
      }
      setRows(rowData);
      setRowCount(r1.data.total);
      setPage(newPage);
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  }, [search, filter, logService, blockUI, snackbarUI]);

  const handleSubmit = useCallback((values) => {
    try {
      if (!_isEmpty(values.dateStart) && !_isEmpty(values.dateEnd)) {
        let dateStart = values.dateStart + '00:00:00';
        let dateEnd = values.dateEnd + '23:59:00';
        dateStart = moment(dateStart, 'MM/DD/YYYY HH:mm:ss').utc().format('MM/DD/YYYY HH:mm:ss');
        dateEnd = moment(dateEnd, 'MM/DD/YYYY HH:mm:ss').utc().format('MM/DD/YYYY HH:mm:ss');
        setFilter('&filter[date_history_start]=' + dateStart + '&filter[date_history_end]=' + dateEnd);
      } else if (_isEmpty(values.dateStart) && _isEmpty(values.dateEnd)) {
        setFilter(null);
      }
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  }, [snackbarUI, setFilter]);

  const handleActiveSubmit = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      formikRef.current.validateForm().then((errors) => {
        if (Object.keys(errors).length === 0) {
          handleSubmit(formikRef.current.values);
        }
      });
    }
  };

  useEffect(() => {
    (async function init() {
      await getListLogs(0);
    })();
  }, [filter]);

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid item xs={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            innerRef={formikRef}
          >
            {() => (
              <Form autoComplete="off">
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={12} sm={2} >
                    <DatePickerField
                      disableToolbar
                      inputVariant="outlined"
                      size="small"
                      name={'dateStart'}
                      format="MM/dd/yyyy"
                      maxDate={new Date()}
                      keyboardIcon={<DateRangeIcon/>}
                      InputAdornmentProps={{position: 'start'}}
                      className={wizardStyle.datePicker}
                      placeholder="Date from"
                      onKeyDown={handleActiveSubmit}
                      form={formikRef}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={2} >
                    <DatePickerField
                      disableToolbar
                      inputVariant="outlined"
                      size="small"
                      name={'dateEnd'}
                      format="MM/dd/yyyy"
                      maxDate={new Date()}
                      keyboardIcon={<DateRangeIcon/>}
                      InputAdornmentProps={{position: 'start'}}
                      className={wizardStyle.datePicker}
                      placeholder="Date to"
                      onKeyDown={handleActiveSubmit}
                      form={formikRef}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
        <Grid item xs={12}>
          <div style={{width: '100%'}}>
            <DataGrid
              autoHeight
              pagination
              rows={rows}
              columns={columns}
              pageSize={pageSize}
              page={page}
              paginationMode="server"
              onPageChange={(newPage) => {
                (async function init() {
                  await getListLogs(newPage);
                })();
              }}
              rowCount={rowCount}
              className={wizardStyle.dataGrid}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
}
