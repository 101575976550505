import { makeStyles } from '@material-ui/core/styles';

export const WizardStyles = makeStyles((theme) => ({
  ctnInner: {
    fontFamily: [
      'Roboto',
      'Arial'
    ].join(','),
    color: '#2F3A4A',
    '& label': {
      fontSize: 16
    }
  },
  chkCustom: {
    margin: '20px 0 0 40px',
    '& .MuiFormControlLabel-label': {
      fontSize: 16
    }
  },
  inputCustom: {
    minWidth: 200,
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '16px',
  },
  label: {
    fontStyle: 'normal !important',
    fontWeight: '400 !important',
    fontSize: '14px !important',
    lineHeight: '16px !important',
    paddingBottom: '10px !important',
  },
  labelStreet: {
    fontStyle: 'normal !important',
    fontWeight: '100 !important',
    fontSize: '14px !important',
    lineHeight: '16px !important',
    paddingBottom: '10px !important',
    color: 'grey !important'
  },
  labelW: {
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
    paddingBottom: '10px',
    fontWeight: 'bold',
  },
  alert: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
    color: theme.palette.secondary.main,
    '& .MuiAlert-icon': {
      color: theme.palette.secondary.main,
    }
  },
  formControl: {
    marginTop: theme.spacing(1),
    minWidth: 320,
    maxWidth: '100%',
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: '#E8E8E8',
    '& .MuiSelect-icon': {
      color: '#E8E8E8',
    },
    '& .MuiInputBase-input': {
      color: '#E8E8E8',
    },
  },
  completed: {
    backgroundColor: theme.palette.primary.main,
    color: '#E8E8E8',
    '& .MuiSelect-icon': {
      color: '#E8E8E8',
    },
    '& .MuiInputBase-input': {
      color: '#E8E8E8',
    },
  },
  pending: {
    backgroundColor: '#E8E8E8',
    color: 'white',
    '& .MuiSelect-icon': {
      color: 'white',
    },
    '& .MuiInputBase-input': {
      color: 'white',
    },
  },
  datePicker: {
    backgroundColor: theme.palette.primary.main,
    color: '#E8E8E8',
    '& .MuiIconButton-label': {
      color: '#E8E8E8',
    },
    '& .MuiInputBase-input': {
      color: '#E8E8E8'
    }
  },
  checkboxField: {
    color: theme.palette.secondary.main,
    '&$checked': {
      color: theme.palette.secondary.main,
    },
    '& .MuiFormControlLabel-label': {
      color: theme.palette.primary.main,
    }
  },
  grdAnswer: {
    marginTop: 28,
  },
  inputCardNumber: {
    width: 65,
    marginRight: 10
  },
  selectMonth: {
    width: 130,
    marginRight: 10
  },
  inputCardName: {
    width: 200,
    marginRight: 10
  },
  stepper: {
    padding: theme.spacing(3, 0, 5)
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  },
  buttonTransform: {
    margin: theme.spacing(1),
    textTransform: 'none'
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%'
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  rootModal: {
    height: 300,
    flexGrow: 1,
    minWidth: 300,
    transform: 'translateZ(0)',
    // The position fixed scoping doesn't work in IE 11.
    // Disable this demo to preserve the others.
    '@media all and (-ms-high-contrast: none)': {
      display: 'none',
    },
  },
  paperModal: {
    position: 'absolute',
    width: 900,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(6),
    outline: 'none'
  },
  paperModalFull: {
    position: 'absolute',
    width: 800,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(6),
    outline: 'none'
  },
  paperModalSmall: {
    position: 'absolute',
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(6),
    outline: 'none'
  },
  dataGrid: {
    border: 0,
    WebkitFontSmoothing: 'auto',
    letterSpacing: 'normal',
    '& .MuiDataGrid-iconSeparator': {
      display: 'none',
    },
  },
  labelIcon: {
    display: 'inline-flex', alignItems: 'center'
  },
  mt0: {
    marginTop: -7
  },
  mt7: {
    marginTop: 7
  },
  inputSelectCustom: {
    height: '41px'
  },
  todoListWrapper:{
    '& .description':{
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      paddingRight: '42px',
      color: 'gray',
      lineHeight: '40px'
    },
    '& .weekDay, .reminder':{
      textAlign: 'center',
      color: 'gray',
      lineHeight: '40px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    },
    '& .btnEdit':{
      padding: '0px',
      marginLeft: '24px'
    },
    '& .btnEditActive':{
      color: 'red',
      backgroundColor: '#e8ede9',
      padding: '0px',
      marginLeft: '24px'
    },
    '& .btnDelete':{
      padding: '0px'
    },
    '& .btnDeleteActive':{
      color: 'red',
      backgroundColor: '#e8ede9',
      padding: '0px'
    },
    '& .wrapperBtn':{
      lineHeight: '37px'
    }
  },
  wrapperTodoList:{
    height: '155px',
    overflow: 'scroll',
    marginBottom: '22px'
  },
  notifyPrimary: {
    fontSize: '12px',
    fontWeight: '700'
  },
  infoReminder:{
    fontSize: '12px',
    fontWeight: '700'
  },
  descriptionNotify:{
    textAlign: 'justify',
    fontSize: '12px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    marginTop: '3px',
    display: 'flex'
  },
  descriptionNotifyRead:{
    overflow: 'hidden',
    fontSize: '12px',
    marginTop: '3px',
    textAlign: 'justify'
  },
  btnNotify:{
    color: '#11aa39',
    fontSize: '12px'
  },
  wrapperBtnNotify:{
    textAlign: 'center'
  },
  notifyWrapper:{
    backgroundColor: '#11aa3912',
    marginBottom: '5px',
    borderRadius: '20px'
  },

  notifyWrapperRead:{
    backgroundColor: 'white',
  },
  availableNotify: {
    display: 'block'
  },
  unavailableNotify: {
    display: 'none'
  },
  notifySecondary:{
    right: "13px",
    '& .btnNotifySecondary':{
      padding: '0px',
      '& .MuiFormControlLabel-root':{
        margin: '0px'
      },
      '& .MuiCheckbox-root':{
        color: 'rgb(17 170 57)'
      }
    }
  },
  titleNotify:{
    fontSize: '14px',
    padding: '0px 0px 14px 0px',
    color: 'gray',
    fontWeight: 'bold',
    letterSpacing: '1px'
  },
  infoNotNotification:{
    textAlign: 'center',
    fontSize: '12px',
    letterSpacing: '0.3px',
    color: '#80808094'
  },
  controlNotify:{
    '& .MuiTypography-body1':{
      fontSize: '13px'
    }
  },
  titleNotification:{
    fontSize: '21px', 
    textAlign: 'center', 
    fontWeight: 'bold', 
    paddingTop: '9px'
  },
  gridLabel:{
    textAlign: 'left', 
    paddingTop: '10px'
  },
  wrapperBtnsStep1:{
    padding: '12px 8px 9px 0px'
  },
  labelDialog:{
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '14px',
    '& .MuiInputBase-input':{
      padding: '5px 5px',
    }
  },
  boxField:{
    marginTop: '15px'
  },
  discountStatusBox:{
    display: 'flex',
    alignItems: 'center',
    margin: '25px 0px'
  },
  discountStatusText:{
    background: '#e5e5e5',
    padding: '5px 10px',
    marginLeft: '10px',
    borderRadius: '5px'
  },
  dialogTitle:{
    '& .MuiTypography-root':{
      fontWeight: '800',
    }
  },
  dialogContentTitle:{
    fontWeight: '800',
    marginBottom: '10px'
  },
  buttonDialog: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    fontWeight: '700'
  },
  wrapperRangeDate: {
    lineHeight: '17px',
    marginBottom: '15px',
    width: '100%',
  },
  controlLabel: {
    height: '37px',
    marginTop: '-6px',
    paddingLeft: '46px',
    color: 'gray',
    '& .MuiTypography-body1': {
      fontSize: '14px'
    }
  },
  wrapperButtonDelete: {
    backgroundColor: 'black',
    borderRadius: '4px',
    color: 'white',
    marginLeft: '7px',
    '& :hover': {
      backgroundColor: 'black',
      borderRadius: '4px',
      color: 'white'
    }
  },
}));
