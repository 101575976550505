import AppService from './AppService';

class AlarmService extends AppService {

  constructor() {
    super();
    this.path = this.getPath('alarms');
  }

  create(data) {
    return this.http.post(`${this.path}`, data);
  }

  update(data, id) {
    return this.http.patch(`${this.path}/${id}`, data);
  }

  updateStatus(data, id) {
    return this.http.patch(`${this.path}/enable-alarm/${id}`, data);
  }

  read(id) {
    return this.http.get(`${this.path}/${id}`);
  }

  list(params = '') {
    const queryParams = params ? params : '';
    return this.http.get(`${this.path}${queryParams}`);
  }

  delete(params) {
    const queryParams = params ? params : '';
    return this.http.delete(`${this.path}?${queryParams}`);
  }

}

export default AlarmService;
