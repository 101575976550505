import React, { createContext, useState, useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux';

import { useUI } from '../../app/context/ui';
import AppHelper from '../../helpers/AppHelper';
import CustomerService from '../../services/CustomerService';
import { HeaderStyle } from '../calendar/styles/HeaderStyle';
import BBWeeksOfYear from './BBWeeksOfYear';
import Header from './Header';

const customerService = new CustomerService();

const BBCalendar = (props) => {

  const {mm} = props;
  const classes = HeaderStyle();
  const [ year, setYear ] = useState((new Date().getMonth() <= 1 ) ? new Date().getFullYear()-1 : new Date().getFullYear());
  const yearReaf = useRef(year);
  const [dataRender, setDataRender] = useState([]);
  const [activityDay, setActivityDay] = useState('');
  const [messageError, setMessageError] = useState('');
  const {blockUI, snackbarUI} = useUI();
  customerService.getAccessToken();

  const onNextYear = () => {
    setYear(++yearReaf.current);
  };

  const onPrevYear = () => {
    setYear(--yearReaf.current);
  };

  const getDataSchedule = useCallback(async () => {
    try {
      blockUI.current.open(true);
      setActivityDay('');
      const {data: calendar} = await customerService.bbCalendar(mm.customer.options.id, year);
      setDataRender(calendar.data);
      calendar.data.map((e) => {
        e.current.active && setActivityDay(e.customer.workday);
      });
      
      blockUI.current.open(false);
    } catch (e) {
      setMessageError(e.response.data.message);
      AppHelper.checkError(e, snackbarUI);
      blockUI.current.open(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockUI, snackbarUI, year, mm.customer.options.id]);

  useEffect(() => {
    activityDay === '' && setActivityDay('Not services');
  }, [activityDay]);

  useEffect(() => {
    getDataSchedule();
  }, [
    year, 
    getDataSchedule
  ]);
  
  return (
    <>
      {messageError === '' ? (
        <>
        <ScheduleContext.Provider value={{getDataSchedule}}>
          <div style={{display: 'grid'}}>
            <Header
              year={year}
              onNextYear={onNextYear}
              onPrevYear={onPrevYear}
            />
            <BBWeeksOfYear
              dataRender={dataRender} 
              setDataRender={setDataRender} 
              setActivityDay={setActivityDay}
              year={year}
            />
          </div>
          <div className={classes.bbNotesContainer}>
            { mm?.customer?.options?.bbNotes }
          </div>
        </ScheduleContext.Provider>
        </>
      ) : (
        <h1 className={classes.messageError}>{messageError}</h1>
      )}
    </>
  );
};
const mapsStateToProps = (state) => {
  return {
    mm: state.mm,
  };
};
export default connect(mapsStateToProps)(BBCalendar);

export const ScheduleContext = createContext();
