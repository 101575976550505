import React from 'react';

import { styled } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

const LotHouseStyled = styled(Box)(() => ({
  width: '25px',
  height: '25px',
  '& .cls-1': {
    fill: '#58595b',
  },
}));

export const LotHouse = () => {
  return (
    <LotHouseStyled>
      <svg id='Icons' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 482.48 417.54'>
        <path
          className='cls-1'
          d='M9.83,230.64H62.57V407.71a9.83,9.83,0,0,0,9.83,9.83H425.9a9.83,9.83,0,0,0,9.83-9.83V237.2l36.92,0h0a9.84,9.84,0,0,0,8.13-15.37L368.55,57a9.82,9.82,0,0,0-8.12-4.29H338.55V9.83A9.84,9.84,0,0,0,328.71,0H285a9.82,9.82,0,0,0-9.83,9.83V52.71H117.56a9.48,9.48,0,0,0-2.17.27c-.22.05-.42.13-.65.19a10.9,10.9,0,0,0-1.4.54c-.25.12-.48.25-.72.39s-.39.19-.57.31-.39.34-.6.5-.41.33-.6.51a10,10,0,0,0-1.15,1.3,3.33,3.33,0,0,1-.25.28L1.71,215.27a9.83,9.83,0,0,0,8.12,15.37ZM182,397.88H148.22V288.43a28.62,28.62,0,0,1,33.78,0Zm93.15,0H201.67V284a9.87,9.87,0,0,0-2.88-7,47.63,47.63,0,0,0-67.36,0,9.87,9.87,0,0,0-2.88,7V397.88H82.23v-174L148.87,126l72.77,106.88a9.84,9.84,0,0,0,8.13,4.3h45.38Zm140.92,0H294.81V237.16l121.26,0ZM275.15,72.37v52.88a9.83,9.83,0,1,0,19.66,0V19.66h24.07V125.25a9.84,9.84,0,0,0,19.67,0V72.37h16.67l98.84,145.17-168.94,0H235l-77.81-114.3-.05-.07L136.18,72.37ZM117.59,80,137,108.49,67.2,211H28.42Z'
        ></path>
        <path
          className='cls-1'
          d='M247.9,199.36l160.18.06h0a9.83,9.83,0,0,0,0-19.66l-160.18-.07h0a9.84,9.84,0,0,0,0,19.67Z'
        ></path>
      </svg>
    </LotHouseStyled>
  );
};
