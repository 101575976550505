import React from 'react';
import {
  Grid,
} from "@material-ui/core";

import {FooterStyle} from './styles/FooterStyle';
import {Box, Button} from "../shared/MaterialUI";
import LinearProgressWithLabel from "./LinearProgressWithLabel";
import {SettingsApplicationsOutlined } from "@material-ui/icons";
import CachedIcon from "@material-ui/icons/Cached";
import {useUI} from "../../app/context/ui";

let dlgSettings = {
  confirm: true,
  btn: {
    close: 'Cancel',
    confirm: 'Yes',
  },
  onConfirm: () => {},
};

const Footer = (props) => {
  const classes = FooterStyle();
  const {dataStatus, updateWeeks, getDataStatus, onDataSeasonWeeks} = props;
  const {dialogUI} = useUI();

  dlgSettings.onConfirm = () => onDataSeasonWeeks();

  return (
    <>
      <Grid container justifyContent="flex-end">
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant='contained'
              color='secondary'
              size='large'
              className={classes.button}
              startIcon={<SettingsApplicationsOutlined/>}
              disabled={dataStatus?.isProgress || false}
              onClick={() => {
                if (updateWeeks.length > 0) {
                  dialogUI.current.open('Schedule', 'Are you sure to process this schedule?', dlgSettings);
                }
              }}
            >
              Process
            </Button>
            <Button
              variant='outlined'
              color="primary"
              size='large'
              className={classes.button}
              startIcon={<CachedIcon/>}
              onClick={getDataStatus}
            >
              Reload
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {dataStatus?.isProgress && (
            <Box sx={{width: '100%'}}>
              <LinearProgressWithLabel value={dataStatus?.percentage || 0} color='secondary'/>
            </Box>
          )
          }
        </Grid>
      </Grid>
    </>

  )
}

export default Footer;
