import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@material-ui/core';
import { Box, Button, Grid, Modal, IconButton, TextField } from '@material-ui/core';
import { WizardStyles } from '../../assets/css';
import { AppStyle } from '../../assets/css/app/AppStyle';
import CloseIcon from '@material-ui/icons/Close';
import {has as _has} from 'lodash';
import clsx from 'clsx';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import CrewService from '../../services/CrewService';
import { useUI } from "../../app/context/ui";
import AppHelper from "../../helpers/AppHelper";
import store from "../../redux/store";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const style = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

export const NoteStyles = makeStyles({
  removeAttachment: {
    color: 'blue',
    cursor: 'pointer',
  },
});

const crewService = new CrewService();

const UploadDocuments = (props) => {
	const {open, setOpen} = props;
	const [modalStyle] = useState(getModalStyle);
  const [file, setFile] = useState(null);
  const [disabled] = useState(false);
  const buttonRef =useRef();
  const { blockUI, snackbarUI } = useUI();

  const state = store.getState();
  const accessToken = state.user.accessToken;
  if (!accessToken) {
    history.push("/login");
  }

	const initialValues = {
		title: '',
	}

	const onSubmit = async (values) => {
    if (file) {
     try {
      blockUI.current.open(true);
      crewService.init(accessToken);
      await crewService.uploadCsv(file, values.title);
      setFile(null);
      blockUI.current.open(false);
      setOpen(false);
     }catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
     }
    }
	}

	const wizardStyle = WizardStyles();
  const appStyle = AppStyle();


  const onDrop = useCallback(
    (acceptedFiles) => {
      setFile(acceptedFiles[0]);
    }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop,  accept: '.csv, .xlsx' , disabled });

  useEffect(() => {
    if (file) {
      buttonRef.current.click();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [file]);

  const validationSchema = Yup.object({
    title: Yup.string().required('Title is required'),
  });

  return (
		<Modal
		open={open}
		onClose={() => setOpen(false)}
		aria-labelledby="simple-modal-title"
		aria-describedby="simple-modal-description"
		disableEscapeKeyDown={true}
		disableBackdropClick={true}
		style={{ zIndex: 1250 }}
	>
    <div style={modalStyle} className={wizardStyle.paperModal}>
		<Grid container alignItems="center" spacing={2}>
          <Grid container item xs={12}>
            <Grid item xs={10}>
              <h2 className={clsx(appStyle.mrg0V)}>Price update detail</h2>
            </Grid>
            <Grid item xs={2}>
              <IconButton
                color="primary"
                aria-label="close"
                className={clsx(appStyle.pdg0, appStyle.btnRight)}
                onClick={() => setOpen(false)}
              >
                <CloseIcon/>
              </IconButton>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              enableReinitialize={true}
              validationSchema={validationSchema}
            >
              {(props) => {
              const { values, errors, handleChange, handleSubmit } = props;
              return (
                <Form autoComplete="off" style={{width: '100%'}}>
                  <Box display="flex" flexDirection="column" width="100%">
                    <span style={{fontWeight: 'bold', marginBottom: '24px'}}>Title:</span>
                    <TextField
                      error={_has(errors, 'title')}
                      name='title'
                      value={values.detail}
                      onChange={handleChange}
                      variant='outlined'
                      helperText={_has(errors, 'title') ? errors.title : ''}
                      style={{ width: '100%' }}
                      fullWidth={true}
                      disabled={values.disabled}
                      placeholder="Write here"
                    />
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    width="100%"
                    justifyContent="center"
                    style={{
                    gap: '24px',
                    marginTop: '24px'
                    }}
                  >
										<span style={{fontWeight: 'bold'}}>Attach Documents:</span>
										<div {...getRootProps({ style })}>
											<input {...getInputProps()} />
											<p>Drag drop some files here, or click to select files</p>
										</div>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                    >
                      <Button
                        variant='contained'
                        color='primary'
                        style={{width: '320px'}}
                        onClick={() => setOpen(false)}
                      >
                        Back
                      </Button>
                      <Button

                        variant='contained'
                        color='primary'
                        style={{width: '320px', display: 'none'}}
                        onClick={handleSubmit}
                        ref={buttonRef}
                      >
                        Save
                      </Button>

                    </Box>
                  </Box>
                </Form>
              );}}
            </Formik>
          </Grid>
        </Grid>
    </div>
	</Modal>
  );
};

export default UploadDocuments;
