import AppService from "./AppService";

class PriceService extends AppService {

	getList() {
		return this.http.get(`/bulk-logs`);
	}
	
	updateLog(id, data) {
		return this.http.patch(`/bulk-logs/${id}`, data);
	}
	
}

export default PriceService;
