import { combineReducers } from 'redux';
import user from './user';
import mm from '../../app/store/mm';
const reducer = combineReducers({
  user,
  mm,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined;
  }

  return reducer(state, action);
};

export default rootReducer;
