import React from 'react';

import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button, makeStyles } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { useUI } from '../../app/context/ui';
import AppHelper from '../../helpers/AppHelper';
import { CustomerService } from '../../services';

let dlgSettings = {
  confirm: true,
  btn: {
    close: 'Cancel',
    confirm: 'Delete',
  },
  onConfirm: () => {},
};

const useStyles = makeStyles(() => ({
  btnDelete: {
    marginLeft: '19px',
    backgroundColor: '#c33636',
  },
}));

const customerService = new CustomerService();

const DeleteCustomer = ({ mm, user }) => {
  const { blockUI, snackbarUI, dialogUI } = useUI();
  const classes = useStyles();
  const history = useHistory();

  const handleDeleteCustomer = () => {
    dlgSettings = {
      ...dlgSettings,
      confirm: true,
      onConfirm: () => {
        onDelete();
      },
    };
    dialogUI.current.open(
      'Wait!',
      'You are about to delete a customer. Are you sure you want to delete this customer?',
      dlgSettings,
      'This process cannot be reverserd'
    );
  };

  const onDelete = async () => {
    try {
      blockUI.current.open(true);
      customerService.getAccessToken();
      await customerService.delete(mm.id);
      dialogUI.current.close();
      blockUI.current.open(false);
      history.push('/customer', { state: '' });

      dlgSettings = {
        ...dlgSettings,
        confirm: false,
        btn: {
          close: 'Close',
        },
      };
      dialogUI.current.open('', '', dlgSettings, 'Successfully deleted');
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  };

  return (
    <>
      {(user.roles[0] === 'Admin' || user.roles[0] === 'Manager') && (
        <Button
          variant='contained'
          color='primary'
          size='small'
          className={classes.btnDelete}
          startIcon={<DeleteForeverIcon />}
          onClick={handleDeleteCustomer}
        >
          Delete customer
        </Button>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    mm: state.mm.customer.options,
    user: state.user,
  };
};

export default connect(mapStateToProps)(DeleteCustomer);
