import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { WeekStyle } from '../calendar/styles/WeekStyle';
import ServiceModal from '../service/ServiceModal';


const BBWeek = (props) => {

  const [menuOpen, setMenuOpen] = useState(false);
  const [bbId, setBbId] = useState('');
  const [weekNumber, setWeekNumber] = useState('');
  const { id } = useSelector((state) => state.mm.customer.options);


  const {dataRender} = props;
  const classes = WeekStyle();

  const renderSlot = (slot) => {
    let backgroundColor = slot.current.active
      ? slot.current.color
      : slot.holidays.length > 0
        ? slot.color
        : slot.winter.active
          ? slot.winter.color
          : slot.color;
    const cursor = 'pointer';
    let backgroundBaseColor = slot.holidays.length > 0 ? slot.color : backgroundColor;
    const bottomColor = slot.customer.color;
    
    return (
      <div
        className={classes.ctnBlockWeek}
        key={`week${slot.week}`}
        onClick={() => {
          setMenuOpen(true);
          setBbId(slot.bbId);
          setWeekNumber(`${slot.selectWeek}/${slot.selectYear}`);
        }}
      >
        <div className='div1' style={{backgroundColor, cursor}}>
          {slot.start}
        </div>
        <div className='div2' style={{backgroundColor, cursor}}>
          {slot.end}
        </div>
        <div className='div3' style={{backgroundColor: backgroundBaseColor, cursor}}>
          {
            (slot.customer.active || slot.todo?.active) 
              ? <div className={classes.ctnCustomerActive} style={{ borderBottom: `solid 6px ${bottomColor}`, }}>{slot.week}</div> 
              : <div className={classes.ctnCustomerActive} style={{ borderBottom: `solid 6px white`, }}>{slot.week}</div> 
          }
        </div>
      </div>
    );
  };

  return <div>{dataRender.map((e) => renderSlot(e))}
   <ServiceModal
      open={menuOpen}
      setOpen={setMenuOpen}
      bbId={bbId}
      weekNumber={weekNumber}
      customerId={id}
    /> 
  </div>;
};

export default BBWeek;
