import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Typography, Button, Alert, Collapse, Container, Grid } from '../../components/shared/MaterialUI';
import { makeStyles } from '@material-ui/core/styles';

import { Pagination } from '@material-ui/lab';
import { DataGrid } from '@material-ui/data-grid';
import { ListStyles } from '../../assets/css';
import { CustomerService } from '../../services';

import { useDispatch } from 'react-redux';
import { addCustomer } from '../../app/store/mm/customerSlice';

import AppHelper from '../../helpers/AppHelper';
import { useUI } from '../../app/context/ui';
import {
  isUndefined as _isUndefined,
  findIndex as _findIndex,
  isEmpty as _isEmpty
} from 'lodash';
import NoteModal from '../../components/note/crud/NoteModal';
import { connect } from 'react-redux';

let dlgSettings = {
  confirm: false,
  btn: {
    close: 'Close'
  },
  onConfirm: () => {
  },
};

const NewOrders = () => {

  const [open, setOpen] = useState(true);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [click, setClick] = useState(0);
  const [select, setSelect] = useState('');
  const { dialogUI, blockUI, snackbarUI } = useUI();
  const dispatch = useDispatch();
  const [openModalNote, setOpenModalNote] = useState(false);
  const [openNote, setOpenNote] = useState(true);
  const [find, setFind] = useState('');

  const history = useHistory();
  const respNewOrder = history.location.state?.approved;
  const location = useLocation();
  const locationState = location.state || [];

  const link = _isUndefined(locationState) ? '' : _isUndefined(locationState.state) ? '' : locationState.state;
  const customerService = useMemo(() => new CustomerService(), []);

  const classes = useStyles();
  const listStyle = ListStyles();
  const pageSize = 10;
  const search = '?render=paginate&sort=-id&limit=' + pageSize + (link ?  link : '') + '&filter[status_orders]=0';

  let [, setCheckedAllStatus] = useState({});

  const [, setIdsListClientPage] = useState([]);

  const columns = [
    { field: 'fullName', headerName: 'Customer', flex: 0.3, minWidth: 250 },
    { field: 'serviceAddress', headerName: 'Address', flex: 0.3, minWidth: 280 },

    { field: 'code', headerName: 'Code', flex: 0.1, minWidth: 150 },
    { field: 'marketName', headerName: 'Market', flex: 0.1, minWidth: 150 },
    { field: 'zipcode', headerName: 'Zip', flex: 0.1, minWidth: 150 },
    {
      field: 'statusOrders',
      headerName: 'Status',
      flex: 0.2,
      minWidth: 200,
      renderCell: (params) => (
        <strong className="prob">
          <Button variant="contained" color="primary" size="small"
                  style={{marginLeft: 16, textTransform: 'none', backgroundColor: 'white', color: 'black'}}>
            {(params.value === 1) ? 'Approved' : (params.value === 2) ? 'Rejected' : 'Pending'}
          </Button>
        </strong>
      ),
    },
  ];

  const onAfterSend = () => {
    setOpenNote(false);
    setOpenModalNote(false);
  }

  const getListCustomer = useCallback(
    async (newPage = 0) => {
      try {
        blockUI.current.open(true);
        const filterPage = newPage > 0 ? '&page=' + (newPage + 1) : '';
        const findParam = search + filterPage;
        setFind(findParam);
        customerService.getAccessToken();
        const r1 = await customerService.list(findParam);
        let res = r1.data.data.map(e=>e.id);
        setIdsListClientPage(res);
        setRows(r1.data.data);
        setLastPage(r1.data.lastPage);
        setRowCount(r1.data.total);
        setPage(newPage);
        localStorage.setItem('query', search);
        blockUI.current.open(false);
      } catch (e) {
        blockUI.current.open(false);
        AppHelper.checkError(e, snackbarUI);
      }
    },
    [blockUI, snackbarUI, customerService, search]
  );

  const onDoubleClick = (e) => {
    const doubleClick = click + 1;
    const record = _findIndex(rows, { id: e.row.id }) ?? 0
    setClick(doubleClick);

    const urlSearchParams = new URLSearchParams(window.location.search);
    const sort = urlSearchParams.get("sort");
    const newSort = !_isEmpty(sort) ? `&sort=${sort}` : '';
    let sortProperty = newSort.split("&sort=")[1];

    if (doubleClick === 2) {
      if (select === e.row.id) {
        setClick(0);
        setSelect('');
        dispatch(addCustomer({ ...e.row }));
        history.push(`/edit-customer?record=${record}&sortCustom=${sortProperty}`, { ...e.row, newOrder:true, find: find });
      } else {
        setClick(1);
        setSelect(e.row.id);
      }
    } else if (doubleClick === 1) {
      setSelect(e.row.id);
    }
  };

  useEffect(() => {
    (async function init() {
      await getListCustomer();
    })();
  }, [getListCustomer]);

  useEffect(() => {
    let temp = {};
    for (let i = 0; i < lastPage; i++) {
      temp = { ...temp, [`item${i}`]: false };
      setCheckedAllStatus({ ...temp });
    }
  }, [lastPage]);

  useEffect(() => {
    if(respNewOrder){
      dlgSettings = {
        ...dlgSettings,
        onConfirm: () => {
        }
      };
      dialogUI.current.open('Great!', 'This request has been approved. Now you can see it in the list of clients.',
        dlgSettings);
    }else if(respNewOrder===false){
      setOpenModalNote(true);
    }
  }, [respNewOrder,dialogUI]);

  return (
    <Container component='main' maxWidth='lg'>
      {location.state && (
        <Collapse in={open}>
          <Alert
            severity='success'
            color='secondary'
            style={{ align: 'center',  color: '#D5D8DC', }}
            icon = {false}
            onClose={() => {
              setOpen(false);
            }}
          >
            {location.state.message}
          </Alert>
        </Collapse>
      )}
      <Typography variant='h4'>Clients List</Typography>
      <div>
        <Grid container direction='row' alignItems='center'>
          <Grid item xs={12} sm={8}>
            <h4>Clients list pending aproval</h4>
          </Grid>

          <Grid item xs={12} sm={4} className={classes.wrapperPagination}>
            <Pagination
              count={lastPage}
              variant='outlined'
              defaultPage={1}
              page={page + 1}
              onChange={(event, value) => {
                (async function init() {
                  await getListCustomer(value - 1);
                })();
              }}
              shape='rounded'
            />
          </Grid>
        </Grid>

      </div>
      <div style={{ height: 580, width: '100%' }}>
        <DataGrid
          autoHeight
          pagination
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[pageSize]}
          page={page}
          paginationMode='server'
          onRowClick={(e) => {
            onDoubleClick(e);
          }}
          onPageChange={(newPage) => {
            (async function init() {
              await getListCustomer(newPage);
            })();
          }}
          rowCount={rowCount}
          className={listStyle.dataGrid}
        />
      </div>

      {
        (openModalNote)
          &&
            <NoteModal
              open={openNote}
              setOpen={setOpenNote}
              onAfterSend={onAfterSend}
              type={'rejected_note'}
            />
      }

    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    mm: state.mm.customer.options,
    notification: state.mm.notification.options,
    user: state.user
  };
};

export default connect(mapStateToProps)(NewOrders);

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    textTransform: 'none',
    fontSize: '13px'
  },
  checkboxSvg: {
    '& .MuiFormControlLabel-label': {
      color: '#113019',
      fontSize: '13px',
    },
  },
  wrapperCheckboxSvg: {
    border: 'solid 1px green',
    borderRadius: '10px',
    paddingLeft: '15px',
    marginBottom: '4px',
  },
  iconButtonSvg: {
    color: '#11aa39',
    backgroundColor: '#11aa391f',
    padding: '5px',
  },
  iconButtonChoose: {
    color: '#ffffff',
    padding: '5px',
    backgroundColor: '#0b0b0bcf',
    borderRadius: '16px',
    fontSize: '12px',
    height: '29px',
    fontWeight: '700',
  },
  rootAwayListener: {
    position: 'relative',
    float: 'right',
    marginRight: '22px',
    marginTop: '6px',
  },
  awayDropdown: {
    top: '36px',
    left: '-52px',
    right: '0',
    width: '138px',
    padding: '8px',
    zIndex: '1',
    position: 'absolute',
    backgroundColor: '#11aa3930',
    borderRadius: '11px',
    textAlign: 'center',
    '& button': {
      marginBottom: '5px',
    },
  },
  btnSelectedAll:{
    color:'green'
  },
  wrapperPagination:{
    paddingLeft: '16px'
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));
