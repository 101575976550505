import React, { useContext, useEffect } from 'react';

import { useFormikContext } from 'formik';
import {
  cloneDeep as _cloneDeep, includes as _includes, without as _without,
} from 'lodash';
import { useHistory } from 'react-router-dom';

import {
  Box, Button, Checkbox, CheckIcon,
  Container, FormControl, FormControlLabel, FormGroup,
  Link, List, ListItem, ListItemIcon,
  ListItemText, Select, Typography,
} from '../../../../../components/shared/MaterialUI';
import { SignUpCustomerContext } from '../../SignUpProcess';
import { Step2Styles } from '../quote-style';

const Step2 = (props) => {
  const { state, dispatch } = useContext(SignUpCustomerContext);
  const { mowCut, zipCodeValid, srvFrecuency } = state;
  const history = useHistory();
  const formik = useFormikContext();

  const classes = Step2Styles();

  const servicesIncluded = [
    'Receive a Personalized Mow Schedule',
    'Designated Mow Day Chosen By You',
    'Mow',
    'Line trim',
    'Edge',
    'Blow Debris From Concrete Areas',
  ];

  const {
    quotePrice,
    handleFrequencySelect,
    defaultBool,
    setDefaultBool,
    disableBagGrass,
    frequencies,
    setQuotePrice,
    getQuotePrice,
    selectedLot,
  } = props;

  const handleMowAreas = (e) => {
    const { name, value } = e.target;
    const mowCutClone = _cloneDeep(mowCut);
    dispatch({
      type: 'setMowCut',
      payload: value,
    });
    if (name === 'front' || name === 'back' || name === 'bagGrass') {
      setDefaultBool((prev) => ({ ...prev, [name]: !defaultBool[name] }));
    }
    if (value === formik.values[name]) {
      let removeMow = _without(mowCutClone, value);
      formik.setFieldValue(name, '');
      dispatch({
        type: 'setMowCut',
        payload: removeMow,
      });
    } else {
      mowCutClone.push(value);
      formik.setFieldValue(name, value);
      dispatch({
        type: 'setMowCut',
        payload: mowCutClone,
      });
    }
  };

  const handleInfoCheckbox = (e) => {
    const { name, value } = e.target;
    if (value === formik.values[name]) {
      formik.setFieldValue(name, '');
      dispatch({
        type: 'setInfoCheckboxes',
        payload: {
          [name]: '',
        },
      });
    } else {
      formik.setFieldValue(name, value);
      dispatch({
        type: 'setInfoCheckboxes',
        payload: { [name]: value },
      });
    }
  };

  const handleSubmit = () => {
    history.push(`/web/sign-up-process`);
  };

  useEffect(() => {
    setQuotePrice(
      getQuotePrice(
        selectedLot,
        srvFrecuency,
        _includes(mowCut, 'BagGrass'),
        _includes(mowCut, 'Front'),
        _includes(mowCut, 'Back')
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLot, srvFrecuency, mowCut]);

  return (
    <Container component='section' disableGutters maxWidth='xl' className={classes.container}>
      <Typography style={{ fontSize: '35px' }}>Your Custom Quote from Mow Managers</Typography>
      <Box className={classes.quoteHeader}>
        <Typography className='quotePrice'>${quotePrice}.00</Typography>
        <Typography className='quoteFrequency'>{frequencies[`f${srvFrecuency}`]} + Tax</Typography>
        <Typography className='exceptions'>{zipCodeValid}</Typography>
        <Box className={classes.selectedOptions}>
          <Typography className='selected'>Selected Options</Typography>
          <Select
            native
            className={classes.select}
            onChange={(e) => handleFrequencySelect(e)}
            value={`f${srvFrecuency}`}
          >
            <option value='f1'>Weekly</option>
            <option value='f2'>Bi Weekly</option>
            <option value='f3'>3 Weekly</option>
            <option value='f4'>Drought Schedule</option>
          </Select>
          <FormControl component='fieldset' style={{ marginBottom: '24px', width: '80%' }}>
            <FormGroup
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
              }}
            >
              <FormControlLabel
                onChange={(e) => handleMowAreas(e)}
                className={classes.checkboxLabel}
                control={
                  <Checkbox checked={defaultBool.front} name='front' value='Front' disableRipple disableFocusRipple />
                }
                label='Front'
              />
              <FormControlLabel
                onChange={(e) => handleMowAreas(e)}
                className={classes.checkboxLabel}
                control={
                  <Checkbox checked={defaultBool.back} name='back' value='Back' disableRipple disableFocusRipple />
                }
                label='Back'
              />
              <FormControlLabel
                onChange={(e) => handleMowAreas(e)}
                className={classes.checkboxLabel}
                disabled={disableBagGrass}
                control={
                  <Checkbox
                    checked={defaultBool.bagGrass}
                    name='bagGrass'
                    value='BagGrass'
                    disabled={disableBagGrass}
                    disableRipple
                    disableFocusRipple
                  />
                }
                label='Bag grass'
              />
            </FormGroup>
          </FormControl>
        </Box>
      </Box>
      <Box className={classes.quoteInfo}>
        <Box className='videoCtn'>
          <iframe
            width='100%'
            height='350px'
            src='https://www.youtube-nocookie.com/embed/y-3gKWnh4sg'
            frameBorder='0'
            allowFullScreen
            style={{ borderRadius: '12px' }}
          />
        </Box>
        <Typography className='budget'>The budget way to mow your lawn.</Typography>
        <>
          <Typography className='subtitles'>Do you want info on these services?</Typography>
          <FormControl component='fieldset' style={{ marginBottom: '24px', width: '80%' }}>
            <FormGroup
              style={{
                display: 'flex',
              }}
            >
              <FormControlLabel
                onChange={(e) => handleInfoCheckbox(e)}
                className={`${classes.checkboxLabel} ${classes.services}`}
                control={<Checkbox name='flowerBedCleaning' value='yes' disableRipple disableFocusRipple />}
                label='Flower Bed Cleaning'
              />
              <FormControlLabel
                onChange={(e) => handleInfoCheckbox(e)}
                className={`${classes.checkboxLabel} ${classes.services}`}
                control={<Checkbox name='bushTrimming' value='yes' disableRipple disableFocusRipple />}
                label='Bush Trimming'
              />
            </FormGroup>
          </FormControl>
        </>
        <Typography className='subtitles'>What&apos;s included</Typography>
        <List className={classes.list}>
          {servicesIncluded.map((service, index) => {
            return (
              <ListItem key={index}>
                <ListItemIcon>
                  <CheckIcon fontSize='small' style={{ fill: '#87c730' }} />
                </ListItemIcon>
                <ListItemText primary={service} />
              </ListItem>
            );
          })}
        </List>
        <Box>
          <Button onClick={() => handleSubmit()} className={classes.button}>
            Proceed
          </Button>
          <Link style={{ textDecoration: 'none' }} to='https://www.mowmanagers.com/'>
            <Button className={classes.button}>Cancel</Button>
          </Link>
        </Box>
      </Box>
    </Container>
  );
};

export default Step2;
