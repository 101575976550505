import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import AttachmentForm from './AttachmentForm';
import AttachmentList from './AttachmentList';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box pl={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  tab: {
    backgroundColor: '#f5f5f5',
    color: theme.palette.primary.main,
    opacity: 1,
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      opacity: 1,
    },
  },
}));

const AttachmentTab = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        <Tab label="Attachments" {...a11yProps(0)} />
        <Tab label="Add" {...a11yProps(1)} className={classes.tab}  />
      </Tabs>
      <TabPanel value={value} index={0}>
        <AttachmentList />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AttachmentForm />
      </TabPanel>
    </div>
  );
};

export default AttachmentTab;
