import AppService from './AppService';

class StateService extends AppService {
  constructor() {
    super();
    this.path = this.getPath('states');
  }

  zipCode(id) {
    return this.http.get(`${this.path}/${id}`);
  }

  zip(zip) {
    return this.http.get(`web/zip/${zip}`);
  }
}

export default StateService;
