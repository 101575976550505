import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { Box, Menu, MenuItem } from '@material-ui/core';
import { AccountCircleOutlined } from '@material-ui/icons';
import { ExpandMore } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Avatar, CssBaseline } from './shared/MaterialUI';
import { WizardStyles, HeaderStyles } from '../assets/css';
import AppHelper from '../helpers/AppHelper';
import { useUI } from '../app/context/ui';
import logo from '../assets/images/logo.png';

const HeaderWeb = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const {snackbarUI} = useUI();
  const history = useHistory();
  const classes = HeaderStyles();
  const wizardStyle = WizardStyles();
  const firstName = props.user.firstName;
  const menuId = 'primary-search-account-menu';

  const logout = () => {
    try {
      props.dispatch({type: 'LOGOUT'});
      history.push('/web/login');
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      id={menuId}
      keepMounted
      transformOrigin={{vertical: 'top', horizontal: 'right'}}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => {
        logout()
      }}>Logout</MenuItem>
    </Menu>
  );

  return (
    <>
      <CssBaseline/>
      <AppBar position="absolute"
              className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            className={classes.menuButton}
          >
            <Avatar alt="Mow Managers" src={logo}/>
          </IconButton>
          
          <Typography variant="h6" className={classes.title}/>

          <Box sx={{flexGrow: 1}}/>
          
          <Box sx={{flexGrow: 1}}/>
          <Box sx={{display: {xs: 'flex', md: 'none'}}}>
            <span>Hi </span>
            <span className={wizardStyle.title}>{firstName}!</span>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              color="inherit"
            >
              <AccountCircleOutlined/>
            </IconButton>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <ExpandMore/>
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      
      {renderMenu}

    </>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(HeaderWeb);
