import { useState } from 'react'
import { Button, Menu, MenuItem } from '@material-ui/core';

import { useUI } from '../../../app/context/ui';
import AppHelper from '../../../helpers/AppHelper';
import { ReportsService } from '../../../services';
import ReportEmail from './ReportEmail';

let dlgSettings = {
  confirm: false,
  btn: {
    close: 'Close',
    confirm: '',
  },
  onConfirm: () => {},
};

export const ButtonReport = ({row}) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [open, setOpen] = useState(false);
	const openMenu = Boolean(anchorEl);

	const { blockUI, dialogUI, snackbarUI } = useUI();

	const reportService = new ReportsService();
	
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

	const handleDownloadReport = async (file, status) => {
    try {
      if (status === 'completed') {
        blockUI.current.open(true);
        reportService.getAccessToken();      
        const { data } = await reportService.download(file);
        window.open(data.resource, '_blank');
        blockUI.current.open(false);
      }
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  }

	const handleSendEmail = async (email) => {
		try {
			if (row.status === 'completed') {
				blockUI.current.open(true);
				reportService.getAccessToken();      
				await reportService.sendEmail(row.filename, email);
				dialogUI.current.open('Success', 'The report is being processed' , dlgSettings, '', true);
				blockUI.current.open(false);
			}
		} catch (e) {
			blockUI.current.open(false);
			AppHelper.checkError(e, snackbarUI);
		}
	}

	return (
		<>
			<Button
				id="basic-button"
				variant='contained'
				color='primary'
				size='small'
				style={{ textTransform: 'none', minWidth: 130 }}
				aria-controls={openMenu ? 'basic-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={openMenu ? 'true' : undefined}
				disabled={(row.status !== 'completed')}
				onClick={handleClick}
			>
				Download
			</Button>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={openMenu}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
				anchorOrigin={{
				vertical: 'top',
				horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				style={{ marginTop: 50 }}
			>
				<MenuItem onClick={() => {
					setOpen(true);
					handleClose();
				}}
				>
					Send Email
				</MenuItem>
				<MenuItem
					onClick={() => {
						handleDownloadReport(row.filename, row.status);
						handleClose();
					}}
				>
					Download
				</MenuItem>
        </Menu>
				<ReportEmail 
					open={open} 
					setOpen={setOpen} 
					report={row}
					handleSendEmail={handleSendEmail}
				/>
		</>
);
}
