import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Form, Formik, useFormikContext } from 'formik';
import { useDispatch} from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { Grid, IconButton, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PhoneIcon from '@material-ui/icons/PhoneAndroid';
import PublicIcon from '@material-ui/icons/Public';

import { useUI } from '../../../app/context/ui';
import {
  addCustomer, updateProperty,
} from '../../../app/store/mm/customerSlice';
import { WizardStyles } from '../../../assets/css';
import { ArrowUpwardIcon } from '../../../components/shared/MaterialUI';
import {
  AutoSave, CheckboxField, InputField, SelectField,
} from '../../../forms';
import { FormatPhoneNumber } from '../../../helpers';
import { srvFrecuencysDb, statusDb } from '../../../models/db';
import ValidationFields from '../../../models/validationFields';
import CustomerService from '../../../services/CustomerService';
import AppHelper from '../../../helpers/AppHelper';
import MailIcon from '@material-ui/icons/Mail';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import { weekDays } from '../../../models/db';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    backgroundColor: '#E8E8E8',
  },
  avatar: {
    padding: '5px 0px 0px 1px',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginTop: '41px',
    paddingLeft: '14%'
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
}));

const customerService = new CustomerService();

const Step = (props) => {
  const {blockUI, dialogUI, snackbarUI} = useUI();
  const {
    formField: {isRestart, status, email, contactNumber, newOrder, billingAddress, firstName, lastName},
    globe,
    parentId,
    setLocationState,
  } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const wizardStyle = WizardStyles();
  const formikHeader = useRef();

  const {values: formValues} = useFormikContext();
  const [isEditingName, setIsEditingName] = useState(false);

  const {id} = formValues;
  const initialValues = {
    isApproved: formValues.isApproved,
    isRestart: formValues.isRestart,
    firstName: formValues.firstName,
    lastName: formValues.lastName,
    status: formValues.status,
    email: formValues.email,
    contactNumber: formValues.contactNumber,
    isTranslation: formValues.isTranslation,
    srvFrecuency: formValues.srvFrecuency,
    statusOrders: formValues.statusOrders,
    subtotalMowing: formValues.subtotalMowing,
    totalMowing: formValues.totalMowing,
    billingAddress: formValues.billingAddress,
    mailPreferences: formValues.mailPreferences,
    weekDayName: formValues.weekDayName,
    crew: formValues.crew,
    weekDay: formValues.weekDay,
    marketName: formValues.marketName
  };

  const frequency = srvFrecuencysDb.find((srv) => {
    if (srv.value === formValues.srvFrecuency) {
      return srv;
    } else {
      return null;
    }
  });

  const weekName = weekDays.find((w) => {
    if (w.value === formValues.weekDay) {
      return w;
    } else {
      return null;
    }
  });

  const validationSchema = Yup.object({
    email: ValidationFields.email(),
    contactNumber: ValidationFields.contactNumber(),
  });

  const handleSubmit = useCallback(async (values) => {
    try {
      blockUI.current.open(true);
      customerService.getAccessToken();
      await customerService.update(values, id);
      blockUI.current.open(false);

      if (formikHeader.current) {
        dispatch(updateProperty(values));
      }

    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  }, [blockUI, id, dispatch, snackbarUI]);

  const handleClickRestart = () => {
    const settings = {
      confirm: true,
      btn: {
        close: 'Cancel',
      },
      onConfirm: () => {
        if (formikHeader.current) {
          formikHeader.current?.setFieldValue('isRestart', true);
          formikHeader.current?.setFieldValue('statusOrders', 0);
        }
        dialogUI.current.close();
      },
    };
    dialogUI.current.open(
      'Customer',
      'Are you sure to move this customer to pending review?',
      settings
    );
  };

  const handleClickUpdatePreferences = (type) => {
    try {
      blockUI.current.open(true);
      formikHeader.current?.setFieldValue('mailPreferences', type);
      handleSubmit({
        mailPreferences: type,
      });
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const handleRedirectMap = () => {
    window.open(globe, '_blank');
  };
  const handleRedirectParent = async () => {
    customerService.getAccessToken();
    await customerService.read(parentId.customerAttributesId).then((res) => {
      history.push({state: {id: res.data.id}});
      setLocationState({id: res.data.id});
      dispatch(addCustomer({...res.data}));
    });
  };

  const handleClickCellPhone = async () => {
    try {
      blockUI.current.open(true);
      customerService.getAccessToken();
      const data = {
        isCellphone: !formValues.isCellphone,
      };
      await customerService.updateCellPhone(data, id);
      dispatch(updateProperty(data));
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  useEffect(() => {
    setIsEditingName(false);
  }, [formValues.firstName, formValues.lastName]);

  useEffect(() => {
    formikHeader.current?.setFieldValue('email', formValues.email);
    formikHeader.current?.setFieldValue('billingAddress', formValues.billingAddress);
    formikHeader.current?.setFieldValue('contactNumber', formValues.contactNumber);
    formikHeader.current?.setFieldValue('status', formValues.status);
  }, [formValues.billingAddress, formValues.email, formValues.contactNumber, formValues.status]);

  return (
    <>
      <Formik
        innerRef={formikHeader}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form autoComplete="off">
            <Paper className={classes.paper}>
              <Grid container spacing={1} direction="row">
                <Grid item xs={12}/>
                <Grid item xs={12}/>
                <Grid item xs={12} sm={1}/>
                <Grid item xs={12} sm={4}>
                  <Grid container spacing={2} justifyContent="flex-end" alignItems="flex-start">
                    {
                      !isEditingName ? (
                      <Grid item xs={12} style={{cursor: 'pointer'}} onClick={() => setIsEditingName(true)}>
                        <span  className={wizardStyle.title}>{formValues.firstName} {formValues.lastName}</span>
                      </Grid>
                      ) : (
                        <>
                          <Grid container item xs={6}>
                            <InputField
                              name={firstName.name}
                              fullWidth
                            />
                          </Grid>
                          <Grid container item xs={6}>
                            <InputField
                              name={lastName.name}
                              fullWidth
                            />
                          </Grid>
                        </>
                      )
                    }
                    <Grid item xs={parentId ? 8 : 5}>
                      <span className={wizardStyle.title}>Address</span>
                    </Grid>
                    <Grid item xs={parentId ? 2 : 7}>
                      <IconButton
                        aria-label="redirect"
                        size="small"
                        style={{padding: '0px'}}
                        onClick={handleRedirectMap}
                      >
                        <PublicIcon fontSize="inherit"/>
                      </IconButton>
                    </Grid>
                    {parentId && (
                      <Grid item xs={2}>
                        <IconButton
                          aria-label="redirect"
                          size="small"
                          style={{padding: '0px'}}
                          onClick={handleRedirectParent}
                        >
                          <ArrowUpwardIcon fontSize="inherit"/>
                        </IconButton>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <span>{formValues.serviceAddress}</span>
                    </Grid>
                    <Grid item xs={7}>
                      <span className={wizardStyle.title}>Create date</span>
                    </Grid>
                    <Grid item xs={5} style={{paddingLeft:'4px'}}>
                      <span>{formValues.joinDate}</span>
                    </Grid>
                    <Grid item xs={7}>
                      <span className={wizardStyle.title}>Start date</span>
                    </Grid>
                    <Grid item xs={5} style={{paddingLeft:'4px'}}>
                      <span>{formValues.serviceDate}</span>
                    </Grid>
                    <Grid item xs={7}>
                      <span className={wizardStyle.title}>No tax</span>
                    </Grid>
                    <Grid item xs={5} style={{paddingLeft:'4px'}}>
                      <span>${Number(formValues.subtotalMowing).toFixed(2)}</span>
                    </Grid>
                    <Grid item xs={7}>
                      <span className={wizardStyle.title}>Calculated tax</span>
                    </Grid>
                    <Grid item xs={5} style={{paddingLeft:'4px'}}>
                      <span>${Number(formValues.totalMowing).toFixed(2)}</span>
                    </Grid>
                    <Grid item xs={7}>
                      <span className={wizardStyle.title}>Mow Day</span>
                    </Grid>
                    <Grid item xs={5} style={{paddingLeft:'4px'}}>
                      <span>{weekName?.label}</span>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={1} alignItems="center">
                    {!newOrder && (
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={1}/>
                          <Grid item xs={4}>
                            {!formValues.isRestart && (
                              <CheckboxField
                                name={isRestart.name}
                                label={isRestart.label}
                                className={wizardStyle.checkboxField}
                                onChange={handleClickRestart}
                              />
                            )}
                          </Grid>
                          <Grid item xs={7}>
                            <SelectField
                              className={wizardStyle.selected}
                              name={status.name}
                              data={statusDb}
                              autoFocus
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    <Grid item xs={1}/>
                    <Grid item xs={4}>
                      <span className={wizardStyle.title}>Account #</span>
                    </Grid>
                    <Grid item xs={7}>
                      <span className={wizardStyle.title}>{formValues.customerId}</span>
                    </Grid>

                    {
                      (formValues.mailPreferences === 1)
                        ?
                          (
                            <>
                              <Grid item xs={1}>
                                <AlternateEmailIcon style={{width: '20px'}}/>
                              </Grid>
                              <Grid item xs={3}>
                                <span className={wizardStyle.title}>Email</span>
                              </Grid>
                              <Grid item xs={1}>
                                <IconButton
                                  aria-label="redirect"
                                  size="small"
                                  style={{padding: '0px'}}
                                  onClick={()=>handleClickUpdatePreferences(2)}
                                >
                                  <UnfoldMoreIcon fontSize="inherit"/>
                                </IconButton>
                              </Grid>
                              <Grid item xs={7}>
                                <InputField name={email.name} fullWidth/>
                              </Grid>
                            </>
                          )
                        :
                          (
                            <>
                              <Grid item xs={1}>
                                <MailIcon style={{width: '20px'}}/>
                              </Grid>
                              <Grid item xs={3}>
                                <span className={wizardStyle.title}>Postal mail</span>
                              </Grid>
                              <Grid item xs={1}>
                                <IconButton
                                  aria-label="redirect"
                                  size="small"
                                  style={{padding: '0px'}}
                                  onClick={()=>handleClickUpdatePreferences(1)}
                                >
                                  <UnfoldMoreIcon fontSize="inherit"/>
                                </IconButton>
                              </Grid>
                              <Grid item xs={7}>
                                <InputField name={billingAddress.name} fullWidth/>
                              </Grid>
                            </>
                          )
                    }
                    <Grid item xs={1}>
                       <PhoneIcon
                        onClick={handleClickCellPhone}
                        color={formValues.isCellphone ? 'secondary' : 'primary'}
                        style={{width: '20px', cursor: 'pointer'}}
                       />
                    </Grid>
                    <Grid item xs={4}>
                      <span className={wizardStyle.title}>Phone number</span>
                    </Grid>
                    <Grid item xs={7}>
                      <InputField
                        name={contactNumber.name}
                        InputProps={{
                          inputComponent: FormatPhoneNumber,
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={1}/>
                    <Grid item xs={4}>
                      <span className={wizardStyle.title}>Frequency</span>
                    </Grid>
                    <Grid item xs={7}>
                      <span className={wizardStyle.title}>{frequency?.label}</span>
                    </Grid>
                    <Grid item xs={1}/>
                    <Grid item xs={4}>
                      <span className={wizardStyle.title}>Crew</span>
                    </Grid>
                    <Grid item xs={7} style={{paddingLeft:'4px'}}>
                      <span>{formValues.crew}</span>
                    </Grid>
                    <Grid item xs={1}/>
                    <Grid item xs={4}>
                      <span className={wizardStyle.title}>Market</span>
                    </Grid>
                    <Grid item xs={7} style={{paddingLeft:'4px'}}>
                      <span>{formValues.marketName}</span>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <>
                    <AutoSave debounceMs={500} id={id} />
                  </>
                </Grid>
              </Grid>
            </Paper>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default Step;
