const statusBbDb = [
  {
    value: null,
    label: '-- Select --'
  },
  {
    value: 1,
    label: 'Not'
  },
  {
    value: 2,
    label: 'Yes'
  },
  {
    value: 3,
    label: 'Cancelled'
  }
];

export default statusBbDb;
