import { makeStyles } from '@material-ui/core/styles';

export const ActivityListStyle = makeStyles(() => ({
  ctnActivityList:{
    height: '129px',
    padding: '18px 22px',
    overflowY: 'scroll',
    backgroundColor: '#E8E8E8',
    fontSize: '15px',
    '& .titleDay':{
      fontWeight: '600',
      fontSize: '16px',
      marginBottom: '11px',
    },
    '& .ctnInfoActivityList':{
      marginBottom: '14px'
    },
    '& .ctnHours':{
      borderRight: 'solid 3px #5887D6',
      '& .at':{
        color: '#10AA39',
        fontWeight: '600',
      }
    },
    '& .ctnInfo':{
      paddingLeft: '25px',
      '& .infoTitle':{
        color: '#7A95A4'
      }
    }
  }
}));
