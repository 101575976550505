import AppService from './AppService';

class ExceptionService extends AppService {
  constructor() {
    super();
    this.path = this.getPath('exceptions');
  }

  listExceptions(marketId, params = '') {
    const queryParams = params ? params : '';
    return this.http.get(`${this.path}${marketId}${queryParams}`);
  }

  getExceptions({ marketId, page = 1, limit = 50, sort= '-id'}) {
    return this.http.get(
      `${this.path}/${marketId}?render=paginate&sort=${sort}&limit=${limit}&page=${page}`
    );
  }

  paginationExceptions(optionSelected, url, page) {
    return this.http.get(`${this.path}/${optionSelected}?${url}&page=${page}`);
  }

  delete(taskId) {
    return this.http.delete(`${this.path}/${taskId}`);
  }

  editException(id, data) {
    return this.http.patch(`${this.path}/${id}`, data);
  }

  createException(data) {
    return this.http.post(this.path, data);
  }

}

export default ExceptionService;
