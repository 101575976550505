import './App.css';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import ProtectedRoute from './components/guard/ProtectedRoute';
import ProtectedWebRoute from './components/guard/ProtectedWebRoute';
import Routes from './navigation/Route';
import RoutesWeb from './navigation/RouteWeb';
import ForgotPassword from './pages/auth/ForgotPassword';
import Login from './pages/auth/LoginPage';
import ResetPassword from './pages/auth/ResetPassword';
import ForgotPasswordPage from './pages/web/auth/ForgotPassword';
import LoginPage from './pages/web/auth/LoginPage';
import ResetPasswordPage from './pages/web/auth/ResetPassword';
import SignUpProcess from './pages/web/signUpProcess/SignUpProcess';
import ExternalSearchPhone from './pages/external/ExternalSearchPhone';
import ExternalCalendar from './pages/external/ExternalCalendar';

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path='/login' component={Login} />
          <Route exact path='/forgot-password' component={ForgotPassword} />
          <Route exact path='/reset-password' component={ResetPassword} />
          <Route exact path='/external-search-phone' component={ExternalSearchPhone} />
          <Route exact path='/external-calendar' component={ExternalCalendar} />
          {Routes.map((layout, i) => {
            return (
              <ProtectedRoute
                key={i}
                exact={layout.exact}
                path={layout.path}
                component={layout.component}
                name={layout.name}
              />
            );
          })}
          <Route exact path='/web/login' component={LoginPage} />
          <Route exact path='/web/forgot-password' component={ForgotPasswordPage} />
          <Route exact path='/web/reset-password' component={ResetPasswordPage} />
          {
            RoutesWeb.map((layout,i) => {
              return (
                <ProtectedWebRoute
                  key={i}
                  exact={layout.exact} 
                  path={layout.path}
                  component={layout.component} 
                  name={layout.name}
                />
              );
            })
          }
          <Route
            exact
            path={['/web/quote', '/web/sign-up-process', '/web/sign-up-process-billing', '/web/success']}
            component={SignUpProcess}
          />
        </Switch>
      </Router>
    </>
  );
}

export default App;
