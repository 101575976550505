import AppService from './AppService';

class WebService extends AppService {

  constructor() {
    super();
    this.path = this.getPath('web');
  }

  taxRate(id) {
    return this.http.get(`${this.path}/markets/${id}`);
  }

  create(data) {
    return this.http.post(`${this.path}`, data);
  }

  getState(code) {
    return this.http.get(`${this.path}/zip/${code}`);
  }
  
}

export default WebService;
