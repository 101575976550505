import AppService from './AppService';

class CustomerWebService extends AppService {

  constructor() {
    super();
    this.path = this.getPath('customer');
  }

  update(data, id) {
    return this.http.patch(`${this.path}/${id}`, data);
  }

  read(id) {
    return this.http.get(`${this.path}/${id}`);
  }

  me() {
    return this.http.get(`${this.path}/me`);
  }

  updateCreditCard(data, id) {
    return this.http.patch(`${this.path}/${id}/update-credit-card`, data);
  }

  list(params = '') {
    const queryParams = params ? params : '';
    return this.http.get(`${this.path}${queryParams}`);
  }

}

export default CustomerWebService;
