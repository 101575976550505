import React, { useEffect, useRef, useState } from 'react';

import { Form, Formik } from 'formik';
import {
  isEmpty as _isEmpty, isNumber as _isNumber, mapKeys as _mapKeys,
  toNumber as _toNumber, isNull as _isNull
} from 'lodash';
import { useHistory } from 'react-router-dom';

import { useUI } from '../../../app/context/ui';
import { Button, Grid } from '../../../components/shared/MaterialUI';
import AppHelper from '../../../helpers/AppHelper';
import { CustomerService } from '../../../services';
import { useStyles } from '../style/lookup-style';
import Lookup1 from './components/Lookup1';
import Lookup2 from './components/Lookup2';
import Lookup3 from './components/Lookup3';
import Lookup4 from './components/Lookup4';
import Lookup5 from './components/Lookup5';
import SaveSearch from './components/SaveSearch';
import moment from "moment";

const Lookup = ({ setTabValue }) => {
  const classes = useStyles();
  const history = useHistory();
  const formikRef = useRef();
  const [expandedCollapse, setExpandedCollapse] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.matchMedia('(min-width: 768px)').matches);

  const customerService = new CustomerService();

  const { dialogUI, blockUI, snackbarUI } = useUI();

  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  const initialValues = {
    billing_note_date: '',
    market: '',
    customer_id: '',
    service_address: '',
    week: '',
    schedule_service: null,
    lot_review: '',
    payment_option: '',
    zipcode: '',
    crew: '',
    fullName: '',
    contactNumber: '',
    week_day: '',
    is_bb: false,
    week_number_bb: '',
    week_day_bb: '',
    crew_bb: '',
    status_bb: '',
    created_at: '',
    status_orders: false,
    mapsco: '',
    map_a: '',
    mapsco_grid: '',
    status: '',
    srv_frecuency: '',
    serviceNotes: [],
    is_skips: '',
    is_write: '',
    is_redo: '',
    csr: '',
    updated_at: null,
    end_date: null,
    user_id: '',
    market_bb: '',
    log:{
      customer_id: '',
      date_start: '',
      date_end: '',
      user_id: '',
      updated_at: '',
    },
    note:{
      created_at: '',
      last_name: '',
    },
    bb:{
      week_number: '',
      week_day: '',
      crew: '',
      status: '',
    }
  };

  const findSearch = (values) => {
    let arr = [];
    _mapKeys(values, (value, key) => {
      if ((!_isEmpty(value) || _isNumber(value)) && !_isNull(value)) {
        if (key === 'serviceNotes') {
          value.map((val) => {
            arr.push(`filter[${val}]=${true}`);
          });
        } else if (key === 'week') {
          let mowWeek = value.split('/');
          mowWeek.map((val, index) => {
            index === 0
                ? arr.push(`filter[work_weeks.weeks]=${_toNumber(val)}`)
                : arr.push(`filter[work_weeks.year]=${val}`);
          });
        } else if (key === 'week_day') {
          arr.push(`filter[work_weeks.week_day]=${value}`);
        } else if (key === 'status') {
          arr.push(`filter[${key}]=${value}`);
          arr.push(`filter[status_orders]=1`);
        }
        else if(key === 'log' || key === 'note' || key === 'bb'){
          for (const keys in value) {
            if (Object.hasOwnProperty.call(value, keys)) {
              let element = value[keys];
              if(element!=='' && !_isNull(element)){
                arr.push(`filter[${key}.${keys}]=${element}`);
              }
            }
            if(key === 'note' && value.last_name != '') {
              const amount = moment().isoWeekday() == 1 ? 3 : 1;
              const created_at = moment().subtract(amount, 'd').format('MM/DD/YYYY');
              if (!value.created_at && !arr.find((ele) => ele === `filter[note.created_at]=${created_at}`)){
                arr.push(`filter[note.created_at]=${created_at}`);
              }
              if (!arr.find((ele) => ele === 'filter[note.type_id]=1')){
                arr.push(`filter[note.type_id]=1`);
              }
            }
          }
        }
        else {
          arr.push(`filter[${key}]=${value}`);
        }
      } else {
        null;
      }
    });
    return arr;
  };

  const checkValueBB = (obj) => {
    const values = [
      obj.market_bb,
      obj.bb.crew,
      obj.bb.status,
      obj.bb.week_day,
      obj.bb.week_number
    ];
    return values.some(val => val !== null && val !== undefined && val !== '');
  }

  // eslint-disable-next-line require-await
  const handleSearch = async (values) => {
    try {
      blockUI.current.open(true);
      const lot_review = values.lot_review ? (values.lot_review === 1 ? 1 : 0) : '';
      let market = (values.market_bb) ? values.market_bb : values.market;
      let dataSearch = { ...values, lot_review, market };
      let existsBB = checkValueBB(dataSearch);
      delete dataSearch.market_bb;
      let arr = findSearch(dataSearch);
      if (arr.length > 0) {
        customerService.getAccessToken();
        let withLog = arr.some(element => element.includes("log."));
        let sort = "-id";
        if (withLog) {
          sort = "-updated_at";
        }
        const r1 = await customerService.list(`?render=count&sort=${sort}&${arr.join('&')}`);
        if (r1.data > 0) {
          let typeLog = arr[0].includes('log');
          const condition = arr.join('&');
          const r2 = await customerService.list(`?render=paginate&sort=${sort}&${condition}&limit=50`);
          if(existsBB){
            history.push('/customer?type_lookup=bb',{process: r2.data, type: 'lookup', typeLog, condition});
          }else{
            history.push('/customer',{process: r2.data, type: 'lookup', typeLog, condition});
          }
        } else {
          dialogUI.current.open('oops', 'No customer profiles match your search parameters');
        }
      } else {
        dialogUI.current.open('oops', 'Please select at least one search parameter.');
      }
      blockUI.current.open(false);
    } catch (e) {
      dialogUI.current.open('oops', 'Has had an error, try again later.');
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.matchMedia('(min-width: 768px)').matches);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        onSubmit={(values) => {
          handleSearch(values);
        }}
      >
        {(formProps) => {
          return (
            <Form>
              <Grid container spacing={0} className={classes.gripLookups}>
                <Lookup1 formikRef={formikRef} expandedCollapse={expandedCollapse} />
                <Lookup2 formikRef={formikRef} expandedCollapse={expandedCollapse} />
                <Lookup3 formikRef={formikRef} expandedCollapse={expandedCollapse} />
                <Lookup4 formikRef={formikRef} expandedCollapse={expandedCollapse} setExpandedCollapse={setExpandedCollapse}/>
                <Lookup5 formikRef={formikRef} />
              </Grid>
              <Grid container className={classes.wrapperBtns}>
                <Grid xs={5}>
                  <Grid container>
                    <Grid xs={5}>
                      <Button
                        className={`${classes.button} ${classes.backBtn}`}
                        onClick={() => {
                          history.goBack();
                        }}
                      >
                        Back
                      </Button>
                    </Grid>
                    <Grid xs={1}/>
                    <Grid xs={5}>
                      <Button
                        className={`${classes.button} ${classes.backBtn}`}
                        onClick={() => {
                          dialogUI.current.open('', 'Re-choose search parameters');
                          formProps.resetForm();
                          setTimeout(() => {
                            window.location.reload();
                          }, 2000);
                        }}
                      >
                        Clear
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid xs={2}/>
                <Grid xs={5}>
                  <Grid container justifyContent="flex-end">
                    <Grid xs={5}>
                      <SaveSearch
                        findSearch={findSearch}
                        setTabValue={setTabValue}
                        nameBtn={(isDesktop) ? 'Save search' : 'Save'}
                      />
                    </Grid>
                    <Grid xs={1}/>
                    <Grid xs={5}>
                      <Button
                        type='submit'
                        className={`${classes.button} ${classes.searchBtn}`}
                      >
                        Search
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default Lookup;
