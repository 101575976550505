import AppService from '../AppService';

class PickItemService extends AppService {

  constructor() {
    super();
    this.path = this.getPath('pick-item');
  }

  list(params = '') {
    const queryParams = params ? params : '';
    return this.http.get(`${this.path}${queryParams}`);
  }

  listSearch(search) {
    return this.http.get(`${this.path}?${search}`);
  }

  pick(pick) {
    return this.http.get(`${this.path}/pick/${pick}`);
  }
}

export default PickItemService;
