import { Button } from '@material-ui/core';

export const columns = [
  { field: 'fullName', headerName: 'Customer', flex: 0.3, minWidth: 250 },
  { field: 'serviceAddress', headerName: 'Address', flex: 0.3, minWidth: 280 },
  { field: 'customerId', headerName: 'Account No', flex: 0.2, minWidth: 180 },
  { field: 'marketName', headerName: 'Market', flex: 0.2, minWidth: 150 },
  {
    field: 'statusName',
    headerName: 'Status',
    flex: 0.2,
    minWidth: 200,
    renderCell: (params) => (
      <strong className='prob'>
        <Button
          variant='contained'
          color='primary'
          size='small'
          style={{ marginLeft: 16, textTransform: 'none' }}
        >
          {params.value}
        </Button>
      </strong>
    ),
  },
];
