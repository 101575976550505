import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { Typography, Button, Container, Grid } from '../../components/shared/MaterialUI';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import { DataGrid } from '@material-ui/data-grid';
import { ListStyles } from '../../assets/css';
import { Pagination } from '@material-ui/lab';
import { CustomerService, TaskService } from '../../services';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import Tooltip from '@material-ui/core/Tooltip';
import AppHelper from '../../helpers/AppHelper';
import { useUI } from '../../app/context/ui';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { isUndefined as _isUndefined} from 'lodash';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';
import { columnsExportOperationsReportDb } from "../../models/db";
import ReportName from '../reports/components/ReportName';

let dlgSettings = {
  confirm: false,
  btn: {
    close: 'Close',
    confirm: '',
  },
  onConfirm: () => {},
};

const ListReport = () => {

  const [page, setPage] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [reportNameModal, setReportNameModal] = useState(false);
  const { blockUI, snackbarUI, dialogUI } = useUI();
  const location = useLocation();
  let locationState = [];
  locationState = location.state;

  const [rowsState, setRowsState] = useState({
    pageSize: 20,
    rows: [],
    rowCount: 0,
    page: 0,
  });

  const link = _isUndefined(locationState) ? '' : _isUndefined(locationState.state) ? '' : locationState.state;
  const customerService = useMemo(() => new CustomerService(), []);
  const taskService = useMemo(() => new TaskService(), []);

  const classes = useStyles();
  const listStyle = ListStyles();

  const [openButton, setOpenButton] = useState(false);
  const [listLength, setListLength] = useState(20);
  const search = '?render=paginate&sort=-id&limit=' + listLength + link;

  const columns = [
    { field: 'mapsco', headerName:'Mapsco', flex: 0.2, minWidth: 150 },
    { field: 'mapA', headerName:'Map A', flex: 0.2, minWidth: 150 },
    { field: 'mapscoGrid', headerName:'Mapsco Grid', flex: 0.2, minWidth: 190 },
    { field: 'marketName', headerName:'Market', flex: 0.2, minWidth: 200 },
    { field: 'cityName', headerName: 'City', flex: 0.2, minWidth: 200 },
    { field: 'fullName', headerName:'Customer', flex: 0.2, minWidth: 200 },
    { field: 'mowCut', headerName: 'Mow cut', flex: 0.2, minWidth: 200,
      renderCell: (params) => {
        let result = '';
        let res = params.row.mowCut;
        if (typeof res === 'string') res = JSON.parse(res)
        res.map((e) => {
          result += `${e}, `;
        });
        return result.substring(0, result.length - 2);
      },
    },
    { field: 'crew', headerName:'Crew', flex: 0.2, minWidth: 200 },
  ];

  const [columnsRender, setColumnsRender] = useState(columns);

  const getListCustomer = useCallback(
    async (newPage = 0) => {
      try {
        blockUI.current.open(true);
        const filterPage = newPage > 0 ? '&page=' + (newPage + 1) : '';
        const find = search + filterPage;
        customerService.getAccessToken();
        const r1 = await customerService.list(find);
        setLastPage(r1.data.lastPage);
        setPage(newPage);
        const dataProcess = r1.data;
        setRowsState((prev) => ({
          pageSize: dataProcess.perPage || prev.pageSize,
          rows: dataProcess.data,
          rowCount: dataProcess.total,
          page: dataProcess.currentPage - 1,
        }));
        blockUI.current.open(false);
      } catch (e) {
        blockUI.current.open(false);
        AppHelper.checkError(e, snackbarUI);
      }
    },
    [blockUI, snackbarUI, customerService, search]
  );

  const [checkboxsSvg, setCheckboxsSvg] = useState(columnsExportOperationsReportDb);

  const handleChangeCheckBoxSvg = (e) => {
    let res = checkboxsSvg.map((item) => {
      if (item.name === e.target.name) {
        return {
          ...item,
          value: e.target.checked,
        };
      } else {
        return { ...item };
      }
    });
    setCheckboxsSvg(res);
  };

  const handleGenerateSvg = async (name) => {
    try {
      blockUI.current.open(true);
      taskService.getAccessToken();
      let query = _isUndefined(locationState)
        ? '' : _isUndefined(locationState.state)
        ? '' : locationState.state;
      query = query + '&nameDoc=' + name;
      await taskService.addLookupReport(query);
      dialogUI.current.open('Success', 'The report is being processed' , dlgSettings, '', true, {route:'/report', message:'Go to report'});
      blockUI.current.open(false);
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  };

  useEffect(() => {

    setColumnsRender([]);
    let res = [];
    checkboxsSvg.filter((e)=>{
      if(e.value){
        if(e.field=='srvFrecuency'){
          res = [...res,
                 { field: e.field,
                   headerName: e.desc,
                   flex: 0.3,
                   minWidth: 400,
                   renderCell: (params) => {
                     let res;
                     switch (params.row.srvFrecuency) {
                      case 1:
                        res = 'Weekly';
                        break;
                      case 2:
                        res = 'Bi-Weekly';
                        break;
                      case 4:
                        res = 'Drought Schedule';
                        break;
                      default:
                        break;
                    }
                     return res;
                   } }];
        }else{
          res = [...res, { field: e.field, headerName: e.desc, flex: 0.3, minWidth: 400 }];
        }
      }
    });
    setColumnsRender(
      [
        ...columns,
        ...res
      ]
    )
  }, [checkboxsSvg]);

  useEffect(() => {
    (async function init() {
      await getListCustomer();
    })();
    localStorage.setItem('listClientExport', JSON.stringify([]));
  }, [getListCustomer]);

  return (
    <Container component='main' maxWidth='lg'>
      <Box mb={2}/>
      <Typography variant='h4'>Report</Typography>
      <Box mb={2}/>
      <div>
        <Grid container direction='row' alignItems='center'>
          <Grid item xs={12} sm={2}>
            <FormControl className={classes.formControl}>
              <InputLabel id="listLength">Results</InputLabel>
              <Select
                labelId="listLength"
                id="demo-simple-select"
                value={listLength}
                onChange={(e)=>{setListLength(e.target.value)}}
              >
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={classes.wrapperCheckboxSvg}>
              {checkboxsSvg.map((e, i) => (
                  <Tooltip title={e.desc} placement='top' key={'checksvg' + i}>
                    <FormControlLabel
                      className={classes.checkboxSvg}
                      value={e.name}
                      control={<Checkbox size='small' name={e.name} checked={e.value} onChange={handleChangeCheckBoxSvg} />}
                      label={e.name}
                      labelPlacement='end'
                    />
                  </Tooltip>
              ))}

              <ClickAwayListener
                onClickAway={() => {
                  setOpenButton(false);
                }}
              >
                <div className={classes.rootAwayListener}>
                  <Tooltip title='Download report' placement='top'>
                    <IconButton
                      aria-label='delete'
                      onClick={() => {
                        setOpenButton((prev) => !prev);
                      }}
                      className={classes.iconButtonSvg}
                    >
                      <GetAppIcon fontSize='small' />
                    </IconButton>
                  </Tooltip>
                  {openButton ? (
                    <div className={classes.awayDropdown}>
                      <Button
                        onClick={() => {
                          setReportNameModal(true);
                        }}
                        className={classes.iconButtonChoose}
                        variant='contained'
                        color='secondary'
                      >
                        PDF
                      </Button>
                    </div>
                  ) : null}
                </div>
              </ClickAwayListener>
            </div>
          </Grid>

          <Grid item xs={12} sm={4} style={{paddingLeft: '26px'}}>
            <Pagination
              count={lastPage}
              variant='outlined'
              defaultPage={1}
              page={page + 1}
              onChange={(event, value) => {
                (async function init() {
                  await getListCustomer(value - 1);
                })();
              }}
              shape='rounded'
            />
          </Grid>
        </Grid>
      </div>
      <div style={{ height: 580, width: '100%' }}>
        <DataGrid
          autoHeight
          headerHeight={56}
          rowHeight={56}
          columns={columnsRender}
          {...rowsState}
          rowsPerPageOptions={[listLength]}
          paginationMode='server'
          className={listStyle.dataGrid}
          onPageChange={(newPage) => {
            (async function init() {
              await getListCustomer(newPage);
            })();
          }}
        />
      </div>
      <ReportName open={reportNameModal} setOpen={setReportNameModal} handleGenerateSvg={handleGenerateSvg} />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(ListReport);

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    textTransform: 'none',
    fontSize: '13px'
  },
  checkboxSvg: {
    '& .MuiFormControlLabel-label': {
      color: '#113019',
      fontSize: '13px',
    },
  },
  wrapperCheckboxSvg: {
    border: 'solid 1px green',
    borderRadius: '10px',
    paddingLeft: '15px',
    marginBottom: '4px',
  },
  iconButtonSvg: {
    color: '#11aa39',
    backgroundColor: '#11aa391f',
    padding: '5px',
  },
  iconButtonChoose: {
    color: '#ffffff',
    padding: '5px',
    backgroundColor: '#0b0b0bcf',
    borderRadius: '16px',
    fontSize: '12px',
    height: '29px',
    fontWeight: '700',
  },
  rootAwayListener: {
    position: 'relative',
    float: 'right',
    marginRight: '22px',
    marginTop: '6px',
  },
  awayDropdown: {
    top: '36px',
    left: '-52px',
    right: '0',
    width: '138px',
    padding: '8px',
    zIndex: '1',
    position: 'absolute',
    backgroundColor: '#11aa3930',
    borderRadius: '11px',
    textAlign: 'center',
    '& button': {
      marginBottom: '5px',
    },
  },
  formControl:{
    margin: theme.spacing(1),
    minWidth: 120,
  }
}));
