import AppService from './AppService';

class CalendarService extends AppService {
  constructor() {
    super();
    this.path = this.getPath('calendar');
  }

  view(year, filter = 'all') {
    return this.http.get(`${this.path}?year=${year}&filter=${filter}`);
  }

  status(year) {
    return this.http.get(`${this.path}/${year}/status`);
  }

  update(year) {
    return this.http.get(`${this.path}/${year}/status`);
  }

  updateSeasonWeeks(year, data) {
    return this.http.patch(`${this.path}/${year}/season-weeks`, {'weeks': data});
  }
}

export default CalendarService;
