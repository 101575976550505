import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { useUI } from '../../app/context/ui';

import * as Yup from 'yup';
import store from '../../redux/store';

import {
  Container,
  Typography,
  Grid,
  Button,
  TextField,
  Select,
  MenuItem,
  Box,
  FormControl,
} from '../../components/shared/MaterialUI';

import { CreateUserStyles } from '../../assets/css';

import UserService from '../../services/UserService';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import { FormHelperText } from '@material-ui/core';
import { connect } from 'react-redux';

const CreateUser = () => {
  const [hasError, setHasError] = useState({});
  const [requestFailed, setRequestFailed] = useState(false);
  const [initialValues, setInitialValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    role: '',
    gender: 'F',
  });
  const { blockUI } = useUI();
  const history = useHistory();

  const location = useLocation();
  let id = 0;
  if(!_.isUndefined(location.state)){
    id = _.isUndefined(location.state.id) ? 0 : location.state.id;
  }

  const state = store.getState();
  const accessToken = state.user.accessToken;


  if (!accessToken) {
    history.push("/login");
  }

  const userService = new UserService();

  const userStyle = CreateUserStyles();

  useEffect(() => {
    userService.init(accessToken);
    if (id > 0) {
      getReadUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  useEffect(() => {
    if (!setRequestFailed) {
      setHasError("");
    }
  }, [requestFailed]);

  const validationSchema = Yup.object({
    firstName: Yup
      .string('Enter your first name')
      .max(50, "Max. 50 characters")
      .required('First name is required'),
    lastName: Yup
      .string('Enter your last name')
      .max(50, "Max. 50 characters")
      .required('Last name is required'),
    ...(id === 0 && {
    email: Yup
      .string('Enter your email address')
      .email("Invalid email")
      .required('Email is required'),
      password: Yup
          .string('Enter your password')
          .min(8, "Minimum 8 characters")
          .required('Password is required'),
    }),
    role: Yup
      .string('Select your role')
      .required('Role is required')
  });

  const onSubmit = async (values) => {
    try {
      blockUI.current.open(true);
      setRequestFailed(false);
      userService.init(accessToken);
      let r1;
      if (id > 0) {
        _.unset(values, 'email');
        if (!_.isUndefined(values.password)) {
          if (_.isEmpty(values.password)) {
            _.unset(values, 'password');
          }
        }
        r1 = await userService.update(values, id);
      } else {
        r1 = await userService.create(values);
      }
      blockUI.current.open(false);

      history.push("/users", {...r1.data});
    } catch (e) {
      blockUI.current.open(false);
      setRequestFailed(true);
      if (!e.response.data.errors) {
        setHasError({ message: e.response.data.message });
      }
      if (!_.isUndefined(e.response.data.errors.email)) {
        setHasError({ message: e.response.data.errors.email });
      }
      if (!_.isUndefined(e.response.data.errors.password)) {
        setHasError({ message: e.response.data.errors.password });
      }
    }
  }

  const getReadUser = async () => {
    try {
      setRequestFailed(false);

      const r1 = await userService.read(id);
      setInitialValues({
        firstName: r1.data.firstName,
        lastName: r1.data.lastName,
        email: r1.data.email,
        password: '',
        role: r1.data.role.charAt(0).toUpperCase() + r1.data.role.slice(1),
        gender: 'F',
      });
    } catch (e) {
      setRequestFailed(true);
    }
  };

  return (
    <Container component="main" maxWidth="sm" className={userStyle.ctnInner}>
      <Typography variant="h4">{id > 0 ? 'Edit' : 'Create'} User</Typography>
      <Box pb={2} />
      {requestFailed && (
        <p className={userStyle.formError}>{hasError.message}</p>
      )}
      <Box pb={2} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            handleChange,
          } = props;
          return(
            <Form autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={3} className={userStyle.grdItem}>
                  <label>First Name:</label>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    variant="outlined"
                    autoFocus
                    fullWidth
                    id="firstName"
                    name="firstName"
                    value={values.firstName}
                    onChange={handleChange}
                    error={touched.firstName && Boolean(errors.firstName)}
                    helperText={
                      errors.firstName && touched.firstName ? errors.firstName : ""
                    }
                    className={userStyle.inputCustom}
                  />
                </Grid>
                <Grid item xs={3} className={userStyle.grdItem}>
                  <label>Last Name:</label>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="lastName"
                    name="lastName"
                    value={values.lastName}
                    onChange={handleChange}
                    error={touched.lastName && Boolean(errors.lastName)}
                    helperText={
                      errors.lastName && touched.lastName ? errors.lastName : ""
                    }
                    className={userStyle.inputCustom}
                    autoComplete="nope"
                  />
                </Grid>
                <Grid item xs={3} className={userStyle.grdItem}>
                  <label>Email:</label>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="email"
                    name="email"
                    type="email"
                    value={values.email}
                    onChange={handleChange}
                    error={touched.email && Boolean(errors.email)}
                    helperText={
                      errors.email && touched.email ? errors.email : ""
                    }
                    disabled={id > 0}
                    className={userStyle.inputCustom}
                    autoComplete="nope"
                  />
                </Grid>
                <Grid item xs={3} className={userStyle.grdItem}>
                  <label>Password:</label>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="password"
                    name="password"
                    type="password"
                    value={values.password}
                    onChange={handleChange}
                    error={touched.password && Boolean(errors.password)}
                    helperText={
                      errors.password && touched.password ? errors.password : ""
                    }
                    className={userStyle.inputCustom}
                    autoComplete="nope"
                  />
                </Grid>
                <Grid item xs={3} className={userStyle.grdItem}>
                  <label>Role:</label>
                </Grid>
                <Grid item xs={9}>
                  <FormControl variant="outlined" fullWidth className={userStyle.inputCustom}>
                    <Select
                      displayEmpty
                      id="role"
                      name="role"
                      value={values.role}
                      onChange={handleChange}
                      error={touched.role && Boolean(errors.role)}
                      helperText={
                        errors.role && touched.role ? errors.role : ""
                      }
                    >
                      <MenuItem value="" disabled>Select your role</MenuItem>
                      <MenuItem value="Admin">Admin</MenuItem>
                      <MenuItem value="Manager">Manager</MenuItem>
                      <MenuItem value="Representative">Representative</MenuItem>
                    </Select>
                    <FormHelperText className={userStyle.formError}>{errors.role}</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Box pb={8}/>
              <Grid container justify="center">
                <Button
                  variant="contained"
                  size="large"
                  className={userStyle.button}
                  onClick={() => { history.push('users') }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  className={userStyle.button}
                >
                  Save
                </Button>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(CreateUser);
