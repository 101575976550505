import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  state: null,
  options: {
    id: 0,
  }
};

const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    addCustomer: (state, action) => {
      state.state = true;
      state.options = {
        ...initialState.options,
        ...action.payload
      };
    },
    removeCustomer: (state) => {
      state.state = null;
    },
    updateProperty: (state, action) => {
      state.state = true;
      state.options = {
        ...state.options,
        ...action.payload
      };
    }
  }
});

export const { addCustomer, removeCustomer, updateProperty } = customerSlice.actions;

export default customerSlice.reducer;
