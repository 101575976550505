import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
    Container,
    CssBaseline,
    Grid,
    Typography,
    Button,
    TextField,
    CardMedia,
} from "../../components/shared/MaterialUI";

import { useHistory, Link } from "react-router-dom";

import { useUI } from "../../app/context/ui";

import AuthService from "../../services/AuthService";

import logo from "../../assets/images/login-logo.png";
import {LoginStyles} from '../../assets/css';

const ForgotPassword = () => {
    const [hasError, setHasError] = useState({});
    const [requestFailed, setRequestFailed] = useState(false);

    const loginStyle = LoginStyles();

    const { blockUI } = useUI();
    const history = useHistory();

    const authService = new AuthService();

    useEffect(() => {
        if (!setRequestFailed) {
            setHasError("");
        }
    }, [requestFailed]);

    const LoginSchema = Yup.object().shape({
        email: Yup.string().email("Invalid email").required("Email is required")
    });

    const onSubmit = async (values) => {
        try {
            blockUI.current.open(true);
            setRequestFailed(false);

            // reset
            const r1 = await authService.passwordReset(values);
            blockUI.current.open(false);
            history.push("/login", {...r1.data});
        } catch (e) {
            blockUI.current.open(false);
            setRequestFailed(true);
            setHasError({ message: e.response.data.message });
        }
    };

    return (
        <Container component="main" maxWidth="lg" className={loginStyle.bgMain}>
            <Typography component="div" className={loginStyle.panForm}>
                <CssBaseline />
                <Typography component="div" className={loginStyle.alignItemsAndJustifyContent}>
                    <CardMedia
                      className={loginStyle.cardLogo}
                      component="img"
                      image={logo}
                      title="Mow Manager"
                    />
                </Typography>
                <Typography component="div">
                    {requestFailed && (
                        <p className={loginStyle.formError}>{hasError.message}</p>
                    )}
                </Typography>

                <Formik
                    initialValues={{
                        email: "",
                    }}
                    onSubmit={(values,{resetForm}) => {
                        onSubmit(values, resetForm);
                    }}
                    validationSchema={LoginSchema}
                >
                    {(props) => {
                        const {
                            values,
                            touched,
                            errors,
                            handleBlur,
                            handleChange,
                        } = props;
                        return (
                            <Form className={loginStyle.formMain} style={{top: '90px'}}>
                                <TextField
                                    margin="normal"
                                    className={classNames(loginStyle.inputEmail, loginStyle.brdInput)}
                                    required
                                    fullWidth
                                    name="email"
                                    id="email"
                                    autoComplete="email"
                                    autoFocus
                                    value={values.email}
                                    type="email"
                                    helperText={
                                        errors.email && touched.email ? errors.email : ""
                                    }
                                    error={!!(errors.email && touched.email)}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Email"
                                />

                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classNames(loginStyle.btnSubmit, loginStyle.btnSendReset)}
                                >
                                    Send Reset Link
                                </Button>

                                <Grid container justify="flex-end" className={loginStyle.gridForgotPassword}>
                                    <Grid item>
                                        <Link to="/login" variant="body2" className={loginStyle.link}>
                                            Go back to login
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Form>
                        );
                    }}
                </Formik>
            </Typography>
        </Container>
    );
};

export default ForgotPassword;
