import {makeStyles} from '@material-ui/core/styles';

export const CreateUserStyles = makeStyles((theme) => ({
  formError: {
    color: "#f44336",
    textAlign: "center"
  },
  button: {
    margin: theme.spacing(2),
    width: "200px",
    height: "40px",
    borderRadius: "4px",
    textTransform: 'none'
  },
  autoComplete: {
    width: "100%",
    marginTop: "0",
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]':{
      padding: "0px",
    },
  },
  inputCustom: {
    "& .MuiOutlinedInput-root": {
      height: "32px",
    },
    "& .MuiInputBase-input": {
      fontSize: 16,
      fontFamily: [
        'Roboto',
        'Arial'
      ].join(','),
    }
  },
  ctnInner: {
    fontFamily: [
      'Roboto',
      'Arial'
    ].join(','),
    fontWeight: "bold",
    color: "#2F3A4A"
  },
  optButton: {
    "& .MuiFormControlLabel-label": {
      fontSize: 16
    }
  },
  grdItem: {
    marginTop: 5,
  },
}));
