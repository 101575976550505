import { makeStyles } from '@material-ui/core/styles';

export const FooterStyle = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    margin: theme.spacing(2),
    textTransform: 'none',
    fontSize: '13px',
  },
}));
