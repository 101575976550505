import AppService from './AppService';

class SpecialRequestService extends AppService {

  constructor() {
    super();
    this.path = this.getPath('special-requests');
  }

  read(id) {
    return this.http.get(`${this.path}/customer/${id}`);
  }
}

export default SpecialRequestService;
