import { Divider, Grid } from '@material-ui/core';

import React, { useCallback, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { findIndex as _findIndex } from 'lodash';

import { statusDb } from '../../../models/db';
import { Box } from '../../shared/MaterialUI';
import { MarketService } from '../../../services';
import AppHelper from '../../../helpers/AppHelper';
import { useUI } from '../../../app/context/ui';
import { Utils } from '../../../helpers';
import FieldList from './FieldList';

const marketService = new MarketService();

const fieldDb = [
  {
    value: 1,
    label: 'Status',
    field: 'status',
    tag: 'drop_down',
    data: statusDb
  },
  {
    value: 2,
    label: 'Market',
    field: 'market',
    tag: 'drop_down',
    data: []
  }
];

const Lookup10 = () => {
  const {setFieldValue} = useFormikContext();
  const [field, setField] = useState(fieldDb);

  const {snackbarUI} = useUI();

  const getListMarket = useCallback(async () => {
    try {
      marketService.getAccessToken();
      const r1 = await marketService.list();
      const arr = Utils.updateArrayObjectItem(r1.data, []);
      const index = _findIndex(field, {'field': 'market'});
      fieldDb[index]['data'] = arr;
      setField(fieldDb);
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  }, [snackbarUI, field]);

  useEffect(() => {
    (async function init() {
      await getListMarket();
    })();
  }, [getListMarket]);

  useEffect(() => {
    setFieldValue('fields', field);
  }, [field, setFieldValue]);

  return (
    <Grid style={{width: '100%'}}>
      <Box mt={2}/>
      <Divider/>
      <FieldList/>
    </Grid>
  );
}
export default Lookup10;
