import AppService from './AppService';

class NoteService extends AppService {

  constructor() {
    super();
    this.path = this.getPath('notes');
  }

  create(data) {
    return this.http.post(`${this.path}`, data);
  }

  update(data, id) {
    return this.http.post(`${this.path}/${id}`, data);
  }

  delete(id) {
    return this.http.delete(`${this.path}/${id}`);
  }

  read(id) {
    return this.http.get(`${this.path}/${id}`);
  }

  list(queryParams = '') {
    const url = queryParams === '' ? this.path : `${this.path}?${queryParams}`;
    return this.http.get(url);
  }

  deleteByIds(queryParams) {
    return this.http.delete(`${this.path}?${queryParams}`);
  }
}

export default NoteService;
