import { combineReducers } from '@reduxjs/toolkit';
import dialog from './dialogSlice';
import customer from './customerSlice';
import toolbar from './toolbarSlice';
import notification from './notificationSlice';
import alarm from './alarmSlice';
import skips from './skipsSlice';
import scroll from './scrollCustomerSlice';

const uiReducers = combineReducers({
  dialog,
  customer,
  toolbar,
  notification,
  skips,
  scroll,
  alarm,
});

export default uiReducers;
