const srvFrecuencysDb = [
  {
    value: null,
    label: '-- Select --'
  },
  {
    value: 1,
    label: 'Weekly'
  },
  {
    value: 2,
    label: 'Bi-Weekly'
  },
  // {
  //   value: 3,
  //   label: '3 Week'
  // },
  {
    value: 4,
    label: 'Drought Schedule'
  },
  /*{
    value: 5,
    label: 'Bagging'
  }*/
];

export default srvFrecuencysDb;
