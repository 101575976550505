import AppService from './AppService';

class AttachmentService extends AppService {

  constructor() {
    super();
    this.path = this.getPath('attachments');
  }

  delete(id) {
    return this.http.delete(`${this.path}/${id}`);
  }

  list(search = '') {
    const url = search === '' ? this.path : `${this.path}?${search}`;
    return this.http.get(url);
  }

}

export default AttachmentService;
