import React from 'react';

import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import Header from '../Header';
import { Container } from '../shared/MaterialUI';

const ProtectedRoute = (props) => {
  const Component = props.component;
  const authToken = props.user.accessToken;

  const classes = useStyles();

  return authToken ? (
    <div className={classes.root}>
      <Header title={props.name} />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth='xl' className={classes.container}>
          <Component />
        </Container>
      </main>
    </div>
  ) : (
    <Redirect to={{ pathname: '/login' }} />
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

export default connect(mapStateToProps)(ProtectedRoute);
