import React, { useContext, useState } from 'react';
import { WeekStyle } from '../styles/WeekStyle';
import { ListItemIcon, ListItemText, MenuItem, Popover } from '@material-ui/core';
import CreateNewIcon from '@material-ui/icons/CreateNewFolderOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import HightLightOffIcon from '@material-ui/icons/HighlightOffOutlined';
import RedoIcon from '@material-ui/icons/RedoOutlined';
import { useUI } from '../../../app/context/ui';
import { useSelector } from 'react-redux';
import AppHelper from '../../../helpers/AppHelper';
import { CustomerService } from '../../../services';
import SkipModal from './SkipModal';
import WriteInModal from './WriteInModal';
import RedoModal from './RedoModal';
import { PreviousCustomerContext } from '../../../pages/customer/EditCustomer';

const customerService = new CustomerService();

const MenuService = (props) => {
  const { anchorEl, menuOpen, weekSelect, setMenuOpen } = props;
  const [openSkip, setOpenSkip] = useState(false);
  const [openWriteIn, setOpenWriteIn] = useState(false);
  const [openRedo, setOpenRedo] = useState(false);
  const { blockUI, snackbarUI } = useUI();
  const { id, weekDay } = useSelector( ({mm}) => mm.customer.options);
  const classes = WeekStyle();
  const { setUpdateServiceNote } = useContext(PreviousCustomerContext);

  const dataToSend = {
    ...weekSelect,
    status: "removed",
    year: props.year,
    customer: {
      ...weekSelect.customer,
      weekday: weekDay,
    }
  }

  let closeRemove = async() => {
    props.setMenuOpen(false);
    try {
      blockUI.current.open(true);
      customerService.getAccessToken();
      await customerService.attributes(dataToSend, id);
      setUpdateServiceNote(new Date());
      props.getDataSchedule();
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  }

  const onSkipModal = () => {
    setMenuOpen(false);
    setOpenSkip(true);
  }

  const onWriteInModal = () => {
    setMenuOpen(false);
    setOpenWriteIn(true);
  }

  const onRedoModal = () => {
    setMenuOpen(false);
    setOpenRedo(true);
  }

  const onAfterSendSkip = () => {
    setOpenSkip(false);
    props.getDataSchedule();
  };

  const onAfterSendWriteIn = () => {
    setOpenWriteIn(false);
    props.getDataSchedule();
  };

  const onAfterSendRedo = () => {
    setOpenRedo(false);
    props.getDataSchedule();
  };

  return (
    <>
      <Popover
        className={classes.popover}
        open={Boolean(menuOpen)}
        anchorEl={anchorEl}
        onClose={() => props.setMenuOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        classes={{
          paper: classes.py2
        }}
      >
        <MenuItem onClick={closeRemove}>
          <ListItemIcon className={classes.minW25}>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText primary="Remove"/>
        </MenuItem>
        <MenuItem onClick={onSkipModal}>
          <ListItemIcon className={classes.minW25}>
            <HightLightOffIcon/>
          </ListItemIcon>
          <ListItemText primary="Skip"/>
        </MenuItem>
        <MenuItem onClick={onWriteInModal}>
          <ListItemIcon className={classes.minW25}>
            <CreateNewIcon/>
          </ListItemIcon>
          <ListItemText primary="Write in"/>
        </MenuItem>
        <MenuItem onClick={onRedoModal}>
          <ListItemIcon className={classes.minW25}>
            <RedoIcon/>
          </ListItemIcon>
          <ListItemText primary="Redo"/>
        </MenuItem>
      </Popover>

      {
        (openSkip)
          &&
            <SkipModal
              open={openSkip}
              setOpen={setOpenSkip}
              dataToSend={dataToSend}
              onAfterSend={onAfterSendSkip}
            />
      }

      {
        (openWriteIn)
          &&
            <WriteInModal
              open={openWriteIn}
              setOpen={setOpenWriteIn}
              dataToSend={dataToSend}
              onAfterSend={onAfterSendWriteIn}
            />
      }
      
      {
        (openRedo)
          &&
            <RedoModal
              open={openRedo}
              setOpen={setOpenRedo}
              dataToSend={dataToSend}
              onAfterSend={onAfterSendRedo}
            />
      }
      
    </>
  );
};

export default MenuService;
