import {environment} from '../environments';
import axios from 'axios';
import {isEmpty as _isEmpty, has as _has, get as _get} from 'lodash';
import store from '../redux/store';

class AppService {

  constructor() {
    this.http = axios.create({
      baseURL: environment.apiMain,
    });

    this.http.defaults.params = {};
    this.http.interceptors.response.use(function (r) {
      return r;
    }, function (e) {
      if (
        e.response.status === 401
        && _has(e.response, 'config.url')
        && _get(e.response, 'config.url') !== undefined
        && _get(e.response, 'config.url') !== '/auth/login'
        && _get(e.response, 'config.url') !== '/web/auth/login'
      ) {
        const state = store.getState();
        const role = state.user.role;
        window.location = role === 'customers' ? '/web/login?et=1' : '/login?et=1';
      } else {
        return Promise.reject(e);
      }
    });
  }

  init(accessToken = '') {
    this.setAccessToken(accessToken);
  }

  setAccessToken(accessToken) {
    if (!_isEmpty(accessToken)) {
      this.http.defaults.headers.common['Authorization'] = 'bearer ' + accessToken;
    } else {
      delete this.http.defaults.headers.common['Authorization'];
    }
  }

  getAccessToken() {
    const state = store.getState();
    const accessToken = state.user.accessToken;
    return this.init(accessToken);
  }

  reset() {
    delete this.http.defaults.headers.common['Authorization'];
    this.http.defaults.params = {};
  }

  getOptions() {
    return this.http.defaults.headers;
  }

  getPath(service) {
    return '/' + service;
  }

  logout() {
    return this.http.post('/auth/logout');
  }
}

export default AppService;
