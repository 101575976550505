const cardTypeDb = [
  {
    value: 4,
    label: 'Visa'
  },
  {
    value: 5,
    label: 'Mastercard'
  },
  {
    value: 3,
    label: 'American Express'
  },
  {
    value: 6,
    label: 'Discover'
  },
  {
    value: null,
    label: 'E-Check'
  },
];

export default cardTypeDb;
