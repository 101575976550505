import React, { useContext } from 'react';

import { Formik } from 'formik';
import { includes as _includes, omit as _omit } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';

import { useUI } from '../../../../app/context/ui';
import { Container } from '../../../../components/shared/MaterialUI';
import { WebService } from '../../../../services';
import { SignUpCustomerContext } from '../SignUpProcess.js';
import BillingInfo from './components/BillingInfo';
import {
  cardSchema, customerSchema, eCheckSchema,
} from './components/components/validationSchema';
import CustomerInfo from './components/CustomerInfo';
import { SignUpStyles } from './styles/signUp-style';

const SignUpCustomer = () => {
  const { state } = useContext(SignUpCustomerContext);
  const [value, setValue] = React.useState(0);

  const { blockUI, snackbarUI } = useUI();
  const webService = new WebService();
  const history = useHistory();
  const classes = SignUpStyles();

  const params = useLocation();
  const { pathname } = params;
  const isBilling = _includes(pathname, 'billing');

  const handleSubmit = async () => {
    if (isBilling) {
      let payload = _omit(state, [
        'zipCodeValid',
        'isFlowerBedsDamage',
        'scheduleService',
        'disclaimerAboveGroundPool',
        'disclaimerFencePlastic',
        'disclaimerTrampoline',
        'zipCode',
        'verifyAccountNumber',
      ]);
      try {
        blockUI.current.open(true);
        const r1 = await webService.create(payload);
        if (r1.data.message === 'customer was created successfully') {
          history.push('/web/success');
        }
        blockUI.current.open(false);
      } catch (e) {
        snackbarUI.current.open('There was an error');
        blockUI.current.open(false);
      }
    } else {
      history.push(`/web/sign-up-process-billing`);
    }
  };

  const props = { value, setValue };

  return (
    <Container maxWidth='lg' className={classes.container}>
      <Formik
        initialValues={state}
        validateOnChange={isBilling ? true : false}
        validationSchema={isBilling ? (value === 0 ? cardSchema : eCheckSchema) : customerSchema}
        onSubmit={() => {
          handleSubmit();
        }}
      >
        {(isBilling && <BillingInfo {...props} />) || (!isBilling && <CustomerInfo />)}
      </Formik>
    </Container>
  );
};

export default SignUpCustomer;
