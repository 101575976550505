import React, { useCallback, useEffect, useState } from 'react';
import { Container, Divider, IconButton, InputLabel, Modal } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { Field, useFormikContext } from 'formik';
import { isUndefined as _isUndefined, toNumber as _toNumber, find as _find, replace as _replace } from 'lodash';
import NumberFormat from 'react-number-format';

import { Alert, Box, Button, Grid, makeStyles } from '../../../components/shared/MaterialUI';
import { WizardStyles, LoginStyles } from '../../../assets/css';
import { CheckboxControl, InputField, RadioField, SelectField } from '../../../forms';
import { BusinessLogicService, LogicService } from '../../../services';

import { Utils } from '../../../helpers';
import { srvFrecuencysDb } from '../../../models/db';
import { useUI } from '../../../app/context/ui';
import AppHelper from '../../../helpers/AppHelper';

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: 300,
    flexGrow: 1,
    minWidth: 300,
    transform: 'translateZ(0)',
    '@media all and (-ms-high-contrast: none)': {
      display: 'none',
    },
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const businessLogicService = new BusinessLogicService();
const logicService = new LogicService();

const Step3 = (props) => {
  const [billingLotSizes, setBillingLotSizes] = useState([]);
  const [srvCost, setSrvCost] = useState({});
  const [srvFrec, setSrvFrec] = useState(1);
  const [open, setOpen] = useState(false);
  const [disabledBagGrass, setDisabledBagGrass] = useState(false);

  const {blockUI, snackbarUI} = useUI();

  const {values: formValues} = useFormikContext();
  const {lotSize, reasonEdit} = formValues;
  const [modalStyle] = useState(getModalStyle);
  const classes = useStyles();
  const {
    formField: {
      costService,
      billingLotSize,
      reasonSize,
      srvFrecuency,
      grassOvergrown,
      mowCut,
      calculatedTax,
      totalMowing,
      specialReq,
    }
  } = props;

  const {formikRef} = props;

  const wizardStyle = WizardStyles();
  const loginStyle = LoginStyles();

  const getBillingLotSize = useCallback(async () => {
    try {
      blockUI.current.open(true);
      businessLogicService.getAccessToken();
      const r1 = await businessLogicService.list();
      const arrLot = Utils.updateArrayObjectItem(r1.data,
        [{value: null, label: '-- Select --'}]);
      setBillingLotSizes(arrLot);
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  }, [snackbarUI, blockUI]);

  const setServiceCost = (value, key, isEdit = false, reasonEdit=false) => {
    const srv = value;
    setSrvCost(srv);
    if (formikRef.current && (isEdit || !reasonEdit)) {
      const val = _toNumber(_replace(srv[`f${key}`], '$', ''));

      formikRef.current.setFieldValue(
          'costService', val
      );
      formikRef.current.setFieldValue(
          'reasonEdit', reasonEdit
      );
    }
  };

  const getFindLotSize = useCallback(async () => {
    try {
      blockUI.current.open(true);
      businessLogicService.getAccessToken();
      const r1 = await businessLogicService.findLotSize(lotSize);
      let newLotSize = r1.data;
      if (formikRef.current && !reasonEdit) {
        formikRef.current.setFieldValue(
          'billingLotSize', r1.data.id
        );
        formikRef.current.setFieldValue(
          'srvFrecuency', 1
        );
      }

      if (!_isUndefined(formValues.billingLotSize)) {
        if (formValues.billingLotSize !== '') {
          const nLotSize = _find(billingLotSizes, {'value': formValues.billingLotSize});
          if (!_isUndefined(nLotSize)) {
            newLotSize = nLotSize;
          }
        }
      }

      setServiceCost(JSON.parse(newLotSize.serviceFrequency), 1, false);
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(true);
      AppHelper.checkError(e, snackbarUI);
    }
  }, [blockUI, snackbarUI, billingLotSizes, formValues, formikRef, lotSize, reasonEdit, setServiceCost]);


  const handleChangeSrvFrc = (event) => {
    if (event.target.value > 0) {
      setSrvFrec(event.target.value);
      setServiceCost(srvCost, event.target.value, true);
    }
  }

  const handleChangeLotSize = (event) => {
    const lotSize = _find(billingLotSizes, {'value': event.target.value});
    if (!_isUndefined(lotSize)) {
      setServiceCost(JSON.parse(lotSize.serviceFrequency), srvFrec, true, true);
    }
  }

  const handleOpen = (e) => {
    if (e.target.value === 'Yes') {
      setOpen(true);
    }
  }

  const handleClose = () => {
    setOpen(false);
  }

  const setUpdatePrice = useCallback(async () => {

    if(formValues.costService && formValues.srvFrecuency){
      logicService.getAccessToken();
      let find = '';
      find += `costService=${formValues.costService}`;
      find += `&lotSize=${_toNumber(formValues.lotSize)}`;
      find += `&turfSize=${_toNumber(formValues.turfSize)}`;
      find += `&taxRate=${_toNumber(formValues.taxRate)}`;
      find += `&srvFrequency=${formValues.srvFrecuency}`;
      find += `&isBagging=${formValues.mowCut.includes('BagGrass')}`;
      find += `&businesslogicId=${_toNumber(formValues.billingLotSize)}`;

      const {data: dataBaggrass} = await logicService.proccessBaggrass(find);

      formikRef.current?.setFieldValue('subtotalMowing', dataBaggrass.newSubtotal);
      formikRef.current?.setFieldValue('totalMowing', dataBaggrass.total);
      formikRef.current?.setFieldValue('calculatedTax', dataBaggrass.tax);
    }
  }, [formValues, formikRef]);

  const handleDisabledBagGrass = () => {
    if(Number(formValues.srvFrecuency) === 4){
      const mowCutWithoutBagGrass = formValues.mowCut.filter((item) => item !== "BagGrass");
      formikRef.current?.setFieldValue("mowCut", mowCutWithoutBagGrass);
      setDisabledBagGrass(true);
    }else{
      setDisabledBagGrass(false);
    }
  };

  useEffect(() => {
    (async function init() {
      await getBillingLotSize();
    })();
  },[]);

  useEffect(() => {
    (async function init() {
      await getFindLotSize();
    })();
  },[]);

  useEffect(() => {
    handleDisabledBagGrass();
  }, [formValues.srvFrecuency]);

  useEffect(() => {
    if (formikRef.current) {
      (async function init() {
        await setUpdatePrice();
      })();
    }
  }, [formValues.costService]);

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <Grid container spacing={1}>
        <Grid item xs={11}>
          <h2 id="simple-modal-title">Inform your customer</h2>
        </Grid>
        <Grid item xs={1}>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon/>
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <p id="simple-modal-description">
            We will activate your account after our new customer department has reviewed and accepted all information
            submitted. Any changes will be called to you quickly.
          </p>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleClose}
            className={loginStyle.btnSubmit}
          >
            Close
          </Button>
        </Grid>
      </Grid>
    </div>
  );

  return (
    <Container component="main" maxWidth="lg">
      <span className={wizardStyle.title}>Cost information for the customer</span>
      <Divider/>
      <Box my={2}/>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Box border={1} borderColor={'#E8E8E8'} p={1} my={1}>
            <InputLabel id="demo-mutiple-checkbox-label" className={wizardStyle.label}>
              The cost for the service is:
            </InputLabel>
            <InputField name={costService.name} InputProps={{inputComponent: NumberFormat}} fullWidth/>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box border={1} borderColor={'#E8E8E8'} p={1} my={1}>
            <Alert severity="info" color="info" className={wizardStyle.alert}>
              This is to MOW with mulching mower the grass areas, edge concrete areas, them blow clean.
            </Alert>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <span className={wizardStyle.title}>Start a subscription</span>
          <Divider/>
        </Grid>
        <Grid item xs={12}>
          <label className={wizardStyle.label}>Billing information:</label>
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputLabel className={wizardStyle.label}>Billing lot size adjustment</InputLabel>
          <SelectField
            name={billingLotSize.name}
            data={billingLotSizes}
            onClick={handleChangeLotSize}
            autoFocus
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputLabel className={wizardStyle.label}>Reason for size adjustment</InputLabel>
          <InputField name={reasonSize.name} fullWidth/>
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputLabel className={wizardStyle.label}>Select service frequency</InputLabel>
          <SelectField
            name={srvFrecuency.name}
            data={srvFrecuencysDb}
            onClick={handleChangeSrvFrc}
            autoFocus
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputLabel className={wizardStyle.label}>Is your grass overgrown?</InputLabel>
          <Alert severity="info" color="info" className={wizardStyle.alert}>
            Priority Mow charge double & pay x2
          </Alert>
        </Grid>
        <Grid item xs={12} sm={4}>
          <br/>
          <InputLabel className={wizardStyle.label}>What to mow?</InputLabel>
        </Grid>
        <Grid item xs={12} sm={4}>
          <br/>
          <InputLabel className={wizardStyle.label}>Calculated tax</InputLabel>
        </Grid>
        <Grid item xs={12} sm={4}>
          <RadioField row name={grassOvergrown.name} onChange={handleOpen}/>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Field component={CheckboxControl} name={mowCut.name} value="Front" label="Front"/>
          <Field component={CheckboxControl} name={mowCut.name} value="Back" label="Back"/>
          <Field component={CheckboxControl} name={mowCut.name} value="BagGrass" label="Bag grass" onChange={setUpdatePrice} disabled={disabledBagGrass}/>
          <Field component={CheckboxControl} name={mowCut.name} value="DuplexRight" label="Duplex right"/>
          <Field component={CheckboxControl} name={mowCut.name} value="DuplexLeft" label="Duplex left"/>
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputField name={calculatedTax.name} InputProps={{inputComponent: NumberFormat}} fullWidth disabled/>
        </Grid>
        <Grid item xs sm={4}>
          <InputLabel className={wizardStyle.label}>Total Mowing price</InputLabel>
          <InputField name={totalMowing.name} InputProps={{inputComponent: NumberFormat}} fullWidth/>
        </Grid>
        <Grid item xs sm={8}>
          <InputLabel className={wizardStyle.label}>Special request</InputLabel>
          <InputField name={specialReq.name} multiline fullWidth/>
        </Grid>
        <Grid item xs={12} sm={4}>
          <div>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              {body}
            </Modal>
          </div>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Step3;
