import React, { useState } from 'react';

import clsx from 'clsx';
import { upperFirst } from 'lodash';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import {
  Badge, Box, Grid, Menu,
  MenuItem, Tooltip,
} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {
  AccountCircleOutlined, ChevronRight, ExpandMore,
} from '@material-ui/icons';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import NotificationsIcon from '@material-ui/icons/Notifications';

import { useUI } from '../app/context/ui';
import { toolbarDrawer } from '../app/store/mm/toolbarSlice';
import { HeaderStyles } from '../assets/css';
import logo from '../assets/images/logo.png';
import AppHelper from '../helpers/AppHelper';
import MainListItems from '../navigation/MainListItems';
import NotificationTodo from './alarms/NotificationTodo';
import { Avatar, CssBaseline } from './shared/MaterialUI';

const Header = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const { snackbarUI } = useUI();
  const dispatch = useDispatch();
  const badgeContent = useSelector(({ mm }) => mm.notification.count) ?? 0;
  const open = useSelector(({ mm }) => mm.toolbar.toggleDrawerOpen) ?? false;

  const history = useHistory();
  const classes = HeaderStyles();

  const firstName = props.user.firstName;
  const title = props.title;

  const menuId = 'primary-search-account-menu';

  const location = useLocation();
  const currentPath = location.pathname;

  const logout = () => {
    try {
      // appService.setAccessToken(accessToken);
      // await appService.logout();
      props.dispatch({ type: 'LOGOUT' });
      history.push('/login');
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        onClick={() => {
          logout();
        }}
      >
        Logout
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <CssBaseline />
      <AppBar position='absolute' className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge='start'
            color='inherit'
            aria-label='open drawer'
            onClick={() => {
              dispatch(toolbarDrawer());
            }}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <Avatar alt='Mow Managers' src={logo} />
            {open ? <ChevronLeftIcon /> : <ChevronRight />}
            <Divider orientation='vertical' flexItem />
          </IconButton>
          <Typography variant='h6' className={classes.title}>
            {currentPath !== '/edit-customer' && upperFirst(title)}
            {(currentPath === '/edit-customer' || currentPath === '/payment-list') && (
              <IconButton
                edge='end'
                aria-label='account of current user'
                aria-controls={menuId}
                aria-haspopup='true'
                color='inherit'
                onClick={() => {
                  history.goBack();
                }}
              >
                <KeyboardBackspaceIcon />
                <span style={{ fontSize: '20px', lineHeight: '20px' }}> Back</span>
              </IconButton>
            )}
          </Typography>

          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Tooltip title='Open notifications'>
              <IconButton size='medium' color='inherit' onClick={handleOpenUserMenu}>
                <Badge badgeContent={badgeContent} color='error' overlap='circular'>
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            </Tooltip>
            <NotificationTodo anchorElUser={anchorElUser} setAnchorElUser={setAnchorElUser} />
          </Box>

          <Box sx={{ display: { xs: 'flex' }, alignItems: 'center' }}>
            <span>
              Hi <b>{firstName}!</b>
            </span>
            {/* <span className={wizardStyle.title}>{firstName}!</span> */}
            <IconButton
              edge='end'
              aria-label='account of current user'
              aria-controls={menuId}
              aria-haspopup='true'
              color='inherit'
            >
              <AccountCircleOutlined />
            </IconButton>
            <IconButton
              edge='end'
              aria-label='account of current user'
              aria-controls={menuId}
              aria-haspopup='true'
              onClick={handleProfileMenuOpen}
              color='inherit'
            >
              <ExpandMore />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant='permanent'
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
        color='primary'
      >
        <div className={classes.toolbarIcon}>
          {open && (
            <Grid container alignItems='center'>
              <IconButton
                onClick={() => {
                  dispatch(toolbarDrawer());
                }}
              >
                <Avatar alt='Mow Managers' src={logo} />
                {open ? <ChevronLeftIcon style={{ color: '#FFFFFF' }} /> : <ChevronRight />}
                <Divider style={{ background: '#FFFFFF' }} orientation='vertical' flexItem />
              </IconButton>
              <IconButton
                onClick={() => {
                  dispatch(toolbarDrawer());
                }}
              >
                <ArrowBackIcon style={{ color: '#FFFFFF', fontSize: '18px' }} />
                <span style={{ margin: '2px', color: '#FFFFFF', fontSize: '20px' }}>Overview</span>
              </IconButton>
            </Grid>
          )}
        </div>
        <Divider />
        <List color='primary'>
          <MainListItems open={open} />
        </List>
      </Drawer>
      {renderMenu}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    mm: state.mm,
  };
};

export default connect(mapStateToProps)(Header);
