import * as React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconUI from "./NavaIcon";

import { matchPath, useHistory, useLocation } from 'react-router-dom';
import {Collapse, Divider, List, Tooltip} from "@material-ui/core";
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { menuChildren } from '../app/store/mm/toolbarSlice';
import { connect } from 'react-redux';

const ListItemStyle = makeStyles((theme) => ({
  root: {
    '&$selected': {
      backgroundColor: theme.palette.secondary.main,
      color: 'white',
      '& .MuiListItemIcon-root': {
        color: 'white'
      }
    },
    '&$selected:hover': {
      backgroundColor: '#C8DBCAB2',
      color: 'white',
      '& .MuiListItemIcon-root': {
        color: 'white'
      }
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: 'white',
      '& .MuiListItemIcon-root': {
        color: 'white'
      }
    }
  },
  selected: {},
  item: {
    '& .MuiListItemText-primary':{
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '130px'
    }
  }
}));

const MainListItems = (props) => {

  const navigation = props.user?.navigation || [];

  return (
    <div>
        {navigation.map((nav, i) => {
            return(
                <ChildrenItems key={i} menu={nav} {...props} />
            );
        })}
    </div>
  );
}

const ChildrenItems = (props) => {
    const { pathname } = useLocation();
    const match = (path) => (path ? !!matchPath( pathname, { path , end: false }) : false);
    const history = useHistory();
    const {menu, open} = props;
    const dispatch = useDispatch();

    const openMenu = useSelector(({mm}) => mm.toolbar.menuChildrenOpen) ?? false;
    const childrenId = useSelector(({mm}) => mm.toolbar.menuChildrenId) ?? '';

    const listItemStyle = ListItemStyle();

    return (
        <div>
          <ListItem
            button key={menu.id}
            selected={match(menu.url)}
            onClick={() => {dispatch(menuChildren(menu.id))}}
            classes={{ root: listItemStyle.root, selected: listItemStyle.selected }}
            >
            <Tooltip title={menu.title} placement="right">
              <ListItemIcon>
                <IconUI icon={menu.icon} style={{fontSize: 'xx-large'}}
                        onClick={() => { history.push(menu.url)}}/>
              </ListItemIcon>
            </Tooltip>
            <Tooltip title={menu.title} placement="right">
              <ListItemText primary={menu.title} onClick={() => { history.push(menu.url)}} className={listItemStyle.item}/>
            </Tooltip>
            {menu.children && ( (openMenu && childrenId === menu.id) ? <ExpandLess/> : <ExpandMore/>)}
          </ListItem>
            {menu.children && (
          <Collapse
              key={menu.children.id}
              in={open && openMenu && childrenId === menu.id}
              timeout='auto'
              unmountOnExit
          >
            <List component='li' disablePadding key={menu.id}>
              {menu.children.map(children => {
                return (
                    <ListItem button key={children.id}
                              selected={match(children.url)}
                              classes={{ root: listItemStyle.root, selected: listItemStyle.selected }}
                    >
                      <ListItemIcon>
                        {/* <InsertDriveFileTwoToneIcon /> */}
                      </ListItemIcon>
                      <ListItemText key={children.id} primary={children.title}
                                    onClick={() => { 
                                      history.push(children.url, {state: null})
                                      }}/>
                    </ListItem>
                );
              })}
            </List>
          </Collapse>
            )}
          <Divider />
        </div>
    )
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(MainListItems);
