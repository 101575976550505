import {  useState, useEffect } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid } from "@material-ui/data-grid";
import clsx from "clsx";

import { Update } from "./components/Update";
import { ListStyles } from "../../assets/css";
import PriceModal from "./components/PriceModal";
import PriceService from "../../services/PriceService";
import { useUI } from "../../app/context/ui";
import store from "../../redux/store";
import AppHelper from "../../helpers/AppHelper";

const columns = [
  { field: "joinDate", headerName: "Date / Time", flex: 0.4 },
  { field: "amount", headerName: "Amount", width: 150 },
  { field: "typeOperation", headerName: "Type", width: 150 },
  { field: "fullName", headerName: "Represantive", width: 150 },
  { field: "detail", headerName: "Details", flex: 0.3 },
];

const priceDetailAdapter = (data) => {
  return data.map((item) => {
    return {
      id: item.id,
      joinDate: item.joinDate,
      amount: `$ ${item.amount}`,
      typeOperation: item.typeOperation,
      fullName: item?.user?.fullName,
      detail: item.detail,
    };
  });
};

export const UpdatePrice = () => {

  const [rows, setRows] = useState([]);
  const [openSelect, setOpenSelect] = useState(false);
  const [priceSelected, setPriceSelected] = useState(null);

  const { blockUI, snackbarUI } = useUI();
  const state = store.getState();
  const accessToken = state.user.accessToken;

  if (!accessToken) {
    history.push("/login");
  }

  const priceService = new PriceService();
  const classes = useStyles();
  const listStyle = ListStyles();

  const getData = async () => {
    try {
      blockUI.current.open(true);
      priceService.init(accessToken);
      const res = await priceService.getList();
      setRows(priceDetailAdapter(res.data));
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  useEffect(() => {
    getData();
	// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box display="flex" flexDirection="column" paddingX="12px" gridGap={24}>
      <Typography variant="h4" component="h1">
        Price Bulk Update
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Update getData={getData} />
		</Grid>
      </Grid>
      <Typography variant="h4" component="h1" className={classes.title}>
        Price Updates
      </Typography>
      <div style={{ height: 580, width: "100%" }}>
        <DataGrid
          className={clsx(listStyle.dataGrid, classes.root)}
          rows={rows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          onRowClick={(params) => {
            setPriceSelected(params.row);
            setOpenSelect(true);
          }}
        />
      </div>
      {openSelect && (
        <PriceModal open={openSelect} setOpen={setOpenSelect} priceSelected={priceSelected} getData={getData} />
      )}
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: 24,
  },
  button: {
    margin: theme.spacing(1),
    textTransform: "none",
  },
  root: {
    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
      {
        display: "none",
      },
  },
}));
