import React, { useContext } from 'react';

import {
  Select, Typography,
} from '../../../../../../components/shared/MaterialUI';
import { SignUpCustomerContext } from '../../../SignUpProcess';
import { SignUpStyles } from '../../styles/signUp-style';

const HearBoutUs = () => {
  const { state, dispatch } = useContext(SignUpCustomerContext);

  const classes = SignUpStyles();

  const webSearch = [
    {
      id: 1,
      picklistId: 1,
      name: 'Google',
      description: null,
      status: 1,
      order: 1,
    },
    {
      id: 2,
      picklistId: 1,
      name: 'Yahoo',
      description: null,
      status: 1,
      order: 2,
    },
    {
      id: 3,
      picklistId: 1,
      name: 'Bing',
      description: null,
      status: 1,
      order: 3,
    },
    {
      id: 4,
      picklistId: 1,
      name: 'Other',
      description: null,
      status: 1,
      order: 4,
    },
  ];

  const socialMedia = [
    {
      id: 5,
      picklistId: 2,
      name: 'Facebook',
      description: null,
      status: 1,
      order: 1,
    },
    {
      id: 6,
      picklistId: 2,
      name: 'Instagram',
      description: null,
      status: 1,
      order: 2,
    },
    {
      id: 7,
      picklistId: 2,
      name: 'Next Door',
      description: null,
      status: 1,
      order: 3,
    },
  ];

  const referral = [
    {
      id: 8,
      picklistId: 4,
      name: 'Friend',
      description: null,
      status: 1,
      order: 1,
    },
    {
      id: 9,
      picklistId: 4,
      name: 'Neighbor',
      description: null,
      status: 1,
      order: 2,
    },
    {
      id: 10,
      picklistId: 4,
      name: 'Lawntech  Dallas',
      description: null,
      status: 1,
      order: 3,
    },
    {
      id: 11,
      picklistId: 4,
      name: 'Go Mow',
      description: null,
      status: 1,
      order: 4,
    },
    {
      id: 12,
      picklistId: 4,
      name: 'Law Co.',
      description: null,
      status: 1,
      order: 5,
    },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch({
      type: 'setTextFields',
      payload: { [name]: parseInt(value) },
    });
  };

  return (
    <>
      <Select native className={classes.select} onChange={(e) => handleChange(e)} inputProps={{ name: 'about' }}>
        <option value='' defaultValue>
          -- Select --
        </option>
        <option value={1}>Web search</option>
        <option value={2}>Social media</option>
        <option value={3}>Mail card</option>
        <option value={4}>Referral</option>
        <option value={9}>TV AD</option>
        <option value={5}>I am a current customer adding a new property</option>
      </Select>
      {state && state.about === 1 ? (
        <>
          <Typography variant='body1' className='textLabels'>
            Web search
          </Typography>
          <Select
            native
            className={classes.select}
            onChange={(e) => handleChange(e)}
            inputProps={{ name: 'aboutOption' }}
          >
            <option value='' defaultValue>
              -- Select --
            </option>
            {webSearch.map((search, index) => {
              return (
                <option key={index} value={search.id}>
                  {search.name}
                </option>
              );
            })}
          </Select>
        </>
      ) : state.about === 2 ? (
        <>
          <Typography variant='body1' className='textLabels'>
            Social Media
          </Typography>
          <Select
            native
            className={classes.select}
            onChange={(e) => handleChange(e)}
            inputProps={{ name: 'aboutOption' }}
          >
            <option value='' defaultValue>
              -- Select --
            </option>
            {socialMedia.map((social, index) => {
              return (
                <option key={index} value={social.id}>
                  {social.name}
                </option>
              );
            })}
          </Select>
        </>
      ) : state.about === 4 ? (
        <>
          <Typography variant='body1' className='textLabels'>
            Referral
          </Typography>
          <Select
            native
            className={classes.select}
            onChange={(e) => handleChange(e)}
            inputProps={{ name: 'aboutOption' }}
          >
            <option value='' defaultValue>
              -- Select --
            </option>
            {referral.map((referral, index) => {
              return (
                <option key={index} value={referral.id}>
                  {referral.name}
                </option>
              );
            })}
          </Select>
        </>
      ) : null}
    </>
  );
};

export default HearBoutUs;
