import AppService from './AppService';

class InvoiceService extends AppService {
  constructor() {
    super();
    this.path = this.getPath('invoices');
  }

  listInvoices(customer, params = '') {
    const queryParams = params ? params : '';
    return this.http.get(`${this.path}${queryParams}`);
  }

  getInvoices({ page = 1, limit = 50, sort= '-id'}) {
    return this.http.get(
      `${this.path}?render=paginate&sort=${sort}&limit=${limit}&page=${page}&include=customers`
    );
  }

  paginationInvoices(url, page) {
    return this.http.get(`${this.path}?${url}&page=${page}&include=customers`);
  }

}

export default InvoiceService;
