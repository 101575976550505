import React from 'react'
import AlarmManager from './AlarmManager';
import DetailAlarm from './DetailAlarm';
import ViewBasic from './ViewBasic';

const Alarms = (props) => {

  return (
    <>
      <AlarmManager
        handleRemoveAlarm={props.handleRemoveAlarm}
        setOpenAlarmView={props.setOpenAlarmView}
        setdataAlarmDetail={props.setdataAlarmDetail}
        handleUpdateDataAlarm={props.handleUpdateDataAlarm}
        handleAddedAlarm={props.handleAddedAlarm}
        />
      <ViewBasic
        handleUpdateStatusAlarm={props.handleUpdateStatusAlarm}
        handleUpdateDataAlarm={props.handleUpdateDataAlarm}
        setOpenAlarmView={props.setOpenAlarmView}
        setdataAlarmDetail={props.setdataAlarmDetail}
      />
      <DetailAlarm
        openAlarmView={props.openAlarmView}
        setOpenAlarmView={props.setOpenAlarmView}
        dataAlarmDetail={props.dataAlarmDetail}
        handleUpdateDataAlarm={props.handleUpdateDataAlarm}
      />
    </>
  )
}

export default Alarms;
