import React, { memo, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Box, Button, Grid, Modal, IconButton } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import CloseIcon from '@material-ui/icons/Close';

import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { isEmpty as _isEmpty, has as _has } from 'lodash';
import clsx from 'clsx';
import format from 'date-fns/format';
import { serialize } from 'object-to-formdata';

import { WizardStyles } from '../../../assets/css';
import { NoteService } from '../../../services';
import AppButton from '../../forms/AppButton';
import { AppStyle } from '../../../assets/css/app/AppStyle';
import Header from './Header';
import NoteForm from './NoteForm';
import NoteTab from './NoteTab';
import { useUI } from '../../../app/context/ui';
import moment from 'moment';
import dayjs from "dayjs";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const formState = {
  id: 0,
  customerAttributesId: '',
  serviceDate: '',
  serviceHour: '',
  description: '',
  disabled: false,
  typeId: '',
  temporary: false,
  attachments: [],
  customerManager: '',
  periodHour: '',
};

const noteService = new NoteService();

const NoteModal = (props) => {
  const { open, setOpen, user, onAfterSend, type } = props;
  const [error, setError] = useState(false);
  const [withAttachments, setWithAttachments] = useState(true);
  const [loading, setLoading] = useState({ state: false, message: 'Saving...' });
  const [initialValues, setInitialValues] = useState(formState);
  const [modalStyle] = useState(getModalStyle);
  const appStyle = AppStyle();
  const wizardStyle = WizardStyles();
  const { dialogUI } = useUI();

  const [infoCheck, setInfoCheck] = useState([
    {name:'infoA', label:'Wanted a 1 Time Mow', active:false},
    {name:'infoB', label:'Just Shopping', active:false},
    {name:'infoC', label:'Not In Service Area', active:false}
  ]);

  const validationSchema = Yup.object({
    serviceDate: Yup.string().nullable().required('Field is required'),
    serviceHour: Yup.string()
      .nullable()
      .required('Field is required'),
    description: (infoCheck[0]?.active || infoCheck[1]?.active ||  infoCheck[2]?.active) ? Yup.string().min(3, 'Minimum 3 characters')  : Yup.string().min(3, 'Minimum 3 characters').required('Field is required')
  });

  noteService.init(user.accessToken);

  const onDelete = async (id) => {
    try {
      dialogUI.current.close();
      await noteService.delete(id);
      onAfterSend('OK', 'Your note has been deleted.');
      setError(false);
    } catch (e) {
      setError(e.message);
    }
  };

  const onEdit = (disabled) => {
    setInitialValues({ ...initialValues, disabled});
  };

  const onBlock = (state) => {
    setLoading({...loading, state});
  };

  const onSend = async (values) => {
    try {
      onBlock(true);
      const date = values.serviceDate instanceof Date ? values.serviceDate : new Date(values.serviceDate);
      values.serviceDate = format(date, 'yyyy-MM-dd');
      const id = values.id || 0;
      if (id !== 0) {
        values._method = 'PATCH';
      }
      let message = id != 0 ? 'Your note has been updated.' : 'Your note has been created.';

      let serviceHour = values.serviceHour;
      if (serviceHour instanceof dayjs) {
        serviceHour = serviceHour.format('HH:mm');
      }else{
        serviceHour = moment(serviceHour, 'MM/DD/YYYY hh:mm A').format('HH:mm');
      }
      const formData = serialize({...values, serviceHour});

      id !== 0 ? await noteService.update(formData, id) : await noteService.create(formData);
      infoCheck.map(async (e)=>{
        if(e.active){
          const date = new Date(values.serviceHour);
          const serviceHour = format(date, 'HH:mm');
          const formData = serialize({
            ...values, 
            description: e.label, 
            serviceHour,
            typeId: 2
          });
          await noteService.create(formData);
        }
      });
      onBlock(false);
      onAfterSend('OK', message);
      setError(false);
    } catch (e) {
      onBlock(false);
      setError(e.message);
    }
  };

  const getDataReset = () => {
    const m = moment();
    const serviceDate = m.toDate();
    const serviceHour = m.format('MM/DD/YYYY hh:mm A');
    const temporary = false;
    return { serviceDate, serviceHour, temporary };
  };

  const updateForm = useCallback(() => {
    // General by default
    let noteTypeId = 1;
    switch (props.type) {
      case 'private_note':
        noteTypeId = 2;
        break;
      case 'special_request':
        noteTypeId = 3;
        break;
      case 'rejected_note':
        noteTypeId = 4;
        break;
    }

    const resetData = getDataReset();
    const customerAttributesId = props.mm.customer.options.id;
    let partialValues = { ...resetData, customerAttributesId, typeId: noteTypeId };
    if (!_isEmpty(props.entity)) {
      partialValues = { ...partialValues, ...props.entity };
    }

    //setInitialValues((initialValues) => ({ ...initialValues, ...partialValues }));
    setInitialValues({ ...formState, ...partialValues });
  }, [props]);

  useEffect(() => {
    if (_has(props, 'open')) {
      setOpen(props.open);
    }
    updateForm();
    (type=='rejected_note')&&setWithAttachments(false);
  }, [props, setOpen, updateForm]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
    >
      <div style={modalStyle} className={wizardStyle.paperModalFull}>
      <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => onSend(values)}
          enableReinitialize={true}
        >
          {(props) => {
            const { values, handleSubmit } = props;
            return (
              <Form autoComplete="off">
                <Grid container alignItems="center" spacing={0}>
                  <Grid container item xs={12}>
                    <Grid item xs={10}>
                      <Header type={type} id={values.id} />
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton
                        color='primary'
                        aria-label='close'
                        className={clsx(appStyle.pdg0, appStyle.btnRight)}
                        disabled={loading.state}
                        onClick={() => setOpen(false)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  {values.id > 0 && (
                    <div style={{ width: '100%' }}>
                      <Box display='flex' justifyContent='flex-end' alignItems='center'>
                        <Box>
                          <IconButton
                            color='primary'
                            disabled={!values.disabled}
                            onClick={() => {
                              const settings = {
                                confirm: true,
                                btn: {
                                  close: 'Cancel'
                                },
                                onConfirm: () => onDelete(values.id)
                              };
                              dialogUI.current.open('Note', 'Are you sure to delete this note?', settings);
                            }}
                          >
                            <DeleteOutlineIcon />
                          </IconButton>
                        </Box>
                        <Box>
                          <Button
                            variant='contained'
                            color='primary'
                            size='small'
                            startIcon={<EditIcon />}
                            disabled={!values.disabled}
                            className={appStyle.pdg20H}
                            width={1}
                            onClick={() => onEdit(!values.disabled)}
                          >
                            Edit
                          </Button>
                        </Box>
                      </Box>
                    </div>
                  )}
                  {withAttachments
                    ? <NoteTab />
                    : <NoteForm type={type} infoCheck={infoCheck} setInfoCheck={setInfoCheck}/>
                  }
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {loading.state && (
                        <Alert variant='outlined' icon={<HourglassEmptyIcon fontSize='inherit' />} severity='warning'>
                          {loading.message}
                        </Alert>
                      )}
                      {error && <Alert severity='warning'>{error}</Alert>}
                    </Grid>
                    <Grid item xs={6}>
                      <AppButton
                        onPress={handleClose}
                        label={'Cancel'}
                        type={'main'}
                        disabled={values.disabled || loading.state}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <AppButton
                        onPress={handleSubmit}
                        label={'Save'}
                        type={'main'}
                        color={'primary'}
                        disabled={values.disabled || loading.state}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    mm: state.mm,
  };
};
export default connect(mapStateToProps)(memo(NoteModal));
