import { Box, Divider, Grid, InputLabel } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';

import { useFormikContext } from 'formik';
import NumberFormat from 'react-number-format';
import { InputField, SelectField } from '../../../forms';
import { WizardStyles } from '../../../assets/css';
import AppButton from '../../forms/AppButton';
import { MarketService } from '../../../services';
import AppHelper from '../../../helpers/AppHelper';
import { useUI } from '../../../app/context/ui';
import { weekDays } from '../../../models/db';
import { FormatWeekYear } from '../../../helpers';

const marketService = new MarketService();

const Lookup12 = () => {

  const { handleSubmit, setFieldValue } = useFormikContext();
  const [marketsDb, setMarketsDb] = useState([]);

  const { snackbarUI } = useUI();

  const wizardStyle = WizardStyles();

  const getListMarket = useCallback(async () => {
    try {
      marketService.getAccessToken();
      const r1 = await marketService.list();
      let result = r1.data.map((e) => ({
        'value': e.id,
        'label': e.name
      }));
      setMarketsDb(result);
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  }, [snackbarUI]);

  useEffect(() => {
    (async function init() {
      await getListMarket();
    })();
  }, [getListMarket]);

  useEffect(() => {
    setFieldValue('searchBasic', '');
    setFieldValue('fields', []);
  }, [setFieldValue]);

  return (
    <div style={{ width: '100%' }}>
      <Divider />
      <Box my={2} />
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12}>
          <InputLabel className={wizardStyle.title}>Mow week number:</InputLabel>
          <InputField
            name={'week'}
            placeholder='Type here'
            InputProps={{
              inputComponent: FormatWeekYear,
            }}
            isNumericString
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel className={wizardStyle.title}>Mow day:</InputLabel>
          <SelectField
            name="week_day"
            data={weekDays.filter((weekD) => weekD.value !== null)}
            placeholder={'-- Select --'} autoFocus fullWidth />
        </Grid>
        <Grid item xs={12}>
          <InputLabel className={wizardStyle.label}>Crew:</InputLabel>
          <InputField
            name="Crew"
            InputProps={{ inputComponent: NumberFormat }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel className={wizardStyle.title}>Market:</InputLabel>
          <SelectField name="market" data={marketsDb} placeholder={'-- Select --'} autoFocus fullWidth />
        </Grid>
        <Grid container item xs={12}>
          <AppButton
            onPress={handleSubmit}
            label={'Search'}
            type={'main'}
            color={'primary'}
          />
        </Grid>
      </Grid>
    </div>
  );
}
export default Lookup12;
