import React from 'react';

import {
  ArcElement, Chart as ChartJS, Legend, Title,
  Tooltip,
} from 'chart.js';
import { isEmpty } from 'lodash';
import { Pie } from 'react-chartjs-2';

import {
  ArrowUpwardIcon, AttachMoneyIcon, Box, CloudIcon,
  Container, LinearProgress, PersonAddIcon, Tooltip as TooltipMUI,
  Typography,
} from '../../components/shared/MaterialUI';
import { ChartStyles } from './charts-style';

ChartJS.register(ArcElement, Tooltip, Legend, Title);

const Charts = ({ market }) => {
  const classes = ChartStyles();

  const legendMargin = {
    id: 'legendMargin',
    beforeInit(chart) {
      const fitValue = chart.legend.fit;
      chart.legend.fit = function fit() {
        fitValue.bind(chart.legend)();
        return (this.width += 30);
      };
    },
  };

  const noDataToDisplay = {
    afterDraw: function (chart) {
      if (chart.data.datasets[0].data.every((item) => item === 0)) {
        let ctx = chart.ctx;
        let width = chart.width;
        let height = chart.height;

        chart.clear();
        ctx.save();
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText('No data to display', width / 2, height / 2);
        ctx.restore(); // 14px \"Open Sans\"
        ctx.font = `14px "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`;
      }
    },
  };

  const plugins = [legendMargin, noDataToDisplay];

  const markets = market?.map((a) => {
    return a.name;
  });

  return (
    <Container component='section' maxWidth='xl' disableGutters className={classes.chartsContainer}>
      {!isEmpty(market) &&
        markets.map((mkt, index) => {
          const data = {
            labels: market[index].dayNewSignups.data.labels,
            datasets: [market[index].dayNewSignups.data.datasets],
          };
          const options = market[index].dayNewSignups.options;
          const thereshold = Math.floor(market[index].mowingCapacity.capacity * 0.85);
          return (
            <Box key={index}>
              <Typography style={{ fontSize: '22px', fontWeight: 700 }}>{mkt}</Typography>
              <Container
                component='article'
                key={index}
                maxWidth='xl'
                disableGutters
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '12px',
                  margin: '12px 0',
                  justifyContent: 'space-around',
                }}
              >
                <Box className={classes.chartBox}>
                  <Pie data={data} options={options} plugins={plugins} />
                </Box>
                <Box className={classes.metricsBox}>
                  <Box
                    className={classes.circleIcon}
                    style={{
                      backgroundColor: 'rgba(218, 230, 255, 1)',
                    }}
                  >
                    <PersonAddIcon style={{ fontSize: '24px', color: '#5887D6' }} />
                  </Box>
                  <Typography className='metricsTitle'>{market[index].weeklySignups.label}</Typography>
                  <Box className={classes.numberMetric}>
                    <ArrowUpwardIcon />
                    <Typography style={{ fontWeight: 700, fontFamily: 'Roboto', fontSize: '20px' }}>
                      {`${market[index].weeklySignups.count.toLocaleString()}`}
                    </Typography>
                  </Box>
                </Box>

                <Box className={classes.metricsBox}>
                  <Box
                    className={classes.circleIcon}
                    style={{
                      backgroundColor: '#FFF7DA',
                    }}
                  >
                    <AttachMoneyIcon style={{ fontSize: '24px', color: '#F2C94C' }} />
                  </Box>
                  <Typography className='metricsTitle'>{market[index].dailyRevenue.label}</Typography>
                  <Box className={classes.numberMetric}>
                    <ArrowUpwardIcon />
                    <Typography style={{ fontWeight: 700, fontFamily: 'Roboto', fontSize: '20px' }}>{`$${market[
                      index
                    ].dailyRevenue.count.toLocaleString()}`}</Typography>
                  </Box>
                </Box>

                <Box className={classes.metricsBox}>
                  <Box
                    className={classes.circleIcon}
                    style={{
                      backgroundColor: '#DAFFF6',
                    }}
                  >
                    <CloudIcon style={{ fontSize: '24px', color: '#37EEC2' }} />
                  </Box>
                  <Typography className='metricsTitle'>{market[index].mowingCapacity.label}</Typography>
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: 700,
                        fontFamily: 'Roboto',
                        fontSize: '20px',
                        lineHeight: '1',
                        textAlign: 'center',
                        marginBottom: '16px',
                      }}
                    >{`${market[index].mowingCapacity.capacity.toLocaleString()}`}</Typography>
                    <TooltipMUI
                      title={
                        <React.Fragment>
                          <Typography color='inherit'>
                            Mowing schedule: {market[index].mowingCapacity.schedule}
                          </Typography>
                        </React.Fragment>
                      }
                    >
                      <LinearProgress
                        variant='determinate'
                        value={market[index].mowingCapacity.progress >= market[index].mowingCapacity.capacity ? 100 : market[index].mowingCapacity.progress}
                        className={`${
                          market[index].mowingCapacity.schedule >= thereshold
                            ? classes.linearProgressDanger
                            : classes.linearProgress
                        }`}
                      />
                    </TooltipMUI>
                  </Box>
                </Box>
              </Container>
            </Box>
          );
        })}
    </Container>
  );
};

export default Charts;
