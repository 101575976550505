import { createSlice } from '@reduxjs/toolkit';

const toolbarSlice = createSlice({
  name: 'toolbar',
  initialState: {
    toggleDrawerOpen: false,
    menuChildrenOpen: false,
    menuChildrenId: ''
  },
  reducers: {
    toolbarDrawer: (state) => {
      state.toggleDrawerOpen = !state.toggleDrawerOpen;
    },
    menuChildren: (state, action) => {
      state.menuChildrenOpen = (state.menuChildrenId !== action.payload);
      state.menuChildrenId = (!state.menuChildrenOpen) ? '' : action.payload;
    }
  }
});

export const {
  toolbarDrawer,
  menuChildren
} = toolbarSlice.actions;

export default toolbarSlice.reducer;
