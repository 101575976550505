import { makeStyles } from '../../../components/shared/MaterialUI';

export const useStyles = makeStyles((theme) => ({
  gridColumns: {
    padding: '0 26px',
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: 'rgba(16, 170, 57, 0.5)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(16, 170, 57, 0.7)',
      },
    },
    '& .MuiFormControl-root': {
      marginTop: '15px',
      width: '100%',
      '& .MuiOutlinedInput-input': {
        padding: '11px 15px',
      },
      '&.smallDatePicker': {
        marginTop: '0',
        '& .MuiInputBase-input': {
          padding: '10px 0px 10px 12px',
        },
        '& .MuiIconButton-root': {
          padding: '12px 6px 12px 0',
          '&:hover': {
            backgroundColor: 'unset',
          },
        },
      },
      '&.datePicker': {
        borderRadius: '4px',
        border: '1px solid rgba(0,0,0,0.2)',
        '& .MuiInputBase-input': {
          padding: '11px 15px',
        },
        '& .MuiInput-root': {
          '&::before, &::after': {
            content: 'unset',
          },
        },
      },
    },
    '& .labels': {
      color: 'rgba(47, 58, 74, 1)',
    },
    '& .MuiGrid-item': {
      height: '85px',
      '&:not(:first-child)': {
        marginTop: '14px',
      },
    },
    '& .phoneNumber': {
      margin: 0,
      marginTop: '15px',
      height: '41px',
      maxHeight: '41px',
      padding: '9px 15px',
      width: '100%',
      fontSize: '15px',
      borderRadius: '4px',
      fontFamily: 'Open Sans',
      lineHeight: '1.1876em',
      border: '1px solid rgba(0,0,0,0.2)',
      '&:focus-visible': {
        outline: 'none',
      },
      '&:hover': {
        borderColor: 'rgba(16, 170, 57, 0.5)',
      },
      '&:focus': {
        border: '2px solid rgba(16, 170, 57, 0.7)',
      },
      '&::placeholder': {
        opacity: '0.5',
      },
    },
    '& .MuiSelect-icon': {
      right: '8px',
    },
    '& .MuiSelect-select:focus': {
      background: 'white',
    },
  },
  select: {
    width: '100%',
    backgroundColor: '#FFF',
    marginTop: '15px',
    '&::before, &::after': {
      content: 'unset',
    },
    '& .MuiSelect-root': {
      padding: '10px 18px',
      borderRadius: '4px',
      border: '1px solid rgb(47, 58, 74, 0.3)',
    },
  },

  noPaddingLeft: {
    paddingLeft: 0,
  },
  noPaddingRight: {
    paddingRight: 0,
  },
  button: {
    width: '100%',
    color: 'rgba(47, 58, 74, 1)',
    fontWeight: '500'
  },
  backBtn: {
    backgroundColor: 'rgba(242, 242, 242, 1)',
  },
  saveBtn: {
    border: '1px solid rgb(11,12,14)',
  },
  searchBtn: {
    backgroundColor: 'rgb(11,12,14)',
    color: '#FFF',
    transition: 'ease 0.3s all',
    '&:hover': {
      backgroundColor: 'rgba(11,12,14, 0.2)',
      color: 'inherit',
    },
  },
  paperModal: {
    position: 'absolute',
    width: 550,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflowY: 'scroll',
  },
  ctnInner: {
    fontFamily: ['Roboto', 'Arial'].join(','),
    fontWeight: 'bold',
    color: '#2F3A4A',
    paddingBottom: '12px',
    '& .title': {
      fontSize: '20px',
      textAlign: 'center',
      marginBottom: '46px',
      fontWeight: 'bold',
    },
  },
  grdItem: {
    marginTop: 5,
    fontWeight: 100,
  },
  inputCustom: {
    '& .MuiOutlinedInput-root': {
      height: '32px',
    },
    '& .MuiInputBase-input': {
      fontSize: 16,
      fontFamily: ['Roboto', 'Arial'].join(','),
    },
  },
  buttonSaveSearch: {
    width: '160px',
    marginRight: '30px',
  },
  infoItem: {
    fontWeight: 'bold',
  },
  btnDelete: {
    backgroundColor: '#bb0909',
    border: 'none',
  },
  wrapperBb:{
    height: 'auto !important',
    marginTop: '0px !important',
    '& .title':{
      padding: '11px 0px 0px 2px'
    }
  },
  wrapperGap:{
    display: 'flex', 
    gap: '40px'
  },
  gripLookups:{
    marginTop: '24px'
  },
  noMarginTop:{
    marginTop: '0px !important'
  },
  wrapperContainerBb:{
    paddingTop: '9px'
  },
  labelDate:{
    marginBottom: '22px !important'
  },
  wrapperActivitySearch:{
    height: 'auto !important',
    borderTop: 'solid 1px #11aa39',
    marginTop: '24px !important',
    '& .title':{
      padding: '11px 0px 0px 2px',
      fontWeight: 'bold'
    }
  },
  buttonExpand:{
    marginTop: '26px',
    padding: '0px 0px 0px 15px',
    border: 'solid 1px #11aa39',
    color: '#11aa39',
  },
  wrapperBtns: {
    marginTop: '35px'
  }
}));
