
const CalculateNumberWeek = (currentDate = new Date()) => {
  const dayInMiliseconds = 1000 * 60 * 60 * 24;
  const startYear = new Date(Date.UTC(currentDate.getUTCFullYear(), 0, 1));
  const diferenceDate = currentDate - startYear;
  const week = Math.ceil(((diferenceDate / dayInMiliseconds) + 1) / 7);
  return {
    week,
    year: currentDate.getFullYear(),
    dayWeek: currentDate.getDay()
  }
}

const GenerateNameDownloadWithDate = (name,typeDoc) => {
  const dateNow = new Date();
  const year = dateNow.getFullYear();
  const month = dateNow.getMonth()+1;
  const day = dateNow.getDate();
  const hours = dateNow.getHours();
  const minutes = dateNow.getMinutes();
  const seconds = dateNow.getSeconds();
  let nameDownload = `${name}_${year+''+month+''+day}`;
  nameDownload += `_${hours+''+minutes+''+seconds}`;
  nameDownload += `.${typeDoc}`;
  return nameDownload;
}

export { CalculateNumberWeek, GenerateNameDownloadWithDate };
