import React, { memo, useEffect, useState } from 'react';
import clsx from 'clsx';

import { AppStyle } from '../../../assets/css/app/AppStyle';

const Header = memo(({ type, id }) => {
  const [title, setTitle] = useState('');
  const appStyle = AppStyle();

  const getTitle = (type, id) => {
    const preffix = id === 0 ? 'New ' : '';
    switch (type) {
      case 'private_note':
        setTitle(preffix + 'Private Note');
        break;
      case 'special_request':
        setTitle(preffix + 'Special Request');
        break;
      case 'rejected_note':
        setTitle('Wait!');
        break;
      default:
        setTitle(preffix + 'General Note');
    }
  };

  useEffect(() => {
    getTitle(type, id);
  }, [type, id]);

  return <h2 className={clsx(appStyle.mrg0V)}>{title}</h2>;
});

export default Header;
