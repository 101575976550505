import React, { useState, useEffect } from 'react';
import { Button, Checkbox, Modal } from '@material-ui/core';
import { NotificationsStyle } from '../../styles/NotificationsStyle';
import { useUI } from '../../../../app/context/ui';
import { useSelector } from 'react-redux';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const DetailAlarm = (props) => {

  const {openAlarmView, setOpenAlarmView, dataAlarmDetail, handleUpdateDataAlarm} = props;
  const [modalStyle] = useState(getModalStyle);
  const alarmsAvailable = useSelector(({mm}) => mm.alarm) || {date: null, options: []};
  const notificationsStyle = NotificationsStyle();
  const {blockUI} = useUI();

  const [checked, setChecked] = React.useState(dataAlarmDetail.snooze);

  const handleChange = async (event) => {
    try {
      blockUI.current.open(true);
      setChecked(event.target.checked);
      let alarmsUpdated = alarmsAvailable.options.map((e)=>{
        if(e.id == dataAlarmDetail.id){
          return {
            ...e,
            snooze: event.target.checked
          }
        }else{
          return { ...e }
        }
      });
      await handleUpdateDataAlarm({...dataAlarmDetail, snooze: event.target.checked}, dataAlarmDetail.id, alarmsUpdated);
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
    }
  };

  const handleStopAlarm = async () => {
    try {
      blockUI.current.open(true);
      let alarmsUpdated = alarmsAvailable.options.map((e)=>{
        if(e.id == dataAlarmDetail.id){
          return {
            ...e,
            active: false
          }
        }else{
          return { ...e }
        }
      });
      await handleUpdateDataAlarm({...dataAlarmDetail, active: false}, dataAlarmDetail.id, alarmsUpdated);
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
    }
  }

  useEffect(() => {
    setChecked(dataAlarmDetail.snooze);
  }, [dataAlarmDetail])

  return (
    <Modal
      open={openAlarmView}
      onClose={() => setOpenAlarmView(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableEscapeKeyDown={true}
    >
      <div style={modalStyle} className={notificationsStyle.paperModal_sm}>
        <div className={notificationsStyle.titleAlarm}>Alarm</div>
        <div className='wrapperHourDate'>
          <span className='date'>{ dataAlarmDetail.alarmDate }</span>
          <span className='hour'>{ dataAlarmDetail.alarmHour }</span>
        </div>
        <div className='name'>{ dataAlarmDetail.users?.fullName || 'Name not registered' }</div>
        <div className='detail'>{ dataAlarmDetail.detail }</div>
        <div>
          <div className='titleSnooze'>Snooze</div>
          <div>
            <Checkbox
              checked={checked}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </div>
        </div>
        <div className='wrapperButtonAction'>
          <Button
            variant='contained'
            size='small'
            onClick={() => {setOpenAlarmView(false)}}
            className={notificationsStyle.buttonClose}
          >
            Close
          </Button>
          <Button
            variant='contained'
            color='primary'
            size='small'
            onClick={() => {
              handleStopAlarm();
              setOpenAlarmView(false);
            }}
            className={notificationsStyle.buttonActions}
          >
            Stop alarm
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default DetailAlarm;
