import React, {useCallback, useEffect, useState} from 'react';
import {
  Container,
  Grid,
  Typography,
  TextField,
  Box, Alert
} from '../../../components/shared/MaterialUI';

import { WizardStyles, LoginStyles } from '../../../assets/css';
import {
  Button,
  Divider, IconButton,
  InputLabel, Modal
} from '@material-ui/core';
import { InputField, RadioField, SelectField } from '../../../forms';
import DatePickerField from '../../../forms/DatePickerField';
import { useFormikContext } from 'formik';
import CloseIcon from '@material-ui/icons/Close';
import { FormatPhoneNumber, Utils } from '../../../helpers';
import { weekDays } from '../../../models/db';
import {MarketService} from "../../../services";
import { includes as _includes} from 'lodash';
import moment from "moment";

const marketService = new MarketService();
const Step4 = (props) => {
  const [modalStyle] = useState(Utils.getModalStyle);
  const [open, setOpen] = useState(false);
  const [label, setLabel] = useState('');
  const [description, setDescription] = useState('');
  const [value, setValue] = useState(null);
  const [message, setMessage] = useState('');
  const [calendar, setCalendar] = useState([]);

  const { values: formValues } = useFormikContext();
  const {formikRef, handleNext} = props;
  const {
    formField: {
      firstName,
      lastName,
      email,
      contactNumber,
      isCellphone,
      serviceDate,
      isCommunityGate,
      communityAnswer,
      isBackyardGate,
      backyardAnswer,
      isFlowerBeds,
      isPetsBackyard,
      isFencePlastic,
      isAboveGroundPool,
      isTranpoline,
      role,
      password,
      weekDay
    }
  } = props;

  const wizardStyle = WizardStyles();
  const loginStyle = LoginStyles();

  const handleCheck = (e) => {
    setValue(e.target.value);
    if (e.target.value === 'Yes' && label === 'isPetsBackyard'){
      setMessage('Before we finish things off and ship your order, I need to ask you 3 more questions ' +
          'about items that may be in your backyard.');
    }else if (e.target.value === 'No'){
      setMessage('By not accepting the liability terms, the following customer application will be ' +
          'canceled and the information will be lost.');
    }else {
      setMessage('');
    }
  };

  const handleOpenCustomer = (event) => {
    setLabel(event.target.name);
    if (event.target.value === 'Yes') {
      setOpen(true);
      setValue(null);
    }
    switch (event.target.name) {
      case 'isFlowerBeds':
        return setDescription('We are not responsible for damage to your flower beds, do you agree to ' +
            'release us from liability regarding your flower beds? ' +
            ' We can not service your property unless we are released from liability.');
      case 'isPetsBackyard':
        return setDescription('Explain that we are not responsible for pets. You must leave your pet indoors ' +
            'on the day of harvest. I know it is a simple request for us to close your door to prevent ' +
            'your pet from leaving, but the priority of our staff is to cut the grass. Thanks for that ' +
            'information.');
      case 'isFencePlastic':
        return setDescription('We are not responsible for damage to your vinyl fence, do you agree to release ' +
            'us from liability regarding your vinyl fence?  We can not service your property unless we are released ' +
            'from liability.');
      case 'isAboveGroundPool':
        return setDescription('We are not responsible for damage to your above pool, do you agree to release ' +
            'us from liability regarding your above pool?  We can not service your property unless we are released ' +
            'from liability.');
      case 'isTranpoline':
        return setDescription('We are not responsible for damage to your trampoline, do you agree to release ' +
            'us from liability regarding your trampoline?  We can not service your property unless we are released ' +
            'from liability.');
      default:
        return setDescription('');
    }
  }

  const updateValueField = (fields, value) => {
    setOpen(false);
    if (formikRef.current) {
      formikRef.current.setFieldValue(
          `${fields}`, value
      );
    }
    if(value === 'No'){
      handleNext(6);
    }
  }

  const getCalendar = useCallback(async () => {
    try {
      marketService.getAccessToken();
      const r = await marketService.calendar(formValues.zipcode);
      setCalendar(r.data)
    } catch (e) {
      setCalendar([]);
    }
  }, [formValues.zipcode]);

  const disableWeekends = (date) => {
    const ISODateString = moment(date).format('YYYY-MM-DD');
    return date.getDay() === 0 || date.getDay() === 6 || _includes(calendar, ISODateString);
  }

  useEffect(() => {
    (async function init() {
      await getCalendar();
    })();
  }, [getCalendar]);

  const body = (
      <div style={modalStyle} className={wizardStyle.paperModal}>
        <Grid container spacing={1}>
          <Grid item xs={11}>
            <h2 id="simple-modal-title">Inform your customer</h2>
          </Grid>
          <Grid item xs={1}>
            <IconButton aria-label="close" onClick={() => {updateValueField(label, '')}}>
              <CloseIcon/>
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <p id="simple-modal-description">
              {description}
            </p>
            <p>Check if the client agrees:</p>
            <div>
              <RadioField row name="agrees" value={value} onChange={handleCheck} />
            </div>
            <div>
              {value === 'No' && (
                  <Alert severity="info" color="info" className={wizardStyle.alert}>
                    {message}
                  </Alert>
              )}
              {value === 'Yes' && (
                  <p>{message}</p>
              )}
            </div>
            <div>
              <Box pb={4}/>
            </div>
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={loginStyle.btnSubmit}
                disabled={(value === null)}
                onClick={() => {updateValueField(label, value)}}
            >
              {value === 'No' ? 'Ok' : 'Save'}
            </Button>
          </Grid>
        </Grid>
      </div>
  );

  return (
    <Container component="main" maxWidth="lg" className={wizardStyle.ctnInner}>
      <Typography className={wizardStyle.title}>Cost information for the customer</Typography>
      <hr/>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <InputLabel className={wizardStyle.label}>First Name</InputLabel>
          <InputField name={firstName.name}  fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel className={wizardStyle.label}>Last Name</InputLabel>
          <InputField name={lastName.name}  fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel className={wizardStyle.label}>Email</InputLabel>
          <InputField name={email.name}  fullWidth />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputLabel className={wizardStyle.label}>Contact number</InputLabel>
          <InputField name={contactNumber.name}
                      InputProps={{inputComponent: FormatPhoneNumber}} fullWidth />
        </Grid>
        <Grid item xs={12} sm={3}>
          <label className={wizardStyle.label}>Is this a cellphone?</label>
          <RadioField row name={isCellphone.name} />
        </Grid>

        <Grid item xs={12}>
          <Typography className={wizardStyle.title}>Schedule</Typography>
          <Divider/>
        </Grid>

        <Grid item xs sm={4}>
          <label className={wizardStyle.label}>Start date</label>
          <DatePickerField
              disableToolbar
              name={serviceDate.name}
              format="MM/dd/yyyy"
              shouldDisableDate={disableWeekends}

              minDate={new Date()}
              maxDate={new Date('2050/12/31')}
              fullWidth
          />
        </Grid>

        <Grid item xs sm={2}>
          <label className={wizardStyle.label}>Community gate? *</label>
          <RadioField row name={isCommunityGate.name} />
        </Grid>
        <Grid item xs sm={2} className={wizardStyle.grdAnswer}>
          {formValues.isCommunityGate === 'Yes' && (
              <InputField name={communityAnswer.name}  placeholder="Enter code" fullWidth />
          )}
        </Grid>

        <Grid item xs sm={2}>
          <label className={wizardStyle.label}>Backyard gate? *</label>
          <RadioField row name={isBackyardGate.name} />
        </Grid>
        <Grid item xs sm={2} className={wizardStyle.grdAnswer}>
          {formValues.isBackyardGate === 'Yes' && (
              <InputField name={backyardAnswer.name}  placeholder="Only if you need code" fullWidth />
          )}
        </Grid>

        <Grid item xs sm={4}>
          <InputLabel className={wizardStyle.label}>Week day *</InputLabel>
          <SelectField name={weekDay.name} data={weekDays} autoFocus fullWidth />
        </Grid>
        <Grid item xs sm={2}>
          <label className={wizardStyle.label}>Flower beds? *</label>
          <RadioField row name={isFlowerBeds.name} onChange={handleOpenCustomer}/>
        </Grid>
        <Grid item xs sm={2}/>
        <Grid item xs sm={2}>
          <label className={wizardStyle.label}>Pets in backyard? *</label>
          <RadioField row name={isPetsBackyard.name} onChange={handleOpenCustomer}/>
        </Grid>
        <Grid item xs sm={2}/>

        <Grid item xs sm={4}>
          <label className={wizardStyle.label}>Is your fence made of white vinyl plastic? *</label>
          <RadioField row name={isFencePlastic.name} onChange={handleOpenCustomer}/>
        </Grid>

        <Grid item xs sm={4}>
          <label className={wizardStyle.label}>Do you own an above ground pool? *</label>
          <RadioField row name={isAboveGroundPool.name} onChange={handleOpenCustomer}/>
        </Grid>

        <Grid item xs sm={4}>
          <label className={wizardStyle.label}>Do you have a trampoline and want us to move it? </label>
          <RadioField row name={isTranpoline.name} onChange={handleOpenCustomer}/>
        </Grid>
        <Grid item xs={12}>
          <TextField
              type="hidden"
              size="small"
              name={role.name}
          />
          <TextField
              type="hidden"
              size="small"
              name={password.name}
          />
        </Grid>
        <Grid item xs={12}>
          <div>
            <Modal
                open={open}
                onClose={() => {updateValueField(label, '')}}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
              {body}
            </Modal>
          </div>
        </Grid>
      </Grid>
      <Box pb={4} />
    </Container>
  )
}

export default Step4;
