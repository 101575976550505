import {serialize} from 'object-to-formdata';
import AppService from './AppService';

class BushBedService extends AppService {

  constructor() {
    super();
    this.path = this.getPath('bush-bed');
  }

  createService(data) {
    const dataToSend = serialize(data);
    return this.http.post(this.path, dataToSend);
  }

	list(params = '') {
		const queryParams = params ? params : '';
		return this.http.get(`${this.path}${queryParams}`);
	}

  getService(id) {
    return this.http.get(`${this.path}/${id}`);
  }

  updateService(id, data) {
    const dataToSend = serialize({
      ...data,
      _method: 'PATCH',
    });
    return this.http.post(`${this.path}/${id}`, dataToSend);
  }
}

export default BushBedService;
