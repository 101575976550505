import React, { useState } from 'react';
import { useStyles } from './mapStyles';
import SvgIconMarkerSelected from './SvgIconMarkerSelected';
import MarkerSvg from './SvgIconMarker';
import { Popover, Typography } from '@material-ui/core';

const Marker = ({ onClick, dataMarker, mapColor, selected }) => {
  
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <div onClick={()=>{
      handlePopoverClose();
      onClick();
    }}>

      {
        (selected)
          ?
            <SvgIconMarkerSelected
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              color={mapColor}
            />
          :
            <MarkerSvg
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              color={mapColor}
            />
      }
      
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography>
          {
            (dataMarker.crew)
              ? `Crew: ${dataMarker.crew}`
              : 'No crew assigned'
          }
        </Typography>
        <Typography>
          {
            (dataMarker.zipcode)
              && `Zipcode: ${dataMarker.zipcode}`
          }
        </Typography>
        <Typography>
          {
            (dataMarker.serviceAddress)
              && `${dataMarker.serviceAddress}`
          }
        </Typography>
      </Popover>
    </div>
  )
};

export default Marker;
