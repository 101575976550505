import { Divider, Grid, InputLabel, TextField } from '@material-ui/core';

import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { find as _find, isUndefined as _isUndefined } from 'lodash';

import { Box } from '../../shared/MaterialUI';
import FieldList from './FieldList';
import { statusDb } from '../../../models/db';
import classNames from 'classnames';
import { WizardStyles } from '../../../assets/css';
import { Autocomplete } from '@material-ui/lab';

const lookupSwr = [
  {
    value: 1,
    label: 'Status',
    field: 'status',
    tag: 'drop_down',
    data: statusDb
  },
  {
    value: 2,
    label: 'Skips date',
    field: 'skips_service_day',
    tag: 'calendar_select'
  },
  {
    value: 3,
    label: 'Write in date',
    field: 'write_service_day',
    tag: 'calendar_select'
  },
  {
    value: 4,
    label: 'Redo date',
    field: 'redo_service_day',
    tag: 'calendar_select'
  }
];

const lookupParent = [
  {
    parent: 2,
    name: 0,
    label: 'Skips done',
    field: 'skips_completed',
    tag: 'hidden'
  },
  {
    parent: 3,
    name: 0,
    label: 'Write in done',
    field: 'write_completed',
    tag: 'hidden'
  },
  {
    parent: 4,
    name: 0,
    label: 'Redo done',
    field: 'redo_completed',
    tag: 'hidden'
  }
];

const Lookup9 = () => {
  const {setFieldValue} = useFormikContext();
  const wizardStyle = WizardStyles();

  const handleChangeAuto = (event, values) => {
    let arr = [];
    let ids = '';
    values.map(function (val) {
      ids += val.value;
      arr.push(val);
      const parent = _find(lookupParent, {'parent': val.value});
      if (!_isUndefined(parent)) {
        arr.push(parent);
      }
    });
    setFieldValue('searchBasic', ids);
    setFieldValue('fields', arr);
  }

  useEffect(() => {
    setFieldValue('searchBasic', '');
    setFieldValue('fields', []);
  }, [setFieldValue]);

  return (
    <Grid style={{width: '100%'}}>
      <Box mt={2}/>
      <Divider/>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12}>
          <InputLabel className={classNames(wizardStyle.title, wizardStyle.mt7)}>
            Search attributes:
          </InputLabel>
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            name="searchBasic"
            multiple
            id="tags-standard"
            options={lookupSwr}
            getOptionLabel={(option) => option.label}
            onChange={handleChangeAuto}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <FieldList/>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default Lookup9;
