import AdminPanel from '../pages/admin/AdminPanel';
import Login from '../pages/auth/LoginPage';
import CreateCustomer from '../pages/customer/CreateCustomer';
import EditCustomer from '../pages/customer/EditCustomer';
import ListCustomer from '../pages/customer/ListCustomer';
import NewOrders from '../pages/customer/NewOrders';
import Dashboard from '../pages/Dashboard';
import CustomerReferralPanel from '../pages/referral/CustomerReferralPanel';
import ReferralPanel from '../pages/referral/ReferralPanel';
import ListReport from '../pages/reports/ListReport';
import ListReconciliationReport from '../pages/customer/ListReconciliationReport';
import LookupPanel from '../pages/search/LookupPanel';
import CreateUser from '../pages/user/Create';
import ListUsers from '../pages/user/List';
import PaymentList from '../pages/web/payment/PaymentList';
import PaymentPage from '../pages/web/payment/PaymentPage';
import CreateCrewMember from '../pages/crew-members/CreateCrewMember';
import CrewMemberList from '../pages/crew-members/CrewMemberList';
import CreateReport from '../pages/reports/CreateReport';
import { UpdatePrice } from '../pages/update-price/UpdatePrice';
import UploadPayment from '../pages/crew-members/UploadPayment';
import BbList from '../pages/bb/BbList';
import BbCreate from '../pages/bb/BbCreate';
import ListBushBedReport from '../pages/customer/ListBushBedReport';
import ListInvoices from '../pages/invoices/ListInvoices';
import ListMarket from '../pages/market/ListMarket';
import ListZip from '../pages/zip/ListZip';
import ScheduleManagement from '../pages/schedule/ScheduleManagement'

const Routes = [
  {
    path: '/',
    name: 'login',
    exact: true,
    pageTitle: 'Login',
    component: Login,
  },
  {
    path: '/dashboard',
    name: '',
    exact: true,
    pageTitle: '',
    component: Dashboard,
  },
  {
    path: '/edit-customer',
    name: 'Edit customer',
    exact: true,
    pageTitle: 'Customer profile info',
    component: EditCustomer,
  },
  {
    path: '/users',
    exact: true,
    component: ListUsers,
  },
  {
    path: '/create-user',
    exact: true,
    component: CreateUser,
  },
  {
    path: '/crew-members',
    exact: true,
    component: CrewMemberList,
  },
  {
    path: '/payments',
    exact: true,
    component: UploadPayment,
  },
  {
    path: '/create-crew-member',
    exact: true,
    component: CreateCrewMember,
  },
  {
    path: '/upload-payment',
    exact: true,
    component: UploadPayment,
  },
  {
    path: '/customer',
    exact: true,
    component: ListCustomer,
  },
  {
    path: '/create-customer',
    exact: true,
    component: CreateCustomer,
  },
  {
    path: '/admin-panel',
    exact: true,
    component: AdminPanel,
  },
  {
    path: '/referral-panel',
    exact: true,
    component: ReferralPanel,
  },
  {
    path: '/customer-referral-panel',
    exact: true,
    component: CustomerReferralPanel,
  },
  {
    path: '/operations-report',
    exact: true,
    component: ListReport,
  },
  {
    path: '/reconciliation-report',
    exact: true,
    component: ListReconciliationReport,
  },
  {
    path: '/new-orders',
    exact: true,
    component: NewOrders,
  },
  {
    path: '/expired-card',
    exact: true,
    component: ListCustomer,
  },
  {
    path: '/payment-configuration',
    exact: true,
    component: PaymentPage,
  },
  {
    path: '/payment-list',
    exact: true,
    component: PaymentList,
  },
  {
    path: '/lookup-search',
    exact: true,
    component: LookupPanel,
  },
  {
    path: '/report',
    exact: true,
    component: CreateReport,
  },
  {
    path: '/update-price',
    exact: true,
    component: UpdatePrice,
  },
  {
    path: '/bb-crew-members',
    exact: true,
    component: BbList,
  },
  {
    path: '/bb-create-crew-member',
    exact: true,
    component: BbCreate,
  },
  {
    path: '/bush-bed-report',
    exact: true,
    component: ListBushBedReport,
  },
  {
    path: '/invoices',
    exact: true,
    component: ListInvoices,
  },
  {
    path: '/market-map',
    exact: true,
    component: ListMarket,
  },
  {
    path: '/zip',
    exact: true,
    component: ListZip,
  },
  {
    path: '/schedule-management',
    exact: true,
    component: ScheduleManagement,
  },
/* TODO::Email Change
  {
    path: '/emails',
    exact: true,
    component: ListEmailNotification,
  },*/
];

export default Routes;
