import AppService from './AppService';

class HelperService extends AppService {
  constructor() {
    super();
    this.path = this.getPath('helper');
  }

  dateInfo(date) {
    return this.http.get(`${this.path}/date-info?date=${date}`);
  }

  weeks(date) {
    return this.http.get(`${this.path}/weeks?year=${date}`);
  }
}

export default HelperService;
