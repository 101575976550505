import React from 'react'

import { WeekStyle } from '../calendar/styles/WeekStyle';
import Week from './Week';

const WeeksOfYear = (props) => {

  const classes = WeekStyle();

  return (
    <div className={classes.ctnWeekOfYear}>
      <Week
        dataRender={props.dataRender}
        setDataRender={props.setDataRender}
        getDataSchedule={props.getDataSchedule}
        year={props.year}
        type={props.type || "default"}
      />
    </div>
  )
}

export default WeeksOfYear;
