import React from 'react';
import { Grid, InputLabel } from '@material-ui/core';

import { FieldArray, useFormikContext } from 'formik';
import classNames from 'classnames';

import { CheckboxField, InputField, SelectField } from '../../../forms';
import AppButton from '../../forms/AppButton';
import { WizardStyles } from '../../../assets/css';
import DatePickerField from '../../../forms/DatePickerField';

const FieldList = () => {
  const {values, handleSubmit} = useFormikContext();
  const wizardStyle = WizardStyles();

  return (
    <Grid container alignItems="center" spacing={2}>
      <FieldArray
        name="fields"
        render={() => {
          return (
            <Grid container item xs={12}>
              {values.fields &&
              values.fields.length > 0 &&
              values.fields.map((e, index) => (
                <Grid container alignItems="center" spacing={2} key={index}>
                  <Grid item xs={12}>
                    {(e.tag !== 'hidden') && (
                    <InputLabel className={classNames(wizardStyle.title, wizardStyle.mt7)}>
                      {e.label}:
                    </InputLabel>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {(() => {
                      switch (e.tag) {
                        case 'text_field':
                          return <InputField name={`fields.${index}.name`} autoFocus fullWidth/>;
                        case 'drop_down':
                          return <SelectField name={`fields.${index}.name`}
                                              data={e.data}
                                              placeholder={'-- Select --'}
                                              autoFocus
                                              fullWidth/>;
                        case 'calendar_select':
                          return <DatePickerField
                            disableToolbar
                            name={`fields.${index}.name`}
                            format="MM/dd/yyyy"
                            maxDate={new Date('2050/12/31')}
                            autoFocus
                            fullWidth
                          />;
                        case 'checkbox':
                          return <CheckboxField
                            name={`fields.${index}.name`}
                          />;
                        default:
                          return null;
                      }
                    })()}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          )
        }}
      />
      <Grid item xs={12}>
        <AppButton
          onPress={handleSubmit}
          label={'Search'}
          type={'main'}
          color={'primary'}
        />
      </Grid>
    </Grid>
  );
}

export default FieldList;
