import React, { useState } from 'react';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { useUI } from '../../../app/context/ui';
import {
  Container,
  Grid,
  Button,
  Box,
  Typography
} from '../../../components/shared/MaterialUI';
import AppHelper from '../../../helpers/AppHelper';
import { ModalStyle } from '../styles/ModalStyle';
import { InputField } from '../../../forms';

const phoneRegExp = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
const webRegExp = /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

const ResignReferralTypes = (props) => {
  const {
    typeModalReferralType,
    setOpenModalReferralType,
    dataEditReferralType,
    dataReferral,
    setDataReferral
  } = props;
  
  const classes = ModalStyle();
  const [initialValues,] = useState({
    customerReferralId: dataEditReferralType?.idReferral || '',
    nameReferral: dataEditReferralType?.nameReferral || '',
    id: dataEditReferralType?.id || '',
    name: dataEditReferralType?.name || '',
    phone: dataEditReferralType?.phone || '',
    website: dataEditReferralType?.website || ''
  });

  const {blockUI, snackbarUI} = useUI();
  const validationSchema = Yup.object({
    name: Yup
      .string('Enter name')
      .max(50, 'Max. 50 characters')
      .required('Name is required'),
    phone: Yup
      .string('Enter name')
      .matches(phoneRegExp, 'Phone number is not valid')
      .required('Phone is required'),
    website: Yup
      .string()
      .matches(webRegExp, 'Website should be a valid URL')
  });
  const onSubmit = (values) => {
    try {
      blockUI.current.open(true);
      if (typeModalReferralType === 'Add') {
        let newArray = dataReferral.filter((e) => {
          if (values.customerReferralId !== '') {
            if (e.id === values.customerReferralId) {
              e.types.push(values);
            }
          } else {
            if (e.name === values.nameReferral) {
              e.types.push(values);
            }
          }

          return e;
        });
        setDataReferral(newArray);
        setOpenModalReferralType(false);
      } else {
        let newArray = dataReferral.filter((e) => {
          if (values.customerReferralId !== '') {
            if (e.id === values.customerReferralId) {
              e.types[dataEditReferralType.index] = {...values};
            }
          } else {
            if (e.name === values.nameReferral) {
              e.types[dataEditReferralType.index] = {...values};
            }
          }
          return e;
        });
        setDataReferral(newArray);
        setOpenModalReferralType(false);
      }
      blockUI.current.open(false);
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  }
  return (
    <Container component="main" maxWidth="sm" className={classes.ctnInner}>
      <Typography variant="h4" className="title">{typeModalReferralType} Customer Referral</Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          onSubmit(values);
        }}
        enableReinitialize={true}
      >
        {() => {
          return (
            <Form autoComplete="off">
              <Grid container spacing={3}>
                <input type="hidden" name="id" id="id"/>
                <input type="hidden" name="customerReferralId" id="customerReferralId"/>
                <input type="hidden" name="nameReferral" id="nameReferral"/>
                <Grid item xs={3} className={classes.grdItem}>
                  <label>Name:</label>
                </Grid>
                <Grid item xs={9}>
                  <InputField name="name" fullWidth/>
                </Grid>
                <Grid item xs={3} className={classes.grdItem}>
                  <label>Phone:</label>
                </Grid>
                <Grid item xs={9}>
                  <InputField name="phone" fullWidth/>
                </Grid>
                <Grid item xs={3} className={classes.grdItem}>
                  <label>Web site:</label>
                </Grid>
                <Grid item xs={9}>
                  <InputField name="website" fullWidth/>
                </Grid>
              </Grid>
              <Box pb={2}/>
              <Grid container justify="center">
                <Button
                  variant="contained"
                  size="large"
                  className={classes.button}
                  onClick={() => {
                    setOpenModalReferralType(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  className={classes.button}
                >
                  Save
                </Button>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};

export default ResignReferralTypes;
