import React from 'react';

import BBCalendar from '../../../components/bbCalendar/BBCalendar';

export default function Step6() {
  return (
    <>
     <BBCalendar/>
      </>
  );
}
