import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import store from './redux/store';
import { UIProvider } from './app/context/ui';
import { ThemeProvider } from '@material-ui/core/styles'
import { theme } from './assets/theme'
import './index.css';
import App from './App';

import {environment} from './environments';
import Echo from "laravel-echo";
import Pusher from "pusher-js";

window.Pusher = Pusher;

window.Echo = new Echo({
  broadcaster: "pusher",
  key: "ASDASD2121",
  cluster: "mt1",
  wsHost: environment.host,
  wsPort: environment.port,
  forceTLS: false,
  disableStats: false,
});

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <UIProvider>
        <App />
      </UIProvider>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);
