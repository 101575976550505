import React from 'react';

const MarkerSvg = ({color, onMouseEnter, onMouseLeave}) => {
  return (
    <svg width="54" height="60" viewBox="0 0 54 60" fill="none" xmlns="http://www.w3.org/2000/svg" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <path d="M53.1503 25.4919C53.1503 39.1737 35.4687 49.4392 26.6279 59.3485C18.6712 49.4392 0.105469 39.1737 0.105469 25.4919C0.105469 11.8101 11.98 0.71875 26.6279 0.71875C41.2758 0.71875 53.1503 11.8101 53.1503 25.4919Z" fill={color}/>
      <path d="M25.3526 24.659C25.2051 24.6592 25.0593 24.6303 24.9247 24.5741C24.7901 24.518 24.6697 24.4359 24.5714 24.3333C24.4744 24.2287 24.4019 24.1062 24.3586 23.9738C24.3154 23.8414 24.3023 23.702 24.3203 23.5646C24.3203 23.4473 26.0361 11.6562 37.1812 11.6562C37.4358 11.6577 37.6811 11.7458 37.8711 11.9041C38.0611 12.0624 38.1828 12.28 38.2134 12.5162C38.5432 15.5842 37.566 18.6501 35.4934 21.05C33.2616 23.4604 29.8441 24.659 25.3526 24.659ZM36.1769 13.6497C29.4675 14.1969 27.2636 20.1641 26.6219 22.6656C27.9706 22.6602 29.3047 22.4052 30.5472 21.9155C31.7898 21.4257 32.9162 20.7109 33.8614 19.8123C35.391 18.0795 36.2116 15.8953 36.1769 13.6497V13.6497Z" fill="white"/>
      <path d="M25.3526 24.6438H25.2271C21.8654 24.2529 15.4629 21.8165 15.4629 15.4454C15.464 15.2062 15.5579 14.9754 15.7272 14.7957C15.8964 14.6161 16.1296 14.4998 16.3835 14.4683C18.9284 14.2276 21.4728 14.9294 23.4695 16.4226C24.495 17.3539 25.2889 18.4843 25.7951 19.7338C26.3012 20.9834 26.5073 22.3215 26.3988 23.6536C26.399 23.7913 26.368 23.9275 26.3079 24.0532C26.2478 24.179 26.1599 24.2914 26.0501 24.3832C25.8581 24.5447 25.6108 24.637 25.3526 24.6438V24.6438ZM17.6529 16.3835C18.1969 20.4485 22.1862 21.9468 24.2507 22.4549C24.1919 20.7264 23.4167 19.0868 22.0886 17.8818C20.8333 16.9294 19.27 16.4014 17.6529 16.3835V16.3835Z" fill="white"/>
      <path d="M25.3528 30.041C25.0753 30.041 24.8092 29.938 24.6131 29.7548C24.4169 29.5715 24.3066 29.323 24.3066 29.0638V23.6568C24.3066 23.3977 24.4169 23.1491 24.6131 22.9659C24.8092 22.7826 25.0753 22.6797 25.3528 22.6797C25.6303 22.6797 25.8964 22.7826 26.0926 22.9659C26.2887 23.1491 26.399 23.3977 26.399 23.6568V29.0378C26.4047 29.1687 26.3817 29.2994 26.3315 29.4218C26.2813 29.5442 26.2049 29.6558 26.107 29.7497C26.0091 29.8436 25.8917 29.9178 25.7621 29.9679C25.6324 30.018 25.4931 30.0429 25.3528 30.041V30.041Z" fill="white"/>
      <path d="M28.9796 38.2614H22.2841C20.9101 38.241 19.5995 37.7176 18.6344 36.8039C17.6692 35.8902 17.1265 34.6592 17.123 33.3756V29.0631C17.123 28.8039 17.2333 28.5554 17.4295 28.3721C17.6257 28.1889 17.8917 28.0859 18.1692 28.0859H33.1642C33.4417 28.0859 33.7078 28.1889 33.904 28.3721C34.1001 28.5554 34.2104 28.8039 34.2104 29.0631V33.3756C34.2067 34.6704 33.6544 35.9111 32.6742 36.8266C31.6941 37.7422 30.3657 38.258 28.9796 38.2614V38.2614ZM19.2154 30.0403V33.3756C19.2152 34.142 19.5362 34.8779 20.1098 35.4258C20.6834 35.9737 21.4639 36.2901 22.2841 36.3071H28.9796C29.8119 36.3071 30.6102 35.9983 31.1988 35.4485C31.7874 34.8988 32.118 34.1531 32.118 33.3756V30.0403H19.2154Z" fill="white"/>
    </svg>
  );
};

export default MarkerSvg;
