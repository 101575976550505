import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { DataGrid } from '@material-ui/data-grid';
import { Edit } from '@material-ui/icons';
import clsx from 'clsx';

import { Typography, Button, Alert, Collapse, Container } from '../../components/shared/MaterialUI';
import store from '../../redux/store';
import UserService from '../../services/UserService';
import { ListStyles } from '../../assets/css';
import { useUI } from '../../app/context/ui';
import AppHelper from '../../helpers/AppHelper';

const columns = [
  { field: 'email', headerName: 'User name', flex: 0.4},
  { field: 'joinDate', headerName: 'Join date', width: 150 },
  { field: 'firstName', headerName: 'First name', width: 150 },
  { field: 'lastName', headerName: 'Last name', width: 150 },
  { field: 'role', headerName: 'Role', flex: 0.3},
];

const userService = new UserService();

const ListUsers = () => {
  const [open, setOpen] = useState(true);
  const [rows, setRows] = useState([]);
  const [select, setSelect] = useState(0);

  const { blockUI, snackbarUI } = useUI();
  const history = useHistory();
  const state = store.getState();
  const accessToken = state.user.accessToken;
  if (!accessToken) {
    history.push("/login");
  }

  const location = useLocation();
  const classes = useStyles();
  const listStyle = ListStyles();

  const getListUser = async () => {
    try {
      blockUI.current.open(true);

      userService.init(accessToken);
      const r1 = await userService.list('?sort=-id&filter[role]=admin,manager,representative,Admin,Manager,Representative');
      setRows(r1.data);
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  useEffect(() => {
    (async function init() {
      await getListUser();
    })();
  }, []);

  return (
    <Container component="main" maxWidth="lg">
      {location.state && (
        <Collapse in={open}>
          <Alert severity="success" color="info" style={{align: 'center'}} onClose={() => {setOpen(false);}}>
            {location.state.message}
          </Alert>
        </Collapse>
      )}
      <Typography variant="h4">Users</Typography>
      <div>
        <Button
          variant="contained"
          color="primary"
          size="large"
          className={classes.button}
          startIcon={<AddIcon />}
          onClick={() => { history.push('create-user',{ id: 0 }) }}
          disabled={select[0] > 0}
        >
          Create user
        </Button>
        <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            startIcon={<Edit />}
            onClick={() => { history.push('create-user',{ id: select[0] }) }}
            disabled={!select[0]}
        >
          Edit user
        </Button>
      </div>
      <div style={{ height: 580, width: '100%' }}>
        <DataGrid className={clsx(listStyle.dataGrid, classes.root)} rows={rows} columns={columns}
                  pageSize={10}
                  checkboxSelection
                  selectionModel={select}
                  onSelectionModelChange={(e) => {
                    const newSelectionModel = e ?? [];
                    if (newSelectionModel.length > 1) {
                      const selectionSet = new Set(select);
                      const result = newSelectionModel.filter(
                        (s) => !selectionSet.has(s)
                      );
                      setSelect(result);
                    } else {
                      setSelect(newSelectionModel);
                    }
                  }}/>
      </div>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(ListUsers);

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    textTransform: 'none'
  },
  root: {
    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
      display: "none"
    }
  }
}));
