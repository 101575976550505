import * as React from 'react';
import {ThemeProvider} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";

export default function ColorSeason(props) {
  const {main = '#007FFF', dark = '#0066CC'} = props;

  return (
    <ThemeProvider
      theme={{
        palette: {
          primary: {
            main: main,
            dark: dark,
          },
        },
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          borderRadius: 1,
          bgcolor: 'primary.main',
          '&:hover': {
            bgcolor: 'primary.dark',
          },
        }}
      />
    </ThemeProvider>
  );
}
