import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { useUI } from '../../../app/context/ui';
import * as Yup from 'yup'; import {
  Container,
  Grid,
  Button,
  TextField,
  Box,
  Typography
} from '../../../components/shared/MaterialUI';
import AppHelper from '../../../helpers/AppHelper';
import { ModalStyle } from '../styles/ModalStyle';

const CustomerReferralType = (props) => {

  const { 
    typeModalReferralType,
    setOpenModalReferralType,
    dataEditReferralType,
    dataReferral,
    setDataReferral } = props;
  const classes = ModalStyle();
  const [initialValues,] = useState({
    customerReferralId: dataEditReferralType?.idReferral || '',
    nameReferral: dataEditReferralType?.nameReferral || '',
    id: dataEditReferralType?.id || '',
    service: dataEditReferralType?.service || '',
    name: dataEditReferralType?.name || '',
    phone: dataEditReferralType?.phone || '',
    comment: dataEditReferralType?.comment || '',
    secondChoice: dataEditReferralType?.secondChoice || '',
    secondPhone: dataEditReferralType?.secondPhone || '',
    secondComment: dataEditReferralType?.secondComment || '',
  });
  const { blockUI, snackbarUI } = useUI();
  const validationSchema = Yup.object({
    name: Yup
      .string('Enter name')
      .max(50, "Max. 50 characters")
      .required('Name is required'),
  });
  const onSubmit = (values) => {
    try {
      blockUI.current.open(true);
      if (typeModalReferralType === 'Add'){
        let newArray = dataReferral.filter((e)=>{  
          if(values.customerReferralId !== ''){
            if (e.id === values.customerReferralId) {
              e.types.push(values);
            }
          }else{
            if (e.name === values.nameReferral) {
              e.types.push(values);
            }
          }
          
          return e;
        });
        setDataReferral(newArray);
        setOpenModalReferralType(false);
      }else{
        let newArray = dataReferral.filter((e) => {
          if (values.customerReferralId !== '') {
            if (e.id === values.customerReferralId) {
              e.types[dataEditReferralType.index] = { ...values };
            }
          }else{
            if (e.name === values.nameReferral) {
              e.types[dataEditReferralType.index] = { ...values };
            }
          }
          return e;
        });
        setDataReferral(newArray);
        setOpenModalReferralType(false);
      }
      blockUI.current.open(false);
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  }  
  return (
    <Container component="main" maxWidth="sm" className={classes.ctnInner}>
      <Typography variant="h4" className="title">{typeModalReferralType} Customer Referral</Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            onSubmit(values).then(() => { });
          }}
          enableReinitialize={true}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              handleChange,
            } = props;
            return (
              <Form autoComplete="off">
                <Grid container spacing={3}>
                  <Grid item xs={3} className={classes.grdItem}>
                      <label>Services:</label>
                  </Grid>
                  <input type="hidden" name="id" id="id"/>
                  <input type="hidden" name="customerReferralId" id="customerReferralId" />
                  <input type="hidden" name="nameReferral" id="nameReferral" />
                  <Grid item xs={9}>
                    <TextField
                      variant="outlined"
                      autoFocus
                      fullWidth
                      id="service"
                      name="service"
                      value={values.service}
                      onChange={handleChange}
                      error={touched.services && Boolean(errors.service)}
                      helperText={
                        errors.service && touched.service ? errors.service : ""
                      }
                      className={classes.inputCustom}
                    />
                  </Grid>
                  <Grid item xs={3} className={classes.grdItem}>
                    <label>Name:</label>
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      error={touched.name && Boolean(errors.name)}
                      helperText={
                        errors.name && touched.name ? errors.name : ""
                      }
                      className={classes.inputCustom}
                    />
                  </Grid>
                  <Grid item xs={3} className={classes.grdItem}>
                    <label>Phone:</label>
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="phone"
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                      error={touched.phone && Boolean(errors.phone)}
                      helperText={
                        errors.phone && touched.phone ? errors.phone : ""
                      }
                      className={classes.inputCustom}
                    />
                  </Grid>
                  <Grid item xs={3} className={classes.grdItem}>
                    <label>Comments:</label>
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="comment"
                      name="comment"
                      value={values.comment}
                      onChange={handleChange}
                      error={touched.comment && Boolean(errors.comment)}
                      helperText={
                          errors.comment && touched.comment ? errors.comment : ""
                      }
                      className={classes.inputCustom}
                    />
                  </Grid>
                  <Grid item xs={3} className={classes.grdItem}>
                    <label>2nd Choice:</label>
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="secondChoice"
                      name="secondChoice"
                      value={values.secondChoice}
                      onChange={handleChange}
                      error={touched.secondChoice && Boolean(errors.secondChoice)}
                      helperText={
                        errors.secondChoice && touched.secondChoice ? errors.secondChoice : ""
                      }
                      className={classes.inputCustom}
                    />
                  </Grid>
                  <Grid item xs={3} className={classes.grdItem}>
                    <label>2nd Number:</label>
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="secondPhone"
                      name="secondPhone"
                      value={values.secondPhone}
                      onChange={handleChange}
                      error={touched.secondPhone && Boolean(errors.secondPhone)}
                      helperText={
                        errors.secondPhone && touched.secondPhone ? errors.secondPhone : ""
                      }
                      className={classes.inputCustom}
                    />
                  </Grid>
                  <Grid item xs={3} className={classes.grdItem}>
                    <label>2nd Comments:</label>
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="secondComment"
                      name="secondComment"
                      value={values.secondComment}
                      onChange={handleChange}
                      error={touched.secondComment && Boolean(errors.secondComment)}
                      helperText={
                        errors.secondComment && touched.secondComment ? errors.secondComment : ""
                      }
                      className={classes.inputCustom}
                    />
                  </Grid>
                </Grid>
                <Box pb={2}></Box>
                <Grid container justify="center">
                  <Button
                    variant="contained"
                    size="large"
                    className={classes.button}
                    onClick={() => { setOpenModalReferralType(false); }}
                  >
                    Cancel
                  </Button>  
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    color="primary"
                    className={classes.button}
                  >
                    Save
                  </Button>
                </Grid>
              </Form>
            );
          }}
        </Formik>
    </Container>
  );
};

export default CustomerReferralType;
