import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { DataGrid } from "@material-ui/data-grid";
import { Edit } from "@material-ui/icons";
import clsx from "clsx";
import {
  Typography,
  Button,
  Alert,
  Collapse,
  Container,
  Box,
} from "../../components/shared/MaterialUI";
import store from "../../redux/store";
import { ListStyles } from "../../assets/css";
import { useUI } from "../../app/context/ui";
import AppHelper from "../../helpers/AppHelper";
import { BbCrewService } from "../../services";

const columns = [
  { field: "firstName", headerName: "Name", flex: 0.3 },
  { field: "lastName", headerName: "Last name", width: 180 },
  { field: "email", headerName: "Email", width: 150 },
  { field: "joinDate", headerName: "Join date", width: 160 },
  { 
    field: "serviceMarket", 
    headerName: "Market", 
    flex: 0.3,
    renderCell: (params) => (params.row.serviceMarket.name),
  },
];

const crewService = new BbCrewService();

const BbList = () => {
  const [open, setOpen] = useState(true);
  const [rows, setRows] = useState([]);
  const [select, setSelect] = useState(0);

  const { blockUI, snackbarUI } = useUI();
  const history = useHistory();
  const state = store.getState();
  const accessToken = state.user.accessToken;
  if (!accessToken) {
    history.push("/login");
  }

  const location = useLocation();
  const classes = useStyles();
  const listStyle = ListStyles();

  const getBbMembersList = async () => {
    try {
      blockUI.current.open(true);
      crewService.init(accessToken);
      const res = await crewService.list(`?sort=-id`);
      setRows(res.data);
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  useEffect(() => {
    getBbMembersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container component="main" maxWidth="lg">
      {location.state && (
        <Collapse in={open}>
          <Alert
            severity="success"
            color="info"
            style={{ align: "center" }}
            onClose={() => {
              setOpen(false);
            }}
          >
            {location.state.message}
          </Alert>
        </Collapse>
      )}
      <Typography variant="h4">B&B Crews</Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={5}>
        <div>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            startIcon={<AddIcon />}
            onClick={() => {
              history.push("bb-create-crew-member", { id: 0 });
            }}
          >
            Create crew
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            startIcon={<Edit />}
            onClick={() => {
              history.push("bb-create-crew-member", { id: select[0] });
            }}
            disabled={select <= 0}
          >
            Edit crew
          </Button>
        </div>
      </Box>
      <div style={{ height: 580, width: "100%" }}>
        <DataGrid
          className={clsx(listStyle.dataGrid, classes.root)}
          rows={rows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          checkboxSelection
          selectionModel={select}
          onSelectionModelChange={(e) => {
            const newSelectionModel = e ?? [];
            if (newSelectionModel.length > 1) {
              const selectionSet = new Set(select);
              const result = newSelectionModel.filter(
                (s) => !selectionSet.has(s)
              );
              setSelect(result);
            } else {
              setSelect(newSelectionModel);
            }
          }}
        />
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(BbList);

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    textTransform: "none",
  },
  root: {
    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
      {
        display: "none",
      },
  },
}));
