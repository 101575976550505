import React, { useCallback, useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { Button, Container, Typography } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';

import { useUI } from '../../../app/context/ui';
import { ListStyles } from '../../../assets/css';
import AppHelper from '../../../helpers/AppHelper';
import { LocationService, LocationWebService } from '../../../services';
import { PaymentStyle } from './payment-style';

const locationService = new LocationService();
const locationWebService = new LocationWebService();

const PaymentList = (props) => {
  const classes = PaymentStyle();
  const listStyle = ListStyles();
  const { snackbarUI, blockUI } = useUI();
  const location = useLocation();

  const id = location.state?.id || props.user.id;
  const [rows, setRows] = useState([]);
  const history = useHistory();

  const handleRedirectUpdatePayment = (parentId) => {
    try {
      blockUI.current.open(true);
      if (location.pathname === '/payment-list') {
        history.push('/payment-configuration', { id: parentId });
      } else {
        history.push('/web/account/payment-configuration', { id: parentId });
      }
      blockUI.current.open(false);
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const columns = [
    { field: 'streetAddress', headerName: 'Address', flex: 0.4, minWidth: 250 },
    { field: 'accountNumber', headerName: 'Account number', flex: 0.3, minWidth: 180 },
    {
      field: 'id',
      headerName: ' ',
      flex: 0.2,
      minWidth: 200,
      renderCell: (params) => (
        <strong className='prob'>
          <Button
            variant='contained'
            color='primary'
            size='small'
            style={{ marginLeft: 16, textTransform: 'none' }}
            onClick={() => {
              handleRedirectUpdatePayment(params.row.parentId);
            }}
          >
            Edit payment information
          </Button>
        </strong>
      ),
    },
  ];

  const getInitialValues = useCallback(async () => {
    try {
      blockUI.current.open(true);
      locationWebService.getAccessToken();
      locationService.getAccessToken();
      let consult = `?filter[customer_attributes_id]=${id}&filter[status]=1&sort=id&filter[is_payment]=1`;
      let r1 =
        location.pathname === '/payment-list'
          ? await locationService.list(consult)
          : await locationWebService.list(consult);
      setRows(r1.data);
      blockUI.current.open(false);
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  }, [blockUI, id, snackbarUI, location]);

  const handleRedirectUpdateReturn = () => {
    location.pathname === '/payment-list'
      ? history.goBack()
      : (window.location.href = 'https://www.mowmanagers.com/');
  };

  useEffect(() => {
    (async function init() {
      await getInitialValues();
    })();
  }, [getInitialValues]);

  return (
    <Container component='main' maxWidth='lg'>
      <Typography variant='h4' style={{ marginBottom: '30px' }}>
        Property list
      </Typography>
      <div style={{ width: '100%' }}>
        <DataGrid
          autoHeight
          pagination
          rows={rows}
          columns={columns}
          className={listStyle.dataGrid}
        />
      </div>
      <div className={classes.wrapperBtnDone}>
        <Button
          variant='contained'
          color='primary'
          onClick={handleRedirectUpdateReturn}
          className='btnDone'
        >
          DONE
        </Button>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(PaymentList);
