import React from 'react';
import { Button } from '@material-ui/core';

export const columns = (handleLinkContextMenu, isLookupWithCellphone = false) => {

  const renderCellWithContextMenu = (params) => {
    const dataStateRow = {
      page: params.api.getState().pagination.page,
      rowsIds: params.api.getState().rows.allRows,
    };

    const onContextMenu = (event) => {
      if (handleLinkContextMenu) {
        handleLinkContextMenu(params.row, event, dataStateRow);
      }
    };

    if (params.field === 'statusName') {
      return (
        <div onContextMenu={onContextMenu}>
          <Button
            variant='contained'
            color='primary'
            size='small'
            style={{ textTransform: 'none', minWidth: 130, pointerEvents: 'none' }}
            disableRipple
          >
            {params.value}
          </Button>
        </div>
      );
    } else {
      return (
        <div onContextMenu={onContextMenu} style={{ width: '100%', height: '100%' }}>
          {params.value}
        </div>
      );
    }
  };

  return [
    {
      field: 'firstName',
      headerName: 'Name',
      width: (isLookupWithCellphone) ? 300 : 200,
      disableColumnMenu: true,
      renderCell: renderCellWithContextMenu,
    },
    {
      field: 'lastName',
      headerName: 'Last name',
      width: 200,
      disableColumnMenu: true,
      renderCell: renderCellWithContextMenu,
    },
    {
      field: 'serviceAddress',
      headerName: 'Address',
      width: 250,
      disableColumnMenu: true,
      hide: (isLookupWithCellphone) ? true : false,
      renderCell: renderCellWithContextMenu,
    },
    {
      field: 'zipcode',
      headerName: 'Zip',
      minWidth: 30,
      disableColumnMenu: true,
      hide: (isLookupWithCellphone) ? true : false,
      renderCell: renderCellWithContextMenu,
    },
    {
      field: 'contactNumber',
      headerName: 'Phone number',
      width: 150,
      disableColumnMenu: true,
      hide: true,
      renderCell: renderCellWithContextMenu,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 150,
      disableColumnMenu: true,
      hide: true,
      renderCell: renderCellWithContextMenu,
    },
    {
      field: 'customerId',
      headerName: 'Account No',
      width: 150,
      disableColumnMenu: true,
      hide: (isLookupWithCellphone) ? true : false,
      renderCell: renderCellWithContextMenu,
    },
    {
      field: 'phoneMarket',
      headerName: 'Phone Number',
      width: 300,
      disableColumnMenu: true,
      hide: (isLookupWithCellphone) ? false : true,
      renderCell: renderCellWithContextMenu,
    },
    {
      field: 'marketName',
      headerName: 'Market',
      width: (isLookupWithCellphone) ? 200 : 150,
      disableColumnMenu: true,
      renderCell: renderCellWithContextMenu,
    },
    {
      field: 'statusName',
      headerName: 'Status',
      width: 180,
      headerAlign: 'center',
      align: 'center',
      disableColumnMenu: true,
      renderCell: renderCellWithContextMenu,
    },
  ];
};
