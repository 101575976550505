import React, { useState } from 'react';
import { Box, Button, Grid, Modal, IconButton, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Formik, Form } from 'formik';
import clsx from 'clsx';
import {has as _has} from 'lodash';

import { WizardStyles } from '../../../assets/css';
import { AppStyle } from '../../../assets/css/app/AppStyle';
import PriceService from "../../../services/PriceService";
import { useUI } from "../../../app/context/ui";
import store from "../../../redux/store";
import AppHelper from "../../../helpers/AppHelper";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const PriceModal = (props) => {
  const {open, setOpen, priceSelected, getData} = props;

  const [modalStyle] = useState(getModalStyle);
  const wizardStyle = WizardStyles();
  const appStyle = AppStyle();

  const id = priceSelected ? priceSelected.id : '';
  const initialValues = {
    detail: priceSelected ? priceSelected.detail ?? '' : '',
  };

  const { blockUI, snackbarUI } = useUI();
	const state = store.getState();
  const accessToken = state.user.accessToken;

  if (!accessToken) {
    history.push("/login");
  }

	const priceService = new PriceService();

  const onSubmit = async (values) => {
    try {
      blockUI.current.open(true);
      priceService.init(accessToken);
      await priceService.updateLog(id, values);
      blockUI.current.open(false);
      getData();
      setOpen(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      style={{ zIndex: 1250 }}
    >
      <div style={modalStyle} className={wizardStyle.paperModal}>
        <Grid container alignItems="center" spacing={2}>
          <Grid container item xs={12}>
            <Grid item xs={10}>
              <h2 className={clsx(appStyle.mrg0V)}>Price update detail</h2>
            </Grid>
            <Grid item xs={2}>
              <IconButton
                color="primary"
                aria-label="close"
                className={clsx(appStyle.pdg0, appStyle.btnRight)}
                onClick={() => setOpen(false)}
              >
                <CloseIcon/>
              </IconButton>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              enableReinitialize={true}
            >
              {(props) => {
              const { values, errors, handleChange, handleSubmit } = props;
              return (
                <Form autoComplete="off" style={{width: '100%'}}>
                  <Box display="flex" flexDirection="column" width="100%">
                    <span style={{fontWeight: 'bold'}}>Details:</span>
                    <TextField
                      error={_has(errors, 'detail')}
                      name='detail'
                      value={values.detail}
                      onChange={handleChange}
                      multiline
                      rows={4}
                      variant='outlined'
                      helperText={_has(errors, 'detail') ? errors.description : ''}
                      style={{ width: '100%' }}
                      fullWidth={true}
                      disabled={values.disabled}
                      placeholder="Write here"
                    />
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="row"
                      width="100%"
                      justifyContent="center"
                      style={{
                      gap: '24px',
                      padding: '12px 64px 12px 64px',
                      marginTop: '24px'
                      }}
                    >
                      <Button
                        variant='contained'
                        color='default'
                        style={{width: '320px'}}
                        onClick={() => setOpen(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant='contained'
                        color='primary'
                        style={{width: '320px'}}
                        onClick={handleSubmit}
                      >
                        Save
                      </Button>
                  </Box>
                </Form>
              );}}
            </Formik>
          </Grid>
        </Grid>
      </div>
    </Modal>
  )
}

export default PriceModal;
