import { makeStyles } from '@material-ui/core/styles';

export const PaymentStyle = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapperPaymentOption:{
    border: 'solid 1px green',
    borderRadius: '9px',
    padding: '0px 30px 28px 30px',
    '& .titlePayment':{
      fontSize: '23px',
      color: '#12802f',
      fontWeight: '200'
    },
    '& .wrapperButton':{
      textAlign: 'center'
    },
    '& .infoTitle':{
      fontSize: '14px',
      textAlign: 'center',
      fontWeight: '100',
      margin: '0px auto',
      color: 'gray'
    }
  },
  rootNavigation:{
    width: '100%',
    backgroundColor: '#fafafa',
    '& .MuiBottomNavigationAction-root .Mui-selected':{
      color: 'green',
      fontWeight: 'bold',
      borderBottom: '1px solid green',
      paddingBottom: '3px'
    }
  },
  label:{
    padding: '20px 0px',
    fontWeight: 'bold',
    color: 'black',
    '& span':{
      color: 'red'
    }
  },
  infoTitleEcheck:{
    fontSize: '14px',
    textAlign: 'center',
    fontWeight: '100',
    margin: '15px 0px',
    color: 'gray'
  },
  btnSubmit:{
    margin: '0px auto',
    marginTop: '24px',
    borderRadius: '20px',
    padding: '9px 30px',
    backgroundColor: '#00652d',
    fontWeight: '800',
    letterSpacing: '3px',
    fontSize: '13px'
  },
  wrapperBtnDone: {
    textAlign: 'center',
    paddingTop: '19px',
    '& .btnDone':{
      padding: '8px 117px',
      backgroundColor: '#11aa39',
      borderRadius: '15px'
    }
  }
}));
