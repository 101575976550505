import {createStore} from 'redux';
import rootReducer from './reducers/rootReducer';
//import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';

import { loadState, saveState } from './state-handler';
const initialState = loadState();
//const middleware = [thunk];

/*const store = createStore(rootReducer, initialState, compose(applyMiddleware(...middleware),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
));*/

//const store = createStore(rootReducer, initialState, compose(applyMiddleware(...middleware)));
const store = createStore(rootReducer, initialState, composeWithDevTools());


store.subscribe(() => {
  saveState(store.getState())
});

export default store;
