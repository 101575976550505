import AppService from "./AppService";

class TaskService extends AppService {

    constructor() {
        super();
        this.path = this.getPath('tasks');
    }

    reports({ page = 1, pageSize = 50, userId }) {
        let params = 'render=paginate&sort=-id';
        params += `&limit=${pageSize}`;
        params += `&filter[user_id]=${userId}`;
        params += `&page=${page}`;
        return this.http.get(`${this.path}?${params}`);
    }

    updateBulk(price, indicator, userId) {
        let params = 'type=update-bulk-prices';
        params += `&price=${price}`;
        params += `&indicator=${indicator}`;
        params += `&user=${userId}`;
        return this.http.get(`${this.path}/add-to-queue?${params}`);
    }

    addLookupReport(queryParams = '') {
        return this.http.get(`${this.path}/add-to-queue?type=lookup-report${queryParams}`);
    }

    addLookupReportBb(queryParams = '') {
        return this.http.get(`${this.path}/add-to-queue?type=lookup-report-bb${queryParams}`);
    }

    addLookupReconciliationReport(params = '') {
        return this.http.get(`${this.path}/add-to-queue?type=reconciliation-report`, { params });
    }

    addCustomerReport(params, isLookupWithCellphone = false) {
        let type = '';
        const originalParams = params;
        if(isLookupWithCellphone){
            type = 'contact-phone';
            params = {
                type_doc: params.type_doc,
                sort: '-id',
                ['filter[status]']: 1,
                ['filter[status_orders]']: 1,
                ['filter[has_phone]']: 1,
                ['filter[isCellphone]']: 1,
                nameDoc: params.nameDoc,
            };
            if(originalParams.ids){
                params = {ids:originalParams.ids, ...params}
            }
        }else{
            const defaultType = 'customer-report';
            type = params.typeLookup && params.typeLookup !== 'lookup'  ? params.typeLookup : defaultType;
            if(type === 'lookup-source'){
                delete params['filter[status_orders]'];
            }
        }
        return this.http.get(`${this.path}/add-to-queue?type=${type}`, { params });
    }

    addLookupBushBedReport(params = '') {
        return this.http.get(`${this.path}/add-to-queue?type=bush-bed-report`, { params });
    }

    delete(taskId) {
        return this.http.delete(`${this.path}/${taskId}`);
    }

    list(params = '') {
        const queryParams = params ? params : '';
        return this.http.get(`${this.path}${queryParams}`);
    }
}

export default TaskService;
