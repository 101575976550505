import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

import { makeStyles } from "@material-ui/core/styles";
import { DataGrid } from "@material-ui/data-grid";
import clsx from "clsx";
import UploadDocuments from "./UploadDocuments";

import {
  Alert,
  Box,
  Button,
  Collapse,
  Container,
  Typography,
} from "../../components/shared/MaterialUI";
import store from "../../redux/store";
import { ListStyles } from "../../assets/css";
import { useUI } from "../../app/context/ui";
import AppHelper from "../../helpers/AppHelper";
import CrewService from "../../services/CrewService";

const columns = [
  { field: "crewNumber", headerName: "Crew number", flex: 0.4 },
  { field: "createdAt", headerName: "Date", width: 150 },
  { field: "amount", headerName: "Amount", width: 150 },
  { field: "note", headerName: "Note", width: 150 },
];

const listAdapter = (data) => {
  return data.map((item) => {
    return {
      id: item.id,
      crewNumber: item.crewNumber,
      createdAt: new Date(item.createdAt).toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'UTC'}),
      amount: `$${item.amount}`,
      note: item.note,
    };
  });
}

const crewService = new CrewService();

const UploadPayment = () => {
  const [open, setOpen] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [rows, setRows] = useState([]);

  const { blockUI, snackbarUI } = useUI();
  const history = useHistory();
  const state = store.getState();
  const accessToken = state.user.accessToken;
  if (!accessToken) {
    history.push("/login");
  }

  const location = useLocation();
  const classes = useStyles();
  const listStyle = ListStyles();

  const getCrewMembersList = async () => {
    try {
      blockUI.current.open(true);
      crewService.init(accessToken);
      const res = await crewService.getCrewNumbers();
      setRows(listAdapter(res.data.data));
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  useEffect(() => {
    if(!openModal) getCrewMembersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  return (
    <Container component="main" maxWidth="lg">
      {location.state && (
        <Collapse in={open}>
          <Alert
            severity="success"
            color="info"
            style={{ align: "center" }}
            onClose={() => {
              setOpen(false);
            }}
          >
            {location.state.message}
          </Alert>
        </Collapse>
      )}
      <Box
        display="flex"
        justifyContent="space-between"
      >
        <Typography variant="h4">Crew payments</Typography>
        <Box>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            className={classes.button}
            onClick={() => setOpenModal(true)}
          >
            Upload New Payment
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            className={classes.button}
            startIcon={ <NavigateBeforeIcon/>}
            onClick={() => history.push("/crew-members")}
          >
            Back
          </Button>
        </Box>
      </Box>
      <div style={{ height: 580, width: "100%", marginTop:20 }}>
        <DataGrid
          className={clsx(listStyle.dataGrid, classes.root)}
          rows={rows}
          columns={columns}
          pageSize={50}
          rowsPerPageOptions={[10]}
        />
      </div>
      {
        openModal && (
          <UploadDocuments open={openModal} setOpen={setOpenModal} />
        )
      }
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(UploadPayment);

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    textTransform: "none",
  },
  root: {
    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
      {
        display: "none",
      },
  },
}));
