import '@fontsource/roboto';
import '@fontsource/roboto-condensed';
import '@fontsource/open-sans';

import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#242323',
    },
    secondary: {
      main: '#10AA39',
    },
    text: {
      // secondary: 'rgba(0, 0, 0, 0.87)'
    },
  },
  typography: {
    color: '#242323',
    fontFamily: ['Roboto', 'Roboto Condensed', 'Open Sans'].join(','),
    fontSize: 16,
    fontWeight: 400,
    style: 'normal',
    lineHeight: '21.88px',
    button: {
      textTransform: 'none',
    },
    body1: {
      fontSize: 16,
    },
  },
  focused: {},
  disabled: {},
  error: {},
  overrides: {
    MuiAlert: {
      standardInfo: {
        backgroundColor: 'transparent',
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: '1px solid #484646',
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottom: '1px solid #484646',
        },
        '&:focused:after': {
          borderBottom: '1px solid #484646',
        },
      },
    },
    MuiInputBase: {
      root: {
        fontSize: 16,
      },
    },
    MuiDialog: {
      root: {
        fontSize: 16,
      },
      paper: {
        padding: '25px',
      },
    },
    MuiDialogContentText: {
      root: {
        color: 'rgba(0, 0, 0, 0.87) !important',
      },
    },
  },
});
