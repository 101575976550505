import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  Container,
  CssBaseline,
  Grid,
  Typography,
  Button,
  TextField,
  IconButton,
  Input,
  InputAdornment,
  Visibility,
  VisibilityOff,
  CardMedia, Alert,
} from '../../components/shared/MaterialUI';

import { connect } from 'react-redux';
import { addUser } from '../../redux/actions/user';
import store from '../../redux/store';

import { useHistory, Link } from 'react-router-dom';
import {Collapse} from '@material-ui/core';

import { useUI } from '../../app/context/ui';

import AuthService from '../../services/AuthService';
import UserService from '../../services/UserService';

import logo from '../../assets/images/login-logo.png';
import {LoginStyles} from '../../assets/css';
import _ from 'lodash';
import {useQuery} from '../../helpers/useQuery';

const LoginPage = (props) => {
  let query = useQuery();
  const [hasError, setHasError] = useState({});
  const [requestFailed, setRequestFailed] = useState(false);
  const [open, setOpen] = useState(true);

  let locationState = false;
  if (!_.isUndefined(props.location)) {
    locationState = _.isUndefined(props.location.state) ? false : props.location.state;
  }

  // if error code 401
  if (query.get('et')) {
    props.dispatch({ type: 'LOGOUT' });
  }

  const loginStyle = LoginStyles();

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const { blockUI } = useUI();
  const history = useHistory();

  const state = store.getState();
  const accessToken = state.user.accessToken;
  if (accessToken) {
    history.push('/dashboard');
  }

  const authService = new AuthService();
  const userService = new UserService();

  useEffect(() => {
    if (!setRequestFailed) {
      setHasError("");
    }
  }, [requestFailed]);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string().min(8, "Minimum 8 characters").required("Password is required"),
  });

  const onSubmit = async (values) => {
    try {
      blockUI.current.open(true);
      setRequestFailed(false);
      // authentication
      const r1 = await authService.login(values);
      const accessToken = r1.data.accessToken;
      userService.setAccessToken(accessToken);
      // get current user
      const r2 = await userService.me();
      let payload = { ...r2.data, accessToken};
      props.dispatch(addUser(payload));
      blockUI.current.open(false);
      history.push("/dashboard");
    } catch (e) {
      blockUI.current.open(false);
      setRequestFailed(true);
      setHasError({ message: e.response.data.message });
    }
  };

  return (
    <Container component="main" maxWidth="lg" className={loginStyle.bgMain}>
      <Typography component="div" className={loginStyle.panForm}>
        <CssBaseline />
        <Typography component="div" className={loginStyle.alignItemsAndJustifyContent}>
          <CardMedia
            className={loginStyle.cardLogo}
            component="img"
            image={logo}
            title="Mow Manager"
          />
        </Typography>
        <Typography component="div">
          {requestFailed && (
            <p className={loginStyle.formError}>{hasError.message}</p>
          )}
        </Typography>
        {locationState && (
          <Collapse in={open}>
            <Alert severity="success" color="info" style={{align: 'center'}} onClose={() => {setOpen(false);}}>
              {locationState.message}
            </Alert>
          </Collapse>
        )}
        <Typography component="div" className={loginStyle.formMain}>
          <Formik
            initialValues={{
              password: "",
              showPassword: false,
              email: "",
            }}
            onSubmit={(values) => onSubmit(values)}
            validationSchema={LoginSchema}
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                handleBlur,
                handleChange,
                setFieldValue,
              } = props;
              return (
                <Form>
                  <TextField
                    margin="normal"
                    className={classNames(loginStyle.inputEmail)}
                    required
                    fullWidth
                    name="email"
                    id="email"
                    autoComplete="email"
                    autoFocus
                    value={values.email}
                    type="email"
                    helperText={
                      errors.email && touched.email ? errors.email : ""
                    }
                    error={!!(errors.email && touched.email)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Email"
                  />

                  <Input
                    margin="none"
                    className={classNames(loginStyle.inputPassword)}
                    required
                    fullWidth
                    name="password"
                    id="standard-adornment-password"
                    type={values.showPassword ? "text" : "password"}
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Password"
                    error={!!(errors.password && touched.password)}
                    inputProps={{ className: loginStyle.input }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          className={loginStyle.icoShowPassword}
                          aria-label="toggle password visibility"
                          onClick={() => {
                            setFieldValue("showPassword", !values.showPassword);
                          }}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {values.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {errors.password && touched.password ? (
                    <p className={classNames(loginStyle.formError)}>
                      {errors.password}
                    </p>
                  ) : null}
                  <Grid container justify="flex-end" className={loginStyle.gridForgotPassword}>
                    <Grid item>
                      <Link to="/forgot-password" variant="body2" className={loginStyle.link}>
                        Forgot your password?
                      </Link>
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={loginStyle.btnSubmit}
                  >
                    Login
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </Typography>
      </Typography>
    </Container>
  )
};

export default connect(null)(LoginPage);
