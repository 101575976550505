import AppService from './AppService';

class LogicService extends AppService {

  constructor() {
    super();
    this.path = this.getPath('logics');
  }

  proccessBaggrass(params = '') {
    const queryParams = params ? params : '';
    return this.http.get(`${this.path}/baggrass?${queryParams}`);
  }
}

export default LogicService;
