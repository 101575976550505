import React, { useEffect, useState, useCallback } from 'react';
import Activity from './Activity';
import { ActivityListStyle } from './styles/ActivityListStyle';
import CustomerService from '../../services/CustomerService';
import { useUI } from '../../app/context/ui';
import AppHelper from '../../helpers/AppHelper';
import { connect } from 'react-redux';

const customerService = new CustomerService();

const ActivityList = (props) => {
  const { mm } = props;
  const classes = ActivityListStyle();
  const { blockUI, snackbarUI } = useUI();
  const [activities, setActivities] = useState([]);
  customerService.getAccessToken();

  const getDataActivities = useCallback(async () => {
    try {
      blockUI.current.open(true);
      const { data: activities } = await customerService.activities(mm.customer.options.id, props.year);
      setActivities(activities);
      blockUI.current.open(false);
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  }, [blockUI, snackbarUI, props.year, mm.customer.options.id]);

  useEffect(() => {
    (async function init() {
      await getDataActivities();
    })();
  }, [props.year, props.dataRender]);

  return (
    <div className={classes.ctnActivityList}>
      <Activity activities={activities}/>
    </div>
  )
}

const mapsStateToProps = (state) => {
  return {
    mm: state.mm,
  };
};
export default connect(mapsStateToProps)(ActivityList)
