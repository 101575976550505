import React, { useEffect, useState } from 'react';

import parse from 'autosuggest-highlight/parse';
import { useField } from 'formik';
import _, { at } from 'lodash';
import useGoogle
  from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

import TextField from '@material-ui/core/TextField';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { Grid, Typography } from './MaterialUI';

const GoogleAutocomplete = (props) => {
  const { ...rest } = props;
  const { formikRef, setMapText, setMapLocation, setMapZoom } = props;
  const [field, meta] = useField(props);
  const [gacValue, setGacValue] = useState(null);
  const [gacOptions, setGacOptions] = useState([]);
  const [name, setName] = useState('serviceAddress');
  const { placesService, placePredictions, getPlacePredictions } = useGoogle({
    options: {
      componentRestrictions: { country: 'us' },
    },
  });

  useEffect(() => {
    if (placePredictions.length) {
      setGacOptions(placePredictions);
    }
  }, [placePredictions]);

  useEffect(() => {
    if (!_.isUndefined(field.value)) {
      setGacValue(field.value);
      setName(field.name);
    }
  }, [formikRef]);

  useEffect(() => {
    if (!_.isUndefined(field.value)) {
      setGacValue(field.value);
      setName(field.name);
    }
  }, [props]);

  return (
    <Autocomplete
      id='mm-google-map'
      style={{ width: '100%' }}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      value={gacValue}
      filterOptions={(x) => x}
      options={gacOptions}
      getOptionSelected={(option, value) => {
        return option.description === value;
      }}
      onChange={(event, newValue) => {
        if (
          formikRef.current &&
          newValue &&
          _.has(newValue, 'description') &&
          _.has(newValue, 'place_id')
        ) {
          formikRef.current.setFieldValue(name, newValue['description']);
          placesService?.getDetails({ placeId: newValue['place_id'] }, (place) => {
            if (setMapLocation !== undefined && setMapZoom !== undefined) {
              setMapLocation({
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
              });
              setMapZoom(19);
            }
          });
          setGacValue(newValue['description']);
          if (setMapText !== undefined) {
            setMapText(newValue['description']);
          }
        }
      }}
      onInputChange={(event, newInputValue) => {
        getPlacePredictions({ input: newInputValue });
      }}
      renderInput={(params) => {
        function _renderHelperText() {
          const [touched, error] = at(meta, 'touched', 'error');
          if (touched && error) {
            return error;
          }
        }

        return (
          <TextField
            {...params}
            variant='outlined'
            size='small'
            fullWidth
            autoComplete='off'
            error={meta.touched && meta.error && true}
            helperText={_renderHelperText()}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
            }}
          />
        );
      }}
      renderOption={(option) => {
        const matches = option.matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        );

        return (
          <Grid container alignItems='center'>
            <Grid item>
              <LocationOnIcon />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}
              <Typography variant='body2' color='textSecondary'>
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
      {...rest}
    />
  );
};

export default GoogleAutocomplete;
