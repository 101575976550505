import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';

import { FieldArray, Form, Formik, useFormikContext } from 'formik';
import { clone as _clone, map as _map, mapKeys as _mapKeys } from 'lodash';
import NumberFormat from 'react-number-format';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { ButtonGroup, Grid, InputLabel, Modal } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';

import { useUI } from '../../../app/context/ui';
import { addCustomer } from '../../../app/store/mm/customerSlice';
import { WizardStyles } from '../../../assets/css';
import GoogleAutocomplete from '../../../components/shared/GoogleAutocomplete';
import { Button } from '../../../components/shared/MaterialUI';
import { InputField } from '../../../forms';
import AutoSave from '../../../forms/AutoSave';
import AppHelper from '../../../helpers/AppHelper';
import {
  CustomerService, LocationService, SrvService,
} from '../../../services';

const customerService = new CustomerService();
const locationService = new LocationService();

let dlgSettings = {
  confirm: true,
  btn: {
    close: 'Cancel',
    confirm: 'Yes',
  },
  onConfirm: () => {},
};

const Step2 = (props) => {
  const [isValidZip, setIsValidZip] = useState([]);
  const [isValidAccount, setIsValidAccount] = useState([]);
  const [open, setOpen] = useState(false);
  const [item, setItem] = useState(null);
  const [body] = useState(<div>A</div>);
  const [mapLocation, setMapLocation] = useState({
    lat: 37.09024,
    lng: -95.712891,
  });

  const [mapZoom, setMapZoom] = useState(2);
  const [mapText, setMapText] = useState('');
  const [initialValues, setInitialValues] = useState({ locations: [] });
  const { blockUI, snackbarUI, dialogUI } = useUI();
  const {
    formField: { zipcode },
    setLocationState,
  } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const formikLocations = useRef();
  const { formikRef } = props;
  const { values: formValues } = useFormikContext();
  const { id } = formValues;

  const newValue = {
    id: 0,
    customerAttributesId: id,
    streetAddress: null,
    accountNumber: null,
    zipCode: null,
    mapLocation: null,
    mapZoom: null,
    mapText: null,
  };

  const validationSchema = Yup.object().shape({
    locations: Yup.array().of(
      Yup.object().shape({
        streetAddress: Yup.string().nullable(),
        accountNumber: Yup.string().nullable(),
        zipCode: Yup.string()
          .nullable()
          .matches(/^[0-9]{5}$/, `${zipcode.invalidErrorMsg}`),
      })
    ),
  });

  const wizardStyle = WizardStyles();
  const srvService = new SrvService();

  useEffect(() => {
    if (formikLocations.current && item >= 0) {
      formikLocations.current.setFieldValue(`locations.${item}.mapText`, mapText);
      formikLocations.current.setFieldValue(`locations.${item}.mapLocation`, mapLocation);
      formikLocations.current.setFieldValue(`locations.${item}.mapZoom`, mapZoom);
    }
  }, [item, mapText, mapLocation, mapZoom]);

  const onKeyPress = (e) => {
    if (e.key === 'Enter' || e.target.value.length === 5) {
      // write your functionality here
      getSrvZipCode(e.target.value);
    }
  };

  const onKeyPressAccount = (e, index, arrayHelper) => {
    if (e.key === 'Enter' || e.target.value.length >= 5) {
      // write your functionality here
      getAccountNumber(e.target.value, index, arrayHelper);
    }
  };

  const getSrvZipCode = async (zip) => {
    try {
      srvService.getAccessToken();
      await srvService.zipCode(`zip-code/${zip}`);
    } catch (e) {
      const values = isValidZip || [];
      const index = values.indexOf(zip);
      if (index === -1) {
        values.push(zip);
      }
      setIsValidZip(values);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const handleRedirectCustomer = async (account) => {
    customerService.getAccessToken();
    await customerService.list(`?filter[customer_id]=${account}&sort=id&limit=1`).then((res) => {
      customerService.read(res.data[0].id).then((res) => {
        history.push({ state: { id: res.data.id } });
        setLocationState({ id: res.data.id });
        dispatch(addCustomer({ ...res.data }));
      });
    });
    // (r1.data.length > 0) && dispatch(addCustomer({...r1.data[0]}));
  };

  const getAccountNumber = async (account, index, arrayHelper) => {
    try {
      customerService.getAccessToken();
      const {data: accountVerify} = await customerService.verifyAccountNumber(account);
      if(accountVerify){
        const r1 = await customerService.list(`?filter[customer_id]=${account}&sort=id&limit=1`);
        if (r1.data.length > 0) {
          let data = r1.data[0];
          let indexArray = arrayHelper.form.values.locations.findIndex((e) => e.id == index);
          formikLocations.current.setFieldValue(
            `locations.${indexArray}.mapText`,
            data.mailingAddress
          );
          formikLocations.current.setFieldValue(`locations.${indexArray}.mapLocation`, '{}');
          formikLocations.current.setFieldValue(`locations.${indexArray}.mapZoom`, 19);
          formikLocations.current.setFieldValue(
            `locations.${indexArray}.streetAddress`,
            data.mailingAddress
          );
          formikLocations.current.setFieldValue(
            `locations.${indexArray}.zipCode`,
            data.mailingZipCode
          );
          formikLocations.current.setFieldValue(`locations.${indexArray}.status`, 1);
        }
      }
      
    } catch (e) {
      const values = isValidAccount || [];
      const index = values.indexOf(account);
      if (index === -1) {
        values.push(account);
      }
      setIsValidAccount(values);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const getInitialValues = useCallback(async () => {
    try {
      locationService.getAccessToken();
      const r1 = await locationService.list(
        `?filter[customer_attributes_id]=${id}&filter[status]=1&sort=id`
      );
      _map(r1.data, function (values) {
        _mapKeys(values, function (val, key) {
          if (key === 'mapLocation') {
            values[key] = JSON.parse(val);
          } else {
            values[key] = val;
          }
        });
      });
      return {
        locations: r1.data,
      };
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  }, [id, snackbarUI]);

  const onSubmit = useCallback(
    async (values, index) => {
      try {
        const locationId = values.id || 0;
        const locationValues = _clone(values);
        delete locationValues.id;
        customerService.getAccessToken();
        const {data: accountVerify} = await customerService.verifyAccountNumber(locationValues.accountNumber);

        if(accountVerify){
          if (
            locationValues.streetAddress &&
            locationValues.accountNumber &&
            locationValues.zipCode
          ) {
            if (locationId > 0) {
              locationService.getAccessToken();
              await locationService.update(locationValues, locationId);
            } else {
              locationService.getAccessToken();
              const r1 = await locationService.create(locationValues);
              if (formikLocations.current) {
                formikLocations.current.setFieldValue(`locations.${index}.id`, r1.data.customerId);
              }
            }
          } else {
            blockUI.current.open(false);
            AppHelper.checkError(
              { response: 401, message: 'Fill in the 3 fields to assign a location' },
              snackbarUI
            );
          }
        }
        
      } catch (e) {
        blockUI.current.open(false);
        AppHelper.checkError(e, snackbarUI);
      }
    },
    [blockUI, snackbarUI]
  );

  const handleSubmit = useCallback(
    (values, actions) => {
      try {
        blockUI.current.open(true);
        values.locations.map(function (values, index) {
          if(values.accountNumber && values.zipCode){
            (async function init() {
              await onSubmit(values, index);
            })();
          }
        });

        actions.setSubmitting(false);
        blockUI.current.open(false);
        if (formikRef.current) {
          _mapKeys(values, function (val, key) {
            if(val[0].accountNumber && val[0].zipCode){
              formikRef.current.setFieldValue(key, val);
            }
          });
        }
      } catch (e) {
        blockUI.current.open(false);
      }
    },
    [blockUI, formikRef, onSubmit]
  );

  const handleSetterIdDelete = (data, arrayHelpers) => {
    dlgSettings = {
      ...dlgSettings,
      onConfirm: () => {
        onDelete(data, arrayHelpers);
      },
    };
    dialogUI.current.open('', 'Are you sure you would like to remove this location?', dlgSettings);
  };

  const onDelete = async (data, arrayHelpers) => {
    try {
      blockUI.current.open(true);
      locationService.getAccessToken();
      await locationService.delete(data.id);
      let indexArray = arrayHelpers.form.values.locations.findIndex((e) => e.id == data.id);
      arrayHelpers.remove(indexArray);
      blockUI.current.open(false);
      dialogUI.current.close();
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  useEffect(() => {
    (async function init() {
      const r = await getInitialValues();
      setInitialValues(r);
    })();
  }, [getInitialValues]);

  return (
    <>
      <Formik
        innerRef={formikLocations}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize={true}
      >
        {({ values }) => (
          <Form autoComplete='off'>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FieldArray
                  name='locations'
                  render={(arrayHelpers) => (
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <Button
                          variant='contained'
                          color='primary'
                          size='small'
                          startIcon={<AddIcon className='pin-icon' />}
                          onClick={() => arrayHelpers.push(newValue)}
                        >
                          Add locations
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        {values.locations &&
                          values.locations.length > 0 &&
                          values.locations.map((friend, index) => (
                            <div key={index}>
                              <Grid container spacing={1}>
                                <Grid item xs={12} sm={4}>
                                  <InputLabel className={wizardStyle.label}>
                                    Street address *
                                  </InputLabel>
                                  <GoogleAutocomplete
                                    formikRef={formikLocations}
                                    name={`locations.${index}.streetAddress`}
                                    setMapText={setMapText}
                                    setMapLocation={setMapLocation}
                                    setMapZoom={setMapZoom}
                                    onClose={() => setItem(index)}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <InputLabel className={wizardStyle.label}>
                                    Account Number *
                                  </InputLabel>
                                  <InputField
                                    name={`locations.${index}.accountNumber`}
                                    InputProps={{
                                      inputComponent: NumberFormat,
                                    }}
                                    onKeyUp={(e) => {
                                      onKeyPressAccount(
                                        e,
                                        values.locations[index].id,
                                        arrayHelpers
                                      );
                                    }}
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                  <InputLabel className={wizardStyle.label}>Zip code *</InputLabel>
                                  <InputField
                                    name={`locations.${index}.zipCode`}
                                    inputProps={{
                                      min: 0,
                                      style: { textAlign: 'center' },
                                      minLength: 1,
                                      maxLength: 5,
                                    }}
                                    InputProps={{
                                      inputComponent: NumberFormat,
                                    }}
                                    onKeyUp={onKeyPress}
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                  <br />
                                  <ButtonGroup variant='contained' style={{ marginTop: '7px' }}>
                                    <Button
                                      variant='contained'
                                      size='small'
                                      startIcon={<SearchIcon className='pin-icon' />}
                                      onClick={() => {
                                        handleRedirectCustomer(
                                          values.locations[index].accountNumber
                                        );
                                      }}
                                    />
                                    <Button
                                      variant='contained'
                                      size='small'
                                      startIcon={<CloseIcon className='pin-icon' />}
                                      onClick={() => {
                                        const id = values.locations[index].id;
                                        if (id > 0) {
                                          handleSetterIdDelete(
                                            values.locations[index],
                                            arrayHelpers
                                          );
                                        }
                                      }}
                                    />
                                  </ButtonGroup>
                                </Grid>
                              </Grid>
                            </div>
                          ))}
                        {/* Add a new empty item at the end of the list */}
                      </Grid>
                    </Grid>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <AutoSave debounceMs={500} id={id} />
              </Grid>
              <Grid item xs={12}>
                <div>
                  <Modal
                    open={open}
                    onClose={() => {
                      setOpen(false);
                    }}
                    aria-labelledby='simple-modal-title'
                    aria-describedby='simple-modal-description'
                  >
                    {body}
                  </Modal>
                </div>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Step2;
