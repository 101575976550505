import { makeStyles } from '@material-ui/core/styles';

export const YesNoQStyles = makeStyles(() => ({
  inputContainer: {
    width: '100%',
  },
  label: {
    marginBottom: '24px',
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#676767',
    lineHeight: '32px',
    fontFamily: 'Roboto Condensed',
    '& span': {
      color: 'red',
    },
  },
  radioGroup: {
    width: '100%',
    marginBottom: '24px',
    '& .MuiFormGroup-root': {
      flexDirection: 'row',
    },
    '& .MuiTypography-body1': {
      fontFamily: 'Open Sans',
    },
  },
  inputRadio: {
    '& .MuiSvgIcon-root': {
      width: '18px',
      height: '18px',
    },
    '&.MuiRadio-colorSecondary': {
      '&:hover': {
        backgroundColor: 'unset',
      },
      '&.Mui-checked': {
        color: '#005cc8',
        '&:hover': {
          backgroundColor: 'unset',
        },
      },
    },
  },
  alertMessage: {
    fontFamily: 'Open Sans',
    width: '90%',
    marginBottom: '24px',
  },
  hiddenTextField: {
    width: '100%',
    marginBottom: '24px',
    '& .MuiFilledInput-root': {
      width: '100%',
    },
  },
}));
