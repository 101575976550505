const lookupBasicDb = [
  {
    value: 1,
    label: 'Name',
    field: 'full_name',
    tag: 'text_field'
  },
  {
    value: 2,
    label: 'Service Address',
    field: 'service_address',
    tag: 'text_field'
  },
  {
    value: 3,
    label: 'Account number',
    field: 'customer_id',
    tag: 'text_field'
  },
  {
    value: 4,
    label: 'status',
    field: 'status',
    tag: 'drop_down'
  },
  {
    value: 5,
    label: 'Mow week day',
    field: 'week_day',
    tag: 'drop_down'
  },
  {
    value: 6,
    label: 'Mow week number',
    field: 'week',
    tag: 'text_field'
  },
  {
    value: 7,
    label: 'Mow day',
    field: 'mow_day',
    tag: 'calendar_select'
  },
  {
    value: 8,
    label: 'Mapsco',
    field: 'mapsco',
    tag: 'text_field'
  },
  {
    value: 9,
    label: 'MapA',
    field: 'map_a',
    tag: 'text_field'
  },
  {
    value: 10,
    label: 'Mapsco Grid',
    field: 'mapsco_grid',
    tag: 'text_field'
  },
  {
    value: 11,
    label: 'Creation date',
    field: 'created_at',
    tag: 'calendar_select'
  },
  {
    value: 12,
    label: 'Phone number',
    field: 'contact_number',
    tag: 'text_field'
  },
  {
    value: 13,
    label: 'Market',
    field: 'market',
    tag: 'drop_down'
  },
  {
    value: 14,
    label: 'Future cancel week',
    field: 'future_cancel',
    tag: 'text_field'
  },
  {
    value: 15,
    label: 'Send schedule week',
    field: 'send_schedule',
    tag: 'text_field'
  },
  {
    value: 16,
    label: 'Frequency',
    field: 'srv_frecuency',
    tag: 'drop_down'
  },
  {
    value: 17,
    label: 'Signed by',
    field: 'csr',
    tag: 'drop_down'
  },
  {
    value: 18,
    label: 'Card type',
    field: 'card_type',
    tag: 'drop_down'
  },
  {
    value: 19,
    label: 'By source',
    field: 'created_at',
    tag: 'calendar_range'
  },
];

export default lookupBasicDb;
