import React from 'react'
import { WeekStyle } from '../calendar/styles/WeekStyle';


import BBWeek from './BBWeek';


const BBWeeksOfYear = (props) => {

  const classes = WeekStyle();

  return (
    <div className={classes.ctnWeekOfYear}>
      <BBWeek
        dataRender={props.dataRender} 
        setDataRender={props.setDataRender} 
        year={props.year}
      />
    </div>
  )
}

export default BBWeeksOfYear;
