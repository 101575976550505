import React from 'react';

import {
  Box, CheckIcon, DateRangeIcon, Typography,
} from '../../components/shared/MaterialUI';
import { SideChartStyles } from './charts-style';
import OverWeekChart from './components/OverWeekChart';
import TotalActiveCustomer from './components/TotalActiveCustomer';

export function SideCharts({ overWeekRenueve, totalActiveCustomer, totalScheduleNow }) {
  const classes = SideChartStyles();

  return (
    <Box className={classes.sideChart}>
      {overWeekRenueve && <OverWeekChart revenue={overWeekRenueve} />}
      <Box className='sideMetrics sideOne'>
        <Box component='span'>
          <CheckIcon style={{ fill: '#5887D6' }} />
        </Box>
        {totalActiveCustomer && <TotalActiveCustomer customers={totalActiveCustomer} />}
      </Box>
      <Box className='sideMetrics sideTwo'>
        <Box component='span'>
          <DateRangeIcon style={{ fill: '#10AA39' }} />
        </Box>
        <Box>
          {totalScheduleNow && (
            <>
              <Typography className={classes.sideTitles}>Total scheduled mows</Typography>
              <Typography className='numbers'>{`${totalScheduleNow.count.toLocaleString()}`}</Typography>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}
