import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';

import { useUI } from '../../../../app/context/ui';
import {
  Grid, Typography,
} from '../../../../components/shared/MaterialUI';
import { InputField, SelectField } from '../../../../forms';
import DatePickerField from '../../../../forms/DatePickerField';
import {FormatWeekYear, Utils} from '../../../../helpers';
import AppHelper from '../../../../helpers/AppHelper';
import { statusBbDb, statusDb } from '../../../../models/db/index';
import store from '../../../../redux/store';
import { BbCrewService, MarketService, UserService } from '../../../../services';
import Labels from '../../../web/signUpProcess/signUpCustomer/atoms/Labels';
import { useStyles } from '../../style/lookup-style';
import { isUndefined as _isUndefined } from 'lodash';
import weekDays from '../../../../models/db/week-days-bb';

const Lookup5 = (props) => {
  const state = store.getState();
  const accessToken = state.user.accessToken;
  const { snackbarUI } = useUI();
  const classes = useStyles();
  const [users, setUsers] = useState();
  const [crewDb, setCrewDb] = useState([]);
  const [markets, setMarkets] = useState();

  const userService = useMemo(() => new UserService(), []);
  const bbCrewService = useMemo(() => new BbCrewService(), []);
  const marketService = useMemo(() => new MarketService(), []);

  const { formikRef } = props;
  const values = formikRef.current?.values || '';

  const getListUser = useCallback(async () => {
    try {
      const r1 = await userService.list('?filter[role]=Admin,Manager,Representative');
      const arrCsr = Utils.updateArrayObjectItem(
        r1.data,
        [{ value: null, label: '-- Select CSR --' }],
        'id',
        'fullName'
      );
      setUsers(arrCsr);
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  }, [snackbarUI, userService]);

  const getPickItemGroup = useCallback(async (values) => {
    try {
      if (!_isUndefined(values)) {
        const r = await bbCrewService.list('?sort=-id');
        const arr = Utils.updateArrayObjectItem(
          r.data,
          [{ value: null, label: '-- Select --' }],
          'id',
          'fullName'
        );
        setCrewDb(arr);
      }
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  }, [bbCrewService, snackbarUI]);

  const getListMarket = useCallback(async () => {
    try {
      marketService.getAccessToken();
      const r1 = await marketService.list();
      const arr = Utils.updateArrayObjectItem(r1.data, []);
      setMarkets(arr);
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  }, [marketService, snackbarUI]);

  useEffect(() => {
    userService.init(accessToken);
    bbCrewService.init(accessToken);
    getListUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getListUser]);

  useEffect(() => {
    (async function init() {
      await getPickItemGroup(6);
    })();
  }, [getPickItemGroup]);

  useEffect(() => {
    getListMarket();
  }, [getListMarket]);

  useEffect(() => {
    if(values.market_bb){
      const formik = formikRef.current;
      formik.setFieldValue('market', '');
    }
  }, [values.market_bb]);

  return (
    <>
      <Grid
        item xs={12}
        className={`${classes.gridColumns} ${classes.noPaddingRight}`}
        style={{padding: '0px'}}
        >
          <Grid container>

            {/* activity */}

            <Grid item xs={12} className={classes.wrapperActivitySearch}>
              <Typography className='title'>Activity search</Typography>
            </Grid>

            <Grid item xs={4} style={{paddingRight: '23px'}}>
              <Labels text='Status' className='labels' />
              <SelectField name={'status'} data={statusDb} fullWidth placeholder={'-- Select --'} />
            </Grid>
            <Grid item xs={4}>
              <Labels text='Last name' className='labels' />
              <SelectField
                name={'note.last_name'}
                data={[
                  { value: 'a-f', label: 'A - F' },
                  { value: 'g-m', label: 'G - M' },
                  { value: 'n-z', label: 'N - Z' },
                ]}
                fullWidth
                placeholder={'-- Select --'}
              />
            </Grid>
            <Grid item xs={4} style={{paddingLeft: '23px'}}>
              <Labels text='Edited Date' className='labels' />
              <DatePickerField
                name='note.created_at'
                value={values.note?.created_at || ''}
                format='MM/dd/yyyy'
                maxDate={new Date('2050/12/31')}
                variant={'inline'}
                className='datePicker'
                autoOk
                inputProps={{ placeholder: 'Date' }}
              />
            </Grid>

            {/* log search */}

            <Grid item xs={12} className={classes.wrapperActivitySearch}>
              <Typography className='title'>Log search</Typography>
            </Grid>

            <Grid item xs={4} style={{paddingRight: '23px'}}>
              <Labels text='User' className='labels' />
              {users && (
                <SelectField
                  name={'log.user_id'}
                  data={users.filter((user) => user.value !== null)}
                  fullWidth
                  placeholder={'-- Select a user--'}
                />
              )}
            </Grid>
            <Grid item xs={2}>
              <Labels text='Edited Date' className='labels' style={{ paddingLeft:'10px'}}/>
              <DatePickerField
                name='log.updated_at'
                value={values.log?.updated_at || ''}
                format='MM/dd/yyyy'
                maxDate={new Date('2050/12/31')}
                variant={'inline'}
                className='datePicker'
                autoOk
                inputProps={{ placeholder: 'Start date' }}
              />
            </Grid>

            {/* b&b */}

            <Grid item xs={12} className={classes.wrapperActivitySearch}>
              <Typography className='title'>B&B</Typography>
            </Grid>

            <Grid item xs={4} style={{paddingRight: '23px'}}>
              <Labels text='Week Number' className='labels' />
              <InputField
                name={'bb.week_number'}
                placeholder='Type here'
                InputProps={{
                  inputComponent: FormatWeekYear,
                }}
                autoComplete='off'
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <Labels text='Week Day' className='labels' />
              <SelectField
                name={'bb.week_day'}
                data={weekDays.filter((weekD) => weekD.value !== null)}
                fullWidth
                placeholder={'-- Select --'}
              />
            </Grid>
            <Grid item xs={4} style={{ paddingLeft:'23px'}}>
              <Labels text='Crew' className='labels' />
              <SelectField
                name={'bb.crew'}
                data={crewDb.filter((crew) => crew.value !== null)}
                fullWidth
                placeholder={'-- Select --'}
              />
            </Grid>
            <Grid item xs={4} style={{paddingRight: '23px'}}>
              <Labels text='Status' className='labels' />
              <SelectField
                name={'bb.status'}
                data={statusBbDb.filter((status) => status.value !== null)}
                fullWidth
                placeholder={'-- Select --'}
              />
            </Grid>
            <Grid item xs={4}>
              <Labels text='B&B Market' className='labels' />
              <SelectField
                name={'market_bb'}
                data={markets}
                fullWidth
                placeholder={'-- Select a Market --'}
              />
            </Grid>

          </Grid>

      </Grid>
    </>
  );
};

export default Lookup5;
