import React from 'react';
import {
  Grid,
  IconButton,
  Tooltip
} from "@material-ui/core";
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { HeaderStyle } from './styles/HeaderStyle';

const Header = (props) => {
  const classes = HeaderStyle();
  return (
    <Grid container className={classes.ctnCalendar}>
      <Grid container>
        <Grid item xs={12} className={classes.title}>
          All weeks {props.year}
        </Grid>
      </Grid>
      <Grid item xs={4}>
      </Grid>
      <Grid item xs={4} className={classes.wrapperArrows}>
        <Tooltip title="Prev">
          <IconButton aria-label="delete" onClick={props.onPrevYear}>
            <NavigateBeforeIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Next">
          <IconButton aria-label="delete" onClick={props.onNextYear}>
            <NavigateNextIcon />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item xs={4}>
      </Grid>
    </Grid>
  )
}

export default Header;
