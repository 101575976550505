import { Box, Button, Container, Grid, Modal, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useUI } from '../../../../app/context/ui';
import { useStyles } from '../../style/lookup-style';
import * as Yup from 'yup';
import { Form, Formik, useFormikContext } from 'formik';
import AppHelper from '../../../../helpers/AppHelper';
import { CustomerService } from '../../../../services';
import { InputField } from '../../../../forms';

const customerService = new CustomerService();

let dlgSettings = {
  confirm: false,
  btn: {
    close: 'Close',
    confirm: 'Yes',
  },
  onConfirm: () => {},
};

const SaveSearch = ({findSearch, setTabValue, nameBtn = 'Save search'}) => {

  const classes = useStyles();
  const [openModalSave, setOpenModalSave] = useState(false);
  const { blockUI, snackbarUI, dialogUI } = useUI(); 
  const [initialValues,] = useState({
    name: '',
  }); 
  const { values: formValues } = useFormikContext();

  const getModalStyle = () => {
    return {
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%, -50%)`,
    };
  };

  const [modalStyle] = useState(getModalStyle);

  const validationSchema = Yup.object({
    name: Yup
      .string('Enter name')
      .required('Name is required'),
  });  

  const onSubmit = async (values) => {
    try {
      blockUI.current.open(true);  
      let arr = findSearch(formValues);
      customerService.getAccessToken();
      await customerService.list(`?${arr.join('&')}&render=paginate&sort=-id&name=${values.name}&isSave=1`);
      blockUI.current.open(false);
      setOpenModalSave(false);
      dialogUI.current.open('Great!', 'Your search has been saved successfully, you can find it in your search list', dlgSettings);
      setTabValue(1);
    } catch (e) {
      dialogUI.current.open('oops', 'Has had an error, try again later.');
      AppHelper.checkError(e, snackbarUI);
      blockUI.current.open(false);
    }
  }

  const verifySave = () => {
    let arr = findSearch(formValues);
    if(arr.length>0){
        setOpenModalSave(true);
    }else{
      dialogUI.current.open('oops', 'Please select at least one search parameter.');
      blockUI.current.open(false);
    }
  }

  const bodyModalSave = (
    <div style={modalStyle} className={classes.paperModal}>
      <br />
      <Grid container spacing={1}>
        <Container component="main" maxWidth="sm" className={classes.ctnInner}>
          <Typography variant="h4" className="title">Enter a name for this search</Typography>
          <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                  onSubmit(values).then(() => { });
              }}
              enableReinitialize={true}
          >
              {() => {
              return (
                  <Form autoComplete="off">
                  <Grid container spacing={3}>
                      <Grid item xs={12} className={classes.infoItem}>
                        <span>Enter the name you want to give this search.</span>
                      </Grid>
                      <Grid item xs={12} className={classes.grdItem}>
                        <label>Search name:</label>
                      </Grid>
                      <Grid item xs={12}>
                      <InputField name="name" autoFocus fullWidth/>
                      </Grid>
                  </Grid>
                  <Box pb={4}></Box>
                  <Grid container justify="center">
                      <Button
                        variant="contained"
                        size="large"
                        className={classes.buttonSaveSearch}
                        onClick={() => { setOpenModalSave(false); }}
                        >
                          Cancel
                      </Button>  
                      <Button
                        type="submit"
                        variant="contained"
                        size="large"
                        color="primary"
                        className={classes.buttonSaveSearch}
                        >
                          Save
                      </Button>
                  </Grid>
                  </Form>
              );
              }}
          </Formik>
        </Container>
      </Grid>
    </div>
  );

  return (
      <>
        <Button 
          className={`${classes.button} ${classes.saveBtn}`} 
          onClick={()=>{verifySave()}}>
            { nameBtn }
        </Button>
        <Modal
          open={openModalSave}
          onClose={() => {
            setOpenModalSave(false);
          }}
        >
          {bodyModalSave}
        </Modal>
      </>
  )

}

export default SaveSearch;
