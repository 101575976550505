import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import AppHelper from '../../../helpers/AppHelper';
import AttachmentService from '../../../services/AttachmentService';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { useUI } from '../../../app/context/ui';
import { useFormikContext } from 'formik';

const columnsDG = [
  {
    field: 'id',
    headerName: 'Id',
    width: 90,
    sortable: false,
  },
  {
    field: 'filename',
    headerName: 'File',
    flex: 1,
    minWidth: 180,
    sortable: false,
    renderCell: (params) => (
      <a href={params.row.filenameUrl} target='_blank' rel='noreferrer'>
        {params.row.filename}
      </a>
    ),
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 140,
    sortable: false,
    disableClickEventBubbling: true,
    renderCell: () => {},
  },
];

const AttachmentList = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const { values } = useFormikContext();
  const { dialogUI, snackbarUI } = useUI();
  const queryForListAttachmentsRef = useRef('filter[note_id]=NOTE_ID&render=paginate&sort=-id');
  const [columns, setColumns] = useState(columnsDG);

  const attachmentService = useMemo(() => new AttachmentService(), []);
  attachmentService.getAccessToken();

  const getAttachments = useCallback(
    async (search) => {
      try {
        setLoading(true);
        const r = await attachmentService.list(search);
        setRows(r.data.data);
        setLoading(false);
      } catch (e) {
        AppHelper.checkError(e, snackbarUI);
      }
    },
    [attachmentService, snackbarUI]
  );

  const onRemove = useCallback(async (attachmentId) => {
    try {
      dialogUI.current.close();
      await attachmentService.delete(attachmentId);
      await getAttachments(queryForListAttachmentsRef.current);
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  }, [attachmentService, dialogUI, getAttachments, snackbarUI]);

  const initColumns = useCallback(
    () => {
      let newColumns = [...columns];
      newColumns[2].renderCell = (params) => {
        const settings = {
          confirm: true,
          btn: {
            close: 'Cancel',
            confirm: 'Yes',
          },
          onConfirm: () => onRemove(params.row.id),
        };

        return (
          <IconButton
            color='primary'
            disabled={!disabled}
            onClick={() => dialogUI.current.open('Attachment', 'Are you sure to delete this file?', settings)}
          >
            <DeleteOutlineIcon />
          </IconButton>
        );
      };
      setColumns(newColumns);
    },
    [columns, dialogUI, disabled, onRemove]
  );

  useEffect(() => {
    queryForListAttachmentsRef.current = queryForListAttachmentsRef.current.replace('NOTE_ID', values.id);
    if (values.disabled !== disabled) {
      setDisabled(values.disabled);
      initColumns();
    }

  }, [getAttachments, values, disabled, initColumns]);

  useEffect(() => {
    (async function init() {
      await getAttachments(queryForListAttachmentsRef.current);
    })();
  }, [getAttachments]);

  return (
    <div style={{ width: 545, height: 250 }}>
      <DataGrid
        autoHeight={false}
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        disableColumnFilter={true}
        disableColumnMenu={true}
        disableSelectionOnClick={true}
        loading={loading}
      />
    </div>
  );
};

export default AttachmentList;
