import React, { useCallback, useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import EditIcon from '@material-ui/icons/Edit';

import { useUI } from '../../app/context/ui';
import AppHelper from '../../helpers/AppHelper';
import { MarketService } from '../../services';
import { connect } from 'react-redux';
import {AdminPanelStyle} from "./styles/AdminPanelStyle";
import SlotsDialog from "./modals/SlotsDialog";
import moment from 'moment';
import dayjs from 'dayjs';

const marketService = new MarketService();

const AdminPanel = () => {
  const classes = AdminPanelStyle();
  const {blockUI, snackbarUI} = useUI();
  const [markets, setMarkets] = useState([]);
  const [weeksFilters, setWeeksFilters] = useState([]);
  const [openDlgSlots, setOpenDlgSlots] = useState(false);
  const [optionSelected, setOptionSelected] = useState('');
  const [dataSlotsWeek, setDataSlotsWeek] = useState({});
  const handleChangeSelect = (e) => {
    setOptionSelected(e.target.value);
  };

  const getListMarket = useCallback(async () => {
    try {
      blockUI.current.open(true);
      marketService.getAccessToken();
      const r1 = await marketService.list();
      setMarkets(r1.data);
      setOptionSelected(r1.data[0].id);
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  }, [blockUI, snackbarUI]);

  const getSlots = useCallback(
    async (optionSelected) => {
      try {
        if (typeof optionSelected === 'number') {
          blockUI.current.open(true);
          marketService.getAccessToken();
          const r2 = await marketService.slots(optionSelected);
          setWeeksFilters(r2.data?.weeksFilter);
          blockUI.current.open(false);
        }
      } catch (e) {
        blockUI.current.open(false);
        AppHelper.checkError(e, snackbarUI);
      }
    },
    [blockUI, snackbarUI]
  );

  const getListSlotsWeek = useCallback(async (values) => {
    try {
      blockUI.current.open(true);
      const params = `?start=${values.start}&end=${values.end}&week=${values.week}`;
      marketService.getAccessToken();
      const rs = await marketService.slotsWeek(optionSelected, params);
      let weekdays = rs.data.weekdays;
      weekdays = weekdays.map((week)=>({
        ...week,
        lock: 0,
        lock_old: week.lock
      }));
      setDataSlotsWeek({...rs.data, weekdays});
      blockUI.current.open(false);
      setOpenDlgSlots(true);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  }, [blockUI, snackbarUI, optionSelected]);

  const format24Hours = (time) => {
    if (time instanceof dayjs || time instanceof moment) {
        return time.format('HH:mm');
    } else {
        return moment(time).format('HH:mm');
    }
  };
  
  const handleSubmit = useCallback ( async (values) => {
    try {
      blockUI.current.open(true);
      values.isLock = values.isLock ? 1 :0;
      marketService.getAccessToken();
      let start = values.lockHoursRange?.start;
      let end = values.lockHoursRange?.end;

      if (start) {
        start = format24Hours(start);
      }

      if (end) {
        end = format24Hours(end);
      }

      await marketService.updateWeek(
        {
          ...values, 
          lockHoursRange: { 
            start, 
            end
          }
        }, optionSelected);
        
      blockUI.current.open(false);
      setOpenDlgSlots(false);
      await getSlots(optionSelected);
    } catch (e) {
      setOpenDlgSlots(false);
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  },[blockUI, snackbarUI, getSlots, optionSelected]);

  useEffect(() => {
    (async function init() {
      await getListMarket();
    })();
  }, [getListMarket]);

  useEffect(() => {
    (async function init() {
      await getSlots(optionSelected);
    })();
  }, [optionSelected, getSlots]);

  return (
    <Container maxWidth='lg' disableGutters>
      <Grid container>
        <Grid item xs={12} sm={11}>
          <h2 className={classes.h2}>Mow Availabilities</h2>
        </Grid>
      </Grid>
      <Container className={classes.container}>
        <p>Information of mow availabilities</p>
        <Divider/>
        <div className='market'>Market:</div>
        <div>
          <FormControl variant='outlined' className={classes.formControl}>
            <InputLabel id='marketSelect' className={classes.inputLabel}>
              Select an option
            </InputLabel>
            <Select
              labelId='marketSelect'
              id='demo-simple-select-outlined'
              value={optionSelected}
              onChange={handleChangeSelect}
              label='Select an option'
            >
              {markets.map((e) => (
                <MenuItem key={`market${e.id}`} value={e.id}>
                  {e.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {weeksFilters.map((e, i) => {
          let dayNumber = 1;
          let slotsTotalNumber = 9;
          let occupiedNumber = 17;

          return (
            <div key={`weekFilter${i}`}>
              <div className='infoWeek'>
                <Grid container spacing={1}>
                  <Grid container item xs={11}>
                    This week: {e.start} To: {e.end}
                  </Grid>
                  <Grid container item xs={1}>
                    <Button
                      variant='contained'
                      color='secondary'
                      className={classes.buttonEditEnabled}
                      onClick={() => getListSlotsWeek(e)}
                      startIcon={<EditIcon/>}
                    >
                      Edit
                    </Button>
                  </Grid>
                </Grid>
              </div>
              <div className={classes.wrapperThisWeek}>
                <div className='div1'>
                  <div className={classes.infoTitleCol}>Date</div>
                </div>
                {e.weekdays.map((weekDay, index) => {
                  dayNumber++;
                  return (
                    <div key={`weekDay${index}`} className={`div${dayNumber}`}>
                      <div className={classes.infoTitle}>{weekDay.name}</div>
                      <div>{weekDay.workday}</div>
                    </div>
                  );
                })}
                <div className='div7'>
                  <div className={classes.infoTitle}>Saturday</div>
                  <div>_</div>
                </div>
                <div className='div8'>
                  <div className={classes.infoTitle}>Sunday</div>
                  <div>_</div>
                </div>
                <div className='div9'>
                  <div className={classes.infoTitleCol}>Max mows</div>
                </div>
                {e.weekdays.map((weekDay, index) => {
                  slotsTotalNumber++;
                  return (
                    <div key={`slotsTotal${index}`} className={`div${slotsTotalNumber}`}>
                      <div className={classes.dataWrap}>
                        <span
                          style={{marginRight: '10px', color: weekDay.lock === 1 ? 'red' : ''}}>
                          {weekDay.slots}
                        </span>
                      </div>
                    </div>
                  );
                })}
                <div className='div15'>
                  <div className={classes.dataWrap}>0</div>
                </div>
                <div className='div16'>
                  <div className={classes.dataWrap}>0</div>
                </div>
                <div className='div17'>
                  <div className={classes.infoTitleCol}>Scheduled</div>
                </div>
                {e.weekdays.map((weekDay, index) => {
                  occupiedNumber++;
                  return (
                    <div key={`slotsUsed${index}`} className={`div${occupiedNumber}`}>
                      <div className={classes.dataWrap}>{weekDay.used}</div>
                    </div>
                  );
                })}
                <div className='div23'>
                  <div className={classes.dataWrap}>0</div>
                </div>
                <div className='div24'>
                  <div className={classes.dataWrap}>0</div>
                </div>
              </div>
            </div>
          );
        })}
        {openDlgSlots &&
          <SlotsDialog
            openDialog={openDlgSlots}
            setOpenDialog={setOpenDlgSlots}
            data={dataSlotsWeek}
            setData={setDataSlotsWeek}
            handleSubmit={handleSubmit}
          />
        }
      </Container>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(AdminPanel);
