const columnsExportDb = [
  {
    name: 'Crew ..',
    desc: 'Crew number',
    hidden: 'crew',
    field: 'crew',
    value: false,
  },
  {
    name: 'City',
    desc: 'City',
    hidden: 'city',
    field: 'cityName',
    value: false,
  },
  {
    name: 'State',
    desc: 'State',
    hidden: 'state',
    field: 'state',
    value: false,
  },
  {
    name: 'Zip',
    desc: 'Zip code',
    hidden: 'zipcode',
    field: 'zipcode',
    value: false,
  },
  {
    name: 'Mapsco',
    desc: 'Mapsco',
    hidden: 'mapsco',
    field: 'mapsco',
    value: false,
  },
  {
    name: 'M.G..',
    desc: 'Mapsco grid',
    hidden: 'mapsco_grid',
    field: 'mapscoGrid',
    value: false,
  },
  {
    name: 'M. Price',
    desc: 'Mowing Price',
    hidden: 'subtotal_mowing',
    field: 'subtotalMowing',
    value: false,
  },
  {
    name: 'T. tax',
    desc: 'Tx totals',
    hidden: 'total_mowing',
    field: 'totalMowing',
    value: false,
  },
  {
    name: 'Last Edited',
    desc: 'Last Edited',
    hidden: 'last_edited_user',
    field: 'lastEditedUser',
    value: false,
  },
  {
    name: 'Call notes',
    desc: 'Call notes',
    hidden: 'user_note_full_name',
    field: 'userNote',
    value: false,
  },
  {
    name: 'Card Type',
    desc: 'Card type',
    hidden: 'card_type',
    field: 'cardType',
    value: false,
  },
  {
    name: 'Phone',
    desc: 'Phone number',
    hidden: 'contact_number',
    field: 'contactNumber',
    value: false,
  },
  {
    name: 'Email',
    desc: 'Email',
    hidden: 'email',
    field: 'email',
    value: false,
  },  
];

export default columnsExportDb;
