import { createSlice } from '@reduxjs/toolkit';
import { size as _size } from 'lodash';

const initialState = {
  state: null,
  date: null,
  count: 0,
  options: []
};

const alarmSlice = createSlice({
  name: 'alarm',
  initialState,
  reducers: {
    addAlarm: (state, action) => {
      state.state = true;
      state.date = new Date().toLocaleDateString();
      state.count = _size([...action.payload]);
      state.options = [
        ...initialState.options,
        ...action.payload
      ];
    },
  }
});

export const {addAlarm} = alarmSlice.actions;

export default alarmSlice.reducer;
