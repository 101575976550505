import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import BillingNoteForm from './BillingNoteForm';
import { useFormikContext } from 'formik';
import AttachmentForm from './AttachmentForm';
import AttachmentTab from './AttachmentTab';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={3}>
          {children}
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    padding: '20px 0 20px 0',
  },
}));

const BillingNoteTab = ({isEdit}) => {
  const { values } = useFormikContext();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Form" {...a11yProps(0)} />
          <Tab label="Attachments" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <BillingNoteForm isEdit={isEdit} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        {values.id !== 0
          ? <AttachmentTab />
          : <AttachmentForm />
        }
      </TabPanel>
    </div>
  )
}

export default BillingNoteTab;
