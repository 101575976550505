import AppService from './AppService';

class AuthService extends AppService {
  login(formData) {
    return this.http.post('/auth/login', formData)
  }

  passwordReset(formData) {
    return this.http.post('/auth/recover-password', formData);
  }

  passwordChange(formData) {
    return this.http.post('/auth/reset-password', formData);
  }
}

export default AuthService;
