import React, { useCallback, useEffect, useState } from 'react';

import { useFormikContext } from 'formik';
import {
  find as _find, first as _first, isUndefined as _isUndefined,
  toNumber as _toNumber,
} from 'lodash';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';

import { Box, Checkbox, FormControlLabel, InputLabel } from '@material-ui/core';

import { useUI } from '../../../app/context/ui';
import { WizardStyles } from '../../../assets/css';
import GoogleAutocomplete from '../../../components/shared/GoogleAutocomplete';
import GoogleMap from '../../../components/shared/GoogleMap';
import {
  Container, Grid, Typography,
} from '../../../components/shared/MaterialUI';
import {
  CheckboxField, InputField, NumberFormatCustom, SelectField,
} from '../../../forms';
import { Utils } from '../../../helpers';
import AppHelper from '../../../helpers/AppHelper';
import store from '../../../redux/store';
import { 
  MelissaService, 
  MarketService, 
  StateService, 
  UserService 
} from '../../../services';

const marketService = new MarketService();
const stateService = new StateService();
const userService = new UserService();
const melissaService = new MelissaService();

const Step2 = (props) => {
  const [markets, setMarkets] = useState([]);
  const [citys, setCitys] = useState([]);
  const [states, setStates] = useState([]);
  const [csrs, setCsrs] = useState([]);

  const [location, setLocation] = useState({});

  const { blockUI, snackbarUI } = useUI();

  // Google Maps
  const [mapLocation, setMapLocation] = useState({
    lat: 37.09024,
    lng: -95.712891,
  });

  const [mapZoom, setMapZoom] = useState(2);
  const [mapText, setMapText] = useState('');

  const wizardStyle = WizardStyles();
  const {
    formField: {
      city,
      property,
      state,
      serviceAddress,
      owner,
      csr,
      market,
      lotSize,
      taxRate,
      useCornerLot,
    },
  } = props;
  const { formikRef } = props;
  const { values: formValues } = useFormikContext();
  const { 
    zipcode, 
    marketId, 
    costService, 
    isServiceAddressManual,
  } = formValues;
  const stat = store.getState();
  const accessToken = stat.user.accessToken;

  const getListMarket = useCallback(async () => {
    try {
      blockUI.current.open(true);
      const r1 = await marketService.read(marketId);
      let arr = [];
      const obj = Utils.updateObjectKey(r1.data);

      if (formikRef.current) {
        formikRef.current.setFieldValue('taxRate', obj.taxRate);
        formikRef.current.setFieldValue('useCornerLot', "");
        formikRef.current.setFieldValue('market', obj.value);
        formikRef.current.setFieldValue('marketName', obj.label);
      }
      arr.push(obj);
      setMarkets(arr);
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  }, [blockUI, marketId, formikRef, snackbarUI]);

  const getZipCodeState = async () => {
    try {
      const r1 = await stateService.zip(zipcode);
      const arrCity = Utils.updateArrayObjectItem(r1.data, [], 'id', 'city');
      const arrState = Utils.updateArrayObjectItem(r1.data, [], 'stateId', 'stateName');

      const objCity = _first(arrCity);
      const objState = _first(arrState);

      if (formikRef.current) {
        formikRef.current.setFieldValue('city', objCity.value);
        formikRef.current.setFieldValue('cityName', objCity.label);
        formikRef.current.setFieldValue('state', objState.value);
      }

      setLocation({
        lat: _toNumber(objCity.lat),
        lng: _toNumber(objCity.lng),
      });

      setCitys(arrCity);
      setStates(arrState);
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const getListUser = async () => {
    try {
      const r1 = await userService.list('?filter[role]=customer-service&filter[status]=1');
      const arrCsr = Utils.updateArrayObjectItem(
        r1.data,
        [{ value: null, label: '-- Select CSR --' }],
        'id',
        'fullName'
      );
      setCsrs(arrCsr);
      if (formikRef.current) {
        if (_toNumber(costService) === 0) {
          formikRef.current.setFieldValue('csr', props.user.id);
        }
      }
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const onKeyPress = () => {
    if (formikRef.current) {
      formikRef.current.setFieldValue('reasonEdit', false);
    }
  };

  useEffect(() => {
    marketService.init(accessToken);
    getListMarket();
  }, [accessToken]);

  useEffect(() => {
    stateService.init(accessToken);
    getZipCodeState();
  }, [accessToken]);

  useEffect(() => {
    userService.init(accessToken);
    getListUser();
  }, [accessToken]);

  useEffect(() => {
    if (formikRef.current) {
      let cityOld = _find(citys, { value: formValues.city });
      cityOld = !_isUndefined(cityOld) ? cityOld : [];
      let address = '';
      if (!_isUndefined(formValues.serviceAddress)) {
        if (formValues.serviceAddress.length > 0) {
          address =
            formValues.serviceAddress +
            ', ' +
            cityOld.label +
            ', ' +
            formValues.state +
            ' ' +
            zipcode;
        }
      }
      setLocation({
        lat: location.lat,
        lng: location.lng,
        address: address,
      });
    }
  }, [formValues.serviceAddress, formValues.state, formValues.city]);

  const getDataByZipAndAddress = async () => {
    try {
      blockUI.current.open(true);
      let search = {
        address: formValues.serviceAddress,
        zip: formValues.zipcode
      };
      melissaService.init(accessToken);
      const response = await melissaService.getDataMowMellisa(search);
      const { owner: dataOwner, size, pvalue } = response.data;
      formikRef.current.setFieldValue(owner.name, dataOwner);
      formikRef.current.setFieldValue(lotSize.name, size);
      formikRef.current.setFieldValue(property.name, pvalue);
      blockUI.current.open(false);
    } catch (error) {
      blockUI.current.open(false);
    } 
  }

  const handleChangeServiceAddressManualEnabled = (e) => {
    if (formikRef.current) {
      formikRef.current.setFieldValue(
        "isServiceAddressManual",
        e.target.checked
      );
    }
  };

  useEffect(async() => {
    if(formValues.serviceAddress && !formValues.isServiceAddressManual){
      getDataByZipAndAddress();
    }
  }, [formValues.serviceAddress]);

  return (
    <Container component='main' maxWidth='lg' className={wizardStyle.ctnInner}>
      <Typography className={wizardStyle.title}>Street address</Typography>
      <hr />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <InputLabel className={wizardStyle.label}>City *</InputLabel>
          <SelectField name={city.name} data={citys} autoFocus fullWidth />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputLabel className={wizardStyle.label}>Property value</InputLabel>
          <InputField
            name={property.name}
            inputProps={{ minlength: 1, maxLength: 15 }}
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputLabel className={wizardStyle.label}>State *</InputLabel>
          <SelectField name={state.name} data={states} autoFocus fullWidth />
        </Grid>
        <Grid item xs={12} sm={4}>

          <div className={wizardStyle.labelIcon}>
            <InputLabel className={wizardStyle.label}>Service address (US only) *</InputLabel>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isServiceAddressManual} 
                  onChange={handleChangeServiceAddressManualEnabled} 
                  name="isServiceAddressManual"
                />
              }
              label="Manual"
              className={wizardStyle.controlLabel}
            />
          </div>

          {
            (isServiceAddressManual)
              ?
                <InputField name={serviceAddress.name} fullWidth />
              :
                <GoogleAutocomplete
                  serviceAddress={serviceAddress}
                  name={serviceAddress.name}
                  formikRef={formikRef}
                  setMapText={setMapText}
                  setMapLocation={setMapLocation}
                  setMapZoom={setMapZoom}
                />
          }
          
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputLabel className={wizardStyle.label}>Owner</InputLabel>
          <InputField name={owner.name} fullWidth />
        </Grid>
        <Grid item xs sm={4}>
          <InputLabel className={wizardStyle.label}>CSR *</InputLabel>
          <SelectField name={csr.name} data={csrs} autoFocus fullWidth />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputLabel className={wizardStyle.label}>Market *</InputLabel>
          <SelectField name={market.name} data={markets} autoFocus fullWidth />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputLabel className={wizardStyle.label}>Lot size</InputLabel>
          <InputField
            name={lotSize.name}
            onKeyUp={onKeyPress}
            InputProps={{ inputComponent: NumberFormat }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <InputLabel className={wizardStyle.label}>Tax rate *</InputLabel>
          <InputField
            name={taxRate.name}
            InputProps={{ inputComponent: NumberFormat }}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <CheckboxField name={useCornerLot.name} label={useCornerLot.label} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box my={2} />
      </Grid>
      <Grid item xs={12}>
        <div style={{ height: '50vh', width: '100%' }}>
          <GoogleMap center={mapLocation} zoom={mapZoom} text={mapText} />
        </div>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
export default connect(mapStateToProps)(Step2);
