import React, { useContext } from 'react';

import { includes as _includes, without as _without } from 'lodash';

import {
  Box, Container, Typography,
} from '../../../../components/shared/MaterialUI';
import Divider from '../signUpCustomer/atoms/Divider';
import { SignUpCustomerContext } from '../SignUpProcess';

const Success = () => {
  const { state } = useContext(SignUpCustomerContext);
  const {
    firstName,
    lastName,
    serviceAddress,
    cityName,
    zipcode,
    contactNumber,
    email,
    serviceDate,
    zipCodeValid,
    taxRate,
    lotSize,
    srvFrecuency,
    mowCut,
    totalMowing,
    billingAddress,
    billingState,
    billingZipCode,
    mailingAddress,
    mailingZipCode,
    mailingState,
    billingCityName,
    mailingCityName,
  } = state;

  const serviceNames = ['Name', 'Address', 'City', 'Zip', 'State', 'Phone', 'Email'];
  const billingNames = ['Address', 'City', 'Zip', 'State'];
  const mailingNames = ['Name', 'Address', 'City', 'Zip', 'State'];
  const mowingNames = [
    'Lot Size',
    'Frequency',
    'What to Mow',
    'Bag Grass',
    'Cost',
    'Tax',
    'Service Start Date',
    'Exceptions',
  ];

  const serviceDetails = [
    `${firstName} ${lastName}`,
    serviceAddress,
    cityName,
    zipcode,
    state.state,
    contactNumber,
    email,
  ];
  const billingAddressDetails = [billingAddress, billingCityName, billingZipCode, billingState];
  const mailingAddressDetails = [
    `${state.firstName} ${state.lastName}`,
    mailingAddress,
    mailingCityName,
    mailingZipCode,
    mailingState,
  ];
  const mowingsDetails = [
    lotSize,
    srvFrecuency,
    `${_includes(mowCut, 'BagGrass') ? _without(mowCut, 'BagGrass').join(', ') : mowCut.join(', ')}`,
    `${_includes(mowCut, 'BagGrass') ? 'Yes' : 'No'}`,
    `$${totalMowing}`,
    `$${taxRate}`,
    serviceDate,
    zipCodeValid,
  ];

  return (
    <Container maxWidth='lg' style={{ padding: '4rem 6rem' }}>
      <Typography
        variant='h1'
        style={{ fontSize: '75px', fontFamily: 'Roboto Condensed', fontWeight: 700, color: '#444444' }}
      >
        Success!
      </Typography>
      <Typography variant='body1' style={{ fontFamily: 'Open Sans', color: '#676767', marginBottom: '32px' }}>
        Thanks for your Lawn Mowing order. We Will Call you to Confirm your order.
      </Typography>
      <Divider text='Service Address' />
      {serviceNames &&
        serviceNames.map((service, index) => {
          return (
            <Box style={{ display: 'flex', marginBottom: '24px', gap: '24px' }} key={index}>
              <Typography variant='body1' style={{ fontFamily: 'Open Sans', color: '#676767', fontWeight: 700 }}>
                {service}
              </Typography>
              <Typography variant='body1' style={{ fontFamily: 'Open Sans', color: '#676767' }}>
                {serviceDetails[index]}
              </Typography>
            </Box>
          );
        })}
      <Divider text='Billing Address' />
      {billingNames &&
        billingNames.map((service, index) => {
          return (
            <Box style={{ display: 'flex', marginBottom: '24px', gap: '24px' }} key={index}>
              <Typography variant='body1' style={{ fontFamily: 'Open Sans', color: '#676767', fontWeight: 700 }}>
                {service}
              </Typography>
              <Typography variant='body1' style={{ fontFamily: 'Open Sans', color: '#676767' }}>
                {billingAddressDetails[index]}
              </Typography>
            </Box>
          );
        })}
      <Divider text='Mailing Address' />
      {mailingNames &&
        mailingNames.map((service, index) => {
          return (
            <Box style={{ display: 'flex', marginBottom: '24px', gap: '24px' }} key={index}>
              <Typography variant='body1' style={{ fontFamily: 'Open Sans', color: '#676767', fontWeight: 700 }}>
                {service}
              </Typography>
              <Typography variant='body1' style={{ fontFamily: 'Open Sans', color: '#676767' }}>
                {mailingAddressDetails[index]}
              </Typography>
            </Box>
          );
        })}
      <Divider text='Mowing Details' />
      {mowingNames &&
        mowingNames.map((service, index) => {
          return (
            <Box style={{ display: 'flex', marginBottom: '24px', gap: '24px' }} key={index}>
              <Typography variant='body1' style={{ fontFamily: 'Open Sans', color: '#676767', fontWeight: 700 }}>
                {service}
              </Typography>
              <Typography variant='body1' style={{ fontFamily: 'Open Sans', color: '#676767' }}>
                {mowingsDetails[index]}
              </Typography>
            </Box>
          );
        })}
      <Typography variant='body1' style={{ fontFamily: 'Open Sans', color: '#676767', marginTop: '32px' }}>
        Disclaimer: Our Instant quote is taken from the county records and, until confirmation by our research
        department, is only meant as an estimate &amp; is subject to final review. County records can occasionally give
        incorrect or incomplete information. These records do not always include road easements, and may not accurately
        reflect other factors which may affect an increase or decrease in your quoted price.
      </Typography>
    </Container>
  );
};

export default Success;
