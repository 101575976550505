import {makeStyles} from '@material-ui/core/styles';
import backgroundImage from "../images/background.jpg";

export const LoginStyles = makeStyles(() => ({
  bgMain: {
    backgroundImage: "url(" + backgroundImage + ")",
    height: "100vh",
    backgroundSize: "cover",
    maxWidth: "100% !important"
  },
  btnSubmit: {
    margin: "3px 0 2px 0",
    borderRadius: "4px",
    height: "56px",
    "&:hover": {
      backgroundColor: "#242323 !important",
    }
  },
  panPaper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "fixed"
  },
  cardLogo: {
    width: "220px !important",
    height: "134px",
  },
  panForm: {
    backgroundColor: "#C8DBCA",
    padding: "50px",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    width: "500px"
  },
  formMain: {
    width: "80%",
    marginTop: "1px",
    transform: "translate(-50%, -50%)",
    top: "120px",
    left: "50%",
    position: "relative",
  },
  formError: {
    color: "#f44336",
    fontSize: "0.96rem",
    margin: "0",
    marginTop: "3px",
    lineHeight: "1.66",
    letterSpacing: "0.03333em",
    textAlign: "center"
  },
  gridForgotPassword: {
    marginTop: "15px",
    marginBottom: "30px",
    fontSize: "14px"
  },
  inputEmail: {
    "& .MuiInput-input": {
      color: "#484646 !important",
      WebkitBoxShadow: "0 0 0 1000px #C8DBCA inset !important",
    }
  },
  inputPassword: {
    marginTop: "10px",
  },
  input: {
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px #C8DBCA inset",
    }
  },
  inputRecover: {
    marginTop: "20px",
  },
  btnSendReset: {
    marginTop: "20px"
  },
  alignItemsAndJustifyContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icoShowPassword: {
    '& .MuiSvgIcon-root': {
      fontSize: 22
    }
  },
  link: {
    color: "#484646",
    textDecoration: 'none'
  },
}));
