import React from 'react';

import { Box, makeStyles, Typography } from '@material-ui/core';

const TotalActiveCustomer = ({ customers }) => {
  const classes = useStyles();

  return (
    <Box>
      <Typography className={classes.sideTitles}>{customers.label}</Typography>
      <Typography className='numbers'>{`${customers.count.toLocaleString()}`}</Typography>
    </Box>
  );
};

export default TotalActiveCustomer;

const useStyles = makeStyles(() => ({
  sideTitles: {
    color: '#0B0C0E',
    fontWeight: 700,
    fontSize: '18px',
    textAlign: 'center',
  },
}));
