import {makeStyles} from '@material-ui/core/styles';

export const ListStyles = makeStyles(() => ({
  dataGrid: {
    "& .MuiCheckbox-root": {
      color: "#10AA39",
    },
    "& .MuiDataGrid-columnSeparator": {
      color: "transparent",
    },
    "& .MuiDataGrid-colCellTitle": {
      color: "#2F3A4A",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '25px',
    },
    "& .MuiDataGrid-cell": {
      color: "#2F3A4A",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: '14px',
      lineHeight: '16px',
    },
  },
}));
