import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';

import getMinutes from 'date-fns/getMinutes';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Grid } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';

import { useUI } from '../app/context/ui';
import CustomerLookup from '../components/customer/lookup/CustomerLookup';
import { Button, Typography } from '../components/shared/MaterialUI';
import { StatsService } from '../services';
import Charts from './dashboard/Charts';
import { SideCharts } from './dashboard/SideCharts';

const Dashboard = () => {
  const [openLookup, setOpenLookup] = useState(false);
  const history = useHistory();
  const { blockUI } = useUI();
  const classes = useStyles();
  const statsService = useMemo(() => new StatsService(), []);
  const [stats, setStats] = useState({});

  const { market, overWeekRenueve, totalActiveCustomer, totalScheduleNow } = isEmpty(stats) ? '' : stats;

  const getStats = useCallback(async () => {
    try {
      blockUI.current.open(true);
      statsService.getAccessToken();
      const stats = await statsService.getStats();
      setStats(stats.data);
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
    }
  }, [blockUI, statsService]);

  useEffect(() => {
    getStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sideChartProps = {
    overWeekRenueve,
    totalActiveCustomer,
    totalScheduleNow,
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (getMinutes(new Date()) === 0) {
        getStats();
      }
    }, 60 * 1000);
    return () => clearInterval(interval);
  }, [getStats]);

  return (
    <>
      <Container component='main' maxWidth='lg'>
        <Grid container direction='row' justify='space-between' alignItems='center'>
          <Grid item xs={12} sm={8}>
            <Typography variant='h4'>Analytics</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div>
              <Button
                variant='contained'
                color='primary'
                size='small'
                className={classes.button}
                startIcon={<SearchIcon />}
                onClick={() => {
                  setOpenLookup(true);
                }}
              >
                Lookup
              </Button>
              <Button
                variant='contained'
                color='primary'
                size='small'
                className={classes.button}
                startIcon={<AddIcon />}
                onClick={() => {
                  history.push('create-customer');
                }}
              >
                Create customer
              </Button>
            </div>
          </Grid>
        </Grid>
        <CustomerLookup open={openLookup} setOpen={setOpenLookup} />
      </Container>
      <Grid container style={{ marginTop: '16px' }}>
        <Grid item xs={9} className={classes.chartsContainer}>
          <Charts market={market} />
        </Grid>
        <Grid item xs={3} style={{ padding: '0 0 0 16px' }}>
          <SideCharts {...sideChartProps} />
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(Dashboard);

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    textTransform: 'none',
  },
  chartsContainer: {
    padding: '0 16px',
    overflowY: 'scroll',
    height: '90vh',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(11, 12, 14, 1)',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: 'rgba(11, 12, 14, 0.5)',
    },
  },
}));
