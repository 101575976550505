import React, {forwardRef, useImperativeHandle, useState} from 'react';
import { cloneDeep as _cloneDeep } from 'lodash';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

import {
  Dialog, DialogContent, DialogContentText,
  DialogTitle,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: '#fff',
    margin: 0,
  },
}));

const useStylesDialog = makeStyles(() => ({
  importantMessage: {
    fontWeight: 'bold',
  },
  title:{
    textAlign: 'center',
    fontWeight: 'bold',
  },
  wrapperLink: {
    textAlign: 'center',
    paddingBottom: '27px !important',
    '& .link': {
      color: '#065f1c',
      textDecoration: 'none'
    }
  }
}));

const template = {
  main: {
    visible: '',
    message: '',
  }
};

const BlockUI = forwardRef((props, ref) => {
  const classes = useStyles();
  const classesDialog = useStylesDialog();
  const [open, setOpen] = useState(false);
  const [settings, setSettings] = useState(template);

  useImperativeHandle(ref, () => ({
    open: (visible, message = '') => {
      let defaultSettings = _cloneDeep(template);
      defaultSettings.main.visible = visible;
      defaultSettings.main.message = message;
      setSettings(defaultSettings);
      setOpen(true);
    },
    close: () => setOpen(false),
  }));

  return (
    <Backdrop className={classes.backdrop} open={settings.main.visible}>
      {
        (settings.main.message)
          ?
            <Dialog open={open}>
              <DialogTitle className={classesDialog.title}>Great!</DialogTitle>
              <DialogContent>
                <DialogContentText>{settings.main.message}</DialogContentText>
              </DialogContent>
            </Dialog>
          :
            <CircularProgress color="inherit" />
      }
    </Backdrop>
  );
});

export default BlockUI;
