import {isString as _isString, startsWith as _startsWith, has as _has} from 'lodash';

class AppHelper {

  static getStatusCode(e) {
    let statusCode = 500;
    if (_has(e, 'response') && _has(e.response, 'status')) {
      const {status} = e.response;
      statusCode = status;
    }

    return statusCode;
  }

  static getError(error) {
    const defaultMessage = 'Unknown error';

    let message = _has(error, 'response.data') && _isString(error.response.data)
      ? error.response.data
      : defaultMessage;

    if (message === defaultMessage) {
      message = _has(error, 'response.data.message')
        ? error.response.data.message
        : defaultMessage;
    }

    if (message === defaultMessage) {
      message = _has(error, 'response.data.msg')
        ? error.response.data['msg']
        : defaultMessage;
    }

    if (message === defaultMessage) {
      message = _has(error, 'message') ? error.message : defaultMessage;
    }

    return message;
  }

  static checkError(e, snackbarUI = null) {
    const message = this.customizeMessage(this.getError(e));
    const code = this.getStatusCode(e);

    if (!snackbarUI) {
      return {message, code};
    }

    snackbarUI.current.open(message);
  }
  
  static throwException(message) {
    // throwException('Something went wrong!');
    throw new Error(message);
  }

  static customizeMessage(message) {
    let customMessage = message;
    if (this.startsWithText(customMessage, 'Error:')) {
      customMessage = 'Chart not found';
    }

    switch (message) {
      case 'unauthorized':
        customMessage = 'Your session has expired';
        break;
    }

    if (customMessage.length > 100) {
      customMessage = 'Server Error';
    }

    return customMessage;
  }

  static startsWithText(str, searchValue) {
    if (_isString(str) && _isString(searchValue)) {
      return _startsWith(str.toLowerCase(), searchValue.toLowerCase());
    } else {
      return false;
    }
  }
}

export default AppHelper;
