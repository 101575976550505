import React from 'react';
import {
    Container,
    Grid,
    InputLabel
} from '../../../components/shared/MaterialUI';
import { WizardStyles } from '../../../assets/css';
import { Divider } from '@material-ui/core';
import { CheckboxField, InputField } from '../../../forms';

const Step5 = (props) => {
    const {
        formField: {
            privateNotes,
            privateNotesDefault,
            specialRequest,
            speReqNotesDefault,
            bbNotes,
        }
    } = props;
    const wizardStyle = WizardStyles();
    return(
        <Container component="main" maxWidth="lg">
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <span className={wizardStyle.title}>Notes</span>
                    <Divider/>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <InputLabel className={wizardStyle.label}>Private notes</InputLabel>
                    <InputField name={privateNotes.name}  fullWidth />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <span className={wizardStyle.title}>Default notes:</span>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <CheckboxField
                      name={privateNotesDefault.name}
                      label={privateNotesDefault.label}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <InputLabel className={wizardStyle.label}>Special request</InputLabel>
                    <InputField name={specialRequest.name}  fullWidth />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <span className={wizardStyle.title}>Default notes:</span>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <CheckboxField
                      name={speReqNotesDefault.name}
                      label={speReqNotesDefault.label}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <InputLabel className={wizardStyle.label}>B&B notes</InputLabel>
                    <InputField name={bbNotes.name}  fullWidth />
                </Grid>
            </Grid>

        </Container>
    )
}

export default Step5;
