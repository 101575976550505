import _ from 'lodash';

class Utils {
    static updateObjectKey(mainArr, valueText = 'id', labelText = 'name') {

        if (_.isObject(mainArr)) {
            return _.mapKeys(mainArr, function (val, key) {
                return (key === valueText) ? 'value' : (key === labelText) ? 'label' : key;
            });
        } else {
            return mainArr;
        }
    }

    static updateArrayObjectItem(mainArr, outArr, valueText = 'id', labelText = 'name') {

        if (_.isArray(mainArr) && _.isArray(outArr)) {
            mainArr.forEach(value => {
                if (typeof value === 'object') {
                    const arr = this.updateObjectKey(value, valueText, labelText);
                    outArr.push(arr);
                }
            });
        }
        return outArr;
    }

    static rand() {
        return Math.round(Math.random() * 20) - 10;
    }

    static getModalStyle() {
        const top = 50 + Math.round(Math.random() * 20) - 10;
        const left = 50 + Math.round(Math.random() * 20) - 10;

        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    static getFormatDateMDY(date){
        const dateAt = _.split(date, '-');
        return `${dateAt[1]}/${dateAt[2]}/${dateAt[0]}`;
    }
}
export default Utils;
