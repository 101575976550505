import { Box, Divider, FormControlLabel, Grid, IconButton, List, Switch, Tooltip } from '@material-ui/core';
import React, { useState } from 'react';
import { WizardStyles } from '../../../assets/css';
import DoneAllIcon from '@material-ui/icons/DoneAll';

const Notification = (props) => {


  const wizardStyle = WizardStyles();
  const [viewNotify, setViewNotify] = useState(false);
  const { handleMarkAllRead, notifications } = props;
  

  return (
    <>
      <Grid container>
        <Grid item xs={10} className={wizardStyle.gridLabel}>
        <FormControlLabel
          className={wizardStyle.controlNotify}
          control={<Switch checked={viewNotify} onChange={(e)=>{setViewNotify(e.target.checked)}}/>}
          label='Show only unread'
        />
        </Grid>
        <Grid item xs={2} style={{textAlign: 'center'}}>
        <Tooltip title="Mark all as read" placement="left">
          <IconButton edge="end" aria-label="comments" onClick={() => handleMarkAllRead()}>
          <DoneAllIcon/>
          </IconButton>
        </Tooltip>
        </Grid>
      </Grid>
      <Divider/>
      <Box m={1}/>
      <List sx={{mb: 2}}>
        {
          (viewNotify)
            ?
            <>
              <div className={wizardStyle.titleNotify}>TODAY</div>
              {notifications('unreadToday')}
              <div className={wizardStyle.titleNotify} style={{paddingTop: '19px'}}>OLDER</div>
              {notifications('unreadOlder')}
            </>
            :
            <>
              <div className={wizardStyle.titleNotify}>TODAY</div>
              {notifications('today')}
              <div className={wizardStyle.titleNotify} style={{paddingTop: '19px'}}>OLDER</div>
              {notifications('older')}
            </>
        }
      </List>
    </>
  )
}

export default Notification;
