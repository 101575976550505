import React, { useState, useEffect, useContext } from 'react';
import {
  Grid,
  Modal,
  IconButton,
  InputLabel,
  TextField,
  Button
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { Form, Formik } from 'formik';
import clsx from 'clsx';
import * as Yup from 'yup';

import { WizardStyles } from '../../../assets/css';
import { AppStyle } from '../../../assets/css/app/AppStyle';
import { useUI } from '../../../app/context/ui';
import { CustomerService } from '../../../services';
import AppButton from '../../forms/AppButton';
import { connect, useSelector } from 'react-redux';
import DatePickerField from '../../../forms/DatePickerField';
import AppHelper from '../../../helpers/AppHelper';
import Utils from "../../../helpers/Utils";
import { format } from 'date-fns';
import { PreviousCustomerContext } from '../../../pages/customer/EditCustomer';
import { has as _has } from 'lodash';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const customerService = new CustomerService();

const WriteInModal = (props) => {
  const { open, setOpen, dataToSend, onAfterSend } = props;
  const { id } =useSelector( ({mm}) => mm.customer.options);
  const { blockUI, snackbarUI } = useUI();
  const [modalStyle] = useState(getModalStyle);
  const wizardStyle = WizardStyles();
  const appStyle = AppStyle();
  const [ dayWeekNow, setDayWeekNow ] = useState(new Date());
  const { setUpdateServiceNote } = useContext(PreviousCustomerContext);

  const validationSchema = Yup.object({
    write_date: Yup.string().nullable().required('Field is required'),
    message: Yup.string().nullable(),
  });

  const onSend = async (values) => {
    try {
      blockUI.current.open(true);
      customerService.getAccessToken();
      await customerService.attributes({
        ...values,
        status: 'write',
      }, id);
      onAfterSend();
      setUpdateServiceNote(new Date());
      blockUI.current.open(false);
      setOpen(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  useEffect(() => {
    const startDate = new Date(dataToSend.startAt);
    const endDate = new Date(dataToSend.endAt);
    while (startDate <= endDate) {
      if (startDate.getDay() === new Date().getDay()) {
        setDayWeekNow(format(new Date(startDate), 'yyyy-MM-dd'));
      }
      startDate.setDate(startDate.getDate()+1);
    }

    if(
      dataToSend?.customer?.workday &&
      dataToSend?.customer?.message &&
      dataToSend?.customer?.typeServiceNote === 'write'
    ){
      const dateWorkday = new Date(dataToSend.customer.workday);
      dateWorkday.setDate(dateWorkday.getDate()+1);
      setDayWeekNow(format(new Date(dateWorkday), 'yyyy-MM-dd'));
    }
  }, []);

  const addDays = (date, days) => {
    date.setDate(date.getDate() + days);
    return date;
  }

  const disableWeekends = (date) => {
    return date.getDay() === 0 || date.getDay() === 6;
  }

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
    >
      <div style={modalStyle} className={wizardStyle.paperModal}>
        <Formik
          initialValues={{
            ...dataToSend,
            write_date: new Date(Utils.getFormatDateMDY(dayWeekNow)),
            message: (dataToSend?.customer?.typeServiceNote === 'write') ? dataToSend?.customer?.message : '',
            isRegularService: (dataToSend?.customer?.typeServiceNote === 'write') ? dataToSend?.customer?.isRegularService : false
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => onSend(values)}
          enableReinitialize={true}
        >
          {(props) => {
            const {handleSubmit, values, handleChange, errors, setFieldValue} = props;
            return (
              <Form autoComplete="off">
                <Grid container alignItems="center" spacing={2}>
                  <Grid container item xs={12}>
                    <Grid item xs={10}>
                    <h2 className={clsx(appStyle.mrg0V)}>Write In Service </h2>
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton
                        color="primary"
                        aria-label="close"
                        className={clsx(appStyle.pdg0, appStyle.btnRight)}
                        onClick={() => setOpen(false)}
                      >
                        <CloseIcon/>
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <span className={wizardStyle.label}>
                      Choose the date of the write in:
                    </span>
                  </Grid>
                  <Grid item xs={1} style={{marginTop: '10px'}}>
                    <InputLabel className={wizardStyle.labelW}>
                      Date:
                    </InputLabel>
                  </Grid>
                  <Grid item xs={8}>
                    <DatePickerField
                      disableToolbar
                      name='write_date'
                      format='MM/dd/yyyy'
                      minDate={new Date(Utils.getFormatDateMDY(values?.startAt))}
                      maxDate={addDays(new Date(Utils.getFormatDateMDY(values?.endAt)),7)}
                      shouldDisableDate={disableWeekends}
                      /*maxDate={new Date(Utils.getFormatDateMDY(values?.endAt))}*/
                      autoOk
                      variant="inline"
                    />
                  </Grid>
                  <Grid item xs={3} style={{textAlign: 'right'}}>
                    <Button
                      variant='contained'
                      color={(values.isRegularService ? 'secondary' : '')}
                      size='small'
                      onClick={() => {
                        setFieldValue('isRegularService', !values.isRegularService);
                      }}
                    >
                      Regular Service
                    </Button>
                  </Grid>
                  <Grid item xs={12} style={{marginTop: '10px'}}>
                    <InputLabel className={wizardStyle.labelW}>
                      Message:
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name='message'
                      value={values.message}
                      onChange={handleChange}
                      multiline
                      minRows={4}
                      variant='outlined'
                      helperText={_has(errors, 'message') ? errors.message : ''}
                      style={{ width: '100%' }}
                      fullWidth={true}
                      placeholder="Write here"
                      inputProps={{
                        maxLength: 200,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} style={{marginTop: '20px'}}>
                    <AppButton
                      onPress={() => setOpen(false)}
                      label={'Cancel'}
                      type={'main'}
                    />
                  </Grid>
                  <Grid item xs={6} style={{marginTop: '20px'}}>
                    <AppButton
                      onPress={() => {
                        handleSubmit();
                      }}
                      label={'Save'}
                      type={'main'}
                      color={'primary'}
                    />
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  )
}

const mapsStateToProps = (state) => {
  return {
    mm: state.mm,
    user: state.user
  };
};

export default connect(mapsStateToProps)(WriteInModal);
