import React, { useContext, useEffect } from 'react';

import { useFormikContext } from 'formik';
import { isEqual as _isEqual } from 'lodash';

import { useUI } from '../../../../../app/context/ui';
import {
  Box, FormControlLabel, FormLabel, Radio,
  RadioGroup, TextField, Typography,
} from '../../../../../components/shared/MaterialUI';
import { SignUpCustomerContext } from '../../SignUpProcess';
import { YesNoQStyles } from '../styles/yesNo-style';
import alertMessages from './messages/alertMessages.json';

const YesNoQuestions = () => {
  const { state, dispatch } = useContext(SignUpCustomerContext);
  const classes = YesNoQStyles();
  const { dialogUI } = useUI();

  const formik = useFormikContext();

  const {
    grassOvergrown,
    isCommunityGate,
    isBackyardGate,
    isItLocked,
    isFlowerBeds,
    isFlowerBedsDamage,
    isPetsBackyard,
    isFencePlastic,
    disclaimerFencePlastic,
    isAboveGroundPool,
    disclaimerAboveGroundPool,
    isTrampoline,
    disclaimerTrampoline,
    isLineTrimBeds,
  } = state;

  const handleInputs = (e) => {
    const { name, value } = e.target;
    formik.setFieldValue(name, value);
    dispatch({
      type: 'setCheckboxes',
      payload: { [name]: value },
    });
    if (value === 'No') {
      switch (name) {
        case 'isBackyardGate':
          {
            dispatch({
              type: 'setCheckboxes',
              payload: {
                gateCode: '',
                isbackyardComboLock: '',
                isItLocked: '',
              },
            });
          }
          break;
        case 'isCommunityGate':
          {
            dispatch({
              type: 'setCheckboxes',
              payload: {
                communityCode: '',
              },
            });
          }
          break;
        case 'isFlowerBeds':
          {
            dispatch({
              type: 'setCheckboxes',
              payload: {
                flowerAnswer: '',
                isFlowerBedsDamage: '',
              },
            });
          }
          break;
        case 'isPetsBackyard':
          {
            dispatch({
              type: 'setCheckboxes',
              payload: {
                isPetNoTrouble: '',
              },
            });
          }
          break;
        case 'isFencePlastic':
          {
            dispatch({
              type: 'setCheckboxes',
              payload: {
                disclaimerFencePlastic: '',
              },
            });
          }
          break;
        case 'isAboveGroundPool':
          {
            dispatch({
              type: 'setCheckboxes',
              payload: {
                disclaimerAboveGroundPool: '',
              },
            });
          }
          break;
        case 'isTrampoline':
          {
            dispatch({
              type: 'setCheckboxes',
              payload: {
                disclaimerTrampoline: '',
              },
            });
          }
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (_isEqual(grassOvergrown, 'Yes')) {
      dialogUI.current.open('Notice', alertMessages.grassOvergrown);
    }
  }, [dialogUI, grassOvergrown]);

  useEffect(() => {
    if (_isEqual(isFlowerBedsDamage, 'Yes')) {
      dialogUI.current.open('Notice', alertMessages.isFlowerBedsDamage);
    }
  }, [isFlowerBedsDamage, dialogUI]);

  useEffect(() => {
    if (_isEqual(isFlowerBeds, 'Yes')) {
      dialogUI.current.open('Notice', alertMessages.isFlowerBeds);
    }
  }, [isFlowerBeds, dialogUI]);

  useEffect(() => {
    if (_isEqual(disclaimerFencePlastic, 'No')) {
      dialogUI.current.open('Notice', alertMessages.disclaimerLiability);
    }
  }, [disclaimerFencePlastic, dialogUI]);

  useEffect(() => {
    if (_isEqual(disclaimerAboveGroundPool, 'No')) {
      dialogUI.current.open('Notice', alertMessages.disclaimerLiability);
    }
  }, [disclaimerAboveGroundPool, dialogUI]);

  useEffect(() => {
    if (_isEqual(disclaimerTrampoline, 'No')) {
      dialogUI.current.open('Notice', alertMessages.disclaimerLiability);
    }
  }, [disclaimerTrampoline, dialogUI]);

  return (
    <>
      <Box className={classes.inputContainer}>
        <FormLabel component='legend' className={classes.label}>
          Is your grass overgrown? <span>*</span>
        </FormLabel>
        <Box className={classes.radioGroup}>
          <RadioGroup
            aria-label='grassOvergrown'
            name='grassOvergrown'
            onChange={(e) => handleInputs(e)}
          >
            <FormControlLabel
              value='Yes'
              control={
                <Radio
                  className={classes.inputRadio}
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                />
              }
              label='Yes'
              className={classes.inputLabel}
            />
            <FormControlLabel
              value='No'
              control={
                <Radio
                  className={classes.inputRadio}
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                />
              }
              label='No'
              className={classes.inputLabel}
            />
          </RadioGroup>
        </Box>
        {state && _isEqual(grassOvergrown, 'Yes') && (
          <>
            <Typography className={classes.alertMessage}>{alertMessages.grassOvergrown}</Typography>
          </>
        )}
      </Box>
      <Box className={classes.inputContainer}>
        <FormLabel component='legend' className={classes.label}>
          Community Gate? <span>*</span>
        </FormLabel>
        <Box className={classes.radioGroup}>
          <RadioGroup
            aria-label='isCommunityGate'
            name='isCommunityGate'
            onChange={(e) => handleInputs(e)}
          >
            <FormControlLabel
              value='Yes'
              control={
                <Radio
                  className={classes.inputRadio}
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                />
              }
              label='Yes'
              className={classes.inputLabel}
            />
            <FormControlLabel
              value='No'
              control={
                <Radio
                  className={classes.inputRadio}
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                />
              }
              label='No'
              className={classes.inputLabel}
            />
          </RadioGroup>
        </Box>
        {state && _isEqual(isCommunityGate, 'Yes') && (
          <Box className={classes.hiddenTextField}>
            <FormLabel component='legend' className={classes.label}>
              Community Access Gate Code? <span>*</span>
            </FormLabel>
            <TextField
              variant='filled'
              style={{ width: '100%' }}
              name='communityCode'
              onChange={(e) => handleInputs(e)}
            />
          </Box>
        )}
      </Box>
      <Box className={classes.inputContainer}>
        <FormLabel component='legend' className={classes.label}>
          Backyard gate? <span>*</span>
        </FormLabel>
        <Box className={classes.radioGroup}>
          <RadioGroup
            aria-label='isBackyardGate'
            name='isBackyardGate'
            onChange={(e) => handleInputs(e)}
          >
            <FormControlLabel
              value='Yes'
              control={
                <Radio
                  className={classes.inputRadio}
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                />
              }
              label='Yes'
              className={classes.inputLabel}
            />
            <FormControlLabel
              value='No'
              control={
                <Radio
                  className={classes.inputRadio}
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                />
              }
              label='No'
              className={classes.inputLabel}
            />
          </RadioGroup>
        </Box>
        {state && _isEqual(isBackyardGate, 'Yes') && (
          <Box style={{ marginTop: '24px' }}>
            <FormLabel component='legend' className={classes.label}>
              Is it locked? <span>*</span>
            </FormLabel>
            <Box className={classes.radioGroup}>
              <RadioGroup
                aria-label='isItLocked'
                name='isItLocked'
                onChange={(e) => handleInputs(e)}
              >
                <FormControlLabel
                  value='Yes'
                  control={
                    <Radio
                      className={classes.inputRadio}
                      disableRipple
                      disableFocusRipple
                      disableTouchRipple
                    />
                  }
                  label='Yes'
                  className={classes.inputLabel}
                />
                <FormControlLabel
                  value='No'
                  control={
                    <Radio
                      className={classes.inputRadio}
                      disableRipple
                      disableFocusRipple
                      disableTouchRipple
                    />
                  }
                  label='No'
                  className={classes.inputLabel}
                />
              </RadioGroup>
            </Box>
          </Box>
        )}
        {state && _isEqual(isItLocked, 'Yes') && _isEqual(isBackyardGate, 'Yes') && (
          <Box style={{ marginTop: '24px' }}>
            <FormLabel component='legend' className={classes.label}>
              Gate Code? <span>*</span>
            </FormLabel>
            <TextField
              variant='filled'
              name='backyardAnswer'
              style={{ width: '100%' }}
              onChange={(e) => handleInputs(e)}
            />
            <FormLabel component='legend' className={classes.label}>
              Do you want us to install a free combo lock to ensure your back yard is mowed?{' '}
              <span>*</span>
            </FormLabel>
            <Box className={classes.radioGroup}>
              <RadioGroup
                aria-label='isbackyardComboLock'
                name='isbackyardComboLock'
                onChange={(e) => handleInputs(e)}
              >
                <FormControlLabel
                  value='Yes'
                  control={
                    <Radio
                      className={classes.inputRadio}
                      disableRipple
                      disableFocusRipple
                      disableTouchRipple
                    />
                  }
                  label='Yes'
                  className={classes.inputLabel}
                />
                <FormControlLabel
                  value='No'
                  control={
                    <Radio
                      className={classes.inputRadio}
                      disableRipple
                      disableFocusRipple
                      disableTouchRipple
                    />
                  }
                  label='No'
                  className={classes.inputLabel}
                />
              </RadioGroup>
            </Box>
          </Box>
        )}
      </Box>
      <Box className={classes.inputContainer}>
        <FormLabel component='legend' className={classes.label}>
          Flower beds? <span>*</span>
        </FormLabel>
        <Box className={classes.radioGroup}>
          <RadioGroup
            aria-label='isFlowerBeds'
            name='isFlowerBeds'
            onChange={(e) => handleInputs(e)}
          >
            <FormControlLabel
              value='Yes'
              control={
                <Radio
                  className={classes.inputRadio}
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                />
              }
              label='Yes'
              className={classes.inputLabel}
            />
            <FormControlLabel
              value='No'
              control={
                <Radio
                  className={classes.inputRadio}
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                />
              }
              label='No'
              className={classes.inputLabel}
            />
          </RadioGroup>
        </Box>
        {state && _isEqual(isFlowerBeds, 'Yes') && (
          <Box style={{ marginTop: '24px' }}>
            <FormLabel component='legend' className={classes.label}>
              Line trim the beds? &#40;***Requires high speed equipment in beds&#41;? <span>*</span>
            </FormLabel>
            <Box className={classes.radioGroup}>
              <RadioGroup
                aria-label='flowerAnswer'
                name='isLineTrimBeds'
                onChange={(e) => handleInputs(e)}
              >
                <FormControlLabel
                  value='Yes'
                  control={
                    <Radio
                      className={classes.inputRadio}
                      disableRipple
                      disableFocusRipple
                      disableTouchRipple
                    />
                  }
                  label='Yes'
                  className={classes.inputLabel}
                />
                <FormControlLabel
                  value='No'
                  control={
                    <Radio
                      className={classes.inputRadio}
                      disableRipple
                      disableFocusRipple
                      disableTouchRipple
                    />
                  }
                  label='No'
                  className={classes.inputLabel}
                />
              </RadioGroup>
            </Box>
          </Box>
        )}
        {state && _isEqual(isLineTrimBeds, 'Yes') && _isEqual(isFlowerBeds, 'Yes') && (
          <Box style={{ marginTop: '24px' }}>
            <FormLabel component='legend' className={classes.label}>
              Is there anything in the flower beds that could be damaged?
              <span> *</span>
            </FormLabel>
            <Box className={classes.radioGroup}>
              <RadioGroup
                aria-label='isFlowerBedsDamage'
                name='isFlowerBedsDamage'
                onChange={(e) => handleInputs(e)}
              >
                <FormControlLabel
                  value='Yes'
                  control={
                    <Radio
                      className={classes.inputRadio}
                      disableRipple
                      disableFocusRipple
                      disableTouchRipple
                    />
                  }
                  label='Yes'
                  className={classes.inputLabel}
                />
                <FormControlLabel
                  value='No'
                  control={
                    <Radio
                      className={classes.inputRadio}
                      disableRipple
                      disableFocusRipple
                      disableTouchRipple
                    />
                  }
                  label='No'
                  className={classes.inputLabel}
                />
              </RadioGroup>
            </Box>
            {state && _isEqual(isFlowerBedsDamage, 'Yes') && (
              <>
                <Typography className={classes.alertMessage}>
                  {alertMessages.isFlowerBedsDamage}
                </Typography>
              </>
            )}
          </Box>
        )}
      </Box>
      <Box className={classes.inputContainer}>
        <FormLabel component='legend' className={classes.label}>
          Pets in Backyard? <span>*</span>
        </FormLabel>
        <Box className={classes.radioGroup}>
          <RadioGroup
            aria-label='isPetsBackyard'
            name='isPetsBackyard'
            onChange={(e) => handleInputs(e)}
          >
            <FormControlLabel
              value='Yes'
              control={
                <Radio
                  className={classes.inputRadio}
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                />
              }
              label='Yes'
              className={classes.inputLabel}
            />
            <FormControlLabel
              value='No'
              control={
                <Radio
                  className={classes.inputRadio}
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                />
              }
              label='No'
              className={classes.inputLabel}
            />
          </RadioGroup>
        </Box>
        {state && _isEqual(isPetsBackyard, 'Yes') && (
          <Box style={{ marginTop: '24px' }}>
            <FormLabel component='legend' className={classes.label}>
              Can we mow around them without fear of being bitten or creating problems for our crew?{' '}
              <span>*</span>
            </FormLabel>
            <Box className={classes.radioGroup}>
              <RadioGroup
                aria-label='isPetNoTrouble'
                name='isPetNoTrouble'
                onChange={(e) => handleInputs(e)}
              >
                <FormControlLabel
                  value='Yes'
                  control={
                    <Radio
                      className={classes.inputRadio}
                      disableRipple
                      disableFocusRipple
                      disableTouchRipple
                    />
                  }
                  label='Yes'
                  className={classes.inputLabel}
                />
                <FormControlLabel
                  value='yes_knock'
                  control={
                    <Radio
                      className={classes.inputRadio}
                      disableRipple
                      disableFocusRipple
                      disableTouchRipple
                    />
                  }
                  label='Yes, but please knock on the front door before entering backyard'
                  className={classes.inputLabel}
                />
              </RadioGroup>
            </Box>
          </Box>
        )}
      </Box>
      <Box className={classes.inputContainer}>
        <FormLabel component='legend' className={classes.label}>
          Is your fence made of white vinyl plastic? <span>*</span>
        </FormLabel>
        <Box className={classes.radioGroup}>
          <RadioGroup
            aria-label='isFencePlastic'
            name='isFencePlastic'
            onChange={(e) => handleInputs(e)}
          >
            <FormControlLabel
              value='Yes'
              control={
                <Radio
                  className={classes.inputRadio}
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                />
              }
              label='Yes'
              className={classes.inputLabel}
            />
            <FormControlLabel
              value='No'
              control={
                <Radio
                  className={classes.inputRadio}
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                />
              }
              label='No'
              className={classes.inputLabel}
            />
          </RadioGroup>
        </Box>
        {state && _isEqual(isFencePlastic, 'Yes') && (
          <Box style={{ marginTop: '24px' }}>
            <FormLabel component='legend' className={classes.label}>
              Vinyl plastic fences are a challenge for us and all lawn companies, so we ask to be
              released from liability if anything happens to this item. Do you agree? <span>*</span>
            </FormLabel>
            <Box className={classes.radioGroup}>
              <RadioGroup
                aria-label='disclaimerFencePlastic'
                name='disclaimerFencePlastic'
                onChange={(e) => handleInputs(e)}
              >
                <FormControlLabel
                  value='Yes'
                  control={
                    <Radio
                      className={classes.inputRadio}
                      disableRipple
                      disableFocusRipple
                      disableTouchRipple
                    />
                  }
                  label='Yes'
                  className={classes.inputLabel}
                />
                <FormControlLabel
                  value='No'
                  control={
                    <Radio
                      className={classes.inputRadio}
                      disableRipple
                      disableFocusRipple
                      disableTouchRipple
                    />
                  }
                  label='No'
                  className={classes.inputLabel}
                />
              </RadioGroup>
            </Box>
            {formik.errors && formik.errors.disclaimerFencePlastic && (
              <>
                <Typography className={classes.alertMessage}>
                  {formik.errors.disclaimerFencePlastic}
                </Typography>
              </>
            )}
          </Box>
        )}
      </Box>
      <Box className={classes.inputContainer}>
        <FormLabel component='legend' className={classes.label}>
          Do you have an above ground pool? <span>*</span>
        </FormLabel>
        <Box className={classes.radioGroup}>
          <RadioGroup
            aria-label='isAboveGroundPool'
            name='isAboveGroundPool'
            onChange={(e) => handleInputs(e)}
          >
            <FormControlLabel
              value='Yes'
              control={
                <Radio
                  className={classes.inputRadio}
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                />
              }
              label='Yes'
              className={classes.inputLabel}
            />
            <FormControlLabel
              value='No'
              control={
                <Radio
                  className={classes.inputRadio}
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                />
              }
              label='No'
              className={classes.inputLabel}
            />
          </RadioGroup>
        </Box>
        {state && _isEqual(isAboveGroundPool, 'Yes') && (
          <Box style={{ marginTop: '24px' }}>
            <FormLabel component='legend' className={classes.label}>
              Above ground pools are a challenge for us and all lawn companies, so we ask to be
              released from liability if anything happens to this item. Do you agree? <span>*</span>
            </FormLabel>
            <Box className={classes.radioGroup}>
              <RadioGroup
                aria-label='disclaimerAboveGroundPool'
                name='disclaimerAboveGroundPool'
                onChange={(e) => handleInputs(e)}
              >
                <FormControlLabel
                  value='Yes'
                  control={
                    <Radio
                      className={classes.inputRadio}
                      disableRipple
                      disableFocusRipple
                      disableTouchRipple
                    />
                  }
                  label='Yes'
                  className={classes.inputLabel}
                />
                <FormControlLabel
                  value='No'
                  control={
                    <Radio
                      className={classes.inputRadio}
                      disableRipple
                      disableFocusRipple
                      disableTouchRipple
                    />
                  }
                  label='No'
                  className={classes.inputLabel}
                />
              </RadioGroup>
            </Box>
            {formik.errors && formik.errors.disclaimerAboveGroundPool && (
              <>
                <Typography className={classes.alertMessage}>
                  {formik.errors.disclaimerAboveGroundPool}
                </Typography>
              </>
            )}
          </Box>
        )}
      </Box>
      <Box className={classes.inputContainer}>
        <FormLabel component='legend' className={classes.label}>
          Do you have a trampoline and want us to move it? <span>*</span>
        </FormLabel>
        <Box className={classes.radioGroup}>
          <RadioGroup
            aria-label='isTrampoline'
            name='isTrampoline'
            onChange={(e) => handleInputs(e)}
          >
            <FormControlLabel
              value='Yes'
              control={
                <Radio
                  className={classes.inputRadio}
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                />
              }
              label='Yes'
              className={classes.inputLabel}
            />
            <FormControlLabel
              value='No'
              control={
                <Radio
                  className={classes.inputRadio}
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                />
              }
              label='No'
              className={classes.inputLabel}
            />
          </RadioGroup>
        </Box>
        {state && _isEqual(isTrampoline, 'Yes') && (
          <Box style={{ marginTop: '24px' }}>
            <FormLabel component='legend' className={classes.label}>
              Trampolines are a challenge for us and all lawn companies, so we ask to be released
              from liability if anything happens to this item. Do you agree? <span>*</span>
            </FormLabel>
            <Box className={classes.radioGroup}>
              <RadioGroup
                aria-label='disclaimerTrampoline'
                name='disclaimerTrampoline'
                onChange={(e) => handleInputs(e)}
              >
                <FormControlLabel
                  value='Yes'
                  control={
                    <Radio
                      className={classes.inputRadio}
                      disableRipple
                      disableFocusRipple
                      disableTouchRipple
                    />
                  }
                  label='Yes'
                  className={classes.inputLabel}
                />
                <FormControlLabel
                  value='No'
                  control={
                    <Radio
                      className={classes.inputRadio}
                      disableRipple
                      disableFocusRipple
                      disableTouchRipple
                    />
                  }
                  label='No'
                  className={classes.inputLabel}
                />
              </RadioGroup>
            </Box>
            {formik.errors && formik.errors.disclaimerTrampoline && (
              <>
                <Typography className={classes.alertMessage}>
                  {formik.errors.disclaimerTrampoline}
                </Typography>
              </>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

export default YesNoQuestions;
