import React, { useContext } from 'react';

import { useFormikContext } from 'formik';

import {
  Box, TextField, Typography,
} from '../../../../../../components/shared/MaterialUI';
import { SignUpCustomerContext } from '../../../SignUpProcess';
import Labels from '../../atoms/Labels';

const ECheckInfo = () => {
  const { dispatch } = useContext(SignUpCustomerContext);

  const formik = useFormikContext();
  const { errors, touched } = formik;

  const handleTextInputs = (e) => {
    const { name, value } = e.target;
    formik.setFieldValue(name, value);
    dispatch({
      type: 'setTextFields',
      payload: { [name]: value },
    });
  };

  return (
    <>
      <Typography style={{ textTransform: 'uppercase', fontWeight: 700 }} className='checkTitle'>
        Checking account only
      </Typography>
      <Typography className='checkTitle'>
        Conveniently billed to your bank the next business day after your scheduled mow.
      </Typography>
      <Typography className='checkTitle'>** Paying by E-Check will grant your 5th mow free.</Typography>

      <Box style={{ display: 'flex', alignItems: 'center', margin: '32px 0' }}>
        <img src='https://www.mowmanagers.com/wp-content/uploads/2021/03/Account-Number-on-Check.jpg' alt='' />
      </Box>

      <Labels text='Name On Account' classNames='textLabels billingInputs' required={true} />
      <TextField variant='filled' onChange={(e) => handleTextInputs(e)} name='nameOnCard' required />
      <Box style={{ position: 'relative' }}>
        <Labels text='Routing Number' classNames='textLabels billingInputs' required={true} />
        <TextField
          variant='filled'
          name='routingNumber'
          onChange={(e) => handleTextInputs(e)}
          onBlur={formik.handleBlur}
        />
        {errors.routingNumber && touched.routingNumber && (
          <>
            <Typography style={{ position: 'absolute', left: '10px', bottom: '0', color: '#FF0060', fontSize: '14px' }}>
              {errors.routingNumber}
            </Typography>
          </>
        )}
      </Box>

      <Box style={{ position: 'relative' }}>
        <Labels text='Account Number' classNames='textLabels billingInputs' required={true} />
        <TextField
          variant='filled'
          name='accountNumber'
          onChange={(e) => handleTextInputs(e)}
          onBlur={formik.handleBlur}
        />
        {errors.accountNumber && touched.accountNumber && (
          <>
            <Typography style={{ position: 'absolute', left: '10px', bottom: '0', color: '#FF0060', fontSize: '14px' }}>
              {errors.accountNumber}
            </Typography>
          </>
        )}
      </Box>

      <Box style={{ position: 'relative' }}>
        <Labels text='Verify Account Number' classNames='textLabels billingInputs' required={true} />
        <TextField
          variant='filled'
          name='verifyAccountNumber'
          onChange={(e) => handleTextInputs(e)}
          onBlur={formik.handleBlur}
        />
        {errors.verifyAccountNumber && touched.verifyAccountNumber && (
          <>
            <Typography style={{ position: 'absolute', left: '10px', bottom: '0', color: '#FF0060', fontSize: '14px' }}>
              {errors.verifyAccountNumber}
            </Typography>
          </>
        )}
      </Box>
    </>
  );
};

export default ECheckInfo;
