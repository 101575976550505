import React, { useState } from 'react';
import { useUI } from '../../../app/context/ui';
import {
  Container,
  Typography
} from '../../../components/shared/MaterialUI';
import { ModalStyle } from '../styles/ModalStyle';
import ZipForm from './ZipForm';
import { handleFormSubmit } from './handleFormSubmit';

const ZipManager = (props) => {
  const { 
    typeModal,
    setOpenModal,
    dataEdit,
    marketId,
    getListExceptions,
  } = props;
  const classes = ModalStyle();
  const [initialValues,] = useState({
    zip: dataEdit?.zip || '',
    exceptions: dataEdit?.exceptions || '',
  });

  const { blockUI, snackbarUI, dialogUI } = useUI();  

  const onSubmit = (values) => handleFormSubmit({
    typeModal,
    dataEdit,
    marketId,
    values,
    blockUI,
    dialogUI,
    getListExceptions,
    setOpenModal,
    snackbarUI
  });

  return (
    <Container component="main" maxWidth="sm" className={classes.ctnInner}>
      <Typography variant="h4" className="title">{typeModal} Service Zone</Typography>
      <ZipForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        classes={classes}
        setOpenModal={setOpenModal}
      />
    </Container>
  );
};

export default ZipManager;
