import React, { createRef, useMemo, createContext } from 'react';
import PropTypes from 'prop-types';
import SnackbarUI from '../../components/shared/SnackbarUI';
import BlockUI from '../../components/shared/BlockUI';
import DialogUI from '../../components/shared/DialogUI';

const UIContext = createContext({});

const snackbarUI = createRef();
const blockUI = createRef();
const dialogUI = createRef();

const UIProvider = (props) => {
  const rootUI = useMemo(() => {
    return {
      blockUI,
      snackbarUI,
      dialogUI,
    };
  }, []);

  return (
    <UIContext.Provider value={rootUI}>
      {props.children}
      <SnackbarUI ref={snackbarUI} />
      <BlockUI ref={blockUI} />
      <DialogUI ref={dialogUI} />
    </UIContext.Provider>
  );
};

const useUI = () => {
  return React.useContext(UIContext);
};

UIProvider.propTypes = {
  children: PropTypes.node,
};

export { UIProvider, useUI };
