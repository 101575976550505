import React from 'react'
import clsx from 'clsx';
import { AppStyle } from '../../../assets/css/app/AppStyle';

const Header = () => {
  const appStyle = AppStyle();
  return <h2 className={clsx(appStyle.mrg0V)}>Lookup </h2>;
}

export default Header;
