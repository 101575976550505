import React, { useContext, useState } from 'react';
import { Grid, Modal, IconButton, InputLabel } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { Form, Formik } from 'formik';
import clsx from 'clsx';
import * as Yup from 'yup';

import { WizardStyles } from '../../../assets/css';
import { AppStyle } from '../../../assets/css/app/AppStyle';
import { useUI } from '../../../app/context/ui';
import { CustomerService } from '../../../services';
import AppButton from '../../forms/AppButton';
import { connect, useDispatch, useSelector } from 'react-redux';
import { InputField } from '../../../forms';
import AppHelper from '../../../helpers/AppHelper';
import { updateSkipCount } from '../../../app/store/mm/skipsSlice';
import { PreviousCustomerContext } from '../../../pages/customer/EditCustomer';
import { has as _has } from 'lodash';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const customerService = new CustomerService();

const SkipModal = (props) => {
  const { open, setOpen, dataToSend, onAfterSend } = props;
  const dispatch = useDispatch();
  const { id } =useSelector( ({mm}) => mm.customer.options);
  const { blockUI, snackbarUI } = useUI();
  const [modalStyle] = useState(getModalStyle);
  const wizardStyle = WizardStyles();
  const appStyle = AppStyle();
  const { setUpdateServiceNote } = useContext(PreviousCustomerContext);

  const validationSchema = Yup.object({
    message: Yup.string().nullable().required('Field is required'),
  });

  const onSend = async (values) => {
    try {
      blockUI.current.open(true);
      customerService.getAccessToken();
      await customerService.attributes({
        ...values,
        status: 'skip',
      }, id);
      const {data: skip} = await customerService.getSkipsCount(id);
      dispatch(updateSkipCount({count:skip}));
      onAfterSend();
      setUpdateServiceNote(new Date());
      blockUI.current.open(false);
      setOpen(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
    >
      <div style={modalStyle} className={wizardStyle.paperModal}>
        <Formik
          initialValues={{
            ...dataToSend,
            message: (dataToSend?.customer?.typeServiceNote === 'skips') ? dataToSend?.customer?.message : '',
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => onSend(values)}
          enableReinitialize={true}
        >
          {(props) => {
            const {handleSubmit, values, handleChange, errors} = props;
            return (
              <Form autoComplete="off">
                <Grid container alignItems="center" spacing={2}>
                  <Grid container item xs={12}>
                    <Grid item xs={10}>
                    <h2 className={clsx(appStyle.mrg0V)}>Skip Service </h2>
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton
                        color="primary"
                        aria-label="close"
                        className={clsx(appStyle.pdg0, appStyle.btnRight)}
                        onClick={() => setOpen(false)}
                      >
                        <CloseIcon/>
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <span className={wizardStyle.label}>
                      Provide a reason why you want to skip this service.
                    </span>
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel className={wizardStyle.labelW}>
                      Reason:
                    </InputLabel>
                    <InputField
                      name="message"
                      value={values.message}
                      onChange={handleChange}
                      multiline
                      minRows={4}
                      variant='outlined'
                      helperText={_has(errors, 'message') ? errors.message : ''}
                      style={{ width: '100%' }}
                      fullWidth
                      placeholder="Write here"
                      inputProps={{
                        maxLength: 200,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} style={{marginTop: '20px'}}>
                    <AppButton
                      onPress={() => setOpen(false)}
                      label={'Cancel'}
                      type={'main'}
                    />
                  </Grid>
                  <Grid item xs={6} style={{marginTop: '20px'}}>
                    <AppButton
                      onPress={() => {
                        handleSubmit();
                      }}
                      label={'Save'}
                      type={'main'}
                      color={'primary'}
                    />
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  )
}

const mapsStateToProps = (state) => {
  return {
    mm: state.mm,
    user: state.user
  };
};

export default connect(mapsStateToProps)(SkipModal);
