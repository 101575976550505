import * as Yup from 'yup';
import customerForm from './customerForm';
import { includes as _includes } from 'lodash';

const {
  formField: {
    about,
    aboutOption,
    contactNumber,
    csr,
    zipcode,
    serviceAddress,
    city,
    state,
    billingName,
    cardType,
    market,
    srvFrecuency,
    mowCut,
  }
} = customerForm;

const phoneRegExp = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;

class ValidationFields {

  static about = () => {
    return Yup.string()
      .nullable()
      .required(`${about.requiredErrorMsg}`);
  }

  static aboutOption = () => {
    return Yup.string()
      .nullable()
      .when([about.name], (about) => {
        if (_includes(['1', '2', '4'], about)) {
          return Yup.string().nullable().required(`${aboutOption.requiredErrorMsg}`)
        }
      });
  }

  static email = () => {
    return Yup.string()
      .nullable()
      .email("Invalid email");
  }

  static contactNumber = () => {
    return Yup.string()
      .nullable()
      .required(`${contactNumber.requiredErrorMsg}`)
      .matches(phoneRegExp, contactNumber.invalidErrorMsg);
  }

  static csr = () => {
    return Yup.string()
      .nullable()
      .required(`${csr.requiredErrorMsg}`);
  }

  static sendSchedule = () => {
    return Yup.string()
      .nullable()
  }

  static futureCancel = () => {
    return Yup.string()
      .nullable();
  }

  static zipCode = () => {
    return Yup.string()
      .required(`${zipcode.requiredErrorMsg}`)
      .matches(/^[0-9]{5}$/, `${zipcode.invalidErrorMsg}`)
      .test('len', `${zipcode.invalidErrorMsg}`,
        val => val && val.length === 5
      );
  }

  static city = () => {
    return Yup.string()
      .nullable()
      .required(`${city.requiredErrorMsg}`);
  }

  static state = () => {
    return Yup.string()
      .nullable()
      .required(`${state.requiredErrorMsg}`);
  }

  static address = () => {
    return Yup.string()
      .nullable()
      .required(`${serviceAddress.requiredErrorMsg}`);
  }

  static billingName = () => {
    return Yup.string()
      .nullable()
      .required(`${billingName.requiredErrorMsg}`);
  }

  static cardType = () => {
    return Yup.string()
      .nullable()
      .required(`${cardType.requiredErrorMsg}`);
  }

  static market = () => {
    return Yup.string()
      .nullable()
      .required(`${market.requiredErrorMsg}`);
  }

  static srvFrecuency = () => {
    return Yup.string()
      .nullable()
      .required(`${srvFrecuency.requiredErrorMsg}`);
  }
  static mowCut = () => {
    return Yup.array()
      .transform(ids => {
        return ids.filter(id => {
          return id === 0 || id;
        });
      })
      .min(1, `${mowCut.requiredErrorMsg}`);
  }
}

export default ValidationFields;
