import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { useUI } from '../../../app/context/ui';
import * as Yup from 'yup'; import {
  Container,
  Grid,
  Button,
  TextField,
  Box,
  Typography
} from '../../../components/shared/MaterialUI';
import AppHelper from '../../../helpers/AppHelper';
import { ModalStyle } from '../styles/ModalStyle';

const CustomerReferral = (props) => {
  const { 
    typeModalReferral,
    setOpenModalReferral,
    dataEditReferral,
    dataReferral,
    setDataReferral } = props;
  const classes = ModalStyle();
  const [initialValues,] = useState({
    name: dataEditReferral?.name || '',
  });
  const { blockUI, snackbarUI } = useUI();  
  const validationSchema = Yup.object({
    name: Yup
      .string('Enter name')
      .max(50, "Max. 50 characters")
      .required('Name is required'),
  });  
  const onSubmit = (values) => {
    try {
      blockUI.current.open(true);  
      if (typeModalReferral === 'Add') {
        setDataReferral([...dataReferral, {
          id: null,
          name: values.name,
          types: []
        }]);
      } else {
        const newArray = dataReferral.filter(e => {
          if (e.id === dataEditReferral.id) {
            e.name = values.name;
          }
          return e;
        });
        setDataReferral(newArray);
      }
      blockUI.current.open(false);
      setOpenModalReferral(false);
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  }  

  return (
    <Container component="main" maxWidth="sm" className={classes.ctnInner}>
      <Typography variant="h4" className="title">{typeModalReferral} Market</Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
            onSubmit(values).then(() => { });
        }}
        enableReinitialize={true}
      >
        {(props) => {
          const {
              values,
              touched,
              errors,
              handleChange,
          } = props;
          return (
            <Form autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={3} className={classes.grdItem}>
                  <label>Name:</label>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    variant="outlined"
                    autoFocus
                    fullWidth
                    id="name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    error={touched.name && Boolean(errors.name)}
                    helperText={
                      errors.name && touched.name ? errors.name : ""
                    }
                    className={classes.inputCustom}
                  />
                </Grid>
              </Grid>
              <Box pb={2}/>
              <Grid container justify="center">
                <Button
                  variant="contained"
                  size="large"
                  className={classes.button}
                  onClick={() => { setOpenModalReferral(false); }}
                >
                  Cancel
                </Button>  
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  className={classes.button}
                >
                  Save
                </Button>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};

export default CustomerReferral;
