import React, { useEffect, useState, useMemo } from 'react';
import { Box,TextField } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { has as _has, isUndefined as _isUndefined } from 'lodash';

import DatePickerField from '../../../forms/DatePickerField';
import { SelectField } from '../../../forms';
import { AppStyle } from '../../../assets/css/app/AppStyle';
import { PickItemServiceData } from '../../../services/pickItemService';

const statusDb = [
  {
    value: 2,
    label: 'Completed',
  },{
    value: 1,
    label: 'Pending',
  }
];

const BillingNoteForm = ({isEdit}) => {

  const { values, handleChange, errors } = useFormikContext();
	const [reasonDb, setReasonDb] = useState([]);
  const appStyle = AppStyle();

	const pickItemServiceData = useMemo(() => new PickItemServiceData(), []);

	const getPickItemGroup = (group) => {
    if (!_isUndefined(group)) {
      pickItemServiceData.getAboutOption(group)
        .then(result => setReasonDb(result))
        .catch(() => {
          setReasonDb([])
        });
    }
  }

	useEffect(() => {
    getPickItemGroup(7);
		// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ width: '100%' }}>
			<div>
				<Box display='flex' alignItems='center' mb={2}>
					<Box>
						<span className={appStyle.txtBold}>
							{values.id === 0 ? ' Date and reason of the note:' : 'Time and date of the note:'}
						</span>
					</Box>
				</Box>

				<Box display='flex' flexDirection='row' alignItems='center' mb={2}>
					<Box mr={1}>
						<span className={appStyle.txtBold}>Date: </span>
					</Box>
					<Box width={150} mr={3}>
						<DatePickerField
							disableToolbar
							name='serviceDate'
							value={values.serviceDate}
							onChange={handleChange}
							format='MM/dd/yyyy'
							minDate={new Date()}
							maxDate={new Date('2050/12/31')}
							disabled={values.disabled}
              shouldDisableDate={(date) => date.getDay() === 0 || date.getDay() === 6}
						/>
					</Box>
					<Box mr={1}>
						<span className={appStyle.txtBold}>Reason: </span>
					</Box>
					<Box width={150} mr={3}>
						<SelectField
							name='reason'
							data={reasonDb}
							autoFocus
							fullWidth
              disabled={values.disabled}
							/>
					</Box>
           {
            isEdit && (
              <>
                <Box mr={1}>
                  <span className={appStyle.txtBold}>Status: </span>
                </Box>
                <Box width={150} mr={3}>
                  <SelectField
                    name='status'
                    data={statusDb}
                    autoFocus
                    fullWidth
                    disabled={values.disabled}
                    />
                </Box>
              </>
            )
           }
				</Box>
			</div>
      <Box display='flex' flexDirection='column' mb={2}>
        <Box>
          <TextField
            error={_has(errors, 'description')}
            name='description'
            value={values.description}
            onChange={handleChange}
            multiline
            rows={4}
            variant='outlined'
            helperText={_has(errors, 'description') ? errors.description : ''}
            style={{ width: '100%' }}
            fullWidth={true}
            disabled={values.disabled}
            placeholder="Write here"
          />
        </Box>
      </Box>
      {values.customerManager && (
        <Box display='flex' flexDirection='row' mb={2}>
          <Box mr={1}>
            <span className={appStyle.txtBold}>Customer managger:</span>
          </Box>
          <Box>
            <span>{values.customerManager}</span>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default BillingNoteForm;
