import React from 'react';

import { Box, Typography } from '../../../../../components/shared/MaterialUI';

const Divider = ({ text }) => {
  return (
    <>
      <Box
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 25% 1fr',
          width: '100%',
          alignItems: 'center',
          marginBottom: '24px',
        }}
      >
        <hr style={{ width: '100%', height: '1px', margin: '0', opacity: '0.3' }} />
        <Typography
          style={{
            padding: '0 18px',
            fontSize: '35px',
            fontFamily: 'Roboto Condensed',
            color: '#444444',
            textAlign: 'center',
          }}
        >
          {text}
        </Typography>
        <hr style={{ width: '100%', height: '1px', margin: '0', opacity: '0.3' }} />
      </Box>
    </>
  );
};

export default Divider;
