import AppService from './AppService';

class BusinessLogicService extends AppService {
  constructor() {
    super();
    this.path = this.getPath('base-price');
  }

  read(id) {
    return this.http.get(`${this.path}/${id}`);
  }

  list() {
    return this.http.get(`${this.path}`);
  }

  findLotSize(id) {
    return this.http.get(`${this.path}/lot-size/${id}`);
  }

  findTurfSize(id) {
    return this.http.get(`${this.path}/turf-size/${id}`);
  }

  findLotSizeCustomer(id, customer) {
    return this.http.get(`${this.path}/lot-size/${id}/${customer}`);
  }

  findTurfSizeCustomer(id, customer) {
    return this.http.get(`${this.path}/turf-size/${id}/${customer}`);
  }
}

export default BusinessLogicService;

