import React, { useEffect, useState } from 'react';
import {
  isEmpty as _isEmpty,
  isUndefined as _isUndefined,
  split as _split,
  words as _words,
  toNumber as _toNumber,
} from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Grid } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import { useUI } from '../../app/context/ui';
import { ListStyles } from '../../assets/css';
import AppHelper from '../../helpers/AppHelper';
import { InvoiceService } from '../../services';

const ListInvoices = () => {
  const history = useHistory();
  const location = useLocation();
  const locationState = location.state || [];
  let dataProcess;
  const { blockUI, snackbarUI } = useUI();
  const [paginationURL, setPaginationURL] = useState('');
  const query = new URLSearchParams(useLocation().search);
  const invoiceService = new InvoiceService();
  const listStyle = ListStyles();
  const [rowsState, setRowsState] = useState({
    pageSize: 50,
    rows: [],
    rowCount: 0,
    page: _toNumber(query.get('page')) || 0,
  });

  const link = _isUndefined(locationState)
    ? ''
    : _isEmpty(locationState.condition)
    ?
    _isEmpty(locationState.state)
      ? ''
      : '&' + locationState.state
    : '&' + locationState.condition

  const search = `?render=paginate&limit=${rowsState.pageSize}&page=
  ${rowsState.page + 1}`;

  const getListInvoices = async (pageSize) => {
    try {
      blockUI.current.open(true);
      invoiceService.getAccessToken();
      const query = _words(search, /[^&]+/g)
        .map( (n)=> {
            return n.substring(0,5) === 'limit' &&  pageSize ? `limit=${pageSize}` : n
        })
        .slice(0, -1).join('&');
      const r1 = link
        ? await invoiceService.listInvoices(`${query}`)
        : await invoiceService.getInvoices({
            page: rowsState.page + 1,
            limit: pageSize || rowsState.pageSize
          })
      dataProcess = r1.data;
      setPaginationURL(_split(dataProcess.firstPageUrl, '?')[1]);
      setRowsState((prev) => ({
        pageSize: dataProcess.perPage || prev.pageSize,
        rows: dataProcess.data,
        rowCount: dataProcess.total,
        page: dataProcess.currentPage - 1,
      }));
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const handleOnPageChange = async (page) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const sort = urlSearchParams.get("sort");
    const query = _words(paginationURL, /[^&]+/g)
        .map( (n)=> {
          return n.substring(0,4) === 'sort' &&  !_isEmpty(sort) ? `sort=${sort}` : n
        })
        .slice(0, -1).join('&');
    const find = query.indexOf('sort=') === -1 ? `${query}` : query;
    try {
      blockUI.current.open(true);
      invoiceService.getAccessToken();
      const response = await invoiceService.paginationInvoices(`${find}`, page + 1);
      const dataProcess = response.data;
      setRowsState((prev) => ({
        ...prev,
        rowCount: dataProcess.total,
        page: dataProcess.currentPage - 1,
      }));
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const renderColumns = [
    {
      field: 'transactionId',
      headerName: 'Payment number',
      flex: 0.1,
      minWidth: 200,
      disableColumnMenu: true,
    },
    {
      field: 'total',
      headerName: 'Total',
      flex: 0.1,
      minWidth: 100,
      disableColumnMenu: true,
    },
    {
      field: 'address',
      headerName: 'Service address',
      flex: 0.1,
      minWidth: 200,
      disableColumnMenu: true,
      renderCell: (params) => {
        return params.row.customers.serviceAddress
      },
    },
    {
      field: 'customerId',
      headerName: 'Account number',
      flex: 0.1,
      minWidth: 200,
      disableColumnMenu: true,
    },
    {
      field: 'accountName',
      headerName: 'Account name',
      flex: 0.1,
      minWidth: 200,
      disableColumnMenu: true,
      renderCell: (params) => {
        return params.row.customers.fullName
      },
    },
    {
      field: 'createdAt',
      headerName: 'Date',
      flex: 0.1,
      minWidth: 200,
      disableColumnMenu: true,
    },
    {
      field: 'statusName',
      headerName: 'Status',
      flex: 0.1,
      minWidth: 150,
      disableColumnMenu: true,
    },
  ];

  const handlePageSizeChange = (pageSize) => {
    getListInvoices(pageSize)
  };

  const insertUrlParam = (key, value) => {
    let searchParams = new URLSearchParams(window.location.search)
    searchParams.set(key, value)
    history.push({ ...location, search: searchParams.toString() })
  };

  useEffect(() => {
    getListInvoices()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <>
      <Grid container style={{ marginBottom: '12px' }}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={9}>
              <div style={{ marginBottom: '30px', fontSize: '28px', fontWeight: '700' }}>
                Invoices list
              </div>
            </Grid>
            <Grid item xs={3}>
              <div style={{ marginTop: '20px' }}>
                Search results: {rowsState && (rowsState.rowCount)} records
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Box style={{ height: 580, width: '100%' }}>
          <DataGrid
            autoHeight
            headerHeight={56}
            rowHeight={56}
            columns={renderColumns}
            {...rowsState}
            rowsPerPageOptions={[50, 75, 100]}
            paginationMode='server'
            className={listStyle.dataGrid}
            onPageChange={(page) => {
              insertUrlParam('page', page)
              handleOnPageChange(page)
            }}
            onPageSizeChange={(pageSize) => {
              handlePageSizeChange(pageSize)
            }}
            hideFooterSelectedRowCount={true}
          />
        </Box>
      </Grid>
    </>
  )
}

export default ListInvoices;
