import React, { useCallback } from 'react';
import {
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Tooltip
} from "@material-ui/core";
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { HeaderStyle } from './styles/HeaderStyle';
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { CustomerService } from '../../services';
import { useUI } from '../../app/context/ui';
import AppHelper from '../../helpers/AppHelper';
import { useFormikContext } from 'formik';
import { some as _some, isArray as _isArray, clone as _clone } from 'lodash';
import {updateProperty} from "../../app/store/mm/customerSlice";
import {useDispatch} from "react-redux";

const customerService = new CustomerService();

const Header = (props) => {
  const classes = HeaderStyle();
  const dispatch = useDispatch();
  const {blockUI, snackbarUI} = useUI();
  const formik = useFormikContext();
  const {values: formValues} = useFormikContext();
  const {id, winterScheduleStatus} = formValues;
  const { checkedYear } = props;

  const handleChangeRangeDate = (e) => {
    props.setRangeDate(e.target.value);
  };

  const handleChange = useCallback(async (event) => {
    try {
      blockUI.current.open(true);
      customerService.getAccessToken();
      let optWinter = _clone(_isArray(winterScheduleStatus) ? winterScheduleStatus : []);
      if (_some(optWinter, {year: props.year})) {
        optWinter.map((winter, index) => {
          if (winter.year === props.year) {
            optWinter[index] = {year: props.year, status: event.target.checked};
          }
          return winter;
        });
      } else {
        optWinter.push({year: props.year, status: event.target.checked})
      }
      props.setCheckedYear(event.target.checked);

      await customerService.update({winterScheduleStatus: optWinter, year: props.year}, id);
      dispatch(updateProperty({winterScheduleStatus: optWinter}));
      formik.setFieldValue(winterScheduleStatus, optWinter);

      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  }, [blockUI, winterScheduleStatus, props, id, dispatch, formik, snackbarUI]);

  return (
    <Grid container className={classes.ctnCalendar}>
      <Grid container>
        <Grid item xs={12} className={classes.title}>
          All weeks {props.year}
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <FormGroup row>
          <FormControlLabel
            className={classes.checkboxWinter}
            control={
              <Checkbox
                checked={checkedYear}
                onChange={handleChange}
              />
            }
            label="Opt. winter"
          />
        </FormGroup>
      </Grid>
      <Grid item xs={4} className={classes.wrapperArrows}>
        <Tooltip title="Prev">
          <IconButton aria-label="delete" onClick={props.onPrevYear}>
            <NavigateBeforeIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Next">
          <IconButton aria-label="delete" onClick={props.onNextYear}>
            <NavigateNextIcon />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item xs={4}>
        <FormControl className={classes.formControl}>
          <Select
            value={props.rangeDate}
            onChange={handleChangeRangeDate}
            displayEmpty
            className={classes.selectEmpty}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem value="" disabled>
              Range date
            </MenuItem>
            <MenuItem value="all">All weeks</MenuItem>
            <MenuItem value="winter">Only winter</MenuItem>
            <MenuItem value="season">Only in season</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  )
}

export default Header;
