import AppService from './AppService';

class LogService extends AppService {

  constructor() {
    super();
    this.path = this.getPath('logs');
  }

  list(params = '') {
    const queryParams = params ? params : '';
    return this.http.get(`${this.path}${queryParams}`);
  }
}

export default LogService;

