import React from 'react';
import { at } from 'lodash';
import { useField } from 'formik';
import {
    Radio,
    FormControl,
    FormControlLabel,
    FormHelperText,
    RadioGroup,
} from '@material-ui/core';

export default function RadioField(props) {

    const {
        options = [{"label": "Yes", "value": "Yes"}, {"label": "No", "value": "No"}],
        label,
        styleDirectionWrapper,
        styleDirectionItems,
        ...rest } = props;
    const [field, meta, helper] = useField(props);
    const { setValue } = helper;

    function _renderHelperText() {
        const [touched, error] = at(meta, 'touched', 'error');
        if (touched && error) {
            return <FormHelperText error={true} style={{textAlign: 'center'}}>{error}</FormHelperText>;
        }
    }

    function _onChange(e) {
        setValue(e.target.value);
    }

    return (
        <FormControl
            style={
                (styleDirectionWrapper && styleDirectionWrapper === 'column')
                ? { flexDirection: 'column' }
                : { flexDirection: 'row' }
            }
            {...rest}
        >
            <RadioGroup
                style={
                    (styleDirectionItems && styleDirectionItems === 'column')
                    ? { flexDirection: 'column' }
                    : { flexDirection: 'row' }
                }
                {...field}
                {...rest}
                onChange={_onChange}
            >
                {options.map(({ value, label }) => {
                    return (
                        <FormControlLabel
                            key={value}
                            value={value}
                            control={<Radio color="secondary"/>}
                            label={label}
                        />

                    );
                })}
            </RadioGroup>
            {_renderHelperText()}
        </FormControl>
    );
}
