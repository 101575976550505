import { Button, Container } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useUI } from '../../../app/context/ui';
import { ListStyles } from '../../../assets/css';
import AppHelper from '../../../helpers/AppHelper';
import { QueriesService } from '../../../services';
import { useStyles } from '../style/lookup-style';

let dlgSettings = {
  confirm: true,
  btn: {
    close: 'Cancel',
    confirm: 'Yes',
  },
  onConfirm: () => {},
};

const ListLookup = () => {

  const listStyle = ListStyles();
  const lookUpStyle = useStyles();
  const [rows, setRows] = useState([]);
  const { blockUI, snackbarUI, dialogUI } = useUI();
  const queriesService = useMemo(() => new QueriesService(), []);
  const history = useHistory();

  const handleSetterIdDelete = (id) => {
    dlgSettings = {
      ...dlgSettings,
      onConfirm: () => {
        onDelete(id);
      },
    };
    dialogUI.current.open('Querie', 'Are you sure to delete this querie?', dlgSettings);
  };

  const handleProccessQuerie = async (id) => {
    try {
      blockUI.current.open(true);
      queriesService.getAccessToken();
      let r1 = await queriesService.process(id);
      history.push('/customer',{process: r1.data});
      dialogUI.current.close();
      blockUI.current.open(false);
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  }

  const onDelete = async (id) => {
    try {
      blockUI.current.open(true);
      queriesService.getAccessToken();
      await queriesService.delete(id);
      let res = rows.filter((e)=>(e.id != id));
      setRows(res);
      dialogUI.current.close();
      blockUI.current.open(false);
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const columns = [
    { field: 'name', headerName: 'Search name', flex: 0.3, minWidth: 250 },
    {
      field: 'id',
      headerName: 'Actions',
      flex: 0.1,
      minWidth: 200,
      renderCell: (params) => (
        <>
          <Button variant='contained' color='primary' size='small' className={lookUpStyle.btnDelete} onClick={()=>{handleSetterIdDelete(params.value)}}>
            Delete search
          </Button>
          <Button variant='contained' color='primary' size='small' style={{ marginLeft: 16, textTransform: 'none' }} onClick={()=>{handleProccessQuerie(params.value)}}>
            Use this search
          </Button>
        </>
      ),
    },
  ];

  const getListQueries = useCallback(
    async () => {
      try {
        blockUI.current.open(true);
        queriesService.getAccessToken();
        const r1 = await queriesService.list();
        setRows(r1.data);
        blockUI.current.open(false);
      } catch (e) {
        blockUI.current.open(false);
        AppHelper.checkError(e, snackbarUI);
      }
    },
    [blockUI, queriesService, snackbarUI]
  );


  useEffect(() => {
    (async function init() {
      await getListQueries();
    })();
  }, [getListQueries]);

  return (
    <Container>
      <div style={{ height: 580, width: '100%', marginTop: '40px' }}>
        <DataGrid
          autoHeight
          pagination
          rows={rows}
          pageSize={10}
          columns={columns}
          className={listStyle.dataGrid}
        />
      </div>
    </Container>
  );
};

export default ListLookup;
