import React, { memo, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Box, Button, Grid, Modal, IconButton } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import CloseIcon from '@material-ui/icons/Close';

import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { isEmpty as _isEmpty, has as _has } from 'lodash';
import clsx from 'clsx';
import format from 'date-fns/format';
import { serialize } from 'object-to-formdata';

import { WizardStyles } from '../../../assets/css';
import { NoteService } from '../../../services';
import AppButton from '../../forms/AppButton';
import { AppStyle } from '../../../assets/css/app/AppStyle';
import { useUI } from '../../../app/context/ui';
import BillingNoteTab from './BillingNoteTab';
import moment from "moment";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const formState = {
  id: 0,
  customerAttributesId: '',
  serviceDate: '',
	reason: '',
  description: '',
  disabled: false,
  typeId: '',
  temporary: false,
  attachments: [],
  customerManager: '',
  status: 1,
};

const noteService = new NoteService();

const BillingNoteModal = (props) => {
  const { open, setOpen, user, onAfterSend } = props;
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState({ state: false, message: 'Saving...' });
  const [initialValues, setInitialValues] = useState(formState);
  const [modalStyle] = useState(getModalStyle);
  const appStyle = AppStyle();
  const wizardStyle = WizardStyles();
  const { dialogUI } = useUI();

  const validationSchema = Yup.object({
    serviceDate: Yup.string().nullable().required('Field is required'),
    reason: Yup.string().nullable().required('Field is required'),
    description: Yup.string().min(3, 'Minimum 3 characters'),
  });

  noteService.init(user.accessToken);

  const onDelete = async (id) => {
    try {
      dialogUI.current.close();
      await noteService.delete(id);
      onAfterSend('OK', 'Your note has been deleted.');
      setError(false);
    } catch (e) {
      setError(e.message);
    }
  };

  const onEdit = (disabled) => {
    setInitialValues({ ...initialValues, disabled});
  };

  const onBlock = (state) => {
    setLoading({...loading, state});
  };

  const onSend = async (values) => {
    try {
      onBlock(true);
      const date = values.serviceDate instanceof Date ? values.serviceDate : new Date(values.serviceDate);
      values.serviceDate = format(date, 'yyyy-MM-dd');
      const id = values.id || 0;
      if (id !== 0) {
        values._method = 'PATCH';
      }
      let message = id != 0 ? 'Your note has been updated.' : 'Your note has been created.';
      const formData = serialize({...values});
      id !== 0 ? await noteService.update(formData, id) : await noteService.create(formData);
      onBlock(false);
      onAfterSend('OK', message);
      setError(false);
    } catch (e) {
      onBlock(false);
      setError(e.message);
    }
  };

   const adjustToNearestBusinessDay = (date) => {
     const day = date.getDay();
     if (day === 6) {
       return new Date(date.setDate(date.getDate() + 2));
     } else if (day === 0) {
       return new Date(date.setDate(date.getDate() + 1));
     }
     return date;
   };

  const getDataReset = () => {
    const m = moment();
    let serviceDate = m.toDate();
    serviceDate = adjustToNearestBusinessDay(serviceDate);
    const serviceHour = m.format('HH:mm');
    const temporary = false;
    return { serviceDate, serviceHour, temporary };
  };

  const updateForm = useCallback(() => {
    let noteTypeId = 7;
    const resetData = getDataReset();
    const customerAttributesId = props.mm.customer.options.id;
    let partialValues = { ...resetData, customerAttributesId, typeId: noteTypeId };
    if (!_isEmpty(props.entity)) {
      partialValues = { ...partialValues, ...props.entity };
    }

    //setInitialValues((initialValues) => ({ ...initialValues, ...partialValues }));
    setInitialValues({ ...formState, ...partialValues });
  }, [props]);

  useEffect(() => {
    if (_has(props, 'open')) {
      setOpen(props.open);
    }
    updateForm();
  }, [props, setOpen, updateForm]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
    >
      <div style={modalStyle} className={wizardStyle.paperModalFull}>
      <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => onSend(values)}
          enableReinitialize={true}
        >
          {(props) => {
            const { values, handleSubmit } = props;
            return (
              <Form autoComplete="off">
                <Grid container alignItems="center" spacing={0}>
                  <Grid container item xs={12}>
                    <Grid item xs={10}>
										<h2 className={clsx(appStyle.mrg0V)}>{'New Billing Note'}</h2>
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton
                        color='primary'
                        aria-label='close'
                        className={clsx(appStyle.pdg0, appStyle.btnRight)}
                        disabled={loading.state}
                        onClick={() => setOpen(false)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  {values.id > 0 && (
                    <div style={{ width: '100%' }}>
                      <Box display='flex' justifyContent='flex-end' alignItems='center'>
                        <Box>
                          <IconButton
                            color='primary'
                            disabled={!values.disabled}
                            onClick={() => {
                              const settings = {
                                confirm: true,
                                btn: {
                                  close: 'Cancel'
                                },
                                onConfirm: () => onDelete(values.id)
                              };
                              dialogUI.current.open('Note', 'Are you sure to delete this note?', settings);
                            }}
                          >
                            <DeleteOutlineIcon />
                          </IconButton>
                        </Box>
                        <Box>
                          <Button
                            variant='contained'
                            color='primary'
                            size='small'
                            startIcon={<EditIcon />}
                            disabled={!values.disabled}
                            className={appStyle.pdg20H}
                            width={1}
                            onClick={() => onEdit(!values.disabled)}
                          >
                            Edit
                          </Button>
                        </Box>
                      </Box>
                    </div>
                  )}
                  <BillingNoteTab isEdit={initialValues.id !== 0}/>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {loading.state && (
                        <Alert variant='outlined' icon={<HourglassEmptyIcon fontSize='inherit' />} severity='warning'>
                          {loading.message}
                        </Alert>
                      )}
                      {error && <Alert severity='warning'>{error}</Alert>}
                    </Grid>
                    <Grid item xs={6}>
                      <AppButton
                        onPress={handleClose}
                        label={'Cancel'}
                        type={'main'}
                        disabled={values.disabled || loading.state}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <AppButton
                        onPress={handleSubmit}
                        label={'Save'}
                        type={'main'}
                        color={'primary'}
                        disabled={values.disabled || loading.state}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    mm: state.mm,
  };
};
export default connect(mapStateToProps)(memo(BillingNoteModal));
