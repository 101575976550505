import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useUI } from "../../app/context/ui";
import { SpecialRequestService } from '../../services';
import AppHelper from '../../helpers/AppHelper';
import { connect } from 'react-redux';
import ModalNote from './components/modal-note';
import {  isArray as _isArray} from 'lodash';

const RequestMessage = (props) => {
  const { mm } = props;
  const { blockUI, snackbarUI } = useUI();

  const [notesAll, setNotesAll] = useState({
      ccDeclineSpecial: undefined,
      fifthMowSpecial: undefined,
      notesSpecial: undefined,
  });

  const specialRequestService = useMemo(() => new SpecialRequestService(), []);

  const getListNotes = useCallback(
    async () => {
      try {
        blockUI.current.open(true);
        specialRequestService.getAccessToken();
        const { data: specialRequest } = await specialRequestService.read(mm.id);
        setNotesAll(specialRequest);
        blockUI.current.open(false);
      } catch (e) {
        blockUI.current.open(false);
        AppHelper.checkError(e, snackbarUI);
      }
    },
    [blockUI, specialRequestService, mm.id, snackbarUI]
  );

	useEffect(() => {
    (async function init() {
      await getListNotes();
    })();
  }, [getListNotes]);

  return (
		<>
      {
        (notesAll.ccDeclineSpecial && _isArray(notesAll.ccDeclineSpecial))
          &&
            <ModalNote status={true} notes={notesAll.ccDeclineSpecial} nameModal='Decline Special Requests'/>
      }
      {
        (notesAll.fifthMowSpecial && _isArray(notesAll.fifthMowSpecial))
          &&
            <ModalNote status={true} notes={notesAll.fifthMowSpecial} nameModal='Fifth Mow Special Requests'/>
      }
      {/* {
        (notesAll.notesSpecial && _isArray(notesAll.notesSpecial))
          &&
            <ModalNote status={true} notes={notesAll.notesSpecial} nameModal='Notes Special Requests'/>
      } */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    mm: state.mm.customer.options,
  };
};

export default connect(mapStateToProps)(RequestMessage);
