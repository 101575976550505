import { 
  isUndefined as _isUndefined, 
  toNumber as _toNumber, 
  isEmpty as _isEmpty 
} from 'lodash';
import * as Yup from 'yup';

import { CustomerService } from '../services';
import customerForm from './customerForm';
import ValidationFields from './validationFields';

const {
  formField: {
    about,
    aboutOption,
    useServiceHousesScheduled,
    useServiceHousesOccupied,
    zipcode,
    zipcodeValid,
    city, // Step2
    property,
    state,
    serviceAddress,
    owner,
    csr,
    market,
    lotSize,
    taxRate,
    aboutOld,
    costService, // Step 3
    billingLotSize,
    reasonSize,
    reasonEdit,
    srvFrecuency,
    grassOvergrown,
    mowCut,
    calculatedTax,
    totalMowing,
    firstName, // Step 4
    lastName,
    email,
    contactNumber,
    isCellphone,
    serviceDate,
    isCommunityGate,
    communityAnswer,
    isBackyardGate,
    backyardAnswer,
    isFlowerBeds,
    isPetsBackyard,
    isFencePlastic,
    isAboveGroundPool,
    isTranpoline,
    cardNumber, // Step 6
    cardMonth,
    cardYear,
    nameOnCard,
    cardType,
    isBillingAddress,
    billingAddress,
    billingCity,
    billingState,
    billingZipCode,
    billingName,
    isMailingAddress,
    mailingName,
    mailingAddress,
    mailingCity,
    mailingState,
    mailingZipCode,
    isAddressBillingService,
    bankAccount,
    bankRouting,
    billingNameBank,
    weekDay,
    billingPoBox,
    billingPoBoxCity,
    billingPoBoxState,
    billingPoBoxZipcode,
    mailingPoBox,
    mailingPoBoxCity,
    mailingPoBoxState,
    mailingPoBoxZipcode,
  },
} = customerForm;

const visaRegEx = /^4[0-9].*$/;
const mastercardRegEx = /^[2,5][0-9]{15}(?:[0-9]{3})?$/;
const aExpressRegEx = /^3[0-9]{14}(?:[0-9]{3})?$/;
const discoverRegEx = /^6(?:011|5[0-9]{2})[0-9]{12}$/;
const phoneRegExp = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
const emailRegExp = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
const yesterday = new Date();
yesterday.setHours(0, 0, 0, 0);

export default [
  Yup.object().shape({
    [about.name]: ValidationFields.about(),
    [aboutOption.name]: ValidationFields.aboutOption(),
    [useServiceHousesScheduled.name]: Yup.bool().oneOf(
      [true],
      `${useServiceHousesScheduled.requiredErrorMsg}`
    ),
    [useServiceHousesOccupied.name]: Yup.bool().oneOf(
      [true],
      `${useServiceHousesOccupied.requiredErrorMsg}`
    ),
    [zipcode.name]: ValidationFields.zipCode(),
    [zipcodeValid.name]: Yup.string().nullable().required(`${zipcodeValid.requiredErrorMsg}`),
  }),
  Yup.object().shape({
    [city.name]: ValidationFields.city(),
    [property.name]: Yup.number().notRequired(),
    [state.name]: ValidationFields.state(),
    [serviceAddress.name]: Yup.string()
      .nullable()
      .required(`${serviceAddress.requiredErrorMsg}`)
      .test('serviceAddress', `${serviceAddress.invalidErrorMsg}`, (val) => val && true),
    [owner.name]: Yup.string().notRequired(),
    [csr.name]: ValidationFields.csr(),
    [market.name]: ValidationFields.market(),
    [lotSize.name]: Yup.number().required(`${lotSize.requiredErrorMsg}`),
    [taxRate.name]: Yup.number().required(`${taxRate.requiredErrorMsg}`),
    [aboutOld.name]: Yup.string().nullable().required(`${aboutOld.requiredErrorMsg}`),
  }),
  Yup.object().shape({
    [costService.name]: Yup.string()
      .required(`${costService.requiredErrorMsg}`)
      .test('costService', `${costService.invalidErrorMsg}`, (val) => val && val > 0),
    [billingLotSize.name]: Yup.string().nullable().required(`${billingLotSize.requiredErrorMsg}`),
    [reasonSize.name]: Yup.string().when([reasonEdit.name], (reasonEdit) => {
      if (reasonEdit) {
        return Yup.string().required(`${reasonSize.requiredErrorMsg}`);
      }
    }),
    [srvFrecuency.name]: ValidationFields.srvFrecuency(),
    [grassOvergrown.name]: Yup.string().nullable().required(`${grassOvergrown.requiredErrorMsg}`),
    [mowCut.name]: ValidationFields.mowCut(),
    [calculatedTax.name]: Yup.string().nullable().required(`${calculatedTax.requiredErrorMsg}`),
    [totalMowing.name]: Yup.string()
      .required(`${totalMowing.requiredErrorMsg}`)
      .test('totalMowing', `${totalMowing.invalidErrorMsg}`, (val) => val && val > 0),
  }),
  Yup.object().shape({
    [firstName.name]: Yup.string()
      .max(50, 'Max. 50 characters')
      .required(`${firstName.requiredErrorMsg}`),
    [lastName.name]: Yup.string()
      .max(50, 'Max. 50 characters')
      .required(`${lastName.requiredErrorMsg}`),
    [email.name]: Yup.string()
      .nullable()
      .email('Invalid email'),
/*
      .test('email', `${email.invalidErrorMsg}`, function (email) {
        return checkAvailabilityEmail(email);
      }),
*/
    [contactNumber.name]: Yup.string()
      .required(`${contactNumber.requiredErrorMsg}`)
      .matches(phoneRegExp, contactNumber.invalidErrorMsg),
    [isCellphone.name]: Yup.string().nullable().required(`${isCellphone.requiredErrorMsg}`),
    [serviceDate.name]: Yup.date()
      .label('Service start date')
      .nullable()
      .typeError(`${serviceDate.invalidErrorMsg}`)
      .min(yesterday, 'Date cannot be in the past')
      .required(`${serviceDate.requiredErrorMsg}`),
    [isCommunityGate.name]: Yup.string().nullable().required(`${isCommunityGate.requiredErrorMsg}`),
    [communityAnswer.name]: Yup.string().when([isCommunityGate.name], {
      is: 'Yes',
      then: Yup.string().required(`${communityAnswer.requiredErrorMsg}`),
      otherwise: Yup.string().nullable(),
    }),
    [isBackyardGate.name]: Yup.string().nullable().required(`${isBackyardGate.requiredErrorMsg}`),
    [backyardAnswer.name]: Yup.string().when([isBackyardGate.name], {
      is: 'Yes',
      then: Yup.string().required(`${backyardAnswer.requiredErrorMsg}`),
      otherwise: Yup.string().nullable(),
    }),
    [weekDay.name]: Yup.string().nullable().required(`${weekDay.requiredErrorMsg}`),
    [isFlowerBeds.name]: Yup.string().nullable().required(`${isFlowerBeds.requiredErrorMsg}`),
    [isPetsBackyard.name]: Yup.string().nullable().required(`${isPetsBackyard.requiredErrorMsg}`),
    [isFencePlastic.name]: Yup.string().nullable().required(`${isFencePlastic.requiredErrorMsg}`),
    [isAboveGroundPool.name]: Yup.string()
      .nullable()
      .required(`${isAboveGroundPool.requiredErrorMsg}`),
    [isTranpoline.name]: Yup.string().nullable().required(`${isTranpoline.requiredErrorMsg}`),
  }),
  Yup.object().shape({}),
  Yup.object().shape({
    [cardNumber.name]: Yup.number().when(
      [cardType.name, bankAccount.name],
      (cardType, bankAccount) => {
        if (_toNumber(cardType) === 4) {
          return Yup.string()
            .required(`${cardNumber.requiredErrorMsg}`)
            .matches(visaRegEx, cardNumber.invalidErrorMsg)
            .max(16, 'Max. 16 characters')
            .min(13, 'Min. 13 characters');
        } else if (_toNumber(cardType) === 5) {
          return Yup.string()
            .required(`${cardNumber.requiredErrorMsg}`)
            .matches(mastercardRegEx, cardNumber.invalidErrorMsg);
        } else if (_toNumber(cardType) === 3) {
          return Yup.string()
            .required(`${cardNumber.requiredErrorMsg}`)
            .matches(aExpressRegEx, cardNumber.invalidErrorMsg);
        } else {
          if (!bankAccount) {
            return Yup.string()
              .required(`${cardNumber.requiredErrorMsg}`)
              .matches(discoverRegEx, cardNumber.invalidErrorMsg);
          }
        }
      }
    ),
    [bankAccount.name]: Yup.string().when([cardType.name], (cardType) => {
      if (!cardType) {
        return Yup.string().nullable().required(`${bankAccount.requiredErrorMsg}`);
      }
    }),
    [bankRouting.name]: Yup.string().when([bankAccount.name], (bankAccount) => {
      if (bankAccount) {
        return Yup.string().nullable().required(`${bankRouting.requiredErrorMsg}`);
      }
    }),
    [billingNameBank.name]: Yup.string().when([bankAccount.name], (bankAccount) => {
      if (bankAccount) {
        return Yup.string().nullable().required(`${billingNameBank.requiredErrorMsg}`);
      }
    }),
    [cardYear.name]: Yup.string().when([cardNumber.name], (cardNumber) => {
      if (cardNumber) {
        return Yup.string().nullable().required(`${cardYear.requiredErrorMsg}`);
      }
    }),
    [cardMonth.name]: Yup.string().when(
      [cardYear.name, cardNumber.name],
      (cardYear, cardNumber) => {
        if (cardNumber) {
          const startDate = new Date();
          if (startDate.getFullYear() === _toNumber(cardYear)) {
            return Yup.string()
              .nullable()
              .required(`${cardMonth.requiredErrorMsg}`)
              .test('cardYear', cardMonth.invalidErrorMsg, (val) => {
                if (val) {
                  const startDate = new Date();
                  const endDate = new Date(startDate.getFullYear(), val, startDate.getDay());
                  return endDate >= startDate;
                }
                return false;
              });
          } else {
            return Yup.string().nullable().required(`${cardMonth.requiredErrorMsg}`);
          }
        }
      }
    ),
    [nameOnCard.name]: Yup.string().when([cardNumber.name], (cardNumber) => {
      if (cardNumber) {
        return Yup.string().nullable().required(`${nameOnCard.requiredErrorMsg}`);
      }
    }),
    [isBillingAddress.name]: Yup.string()
      .nullable()
      .when([billingPoBox.name], {
        is: (val) => !val || val?.trim() === '',
        then: Yup.string().nullable().required(`${isBillingAddress.requiredErrorMsg}`),
        otherwise: Yup.string().nullable(),
      }),
    [billingAddress.name]: Yup.string().when([isBillingAddress.name], {
      is: 'No',
      then: Yup.string().nullable().required(`${billingAddress.requiredErrorMsg}`),
      otherwise: Yup.string().nullable(),
    }),
    [billingPoBoxCity.name]: Yup.string().when([billingPoBox.name], {
      is: (val) => val && val !== '',
      then: Yup.string().required(`${billingPoBoxCity.requiredErrorMsg}`),
    }),
    [billingPoBoxState.name]: Yup.string().when([billingPoBox.name], {
      is: (val) => val && val !== '',
      then: Yup.string().required(`${billingPoBoxState.requiredErrorMsg}`),
    }),
    [billingPoBoxZipcode.name]: Yup.string().when([billingPoBox.name], {
      is: (val) => val && val !== '',
      then: Yup.string().required(`${billingPoBoxZipcode.requiredErrorMsg}`),
    }),
    [mailingPoBoxCity.name]: Yup.string().when([mailingPoBox.name], {
      is: (val) => val && val !== '',
      then: Yup.string().required(`${mailingPoBoxCity.requiredErrorMsg}`),
    }),
    [mailingPoBoxState.name]: Yup.string().when([mailingPoBox.name], {
      is: (val) => val && val !== '',
      then: Yup.string().required(`${mailingPoBoxState.requiredErrorMsg}`),
    }),
    [mailingPoBoxZipcode.name]: Yup.string().when([mailingPoBox.name], {
      is: (val) => val && val !== '',
      then: Yup.string().required(`${mailingPoBoxZipcode.requiredErrorMsg}`),
    }),
    [billingCity.name]: Yup.string().when([isBillingAddress.name], {
      is: 'No',
      then: Yup.string().nullable().required(`${billingCity.requiredErrorMsg}`),
      otherwise: Yup.string().nullable(),
    }),
    [billingState.name]: Yup.string().when([isBillingAddress.name], {
      is: 'No',
      then: Yup.string().nullable().required(`${billingState.requiredErrorMsg}`),
      otherwise: Yup.string().nullable(),
    }),
    [billingZipCode.name]: Yup.string().when([isBillingAddress.name], {
      is: 'No',
      then: Yup.string()
        .required(`${billingZipCode.requiredErrorMsg}`)
        .test('len', `${billingZipCode.invalidErrorMsg}`, (val) => val && val.length === 5),
      otherwise: Yup.string().nullable(),
    }),
    [billingName.name]: Yup.string().when([isBillingAddress.name], {
      is: 'No',
      then: Yup.string().nullable().required(`${billingName.requiredErrorMsg}`),
      otherwise: Yup.string().nullable(),
    }),
    [isMailingAddress.name]: Yup.string()
      .nullable()
      .required(`${isMailingAddress.requiredErrorMsg}`),
    [isMailingAddress.name]: Yup.string()
      .nullable()
      .when([mailingPoBox.name], {
        is: (val) => !val || (typeof val === 'string' && val.trim() === ''),
        then: Yup.string().nullable().required(`${isMailingAddress.requiredErrorMsg}`),
        otherwise: Yup.string().nullable(),
      }),
    [mailingName.name]: Yup.string().when([isMailingAddress.name], {
      is: 'No',
      then: Yup.string().nullable().required(`${mailingName.requiredErrorMsg}`),
      otherwise: Yup.string().nullable(),
    }),
    [mailingAddress.name]: Yup.string().when([isMailingAddress.name], {
      is: 'No',
      then: Yup.string().nullable().required(`${mailingAddress.requiredErrorMsg}`),
      otherwise: Yup.string().nullable(),
    }),
    [mailingCity.name]: Yup.string().when([isMailingAddress.name], {
      is: 'No',
      then: Yup.string().nullable().required(`${mailingCity.requiredErrorMsg}`),
      otherwise: Yup.string().nullable(),
    }),
    [mailingState.name]: Yup.string().when([isMailingAddress.name], {
      is: 'No',
      then: Yup.string().nullable().required(`${mailingState.requiredErrorMsg}`),
      otherwise: Yup.string().nullable(),
    }),
    [mailingZipCode.name]: Yup.string().when([isMailingAddress.name], {
      is: 'No',
      then: Yup.string()
        .required(`${mailingZipCode.requiredErrorMsg}`)
        .test('len', `${billingZipCode.invalidErrorMsg}`, (val) => val && val.length === 5),
      otherwise: Yup.string().nullable(),
    }),
    [isAddressBillingService.name]: Yup.string().when([isMailingAddress.name], {
      is: 'Yes',
      then: Yup.string().nullable().required(`${isAddressBillingService.requiredErrorMsg}`),
      otherwise: Yup.string().nullable(),
    }),
  }),
];

const checkAvailabilityEmail = async (email) => {
  if (_isEmpty(email)) {
    return true;
  }
  if (_isUndefined(email) || !emailRegExp.test(email)) {
    return false;
  }
  const customerService = new CustomerService();
  customerService.getAccessToken();
  try {
    await customerService.verifyEmail(`email=${email}`);
    return true;
  } catch (e) {
    return false;
  }
};
