import { makeStyles } from '@material-ui/core/styles';

export const ModalStyle = makeStyles(() => ({
  ctnInner: {
    fontFamily: [
      'Roboto',
      'Arial'
    ].join(','),
    fontWeight: "bold",
    color: "#2F3A4A",
    paddingBottom: '12px',
    '& .title':{
      fontSize: '20px',
      textAlign: 'center',
      marginBottom: '46px',
      fontWeight: 'bold',
    },
  },
  grdItem: {
    marginTop: 5,
  },
  inputCustom: {
    "& .MuiOutlinedInput-root": {
      height: "32px",
    },
    "& .MuiInputBase-input": {
      fontSize: 16,
      fontFamily: [
        'Roboto',
        'Arial'
      ].join(','),
    }
  },
  button:{
    width: '160px',
    marginRight: '30px'
  }
}));
