import AppService from './AppService';

class AuthWebService extends AppService {
  login(formData) {
    return this.http.post('/web/auth/login', formData);
  }

  passwordReset(formData) {
    return this.http.post('/web/auth/recover-password', formData);
  }

  passwordChange(formData) {
    return this.http.post('/web/auth/reset-password', formData);
  }
}

export default AuthWebService;
