import { makeStyles } from '@material-ui/core/styles';

export const HeaderStyle = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    justifyContent: 'center',
    alignItems: 'center',
  },
  ctnCalendar: {
    paddingLeft: '24px'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    '& .MuiSelect-root': {
      fontSize: '14px'
    }
  },
  title: {
    fontSize: '15px',
    paddingTop: '17px',
    fontWeight: 'bold',
    textAlign: 'center'
  },
  subtitle: {
    fontSize: '15px',
    paddingTop: '17px',
    textAlign: 'center'
  },
  wrapperArrows: {
    textAlign: 'center'
  },
  messageError:{
    fontSize: '14px',
    textAlign: 'center',
    fontWeight: '200',
  },
  checkboxField: {
    color: theme.palette.secondary.main,
    '&$checked': {
      color: theme.palette.secondary.main,
    },
    '& .MuiFormControlLabel-label': {
      color: theme.palette.primary.main,
    }
  },
  bbNotesContainer: {
    border: 'solid 1px black',
    padding: '16px',
    borderRadius: '100px',
    margin: '8px 20px',
    fontSize: '14px',
  },
}));
