import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Divider, Grid, IconButton, InputAdornment } from '@material-ui/core';
import BorderColorIcon from '@material-ui/icons/BorderColor';

import { Formik, Form, useFormikContext } from 'formik';
import {
  isEmpty as _isEmpty,
  isUndefined as _isUndefined,
  isArray as _isArray, assign as _assign
} from 'lodash';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';

import { InputField, SelectField } from '../../../forms';
import CheckboxArrayField from '../../../forms/CheckboxArrayField';
import { WizardStyles } from '../../../assets/css';
import store from '../../../redux/store';
import { UserService } from '../../../services';
import { FormatWeekYear, GenerateNameDownloadWithDate, Utils } from '../../../helpers';
import { PickServiceData } from '../../../services/pickService';
import { PickItemServiceData } from '../../../services/pickItemService';
import CustomerService from '../../../services/CustomerService';
import AutoSave from '../../../forms/AutoSave';
import ValidationFields from '../../../models/validationFields';
import { useUI } from '../../../app/context/ui';
import AppHelper from '../../../helpers/AppHelper';
import { updateProperty } from '../../../app/store/mm/customerSlice';
import PrintIcon from '@material-ui/icons/Print';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

const Step8 = (props) => {
  const {blockUI, snackbarUI, dialogUI} = useUI();
  const [isFormFocused, setIsFormFocused] = useState(false);
  const [children, setChildren] = useState([]);
  const [aboutsDb, setAboutsDb] = useState([]);
  const [csrs, setCsrs] = useState([]);
  const { mm } = props;

  const {
    formField: {
      optReviews,
      sendSchedule,
      futureCancel,
      about,
      aboutOption,
      referralCode,
      csr,
      phoneMarket,
    }
  } = props;

  const wizardStyle = WizardStyles();
  const state = store.getState();
  const history = useHistory();
  const accessToken = state.user.accessToken;
  if (!accessToken) {
    history.push('/login');
  }
  const dispatch = useDispatch();
  const {values: formValues} = useFormikContext();
  const {id} = formValues;
  const initialValues = {
    optReviews: _isEmpty(formValues.optReviews) ? [] :
      !_isArray(formValues.optReviews) ? JSON.parse(formValues.optReviews) : _assign([], formValues.optReviews),
    sendSchedule: formValues.sendSchedule ? (formValues.sendSchedule.length === 4) ? '' : formValues.sendSchedule : '',
    futureCancel: formValues.futureCancel ? (formValues.futureCancel.length === 4) ? '' : formValues.futureCancel : '',
    about: formValues.about,
    aboutOption: formValues.aboutOption,
    csr: formValues.csr,
    phoneMarket: formValues.phoneMarket
  };

  const validationSchema = Yup.object({
    about: ValidationFields.about(),
    aboutOption: ValidationFields.aboutOption(),
    sendSchedule: ValidationFields.sendSchedule(),
    futureCancel: ValidationFields.futureCancel(),
  });

  const pickServiceData = useMemo(() => new PickServiceData(), []);
  const pickItemServiceData = useMemo(() => new PickItemServiceData(), []);
  const userService = useMemo(() => new UserService(), []);
  const customerService = useMemo(() => new CustomerService(), []);


  const classes = useStyles();

  const reviews = [
    {'label': 'Restart Invites', 'value': '1'},
    {'label': 'Send Reviews', 'value': '2'},
    {'label': 'Review sent', 'value': '3'}
  ];

  const handleChangeAbout = (event) => {
    (async function init() {
      await getPickItemGroup(event.target.value);
    })();
  };

  const getPickItem = useCallback(async (values) => {
    try {
      const r = await pickServiceData.getAbout(values);
      setAboutsDb(r);
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  }, [pickServiceData, snackbarUI]);

  const getPickItemGroup = useCallback(async (values) => {
    try {
      if (!_isUndefined(values)) {
        const r = await pickItemServiceData.getAboutOption(values);
        setChildren(r);
      }
    } catch (e) {
      setChildren([]);
      AppHelper.checkError(e, snackbarUI);
    }
  }, [pickItemServiceData, snackbarUI]);

  const getListUser = async () => {
    try {
      userService.init(accessToken);
      const r1 = await userService
        .list('?filter[role]=customer-service&filter[customer_attributes_id]=' + id + '&filter[status]=1');
      const arrCsr = Utils.updateArrayObjectItem(r1.data,
        [{value: null, label: '-- Select CSR --'}], 'id', 'fullName');
      setCsrs(arrCsr)
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  }

  const handleSubmit = useCallback(async values => {
    try {
      blockUI.current.open(true);
      customerService.getAccessToken();
      await customerService.update(values, id);
      blockUI.current.open(false);
      dispatch(updateProperty(values));

    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  }, [blockUI, customerService, dispatch, id, snackbarUI]);

  const handlePrintScheduleCustomer = async () => {
    try {
      blockUI.current.open(true);
      customerService.getAccessToken();
      let nameDownload = GenerateNameDownloadWithDate(`schedule_customerId_${mm.customerId}`,'pdf');
      const r1 = await customerService.downloadSchedule(mm.id);
      const url = window.URL.createObjectURL(new Blob([r1.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', nameDownload);
      document.body.appendChild(link);
      link.click();
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  }

  const handleSendScheduleCustomer = async () => {
    try {
      blockUI.current.open(true);
      customerService.getAccessToken();
      await customerService.sendSchedule(mm.id);
      blockUI.current.open(false);
      dialogUI.current.open('', 'The mail was sent correctly');
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  }

  useEffect(() => {
    (async function init() {
      await getPickItem(1);
    })();
  }, []);

  useEffect(() => {
    (async function init() {
      await getPickItemGroup(formValues.about);
    })();
  }, []);

  useEffect(() => {
    (async function init() {
      await getListUser();
    })();
  }, []);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {() => (
          <Form autoComplete="off" onFocus={() => setIsFormFocused(true)} onBlur={() => setIsFormFocused(false)}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <CheckboxArrayField row name={optReviews.name} options={reviews}/>
                </Grid>
                <Grid item xs={6} className={classes.wrapperPrintSchedule}>

                  <Button
                    variant='contained'
                    color='primary'
                    size='small'
                    className={classes.btnSend}
                    startIcon={<MailOutlineIcon />}
                    onClick={handleSendScheduleCustomer}
                  >
                    Send Schedule
                  </Button>

                  <Button
                    variant='contained'
                    color='primary'
                    size='small'
                    startIcon={<PrintIcon />}
                    onClick={handlePrintScheduleCustomer}
                  >
                    Print Schedule
                  </Button>

                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={1}>
                  <span className={wizardStyle.label}>Send Schedule</span>
                </Grid>
                <Grid item xs={3}>
                  <InputField
                    name={sendSchedule.name}
                    InputProps={{
                      inputComponent: FormatWeekYear,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Divider className={classes.divider} orientation="vertical"/>
                          <IconButton color="primary" aria-label="directions">
                            <BorderColorIcon/>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }} fullWidth/>

                </Grid>
                <Grid item xs={1}>
                  <span className={wizardStyle.label}>Future cancel #</span>
                </Grid>
                <Grid item xs={3}>
                  <InputField
                    name={futureCancel.name}
                    InputProps={{
                      inputComponent: FormatWeekYear,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Divider className={classes.divider} orientation="vertical"/>
                          <IconButton color="primary" className={classes.iconButton} aria-label="directions">
                            <BorderColorIcon/>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }} fullWidth/>
                </Grid>
                <Grid item xs={1}>
                  <span className={wizardStyle.label}>Source</span>
                </Grid>
                <Grid item xs={3}>
                  <SelectField
                    name={about.name}
                    data={aboutsDb}
                    onClick={handleChangeAbout}
                    autoFocus
                    fullWidth
                  />
                </Grid>
                <Grid item xs={1}>
                  <span className={wizardStyle.label}>Source 2</span>
                </Grid>
                <Grid item xs={3}>
                  <SelectField
                    name={aboutOption.name}
                    data={children}
                    autoFocus
                    fullWidth
                  />
                </Grid>
                <Grid item xs={1}>
                  <span className={wizardStyle.label}>Referral Code</span>
                </Grid>
                <Grid item xs={3}>
                  <InputField name={referralCode.name} value={mm.referralCode} fullWidth disabled/>
                </Grid>
                <Grid item xs={1}>
                  <span className={wizardStyle.label}>Sign by</span>
                </Grid>
                <Grid item xs={3}>
                  <SelectField
                    name={csr.name}
                    data={csrs}
                    autoFocus
                    fullWidth
                  />
                </Grid>
                <Grid item xs={1}>
                  <span className={wizardStyle.label}>Local Number</span>
                </Grid>
                <Grid item xs={3}>
                  <InputField name={phoneMarket.name} fullWidth disabled/>
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.autoSave}>
                <AutoSave debounceMs={500} id={id} isFocused={isFormFocused} />
              </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    mm: state.mm.customer.options
  };
};

export default connect(mapStateToProps)(Step8);

const useStyles = makeStyles(() => ({
  iconButton: {
    padding: 5,
  },
  divider: {
    height: 30,
    margin: 4,
  },
  wrapperPrintSchedule:{
    textAlign: 'right'
  },
  autoSave:{
    textAlign: 'center',
    paddingTop: '10px'
  },
  btnSend:{
    marginRight: '24px'
  }
}));
