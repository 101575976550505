import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
import { useUI } from "../../app/context/ui";

import * as Yup from "yup";
import store from "../../redux/store";

import {
  Container,
  Typography,
  Grid,
  Button,
  TextField,
  Box,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from "../../components/shared/MaterialUI";
import { CreateUserStyles } from "../../assets/css";

import _ from "lodash";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { BbCrewService, MarketService } from "../../services";

const BbCreate = () => {
  const [hasError, setHasError] = useState({});
  const [requestFailed, setRequestFailed] = useState(false);
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    market: "",
  });
  const { blockUI } = useUI();
  const history = useHistory();
  const [dataMarket, setDataMarket] = useState([]);

  const location = useLocation();
  let id = 0;
  if (!_.isUndefined(location.state)) {
    id = _.isUndefined(location.state.id) ? 0 : location.state.id;
  }

  const state = store.getState();
  const accessToken = state.user.accessToken;

  if (!accessToken) {
    history.push("/login");
  }
  const crewService = new BbCrewService();
  const marketService = new MarketService();

  const userStyle = CreateUserStyles();

  useEffect(() => {
    crewService.init(accessToken);
    marketService.init(accessToken);
    getMarket();
    if (id > 0) {
      getReadUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  const getMarket = async () => {
    try {
      blockUI.current.open(true);
      setRequestFailed(false);
      const r2 = await marketService.list();
      setDataMarket(r2.data);
      blockUI.current.open(false);
    } catch (e) {
      setRequestFailed(true);
      blockUI.current.open(false);
    }
  }

  useEffect(() => {
    if (!setRequestFailed) {
      setHasError("");
    }
  }, [requestFailed]);

  const validationSchema = Yup.object({
    firstName: Yup.string("Enter your first name")
      .max(50, "Max. 50 characters")
      .required("First name is required"),
    lastName: Yup.string("Enter your last name")
      .max(50, "Max. 50 characters")
      .required("Last name is required"),
    market: Yup.number()
      .required("Market is required")
      .nullable(),
    ...(id === 0 && {
      email: Yup.string("Enter your email address")
        .email("Invalid email")
        .required("Email is required"),
    }),
  });

  const onSubmit = async (values) => {
    try {
      blockUI.current.open(true);
      setRequestFailed(false);
      crewService.init(accessToken);
      let r1;
      if (id > 0) {
        _.unset(values, "email");
        r1 = await crewService.update({...values}, id);
      } else {
        r1 = await crewService.create({...values});
      }
      blockUI.current.open(false);
      history.push("/bb-crew-members", { ...r1.data });
    } catch (e) {
      blockUI.current.open(false);
      setRequestFailed(true);
      if (!e.response.data.errors) {
        setHasError({ message: e.response.data.message });
      }
      if (!_.isUndefined(e.response.data.errors.email)) {
        setHasError({ message: e.response.data.errors.email });
      }
    }
  };

  const getReadUser = async () => {
    try {
      blockUI.current.open(true);
      setRequestFailed(false);
      const r1 = await crewService.read(id);
      setInitialValues({
        firstName: r1.data.firstName,
        lastName: r1.data.lastName,
        email: r1.data.email,
        market: r1.data.serviceMarket.id
      });
      blockUI.current.open(false);
    } catch (e) {
      setRequestFailed(true);
      blockUI.current.open(false);
    }
  };

  return (
    <Container component="main" maxWidth="sm" className={userStyle.ctnInner}>
      <Typography variant="h4">
        {id > 0 ? "Edit" : "Create"} Crew
      </Typography>
      <Box pb={2} />
      {requestFailed && (
        <p className={userStyle.formError}>
          {hasError.message}
        </p>
      )}
      <Box pb={2} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {(props) => {
          const { values, touched, errors, handleChange } = props;
          return (
            <Form autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={3} className={userStyle.grdItem}>
                  <label>First Name:</label>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    variant="outlined"
                    autoFocus
                    fullWidth
                    id="firstName"
                    name="firstName"
                    value={values.firstName}
                    onChange={handleChange}
                    error={touched.firstName && Boolean(errors.firstName)}
                    helperText={
                      errors.firstName && touched.firstName
                        ? errors.firstName
                        : ""
                    }
                    className={userStyle.inputCustom}
                  />
                </Grid>
                <Grid item xs={3} className={userStyle.grdItem}>
                  <label>Last Name:</label>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="lastName"
                    name="lastName"
                    value={values.lastName}
                    onChange={handleChange}
                    error={touched.lastName && Boolean(errors.lastName)}
                    helperText={
                      errors.lastName && touched.lastName ? errors.lastName : ""
                    }
                    className={userStyle.inputCustom}
                    autoComplete="nope"
                  />
                </Grid>
                <Grid item xs={3} className={userStyle.grdItem}>
                  <label>Email:</label>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="email"
                    name="email"
                    type="email"
                    value={values.email}
                    onChange={handleChange}
                    error={touched.email && Boolean(errors.email)}
                    helperText={
                      errors.email && touched.email ? errors.email : ""
                    }
                    disabled={id > 0}
                    className={userStyle.inputCustom}
                    autoComplete="nope"
                  />
                </Grid>
                <Grid item xs={3} className={userStyle.grdItem}>
                  <label>Market:</label>
                </Grid>
                <Grid item xs={9}>
                  <FormControl variant="outlined" fullWidth className={userStyle.inputCustom}>
                    <Select
                      displayEmpty
                      id="market"
                      name="market"
                      value={values.market}
                      onChange={handleChange}
                      error={touched.market && Boolean(errors.market)}
                      helperText={
                        errors.market && touched.market ? errors.market : ""
                      }
                    >
                      <MenuItem value="" disabled>Select your market</MenuItem>
                      {
                        dataMarket.map((e)=>(
                            <MenuItem key={`market${e.id}`} value={e.id}>{e.name}</MenuItem>
                        ))
                      }
                    </Select>
                    <FormHelperText className={userStyle.formError}>{errors.market}</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Box pb={8} />
              <Grid container justifyContent="center">
                <Button
                  variant="contained"
                  size="large"
                  className={userStyle.button}
                  onClick={() => {
                    history.push("bb-crew-members");
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  className={userStyle.button}
                >
                  Save
                </Button>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(BbCreate);
