import React, { createContext, useReducer } from 'react';

import { includes as _includes } from 'lodash';
import { useLocation } from 'react-router-dom';

import Quote from './quote/Quote';
import SignUpCustomer from './signUpCustomer/SignUpCustomer';
import { initialState, SignUpCustomerReducer } from './state';
import Success from './successScreen/Success';

const SignUpProcess = () => {
  const [state, dispatch] = useReducer(SignUpCustomerReducer, initialState);
  const params = useLocation();
  const { pathname } = params;
  const isQuote = _includes(pathname, 'quote');
  const isSuccess = _includes(pathname, 'success');

  const props = {
    state,
    dispatch,
  };

  return (
    <SignUpCustomerContext.Provider value={props}>
      {isQuote ? <Quote /> : !isSuccess ? <SignUpCustomer /> : <Success />}
    </SignUpCustomerContext.Provider>
  );
};

export const SignUpCustomerContext = createContext({});
export default SignUpProcess;
