import AppService from './AppService';

class ReportsService extends AppService {
  constructor() {
    super();
    this.path = this.getPath('reports');
  }

  reports({ page = 1, pageSize = 50, userId }) {
    let params = 'render=paginate&sort=-id';
    params += `&limit=${pageSize}`;
    params += `&filter[user_id]=${userId}`;
    params += `&page=${page}`;
    return this.http.get(`${this.path}?${params}`);
  }

  download(fileName) {
    return this.http.get(`${this.path}/resource?filename=${fileName}`
    );
  }

  sendEmail(filename, email) {
    return this.http.post(`${this.path}/send-email`, { filename, email });
  }

}

export default ReportsService;
