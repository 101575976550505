import AppService from './AppService';

class MelissaService extends AppService {
  constructor() {
    super();
    this.path = this.getPath('melissa');
  }

  async getDataMowMellisa(search) {
    return this.http.get(`${this.path}?address=${search.address}&zip=${search.zip}`);
  }
}

export default MelissaService;
