import AppService from './AppService';
import { serialize } from 'object-to-formdata';

class CrewService extends AppService {

  constructor() {
    super();
    this.path = this.getPath('crew');
  }
  
  getCrewNumbers() {
    return this.http.get(`${this.path}?render=paginate&sort=-id`);
  }

  list(params = '') {
    const queryParams = params ? params : '';
    return this.http.get(`${this.path}${queryParams}`);
  }

  create(data) {
    return this.http.post(`${this.path}`, data);
  }

  uploadCsv(file, title) {
    const formData = serialize({
      file,
      name: title,
    });
    return this.http.post(`${this.path}/upload-payment`, formData);
  }

  update(data, id) {
    return this.http.patch(`${this.path}/${id}`, data);
  }

  read(id) {
    return this.http.get(`${this.path}/${id}`);
  }

}

export default CrewService;
