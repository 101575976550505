import React, { useContext, useEffect } from 'react';

import { Form } from 'formik';
import { includes as _includes } from 'lodash';

import {
  Box, Button, Checkbox, FormControl,
  FormControlLabel, FormGroup, Select, Tab,
  Tabs, TextField, Typography,
} from '../../../../../components/shared/MaterialUI';
import { SignUpCustomerContext } from '../../SignUpProcess';
import Labels from '../atoms/Labels';
import { SignUpStyles } from '../styles/signUp-style';
import CardInfo from './components/CardInfo';
import ECheckInfo from './components/ECheckInfo';

const BillingInfo = ({ value, setValue }) => {
  const { state, dispatch } = useContext(SignUpCustomerContext);
  const {
    isBillingAddress,
    isMailingAddress,
    serviceAddress,
    billingAddress,
    mailingAddress,
    zipcode,
    billingZipCode,
    mailingZipCode,
    cityName,
    billingCityName,
    mailingCityName,
  } = state;
  const classes = SignUpStyles();

  let billingIsSameAddress = _includes(isBillingAddress, 'Yes');
  let mailingIsSameAddress = _includes(isMailingAddress, 'Yes');

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const states = [
    'AK',
    'AL',
    'AR',
    'AZ',
    'CA',
    'CO',
    'CT',
    'DC',
    'DE',
    'FL',
    'GA',
    'HI',
    'IA',
    'ID',
    'IL',
    'IN',
    'KS',
    'KY',
    'LA',
    'MA',
    'MD',
    'ME',
    'MI',
    'MN',
    'MO',
    'MS',
    'MT',
    'NC',
    'ND',
    'NE',
    'NH',
    'NJ',
    'NM',
    'NV',
    'NY',
    'OH',
    'OK',
    'OR',
    'PA',
    'PR',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VA',
    'VT',
    'WA',
    'WI',
    'WV',
    'WY',
  ];

  const handleCheckboxes = (e) => {
    const { name, value } = e.target;
    if (
      (name === 'isBillingAddress' && isBillingAddress === 'Yes') ||
      (name === 'isMailingAddress' && isMailingAddress === 'Yes')
    ) {
      dispatch({
        type: 'setTextFields',
        payload: { [name]: '' },
      });
    } else {
      dispatch({
        type: 'setTextFields',
        payload: { [name]: value },
      });
    }
  };

  const handleInputs = (e) => {
    const { name, value } = e.target;
    dispatch({
      type: 'setTextFields',
      payload: { [name]: value },
    });
  };

  useEffect(() => {
    if (billingIsSameAddress) {
      dispatch({
        type: 'setTextFields',
        payload: {
          billingAddress: serviceAddress,
          billingZipCode: zipcode,
          billingCityName: cityName,
          billingState: state.state,
        },
      });
    }
    if (mailingIsSameAddress) {
      dispatch({
        type: 'setTextFields',
        payload: {
          mailingAddress: serviceAddress,
          mailingCityName: cityName,
          mailingZipCode: zipcode,
          mailingState: state.state,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingIsSameAddress, mailingIsSameAddress]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Labels text="let's get you on the schedule!" classNames={`${classes.titles} uppercase`} />
      <Box style={{ display: 'flex', alignItems: 'center', marginTop: '32px' }}>
        <img
          src='https://www.mowmanagers.com/wp-content/uploads/2020/08/secure-trust-logo.png'
          alt=''
          width='165px'
        />
        <Typography className={classes.trustwaves}>
          This site is protected by Trustwave&apos;s Trusted Commerce Program
        </Typography>
      </Box>
      <Form className={classes.formCtn}>
        <Box>
          <Labels text='Billing Address' classNames={`${classes.titles} billingTitle`} />
          <FormControl component='fieldset'>
            <FormGroup>
              <FormControlLabel
                onChange={(e) => handleCheckboxes(e)}
                className={classes.checkboxLabel}
                control={
                  <Checkbox name='isBillingAddress' value='Yes' disableRipple disableFocusRipple />
                }
                label='Same as Service Address'
              />
            </FormGroup>
          </FormControl>

          <Labels text='Address' classNames='textLabels billingInputs' required={true} />
          <TextField
            onChange={(e) => {
              handleInputs(e);
            }}
            variant='filled'
            value={billingIsSameAddress ? serviceAddress : billingAddress}
            name='billingAddress'
            required
          />

          <Labels text='City' classNames='textLabels billingInputs' required={true} />
          <TextField
            onChange={(e) => {
              handleInputs(e);
            }}
            variant='filled'
            value={billingIsSameAddress ? cityName : billingCityName}
            name='billingCityName'
            required
          />

          <Labels text='Zip' classNames='textLabels billingInputs' required={true} />
          <TextField
            onChange={(e) => {
              handleInputs(e);
            }}
            variant='filled'
            value={billingIsSameAddress ? zipcode : billingZipCode}
            name='billingZipCode'
            required
          />

          <Labels text='State' classNames='textLabels billingInputs' required={true} />
          <Select
            native
            className={classes.select}
            onChange={(e) => handleInputs(e)}
            inputProps={{ name: 'billingState' }}
            value={state.state ? state.state : ''}
            required
          >
            {states &&
              states.map((state, index) => {
                return (
                  <option key={index} value={state}>
                    {state}
                  </option>
                );
              })}
          </Select>
        </Box>
        <Box>
          <Labels text='Mailing Address' classNames={`${classes.titles} billingTitle`} />
          <FormControl component='fieldset'>
            <FormGroup>
              <FormControlLabel
                onChange={(e) => handleCheckboxes(e)}
                className={classes.checkboxLabel}
                control={
                  <Checkbox name='isMailingAddress' value='Yes' disableRipple disableFocusRipple />
                }
                label='Same as Service Address'
              />
            </FormGroup>
          </FormControl>

          <Labels text='Address' classNames='textLabels billingInputs' required={true} />
          <TextField
            onChange={(e) => handleInputs(e)}
            variant='filled'
            value={mailingIsSameAddress ? serviceAddress : mailingAddress}
            name='mailingAddress'
            required
          />

          <Labels text='City' classNames='textLabels billingInputs' required={true} />
          <TextField
            onChange={(e) => handleInputs(e)}
            variant='filled'
            name='mailingCityName'
            value={mailingIsSameAddress ? cityName : mailingCityName}
          />

          <Labels text='Zip' classNames='textLabels billingInputs' required={true} />
          <TextField
            onChange={(e) => handleInputs(e)}
            variant='filled'
            value={mailingIsSameAddress ? zipcode : mailingZipCode}
            name='mailingZipCode'
            required
          />

          <Labels text='State' classNames='textLabels billingInputs' required={true} />
          <Select
            onChange={(e) => handleInputs(e)}
            native
            className={classes.select}
            inputProps={{ name: 'mailingState' }}
            value={state.state ? state.state : ''}
            required
          >
            {states &&
              states.map((state, index) => {
                return (
                  <option key={index} value={state}>
                    {state}
                  </option>
                );
              })}
          </Select>
        </Box>

        <Box>
          <Labels text='Payment' classNames={`${classes.titles} billingTitle`} />
          <Tabs
            value={value}
            onChange={handleTabChange}
            aria-label='payment tabs'
            className={classes.tabs}
          >
            <Tab label='Credit/Debit Card' />
            <Tab
              disabled
              style={{
                margin: 'auto 2px',
                display: 'block',
                padding: '0',
                minWidth: '1px',
                width: '1px',
                height: '22px',
                minHeight: '22px',
                backgroundColor: '#000',
                opacity: '1',
              }}
            />
            <Tab label='E-Check (5th Mow Free **)' />
          </Tabs>
          {value === 0 && (
            <Box
              role='tabpanel'
              hidden={value === 2}
              id='simple-tabpanel-credit-card'
              aria-labelledby='simple-tab-credit-card'
            >
              <CardInfo />
            </Box>
          )}
          {value === 2 && (
            <Box
              role='tabpanel'
              hidden={value === 0}
              id='simple-tabpanel-eCheck'
              aria-labelledby='simple-tab-eCheck'
            >
              <ECheckInfo />
            </Box>
          )}
        </Box>
        <Button type='submit' className={`${classes.button} submitOrder`}>
          Submit
        </Button>
      </Form>
    </>
  );
};

export default BillingInfo;
