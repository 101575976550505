const columnsExportOperationsReportDb = [
    {
        name: 'Zip',
        desc: 'Zip',
        hidden: 'zip_code',
        field: 'zipCode',
        value: false,
    },
    {
        name: 'Service address',
        desc: 'Service address',
        hidden: 'service_address',
        field: 'serviceAddress',
        value: false,
    },
    {
        name: 'Requests Spain',
        desc: 'Requests Spain',
        hidden: 'request_spanish',
        field: 'requestSpanish',
        value: false,
    },
    {
        name: 'Conditions Spain',
        desc: 'Conditions Spain',
        hidden: 'condition_spanish',
        field: 'conditionSpanish',
        value: false,
    }
];

export default columnsExportOperationsReportDb;
