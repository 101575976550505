import AppService from './AppService';

class MarketService extends AppService {

  constructor() {
    super();
    this.path = this.getPath('markets');
  }

  read(id) {
    return this.http.get(`${this.path}/${id}`);
  }

  list() {
    return this.http.get(`${this.path}`);
  }

  slots(id) {
    return this.http.get(`${this.path}/${id}/slots?filter=2`);
  }

  slotsWeek(id, params = '') {
    const queryParams = params ? params : '';
    return this.http.get(`${this.path}/${id}/slots-week${queryParams}`);
  }

  slotsUpdate(data, id) {
    return this.http.patch(`${this.path}/${id}/attributes`, data);
  }

  updateWeek(data, id) {
    return this.http.patch(`${this.path}/${id}/update-week`, data);
  }

  calendar(zip) {
    return this.http.get(`${this.path}/calendar/${zip}`);
  }

}

export default MarketService;
