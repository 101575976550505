import { createSlice } from '@reduxjs/toolkit';
import { size as _size, filter as _filter } from 'lodash';

const initialState = {
  state: null,
  date: null,
  count: 0,
  options: []
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    addNotification: (state, action) => {
      state.state = true;
      state.date = new Date().toLocaleDateString();
      state.count = _size(_filter([...action.payload], function (e) {
        return e.status === 1 && e.available
      }));
      state.options = [
        ...initialState.options,
        ...action.payload
      ];
    },
    removeNotification: (state) => {
      state.state = null;
      state.date = null;
      state.options = []
    },
  }
});

export const {addNotification, removeNotification} = notificationSlice.actions;

export default notificationSlice.reducer;
