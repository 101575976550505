import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import AlarmIcon from '@material-ui/icons/Alarm';
import AlarmList from './AlarmList';
import { AlarmsStyle } from './AlarmsStyle';

const AlarmManager = (props) => {

  const [openAlarmList, setOpenAlarmList] = useState(false);
  const alarmsStyle = AlarmsStyle();

  return (
    <div className={alarmsStyle.btnViewAll}>
      <Button
        variant='contained'
        color='primary'
        size='small'
        startIcon={<AlarmIcon />}
        onClick={() => {
          setOpenAlarmList(true)
        }}
      >
        View all alarms
      </Button>
      <AlarmList
        open={openAlarmList}
        setOpen={setOpenAlarmList}
        handleRemoveAlarm={props.handleRemoveAlarm}
        setOpenAlarmView={props.setOpenAlarmView}
        setdataAlarmDetail={props.setdataAlarmDetail}
        handleUpdateDataAlarm={props.handleUpdateDataAlarm}
        handleAddedAlarm={props.handleAddedAlarm}
      />
    </div>
  )
}

export default AlarmManager;
