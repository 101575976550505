import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  wrapperGeneral: {
    padding: '10px',
    overflowY: 'auto',
    maxHeight: '100%',
  },
  wrapperContainerCalendar: {
    backgroundColor: 'white',
    borderRadius: '12px',
    marginTop: '30px',
    '@media (max-width: 600px)': {
      padding: '0px',
    },
    '@media (min-width: 601px)': {
      padding: '27px 70px',
    },
  },
});

export default useStyles;
