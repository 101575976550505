import React, {useState} from 'react';
import PropTypes from "prop-types";
import {
  DialogContent,
  Grid,
  InputLabel,
  Switch,
  Typography,
} from "@material-ui/core";
import {DialogCreate} from "../AdminDialogStyle";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {
  InputField,
  RadioField,
} from "../../../forms";
import {AdminPanelStyle} from "../styles/AdminPanelStyle";
import AppButton from "../../../components/forms/AppButton";
import {isEmpty as _isEmpty, toString as _toString} from "lodash";
import {Button} from "../../../components/shared/MaterialUI";
import TimePickerField from '../../../forms/TimePickerField';
import moment from 'moment';
import {Box} from "@mui/material";

const SlotsDialog = (
  {
    openDialog,
    setOpenDialog,
    data,
    setData,
    handleSubmit,
  }) => {

  const classes = AdminPanelStyle();
  const [value, setValue] = useState(null);
  const [select, setSelect] = useState({
    count: 0,
    options: []
  });
  const options = [
    {"label": "All day", "value": "0"},
    {"label": "Hour range", "value": "1"}
  ];
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  const initialValues = {
    slots: 0,
    isLock: false,
    lockDayHourly: '',
    lockHoursRange: {
      start: '',
      end: '',
    },
    timeZone: timeZone,
    week: {
      end: data.end,
      week: data.week,
      start: data.start,
      weekdays: data.weekdays.reduce((a, o) => (o.lock && o.lock === 1 && a.push(o.workday), a), []),
    }
  };

  const validationSchema = Yup.object().shape({
    slots: Yup.string()
      .required(`Annual Rate  is required`)
      .matches(/^[0-9]*$/, 'Must be a number'),
    isLock: Yup.boolean()
      .required(`Is lock Rate is required`),
    lockDayHourly: Yup.string().nullable()
      .when('isLock', (isLock) => {
        if (isLock) {
          return Yup.string().nullable().required(`Lock day hourly is required`)
            .matches(/^[0-1]*$/, 'Must be a number')
        }
      }),
    lockHoursRange: Yup.object()
      .shape({
        start: Yup.string().nullable()
          .when('lockDayHourly', () => {
            if (value === '1') {
              return Yup.string()
                .nullable()
                .required("Start date is required")
            }
          }),
        end: Yup.string().nullable()
          .when('lockDayHourly', (lockDayHourly) => {
            if (lockDayHourly === '1') {
              return Yup.string()
                .nullable()
                .required("End date is required")
            }
          }),
      }),
    week: Yup.object()
      .shape({
        weekdays: Yup.array().min(1, 'You should select at least 1 date')
          .of(Yup.string().required()).required(`Provider Type is required`),
      })
  });

  const handleWeek = (e) => {
    const newWeekdays = data.weekdays.map(obj =>
      obj.workday === e.workday ? {...obj, lock: e.lock === 1 ? 0 : 1} : obj
    );
    setData({...data, weekdays: newWeekdays});
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleCheck = (e, setFieldValue) => {
    setValue(e.target.value);
    setFieldValue("lockHoursRange", {
      start: '',
      end: ''
    });
  };

  const handleSizeRowChange = (value) => {
    if (value === null) return 12;
    if (value === '1') return 8;
    return 12;
  };

  return (
    <DialogCreate open={openDialog} onClose={handleClose}>
      <DialogContent>
        <Typography style={{fontSize: '13px', padding: '0px 0px 10px'}}>
          Select the date you want to edit.
          Select several dates to edit in one step.
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, setFieldValue }) => {
            handleSubmit(values);
            setFieldValue("lockHoursRange", {
              start: null,
              end: null
            });
            setSubmitting(false);
          }}
        >
          {({
              values,
              setFieldValue,
              errors
            }) => {
            return (
              <Form>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={12} className={classes.wrapperDates}>
                    {data.weekdays && data.weekdays.map((e, i) => {
                      return (
                        <div
                          className={classes.blockWeekDays}
                          style={{borderColor: e.lock === 1 ? '#10AA39' : '#E8E8E8'}}
                          key={`week${i}`}
                          onClick={() => {
                            let count;
                            let arr = values.week.weekdays;
                            let options = select.options ?? [];
                            if (e.lock === 1) {
                              arr.splice(arr.indexOf(e.workday), 1);
                              count = select.count - 1;
                              delete options[i + 1];
                            } else {
                              arr.push(e.workday);
                              count = select.count + 1;
                              options[i + 1] = {...e}
                            }
                            setSelect({...select, count, options});
                            if (count === 1) {
                              const firstOptions = options.filter(element => typeof element !== undefined).shift();
                              setFieldValue('slots', firstOptions.slots);
                              setFieldValue('isLock', firstOptions.lock_old === 1);
                              setFieldValue('lockDayHourly', _toString(firstOptions.lockDayHourly));
                              let timeStart = firstOptions.lockStartHour;
                              let timeEnd = firstOptions.lockEndHour;

                              if(timeStart){
                                timeStart = moment(timeStart, "HH:mm");
                                timeStart = timeStart.toDate();
                              }

                              if(timeEnd){
                                timeEnd = moment(timeEnd, "HH:mm");
                                timeEnd = timeEnd.toDate();
                              }

                              setFieldValue('lockHoursRange.start', timeStart);
                              setFieldValue('lockHoursRange.end', timeEnd);
                              setValue(_toString(firstOptions.lockDayHourly));
                            } else {
                              setFieldValue('slots', 0);
                              setFieldValue('isLock', false);
                              setFieldValue('lockDayHourly', null);
                              setFieldValue('lockHoursRange.start', '');
                              setFieldValue('lockHoursRange.end', '');
                            }
                            setFieldValue('week.weekdays', arr);
                            handleWeek(e);
                          }}
                        >
                          <div className='div1'>
                            {e.smallName}
                          </div>
                          <div className='div2'>
                            {e.week}
                          </div>
                        </div>
                      );
                    })}
                  </Grid>
                  <Grid item xs={6} sm={12}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid item xs={2}>
                        <InputLabel className={classes.label}>
                          Total Slots:
                        </InputLabel>
                      </Grid>
                      <Grid item xs={2}>
                        <InputField
                          name='slots'
                          disabled={(values.isLock)} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} sm={12}>
                    <label> Lock date: </label>
                    <Switch
                      name="isLock"
                      checked={values.isLock}
                      onChange={(event, checked) => {
                        setFieldValue("isLock", checked);
                      }}
                    />
                  </Grid>
                  {
                    values.isLock &&
                      <Grid item xs={12} sm={12}>
                        <Grid container spacing={1}>
                          <Grid
                            item
                            xs={handleSizeRowChange(value)}
                            style={
                              (value === '1')
                              ? {marginTop: '20px', flexDirection: 'column'}
                              : {marginTop: '-4px', textAlign: 'center', flexDirection: 'column'}
                            }
                          >
                            <RadioField
                              name={'lockDayHourly'}
                              options={options}
                              onChange={(e)=>{
                                handleCheck(e, setFieldValue)
                              }}
                              styleDirectionWrapper={'column'}
                              styleDirectionItems={'row'}
                            />
                          </Grid>
                          {
                            (value === '1')
                              &&
                                <Grid xs={4}>
                                  <Grid container>
                                    <Grid item xs={12} style={{marginBottom: '13px'}}>
                                      <InputLabel className={classes.label}>
                                        Start time:
                                      </InputLabel>
                                      <TimePickerField
                                        disabled={
                                          values.lockDayHourly === '0' ||
                                          _isEmpty(values.lockDayHourly)
                                        }
                                        name='lockHoursRange.start'
                                      />
                                      {
                                        (errors?.lockHoursRange?.start)
                                          &&
                                            <Box className={classes.errorCustom}>
                                              {errors?.lockHoursRange?.start}
                                            </Box>
                                      }
                                    </Grid>
                                    <Grid item xs={12}>
                                      <InputLabel className={classes.label}>
                                        End time:
                                      </InputLabel>
                                      <TimePickerField
                                        disabled={
                                          values.lockDayHourly === '0' ||
                                          _isEmpty(values.lockDayHourly)
                                        }
                                        name='lockHoursRange.end'
                                      />
                                      {
                                        (errors?.lockHoursRange?.end)
                                          &&
                                            <Box className={classes.errorCustom}>
                                              {errors?.lockHoursRange?.end}
                                            </Box>
                                      }
                                    </Grid>
                                  </Grid>
                                </Grid>
                          }
                        </Grid>
                      </Grid>
                  }
                </Grid>
                <Grid
                  container
                  spacing={2}
                  style={
                    (values.isLock)
                      ? {marginTop: '33px'}
                      : {}
                  }>
                  <Grid item xs={6}>
                    <AppButton
                      onPress={handleClose}
                      label={'Cancel'}
                      type={'main'}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      color="primary"
                      fullWidth
                      style={{padding: '5.7px'}}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )
          }}
        </Formik>
      </DialogContent>
    </DialogCreate>
  );
}

export default SlotsDialog;

SlotsDialog.propTypes = {
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
  data: PropTypes.shape({
    end: PropTypes.string.isRequired,
    week: PropTypes.number.isRequired,
    start: PropTypes.string.isRequired,
    weekdays: PropTypes.array.isRequired
  }).isRequired,
  setData: PropTypes.func,
  handleSubmit: PropTypes.func,
};
