import React from 'react';

import NumberFormat from 'react-number-format';

import {
  Grid,
} from '../../../../components/shared/MaterialUI';
import { InputField, SelectField } from '../../../../forms';
import { weekDays } from '../../../../models/db';
import Labels from '../../../web/signUpProcess/signUpCustomer/atoms/Labels';
import { useStyles } from '../../style/lookup-style';

const Lookup2 = (props) => {
  const { expandedCollapse } = props;
  const classes = useStyles();

  return (
    <>
      <Grid
        item xs={4}
        className={`${classes.gridColumns} ${classes.noPaddingRight} ${classes.noPaddingLeft}`}
      >
        <Grid container>

          <Grid item xs={12}>
            <Labels text="Address" className="labels"/>
            <InputField
              name={'service_address'}
              placeholder="Type here"
              autoComplete="off"
              fullWidth
            />
          </Grid>
          
          <Grid item xs={12}>
            <Labels text='Last name' className='labels' />
            <SelectField
              name={'last_name'}
              data={[
                { value: 'a-f', label: 'A - F' },
                { value: 'g-m', label: 'G - M' },
                { value: 'n-z', label: 'N - Z' },
              ]}
              fullWidth
              placeholder={'-- Select --'}
            />
          </Grid>
        
          {
            (expandedCollapse)
              &&
                <>
                  <Grid item xs={12}>
                    <Labels text='Mow day' className='labels' />
                    <SelectField
                      name={'week_day'}
                      data={weekDays.filter((weekD) => weekD.value !== null)}
                      fullWidth
                      placeholder={'-- Select --'}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Labels text='Size verification' className='labels' />
                    <SelectField
                      name={'lot_review'}
                      data={[
                        { value: 1, label: 'Verified' },
                        { value: 2, label: 'Not verified' },
                      ]}
                      fullWidth
                      placeholder={'-- Select --'}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Labels text='Payment methods' className='labels' />
                    <SelectField
                      name={'payment_methods'}
                      data={[
                        { value: 1, label: 'Credit/Debit Card' },
                        { value: 2, label: 'E-Check' },
                      ]}
                      fullWidth
                      placeholder={'-- Select --'}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Labels text='Zip' className='labels' />
                    <InputField
                      name={'zipcode'}
                      placeholder='Type here'
                      inputProps={{ min: 0, minLength: 1, maxLength: 5 }}
                      InputProps={{
                        inputComponent: NumberFormat,
                      }}
                      autoComplete='off'
                      fullWidth
                    />
                  </Grid>
                </>
          }

        </Grid>
      </Grid>
    </>
  );
};

export default Lookup2;