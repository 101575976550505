import AppService from './AppService';

class MarketMapService extends AppService {

  constructor() {
    super();
    this.path = this.getPath('market-map');
  }

  list(query) {
    return this.http.get(`${this.path}?${query}`);
  }

  updateMarketMap(data) {
    return this.http.patch(`${this.path}`, data);
  }

}

export default MarketMapService;
