import React, { useEffect } from 'react';

import { Divider, Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';

import { Box } from '../../shared/MaterialUI';
import FieldList from './FieldList';

const fieldDb = [
  {
    value: 1,
    label: 'Creation date',
    field: 'created_at',
    tag: 'calendar_select'
  },
  {
    value: 2,
    name: 0,
    label: 'Approved',
    field: 'is_approved',
    tag: 'hidden'
  }
];

const Lookup11 = () => {
  const {setFieldValue} = useFormikContext();

  useEffect(() => {
    setFieldValue('fields', fieldDb);
  }, [setFieldValue]);

  return (
    <Grid style={{width: '100%'}}>
      <Box mt={2}/>
      <Divider/>
      <FieldList/>
    </Grid>
  );
}
export default Lookup11;
