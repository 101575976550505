import React, { createContext, useEffect, useState } from 'react';
import { toNumber as _toNumber } from 'lodash';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Grid, Typography, Button, IconButton } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import { useUI } from '../../app/context/ui';
import { ListStyles } from '../../assets/css';
import { ReportStyles } from './components/report-style'
import AppHelper from '../../helpers/AppHelper';
import { TaskService, ReportsService } from '../../services';
import CachedIcon from '@material-ui/icons/Cached';
import CheckIcon from '@material-ui/icons/Check';
import RefreshIcon from '@material-ui/icons/Refresh';
import format from 'date-fns/format';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ErrorIcon from '@material-ui/icons/Error';
import { ButtonReport } from './components/ButtonReport';
import DeleteIcon from '@material-ui/icons/Delete';

let dlgSettings = {
  confirm: true,
  btn: {
    close: 'Cancel',
    confirm: 'Yes',
  },
  onConfirm: () => {},
};

const CreateReport = (props) => {

  const history = useHistory();
  const location = useLocation();
  const { user } = props;
  const { blockUI, snackbarUI, dialogUI } = useUI();
  const taskService = new TaskService();
  const reportService = new ReportsService();
  const listStyle = ListStyles();
  const reportStyle = ReportStyles();
  const query = new URLSearchParams(useLocation().search);

  const columns = [
    {
      field: 'createdAt',
      headerName: 'Report',
      flex: 0.1,
      minWidth: 200,
      disableColumnMenu: true,
      valueFormatter: (params) => (
        format(new Date(params.value), 'yyyy-MM-dd hh:mm:ss')
      ),
    },
    {
      field: 'filename',
      headerName: 'Filename',
      flex: 0.1,
      minWidth: 200,
      disableColumnMenu: true,
      renderCell: (params) => {
        const report = params.row;
        let filename = report.filename;
        if (report.query && report.query.includes('name_doc=') && filename){
          filename = report.query.split('name_doc=')[1] + '.' + filename.split('.')[1];
        }
        return filename;
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      headerAlign: 'center',
      align: 'center',
      disableColumnMenu: true,
      renderCell: (params) => (
        <>
          {
            (params.value === 'requested') && <div style={{color:'#9e9e0e'}}><CloudUploadIcon/> {params.value}</div>
          }
          {
            (params.value === 'in_progress') && <div style={{color:'#56bcbc'}}><RefreshIcon/> {params.value}</div>
          }
          {
            (params.value === 'completed') && <div style={{color:'green'}}><CheckIcon/> {params.value}</div>
          }
          {
            (params.value === 'failed') && <div style={{color:'#c53131'}}><ErrorIcon/> {params.value}</div>
          }
        </>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 290,
      headerAlign: 'center',
      align: 'center',
      disableColumnMenu: true,
      renderCell: (params) => (
        <>
          <ButtonReport
            row={params.row}
          />
          <IconButton
            className={reportStyle.wrapperButtonDelete}
            size="small"
            onClick={
              ()=>{
                handleSetterIdDelete(params.row)
              }
            }
          >
            <DeleteIcon/>
          </IconButton>
        </>
      ),
    },
  ];

  const [rowsState, setRowsState] = useState({
    pageSize: 10,
    rows: [],
    rowCount: 0,
    page: _toNumber(query.get('page')) || 0,
  });

  const handleSetterIdDelete = (row) => {
    dlgSettings = {
      ...dlgSettings,
      onConfirm: () => {
        onDelete(row);
      },
    };
    dialogUI.current.open('Are you sure you want to delete this report?', 'This action cannot be undone', dlgSettings);
  };

  const onDelete = async (row) => {
    try {
      blockUI.current.open(true);
      taskService.getAccessToken();
      const { status } = await taskService.delete(row.id);
      if (status === 200) {
        getListReports();
        const confirmSettings = {
          confirm: false,
          btn: {
            close: 'Close',
          },
        };
        dialogUI.current.close();
        dialogUI.current.open('', '', confirmSettings, 'Report Successfully deleted');
      }
      blockUI.current.open(false);
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const getListReports = async (pageSize=10) => {
    try {
      blockUI.current.open(true);
      taskService.getAccessToken();
      const { data:dataProcess } = await taskService.reports({userId: user.id, ...rowsState, pageSize});
      setRowsState({
        pageSize,
        rows: dataProcess.data,
        rowCount: dataProcess.total,
        page: dataProcess.currentPage - 1,
      });
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const handleOnPageChange = async (page) => {
    try {
      blockUI.current.open(true);
      taskService.getAccessToken();
      const { data: dataProcess } = await taskService.reports({userId: user.id, ...rowsState, page: page + 1});
      setRowsState((prev) => ({
        ...prev,
        rows: dataProcess.data,
        rowCount: dataProcess.total,
        page: dataProcess.currentPage - 1,
      }));
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const handlePageSizeChange = (pageSize) => {
    getListReports(pageSize);
  };

  const insertUrlParam = (key, value) => {
    let searchParams = new URLSearchParams(window.location.search);
    searchParams.set(key, value);
    history.push({...location, search: searchParams.toString()});
  }

  const handleDownloadReport = async (file, status) => {
    try {
      if(status==='completed'){
        blockUI.current.open(true);
        reportService.getAccessToken();
        const { data } = await reportService.download(file);
        window.open(data.resource, '_blank');
        blockUI.current.open(false);
      }
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  }

  const providerProps = {
    rowsState,
    handleDownloadReport
  };

  useEffect(() => {
    getListReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CreateReportContext.Provider value={providerProps}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={9}>
              <Typography variant='h4' className={reportStyle.title}>
                Reports
              </Typography>
            </Grid>
            <Grid item xs={3}/>
            <Grid item xs={9}>
              <Typography variant='h6' className={reportStyle.title}>
                List of reports
              </Typography>
            </Grid>
            <Grid item xs={3} className={reportStyle.wrapperButton}>
              <Button
                variant="contained"
                size="large"
                onClick={()=>{window.location = "/report"}}
              >
                <CachedIcon/>
                <span>Reload</span>
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Box>
          <DataGrid
            autoHeight
            headerHeight={56}
            rowHeight={56}
            columns={columns}
            {...rowsState}
            rowsPerPageOptions={[10, 15, 30]}
            paginationMode='server'
            className={listStyle.dataGrid}
            onPageChange={(page) => {
              insertUrlParam('page', page);
              handleOnPageChange(page);
            }}
            onPageSizeChange={(pageSize) => {
              handlePageSizeChange(pageSize);
            }}
            hideFooterSelectedRowCount={true}
          />
        </Box>
      </CreateReportContext.Provider>
    </>
  );
};

const CreateReportContext = createContext({});

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(CreateReport);
