import React, {useEffect, useRef, useContext} from 'react';
import {useFormikContext} from 'formik';
import {isEqual as _isEqual, cloneDeep as _cloneDeep, mapKeys as _mapKeys,
  unset as _unset, isUndefined as _isUndefined } from 'lodash';
import {WizardStyles} from '../assets/css';
import { PreviousCustomerContext } from '../pages/customer/EditCustomer';
import CustomerService from '../services/CustomerService';
import AppHelper from '../helpers/AppHelper';
import { useUI } from '../app/context/ui';
import { useDispatch } from 'react-redux';
import {addCustomer, updateProperty} from '../app/store/mm/customerSlice';

const AutoSave = ({debounceMs, id, isFocused}) => {
  const formik = useFormikContext();
  const previousRef = useRef()
  const wizardStyle = WizardStyles();
  const {blockUI, snackbarUI} = useUI();
  const dispatch = useDispatch();

  const {previous, year} = useContext(PreviousCustomerContext);

  useEffect(() => { previousRef.current = formik.values },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [previous]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const customerService = new CustomerService();

  const handleSubmit = async (values) => {
    try {
      blockUI.current.open(true);
      customerService.getAccessToken();
      if (values.weekYear) {
        const payload = {
          week: values.weekYear.split('/')[0],
          year: values.weekYear.split('/')[1],
        };
        await customerService.update(payload, id);
        const r1 = await customerService.read(id);
        dispatch(addCustomer(r1.data));
      } else {
        if (areNonTargetFields(values)) {
          const payload = { ...values, year: year };
          if (validFormik(values)) {
            await customerService.update(payload, id);
            dispatch(updateProperty(values));
          }
        }
      }
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  }

  const areNonTargetFields = (obj) => {
    for (const key in obj) {
      if (key !== 'conditionSpanish' && key !== 'requestSpanish') {
        return true;
      }
    }
    return false;
  }

  const validFormik = (values) => {

    let isValid = true;

    if (!_isUndefined(values.contactNumber)) {
      isValid = values.contactNumber.length === 10;
    } else if (!_isUndefined(values.zipcode)) {
      isValid = values.zipcode.length === 5;
    } else if (!_isUndefined(values.billingZipCode)) {
      isValid = values.billingZipCode.length === 5;
    }

    if (!_isUndefined(values.lat)) {
      isValid = isValid && /^\d*(?:\.\d+)?$/.test(values.lat);
    }

    if (!_isUndefined(values.lng)) {
      isValid = isValid && /^-\d*(?:\.\d+)?$/.test(values.lng);
    }

    return isValid;
    
  }

  let payload = _cloneDeep(formik.values);

  function save() {
    if (previousRef.current && Object.keys(previousRef.current).length && !_isEqual(previousRef.current, formik.values)) {
      _mapKeys(payload, (value, key) => {
        if (_isEqual(previousRef.current[key], value)) {
          _unset(payload, key)
        }
      })

      if (!isFocused) {
        handleSubmit(payload);
        previousRef.current = formik.values
      }
    }
  }

  useEffect(() => {
    const update = setTimeout(() => {
      save()
    }, debounceMs);

    return () => clearTimeout(update)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values, isFocused])

  return <>{!!formik.isSubmitting && <span className={wizardStyle.alert}>Saving...</span>}</>;

};

export default AutoSave;

