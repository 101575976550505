import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    textTransform: 'none',
    fontSize: '13px',
  },
  checkboxSvg: {
    '& .MuiFormControlLabel-label': {
      color: '#113019',
      fontSize: '13px',
    },
  },
  wrapperCheckboxSvg: {
    border: 'solid 1px green',
    borderRadius: '10px',
    paddingLeft: '15px',
    marginBottom: '4px',
  },
  iconButtonSvg: {
    color: '#11aa39',
    backgroundColor: '#11aa391f',
    padding: '5px',
  },
  iconButtonChoose: {
    color: '#ffffff',
    padding: '5px',
    backgroundColor: '#0b0b0bcf',
    borderRadius: '16px',
    fontSize: '12px',
    height: '29px',
    fontWeight: '700',
  },
  rootAwayListener: {
    position: 'relative',
    float: 'right',
    marginRight: '36px',
    marginTop: '6px',
  },
  awayDropdown: {
    top: '36px',
    left: '-52px',
    right: '0',
    width: '138px',
    padding: '8px',
    zIndex: '1',
    position: 'absolute',
    backgroundColor: '#11aa3930',
    borderRadius: '11px',
    textAlign: 'center',
    '& button': {
      marginBottom: '5px',
    },
  },
  btnSelectedAll: {
    color: 'green',
  },
  wrapperPagination: {
    paddingLeft: '16px',
  },
}));
