import React from 'react';
import { 
	Box, 
	Button, 
	Dialog, 
	Grid, 
	IconButton, 
	Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { AppStyle } from '../../../../assets/css/app/AppStyle';
import CloseIcon from '@material-ui/icons/Close';

export const CompleteServiceModal = ({
	open,
	onClose,
	job,
}) => {
	const appStyle = AppStyle();
	const onSend = () => { onClose() };
	const notes = job?.notes?.map((note) => note.description).join(', ') || '';
	const images = job?.attachments?.map((attachment) => attachment.filename) || [];
	const jobStartTime = job?.attachments?.find((attachment) => attachment.type === 'check-in')?.createdAt || '';
	const jobEndTime = job?.attachments?.find((attachment) => attachment.type === 'check-out')?.createdAt || '';
	
	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth='sm'
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
    >
      <Grid container alignItems="center" spacing={0}>
        <Grid item container xs={12} spacing={1}>
          <Grid item xs={10}/>
          <Grid item xs={2}>
            <IconButton
              color='primary'
              aria-label='close'
              className={clsx(appStyle.pdg0, appStyle.btnRight)}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item container xs={12} spacing={2} style={{padding: '0 40px'}}>
          <Grid item xs={10}>
            <h2 className={clsx(appStyle.mrg0V)}>{'Completed job detail'}</h2>
          </Grid>
          <Grid item xs={12}>
            <Typography 
              variant='subtitle2'
              component='label'
              style={{fontWeight: 'bold'}}
            >
              {'Job start time: '}
            </Typography>
            <Typography
              variant='subtitle2'
              component='label'
            >
              {jobStartTime}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography 
              variant='subtitle2'
              component='label'
              style={{fontWeight: 'bold'}}
            >
              {'Job completed time: '}
            </Typography>
            <Typography
              variant='subtitle2'
              component='label'
            >
              {jobEndTime}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography 
              variant='subtitle2'
              component='label'
              style={{fontWeight: 'bold'}}
            >
              {'Crew notes: '}
            </Typography>
            <Typography
              variant='subtitle2'
              component='label'
            >
              {notes}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography 
              variant='subtitle2'
              style={{fontWeight: 'bold'}}
            >
              {'Pictures: '}
            </Typography>
            <Box sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 24,
              '& > :not(style)': {
                m: 1,
                width: 128,
                height: 128,
              },
            }}>
            {
              images.map((item, index) => (
                <div key={index} style={{display: 'flex', justifyContent: 'center'}}>
                  <img
                    src={item}
                    alt={'img'}
                    style={{width: '100%', height: '100%', objectFit: 'cover'}}
                  />
                </div>
              ))
            }
            </Box>
          </Grid>
          <Grid item xs={12} style={{margin: '20px 0'}}>
            <Button
              variant="contained"
              color="primary"
              onClick={onSend}
              fullWidth
            >
              {'Close'}
            </Button>
            </Grid>
        </Grid>
      </Grid>
		</Dialog>
	)
}
