import React from 'react';
import { Divider, Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { Box } from '../../shared/MaterialUI';
import AppButton from '../../forms/AppButton';

const Lookup15 = () => {

  const { handleSubmit } = useFormikContext();

  return (
    <Grid style={{width: '100%'}}>
      <Box mt={2}/>
      <Divider/>
      <Grid item xs={12}>
        <AppButton
          onPress={handleSubmit}
          label={'Search'}
          type={'main'}
          color={'primary'}
        />
      </Grid>
    </Grid>
  );
}

export default Lookup15;
