import * as Yup from 'yup';

export const validationSchema = Yup.object({
  zip: Yup.string('Enter zipcode')
    .min(5, "Min. 5 characters")
    .max(5, "Max. 5 characters")
    .required('Zipcode is required'),
  exceptions: Yup.string('Enter exceptions')
    .max(500, "Max. 500 characters"),
});
