import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { CircularProgress, Divider, Grid, InputLabel, Typography } from '@material-ui/core';
import { InputField, CheckboxField, SelectField } from '../../../forms';
import { WizardStyles, LoginStyles } from '../../../assets/css';

import { Alert, Box } from '../../../components/shared/MaterialUI';
import { find as _find, isUndefined as _isUndefined, size as _size, isEmpty as _isEmpty } from 'lodash';

import SrvService from '../../../services/SrvService';
import store from '../../../redux/store';
import { useHistory } from 'react-router-dom';
import { useFormikContext } from 'formik';
import NumberFormat from 'react-number-format';

import { PickServiceData } from '../../../services/pickService';
import { PickItemServiceData } from '../../../services/pickItemService';
import AppHelper from '../../../helpers/AppHelper';
import { useUI } from '../../../app/context/ui';

export default function Step1(props) {
  const [hasError, setHasError] = useState({});
  const [requestFailed, setRequestFailed] = useState(false);
  const [aboutsDb, setAboutsDb] = useState([]);
  const [children, setChildren] = useState([]);
  const [aboutLabel, setAboutLabel] = useState([]);
  const [loading, setLoading] = useState(false);
  const {values: formValues} = useFormikContext();
  const {aboutOld} = formValues;
  const {snackbarUI} = useUI();
  const {
    formField: {
      about,
      aboutOption,
      useServiceHousesScheduled,
      useServiceHousesOccupied,
      zipcode,
      zipcodeValid,
    }
  } = props;

  const {formikRef} = props;

  const history = useHistory();
  const state = store.getState();
  const accessToken = state.user.accessToken;
  if (!accessToken) {
    history.push('/login');
  }

  const srvService = new SrvService();

  const pickServiceData = useMemo(() => new PickServiceData(), []);
  const pickItemServiceData = new PickItemServiceData();

  const handleChangeAbout = (event) => {
    setAboutLabel(event.target.value);
    if (formikRef.current) {
      formikRef.current.setFieldValue(
        'aboutOld', event.target.value
      );
      formikRef.current.setFieldValue(
        'aboutOption', ''
      )
    }
    (async function init() {
      await  getPickItemGroup(event.target.value);
    })();
  };

  const getPickItem = useCallback(async (values) => {
    try {
      const r = await pickServiceData.getAbout(values);
      setAboutsDb(r);
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  }, [pickServiceData, snackbarUI]);

  const getPickItemGroup = useCallback(async (values) => {
    try {
      if (!_isUndefined(values)) {
        const r = await pickItemServiceData.getAboutOption(values);
        setChildren(r);
        const labelAbout = _find(aboutsDb, {'value': values});
        if (!_isUndefined(labelAbout)) {
          setAboutLabel(labelAbout.label);
        }
      }
    } catch (e) {
      setChildren([]);
      AppHelper.checkError(e, snackbarUI);
    }
  }, [pickItemServiceData, snackbarUI, aboutsDb]);


  const onKeyPress = (e) => {
    if (e.key === 'Enter' || e.target.value.length === 5) {
      srvService.init(accessToken);
      // write your functionality here
      getSrvZipCode(e.target.value);
    }
  }

  const getSrvZipCode = async (zip) => {
    try {
      setRequestFailed(false);
      setLoading(true);
      const r1 = await srvService.zipCode(`zip-code/${zip}`);
      const msg = _isEmpty(r1.data.exceptions)
        ? `Mow Managers service in your area`
        : `${r1.data.exceptions}`;

      setHasError({message: msg});
      setLoading(false);
      if (formikRef.current) {
        formikRef.current.setFieldValue(
          'marketId', r1.data.serviceMarketsId
        );
      }
      validStep(msg);
    } catch (e) {
      setLoading(false);
      setRequestFailed(true);
      if (!_isUndefined(e.response)) {
        setHasError({message: e.response.data.message});
      }
      validStep('');
    }
  };

  const validStep = (value) => {
    if (formikRef.current) {
      formikRef.current.setFieldValue(
        'zipcodeValid', value
      );
    }
  }

  useEffect(() => {
    (async function init() {
      await getPickItemGroup(aboutOld);
      srvService.init(accessToken);
    })();
  }, [accessToken, aboutOld]);

  useEffect(() => {
    (async function init() {
      await getPickItem(1)
    })();
  }, [accessToken]);

  const loginStyle = LoginStyles();
  const wizardStyle = WizardStyles();
  return (
    <>
      <span className={wizardStyle.title}>Company</span>
      <Divider/>
      <Box my={2}/>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <InputLabel id="demo-mutiple-checkbox-label" className={wizardStyle.label}>
            How did you hear about us:
          </InputLabel>
          <SelectField
            name={about.name}
            data={aboutsDb}
            autoFocus
            fullWidth
            onClick={handleChangeAbout}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {_size(children) > 0 ?
            <div>
              <InputLabel id="demo-mutiple-checkbox-label" className={wizardStyle.label}>
                {aboutLabel}
              </InputLabel>
              <SelectField
                name={aboutOption.name}
                data={children}
                autoFocus
                fullWidth
              /></div>
            : ''}
        </Grid>
        <Grid item xs={12}>
          <span className={wizardStyle.title}>Schedule your service</span>
          <Divider/>
        </Grid>
        <Grid item xs={12}>
          <Alert severity="info" color="info" className={wizardStyle.alert}>
            We only offer scheduled service and the houses must be occupied
          </Alert>
        </Grid>
        <Grid item xs={12} sm={3}>
          <CheckboxField
            name={useServiceHousesScheduled.name}
            label={useServiceHousesScheduled.label}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <CheckboxField
            name={useServiceHousesOccupied.name}
            label={useServiceHousesOccupied.label}
          />
        </Grid>
        <Grid item xs={12}>
          <span className={wizardStyle.title}>ZIP code to verify services in your area</span>
          <Divider/>
        </Grid>
        <Grid item xs={12}>
          <InputField name={zipcode.name} onKeyUp={onKeyPress}
                      inputProps={{min: 0, style: {textAlign: 'center'}, minLength: 1, maxLength: 5}}
                      InputProps={{
                        inputComponent: NumberFormat,
                      }}
                      fullWidth/>
        </Grid>
        <Grid item xs={12}>
          <Grid container align="center">
            <Grid item xs={12}>
              {loading ?
                <CircularProgress disableShrink/>
                : <Divider/>}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {!requestFailed && (
            <InputField name={zipcodeValid.name} id={zipcodeValid.name} disabled fullWidth/>
          )}
          <Typography component="div">
            {requestFailed && (
              <p className={loginStyle.formError}>{hasError.message}</p>
            )}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
