export default function convertDate(date, days = 1, newFormat = false, formatMDY = true) {
  let dateStart;
  if(!date){
    dateStart = new Date(new Date().toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'}));
  }else{
    const b = date.split(/\D/);
    dateStart = (newFormat) ? new Date(b[2], --b[0], b[1]) : new Date(b[0], --b[1], b[2]);
  }
  dateStart.setDate(dateStart.getDate() + (days -1));
  const month = (dateStart.getMonth() + 1) > 9 ? (dateStart.getMonth() + 1) : `0${(dateStart.getMonth() + 1)}`;
  const day = (dateStart.getDate()) > 9 ? (dateStart.getDate()) : `0${(dateStart.getDate())}`;
  return formatMDY ? `${month}/${day}/${dateStart.getFullYear()}` :
    `${dateStart.getFullYear()}-${month}-${day}`;
}
