import AppService from './AppService';

class StatsService extends AppService {
  constructor() {
    super();
    this.path = this.getPath('stats');
  }
  getStats() {
    return this.http.get(`${this.path}`);
  }
}

export default StatsService;
