import React, { useContext } from 'react';

import { useFormikContext } from 'formik';
import { isEmpty as _isEmpty, startsWith as _startsWith } from 'lodash';

import {
  Box, Select, TextField, Typography,
} from '../../../../../../components/shared/MaterialUI';
import { SignUpCustomerContext } from '../../../SignUpProcess';
import Labels from '../../atoms/Labels';
import { SignUpStyles } from '../../styles/signUp-style';

const CardInfo = () => {
  const { state, dispatch } = useContext(SignUpCustomerContext);
  const classes = SignUpStyles();
  const { cardType, cardNumber } = state;

  const formik = useFormikContext();
  const {errors, touched } = formik

  const months = [
    { value: 1, label: 'January' },
    { value: 2, label: 'February' },
    { value: 3, label: 'March' },
    { value: 4, label: 'April' },
    { value: 5, label: 'May' },
    { value: 6, label: 'June' },
    { value: 7, label: 'July' },
    { value: 8, label: 'August' },
    { value: 9, label: 'September' },
    { value: 10, label: 'October' },
    { value: 11, label: 'November' },
    { value: 12, label: 'December' },
  ];

  const expirationYears = () => {
    const currentYear = new Date().getFullYear();
    const nextFiveYears = [currentYear];
    for (let i = 0; i <= 4; i++) {
      nextFiveYears.push(nextFiveYears[i] + 1);
    }
    return nextFiveYears;
  };

  const handleTextInputs = (e) => {
    const { name, value } = e.target;
    if (name === 'cardNumber') {
      if (_startsWith(value, '4')) {
        formik.setFieldValue('cardType', parseInt(4));
        dispatch({
          type: 'setTextFields',
          payload: { cardType: parseInt(4) },
        });
      } else if (_startsWith(value, '5')) {
        formik.setFieldValue('cardType', parseInt(5));
        dispatch({
          type: 'setTextFields',
          payload: { cardType: parseInt(5) },
        });
      } else if (_startsWith(value, '6')) {
        formik.setFieldValue('cardType', parseInt(6));
        dispatch({
          type: 'setTextFields',
          payload: { cardType: parseInt(6) },
        });
      } else {
        formik.setFieldValue('cardType', parseInt(3));
        dispatch({
          type: 'setTextFields',
          payload: { cardType: parseInt(3) },
        });
      }
      formik.setFieldValue(name, value);
      dispatch({
        type: 'setTextFields',
        payload: { [name]: value },
      });
    } else {
      formik.setFieldValue(name, value);
      dispatch({
        type: 'setTextFields',
        payload: { [name]: value },
      });
    }
  };

  const handleCardType = (e) => {
    const { name, value } = e.target;
    formik.setFieldValue(name, parseInt(value));
    dispatch({
      type: 'setTextFields',
      payload: { [name]: parseInt(value) },
    });
  };

  return (
    <>
      <Typography style={{ margin: '32px 0', fontFamily: 'Open Sans', color: '#676767' }}>
        Conveniently billed to your cad the next business day after your scheduled mow
      </Typography>
      <Box marginTop={3}>
        <Labels text='Name on Card' classNames='textLabels billingInputs' required={true} />
        <TextField onChange={(e) => handleTextInputs(e)} variant='filled' name='nameOnCard' required />
        
        <Box style={{ position: 'relative' }}>
        <Labels text='Card Number' classNames='textLabels billingInputs' required={true} />
        <TextField onChange={(e) => handleTextInputs(e)} variant='filled' name='cardNumber' required onBlur={formik.handleBlur}/>
        {errors.cardNumber && touched.cardNumber && (
          <>
            <Typography style={{ position: 'absolute', left: '10px', bottom: '0', color: '#FF0060', fontSize: '14px' }}>
              {errors.cardNumber}
            </Typography>
          </>
        )}
        </Box>

        <Labels text='Card Type' classNames='textLabels billingInputs' required={true} />
        <Select
          onChange={(e) => handleCardType(e)}
          native
          className={classes.select}
          inputProps={{ name: 'cardType' }}
          value={_isEmpty(cardNumber) ? 4 : cardType}
          required
        >
          <option value={4}>Visa</option>
          <option value={5}>MasterCard</option>
          <option value={6}>Discover Card</option>
          <option value={3}>American Express</option>
        </Select>
        <Labels text='Card Expiration Date' classNames='textLabels billingInputs' required={true} />
        <Box style={{ width: '71%', display: 'flex', justifyContent: 'space-between' }}>
          <Select
            onChange={(e) => handleTextInputs(e)}
            native
            className={classes.billingSelect}
            inputProps={{ name: 'cardMonth' }}
            required
          >
            <option value='' defaultValue>
              Month
            </option>
            {months &&
              months.map((month, index) => {
                return (
                  <option value={month.value} key={index}>
                    {month.label}
                  </option>
                );
              })}
          </Select>
          <Select
            onChange={(e) => handleTextInputs(e)}
            native
            className={classes.billingSelect}
            inputProps={{ name: 'cardYear' }}
            required
          >
            <option value='' defaultValue>
              Year
            </option>
            {expirationYears().map((year, index) => {
              return (
                <option value={year} key={index}>
                  {year}
                </option>
              );
            })}
          </Select>
        </Box>
      </Box>
    </>
  );
};

export default CardInfo;
