import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';

import { useUI } from '../../../../app/context/ui';
import { Grid } from '../../../../components/shared/MaterialUI';
import { InputField, SelectField } from '../../../../forms';
import DatePickerField from '../../../../forms/DatePickerField';
import { FormatPhoneNumber, FormatWeekYear, Utils } from '../../../../helpers';
import AppHelper from '../../../../helpers/AppHelper';
import { MarketService } from '../../../../services';
import Labels from '../../../web/signUpProcess/signUpCustomer/atoms/Labels';
import { useStyles } from '../../style/lookup-style';
import NumberFormat from "react-number-format";

const Lookup3 = (props) => {
  const { snackbarUI } = useUI();
  const classes = useStyles();
  const [markets, setMarkets] = useState();
  const marketService = useMemo(() => new MarketService(), []);

  const { formikRef, expandedCollapse } = props;
  const values = formikRef.current?.values || '';

  /*const _onChange = (e) => {
    const { name } = e.target;
    if (name === 'is_bb') {
      formikRef.current.setFieldValue(name, e.target.checked === true ? '1' : '');
    } else {
      formikRef.current.setFieldValue(name, e.target.checked === true ? '0,2' : '');
    }
  };*/

  const getListMarket = useCallback(async () => {
    try {
      marketService.getAccessToken();
      const r1 = await marketService.list();
      const arr = Utils.updateArrayObjectItem(r1.data, []);
      setMarkets(arr);
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  }, [marketService, snackbarUI]);

  useEffect(() => {
    getListMarket();
  }, [getListMarket]);

  useEffect(() => {
    if(values.market){
      const formik = formikRef.current;
      formik.setFieldValue('market_bb', '');
    }
  }, [values.market]);

  return (
    <>
      <Grid
        item xs={4}
        className={`${classes.gridColumns} ${classes.noPaddingRight}`}
        >
          <Grid container>

            <Grid item xs={12}>
              <Labels text='Phone number' className='labels' />
              <InputField
                name={'contact_number'}
                InputProps={{inputComponent: FormatPhoneNumber}}
                placeholder="Type here"
                autoComplete="off"
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <Labels text='Market' className='labels' />
              <SelectField
                name={'market'}
                data={markets}
                fullWidth
                placeholder={'-- Select a Market --'}
              />
            </Grid>

            {
              (expandedCollapse)
                &&
                  <>
                    <Grid item xs={12}>
                      <Labels text='Mow Week' className='labels' />
                      <InputField
                        name={'week'}
                        placeholder='Type here'
                        InputProps={{
                          inputComponent: FormatWeekYear,
                        }}
                        fullWidth
                      />
                    </Grid>

                    {/* <Grid item xs={12}>
                      <Labels text='Pending approval' className='labels' />
                      <FormControlLabel
                        style={{ marginTop: '15px' }}
                        control={
                          <Checkbox
                            name='status_orders'
                            checked={values.status_orders}
                            onChange={(e) => _onChange(e)}
                            color='secondary'
                          />
                        }
                        label='Yes'
                      />
                    </Grid> */}

                    <Grid item xs={12} style={{ height: 'unset' }}>
                      <div className={classes.labelDate}>Billing note date</div>
                      <DatePickerField
                        name='billing_note_date'
                        value={values.billing_note_date}
                        inputValue={values.billing_note_date}
                        format='MM/dd/yyyy'
                        maxDate={new Date('2050/12/31')}
                        variant={'inline'}
                        className='datePicker smallDatePicker'
                        autoOk
                        inputProps={{ placeholder: 'Start Date' }}
                      />
                    </Grid>

                    <Grid item xs={12} style={{ height: 'unset' }}>
                      <Grid container>
                        <Grid item xs={12} style={{ marginTop: '0', height: 'unset' }}>
                          <div className={classes.labelDate}>Edited date</div>
                          <DatePickerField
                            name='updated_at'
                            value={values.updated_at}
                            inputValue={values.updated_at}
                            format='MM/dd/yyyy'
                            maxDate={new Date('2050/12/31')}
                            variant={'inline'}
                            className='datePicker smallDatePicker'
                            autoOk
                            inputProps={{ placeholder: 'Edited Date' }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} style={{height: 'unset'}}>
                      <Labels text=' Account number' className='labels' />
                      <InputField
                        name='customerId'
                        InputProps={{inputComponent: NumberFormat}}
                        placeholder="Type here"
                        fullWidth
                      />
                    </Grid>
                  </>
            }

          </Grid>
      </Grid>
    </>
  );
};

export default Lookup3;
