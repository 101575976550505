import React, { createContext, useEffect, useState } from 'react'

import {
  includes as _includes,
  isEmpty as _isEmpty,
  isUndefined as _isUndefined,
  split as _split,
  words as _words,
  toNumber as _toNumber,
  findIndex as _findIndex,
  size as _size, filter as _filter,
} from 'lodash'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { Box, Checkbox, IconButton } from '@material-ui/core'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import { DataGrid } from '@mui/x-data-grid'

import { useUI } from '../../app/context/ui'
import { addCustomer } from '../../app/store/mm/customerSlice'
import { ListStyles } from '../../assets/css'
import AppHelper from '../../helpers/AppHelper'
import { CustomerService } from '../../services'
import { columns } from './components/list-reconciliation-report-columns.table'
import ListCustomerHeader from './ListReconciliationReportHeader'

const ListCustomer = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const locationState = location.state || []
  const typeLog = location.state?.typeLog
  const condition = location.state?.condition || ''

  const link = _isUndefined(locationState) ? '' : _isUndefined(locationState.state) ? '' : locationState.state;

  const {blockUI, snackbarUI} = useUI()

  const [paginationURL, setPaginationURL] = useState('')

  const {pathname} = useLocation()
  const expiredCards = pathname === '/expired-card'
  const query = new URLSearchParams(useLocation().search)

  let checkboxItem = 0

  let initialValuesCheckedStatus = {
    item1: false,
    item2: false,
    item3: false,
    item4: false,
    item5: false,
    item6: false,
    item7: false,
    item8: false,
    item9: false,
    item10: false,
    item11: false,
  }

  let [checkedStatus, setCheckedStatus] = useState({
    ...initialValuesCheckedStatus,
  })

  const customerService = new CustomerService()
  const listStyle = ListStyles()

  const [rowsState, setRowsState] = useState({
    pageSize: 50,
    rows: [],
    rowCount: 0,
    page: _toNumber(query.get('page')) || 0,
  })

  const search = `?render=paginate&limit=${rowsState.pageSize}&page=
  ${rowsState.page + 1}${link}${_includes(link, '&filter[status_orders]') ? '' : `${'&filter[status_orders]=1&filter[status]=1'}`}`;

  const [openButton, setOpenButton] = useState(false)
  const [selectAllBtn, setSelectAllBtn] = useState(false)

  const [item, setItem] = useState(0);
  const [valHidden, setValHidden] = useState([]);

  const getListCustomer = async (pageSize) => {
    try {
      blockUI.current.open(true)
      customerService.getAccessToken()
      const urlSearchParams = new URLSearchParams(window.location.search);
      const sort = urlSearchParams.get("sort");
      const newSort = _isEmpty(sort) ? '&sort=-id' : `&sort=${sort}`;
      let dataProcess;
      const query = _words(search, /[^&]+/g)
        .map((n) => {
          return n.substring(0, 5) === 'limit' && pageSize ? `limit=${pageSize}` : n
        })
        .join('&');

      let _query;
      if (link) {
        _query = `${query}${newSort}`;
      } else {
        let _page = rowsState.page + 1;
        let _limit =  pageSize || rowsState.pageSize;
        let _filterValue =  expiredCards ? 2 : 1;
        let _filter =  expiredCards ? 'status' : 'status_orders';
        let _sort = newSort;
        _query = `?render=paginate&sort=${_sort}&limit=${_limit}&filter[${_filter}]=${_filterValue}&page=${_page}`;
      }

      localStorage.setItem('query', _query);

      const r1 = link
        ? await customerService.list(`${query}${newSort}`)
        : await customerService.getCustomers({
          page: rowsState.page + 1,
          limit: pageSize || rowsState.pageSize,
          filterValue: expiredCards ? 2 : 1,
          filter: expiredCards ? 'status' : 'status_orders',
          sort: newSort
        })
      dataProcess = r1.data;

      setPaginationURL(_split(dataProcess.firstPageUrl, '?')[1])
      setRowsState((prev) => ({
        pageSize: dataProcess.perPage || prev.pageSize,
        rows: dataProcess.data,
        rowCount: dataProcess.total,
        page: dataProcess.currentPage - 1,
      }))

      blockUI.current.open(false)
    } catch (e) {
      blockUI.current.open(false)
      AppHelper.checkError(e, snackbarUI)
    }
  }

  const handleVisibilityUser = (id) => {
    setItem(id);
  }

  const handleChangeCheckedUserAll = (e) => {
    let attributes = e.target.parentElement.parentElement.attributes

    let status

    attributes[2].value === 'true' ? (status = true) : (status = false)

    let checkboxItem = attributes[4].value

    let idUser = parseInt(attributes[3].value)

    setCheckedStatus({...checkedStatus, [checkboxItem]: !status})

    if (status) {
      let updated = JSON.parse(localStorage.getItem('listClientExport')).filter(
        (e) => e !== idUser
      )
      localStorage.setItem('listClientExport', JSON.stringify(updated))
    } else {
      let updated = JSON.parse(localStorage.getItem('listClientExport'))
      updated.push(idUser)
      localStorage.setItem('listClientExport', JSON.stringify(updated))
    }
  }

  const [actionColumn, setActionColumn] = useState([
    {
      field: 'id',
      headerName: ' ',
      width: 80,
      hide: pathname === '/expired-card',
      align: 'center',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        ++checkboxItem
        let status
        let listClientExport = JSON.parse(
          localStorage.getItem('listClientExport')
        )

        let res = listClientExport
          ? listClientExport.find((e) => e === params.value)
          : undefined

        res === undefined ? (status = false) : (status = true)

        if (res) {
          checkedStatus = {...checkedStatus, [`item${checkboxItem}`]: true}
        }

        return (
          <>
            {pathname !== '/expired-card' ? (
              <>
                <Checkbox
                  status={`${status}`}
                  iduser={`${params.value}`}
                  checkboxitem={`item${checkboxItem}`}
                  checked={checkedStatus[`item${checkboxItem}`] || false}
                  onChange={(e) => handleChangeCheckedUserAll(e)}
                />
                <IconButton
                  aria-label='redirect'
                  size='small'
                  style={{padding: '0px'}}
                  onClick={() => {
                    handleVisibilityUser(params.value)
                  }}>
                  <VisibilityOffIcon fontSize='inherit'/>
                </IconButton>
              </>
            ) : (
              <></>
            )}
          </>
        )
      },
    },
  ])

  const handleOnPageChange = async (page) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const sort = urlSearchParams.get("sort");
    const newSort = !_isEmpty(sort) ? `&sort=${sort}` : '';
    const query = _words(paginationURL, /[^&]+/g)
      .map((n) => {
        return n.substring(0, 4) === 'sort' && !_isEmpty(sort) ? `sort=${sort}` : n
      })
      .slice(0, -1).join('&');
    const find = query.indexOf('sort=') === -1 ? `${query}${newSort}` : query;
    try {
      blockUI.current.open(true)
      customerService.getAccessToken()
      const response = await customerService.pagination(`${find}`, page + 1)
      const dataProcess = response.data
      selectAllBtn
        ? localStorage.setItem('listClientExport', JSON.stringify([1]))
        : null
      let rws = _filter(dataProcess.data, function (o) {
        return !_includes(valHidden, o.id)
      });

      setRowsState((prev) => ({
        ...prev,
        rows: rws,
        rowCount: dataProcess.total,
        page: dataProcess.currentPage - 1,
      }))

      blockUI.current.open(false)
    } catch (e) {
      blockUI.current.open(false)
      AppHelper.checkError(e, snackbarUI)
    }
  }

  const [renderColumns, setRenderColumns] = useState([
    ...actionColumn,
    ...columns,
  ])

  const handleDoubleClick = (row) => {
    dispatch(addCustomer({...row}))
    const record = _findIndex(rowsState.rows, {id: row.id}) ?? 0
    localStorage.setItem('listCustomerSelect', link)
    if (typeLog) {
      history.push(
        '/edit-customer?tap=3&record=' + record + '&page=' + rowsState.page,
        {...row, typeLog}
      )
    } else {
      history.push(
        '/edit-customer?record=' + record + '&page=' + rowsState.page,
        {...row, condition}
      )
    }
  }

  const handlePageSizeChange = (pageSize) => {
    getListCustomer(pageSize)
  }

  const handleSortModelChange = (field, sort = 'asc') => {
    let order = '';
    const camelToSnakeCase = field.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
    switch (camelToSnakeCase) {
      case 'full_name':
        order = 'first_name,last_name';
        break;
      case 'status_name':
        order = 'status';
        break;
      case 'market_name':
        order = 'market';
        break;
      default:
        order = camelToSnakeCase;
        break;
    }
    order = order + ',id';
    insertUrlParam('sort', sort === 'asc' ? order : `-${order}`);
    handleOnPageChange(rowsState.page);
  }

  const insertUrlParam = (key, value) => {
    let searchParams = new URLSearchParams(window.location.search)
    searchParams.set(key, value)
    history.push({...location, search: searchParams.toString()})
  }

  const providerProps = {
    checkboxItem,
    openButton,
    selectAllBtn,
    actionColumn,
    setRenderColumns,
    setActionColumn,
    handleChangeCheckedUserAll,
    handleVisibilityUser,
    setOpenButton,
    setSelectAllBtn,
    rowsState,
    search,
  }

  useEffect(() => {
    localStorage.setItem('listClientExport', JSON.stringify([]))
    getListCustomer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (item > 0) {
      let arr = valHidden ?? [];
      arr.push(item);
      setValHidden(arr);
      let rws = _filter(rowsState.rows, function (o) {
        return !_includes(arr, o.id)
      });
      setRowsState((prev) => ({...prev, rows: rws}))
      setItem(0);
    }
    // eslint-disable-next-line
  }, [item]);

  return (
    <>
      <ListCustomerContext.Provider value={providerProps}>
        <ListCustomerHeader />
        <Box style={{ height: 580, width: '100%' }}>
          <DataGrid
            autoHeight
            headerHeight={56}
            rowHeight={56}
            columns={renderColumns}
            {...rowsState}
            rowsPerPageOptions={[50, 75, 100]}
            paginationMode='server'
            className={listStyle.dataGrid}
            onPageChange={(page) => {
              insertUrlParam('page', page)
              handleOnPageChange(page)
            }}
            onPageSizeChange={(pageSize) => {
              handlePageSizeChange(pageSize)
            }}
            onSortModelChange={(field) => {
              if (_size(field) > 0) {
                handleSortModelChange(field[0].field, field[0].sort);
              }
            }}
            onRowDoubleClick={(row) => handleDoubleClick(row.row)}
            hideFooterSelectedRowCount={true}
          />
        </Box>
      </ListCustomerContext.Provider>
    </>
  )
}

export const ListCustomerContext = createContext({})
export default ListCustomer
