import { makeStyles } from '@material-ui/core/styles';

export const QuoteStyles = makeStyles((theme) => ({
  container: {
    '& .MuiTypography-root': {
      fontFamily: ['Roboto Condensed', 'Roboto'],
      color: '#444444',
    },
    padding: '32px 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& .divider': {
      '& hr': {
        margin: '90px 0',
        width: '98vw',
        opacity: '0.3',
      },
    },
  },
  lucky: {
    letterSpacing: '1px',
    fontSize: '20px',
    fontWeight: 800,
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  topText: {
    marginBottom: '2rem',
    '& .headerText': {
      fontSize: '35px',
    },
  },
  select: {
    width: '100%',
    marginBottom: '25px',
    '&::before, &::after': {
      content: 'unset',
    },
    '& .MuiSelect-root': {
      boxShadow: '0 1px 2px rgb(0 0 0 / 16%)',
      padding: '10px',
    },
    '& .MuiSelect-select:focus': {
      background: 'white',
    },
  },
  formControl: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    '& .textLabels': {
      marginBottom: '24px',
      fontSize: '20px',
      fontWeight: 'bold',
      color: '#676767',
      '& span': {
        color: 'red',
      },
    },
  },
  lotSizesCtn: {
    width: '100%',
    marginBottom: '24px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      gap: '15px 10px',
    },
    [theme.breakpoints.down('md')]: {
      gap: '15px 0px',
    },
  },
  radioCtn: {
    position: 'relative',
    '& .Mui-checked + label .radioLabel': {
      color: 'green',
    },
    '& input:checked + label .boxButton': {
      border: '1px solid #87c730',
      backgroundColor: '#FFF',
      '& .lotBox': {
        backgroundColor: '#87c730',
      },
      '& .checkedImg': {
        display: 'block',
        width: '25px',
        position: 'absolute',
        top: '-12px',
        right: '-12px',
        zIndex: theme.zIndex.tooltip,
      },
    },
    '& .radioLabelChecked': {
      '& .boxButton': {
        border: '1px solid #87c730',
        backgroundColor: '#FFF',
      },
      '& .boxButton .lotBox': {
        backgroundColor: '#87c730',
      },
    },
    '& .radioLabel': {
      '& .boxButton': {
        border: '1px solid rgb(0 0 0 / 16%)',
        borderRadius: '4px',
        width: '118px',
        height: '118px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'all 200ms',
        '& .lotBox': {
          backgroundColor: '#d7d7d7',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          transition: 'all 200ms',
          borderRadius: '4px',
        },
      },
      '& .MuiTypography-body1': {
        fontSize: '20px',
        fontWeight: 'bold',
        margin: '12px 0 8px 0',
        textTransform: 'uppercase',
        textAlign: 'center',
        color: '#676767',
      },
      '& .MuiTypography-body2': {
        textAlign: 'center',
        color: '#676767',
      },
      '&:hover .boxButton': {
        border: '1px solid #87c730',
        backgroundColor: '#FFF',
      },
      '&:hover .boxButton .lotBox': {
        backgroundColor: '#87c730',
      },
    },
    '& .checkedImg': {
      display: 'none',
    },
  },
  checkboxLabel: {
    '& .MuiCheckbox-root': {
      '& .MuiSvgIcon-root': {
        width: '20px',
        fontSize: '1.5rem',
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    '& .Mui-checked': {
      '& .MuiSvgIcon-root': {
        color: '#007cba',
      },
      '&:hover .MuiSvgIcon-root': {
        color: '#005cc8',
      },
    },
    '& .Mui-disabled': {
      '& .MuiSvgIcon-root': {
        color: '#aaa !important',
      },
    },
    '& .MuiTypography-root': {
      fontFamily: 'Open Sans',
    },
  },
  text: {
    margin: theme.spacing(1),
  },
  tooltip: {
    marginLeft: theme.spacing(2),
    fill: '#808080',
    fontSize: '24px',
  },
  tooltipPopper: {
    '& .MuiTooltip-tooltip': {
      backgroundColor: 'rgba(255, 255, 0, 0.5)',
      color: '#101010',
      border: '1px solid gray',
      fontSize: '16px',
      lineHeight: '18px',
      fontWeight: '700',
      padding: '10px',
    },
  },
  submitButton: {
    '& .MuiButton-root': {
      padding: '10px 23px',
      borderRadius: '50px',
      color: '#FFF',
      fontSize: '20px',
      fontWeight: 600,
      letterSpacing: '3px',
      backgroundColor: '#00652d',
      '&:hover': {
        backgroundColor: '#277d4d',
      },
    },
  },
}));

export const Step2Styles = makeStyles(() => ({
  container: {
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .MuiTypography-root': {
      fontFamily: ['Roboto Condensed', 'sans-serif'],
      color: '#444444',
      letterSpacing: '1px',
    },
  },
  quoteHeader: {
    width: '85%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '42px',
    backgroundColor: '#87c730',
    marginBottom: '24px',
    marginTop: '16px',
    '& .quotePrice': {
      color: '#fff',
      fontSize: '75px',
      fontWeight: 700,
      margin: '0',
    },
    '& .quoteFrequency': {
      color: '#00652D',
    },
    '& .exceptions': {
      margin: '16px 0',
      color: '#FF0000',
      fontWeight: 700,
    },
  },
  quoteInfo: {
    width: '85%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#ededed',
    padding: '44px',
    '& .videoCtn': {
      marginBottom: '24px',
      marginTop: '16px',
      boxShadow: '0px 2px 9px 12px rgba(0,0,0,0.1)',
      borderRadius: '12px',
    },
    '& .budget': {
      fontSize: '35px',
      marginBottom: '24px',
      marginTop: '16px',
    },
    '& .subtitles': {
      fontSize: '20px',
      fontWeight: 800,
      textTransform: 'uppercase',
      color: '#87c730',
      letterSpacing: '1px',
      marginBottom: '24px',
      marginTop: '16px',
    },
  },
  list: {
    '& .MuiListItem-root': {
      padding: '0',
    },
    '& .MuiListItemIcon-root': {
      minWidth: '38px',
    },
    '& .MuiListItemText-root': {
      '& .MuiTypography-root': {
        fontSize: '1rem',
        fontFamily: 'Open Sans',
      },
    },
  },
  selectedOptions: {
    margin: '16px 0 0 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    '& .selected': {
      color: '#FFF',
      fontSize: '2rem',
      fontWeight: 700,
    },
  },
  select: {
    margin: '12px 0',
    width: '50%',
    backgroundColor: '#FFF',
    '&::before, &::after': {
      content: 'unset',
    },
    '& .MuiSelect-root': {
      boxShadow: '0 1px 2px rgb(0 0 0 / 16%)',
      padding: '10px',
    },
    '& .MuiSelect-select:focus': {
      background: 'white',
    },
  },
  checkboxLabel: {
    '& .MuiCheckbox-root': {
      '& .MuiSvgIcon-root': {
        width: '20px',
        fontSize: '1.5rem',
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    '& .Mui-checked': {
      '& .MuiSvgIcon-root': {
        color: '#005cc8',
      },
      '&:hover .MuiSvgIcon-root': {
        color: '#007cba',
      },
    },
    '& .Mui-disabled': {
      '& .MuiSvgIcon-root': {
        color: '#aaa !important',
      },
    },
    '& .MuiTypography-root': {
      fontFamily: 'Open Sans',
      color: '#FFF',
      fontWeight: 600,
    },
  },
  services: {
    '& .MuiTypography-root': {
      fontFamily: 'Roboto Condensed',
      fontWeight: 400,
      color: '#676767',
      fontSize: '20px',
    },
  },
  button: {
    marginRight: '24px',
    marginTop: '32px',
    border: '1px solid #87c730',
    borderRadius: '50px',
    textTransform: 'uppercase',
    color: '#87c730',
    fontSize: '0.875rem',
    padding: '10px 35px',
    letterSpacing: '1px',
    fontWeight: 600,
    '&:hover': {
      color: '#FFF',
      backgroundColor: '#87c730',
    },
  },
}));
