import {Utils} from '../../helpers';
import {PickService} from '../index';

class PickServiceData extends PickService {

  getAbout(group) {
    return new Promise((resolve, reject) => {
      this.getAccessToken();
      this.list(`?filter[group_id]=${group}&sort=name`)
        .then(response => {
          const arr = Utils.updateArrayObjectItem(response.data, []);
          return resolve(arr);
        })
        .catch(error => {
          // handle error
          console.warn('Cannot retrieve user data', error);
          return reject(error);
        });
    });
  }
}

export default PickServiceData;
