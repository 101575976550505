import React, { memo, useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Grid, Modal, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { has as _has } from 'lodash';
import clsx from 'clsx';

import { WizardStyles } from '../../assets/css';
import AppButton from '../forms/AppButton';
import { AppStyle } from '../../assets/css/app/AppStyle';
import { useUI } from '../../app/context/ui';
import ServiceForm from './ServiceForm';
import { ScheduleContext } from '../bbCalendar/BBCalendar';
import BushBedService from '../../services/BushBedService';
import AppHelper from '../../helpers/AppHelper';

const bushBeshService = new BushBedService();

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const formState = {
  weekNumber: '',
  crew: '',
  weekDay: '',
  status: '',
  instruction: '',
};

const ServiceModal = (props) => {
  const { open, setOpen, bbId, weekNumber, customerId } = props;
  const [modalStyle] = useState(getModalStyle);
  const appStyle = AppStyle();
  const wizardStyle = WizardStyles();
  const { blockUI, snackbarUI } = useUI();
  const [initialValues, setInitialValues] = useState({
    ...formState,
    weekNumber
  });

  const { getDataSchedule } = useContext(ScheduleContext);

  const validationSchema = Yup.object({
    weekNumber: Yup.string().required('Required'),
    crew: Yup.string().required('Required'),
    weekDay: Yup.string().required('Required'),
    status: Yup.string().required('Required'),
  });

  const getService = async () => {
    try {
      blockUI.current.open(true);
      bushBeshService.getAccessToken();
      const response = await bushBeshService.getService(bbId);
      setInitialValues({
        ...response.data,
      });
      blockUI.current.open(false);
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const onSend = async (values) => {
    try {
      blockUI.current.open(true);
      bushBeshService.getAccessToken();
      if (bbId) {
        const response = await bushBeshService.updateService(bbId, {
          ...values,
          customerAttributeId: customerId,
        });
        setInitialValues({
          ...response.data,
        });
      } else {
      await bushBeshService.createService({
        ...values,
        customerAttributeId: customerId,
      });
      }
      getDataSchedule();
      setOpen(false);
      blockUI.current.open(false);
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  };

  useEffect(() => {
    setInitialValues({
      ...formState,
      weekNumber,
    });

    if (bbId) {
      getService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bbId, weekNumber]);

  useEffect(() => {
    if (_has(props, 'open')) {
      setOpen(props.open);
    }
  }, [props, setOpen]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
    >
      <div style={modalStyle} className={wizardStyle.paperModalFull}>
      <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => onSend(values)}
          enableReinitialize={true}
        >
          {(props) => {
            const { handleSubmit } = props;
            return (
              <Form autoComplete="off">
                <Grid container alignItems="center" spacing={0}>
                  <Grid container item xs={12}>
                    <Grid item xs={10}>
                      <h2 className={clsx(appStyle.mrg0V)}>{bbId ? 'Edit Service' : 'Create a new service'}</h2>
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton
                        color='primary'
                        aria-label='close'
                        className={clsx(appStyle.pdg0, appStyle.btnRight)}
                        onClick={handleClose}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <ServiceForm />
                  <Grid container spacing={2} style={{ marginTop: '20px' }}>
                    <Grid item xs={6}>
                      <AppButton
                        onPress={handleClose}
                        label={'Cancel'}
                        type={'main'}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <AppButton
                        onPress={handleSubmit}
                        label={'Save'}
                        type={'main'}
                        color={'primary'}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    mm: state.mm,
  };
};
export default connect(mapStateToProps)(memo(ServiceModal));
