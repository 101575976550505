import React from 'react';

import {
  CategoryScale, Chart as ChartJS, Legend, LinearScale,
  LineElement, PointElement, Title, Tooltip,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const OverWeekRevenue = (props) => {
  const data = props?.revenue;

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: 'Week over week revenue',
      },
    },
    maintainAspectRatio: true,
    aspectRatio: 1,
  };

  const custom_canvas_background_color = {
    id: 'custom_canvas_background_color',
    beforeDraw: (chart) => {
      const ctx = chart.canvas.getContext('2d');
      ctx.save();
      ctx.globalCompositeOperation = 'destination-over';
      ctx.fillStyle = '#f1f1f1';
      ctx.fillRect(0, 0, chart.width, chart.height);
      ctx.restore();
    },
  };

  const plugins = [custom_canvas_background_color];

  return (
    <div>
      <Line options={options} data={data} plugins={plugins} className='lineChart' />
    </div>
  );
};

export default OverWeekRevenue;
