import { makeStyles } from '@material-ui/core/styles';

export const NotificationsStyle = makeStyles((theme) => ({
  root:{
    color: 'white !important',
    width: '100%',
    background: 'black !important',
    borderRadius: '7px',
    height: '62px',
    '& .Mui-selected': {
      color: '#20ff5a',
    },
    '& .MuiButtonBase-root':{
      color: 'white'
    }
  },
  paperModal: {
    position: 'absolute',
    width: 900,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(6),
    outline: 'none',
    height: '700px',
    overflowX: 'hidden',
    overflowY: 'scroll',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  titleAlarm: {
    fontSize: '34px',
    fontWeight: 'bold',
    marginBottom: '21px',
  },
  wrapperList: {
    height: '300px',
    overflowX: 'hidden',
    overflowY: 'scroll',
    width: '100%',
    '& .title': {
      fontSize: '17px',
      marginBottom: '17px'
    },
    '& .rows': {
      marginBottom: '20px',
    },
    '& .content': {
      border: 'solid 1px #8080804d',
      borderRadius: '8px',
      padding: '7px 16px',
      cursor: 'pointer',
      '& .titleDate': {
        fontSize: '11px',
        fontWeight: 'bold',
        paddingRight: '30px',
        fontFamily: 'Roboto',
      },
      '& .titleHour': {
        fontSize: '11px',
        fontWeight: 'bold'
      },
      '& .fullName': {
        fontSize: '12px',
        fontWeight: 'bold'
      },
      '& .detail': {
        fontSize: '14px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      }
    }
  },
  checkboxStatus: {
    paddingTop: '15px',
    paddingLeft: '0px'
  },
  wrapperButtonsAlarm: {
    paddingTop: '41px',
    '& .buttonFirst': {
      loat: 'left',
      marginRight: '17px',
    }
  },
  buttonActions: {
    float: 'left',
    marginRight: '18px',
  },
  wrapperNotAlarmsList: {
    border: 'solid 0.5px #80808052',
    padding: '17px 0px',
    borderRadius: '8px',
    textAlign: 'center',
    fontSize: '14px',
    color: '#808080bf',
  },
  paperModal_sm: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(6),
    outline: 'none',
    '& .wrapperHourDate': {
      marginBottom: '10px',
      fontWeight: 'bold',
      '& .date': {
        marginRight: '36px'
      }
    },
    '& .name': {
      marginBottom: '10px',
      fontWeight: 'bold',
    },
    '& .detail': {
      textAlign: 'justify',
      marginBottom: '10px',
    },
    '& .titleSnooze': {
      float: 'left',
      marginRight: '37px',
      paddingTop: '11px',
    },
    '& .wrapperButtonAction': {
      paddingLeft: '55px',
      marginTop: '17px',
      '& .buttonClose':{
        float: 'left',
        marginRight: '18px',
        backgroundColor: '#dadadac4 !important',
        color: 'black !important',
        fontWeight: 'bold',
        width: '94px !important'
      }
    }
  },
}));
