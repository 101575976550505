import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Grid, Modal } from '@material-ui/core';
import { NotificationsStyle } from '../../styles/NotificationsStyle';
import { useSelector } from 'react-redux';
import CreateUpdateAlarm from './CreateUpdateAlarm';
import AlarmDelete from './AlarmDelete';
import AlarmAddIcon from '@material-ui/icons/AlarmAdd';
import EventIcon from '@material-ui/icons/Event';
import { useUI } from '../../../../app/context/ui';

const AlarmList = (props) => {

  const {
    open,
    setOpen,
    handleRemoveAlarm,
    setOpenAlarmView,
    setdataAlarmDetail,
    handleUpdateDataAlarm,
    handleAddedAlarm,
  } = props;
  const notificationsStyle = NotificationsStyle();
  const alarmsAvailable = useSelector(({mm}) => mm.alarm) || [];
  const [statusAlarm, setStatusAlarm] = React.useState([]);
  const [activeFormAlarm, setActiveFormAlarm] = useState(false);
  const [alarmUpdate, setAlarmUpdate] = useState({});
  const { dialogUI } = useUI();


  const handleChangeStatusUpdateAlarm = (event, pos) => {
    let newStatus = statusAlarm.map((e,index)=>{
      if(index === pos){
          return {active: event.target.checked}
        }else{
          return {...e}
        }
      });
    setStatusAlarm(newStatus);
  };

  const customStatusAlarm = () => {
    const statusAlarm = alarmsAvailable.options.map(()=>({
      active: false
    }));
    setStatusAlarm(statusAlarm);
  }

  const handleViewDetail = (alarm) => {
    setdataAlarmDetail(alarm);
    setOpenAlarmView(true);
  }

  useEffect(() => {
    customStatusAlarm();
    setAlarmUpdate({});
    setActiveFormAlarm(false);
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableEscapeKeyDown={true}
    >
      <div className={notificationsStyle.paperModal}
           style={(!activeFormAlarm) ? {height:'550px'} : {height:'700px'}}
      >
        <div className={notificationsStyle.titleAlarm}>Alarms</div>
        <div className={notificationsStyle.wrapperList}
             style={(alarmsAvailable.options.length === 2)
              ? {height: '200px'} : (alarmsAvailable.options.length === 1 )
              ? {height: '100px'} : (alarmsAvailable.options.length === 0 )
              ? {height: '100px'} : {height: '300px'}}
          >
          <div className='title'>Alarms:</div>
          {
            (alarmsAvailable.options.length > 0)
              ?
                alarmsAvailable.options.map((alarm, index)=>(
                  <div key={`alarm_${alarm.id}`} className='rows'>
                    <Grid container>
                      <Grid item xs={1}>
                        <Checkbox
                          className={notificationsStyle.checkboxStatus}
                          checked={statusAlarm[index]?.active}
                          onChange={(e)=>{handleChangeStatusUpdateAlarm(e, index, alarm.id)}}
                        />
                      </Grid>
                      <Grid xs={11} className='content' onClick={()=>{handleViewDetail(alarm)}}>
                        <div>
                            <span className='titleDate'>{alarm.alarmDate}</span>
                            <span className='titleHour'>{alarm.alarmHour}</span>
                        </div>
                        <div className='fullName'>{alarm.users.fullName}</div>
                        <div className='detail'>{alarm.detail}</div>
                      </Grid>
                    </Grid>
                  </div>
                ))
              :
                (
                  <div className={notificationsStyle.wrapperNotAlarmsList}>
                    No alarms available
                  </div>
                )
          }
        </div>
        <div className={notificationsStyle.wrapperButtonsAlarm}>
          <Button
            variant='contained'
            color='primary'
            size='small'
            startIcon={<AlarmAddIcon/>}
            onClick={() => {
              setAlarmUpdate({});
              setActiveFormAlarm(true);
            }}
            className={notificationsStyle.buttonActions}
            style={{backgroundColor:'#10AA39'}}
          >
            Create a new alarm
          </Button>
          <AlarmDelete
            alarmsDelete={statusAlarm}
            customStatusAlarm={customStatusAlarm}
            setOpenModal={setOpen}
            handleRemoveAlarm={handleRemoveAlarm}/>
          <Button
            variant='contained'
            color='primary'
            size='small'
            startIcon={<EventIcon/>}
            onClick={() => {
              let num = 0;
              let index = 0;
              statusAlarm.map((e, i)=>{
                if(e.active === true){
                  num++;
                  index = i;
                }
              });
              if(num <1 || num > 1){
                setStatusAlarm([]);
                dialogUI.current.open('oops', 'Please select only one alarm.');
                customStatusAlarm();
                setAlarmUpdate({});
                setActiveFormAlarm(false);
              }else{
                setAlarmUpdate(alarmsAvailable.options[index]);
                setActiveFormAlarm(true);
              }


            }}
            className={notificationsStyle.buttonActions}
            style={{backgroundColor:'black'}}
          >
            Reschedule
          </Button>
        </div>

        {
          (activeFormAlarm)
            &&
              <CreateUpdateAlarm
                alarm={alarmUpdate}
                setActiveFormAlarm={setActiveFormAlarm}
                handleUpdateDataAlarm={handleUpdateDataAlarm}
                handleAddedAlarm={handleAddedAlarm}
              />
        }
      </div>
    </Modal>
  )
}

export default AlarmList;
