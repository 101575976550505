import React, { useRef, useState } from 'react';

import clsx from 'clsx';
import { Form, Formik } from 'formik';
import { Prompt, useHistory } from 'react-router-dom';

import {
  Box, Button, CircularProgress, Container,
  Grid, IconButton, Modal, Step,
  StepConnector, StepLabel, Stepper, Typography,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import FolderOpenOutlinedIcon from '@material-ui/icons/FolderOpenOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';

import { useUI } from '../../app/context/ui';
import { LoginStyles, WizardStyles } from '../../assets/css';
import { Utils } from '../../helpers';
import customerForm from '../../models/customerForm';
import formInitialValues from '../../models/formInitialValues';
import validationSchema from '../../models/validationSchema';
import IconUI from '../../navigation/NavaIcon';
import store from '../../redux/store';
import CustomerService from '../../services/CustomerService';
import Step1 from './wizard/Step1';
import Step2 from './wizard/Step2';
import Step3 from './wizard/Step3';
import Step4 from './wizard/Step4';
import Step5 from './wizard/Step5';
import Step6 from './wizard/Step6';
import { connect } from 'react-redux';
import { srvFrecuencysDb, weekDays } from '../../models/db';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: '#fff',
    padding: theme.spacing(2),
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(11,12,14,1) 0%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(11,12,14,1) 0%)',
    },
  },
  line: {
    height: 8,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 45,
    height: 48,
    display: 'flex',
    borderRadius: '4px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(11,12,14,1) 35%)',
    boxShadow: '0 4px 10px 0 #E8E8E8',
  },
  completed: {
    backgroundImage: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(11,12,14,1) 35%)',
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <IconUI icon='peopleAlt' />,
    2: <LocationOnOutlinedIcon />,
    3: <FolderOpenOutlinedIcon />,
    4: <PermIdentityOutlinedIcon />,
    5: <EditOutlinedIcon />,
    6: <CreditCardIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

const steps = ['Service area', 'Your location', 'Your price', 'Customer info', 'Notes', 'Card info'];
const { formId, formField } = customerForm;

function _renderStepContent(step, formikRef, handleNext) {
  switch (step) {
    case 0:
      return <Step1 formField={formField} formikRef={formikRef} />;
    case 1:
      return <Step2 formField={formField} formikRef={formikRef} />;
    case 2:
      return <Step3 formField={formField} formikRef={formikRef} />;
    case 3:
      return <Step4 formField={formField} formikRef={formikRef} handleNext={handleNext} />;
    case 4:
      return <Step5 formField={formField} formikRef={formikRef} />;
    case 5:
      return <Step6 formField={formField} formikRef={formikRef} />;
    default:
      return <div>Not Found</div>;
  }
}

const CreateCustomer = () => {
  const classes = WizardStyles();
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchema[activeStep];
  const formikRef = useRef();
  const isLastStep = activeStep === steps.length - 1;

  const [modalStyle] = useState(Utils.getModalStyle);

  const [, setHasError] = useState({});
  const [, setRequestFailed] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [openSave, setOpenSave] = useState(false);
  const [saveCustomer, setSaveCustomer] = useState(false);

  const { blockUI } = useUI();
  const styles = useStyles();
  const loginStyle = LoginStyles();
  const wizardStyle = WizardStyles();
  const customerService = new CustomerService();

  const history = useHistory();
  const state = store.getState();
  const accessToken = state.user.accessToken;
  if (!accessToken) {
    history.push('/login');
  }

  function _sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function _submitForm() {
    await _sleep(1000);
    // alert(JSON.stringify(values, null, 2));
    // actions.setSubmitting(false);
    setSaveCustomer(true);
    setActiveStep(activeStep + 1);
  }

  const _handleSubmit = async (values, actions) => {
    if (isLastStep) {
      // values.serviceDate = new Date(values.serviceDate).toISOString().split('T')[0];
      const dataCustomerResponse = await createCustomers(values);
      // onSubmit(values);
      setInitialValues({
        ...values,
        accountNumber: dataCustomerResponse.accountNumber || '_'
      });
      actions.setSubmitting(false);
      handleOpenSave();
      return 0;
      // _submitForm(values, actions);
    } else {
      setSaveCustomer(false);
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  function _handleNext(step = 1) {
    setActiveStep(step);
  }

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleOpenSave = () => {
    setOpenSave(true);
  };

  const handleCloseSave = () => {
    setOpenSave(false);
    _submitForm(initialValues);
  };

  const createCustomers = async (values) => {
    try {
      blockUI.current.open(true);
      setRequestFailed(false);
      customerService.init(accessToken);
      if(values.billingPoBox != ""){
        values.poBoxEnabled = true;
      }
      const serviceAddress = values.serviceAddress.substring(0, 50);
      const { data } = await customerService.create({...values, serviceAddress});
      blockUI.current.open(false);
      return data;
    } catch (e) {
      blockUI.current.open(false);
      setRequestFailed(true);
      if (!e.response.data.errors) {
        setHasError({ message: e.response.data.message });
      }
    }
  };

  const renderField = (label, value, key) => {
    return value ? (
        <Grid item md={6} xs={12} key={key}>
            <strong>{label}</strong> {value}
        </Grid>
    ) : null;
  };

  const bodySave = () => {
    const {
      billingAddress,
      zipcode,
      cityName,
      state,
      serviceDate,
      weekDay,
      srvFrecuency,
      lotSize,
      turfSize,
      mowCut,
      communityCode,
      gateCode,
      billingName,
      paymentMethods,
      totalMowing,
      marketName,
      specialRequest,
      specialReq,
      accountNumber,
      firstName,
      lastName,
    } = initialValues;

    const nameWeekDay = weekDays.find(val => val.value === weekDay);
    const nameServiceFrequency = srvFrecuencysDb.find(val => val.value === srvFrecuency);
    const namePaymentType = paymentMethods === 2 ? 'E-Check' : 'Credit Card';
    const fullName = `${firstName} ${lastName}`;

    const fields = [
        { label: 'Full Name:', value: fullName},
        { label: 'Address:', value: billingAddress },
        { label: 'Zip:', value: zipcode },
        { label: 'City:', value: cityName },
        { label: 'State:', value: state },
        { label: 'Market:', value: marketName },
        { label: 'Start Date:', value: serviceDate },
        { label: 'Mow Day:', value: nameWeekDay?.label },
        { label: 'Frequency:', value: nameServiceFrequency?.label },
        { label: 'Mow Area:', value: mowCut?.join(', ') },
        { label: 'Billing Name:', value: billingName },
        { label: 'Payment Type:', value: namePaymentType },
        { label: 'Price:', value: totalMowing },
        { label: 'Account Number:', value: accountNumber },
        { label: 'Lot Size:', value: lotSize },
        { label: 'Turf Size:', value: turfSize },
        { label: 'Community Code:', value: communityCode },
        { label: 'Gate Code:', value: gateCode },
        { label: 'Special Request:', value: specialRequest },
        { label: '', value: specialReq },
    ];

    return (
      <div style={modalStyle} className={wizardStyle.paperModal}>
        <Grid container spacing={1}>
          <Grid item xs={11}>
            <h2 id='simple-modal-title'>Great!</h2>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              aria-label='close'
              onClick={handleCloseSave}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <p id='simple-modal-description'>The customer account was created successfully.</p>
            <Grid container spacing={2}>
              {fields.map((field, index) => (
                  renderField(field.label, field.value, index)
              ))}
            </Grid>
            <div>
              <Box pb={4} />
            </div>
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              onClick={handleCloseSave}
              className={loginStyle.btnSubmit}
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </div>
    )
  };

  return (
    <Container maxWidth='lg'>
      <Typography variant='h4'>Create new customer</Typography>
      <span>Pending tasks</span>
      <Prompt
        message={(location, action) => {
          let back = true;
          if (action === 'POP') {
            // Add your back logic here
            if (activeStep > 0 && activeStep < 6) {
              _handleBack();
              back = false;
            }
          }
          return back;
        }}
      />
      <Stepper alternativeLabel activeStep={activeStep} className={classes.stepper} connector={<ColorlibConnector />}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className={styles.root}>
        {activeStep === steps.length ? (
          <div>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {!saveCustomer && <Typography>The customer was not registered</Typography>}
                {saveCustomer && <Typography>The customer has been created successfully</Typography>}
              </Grid>
              <Grid item xs={2}>
                <Button
                  onClick={() => {
                    history.push('customer', { id: 0 });
                  }}
                  variant='contained'
                  color='primary'
                  className={classes.button}
                >
                  CUSTOMER LIST
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button onClick={handleReset} variant='contained' color='primary' className={classes.button}>
                  NEW CUSTOMER
                </Button>
              </Grid>
            </Grid>
          </div>
        ) : (
          <Formik
            innerRef={formikRef}
            initialValues={formInitialValues}
            validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}
            enableReinitialize={true}
          >
            {({ isSubmitting }) => (
              <Form id={formId}>
                {_renderStepContent(activeStep, formikRef, _handleNext)}

                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <Button onClick={_handleBack} className={classes.button}>
                      Back
                    </Button>
                  )}
                  <div className={classes.wrapper}>
                    <Button
                      disabled={isSubmitting}
                      type='submit'
                      variant='contained'
                      color='primary'
                      className={classes.button}
                    >
                      {isLastStep ? 'Save' : 'Next'}
                    </Button>
                    {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
      <div>
        <Modal
          open={openSave}
          onClose={() => {
            setOpenSave(false);
          }}
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'
        >
          {bodySave()}
        </Modal>
      </div>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(CreateCustomer);
