import React, { useState } from 'react';
import { WeekStyle } from './styles/WeekStyle';

import MenuService from './service/MenuService';
import { useUI } from '../../app/context/ui';
import { CustomerService } from '../../services';
import { useSelector } from 'react-redux';
import AppHelper from '../../helpers/AppHelper';

const customerService = new CustomerService();

const Week = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState();
  const { id, weekDay } = useSelector( ({mm}) => mm.customer.options);
  const { blockUI, snackbarUI } = useUI();
  const [weekSelect, setWeekSelect] = useState([]);
  const {dataRender, type} = props;
  const classes = WeekStyle();
  const handleRenderInfoWeek = (customer) => {
    if (customer.active) {
      props.setActivityDay(customer.workday);
    }
  };

  const onAddService = async (slot) => {
    try {
      blockUI.current.open(true);
      customerService.getAccessToken();
      await customerService.attributes({
        ...slot,
        status: "created",
        year: props.year,
        customer: {
          ...slot.customer,
          weekday: weekDay,
        }
      }, id);
      props.getDataSchedule();
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const renderSlot = (slot) => {
    let backgroundColor = slot.current.active
      ? slot.current.color
      : slot.holidays.length > 0
        ? slot.color
        : slot.winter.active
          ? slot.winter.color
          : slot.color;
    const cursor = 'pointer';
    let backgroundBaseColor = slot.holidays.length > 0 ? slot.color : backgroundColor;
    const bottomColor = slot.customer.color;

    return (
      <div
        className={classes.ctnBlockWeek}
        style={props.type === "external" ? {cursor: 'auto'} : {cursor}}
        key={`week${slot.week}`}
        onClick={(event) => {
          if(props.type !== "external"){
            setWeekSelect(slot);
            handleRenderInfoWeek(slot.customer);
            if (slot.customer.active) {
              setMenuOpen(true);
              setAnchorEl(event.currentTarget);
            }
            if (!slot.customer.active) {
              onAddService(slot);
            }
          }
        }}
      >
        <div className='div1' style={{backgroundColor}}>
          {slot.start}
        </div>
        <div className='div2' style={{backgroundColor}}>
          {slot.end}
        </div>
        <div className='div3' style={{backgroundColor: backgroundBaseColor}}>
          {(slot.customer.active || slot.todo?.active) ?
            <div className={classes.ctnCustomerActive} style={{ borderBottom: `solid 6px ${bottomColor}`, }}>{slot.week}</div>
            :
            <div className={classes.ctnCustomerActive} style={{ borderBottom: `solid 6px white`, }}>{slot.week}</div>
          }
        </div>
      </div>
    );
  };

  return <div>{dataRender.map((e) => renderSlot(e))}
    {type !== 'external' && (
      <MenuService
      weekSelect={weekSelect}
      setMenuOpen={setMenuOpen}
      menuOpen={menuOpen}
      anchorEl={anchorEl}
      year={props.year}
      dataRender={props.dataRender}
      setDataRender={props.setDataRender}
      getDataSchedule={props.getDataSchedule}
    />)}
  </div>;
};

export default Week;
