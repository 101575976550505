import React, { useState } from 'react';

import {
  Container, Paper, Tab, Tabs,
  Typography,
} from '../../components/shared/MaterialUI';
import { RenderIf } from '../../helpers/RenderIf';
import Lookup from './components/Lookup';
import ListLookup from './components/ListLookup';
import { useStyles } from './style/panel-style';

const LookupPanel = () => {
  const [tabValue, setTabValue] = useState(0);
  const classes = useStyles();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Container maxWidth='lg' disableGutters style={{ padding: '26px' }}>
      <Typography className={classes.titleLookup}>
        Lookup
      </Typography>
      <Paper elevation={3} component='section' style={{ padding: '20px 45px 104px' }}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label='search tabs' className={classes.tabs}>
          <Tab disableRipple value={0} label='Market information' />
          <Tab disableRipple value={1} label='Saved search lists' />
        </Tabs>
        <RenderIf isTrue={tabValue == 0}>
          <Lookup setTabValue={setTabValue}/>
        </RenderIf>
        <RenderIf isTrue={tabValue == 1}>
          <ListLookup />
        </RenderIf>
      </Paper>
    </Container>
  );
};

export default LookupPanel;
