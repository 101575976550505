import React, { useEffect, useState } from 'react';
import { Box, Button, Checkbox, Container, Grid, TextField, Tooltip, Typography } from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { CreateUserStyles } from '../../../../assets/css';
import { AlarmsStyle } from './AlarmsStyle';
import { useUI } from '../../../../app/context/ui';
import { AlarmService } from '../../../../services';
import DatePickerField from '../../../../forms/DatePickerField';
import { InputField, SelectField } from '../../../../forms';
import { connect, useSelector } from 'react-redux';
import InfoIcon from '@material-ui/icons/Info';
import { alarmsDurationDb } from '../../../../models/db';

const CreateUpdateAlarm = (props) => {

  const {
    alarm,
    setActiveFormAlarm,
    user,
    handleUpdateDataAlarm,
    handleAddedAlarm
  } = props;
  const alarmsStyle = AlarmsStyle();
  const userStyle = CreateUserStyles();
  const baseValues = {
    alarmDate: new Date().toLocaleDateString(),
    alarmHour: '',
    duration: '',
    snooze: false,
    regarding: '',
    detail: '',
  };
  const alarmsAvailable = useSelector(({mm}) => mm.alarm) || {date: null, options: []};


  const [initialValues, setInitialValues] = useState(baseValues);
  const { blockUI, dialogUI } = useUI();
  const alarmService = new AlarmService();

  const validationSchema = Yup.object({
    alarmDate: Yup
      .string()
      .required('Date is required'),
    alarmHour: Yup
      .string()
      .required('Hour is required'),
    duration: Yup
      .string('Select your role')
      .required('Duration is required'),
    snooze: Yup
      .string()
      .required('Snooze is required'),
    regarding: Yup
      .string()
      .required('Regarding is required')
  });

  const onSubmit = async (values) => {
    try {
      blockUI.current.open(true);
      alarmService.getAccessToken();
      if(alarm.id){
        let alarmsUpdated = alarmsAvailable.options.map((e)=>{
          if(e.id == values.id){
            return {
              ...values
            }
          }else{
            return { ...e }
          }
        });
        await handleUpdateDataAlarm(values, values.id, alarmsUpdated);
      }else{
        await handleAddedAlarm(values);
      }
      blockUI.current.open(false);
      setActiveFormAlarm(false);
      dialogUI.current.open('', 'Saved successfully');
    } catch (e) {
      blockUI.current.open(false);
    }
  }

  useEffect(() => {
    if(alarm.id){
      setInitialValues(alarm);
    }else{
      setInitialValues(baseValues);
    }
  }, [alarm]);

  return (
    <Container component="main" className={alarmsStyle.containerCreateAlarm}>
      <Typography className="title">{alarm.id ? 'Edit alarm:' : 'Create a new alarm:'}</Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            handleChange,
          } = props;
          return(
            <Form autoComplete="off">
              <Grid container spacing={3} className='wrapperForm'>

                <Grid item xs={2} className={userStyle.grdItem}>
                  <label>Start date:</label>
                </Grid>
                <Grid item xs={3}>
                  <DatePickerField
                    disableToolbar
                    name='alarmDate'
                    value={values.alarmDate}
                    onChange={handleChange}
                    format='MM/dd/yyyy'
                    maxDate={new Date('2050/12/31')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={2} className={userStyle.grdItem}>
                  <label>Time:</label>
                </Grid>
                <Grid item xs={3}>
                  <InputField
                    type='time'
                    name='alarmHour'
                    value={values.alarmHour}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={2} className={userStyle.grdItem}>
                  <label>Duration:</label>
                </Grid>
                <Grid item xs={3} className={userStyle.grdItem}>
                  <SelectField
                    name='duration'
                    data={alarmsDurationDb}
                    placeholder={'-- Select --'}
                    autoFocus
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={2} className={userStyle.grdItem}>
                  <label>Add user:</label>
                </Grid>
                <Grid item xs={3} className={userStyle.grdItem}>
                  <TextField
                    fullWidth
                    value={user?.fullName}
                    disabled={true}
                    className={userStyle.inputCustom}
                  />
                </Grid>

                <Grid item xs={1} className={userStyle.grdItem}>
                  <label>Snooze:</label>
                </Grid>
                <Grid item xs={1} className={userStyle.grdItem}>
                  <Tooltip title={ `When selected, the alarm, it will sound again after ${values.duration || '___'} minutes` } placement="top">
                    <InfoIcon className='infoIcon'/>
                  </Tooltip>
                </Grid>
                <Grid item xs={3}>
                  <Checkbox
                    checked={values.snooze}
                    onChange={handleChange}
                    name="snooze"
                  />
                </Grid>

                <Grid item xs={12} className='regarding'>
                  Regarding:
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name='regarding'
                    value={values.regarding}
                    onChange={handleChange}
                    multiline
                    minRows={2}
                    variant='outlined'
                    style={{ width: '100%' }}
                    fullWidth={true}
                    placeholder="Write here"
                    error={touched.regarding && Boolean(errors.regarding)}
                    helperText={
                      errors.regarding && touched.regarding ? errors.regarding : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} className='regarding'>
                  Details:
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name='detail'
                    value={values.detail}
                    onChange={handleChange}
                    multiline
                    minRows={2}
                    variant='outlined'
                    style={{ width: '100%' }}
                    fullWidth={true}
                    placeholder="Write here"
                  />
                </Grid>
              </Grid>
              <Box pb={8}/>
              <Grid container justify="center">
                <Button
                  variant="contained"
                  size="large"
                  className={userStyle.button}
                  onClick={() => { setActiveFormAlarm(false) }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  className={userStyle.button}
                >
                  Save
                </Button>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(CreateUpdateAlarm);
