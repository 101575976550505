import AppService from './AppService';

class LocationWebService extends AppService {

  constructor() {
    super();
    this.path = this.getPath('location');
  }

  list(params = '') {
    const queryParams = params ? params : '';
    return this.http.get(`${this.path}${queryParams}`);
  }

}

export default LocationWebService;
