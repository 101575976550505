import { Divider, Grid, InputLabel } from '@material-ui/core';

import React, { useCallback, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { findIndex as _findIndex, concat as _concat, isUndefined as _isUndefined } from 'lodash';

import { SelectField } from '../../../forms';
import { Box } from '../../shared/MaterialUI';
import { WizardStyles } from '../../../assets/css';
import { HelperService } from '../../../services';
import AppHelper from '../../../helpers/AppHelper';
import { useUI } from '../../../app/context/ui';
import FieldList from './FieldList';

const scheduledDb = [
  {
    value: 1,
    label: 'Details',
    field: '',
    tag: ''
  }
];

const fieldDb = [
  {
    value: 1,
    label: 'Year',
    field: 'work_weeks.year',
    tag: 'drop_down',
    data: []
  },
  {
    value: 2,
    label: 'Weeks',
    field: 'work_weeks.weeks',
    tag: 'drop_down',
    data: []
  }
];

const helperService = new HelperService();

const Lookup2 = () => {
  const {setFieldValue, values} = useFormikContext();
  const [field, setField] = useState(fieldDb);
  const [year, setYear] = useState(0);

  const {blockUI, snackbarUI} = useUI();

  const wizardStyle = WizardStyles();
  const handleChangeBasic = (event) => {
    const value = event.target.value;
    if (value === 1) {
      setFieldValue('fields', field);
    }
  };

  const getListHelperWeeks = useCallback(async (date) => {
    try {
      blockUI.current.open(true);
      helperService.getAccessToken();
      const r1 = await helperService.weeks(date);
      const arrayObj = r1.data.map(item => {
        return {
          value: item.week,
          label: _concat(item.week, ' (', item.end, ' - ', item.start, ')')
        };
      });
      blockUI.current.open(false);
      const index = _findIndex(field, {'field': 'work_weeks.weeks'});
      setFieldValue(`fields[` + index + `]['name']`, '');
      setFieldValue(`fields[` + index + `]['data']`, arrayObj);
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  }, [blockUI, snackbarUI, field, setFieldValue]);

  const getListYears = useCallback(() => {
    const today = new Date();
    const year = today.getFullYear();
    const arr = [
      {value: null, label: '-- Select --'},
      {value: year - 1, label: year - 1},
      {value: year, label: year},
      {value: year + 1, label: year + 1},
    ];
    const index = _findIndex(field, {'field': 'work_weeks.year'});
    fieldDb[index]['data'] = arr;
    setField(fieldDb);
  }, [field]);

  useEffect(() => {
    setFieldValue('searchBasic', '');
    setFieldValue('fields', []);
  }, [setFieldValue]);

  useEffect(() => {
    (async function init() {
      await getListYears();
    })();
  }, [getListYears]);

  useEffect(() => {
    const arr = values.fields || [];
    const index = _findIndex(arr, {'field': 'work_weeks.year'});
    if (!_isUndefined(arr[index])) {
      if (!_isUndefined(arr[index]['name'])) {
        if (year !== arr[index]['name']) {
          setYear(arr[index]['name']);
          (async function init() {
            await getListHelperWeeks(arr[index]['name']);
          })();
        }
      }
    }
  }, [values.fields, year, getListHelperWeeks]);

  return (
    <div style={{width: '100%'}}>
      <Divider/>
      <Box my={2}/>
      <Grid container alignItems="center" spacing={2}>
        <Grid container item xs={12}>
          <SelectField
            name="searchBasic"
            data={scheduledDb}
            onClick={handleChangeBasic}
            placeholder={'-- Select --'}
            autoFocus
            fullWidth
          />
        </Grid>
        <Grid container item xs={12}>
          <InputLabel className={wizardStyle.title}>Search:</InputLabel>
        </Grid>
        <Grid container item xs={12}>
          <Box mt={2}/>
          <Divider/>
          <FieldList/>
        </Grid>
      </Grid>
    </div>
  );
}

export default Lookup2;
