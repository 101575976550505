import React, {useState, useRef, useEffect, useCallback, useContext} from 'react';

import {useUI} from '../../app/context/ui';
import AppHelper from '../../helpers/AppHelper';
import Header from './Header';
import WeeksOfYear from './WeeksOfYear';
import {ScheduleManagementContext} from '../../pages/schedule/ScheduleManagement';
import {CalendarService} from '../../services';
import Footer from './Footer';

const calendarService = new CalendarService();

const Schedule = () => {
  const {year, setYear, setWeekRegular, setUpdateWeeks, updateWeeks} = useContext(ScheduleManagementContext);

  const [dataRender, setDataRender] = useState([]);
  const [dataStatus, setDataStatus] = useState({
    'year': '',
    'total': 0,
    'progress': 0,
    'percentage': 0,
    'isProgress': false,
    'status': ''
  });
  const [rangeDate, setRangeDate] = useState('all');
  const [checkedYear, setCheckedYear] = useState(true);

  const yearReaf = useRef(year);

  const {blockUI, dialogUI, snackbarUI} = useUI();
  calendarService.getAccessToken();

  const onNextYear = () => {
    setYear(++yearReaf.current);
  };

  const onPrevYear = () => {
    setYear(--yearReaf.current);
  };

  const getDataSchedule = useCallback(async () => {
    try {
      blockUI.current.open(true);
      setWeekRegular(0);
      setUpdateWeeks([]);

      const {data: calendar} = await calendarService.view(year, rangeDate);
      if (rangeDate === 'winter') {
        let season = [];
        for (let i in calendar.data) {
          season = [...season, calendar.data[i]];
        }
        setDataRender(season);
      } else {
        setDataRender(calendar.data);
        calendar.data.map((e) => {
          (!e.winter.active &&  e.holidays.length === 0) && setWeekRegular(e.week);
        });
      }
      blockUI.current.open(false);
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
      blockUI.current.open(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockUI, snackbarUI, year, rangeDate]);

  const getDataStatus = useCallback(async () => {
    try {
      blockUI.current.open(true);
      const {data: status} = await calendarService.status(year);
      setDataStatus(status);
      blockUI.current.open(false);
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
      blockUI.current.open(false);
    }
  }, [blockUI, snackbarUI, year]);

  const onDataSeasonWeeks = useCallback(async () => {
    try {
      dialogUI.current.close();
      blockUI.current.open(true);
      await calendarService.updateSeasonWeeks(year, updateWeeks);
      setUpdateWeeks([]);
      blockUI.current.open(false);
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
      blockUI.current.open(false);
    }
  }, [blockUI, snackbarUI, dialogUI, year, setUpdateWeeks, updateWeeks]);


  useEffect(() => {
    (async function init() {
      await getDataSchedule();
    })();
  }, [year, rangeDate, getDataSchedule]);

  useEffect(() => {
    (async function init() {
      await getDataStatus();
    })();
  }, [year, updateWeeks, getDataStatus]);

  return (
    <>
      <Header
        year={year}
        onNextYear={onNextYear}
        onPrevYear={onPrevYear}
        setRangeDate={setRangeDate}
        rangeDate={rangeDate}
        setDataRender={setDataRender}
        checkedYear={checkedYear}
        setCheckedYear={setCheckedYear}
      />
      <WeeksOfYear
        dataRender={dataRender}
        setDataRender={setDataRender}
        getDataSchedule={getDataSchedule}
        year={year}
        type="schedule"
      />
      <Footer
        dataStatus={dataStatus}
        updateWeeks={updateWeeks}
        getDataStatus={getDataStatus}
        onDataSeasonWeeks={onDataSeasonWeeks}
      />
    </>
  );
};
export default Schedule;
