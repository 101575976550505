import customerForm from './customerForm';
const {
  formField: {
    about, // Step1
    aboutOption,
    useServiceHousesScheduled,
    useServiceHousesOccupied,
    zipcode,
    zipcodeValid,
    city, // Step2
    property,
    state,
    serviceAddress,
    owner,
    csr,
    market,
    lotSize,
    taxRate,
    useCornerLot,
    aboutOld,
    costService, // Step3
    billingLotSize,
    reasonSize,
    reasonEdit,
    srvFrecuency,
    grassOvergrown,
    mowCut,
    calculatedTax,
    totalMowing,
    subtotalMowing,
    specialReq,
    firstName, // Step 4
    lastName,
    email,
    contactNumber,
    isCellphone,
    serviceDate,
    isCommunityGate,
    communityAnswer,
    isBackyardGate,
    backyardAnswer,
    isFlowerBeds,
    flowerAnswer,
    isPetsBackyard,
    isFencePlastic,
    isAboveGroundPool,
    isTranpoline,
    role,
    password,
    privateNotes, // Step 5
    privateNotesDefault,
    specialRequest,
    speReqNotesDefault,
    bbNotes,
    cardNumber, // Step 6
    cardNumber1,
    cardNumber2,
    cardNumber3,
    cardMonth,
    cardYear,
    nameOnCard,
    cardType,
    isBillingAddress,
    billingAddress,
    billingCity,
    billingState,
    billingZipCode,
    billingName,
    isMailingAddress,
    mailingName,
    mailingAddress,
    mailingCity,
    mailingState,
    mailingZipCode,
    isAddressBillingService,
    isCommercial,
    country,
    useAddressForPaymentDetails,
    week, // Step 1 - Edit customer
    weekDay,
    communityCode, // Step 3
    crew,
    mapscoNumber,
    mapsco,
    mapA,
    mapscoGrid,
    gateCode,
    expiryDate,
    isRestart, // Step 0 edit customer
    isApproved,
    isTranslation,
    cvv,
    joinDate,
    phoneMarket, // Step General
    turfSize, // Step 3 Edit
    lotReview,
    optReviews, // General Tag
    sendSchedule,
    futureCancel,
    conditionSpanish,
    requestSpanish,
    weekNumberBb,
    crewBb,
    weekDayBb,
    statusBb,
    instructionBb,
    billingNoteDate,
    billingNoteReason,
    billingNoteCompleted,
    billingNoteDetail,
    accountNumber,
    skipsCompleted,
    skipsServiceDay,
    skipsUserId,
    skipsReason,
    writeCompleted,
    writeServiceDay,
    writeUserId,
    writeReason,
    redoCompleted,
    redoServiceDay,
    redoUserId,
    redoReason,
    swrInstruction,
    bankAccount,
    bankRouting,
    billingNameBank,
    paymentMethods,
    winterScheduleStatus,
    billingPoBox,
    billingPoBoxCity,
    billingPoBoxState,
    billingPoBoxZipcode,
    mailingPoBox,
    mailingPoBoxCity,
    mailingPoBoxState,
    mailingPoBoxZipcode,
    poBoxEnabled,
    isServiceAddressManual,
  },
} = customerForm;

export default {
  [about.name]: '', // Step 1
  [aboutOption.name]: '',
  [useServiceHousesScheduled.name]: false,
  [useServiceHousesOccupied.name]: false,
  [zipcode.name]: '',
  [zipcodeValid.name]: '',
  [city.name]: '', // Step 2
  [property.name]: '',
  [state.name]: '',
  [serviceAddress.name]: '',
  [owner.name]: '',
  [csr.name]: '',
  [market.name]: '',
  [lotSize.name]: '',
  [taxRate.name]: '',
  [useCornerLot.name]: true,
  [aboutOld.name]: '',
  [costService.name]: '', // Step 3
  [billingLotSize.name]: '',
  [reasonSize.name]: '',
  [reasonEdit.name]: false,
  [srvFrecuency.name]: '',
  [grassOvergrown.name]: '',
  [mowCut.name]: [],
  [calculatedTax.name]: '',
  [totalMowing.name]: '',
  [subtotalMowing.name]: '',
  [specialReq.name]: '',
  [firstName.name]: '', // Step 4
  [lastName.name]: '',
  [email.name]: '',
  [contactNumber.name]: '',
  [isCellphone.name]: 'Yes',
  [serviceDate.name]: '',
  [isCommunityGate.name]: 'Yes',
  [isCommercial.name]: 'No',
  [communityAnswer.name]: '',
  [isBackyardGate.name]: 'Yes',
  [backyardAnswer.name]: '',
  [isFlowerBeds.name]: '',
  [flowerAnswer.name]: '',
  [isPetsBackyard.name]: '',
  [isFencePlastic.name]: '',
  [isAboveGroundPool.name]: '',
  [isTranpoline.name]: '',
  [role.name]: 'customer',
  [password.name]: 'mow12345',
  [privateNotes.name]: '', // Step 5
  [privateNotesDefault.name]: '',
  [specialRequest.name]: '',
  [speReqNotesDefault.name]: '',
  [bbNotes.name]: '',
  [cardNumber.name]: '', // Step 6
  [cardNumber1.name]: '',
  [cardNumber2.name]: '',
  [cardNumber3.name]: '',
  [cardMonth.name]: '',
  [cardYear.name]: '',
  [nameOnCard.name]: '',
  [cardType.name]: '',
  [isBillingAddress.name]: '',
  [billingAddress.name]: '',
  [billingCity.name]: '',
  [billingState.name]: '',
  [billingZipCode.name]: '',
  [billingName.name]: '',
  [isMailingAddress.name]: '',
  [mailingName.name]: '',
  [mailingAddress.name]: '',
  [mailingCity.name]: '',
  [mailingState.name]: '',
  [mailingZipCode.name]: '',
  [isAddressBillingService.name]: '',
  [country.name]: '',
  [useAddressForPaymentDetails.name]: false, // None
  [week.name]: '', // Step 1 - edit customer
  [weekDay.name]: null,
  [communityCode.name]: '', // Step3 - edit customer
  [crew.name]: '',
  [mapscoNumber.name]: '',
  [mapsco.name]: '',
  [mapA.name]: '',
  [mapscoGrid.name]: '',
  [gateCode.name]: '',
  [expiryDate.name]: '',
  [isRestart.name]: false, // Step 0 Edit customer
  [isApproved.name]: false,
  [isTranslation.name]: false,
  [cvv.name]: '',
  [joinDate.name]: '',
  [phoneMarket.name]: '', // Step General
  [turfSize.name]: '', // Step 3 Edit
  [lotReview.name]: false,
  [optReviews.name]: [], // General Tag
  [sendSchedule.name]: '',
  [futureCancel.name]: '',
  [conditionSpanish.name]: '',
  [requestSpanish.name]: '',
  [weekNumberBb.name]: '', // B&B
  [crewBb.name]: '',
  [weekDayBb.name]: '',
  [statusBb.name]: '',
  [instructionBb.name]: '',
  [billingNoteDate.name]: '', // billing Note
  [billingNoteReason.name]: '',
  [billingNoteCompleted.name]: false,
  [billingNoteDetail.name]: '',
  [accountNumber.name]: '',
  [skipsCompleted.name]: false, // Skips,Write In´s and Redos
  [skipsServiceDay.name]: '',
  [skipsUserId.name]: '',
  [skipsReason.name]: '',
  [writeCompleted.name]: false,
  [writeServiceDay.name]: '',
  [writeUserId.name]: '',
  [writeReason.name]: '',
  [redoCompleted.name]: false,
  [redoServiceDay.name]: '',
  [redoUserId.name]: '',
  [redoReason.name]: '',
  [swrInstruction.name]: '',
  [bankAccount.name]: '',
  [bankRouting.name]: '',
  [billingNameBank.name]: '',
  [paymentMethods.name]: 1,
  [winterScheduleStatus.name]: [], // winter ScheduleStatus Tag
  [billingPoBox.name]: '',
  [billingPoBoxCity.name]: '',
  [billingPoBoxState.name]: '',
  [billingPoBoxZipcode.name]: '',
  [mailingPoBox.name]: '',
  [mailingPoBoxCity.name]: '',
  [mailingPoBoxState.name]: '',
  [mailingPoBoxZipcode.name]: '',
  [poBoxEnabled.name]: false,
  [isServiceAddressManual.name]: false,
};
