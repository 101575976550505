import React from 'react';

import { Typography } from '../../../../../components/shared/MaterialUI';

const Labels = ({ text, classNames, required }) => {
  //const classes = SignUpStyles();
  return (
    <>
      <Typography className={classNames}>
        {text} {required && <span>*</span>}
      </Typography>
    </>
  );
};

export default Labels;
