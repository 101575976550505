const lookupDb = [
  {
    value: 1,
    label: 'Basic search'
  },
  {
    value: 2,
    label: 'Scheduled Service'
  },
  {
    value: 9,
    label: 'Skips write ins and redos'
  },
  {
    value: 10,
    label: 'Status and market'
  },
  {
    value: 11,
    label: 'Accounts with pending size verification'
  },
  {
    value: 12,
    label: 'Operations report'
  },
  {
    value: 13,
    label: 'Reconcilation report'
  },
  /*{
    value: 14,
    label: 'B&B report'
  },*/
  {
    value: 15,
    label: 'Active customers with mobiles'
  },
];

export default lookupDb;
