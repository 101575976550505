import AppService from './AppService';

class ReferralService extends AppService {

  constructor() {
    super();
    this.path = this.getPath('referral');
  }

  list(params = '') {
    const queryParams = params ? params : '';
    return this.http.get(`${this.path}${queryParams}`);
  }

  create(data) {
    return this.http.post(`${this.path}`, data);
  }

  update(data, id) {
    return this.http.patch(`${this.path}/${id}`, data);
  }

  delete(id) {
    return this.http.delete(`${this.path}/${id}`);
  }

  createType(data) {
    return this.http.post(`${this.path}/types`, data);
  }

  updateType(data, id) {
    return this.http.patch(`${this.path}/${id}/types`, data);
  }

  deleteType(id) {
    return this.http.delete(`${this.path}/${id}/types`);
  }
}

export default ReferralService;
