import React, {useContext} from 'react';
import CreateNewIcon from '@material-ui/icons/CreateNewFolderOutlined';
import {ListItemIcon, ListItemText, MenuItem, Popover} from '@material-ui/core';
import {AcUnitTwoTone, BeachAccessSharp} from '@material-ui/icons';
import {includes as _includes, findKey as _findKey, findIndex as _findIndex} from 'lodash';

import {WeekStyle} from '../../calendar/styles/WeekStyle';
import {ScheduleManagementContext} from '../../../pages/schedule/ScheduleManagement';

const MenuSchedule = (props) => {
  const {anchorEl, menuOpen} = props;
  const classes = WeekStyle();
  const bgColor = {regular: {color: "#e8e8e8"}, winter: {color: "#cafffe"}, holiday: {color: "#fb0f1c"}};
  const {weekRegular, setWeekRegular, updateWeeks, setUpdateWeeks} = useContext(ScheduleManagementContext);


  const onCloseMenu = (color) => {
    const values = {...props.weekSelect, color: color, winter: {color: color}};
    const newDataRender = props.dataRender.map(obj =>
      obj.week === values.week ? {...obj, ...values} : obj
    );

    if (bgColor.winter.color === color && _includes([weekRegular, weekRegular - 1], props.weekSelect.week)) {
      setWeekRegular(weekRegular - 1);
    } else if (bgColor.regular.color === color) {
      setWeekRegular(props.weekSelect.week);
    }

    const type = _findKey(bgColor, ['color', color]);

    const key = _findIndex(updateWeeks, function (o) {
      return o.week === props.weekSelect.week;
    })
    if (key === -1) {
      setUpdateWeeks((updateWeeks) => [
        ...updateWeeks,
        {type: type, week: props.weekSelect.week},
      ]);
    } else {
      updateWeeks[key]['type'] = type;
      setUpdateWeeks(updateWeeks);
    }

    props.setDataRender(newDataRender);
    props.setMenuOpen(false);
  }

  return (
    <>
      <Popover
        className={classes.popover}
        open={Boolean(menuOpen)}
        anchorEl={anchorEl}
        onClose={() => props.setMenuOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        classes={{
          paper: classes.py2
        }}
      >
        {(weekRegular + 1) === props.weekSelect.week && (
          <MenuItem onClick={() => onCloseMenu(`${bgColor?.regular?.color}`)}>
            <ListItemIcon className={classes.minW25}>
              <CreateNewIcon/>
            </ListItemIcon>
            <ListItemText primary="Regular"/>
          </MenuItem>
        )}
        {(weekRegular - 1) < props.weekSelect.week && (
          <>
            <MenuItem onClick={() => onCloseMenu(`${bgColor?.winter?.color}`)}>
              <ListItemIcon className={classes.minW25}>
                <AcUnitTwoTone/>
              </ListItemIcon>
              <ListItemText primary="Winter"/>
            </MenuItem>
            <MenuItem onClick={() => onCloseMenu(`${bgColor?.holiday?.color}`)}>
              <ListItemIcon className={classes.minW25}>
                <BeachAccessSharp/>
              </ListItemIcon>
              <ListItemText primary="Holiday"/>
            </MenuItem>
          </>
        )}
      </Popover>
    </>
  );
};

export default MenuSchedule;
