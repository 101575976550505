import React, { useEffect, useRef, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { 
  Button, 
  Grid,
  Switch, 
  TextField, 
  Typography,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MarketMapService } from '../../services';
import { useUI } from '../../app/context/ui';
import { useStyles } from './mapStyles';
import Marker from './Marker';

const MapContainer = ({ data, setReloadCustomersMap }) => {
  
  const classes = useStyles();
  const [selectedMarkers, setSelectedMarkers] = useState([]);
  const [selectMultiple, setSelectMultiple] = useState(false);
  const formikRef = useRef();
  const marketMapService = new MarketMapService();
  const { blockUI } = useUI();
  const [errorMessage, setErrorMessage] = useState("");
  const [mapCenter, setMapCenter] = useState({
    lat: 0,
    lng: 0,
  });

  const texasCoordinates = {
    lat: 31.2504400,
    lng: -99.2506100
  };

  const defaultProps = {
    center: mapCenter,
    zoom: 10,
  };

  const [initialValues,] = useState({
    crew: '',
    typeSubmit: 'assign'
  });

  const validationSchema = Yup.object({
    crew: Yup.number().when('typeSubmit', {
      is: 'assign',
      then: Yup.number()
        .typeError('Enter only numbers')
        .required('Type crew number is required')
        .positive("Enter a different crew"),
      otherwise: Yup.number(),
    }),
  });

  const handleMarkerClick = (marker) => {
    if(selectMultiple){
      const isSelected = selectedMarkers.some((selectedMarker) => selectedMarker.id === marker.id);
      if (isSelected) {
        setSelectedMarkers((prevSelectedMarkers) =>
          prevSelectedMarkers.filter((selectedMarker) => selectedMarker.id !== marker.id)
        );
      } else {
        setSelectedMarkers((prevSelectedMarkers) => [...prevSelectedMarkers, marker]);
      }
    }else{
      setSelectedMarkers([marker]);
    }
  };

  const onSubmit = async (values) => {
    if (values.typeSubmit) {
      try {
        blockUI.current.open(true);
        setErrorMessage('');
        const customers_ids = selectedMarkers.map((e)=>e.id).join(', ');
        marketMapService.getAccessToken();
        
        if(values.typeSubmit === 'assign' && values.crew){
          await marketMapService.updateMarketMap({
            customers_ids,
            action: values.typeSubmit,
            crew: Number(values.crew)
          });
          setReloadCustomersMap(true);
        }

        if(values.typeSubmit === 'unassign'){
          await marketMapService.updateMarketMap({
            customers_ids,
            action: values.typeSubmit
          });
          setReloadCustomersMap(true);
        }
        blockUI.current.open(false);
      } catch (e) {
        setErrorMessage(e.response.data.errors.crew[0]);
        blockUI.current.open(false);
      }
    }
  };

  const MapMessage = ({ text }) => (
    <div className={classes.wrapperMessage}>
      <Typography 
        variant="h6" 
        className={classes.messageNotFound}
      >
        {text}
      </Typography>
    </div>
  );

  useEffect(() => {
    if (data.length > 0) {
      setSelectMultiple(false);
      setSelectedMarkers([]);
      const formik = formikRef.current;
      if (formik) {
        formik.setValues({
          ...formik.values,
          crew: '',
        }, false);
      }
      const firstMarker = data[0];
      setMapCenter({
        lat: parseFloat(firstMarker.lat),
        lng: parseFloat(firstMarker.lng),
      });
    }else{
      setMapCenter({
        lat: parseFloat(texasCoordinates.lat),
        lng: parseFloat(texasCoordinates.lng),
      });
    }
  }, [data]);

  useEffect(() => {
    setSelectedMarkers([]);
    const formik = formikRef.current;
    if (formik) {
      formik.setValues({
        ...formik.values,
        crew: '',
      }, false);
    }
  }, [selectMultiple]);

  return (
    <div style={{ height: '500px', width: '100%' }}>

      <Grid container style={{display: 'flex', alignItems: 'center', marginBottom: '25px'}}>
        <Grid item xs={12} style={{textAlign: 'center', color: 'red', marginBottom: '15px'}}>
          { errorMessage }
        </Grid>
        <Grid item xs={3}>
          Select multiple:
          <Switch 
            checked={selectMultiple} 
            onChange={(e)=>{setSelectMultiple(e.target.checked)}}
          />
        </Grid>
        <Grid item xs={8}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                onSubmit(values).then(() => { });
            }}
            enableReinitialize={true}
            innerRef={formikRef} 
          >
            {(props) => {
              const {
                  values,
                  touched,
                  errors,
                  handleChange,
                  submitForm,
              } = props;
              return (
                <Form autoComplete="off">
                  <Grid container spacing={3} alignItems='center'>
                    <Grid item xs={3}>
                      <TextField
                        variant="outlined"
                        autoFocus
                        size="small"
                        fullWidth
                        id="crew"
                        name="crew"
                        disabled={(selectedMarkers.length === 0)}
                        value={values.crew}
                        onChange={(e) => {
                          const { value } = e.target;
                          if (/^[0-9]*$/.test(value) || value === '') {
                            handleChange(e);
                          }
                        }}
                        error={touched.crew && Boolean(errors.crew)}
                        helperText={
                          errors.crew ? (
                            <span style={{ fontSize: '12px' }}>{errors.crew}</span>
                          ) : (
                            ''
                          )
                        }
                      />
                      <input
                        name="typeSubmit"
                        type="hidden"
                        value={values.typeSubmit}
                      />
                    </Grid>
                    <Grid item xs={3} style={{display:'flex', alignItems: 'center'}}>
                      <Button
                        type="button"
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={()=>{
                          const formik = formikRef.current;
                          formik.setValues({
                            ...values,
                            typeSubmit: 'assign'
                          }, false).then(() => {
                            submitForm();
                          });
                        }}
                        disabled={(selectedMarkers.length === 0)}
                        style={{ height: '37px', fontSize: '14px', width: '112px'}}
                      >
                        Assign
                      </Button>
                      <Button
                        type="button"
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={()=>{
                          const formik = formikRef.current;
                          formik.setValues({
                            crew: '',
                            typeSubmit: 'unassign'
                          }, false).then(() => {
                            submitForm();
                          });
                        }}
                        disabled={(selectedMarkers.length === 0)}
                        style={{marginLeft: '10px', height: '37px', fontSize: '14px', width: '112px'}}
                      >
                        Unassign
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
      
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyDYUv7rStrtu1uoQis-4SgihcLl-Iyi55w' }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        key={mapCenter.lat + mapCenter.lng} 
      >
        {data.map((marker) => (
          <Marker
            key={marker.id}
            lat={parseFloat(marker.lat)}
            lng={parseFloat(marker.lng)}
            text={marker.id}
            onClick={() => handleMarkerClick(marker)}
            dataMarker={marker}
            mapColor={marker.mapColor}
            selected={selectedMarkers.some((selectedMarker) => selectedMarker.id === marker.id)}
          />
        ))}
      </GoogleMapReact>

      { data.length === 0 && <MapMessage text="No results found" /> }

    </div>
  );
};

export default MapContainer;
