import React, {useState, useRef, useEffect, useCallback, useContext} from 'react';
import { connect } from 'react-redux';

import { useUI } from '../../app/context/ui';
import ActivityList from './ActivityList';
import AppHelper from '../../helpers/AppHelper';
import Header from './Header';
import WeeksOfYear from './WeeksOfYear';
import CustomerService from '../../services/CustomerService';
import { HeaderStyle } from './styles/HeaderStyle';
import { useFormikContext } from 'formik';

import {find as _find, isArray as _isArray} from 'lodash';
import {PreviousCustomerContext} from "../../pages/customer/EditCustomer";

const customerService = new CustomerService();

const Calendar = (props) => {
  const {mm} = props;
  const classes = HeaderStyle();

  const {year, setYear, updateServiceNote} = useContext(PreviousCustomerContext);

  const [dataRender, setDataRender] = useState([]);
  const [activityDay, setActivityDay] = useState('');
  const [rangeDate, setRangeDate] = useState('all');
  const [messageError, setMessageError] = useState('');
  const {values: formValues} = useFormikContext();
  const [checkedYear, setCheckedYear] = useState(true);

  const {winterScheduleStatus} = formValues;

  const yearReaf = useRef(year);
  const {blockUI, snackbarUI} = useUI();
  customerService.getAccessToken();

  const onNextYear = () => {
    setYear(++yearReaf.current);
  };

  const onPrevYear = () => {
    setYear(--yearReaf.current);
  };

  const getDataSchedule = useCallback(async () => {
    try {
      blockUI.current.open(true);
      setActivityDay('');
      const {data: calendar} = await customerService.calendar(mm.customer.options.id, year, rangeDate);
      if (rangeDate === 'winter') {
        let season = [];
        for (let i in calendar.data) {
          season = [...season, calendar.data[i]];
        }
        setDataRender(season);
      } else {
        setDataRender(calendar.data);
        calendar.data.map((e) => {
          e.current.active && setActivityDay(e.customer.workday);
        });
      }
      blockUI.current.open(false);
    } catch (e) {
      setMessageError(e.response.data.message);
      AppHelper.checkError(e, snackbarUI);
      blockUI.current.open(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockUI, snackbarUI, year, rangeDate, mm.customer.options.id]);

  useEffect(() => {
    activityDay === '' && setActivityDay('Not services');
  }, [activityDay]);


  useEffect(() => {
    (async function init() {
      await getDataSchedule();
    })();
  }, [
    year,
    rangeDate,
    getDataSchedule,
    mm.customer.options.serviceDate,
    mm.customer.options.srvFrecuency,
    mm.customer.options.weekDay,
    mm.customer.options.futureCancel,
    mm.customer.options.skipsServiceDay,
    mm.customer.options.winterScheduleStatus,
    updateServiceNote,
  ]);

  useEffect(() => {
    if (_isArray(winterScheduleStatus)) {
      const el = _find(winterScheduleStatus, (w) => {
        return w.year === year;
      });
      if (el) {
        setCheckedYear(el.status);
      } else {
        setCheckedYear(true);
      }
    }
  }, [winterScheduleStatus, year]);

  return (
    <>
      {messageError === '' ? (
        <>
          <Header
            year={year}
            onNextYear={onNextYear}
            onPrevYear={onPrevYear}
            setRangeDate={setRangeDate}
            rangeDate={rangeDate}
            setDataRender={setDataRender}
            checkedYear={checkedYear}
            setCheckedYear={setCheckedYear}
          />
          <WeeksOfYear
            dataRender={dataRender}
            setDataRender={setDataRender}
            setActivityDay={setActivityDay}
            getDataSchedule={getDataSchedule}
            year={year}
          />
          <ActivityList activityDay={activityDay} dataRender={dataRender}  year={year} />
        </>
      ) : (
        <h1 className={classes.messageError}>{messageError}</h1>
      )}
    </>
  );
};
const mapsStateToProps = (state) => {
  return {
    mm: state.mm,
  };
};
export default connect(mapsStateToProps)(Calendar);
