export default {
  formId: 'checkoutForm',
  formField: {
    about: {
      name: 'about',
      label: 'How did your hear about us:',
      requiredErrorMsg: 'Field is required'
    },
    aboutOption: {
      name: 'aboutOption',
      label: 'How did your hear about us:',
      requiredErrorMsg: 'Field is required'
    },
    referralCode: {
      name: 'referralCode',
      label: 'Referral Code',
    },
    aboutOld: {
      name: 'aboutOld',
      label: 'How did your hear about us:',
      requiredErrorMsg: 'Field is required'
    },
    useServiceHousesScheduled: {
      name: 'useServiceHousesScheduled',
      label: 'Scheduled',
      requiredErrorMsg: 'Field is required'
    },
    useServiceHousesOccupied: {
      name: 'useServiceHousesOccupied',
      label: 'Occupied',
      requiredErrorMsg: 'Field is required'
    },
    zipcode: {
      name: 'zipcode',
      label: 'Zipcode*',
      requiredErrorMsg: 'Zip code is required',
      invalidErrorMsg: 'Zip code is not valid (e.g. 70000)'
    },
    zipcodeValid: {
      name: 'zipcodeValid',
      label: 'zipcodeValid*',
      value: 'zipcodeValid*',
      requiredErrorMsg: 'Field is required',
      invalidErrorMsg: 'Zip code is not valid (e.g. 70000)'
    },
    marketId: {
      name: 'marketId',
      label: 'Market id:',
      requiredErrorMsg: 'Field is required'
    },
    city: { // Step 2
      name: 'city',
      label: 'City*',
      requiredErrorMsg: 'City is required'
    },
    property: {
      name: 'property',
      label: 'property*',
      requiredErrorMsg: 'Field is required',
      invalidErrorMsg: 'Property value is not valid'
    },
    state: {
      name: 'state',
      label: 'State/Province/Region',
      requiredErrorMsg: 'State is required'
    },
    serviceAddress: {
      name: 'serviceAddress',
      label: 'serviceAddress*',
      requiredErrorMsg: 'Service address is required',
      invalidErrorMsg: 'Service address is required'
    },
    owner: {
      name: 'owner',
      label: 'owner*',
      requiredErrorMsg: 'Owner is required'
    },
    csr: {
      name: 'csr',
      label: 'CSR',
      requiredErrorMsg: 'CSR is required'
    },
    market: {
      name: 'market',
      label: 'Market*',
      requiredErrorMsg: 'Market is required'
    },
    lotSize: {
      name: 'lotSize',
      label: 'Lot size *',
      requiredErrorMsg: 'Lot size is required',
      invalidErrorMsg: 'Lot size value is not valid'
    },
    taxRate: {
      name: 'taxRate',
      label: 'Tax rate *',
      requiredErrorMsg: 'Tax rate is required'
    },
    useCornerLot: {
      name: 'useCornerLot',
      label: 'Corner Lot',
      requiredErrorMsg: 'Corner lot is required'
    },
    costService: { // Step 3
      name: 'costService',
      label: 'cost service',
      requiredErrorMsg: 'Cost for the service is required',
      invalidErrorMsg: 'The cost for the service value is not valid'
    },
    billingLotSize: {
      name: 'billingLotSize',
      label: 'BillingLotSize*',
      requiredErrorMsg: 'Field is required'
    },
    reasonSize: {
      name: 'reasonSize',
      label: 'Reason Size',
      requiredErrorMsg: 'Field is required'
    },
    reasonEdit: {
      name: 'reasonEdit',
      label: 'Reason Edit',
      requiredErrorMsg: 'Field is required'
    },
    srvFrecuency: {
      name: 'srvFrecuency',
      label: 'Service Frecuency*',
      requiredErrorMsg: 'Field is required'
    },
    grassOvergrown: {
      name: 'grassOvergrown',
      label: 'Grass Overgrown*',
      requiredErrorMsg: 'Field is required'
    },
    mowCut: {
      name: 'mowCut',
      label: 'What to mow?*',
      requiredErrorMsg: 'Field is required'
    },
    calculatedTax: {
      name: 'calculatedTax',
      label: 'Calculated Tax',
      requiredErrorMsg: 'Field is required'
    },
    totalMowing: {
      name: 'totalMowing',
      label: 'Total Mowing',
      requiredErrorMsg: 'Field is required',
      invalidErrorMsg: 'Total mowing price is not valid'
    },
    subtotalMowing: {
      name: 'subtotalMowing',
      label: 'Subtotal Mowing',
      requiredErrorMsg: 'Field is required',
      invalidErrorMsg: 'Subtotal mowing price is not valid'
    },
    specialReq: {
      name: 'specialReq',
      label: 'Special Request',
      requiredErrorMsg: 'Field is required'
    },
    firstName: { // Step 4
      name: 'firstName',
      label: 'First name*',
      requiredErrorMsg: 'First name is required'
    },
    lastName: {
      name: 'lastName',
      label: 'Last name*',
      requiredErrorMsg: 'Last name is required'
    },
    email: {
      name: 'email',
      label: 'Email *',
      requiredErrorMsg: 'Email is required',
      invalidErrorMsg: 'This email has already been registered'
    },
    contactNumber: {
      name: 'contactNumber',
      label: 'Contact number *',
      requiredErrorMsg: 'Contact number is required',
      invalidErrorMsg: 'Phone number is not valid'
    },
    isCellphone: {
      name: 'isCellphone',
      label: 'Is this a cellphone?*',
      requiredErrorMsg: 'Field is required'
    },
    serviceDate: {
      name: 'serviceDate',
      label: 'Service start date  *',
      requiredErrorMsg: 'Service start date  is required',
      invalidErrorMsg: 'Service start date is not valid'
    },
    isCommunityGate: {
      name: 'isCommunityGate',
      label: 'Community gate?*',
      requiredErrorMsg: 'Field is required'
    },
    communityAnswer: {
      name: 'communityAnswer',
      label: 'Enter code?*',
      requiredErrorMsg: 'Field is required'
    },
    isBackyardGate: {
      name: 'isBackyardGate',
      label: 'Backyard gate?*',
      requiredErrorMsg: 'Field is required'
    },
    backyardAnswer: {
      name: 'backyardAnswer',
      label: 'Only if you need code?*',
      requiredErrorMsg: 'Field is required'
    },
    isFlowerBeds: {
      name: 'isFlowerBeds',
      label: 'Flower beds? *',
      requiredErrorMsg: 'Field is required'
    },
    flowerAnswer: {
      name: 'flowerAnswer',
      label: 'Only if you need code? *',
      requiredErrorMsg: 'Field is required'
    },
    isPetsBackyard: {
      name: 'isPetsBackyard',
      label: 'Pets in backyard?*',
      requiredErrorMsg: 'Field is required'
    },
    isFencePlastic: {
      name: 'isFencePlastic',
      label: 'Is your fence made of white vinyl plastic?*',
      requiredErrorMsg: 'Field is required'
    },
    isAboveGroundPool: {
      name: 'isAboveGroundPool',
      label: 'Do you own on above ground pool?*',
      requiredErrorMsg: 'Field is required'
    },
    isTranpoline: {
      name: 'isTranpoline',
      label: 'Do you have a tranpoline and want us to move it?*',
      requiredErrorMsg: 'Field is required'
    },
    role: {
      name: 'role',
      label: 'Address Line 1*',
      requiredErrorMsg: 'Field is required'
    },
    password: {
      name: 'password',
      label: 'Address Line 1*',
      requiredErrorMsg: 'Field is required'
    },
    privateNotes: { // Step 5
      name: 'privateNotes',
      label: 'Private notes *',
      requiredErrorMsg: 'Field is required'
    },
    specialRequest: {
      name: 'specialRequest',
      label: 'Special Request *',
      requiredErrorMsg: 'Field is required'
    },
    privateNotesDefault: {
      name: 'privateNotesDefault',
      label: 'Gate code',
      requiredErrorMsg: 'Field is required'
    },
    speReqNotesDefault: {
      name: 'speReqNotesDefault',
      label: 'Customer has pets, knock the door',
      requiredErrorMsg: 'Field is required'
    },
    bbNotes: {
      name: 'bbNotes',
      label: 'B&B notes *',
      requiredErrorMsg: 'Field is required'
    },
    cardType: { // Step 6
      name: 'cardType',
      label: 'Card type*',
      requiredErrorMsg: 'Field is required'
    },
    cardNumber: {
      name: 'cardNumber',
      label: 'Card number*',
      requiredErrorMsg: 'Card number is required',
      invalidErrorMsg: 'Card number is not valid'
    },
    cardNumber1: {
      name: 'cardNumber1',
      label: 'Card number*',
      requiredErrorMsg: 'Card number is required',
      invalidErrorMsg: 'Card number is not valid (e.g. 4111111111111)'
    },
    cardNumber2: {
      name: 'cardNumber2',
      label: 'Card number*',
      requiredErrorMsg: 'Card number is required',
      invalidErrorMsg: 'Card number is not valid (e.g. 4111111111111)'
    },
    cardNumber3: {
      name: 'cardNumber3',
      label: 'Card number*',
      requiredErrorMsg: 'Card number is required',
      invalidErrorMsg: 'Card number is not valid (e.g. 4111111111111)'
    },
    cardMonth: {
      name: 'cardMonth',
      label: 'Month:',
      requiredErrorMsg: 'Field is required'
    },
    cardYear: {
      name: 'cardYear',
      label: 'Year:',
      requiredErrorMsg: 'Field is required'
    },
    nameOnCard: {
      name: 'nameOnCard',
      label: 'Name on card*',
      requiredErrorMsg: 'Name on card is required'
    },
    isBillingAddress: {
      name: 'isBillingAddress',
      label: 'Billing address *',
      requiredErrorMsg: 'Field is required'
    },
    isCommercial: {
      name: 'isCommercial',
      label: 'Commercial *',
      requiredErrorMsg: 'Field is required'
    },
    billingAddress: {
      name: 'billingAddress',
      label: 'Billing address *',
      requiredErrorMsg: 'Field is required'
    },
    billingCity: {
      name: 'billingCity',
      label: 'Billing City *',
      requiredErrorMsg: 'Field is required'
    },
    billingState: {
      name: 'billingState',
      label: 'Billing state *',
      requiredErrorMsg: 'Field is required'
    },
    billingZipCode: {
      name: 'billingZipCode',
      label: 'Zip *',
      requiredErrorMsg: 'Zip code is required',
      invalidErrorMsg: 'Zip code is not valid (e.g. 70000)'
    },
    billingName: {
      name: 'billingName',
      label: 'Billing name *',
      requiredErrorMsg: 'Field is required'
    },
    isMailingAddress: {
      name: 'isMailingAddress',
      label: 'Mailing address *',
      requiredErrorMsg: 'Field is required'
    },
    mailingName: {
      name: 'mailingName',
      label: 'Mailing Name',
      requiredErrorMsg: 'Field is required'
    },
    mailingAddress: {
      name: 'mailingAddress',
      label: 'Mailing address',
      requiredErrorMsg: 'Field is required'
    },
    mailingCity: {
      name: 'mailingCity',
      label: 'Mailing city*',
      requiredErrorMsg: 'Field is required'
    },
    mailingState: {
      name: 'mailingState',
      label: 'Mailing state*',
      requiredErrorMsg: 'Field is required'
    },
    mailingZipCode: {
      name: 'mailingZipCode',
      label: 'Zip *',
      requiredErrorMsg: 'Zip code is required',
      invalidErrorMsg: 'Zip code is not valid (e.g. 70000)'
    },
    isAddressBillingService: {
      name: 'isAddressBillingService',
      label: 'isAddressBillingService*',
      requiredErrorMsg: 'Field is required'
    },
    country: {
      name: 'country',
      label: 'Country*',
      requiredErrorMsg: 'Country is required'
    },
    useAddressForPaymentDetails: {
      name: 'useAddressForPaymentDetails',
      label: 'Use this address for payment details'
    },
    week: { // Step 1 Edit Customer
      name: 'week',
      label: 'Week*',
      requiredErrorMsg: 'Field is required',
      invalidErrorMsg: 'Field is required'
    },
    weekYear: {
      name: 'weekYear',
      label: 'Week*',
      requiredErrorMsg: 'Field is required',
      invalidErrorMsg: 'Field is required'
    },
    weekDay: {
      name: 'weekDay',
      label: 'Week*',
      requiredErrorMsg: 'Field is required',
      invalidErrorMsg: 'Field is required'
    },
    communityCode: { // Step 3 Edit Customer
      name: 'communityCode',
      label: 'Community Code*',
      requiredErrorMsg: 'Expiry date is required',
      invalidErrorMsg: 'Expiry date is not valid'
    },
    crew: {
      name: 'crew',
      label: 'Crew Number*',
      requiredErrorMsg: 'Field is required',
      invalidErrorMsg: 'Field is required'
    },
    mapscoNumber: {
      name: 'mapscoNumber',
      label: 'Mapsco Number*',
      requiredErrorMsg: 'Expiry date is required',
      invalidErrorMsg: 'Expiry date is not valid'
    },
    mapsco: {
      name: 'mapsco',
      label: 'Mapsco*',
      requiredErrorMsg: 'Field is required',
      invalidErrorMsg: 'Field is required'
    },
    mapA: {
      name: 'mapA',
      label: 'MapA*',
      requiredErrorMsg: 'Field is required',
      invalidErrorMsg: 'Field is required'
    },
    mapscoGrid: {
      name: 'mapscoGrid',
      label: 'Mapsco Grid *',
      requiredErrorMsg: 'Field is required',
      invalidErrorMsg: 'Field is required'
    },
    gateCode: {
      name: 'gateCode',
      label: 'Gate Code*',
      requiredErrorMsg: 'Expiry date is required',
      invalidErrorMsg: 'Expiry date is not valid'
    },
    expiryDate: {
      name: 'expiryDate',
      label: 'Expiry date*',
      requiredErrorMsg: 'Expiry date is required',
      invalidErrorMsg: 'Expiry date is not valid'
    },
    isRestart: { // Step 0 edit
      name: 'isRestart',
      label: 'Restart',
      requiredErrorMsg: 'Field is required'
    },
    isApproved: {
      name: 'isApproved',
      label: 'Approved',
      requiredErrorMsg: 'Field is required'
    },
    isTranslation: {
      name: 'isTranslation',
      label: 'Translation needs',
      requiredErrorMsg: 'Field is required'
    },
    cvv: {
      name: 'cvv',
      label: 'CVV*',
      requiredErrorMsg: 'CVV is required',
      invalidErrorMsg: 'CVV is invalid (e.g. 357)'
    },
    phoneMarket: {  // Step General
      name: 'phoneMarket',
      label: 'Local Number*',
      requiredErrorMsg: 'Field is required',
      invalidErrorMsg: 'Field is required'
    },
    turfSize: {
      name: 'turfSize',
      label: 'Turf size *',
      requiredErrorMsg: 'turf size is required',
      invalidErrorMsg: 'turf size value is not valid'
    },
    status: {
      name: 'status',
      label: 'Status *',
      requiredErrorMsg: 'Field is required',
      invalidErrorMsg: 'Field is required'
    },
    optReviews: {
      name: 'optReviews',
      label: 'Status *',
      requiredErrorMsg: 'Field is required',
      invalidErrorMsg: 'Field is required'
    },
    sendSchedule: {
      name: 'sendSchedule',
      label: 'Status *',
      requiredErrorMsg: 'Field is required',
      invalidErrorMsg: 'Send schedule must be greater than 0 and can\'t be exceeded 53'
    },
    futureCancel: {
      name: 'futureCancel',
      label: 'Future cancel #',
      requiredErrorMsg: 'Field is required',
      invalidErrorMsg: 'Future cancel must be greater than 0 and can\'t be exceeded 53'
    },
    lotReview: {
      name: 'lotReview',
      label: 'Lot Review',
      requiredErrorMsg: 'Field is required',
      invalidErrorMsg: 'Field is required'
    },
    joinDate: {
      name: 'joinDate',
      label: 'Join date #',
      requiredErrorMsg: 'Field is required',
      invalidErrorMsg: 'Field is required'
    },
    conditionSpanish: {
      name: 'conditionSpanish',
      label: 'Conditions',
      requiredErrorMsg: 'Field is required',
      invalidErrorMsg: 'Field is required'
    },
    requestSpanish: {
      name: 'requestSpanish',
      label: 'Requests',
      requiredErrorMsg: 'Field is required',
      invalidErrorMsg: 'Field is required'
    },
    weekNumberBb: {
      name: 'weekNumberBb',
      label: 'Week number',
      requiredErrorMsg: 'Field is required',
      invalidErrorMsg: 'Field is required'
    },
    crewBb: {
      name: 'crewBb',
      label: 'Crew',
      requiredErrorMsg: 'Field is required',
      invalidErrorMsg: 'Field is required'
    },
    weekDayBb: {
      name: 'weekDayBb',
      label: 'Week Day',
      requiredErrorMsg: 'Field is required',
      invalidErrorMsg: 'Field is required'
    },
    statusBb: {
      name: 'statusBb',
      label: 'status',
      requiredErrorMsg: 'Field is required',
      invalidErrorMsg: 'Field is required'
    },
    instructionBb: {
      name: 'instructionBb',
      label: 'instruction',
      requiredErrorMsg: 'Field is required',
      invalidErrorMsg: 'Field is required'
    },
    billingNoteDate: {
      name: 'billingNoteDate',
      label: 'Date',
      requiredErrorMsg: 'Field is required',
      invalidErrorMsg: 'Field is required'
    },
    billingNoteReason: {
      name: 'billingNoteReason',
      label: 'Reason',
      requiredErrorMsg: 'Field is required',
      invalidErrorMsg: 'Field is required'
    },
    billingNoteCompleted: {
      name: 'billingNoteCompleted',
      label: 'Completed',
      requiredErrorMsg: 'Field is required',
      invalidErrorMsg: 'Field is required'
    },
    billingNoteDetail: {
      name: 'billingNoteDetail',
      label: 'instruction',
      requiredErrorMsg: 'Field is required',
      invalidErrorMsg: 'Field is required'
    },
    accountNumber: {
      name: 'accountNumber',
      label: 'Account number',
      requiredErrorMsg: 'Field is required',
      invalidErrorMsg: 'Account number is not valid'
    },
    skipsCompleted: {
      name: 'skipsCompleted',
      label: 'Done',
      requiredErrorMsg: 'Field is required',
      invalidErrorMsg: 'Field is required'
    },
    skipsServiceDay: {
      name: 'skipsServiceDay',
      label: 'Service day',
      requiredErrorMsg: 'Field is required',
      invalidErrorMsg: 'Field is required'
    },
    skipsUserId: {
      name: 'skipsUserId',
      label: 'Customer manager',
      requiredErrorMsg: 'Field is required',
      invalidErrorMsg: 'Field is required'
    },
    skipsReason: {
      name: 'skipsReason',
      label: 'Reason',
      requiredErrorMsg: 'Field is required',
      invalidErrorMsg: 'Field is required'
    },
    writeCompleted: {
      name: 'writeCompleted',
      label: 'Done',
      requiredErrorMsg: 'Field is required',
      invalidErrorMsg: 'Field is required'
    },
    writeServiceDay: {
      name: 'writeServiceDay',
      label: 'Service day',
      requiredErrorMsg: 'Field is required',
      invalidErrorMsg: 'Field is required'
    },
    writeUserId: {
      name: 'writeUserId',
      label: 'Customer manager',
      requiredErrorMsg: 'Field is required',
      invalidErrorMsg: 'Field is required'
    },
    writeReason: {
      name: 'writeReason',
      label: 'Reason',
      requiredErrorMsg: 'Field is required',
      invalidErrorMsg: 'Field is required'
    },
    redoCompleted: {
      name: 'redoCompleted',
      label: 'Done',
      requiredErrorMsg: 'Field is required',
      invalidErrorMsg: 'Field is required'
    },
    redoServiceDay: {
      name: 'redoServiceDay',
      label: 'Service day',
      requiredErrorMsg: 'Field is required',
      invalidErrorMsg: 'Field is required'
    },
    redoUserId: {
      name: 'redoUserId',
      label: 'Customer manager',
      requiredErrorMsg: 'Field is required',
      invalidErrorMsg: 'Field is required'
    },
    redoReason: {
      name: 'redoReason',
      label: 'Reason',
      requiredErrorMsg: 'Field is required',
      invalidErrorMsg: 'Field is required'
    },
    swrInstruction: {
      name: 'swrInstruction',
      label: 'Instruction',
      requiredErrorMsg: 'Field is required',
      invalidErrorMsg: 'Field is required'
    },
    bankAccount: {
      name: 'bankAccount',
      label: 'Bank Account Number*',
      requiredErrorMsg: 'Bank account is required',
      invalidErrorMsg: 'Field is required'
    },
    bankRouting: {
      name: 'bankRouting',
      label: 'Bank Routing Number*',
      requiredErrorMsg: 'Bank routing is required'
    },
    billingNameBank:{
      name: 'billingNameBank',
      label: 'Billing Name*',
      requiredErrorMsg: 'Billing name is required'
    },
    statusOrders: {
      name: 'statusOrders',
      label: 'statusOrders Name*',
      requiredErrorMsg: 'status orders is required'
    },
    paymentMethods: {
      name: 'paymentMethods',
      label: 'paymentMethods Name*',
      requiredErrorMsg: 'Payment methods is required'
    },
    winterScheduleStatus: {
      name: 'winterScheduleStatus',
      label: 'winterScheduleStatus Name*',
      requiredErrorMsg: 'Winter schedule is required'
    },
    billingPoBox: {
      name: 'billingPoBox',
      label: 'Billing PO Box',
      requiredErrorMsg: 'Field is required'
    },
    billingPoBoxCity: {
      name: 'billingPoBoxCity',
      label: 'Billing PO Box City',
      requiredErrorMsg: 'Field is required'
    },
    billingPoBoxState: {
      name: 'billingPoBoxState',
      label: 'Billing PO Box State',
      requiredErrorMsg: 'Field is required'
    },
    billingPoBoxZipcode: {
      name: 'billingPoBoxZipcode',
      label: 'Billing PO Box ZIP Code',
      requiredErrorMsg: 'Field is required'
    },
    mailingPoBox: {
      name: 'mailingPoBox',
      label: 'Mailing PO Box',
      requiredErrorMsg: 'Field is required'
    },
    mailingPoBoxCity: {
      name: 'mailingPoBoxCity',
      label: 'Mailing PO Box City',
      requiredErrorMsg: 'Field is required'
    },
    mailingPoBoxState: {
      name: 'mailingPoBoxState',
      label: 'Mailing PO Box State',
      requiredErrorMsg: 'Field is required'
    },
    mailingPoBoxZipcode: {
      name: 'mailingPoBoxZipcode',
      label: 'Mailing PO Box ZIP Code',
      requiredErrorMsg: 'Field is required'
    },
    poBoxEnabled: {
      name: 'poBoxEnabled',
      label: '',
      requiredErrorMsg: ''
    },
    lat: {
      name: 'lat',
      label: 'Lat:',
      requiredErrorMsg: '',
      placeholder: '32.804134'
    },
    lng: {
      name: 'lng',
      label: 'Long:',
      requiredErrorMsg: '',
      placeholder: '-96.982847'
    },
    isServiceAddressManual: {
      name: 'isServiceAddressManual',
      label: 'Street address (Manual)',
      requiredErrorMsg: ''
    },
  }
};
