import React from 'react';
import {
  Button,
  Grid, IconButton,
} from '../../../../components/shared/MaterialUI';
import { useStyles } from '../../style/lookup-style';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const Lookup4 = (props) => {
  const classes = useStyles();
  const { setExpandedCollapse, expandedCollapse } = props;

  return (
    <>
      <Grid 
        item xs={12} 
        className={`${classes.gridColumns} ${classes.noPaddingRight}`}
        style={{padding: '0px'}}
        >
          <Grid container>

            <Grid item xs={4} className={classes.wrapperBb}>
               <Button
                size="small"
                variant="outlined"
                className={classes.buttonExpand}
                onClick={() => {
                  setExpandedCollapse(!expandedCollapse);
                }}
                endIcon={
                  <IconButton
                    className={classes.btnSelectedAll}
                    aria-label='delete'
                    onClick={() => {
                      setExpandedCollapse(!expandedCollapse);
                    }}
                  >
                    {expandedCollapse ? (
                      <ExpandLessIcon fontSize='small' style={{color:'green'}}/>
                    ) : (
                      <ExpandMoreIcon fontSize='small' style={{color:'green'}} className={classes.btnExpand} />
                    )}
                  </IconButton>}
              >
                Other search options
              </Button>
            </Grid>
    
          </Grid>
          
      </Grid>
    </>
  );
};

export default Lookup4;
