import React, {useContext, useState} from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import {
  Button, Checkbox, ClickAwayListener, FormControlLabel,
  Grid, IconButton, Tooltip, Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import GetAppIcon from '@material-ui/icons/GetApp';
import SelectAllIcon from '@material-ui/icons/SelectAll';
import { split as _split, filter as _filter, includes as _includes, reduce as _reduce } from 'lodash';
import { TaskService } from '../../services';
import { useUI } from '../../app/context/ui';
import AppHelper from '../../helpers/AppHelper';
import { columnsExportDb, srvFrecuencysDb } from '../../models/db';
import { columns } from './components/list-customer-columns.table';
import { ListCustomerContext } from './ListCustomer';
import { useStyles } from './style/list-costumer.style';
import ReportName from '../reports/components/ReportName';

let baseExports = columnsExportDb;

let dlgSettings = {
  confirm: false,
  btn: {
    close: 'Close',
    confirm: '',
  },
  onConfirm: () => { },
};

const ListCustomerHeader = ({
  handleLinkContextMenu = ()=>{}, 
  isLookupWithCellphone = false,
  type = ''
}) => {
  const {
    selectAllBtn,
    actionColumn,
    openButton,
    handleChangeCheckedUserAll,
    setOpenButton,
    setSelectAllBtn,
    setRenderColumns,
    rowsState,
    search,
    query,
  } = useContext(ListCustomerContext);


  const history = useHistory();
  const classes = useStyles();
  const { pathname, state } = useLocation();
  const condition = state?.condition || '';

  const { snackbarUI, dialogUI, blockUI } = useUI();
  const [reportNameModal, setReportNameModal] = useState(false);
  const [checkboxsSvg, setCheckboxsSvg] = useState(baseExports);
  const [typeDoc, setTypeDoc] = useState('xlsx');

  const taskService = new TaskService();

  const handleChangeCheckBoxSvg = (e) => {
    let res = checkboxsSvg.map((item) => {
      if (item.name === e.target.name) {
        return {
          ...item,
          value: e.target.checked,
        };
      } else {
        return { ...item };
      }
    });
    setCheckboxsSvg(res);
    return res;
  };

  const renderNewColumns = (e) => {
    const res = handleChangeCheckBoxSvg(e);
    let newColumns = [];
    res.filter((e) => {
      if (e.value) {
        if (e.field === 'cardType') {
          newColumns = [
            ...newColumns,
            {
              field: e.field,
              headerName: e.desc,
              flex: 0.3,
              minWidth: 200,
              disableColumnMenu: true,
              renderCell: (params) => {
                let res;
                switch (params.row.cardType) {
                  case 3:
                    res = 'American Express';
                    break;
                  case 4:
                    res = 'Visa';
                    break;
                  case 5:
                    res = 'Mastercard';
                    break;
                  case 6:
                    res = 'Discover';
                    break;
                  default:
                    res = 'Not registered';
                    break;
                }
                return res;
              },
            },
          ];
        }
        else if (e.field === 'subtotalMowing') {
          newColumns = [
            ...newColumns,
            {
              field: e.field,
              headerName: e.desc,
              flex: 0.3,
              minWidth: 200,
              disableColumnMenu: true,
              renderCell: (params) => {
                return `$${params.row.subtotalMowing} _ ${srvFrecuencysDb[params.row.srvFrecuency].label}`;
              },
            },
          ];
        }
        else if (e.field === 'totalMowing') {
          newColumns = [
            ...newColumns,
            {
              field: e.field,
              headerName: e.desc,
              flex: 0.3,
              minWidth: 200,
              disableColumnMenu: true,
              renderCell: (params) => {
                return (Number(params.row.taxRate) !== 0)
                  ? `$${params.row.totalMowing}`
                  : '';
              },
            },
            {
              field: 'no-tax',
              headerName: 'No Tax',
              flex: 0.3,
              minWidth: 200,
              disableColumnMenu: true,
              renderCell: (params) => {
                return (Number(params.row.taxRate) !== 0)
                  ? ''
                  : `$${params.row.totalMowing}`;
              },
            },
          ];
        }
        else if (e.field === 'lastEdited') {
          newColumns = [
            ...newColumns,
            {
              field: e.field,
              headerName: e.desc,
              minWidth: 200,
              disableColumnMenu: true,
              renderCell: (params) => {
                return params.row?.userEdit?.fullName;
              },
            },
          ];
        }
        else if (e.field === 'userNote') {
          newColumns = [
            ...newColumns,
            {
              field: e.field,
              headerName: e.desc,
              minWidth: 200,
              disableColumnMenu: true,
              renderCell: (params) => {
                return params.row?.userNote?.fullName;
              },
            },
          ];
        }
        else {
          newColumns = [
            ...newColumns,
            {
              field: e.field,
              headerName: e.desc,
              width: 160,
              align: 'center',
              headerAlign: 'center',
              disableColumnMenu: true,
            },
          ];
        }
      }
    });

    let columnsTemp = [];

    if (selectAllBtn) {
      columnsTemp = [
        {
          field: 'id',
          headerName: ' ',
          width: 80,
          align: 'center',
          disableColumnMenu: true,
          sortable: false,
          renderCell: (params) => {
            let status = false;
            return (
              <>
                <Checkbox
                  status={`${status}`}
                  iduser={`${params.value}`}
                  checked={true}
                  onChange={handleChangeCheckedUserAll}
                />
              </>
            );
          },
        },
        ...columns(handleLinkContextMenu, isLookupWithCellphone),
        ...newColumns,
      ];
    } else {
      columnsTemp = [
        ...actionColumn, 
        ...columns(handleLinkContextMenu, isLookupWithCellphone), 
        ...newColumns
      ];
    }

    const indexLastEdited = columnsTemp.findIndex(column => column.field === "lastEdited");
    const indexStatusName = columnsTemp.findIndex(column => column.field === "statusName");
    if (indexLastEdited !== -1 && indexStatusName !== -1) {
      const columnLastEdited = columnsTemp.splice(indexLastEdited, 1)[0];
      columnsTemp.splice(indexStatusName, 0, columnLastEdited);
    }
    setRenderColumns(columnsTemp);
    
  };

  const handleSelectAllClientsReport = () => {
    if (!selectAllBtn) {
      setRenderColumns([
        {
          field: 'id',
          headerName: ' ',
          width: 60,
          disableColumnMenu: true,
          sortable: false,
          renderCell: () => {
            return <Checkbox checked={true} />;
          },
        },
        ...columns(handleLinkContextMenu, isLookupWithCellphone),
      ]);
      localStorage.setItem('listClientExport', JSON.stringify([1]));
    } else {
      localStorage.setItem('listClientExport', JSON.stringify([]));
      setRenderColumns([
        ...actionColumn, 
        ...columns(handleLinkContextMenu, isLookupWithCellphone)
      ]);
    }
  };

  const handleGenerateSvg = async (nameDoc) => {
    try {
      blockUI.current.open(true);
      let clients = JSON.parse(localStorage.getItem('listClientExport'));
      if (clients.length > 0) {
        let foreignExists = false;
        let select = 'full_name,service_address,customer_id,name,status';

        checkboxsSvg.map((e) => {
          if (e.value) {
            select += `,${e.hidden}`;
          }
          if (e.hidden === 'city' && e.value) {
            foreignExists = true;
          }
        });

        clients = selectAllBtn ? 'all' : clients.toString();
        let download = clients === 'all' ? { clients } : { ids: clients };

        let filters = _split(search, '&') ?? [];

        if (condition) {
          const newFilters = _split(condition, '&');
          filters = [...newFilters];
        }

        let filter = _reduce(_filter(filters, function (o) {
          return _includes(o, 'filter');
        }), function (result, value) {
          const array = _split(value, '=');
          return { ...result, [array[0]]: array[1] };
        }, {}) ?? { 'filter[statusOrders]': '1' };

        taskService.getAccessToken();

        if(typeDoc === 'pdf'){
          let querySearch = '';
          for (const property in filter) {
            querySearch += `&${property}=${filter[property]}`;
          }
          querySearch = (querySearch === '') ? '&filter[status_orders]=1': querySearch;
          querySearch += "&"+ new URLSearchParams(download).toString()
          querySearch += "&nameDoc=" + nameDoc;

          const typeLookup = query.get('type_lookup');

          if(typeLookup){
            await taskService.addLookupReportBb(querySearch);
          }else{
            await taskService.addLookupReport(querySearch);
          }

        }else{
          await taskService.addCustomerReport({ 
            select, 
            foreignExists, 'type_doc': 
            typeDoc, 
            ...download, 
            'sort': '-id', 
            ...filter, 
            typeLookup: type,
            nameDoc
          }, isLookupWithCellphone);
        }

        dialogUI.current.open('Success', 'The report is being processed', dlgSettings, '', true, { route: '/report', message: 'Go to report' });
        blockUI.current.open(false);
      } else {
        blockUI.current.open(false);
        dialogUI.current.open('Alert', 'Select at least one customer', dlgSettings);
      }
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  return (
    <Grid container style={{ marginBottom: '12px' }}>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={9}>
            <Typography variant='h4' style={{ marginBottom: '30px' }}>
              {pathname === '/expired-card' ? 'Expired Card List' : 'Customer List'}
            </Typography>

          </Grid>
          <Grid item xs={3}>
            <Typography style={{ marginTop: '20px' }}>
              Search results: {rowsState && (rowsState.rowCount)} records
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {pathname !== '/expired-card' && (
        <>
          <Grid item xs={2}>
            <Tooltip title='Select all' placement='top'>
              <IconButton
                className={classes.btnSelectedAll}
                aria-label='delete'
                onClick={() => {
                  setSelectAllBtn(!selectAllBtn);
                  handleSelectAllClientsReport();
                }}
              >
                {selectAllBtn ? (
                  <SelectAllIcon fontSize='small' />
                ) : (
                  <SelectAllIcon fontSize='small' />
                )}
              </IconButton>
            </Tooltip>
            <Button
              variant='contained'
              color='primary'
              size='large'
              className={classes.button}
              startIcon={<AddIcon />}
              onClick={() => {
                history.push('create-customer');
              }}
            >
              Create customer
            </Button>
          </Grid>
          <Grid item xs={10}>
            <div className={classes.wrapperCheckboxSvg}>
              {
                checkboxsSvg &&
                  checkboxsSvg.map((e, i) => (
                    <Tooltip title={e.desc} placement='top' key={'checksvg' + i}>
                      <FormControlLabel
                        className={classes.checkboxSvg}
                        value={e.name}
                        control={
                          <Checkbox
                            size='small'
                            name={e.name}
                            checked={e.value}
                            onChange={renderNewColumns}
                          />
                        }
                        label={e.name}
                        labelPlacement='end'
                      />
                    </Tooltip>
                  ))
              }
              <ClickAwayListener
                onClickAway={() => {
                  setOpenButton(false);
                }}
              >
                <div className={classes.rootAwayListener}>
                  <Tooltip title='Choose the download XLSX/CSV/PDF' placement='top'>
                    <IconButton
                      aria-label='delete'
                      onClick={() => {
                        setOpenButton((prev) => !prev);
                      }}
                      className={classes.iconButtonSvg}
                    >
                      <GetAppIcon fontSize='small' />
                    </IconButton>
                  </Tooltip>
                  {openButton ? (
                    <div className={classes.awayDropdown}>
                      <Button
                        onClick={() => {
                          setReportNameModal(true);
                          setTypeDoc('xlsx');
                        }}
                        className={classes.iconButtonChoose}
                        variant='contained'
                        color='secondary'
                      >
                        EXCEL
                      </Button>
                      <Button
                        onClick={() => {
                          setReportNameModal(true);
                          setTypeDoc('csv');
                        }}
                        className={classes.iconButtonChoose}
                        variant='contained'
                        color='secondary'
                        disabled={(isLookupWithCellphone) ? true : false}
                      >
                        CSV
                      </Button>
                      <Button
                         onClick={() => {
                          setReportNameModal(true);
                          setTypeDoc('pdf');
                        }}
                        className={classes.iconButtonChoose}
                        variant='contained'
                        color='secondary'
                        disabled={(isLookupWithCellphone) ? true : false}
                      >
                        PDF
                      </Button>
                    </div>
                  ) : null}
                </div>
              </ClickAwayListener>

              {/* <Tooltip title='Select all' placement='top'>
                <IconButton
                  className={classes.btnSelectedAll}
                  aria-label='delete'
                  onClick={() => {
                    setSelectAllBtn(!selectAllBtn);
                    handleSelectAllClientsReport();
                  }}
                >
                  {selectAllBtn ? (
                     <SelectAllIcon fontSize='small' />
                  ) : (
                    <SelectAllIcon fontSize='small' />
                  )}
                </IconButton>
              </Tooltip> */}
            </div>
            <ReportName 
              open={reportNameModal} 
              setOpen={setReportNameModal} 
              handleGenerateSvg={handleGenerateSvg} 
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ListCustomerHeader;
