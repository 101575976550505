import React, { useCallback, useEffect, useState } from 'react';

import { isString as _isString, size as _size } from 'lodash';

import { IconButton, Modal, Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';

import { useUI } from '../../app/context/ui';
import AppHelper from '../../helpers/AppHelper';
import ReferralService from '../../services/ReferralService';
import CustomerReferral from './modals/CustomerReferral';
import ResignReferralTypes from './modals/ResignReferralTypes';
import { ReferralStyle } from './styles/ReferralStyle';
import { connect } from 'react-redux';

const referralService = new ReferralService();

let dlgSettings = {
  confirm: true,
  btn: {
    close: 'Cancel',
    confirm: 'Yes',
  },
  onConfirm: () => {},
};

const ReferralPanel = () => {
  const { blockUI, snackbarUI, dialogUI } = useUI();
  const [dataReferral, setDataReferral] = useState([]);
  const [flagActivateEdit, setFlagActivateEdit] = useState(false);
  const [openModalReferral, setOpenModalReferral] = useState(false);
  const [typeModalReferral, setTypeModalReferral] = useState('');
  const [dataEditReferral, setDataEditReferral] = useState({});
  const [rowsDeletesReferrals, setRowsDeletesReferrals] = useState([]);
  const [rowsDeletesTypesReferrals, setRowsDeletesTypesReferrals] = useState([]);
  const [openModalReferralType, setOpenModalReferralType] = useState(false);
  const [typeModalReferralType, setTypeModalReferralType] = useState('');
  const [dataEditReferralType, setDataEditReferralType] = useState({});

  const classes = ReferralStyle();
  referralService.getAccessToken();

  const handleOpenModalAddCustomerReferral = (type, dataEdit) => {
    setOpenModalReferral(true);
    setTypeModalReferral(type);
    setDataEditReferral(dataEdit);
  };

  const handleOpenModalAddCustomerReferralType = (type, dataEdit) => {
    setOpenModalReferralType(true);
    setTypeModalReferralType(type);
    setDataEditReferralType(dataEdit);
  };

  const getModalStyle = () => {
    return {
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%, -50%)`,
    };
  };

  const [modalStyle] = useState(getModalStyle);

  const bodyModalReferral = (
    <div style={modalStyle} className={classes.paperModal}>
      <br />
      <Grid container spacing={1}>
        <CustomerReferral
          typeModalReferral={typeModalReferral}
          setOpenModalReferral={setOpenModalReferral}
          dataEditReferral={dataEditReferral}
          dataReferral={dataReferral}
          setDataReferral={setDataReferral}
        />
      </Grid>
    </div>
  );

  const bodyModalReferralType = (
    <div style={modalStyle} className={classes.paperModal}>
      <br />
      <Grid container spacing={1}>
        <ResignReferralTypes
          typeModalReferralType={typeModalReferralType}
          setOpenModalReferralType={setOpenModalReferralType}
          dataEditReferralType={dataEditReferralType}
          dataReferral={dataReferral}
          setDataReferral={setDataReferral}
        />
      </Grid>
    </div>
  );

  const onDelete = (id, name) => {
    try {
      blockUI.current.open(true);
      if (id === null) {
        let positionDeleteRow = dataReferral.findIndex((e) => e.name === name);
        dataReferral.splice(positionDeleteRow, 1);
        setDataReferral([...dataReferral]);
      } else {
        const positionDeleteRow = dataReferral.findIndex((e) => e.id === id);
        dataReferral.splice(positionDeleteRow, 1);
        setDataReferral([...dataReferral]);
        setRowsDeletesReferrals([...rowsDeletesReferrals, id]);
      }
      dialogUI.current.close();
      blockUI.current.open(false);
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const onDeleteTypeReferral = (id, idReferral, idTypeReferral) => {
    try {
      blockUI.current.open(true);
      dataReferral[idReferral].types.splice(id, 1);
      setDataReferral([...dataReferral]);
      if (idTypeReferral) {
        setRowsDeletesTypesReferrals([...rowsDeletesTypesReferrals, idTypeReferral]);
      }
      dialogUI.current.close();
      blockUI.current.open(false);
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const handleSetterIdDelete = (id, name) => {
    dlgSettings = {
      ...dlgSettings,
      onConfirm: () => {
        onDelete(id, name);
      },
    };
    dialogUI.current.open('Market', 'Are you sure to delete this market?', dlgSettings);
  };

  const handleSetterIdDeleteTypeReferral = (index, idReferral, idTypeReferral) => {
    dlgSettings = {
      ...dlgSettings,
      onConfirm: () => {
        onDeleteTypeReferral(index, idReferral, idTypeReferral);
      },
    };
    dialogUI.current.open('No resign referral', 'Are you sure to delete this customer referral?', dlgSettings);
  };

  const handleSaveChanges = () => {
    try {
      blockUI.current.open(true);
      dataReferral.map(async (e) => {
        if (e.id === null) {
          let { data: resp } = await referralService.create({ name: e.name, isResign: 1 });
          await e.types.map((type) => {
            referralService.createType({ ...type, customerReferralId: resp.id });
          });
        } else {
          referralService.update({ name: e.name }, e.id);
          await e.types.map((type) => {
            if (type.id === '') {
              referralService.createType(type);
            } else {
              referralService.updateType(type, type.id);
            }
          });
        }
      });
      rowsDeletesTypesReferrals.map((item) => {
        referralService.deleteType(item);
      });
      rowsDeletesReferrals.map((item) => {
        referralService.delete(item);
      });
      blockUI.current.open(false);
      setFlagActivateEdit(false);
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const getListResign = useCallback(async () => {
    try {
      blockUI.current.open(true);
      const { data: referrals } = await referralService.list('?filter[is_resign]=1');
      setDataReferral(referrals);
      blockUI.current.open(false);
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  }, [blockUI, snackbarUI]);

  useEffect(() => {
    (async function init() {
      await getListResign();
    })();
  }, [getListResign]);

  return (
    <Container maxWidth='lg' disableGutters>
      <Grid container>
        <Grid item xs={12} sm={11}>
          <h2 className={classes.h2}>No Resign Referral</h2>
        </Grid>
        <Grid item xs={12} sm={1}>
          {flagActivateEdit ? (
            <Button
              variant='contained'
              color='secondary'
              className={classes.buttonEditEnabled}
              onClick={() => setFlagActivateEdit(false)}
              startIcon={<ClearIcon />}
            >
              Cancel
            </Button>
          ) : (
            <Button
              variant='contained'
              color='secondary'
              className={classes.buttonEditEnabled}
              onClick={() => setFlagActivateEdit(true)}
              startIcon={<EditIcon />}
            >
              Edit
            </Button>
          )}
        </Grid>
      </Grid>
      <Container className={classes.container}>
        <div>
          <Grid container>
            {flagActivateEdit && (
              <Grid item sm={12}>
                <Button
                  style={{ marginLeft: '10px', width: '174px' }}
                  variant='contained'
                  color='secondary'
                  className={classes.buttonEditEnabled}
                  onClick={() => {
                    handleOpenModalAddCustomerReferral('Add');
                  }}
                  startIcon={<AddCircleIcon />}
                >
                  Add Market
                </Button>
              </Grid>
            )}
            {dataReferral.map((e, indexReferral) => (
              <>
                <Grid className={classes.wrapperTable} item sm={6} key={`resing${indexReferral}`}>
                  <table>
                    <thead>
                      <tr>
                        <th colSpan={2}>{e.name}</th>
                        <th>
                          <Tooltip title='Edit Market'>
                            <IconButton
                              component='div'
                              style={{ width: '0px', marginRight: '10px' }}
                              variant='contained'
                              color='secondary'
                              disabled={!flagActivateEdit}
                              className={flagActivateEdit ? classes.buttonEditEnabled : classes.buttonEditDisabled}
                              onClick={() => {
                                handleOpenModalAddCustomerReferral('Edit', { id: e.id, name: e.name });
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title='Delete Market'>
                            <IconButton
                              component='div'
                              style={{ width: '0px', marginRight: '10px' }}
                              variant='contained'
                              color='secondary'
                              disabled={!flagActivateEdit}
                              className={flagActivateEdit ? classes.buttonEditEnabled : classes.buttonEditDisabled}
                              onClick={() => {
                                handleSetterIdDelete(e.id, e.name);
                              }}
                            >
                              <DeleteForeverIcon />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title='Add customer referral'>
                            <IconButton
                              component='div'
                              style={{
                                width: '0px',
                                float: 'right',
                                marginRight: '25px',
                                marginTop: '5px',
                              }}
                              variant='contained'
                              color='secondary'
                              onClick={() => {
                                handleOpenModalAddCustomerReferralType('Add', {
                                  idReferral: e.id,
                                  nameReferral: e.name,
                                });
                              }}
                              disabled={!flagActivateEdit}
                              className={flagActivateEdit ? classes.buttonEditEnabled : classes.buttonEditDisabled}
                            >
                              <AddCircleIcon />
                            </IconButton>
                          </Tooltip>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {_size(e.types) > 0 ? (
                        e.types.map((type, index) => (
                          <tr key={`types${index}`}>
                            <td>{type.name}</td>
                            <td>
                              {type.phone}
                              {_isString(type.phone) && _isString(type.website) ? ' / ' : ''}
                              {type.website}
                            </td>
                            <td>
                              <Tooltip title='Edit' placement='right-start'>
                                <IconButton
                                  component='div'
                                  style={{ width: '0px', marginRight: '5px' }}
                                  variant='contained'
                                  color='secondary'
                                  disabled={!flagActivateEdit}
                                  className={flagActivateEdit ? classes.buttonEditEnabled : classes.buttonEditDisabled}
                                  onClick={() => {
                                    handleOpenModalAddCustomerReferralType('Edit', {
                                      ...type,
                                      idReferral: e.id,
                                      nameReferral: e.name,
                                      index,
                                    });
                                  }}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>

                              <Tooltip title='Delete' placement='right'>
                                <IconButton
                                  component='div'
                                  style={{ width: '0px' }}
                                  variant='contained'
                                  color='secondary'
                                  disabled={!flagActivateEdit}
                                  className={flagActivateEdit ? classes.buttonEditEnabled : classes.buttonEditDisabled}
                                  onClick={() => {
                                    handleSetterIdDeleteTypeReferral(index, indexReferral, type.id);
                                  }}
                                >
                                  <DeleteForeverIcon />
                                </IconButton>
                              </Tooltip>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={3}>No Referrals</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </Grid>
              </>
            ))}
          </Grid>
        </div>
        <Grid container justify='center'>
          <Button
            variant='contained'
            color='secondary'
            disabled={!flagActivateEdit}
            className={classes.buttonUpdate}
            startIcon={<SaveIcon />}
            onClick={handleSaveChanges}
          >
            Update
          </Button>
        </Grid>
      </Container>
      <Modal
        open={openModalReferral}
        onClose={() => {
          setOpenModalReferral(false);
        }}
      >
        {bodyModalReferral}
      </Modal>

      <Modal
        open={openModalReferralType}
        onClose={() => {
          setOpenModalReferralType(false);
        }}
      >
        {bodyModalReferralType}
      </Modal>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(ReferralPanel);
