import { Grid } from '@material-ui/core';
import React from 'react';

const Activity = (props) => {

  return (
    <div>
      {
        props.activities.map((e,i)=>(
          <Grid container className="ctnInfoActivityList" key={`activity${i}`}>
            <Grid item xs={2} className="ctnHours">
              <div className="at">{e.workday}</div>
            </Grid>
            <Grid item xs={10} className="ctnInfo">
              <div className="infoTitle">
                {e.description}
              </div>
            </Grid>
          </Grid>
        ))
      }
    </div>
  )
}

export default Activity;
