import React, { useCallback, useMemo } from 'react';
import { Grid } from '@material-ui/core';

import { Formik, Form, useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';

import { InputField } from '../../../forms';
import { WizardStyles } from '../../../assets/css';
import CustomerService from '../../../services/CustomerService';
import AutoSave from '../../../forms/AutoSave';
import { updateProperty } from '../../../app/store/mm/customerSlice';
import AppHelper from '../../../helpers/AppHelper';
import { useUI } from '../../../app/context/ui';

export default function Step7(props) {
  const {blockUI, snackbarUI} = useUI();

  const {
    formField: {
      conditionSpanish,
      requestSpanish,
    }
  } = props;

  const wizardStyle = WizardStyles();

  const dispatch = useDispatch();
  const {values: formValues} = useFormikContext();
  const {id} = formValues;

  const initialValues = {
    conditionSpanish: formValues.conditionSpanish ?? "",
    requestSpanish: formValues.requestSpanish ?? "",
    conditionSpanishTranslation: formValues.conditionSpanishTranslation ?? "",
    requestSpanishTranslation: formValues.requestSpanishTranslation ?? "",
  };

  const customerService = useMemo(() => new CustomerService(), []);

  const handleSubmit = useCallback(async (values) => {
    try {
      blockUI.current.open(true);
      customerService.getAccessToken();
      await customerService.update(values, id);
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  }, [blockUI, customerService, dispatch, id, snackbarUI]);

  const updateConditionSpanish = async (setFieldValue, name, value) => {
    try {
      blockUI.current.open(true);
      const { data: { 
        conditionSpanishTranslation, 
        requestSpanishTranslation
      } } = await customerService.read(id);

      setFieldValue([name], value);
      setFieldValue('conditionSpanishTranslation', conditionSpanishTranslation ?? '');
      setFieldValue('requestSpanishTranslation', requestSpanishTranslation ?? '');
      
      dispatch(updateProperty({
        'conditionSpanishTranslation': conditionSpanishTranslation ?? '',
        'requestSpanishTranslation': requestSpanishTranslation ?? '',
        [name]: value
      }));

      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  }

  const handleOnBlur = (e, setFieldValue) => {
    blockUI.current.open(true);
    const { name, value } = e.target;
    if (
      Object.prototype.hasOwnProperty.call(initialValues, name) &&
      value !== initialValues[name]
    ) {
      handleSubmit({
        [name]: value,
      }).then(() => {
        updateConditionSpanish(setFieldValue, name, value);
      });
    }
    blockUI.current.open(false);
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue }) => (
          <Form autoComplete="off">
            <Grid container
                  justifyContent="center"
                  alignItems="flex-start"
                  spacing={2}>
              <Grid item xs={12}>
                <span className={wizardStyle.label}>Conditions</span>
              </Grid>
              <Grid item xs={12}>
                <InputField 
                  name={conditionSpanish.name} 
                  onBlur={(e)=>{handleOnBlur(e, setFieldValue)}}
                  rows={3} 
                  multiline
                  fullWidth 
                />
              </Grid>
              <Grid item xs={12}>
                <InputField 
                  name="conditionSpanishTranslation"
                  rows={3} 
                  multiline
                  fullWidth 
                />
              </Grid>
              <Grid item xs={12}>
                <span className={wizardStyle.label}>Requests</span>
              </Grid>
              <Grid item xs={12}>
                <InputField 
                  name={requestSpanish.name} 
                  onBlur={(e)=>{handleOnBlur(e, setFieldValue)}}
                  rows={3} 
                  multiline 
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <InputField 
                  name="requestSpanishTranslation"
                  rows={3} 
                  multiline
                  fullWidth 
                />
              </Grid>
              <Grid item xs={12}>
                <>
                  <AutoSave debounceMs={500} id={id} />
                </>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}
