import {makeStyles} from '@material-ui/core/styles';

export const ReportStyles = makeStyles(() => ({
  title: { 
    marginBottom: '30px', 
    fontWeight: 'bold' 
  },
  wrapperButton: {
    textAlign: 'right'
  },
  wrapperButtonDelete: {
    backgroundColor: 'black',
    borderRadius: '4px',
    color: 'white',
    marginLeft: '7px',
    '& :hover': {
      backgroundColor: 'black',
      borderRadius: '4px',
      color: 'white'
    }
  },
}));
