import React, { useCallback, useEffect, useState } from 'react';

import { IconButton, Modal, Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';

import { useUI } from '../../app/context/ui';
import AppHelper from '../../helpers/AppHelper';
import ReferralService from '../../services/ReferralService';
import CustomerReferral from './modals/CustomerReferral';
import CustomerReferralType from './modals/CustomerReferralTypes';
import { ReferralStyle } from './styles/ReferralStyle';
import { connect } from 'react-redux';

const referralService = new ReferralService();

let dlgSettings = {
  confirm: true,
  btn: {
    close: 'Cancel',
    confirm: 'Yes',
  },
  onConfirm: () => {},
};

const ReferralPanel = () => {
  const classes = ReferralStyle();
  const { blockUI, snackbarUI, dialogUI } = useUI();
  const [dataReferral, setDataReferral] = useState([]);
  const [flagActivateEdit, setFlagActivateEdit] = useState(false);
  const [openModalReferral, setOpenModalReferral] = useState(false);
  const [typeModalReferral, setTypeModalReferral] = useState('');
  const [dataEditReferral, setDataEditReferral] = useState({});
  const [rowsDeletesReferrals, setRowsDeletesReferrals] = useState([]);
  const [rowsDeletesTypesReferrals, setRowsDeletesTypesReferrals] = useState([]);
  const [openModalReferralType, setOpenModalReferralType] = useState(false);
  const [typeModalReferralType, setTypeModalReferralType] = useState('');
  const [dataEditReferralType, setDataEditReferralType] = useState({});
  referralService.getAccessToken();

  const handleActivateEdit = () => {
    setFlagActivateEdit(true);
  };

  const handleDesactivateEdit = () => {
    setFlagActivateEdit(false);
  };

  const handleOpenModalAddCustomerReferral = (type, dataEdit) => {
    setOpenModalReferral(true);
    setTypeModalReferral(type);
    setDataEditReferral(dataEdit);
  };

  const handleOpenModalAddCustomerReferralType = (type, dataEdit) => {
    setOpenModalReferralType(true);
    setTypeModalReferralType(type);
    setDataEditReferralType(dataEdit);
  };

  const getModalStyle = () => {
    return {
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%, -50%)`,
    };
  };

  const [modalStyle] = useState(getModalStyle);

  const bodyModalReferral = (
    <div style={modalStyle} className={classes.paperModal}>
      <br />
      <Grid container spacing={1}>
        <CustomerReferral
          typeModalReferral={typeModalReferral}
          setOpenModalReferral={setOpenModalReferral}
          dataEditReferral={dataEditReferral}
          dataReferral={dataReferral}
          setDataReferral={setDataReferral}
        />
      </Grid>
    </div>
  );

  const bodyModalReferralType = (
    <div style={modalStyle} className={classes.paperModal}>
      <br />
      <Grid container spacing={1}>
        <CustomerReferralType
          typeModalReferralType={typeModalReferralType}
          setOpenModalReferralType={setOpenModalReferralType}
          dataEditReferralType={dataEditReferralType}
          dataReferral={dataReferral}
          setDataReferral={setDataReferral}
        />
      </Grid>
    </div>
  );

  const onDelete = (id, name) => {
    try {
      blockUI.current.open(true);
      if (id === null) {
        let positionDeleteRow = dataReferral.findIndex((e) => e.name === name);
        dataReferral.splice(positionDeleteRow, 1);
        setDataReferral([...dataReferral]);
      } else {
        const positionDeleteRow = dataReferral.findIndex((e) => e.id === id);
        dataReferral.splice(positionDeleteRow, 1);
        setDataReferral([...dataReferral]);
        setRowsDeletesReferrals([...rowsDeletesReferrals, id]);
      }
      dialogUI.current.close();
      blockUI.current.open(false);
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const onDeleteTypeReferral = (id, idReferral, idTypeReferral) => {
    try {
      blockUI.current.open(true);
      dataReferral[idReferral].types.splice(id, 1);
      setDataReferral([...dataReferral]);
      if (idTypeReferral) {
        setRowsDeletesTypesReferrals([...rowsDeletesTypesReferrals, idTypeReferral]);
      }
      dialogUI.current.close();
      blockUI.current.open(false);
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const handleSetearIdDelete = (id, name) => {
    dlgSettings = {
      ...dlgSettings,
      onConfirm: () => {
        onDelete(id, name);
      },
    };
    dialogUI.current.open('Market', 'Are you sure to delete this market?', dlgSettings);
  };

  const handleSetearIdDeleteTypeReferral = (index, idReferral, idTypeReferral) => {
    dlgSettings = {
      ...dlgSettings,
      onConfirm: () => {
        onDeleteTypeReferral(index, idReferral, idTypeReferral);
      },
    };
    dialogUI.current.open('Customer Referral', 'Are you sure to delete this customer referral?', dlgSettings);
  };

  const handleSaveChanges = () => {
    try {
      blockUI.current.open(true);
      dataReferral.map(async (e) => {
        if (e.id === null) {
          let { data: resp } = await referralService.create({ name: e.name });
          await e.types.map((type) => {
            referralService.createType({ ...type, customerReferralId: resp.id });
          });
        } else {
          referralService.update({ name: e.name }, e.id);
          await e.types.map((type) => {
            if (type.id === '') {
              referralService.createType(type);
            } else {
              referralService.updateType(type, type.id);
            }
          });
        }
      });
      rowsDeletesTypesReferrals.map((item) => {
        referralService.deleteType(item);
      });
      rowsDeletesReferrals.map((item) => {
        referralService.delete(item);
      });
      blockUI.current.open(false);
      setFlagActivateEdit(false);
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const getDataReferrals = useCallback(async () => {
    try {
      blockUI.current.open(true);
      const { data: referrals } = await referralService.list('?filter[is_resign]=0');
      setDataReferral(referrals);
      blockUI.current.open(false);
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  }, [blockUI, snackbarUI]);

  useEffect(() => {
    getDataReferrals();
  }, [getDataReferrals]);

  return (
    <Container maxWidth='lg' disableGutters>
      <Grid container>
        <Grid item xs={12} sm={11}>
          <h2 className={classes.h2}>Active Customer Referral</h2>
        </Grid>
        <Grid item xs={12} sm={1}>
          {flagActivateEdit ? (
            <Button
              variant='contained'
              color='secondary'
              className={classes.buttonEditEnabled}
              onClick={handleDesactivateEdit}
              startIcon={<ClearIcon />}
            >
              Cancel
            </Button>
          ) : (
            <Button
              variant='contained'
              color='secondary'
              className={classes.buttonEditEnabled}
              onClick={handleActivateEdit}
              startIcon={<EditIcon />}
            >
              Edit
            </Button>
          )}
        </Grid>
      </Grid>
      <Container className={classes.container}>
        <div>
          <Grid container>
            {flagActivateEdit && (
              <Grid item sm={12}>
                <Button
                  style={{ marginLeft: '10px', width: '174px' }}
                  variant='contained'
                  color='secondary'
                  className={classes.buttonEditEnabled}
                  onClick={() => {
                    handleOpenModalAddCustomerReferral('Add');
                  }}
                  startIcon={<AddCircleIcon />}
                >
                  Add Market
                </Button>
              </Grid>
            )}
            <Grid item sm={12}>
              {dataReferral.map((e, indexReferral) => (
                <Grid className={classes.wrapperTable} item sm={12} key={`referral${e.id}`}>
                  <table>
                    <caption>
                      <span style={{ marginRight: '10px' }}>{e.name}</span>
                      <Tooltip title='Edit Market'>
                        <IconButton
                          style={{ width: '0px', marginRight: '10px' }}
                          variant='contained'
                          color='secondary'
                          disabled={!flagActivateEdit}
                          className={flagActivateEdit ? classes.buttonEditEnabled : classes.buttonEditDisabled}
                          onClick={() => {
                            handleOpenModalAddCustomerReferral('Edit', { id: e.id, name: e.name });
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title='Delete Market'>
                        <IconButton
                          style={{ width: '0px' }}
                          variant='contained'
                          color='secondary'
                          disabled={!flagActivateEdit}
                          className={flagActivateEdit ? classes.buttonEditEnabled : classes.buttonEditDisabled}
                          onClick={() => {
                            handleSetearIdDelete(e.id, e.name);
                          }}
                        >
                          <DeleteForeverIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title='Add customer referral'>
                        <IconButton
                          style={{ width: '0px', float: 'right', marginRight: '25px', marginTop: '5px' }}
                          variant='contained'
                          color='secondary'
                          onClick={() => {
                            handleOpenModalAddCustomerReferralType('Add', { idReferral: e.id, nameReferral: e.name });
                          }}
                          disabled={!flagActivateEdit}
                          className={flagActivateEdit ? classes.buttonEditEnabled : classes.buttonEditDisabled}
                        >
                          <AddCircleIcon />
                        </IconButton>
                      </Tooltip>
                    </caption>
                    <thead>
                      <tr>
                        <th>Services</th>
                        <th>Name</th>
                        <th>Phone</th>
                        <th>Comments</th>
                        <th>2nd Choice</th>
                        <th>2nd Choice Number</th>
                        <th>Comments</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {e.types.map((type, index) => (
                        <tr key={`types${e.id}`}>
                          <td>{type.service}</td>
                          <td>{type.name}</td>
                          <td>{type.phone}</td>
                          <td>{type.comment}</td>
                          <td>{type.secondChoice}</td>
                          <td>{type.secondPhone}</td>
                          <td>{type.secondComment}</td>
                          <td>
                            <Tooltip title='Edit' placement='right-start'>
                              <IconButton
                                style={{ width: '0px', marginRight: '5px' }}
                                variant='contained'
                                color='secondary'
                                disabled={!flagActivateEdit}
                                className={flagActivateEdit ? classes.buttonEditEnabled : classes.buttonEditDisabled}
                                onClick={() => {
                                  handleOpenModalAddCustomerReferralType('Edit', {
                                    ...type,
                                    idReferral: e.id,
                                    nameReferral: e.name,
                                    index,
                                  });
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title='Delete' placement='right'>
                              <IconButton
                                style={{ width: '0px' }}
                                variant='contained'
                                color='secondary'
                                disabled={!flagActivateEdit}
                                className={flagActivateEdit ? classes.buttonEditEnabled : classes.buttonEditDisabled}
                                onClick={() => {
                                  handleSetearIdDeleteTypeReferral(index, indexReferral, type.id);
                                }}
                              >
                                <DeleteForeverIcon />
                              </IconButton>
                            </Tooltip>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </div>
        <Grid container justify='center'>
          <Button
            variant='contained'
            color='secondary'
            disabled={!flagActivateEdit}
            className={classes.buttonUpdate}
            startIcon={<SaveIcon />}
            onClick={handleSaveChanges}
          >
            Update
          </Button>
        </Grid>
      </Container>

      <Modal
        open={openModalReferral}
        onClose={() => {
          setOpenModalReferral(false);
        }}
      >
        {bodyModalReferral}
      </Modal>

      <Modal
        open={openModalReferralType}
        onClose={() => {
          setOpenModalReferralType(false);
        }}
      >
        {bodyModalReferralType}
      </Modal>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(ReferralPanel);
