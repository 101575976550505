import React, { useEffect, useState } from 'react';
import { Box, FormControl, Grid, Select, makeStyles } from '@material-ui/core';
import { useUI } from '../../app/context/ui';
import AppHelper from '../../helpers/AppHelper';
import { MarketMapService, MarketService } from '../../services';
import { Utils } from '../../helpers';
import MapContainer from './MapContainer';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/moment';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    marginRight: '50px'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  wrapperMap: { 
    height: 580, 
    width: '100%', 
    backgroundColor: 'white', 
    marginTop: '15px', 
    padding: '25px' 
  },
  titleMarket: { 
    marginBottom: '20px', 
    fontSize: '28px', 
    fontWeight: '700' 
   }
}));

const marketService = new MarketService();
const marketMapService = new MarketMapService();

const ListMarket = () => {

  const { blockUI, snackbarUI } = useUI();
  const classes = useStyles();
  const [market, setMarket] = React.useState({
    value: '',
    items: []
  });

  const [customersMap, setCustomersMap] = useState({
    total: 0,
    data: []
  });

  const todays = moment(new Date()).format('MM/DD/yyyy');
  const [date, setDate] = React.useState(moment(todays, 'MM/DD/yyyy').nextBusinessDay()._d);

  const [reloadCustomersMap, setReloadCustomersMap] = useState(true);

  const handleChange = (event) => {
    setMarket({
      ...market,
      value: event.target.value
    });
    setReloadCustomersMap(true);
    setDate(moment(todays, 'MM/DD/yyyy').nextBusinessDay()._d);
  };
  
  const getListMarkets = async () => {
    try {
      blockUI.current.open(true);
      marketService.getAccessToken();
      const r1 = await marketService.list();
      const arr = Utils.updateArrayObjectItem(r1.data, []);
      setMarket({
        value: arr[0].value,
        items: arr
      });
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const getMarketMap = async (marketId) => {
    try {
      blockUI.current.open(true);
      marketMapService.getAccessToken();
      let dateSearch = moment(new Date(date)).format('yyyy-MM-DD');
      const { data } = await marketMapService.list(`market=${marketId}&date=${dateSearch}`);
      setCustomersMap(data);
      setReloadCustomersMap(false);
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  useEffect(() => {
    getListMarkets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    (market.value && reloadCustomersMap) && getMarketMap(market.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[market.value, reloadCustomersMap, date]);

  return (
    <>
      <Grid container style={{ marginBottom: '12px' }}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <div className={classes.titleMarket}>
                Market
              </div>
            </Grid>
            <Grid item xs={12} style={{display: 'flex', alignItems: 'center'}}>
              <div style={{fontWeight: 'bold'}}>
                Market:
              </div> 
              <FormControl 
                variant="outlined" 
                className={classes.formControl} 
                size="small"
              >
                <Select
                  native
                  value={market.value}
                  onChange={handleChange}
                >
                  {
                    market.items?.map((item)=>(
                      <option 
                        key={item.value} 
                        value={item.value}>
                          {item.label}
                      </option>
                    ))
                  }
                </Select>
              </FormControl>
              <div style={{fontWeight: 'bold', marginRight: '10px'}}>
                Date:
              </div> 
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  value={date}
                  inputVariant='outlined'
                  variant='outlined'
                  disableToolbar
                  format='MM/DD/yyyy'
                  maxDate={new Date('2050/12/31')}
                  disablePast
                  autoOk
                  onChange={(e) =>{
                    setDate(e._d);
                    setReloadCustomersMap(true);
                  }}
                  minDateMessage={null}
                  size="small"
                />
              </MuiPickersUtilsProvider>
              <div style={{marginLeft: '45px'}}>
                <strong>Total Customers: </strong>
                { customersMap.total }
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Box className={classes.wrapperMap}>
          {
            <MapContainer 
              data={customersMap.data} 
              setReloadCustomersMap={setReloadCustomersMap}
            />
          }
        </Box>
      </Grid>
    </>
  )
}

export default ListMarket;
