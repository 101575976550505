import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Box, Divider, Grid, Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import BorderColorIcon from '@material-ui/icons/BorderColor';

import { useFormikContext } from 'formik';
import { isNull as _isNull } from 'lodash';

import BillingNoteModal from '../../../components/note/crud/BillingNoteModal';
import {  WizardStyles } from '../../../assets/css';
import { NoteService } from '../../../services';
import { useUI } from '../../../app/context/ui';
import AppHelper from '../../../helpers/AppHelper';
import { DataGridStyle } from '../../../assets/css/data-grid-style';
import { PickItemServiceData } from '../../../services/pickItemService';
import { SelectStatus } from './components/SelectStatus';
import moment from "moment";

let columns = [
  {
    field: 'serviceDate',
    headerName: 'Date',
    width: 100,
    sortable: false,
    renderHeader: () => {
      return (<div style={{paddingLeft: '15px'}}>Date</div>);
    },
    renderCell: (params) => {
      return (<div style={{paddingLeft: '15px', fontSize: '12px'}}>{params.row.serviceDate}</div>);
    }
  },
  {
    field: 'reasonName',
    headerName: 'Reason',
    width: 140,
    sortable: false,
    renderHeader: () => {
      return (<div style={{paddingLeft: '8px'}}>Reason</div>);
    },
    renderCell: (params) => {
      return (<div style={{paddingLeft: '8px', fontSize: '12px'}}>{params.value}</div>);
    }
  },
  {
    field: 'description',
    headerName: 'Note',
    flex: 1,
    minWidth: 450,
    sortable: false,
    renderHeader: () => {
      return (<div style={{paddingLeft: '181px'}}>Note</div>);
    },
    renderCell: (params) => {
      return (<span style={{padding: '25px 0px', fontSize: '12px', textAlign: 'justify'}}>{_isNull(params.value) ? '' : params.value}</span>)
    }
  },
  {
    field: 'status',
    headerName: 'Note',
    flex: 0.5,
    minWidth: 80,
    sortable: false,
    renderHeader: () => {
      return (<div style={{paddingLeft: '181px'}}>Status</div>);
    },
  },
  {
    field: 'action',
    headerName: 'Actions',
    sortable: false,
    width: 100,
    disableClickEventBubbling: true,
    renderHeader: () => {
      return (<IconButton style={{paddingLeft: '18px'}}><EditIcon/></IconButton>);
    },
  },
];

let dlgSettings = {
  confirm: true,
  btn: {
    close: 'Cancel',
    confirm: 'Yes',
  },
  onConfirm: () => {},
};

export default function Step5() {
  const [rows, setRows] = useState([]);
  const wizardStyle = WizardStyles();
  const { dialogUI, snackbarUI } = useUI();
  const [loading, setLoading] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [allEdit] = useState(false);
  const { values: formValues } = useFormikContext();

  const [openNote, setOpenNote] = useState(false);
  const [selectedNote, setSelectedNote] = useState({});

  const noteService = useMemo(() => new NoteService(), []);
  noteService.getAccessToken();

  const pickItemServiceData = useMemo(() => new PickItemServiceData(), []);

  let queryForListNotes = 'filter[customer_attributes_id]=CA_ID&filter[type_id]=7&render=paginate&sort=-id' +
    '&include=attachments,users';
  queryForListNotes = queryForListNotes.replace('CA_ID', formValues.id);
  dlgSettings.onConfirm = () => onDelete();

  columns[3].renderCell = (params) => (
    <SelectStatus params={params} />
  );

  columns[4].renderCell = (params) => (
    <IconButton onClick={() => onEdit(params.row)}>
      <EditIcon />
    </IconButton>
  );

  const onEdit = (row) => {
    let thisRow = row;
    thisRow['disabled'] = true;
    thisRow['serviceDate'] = thisRow['serviceDate'].replace(/-/g, '/');
    if (!_isNull(thisRow['serviceHour'])) {
      const time = moment(thisRow['serviceHour'], 'hh:mm A');
      thisRow = {...thisRow, 'serviceHour': time.format('HH:mm')};
    }
    setSelectedNote(thisRow);
    setOpenNote(true);
  };

  const onDelete = async () => {
    try {
      const queryParams = selectedIds.map(value => 'ids[]=' + value).join('&');
      dialogUI.current.close();
      await noteService.deleteByIds(queryParams);
      await getNotes(queryForListNotes);
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const getNotes = useCallback(
    async (search) => {
      try {
        setLoading(true);
        const data = await pickItemServiceData.getAboutOption(7);
        const r = await noteService.list(search);
        const rowsFormatted = r.data.data.map((item) => {
          const row = item;
          const reason = data.find((option) => option.value === item.reason)
          row['reasonName'] = reason ? reason.label : '';
          return row;
        });
        setRows(rowsFormatted);
        setLoading(false);
      } catch (e) {
        AppHelper.checkError(e, snackbarUI);
      }
    },
    [noteService, snackbarUI, pickItemServiceData]
  );

  const onAfterSend = useCallback(
    async (title, message) => {
      setOpenNote(false);
      await getNotes(queryForListNotes);
      dialogUI.current.open(title, message);
    },
    [getNotes, dialogUI, queryForListNotes]
  );


  useEffect(() => {
    (async function init() {
      await getNotes(queryForListNotes);
    })();
  }, [getNotes, formValues.id, queryForListNotes]);

  return (
    <>
      <Grid container direction='row' justifyContent='center' alignItems='flex-start'>
        <Grid item xs={12}>
          <Grid container direction='row' justifyContent='flex-end' alignItems='center' spacing={2} className={wizardStyle.wrapperBtnsStep1}>
            {allEdit && (
            <Grid item xs={12} sm={3}>
              <Button
                variant='contained'
                color='primary'
                size='small'
                className={wizardStyle.buttonTransform}
                startIcon={<BorderColorIcon />}
                fullWidth
              >
                All notes
              </Button>
            </Grid>
            )}
            <Grid item xs={12} sm={2}>
              <Button
                variant='contained'
                color='primary'
                size='small'
                className={wizardStyle.buttonTransform}
                startIcon={<DeleteIcon />}
                onClick={() => {
                  if (selectedIds.length > 0) {
                    dialogUI.current.open('Notes', 'Are you sure to delete this note(s)?', dlgSettings);
                  }
                }}
                fullWidth
              >
                Delete
              </Button>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button
                variant='contained'
                color='primary'
                size='small'
                className={wizardStyle.buttonTransform}
                startIcon={<AddIcon />}
                onClick={() => {
                  setSelectedNote({});
                  setOpenNote(true);
                }}
                fullWidth
              >
                New Note
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Box height={'310px'}>
            <DataGridStyle
              disableVirtualization
              columns={columns}
              rows={rows}
              pageSize={5}
              disableColumnMenu
              loading={loading}
              checkboxSelection
              onSelectionModelChange={ids => setSelectedIds(ids)}
            />
          </Box>
        </Grid>
      </Grid>
      <BillingNoteModal
        open={openNote}
        setOpen={setOpenNote}
        onAfterSend={onAfterSend}
        entity={selectedNote}
        type={'general'}
      />
    </>
  );
}
