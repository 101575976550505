import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DemoItem} from "@mui/x-date-pickers/internals/demo";
import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import React, {useEffect, useState} from "react";
import {useField} from "formik";
import dayjs from "dayjs";
import moment from "moment";

export default function TimePickerField(props) {

    const [field, meta, helper] = useField(props);
    const {touched, error} = meta;
    const {setValue} = helper;
    const isError = touched && error && true;
    const {value} = field;
    const [selectedDate, setSelectedDate] = useState(null);


    useEffect(() => {
        if (value) {
            if (value instanceof dayjs){
                setSelectedDate(value);
            }else{
                setSelectedDate(dayjs(moment(value, 'MM/DD/YYYY hh:mm A').toDate()));
            }
        }
    }, [value]);

    function _onChange(date) {
        if (date) {
            setSelectedDate(date);
            try {
                setValue(date);
                if (props.form) {
                    props.form.current?.setFieldValue(props.name, date);
                    props.form.current.submitForm();
                }

            } catch (error) {
                setValue(date);
            }
        } else {
            setValue(date);
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoItem label=''>
                <TimePicker
                    {...field}
                    {...props}
                    onChange={_onChange}
                    value={selectedDate}
                    error={isError}
                    invalidDateMessage={isError && error}
                    helperText={isError && error}
                />
            </DemoItem>
        </LocalizationProvider>
    );
}
