import React, { useState, useEffect } from 'react';
import { Box, FormControlLabel, TextField, withStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { useFormikContext } from 'formik';
import { has as _has } from 'lodash';

import DatePickerField from '../../../forms/DatePickerField';
import { AppStyle } from '../../../assets/css/app/AppStyle';
import TimePickerField from "../../../forms/TimePickerField";

const GreenCheckbox = withStyles({
  root: {
    color: "green",
    '&$checked': {
      color: "green",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const NoteForm = (props) => {

  const { values, handleChange, errors } = useFormikContext();
  const [isTemporary, setIsTemporary] = useState(false);
  const appStyle = AppStyle();
  const {type, infoCheck, setInfoCheck}= props;

  const handleChangeChecked = (event) => {
    let res = infoCheck.map((e)=>{
      if(e.name===event.target.name){
        return {...e, active: event.target.checked};
      }else{
        return e;
      }
    });
    setInfoCheck(res);
  };

  const handleChangeIsTemporary = (e) => {
    setIsTemporary(e.target.checked);
  };

  useEffect(() => {
    setIsTemporary(values.temporary);
  }, [values]);

  return (
    <div style={{ width: '100%' }}>

      {
        (type!=='rejected_note')
          &&
            <div>
              <Box display='flex' alignItems='center' mb={2}>
                <Box>
                  <span className={appStyle.txtBold}>
                    {values.id === 0 ? ' Date and time of the note:' : 'Time and date of the note:'}
                  </span>
                </Box>
              </Box>

              <Box display='flex' flexDirection='row' alignItems='center' mb={2}>
                <Box mr={1}>
                  <span className={appStyle.txtBold}>Date: </span>
                </Box>
                <Box width={150} mr={3}>
                  <DatePickerField
                    disableToolbar
                    name='serviceDate'
                    value={values.serviceDate}
                    onChange={handleChange}
                    format='MM/dd/yyyy'
                    minDate={new Date()}
                    maxDate={new Date('2050/12/31')}
                    disabled={values.disabled}
                  />
                </Box>
                <Box mr={1}>
                  <span className={appStyle.txtBold}>Time: </span>
                </Box>
                <Box>
                  <TimePickerField disabled={values.disabled} name='serviceHour'/>
                </Box>
              </Box>
            </div>
      }

      <Box display='flex' flexDirection='column' mb={2}>
        <Box mb={2} mt={2}>
          <span className={appStyle.txtBold}>{(type!=='rejected_note')?'Note:':'Please support why this request has been rejected.'}</span>
        </Box>
        <Box>
          <TextField
            error={_has(errors, 'description')}
            name='description'
            value={values.description}
            onChange={handleChange}
            multiline
            minRows={4}
            variant='outlined'
            helperText={_has(errors, 'description') ? errors.description : ''}
            style={{ width: '100%' }}
            fullWidth={true}
            disabled={values.disabled}
            placeholder="Write here"
          />
        </Box>
      </Box>

      {
        (type=='rejected_note')
          &&
            <>
              <h4>Default Options</h4>
              {
                infoCheck.map((e,i)=>(
                  <div key={`checkbox${i}`}>
                    <FormControlLabel
                      control={
                        <GreenCheckbox
                          checked={e.active}
                          onChange={handleChangeChecked}
                          name={e.name}
                        />
                      }
                      label={e.label}
                    />
                  </div>
                ))
              }
            </>
      }

      {
        (type!=='rejected_note')
          &&
            <Box display='flex' alignItems='center' flexDirection='row'>
              <Box mr={1}>
                <span className={appStyle.txtBold}>Is this a temporary note?:</span>
              </Box>
              <Box>
                <Checkbox
                  name='temporary'
                  value={values.temporary}
                  onChange={(e) => {
                    handleChangeIsTemporary(e);
                    handleChange(e);
                  }}
                  disabled={values.disabled}
                  checked={isTemporary}
                />
              </Box>
            </Box>
      }

      {values.customerManager && (
        <Box display='flex' flexDirection='row' mb={2}>
          <Box mr={1}>
            <span className={appStyle.txtBold}>Customer manager:</span>
          </Box>
          <Box>
            <span>{values.customerManager}</span>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default NoteForm;
