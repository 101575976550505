import {Utils} from '../../helpers';
import {PickItemService} from './index';

class PickItemServiceData extends PickItemService {

  getAboutOption(group) {
    return new Promise((resolve, reject) => {
      this.getAccessToken();
      this.list(`?filter[picklist_id]=${group}&sort=name`)
        .then(response => {
          const arr = Utils.updateArrayObjectItem(response.data, []);
          return resolve(arr);
        })
        .catch(error => {
          // handle error
          console.warn('Cannot retrieve user data', error);
          return reject(error);
        });
    })
  }
}

export default PickItemServiceData;
