import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';

import { Form, Formik } from 'formik';
import {
  includes as _includes, omit as _omit, toNumber as _toNumber,
} from 'lodash';
import NumberFormat from 'react-number-format';
import {connect, useDispatch} from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import {
  BottomNavigation, BottomNavigationAction, Button, Grid,
  IconButton, InputLabel,
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import { useUI } from '../../../app/context/ui';
import eCheckImage from '../../../assets/images/echeckInfo.png';
import { InputField, SelectField } from '../../../forms';
import AppHelper from '../../../helpers/AppHelper';
import { cardTypeDb, monthsDb } from '../../../models/db';
import { CustomerService, CustomerWebService } from '../../../services';
import { PaymentStyle } from './payment-style';
import {updateProperty} from "../../../app/store/mm/customerSlice";

const initialValues = {
  paymentOption: 0,
  nameOnCard: '',
  creditCardNumber: '',
  cardType: '',
  creditCardExpirationMonth: '',
  creditCardExpirationYear: '',
  checkNameOnAccount: '',
  checkAccountNumber: '',
  checkRoutingNumber: '',
  checkAccountNumberVerify: '',
};

const customerWebService = new CustomerWebService();
const customerService = new CustomerService();

const PaymentPage = (props) => {
  const { user } = props;
  const classes = PaymentStyle();
  const [valueNavigation, setValueNavigation] = useState(0);
  const { snackbarUI, blockUI, dialogUI } = useUI();
  const [formInitialValues, setFormInitialValues] = useState(initialValues);
  const [cardYears, setCardYears] = useState([]);
  const formikPayments = useRef();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const validationCreditSchema = Yup.object({
    nameOnCard: Yup.string().nullable().required('Is required'),
    creditCardNumber: Yup.number().nullable().required('Is required'),
    cardType: Yup.number().nullable().required('Is required'),
    creditCardExpirationMonth: Yup.number().nullable().required('Card month is required'),
    creditCardExpirationYear: Yup.number().nullable().required('Card year is required'),
  });

  const validationCheckSchema = Yup.object({
    checkNameOnAccount: Yup.string().nullable().required('Is required'),
    checkRoutingNumber: Yup.string().nullable().required('Is required'),
    checkAccountNumber: Yup.string().nullable().required('Is required'),
    checkAccountNumberVerify: Yup.string()
      .oneOf([Yup.ref('checkAccountNumber'), null], 'Do not match')
      .required('Is required'),
  });

  const getInitialValues = useCallback(async () => {
    try {
      let id = location.state?.id ? location.state.id : 0;
      blockUI.current.open(true);
      customerWebService.getAccessToken();
      customerService.getAccessToken();

      let r1 =
        location.pathname === '/payment-configuration'
          ? await customerService.read(id)
          : await customerWebService.read(id);

      setFormInitialValues({
        ...initialValues,
        nameOnCard: r1.data.fullName,
        checkNameOnAccount: r1.data.fullName,
      });
      blockUI.current.open(false);
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  }, [snackbarUI, blockUI, location]);

  const onSend = useCallback(
    async (values) => {
      try {
        blockUI.current.open(true);
        customerWebService.getAccessToken();
        customerService.getAccessToken();
        const valueSend = values.paymentOption
          ? _omit(values, validationCreditSchema._nodes)
          : _omit(values, validationCheckSchema._nodes);

        const updatePayment =
          user.role === 'customers'
            ? await customerWebService.updateCreditCard({ ...valueSend }, user.id)
            : await customerService.updateCreditCard({ ...valueSend }, location.state.id);

        dispatch(updateProperty({'cardType': values.paymentOption === 0 ? values.cardType : null}));

        let dlgSettings = {
          btn: {
            close: 'Ok',
          },
          close: true,
          onConfirm: () => {
            dialogUI.current.close();
            user.role === 'customers'
              ? history.push('/web/account/payment-list')
              : history.goBack();
          },
        };

        updatePayment
          ? dialogUI.current.open(
              'Success',
              'The payment method was updated successfully',
              dlgSettings
            )
          : null;
        blockUI.current.open(false);
      } catch (e) {
        blockUI.current.open(false);
        dialogUI.current.open('Error', `${e.response.data.message}`);
        // AppHelper.checkError(e, snackbarUI);
      }
    },
    [blockUI, dialogUI, validationCreditSchema, validationCheckSchema, location, user, history]
  );

  const handleChangeNumCard = (event) => {
    const str = event.target.value;
    let value = str.substring(0, 1);
    if (!_includes([3, 4, 5, 6], _toNumber(value))) {
      value = null;
    }
    if (formikPayments.current) {
      formikPayments.current.setFieldValue('creditCardNumber', str);
      formikPayments.current.setFieldValue('cardType', value);
    }
  };

  useEffect(() => {
    (async function init() {
      await getInitialValues();
    })();
  }, [getInitialValues]);

  useEffect(() => {
    const year = new Date().getFullYear();
    let arrYear = [];
    for (let i = year; i < year + 10; i++) {
      arrYear.push({ value: i, label: i });
    }
    setCardYears(arrYear);
  }, []);

  return (
    <>
      <Formik
        innerRef={formikPayments}
        initialValues={formInitialValues}
        validationSchema={valueNavigation ? validationCheckSchema : validationCreditSchema}
        onSubmit={(values) => onSend(values)}
        enableReinitialize={true}
      >
        {({ setFieldValue }) => {
          return (
            <Form autoComplete='off'>
              <Grid container direction='row' justify='space-between' alignItems='center'>
                <Grid item xs={3}/>
                <Grid item xs={6} className={classes.wrapperPaymentOption}>
                  <h4 className='titlePayment'>
                    <IconButton
                      color='primary'
                      component='span'
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      <KeyboardBackspaceIcon />
                    </IconButton>
                    Payment
                  </h4>

                  <Grid container className='wrapperButton'>
                    <BottomNavigation
                      value={valueNavigation}
                      onChange={(event, value) => {
                        setValueNavigation(value);
                        setFieldValue('paymentOption', value);
                      }}
                      showLabels
                      className={classes.rootNavigation}
                    >
                      <BottomNavigationAction label='Credit/Debit Card' />
                      <BottomNavigationAction label='E-Check' />
                    </BottomNavigation>
                  </Grid>
                  {valueNavigation === 0 ? (
                    <Grid container>
                      <h5 className='infoTitle'>
                        Conveniently billed to your card the next business day after your scheduled
                        mow.
                      </h5>

                      <Grid item xs={12}>
                        <InputLabel className={classes.label}>
                          Name on Card <span>*</span>
                        </InputLabel>
                        <InputField name='nameOnCard' fullWidth />
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel className={classes.label}>
                          Card Number <span>*</span>
                        </InputLabel>
                        <InputField
                          name='creditCardNumber'
                          InputProps={{ inputComponent: NumberFormat }}
                          onChange={handleChangeNumCard}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel className={classes.label}>
                          Card Type <span>*</span>
                        </InputLabel>
                        <SelectField name='cardType' data={cardTypeDb} autoFocus fullWidth />
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel className={classes.label}>
                          Card Expiration Date <span>*</span>
                        </InputLabel>
                        <Grid container>
                          <Grid item xs={6}>
                            <SelectField
                              name='creditCardExpirationMonth'
                              data={monthsDb}
                              autoFocus
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={6} style={{ paddingLeft: '8px' }}>
                            <SelectField
                              name='creditCardExpirationYear'
                              data={cardYears}
                              autoFocus
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container>
                      <h5 className={classes.infoTitleEcheck}>
                        Conveniently billed to your bank the next business day after your scheduled
                        mow.
                      </h5>
                      <h5 className={classes.infoTitleEcheck}>
                        ** Paying by E-Check will grant your 5th mow free.
                      </h5>
                      <figure style={{ textAlign: 'center' }}>
                        <img src={eCheckImage} style={{ width: '75%' }} alt={'E-Check'} />
                      </figure>
                      <Grid item xs={12}>
                        <InputLabel className={classes.label}>
                          Name On Account <span>*</span>
                        </InputLabel>
                        <InputField name='checkNameOnAccount' fullWidth />
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel className={classes.label}>
                          Routing Number <span>*</span>
                        </InputLabel>
                        <InputField
                          name='checkRoutingNumber'
                          InputProps={{ inputComponent: NumberFormat }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel className={classes.label}>
                          Account Number <span>*</span>
                        </InputLabel>
                        <InputField
                          name='checkAccountNumber'
                          InputProps={{ inputComponent: NumberFormat }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel className={classes.label}>
                          Verify Account Number <span>*</span>
                        </InputLabel>
                        <InputField
                          name='checkAccountNumberVerify'
                          InputProps={{ inputComponent: NumberFormat }}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Grid container>
                    <Button
                      type='submit'
                      variant='contained'
                      color='primary'
                      className={classes.btnSubmit}
                    >
                      SUBMIT
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={3}/>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(PaymentPage);
