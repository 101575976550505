import React, {
  useCallback, useContext, useEffect, useMemo,
  useState,
} from 'react';

import { Form, Formik } from 'formik';
import {
  find as _find, includes as _includes, mapValues as _mapValues, set as _set,
  unset as _unset,
} from 'lodash';
import { useLocation } from 'react-router-dom';
import { Element, scroller } from 'react-scroll';
import * as Yup from 'yup';

import { useUI } from '../../../../app/context/ui';
import {
  Box, Container, Typography,
} from '../../../../components/shared/MaterialUI';
import { SrvService, WebService } from '../../../../services';
import BusinessLogicService from '../../../../services/BusinessLogicService';
import { SignUpCustomerContext } from '../SignUpProcess';
import { QuoteStyles } from './quote-style';
import Step1 from './wizard/Step1';
import Step2 from './wizard/Step2';

const Quote = () => {
  const { state, dispatch } = useContext(SignUpCustomerContext);
  const { mowCut, srvFrecuency, taxRate } = state;
  const { search } = useLocation();

  const { address, zipcode } = new Proxy(new URLSearchParams(search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  const { blockUI } = useUI();
  const classes = QuoteStyles();
  const businessLogicService = useMemo(() => new BusinessLogicService(), []);
  const srvService = useMemo(() => new SrvService(), []);
  const webService = new WebService();

  const [nextStep, setNextStep] = useState(false);
  const [lotSize, setLotSize] = useState('');
  const [quotePrice, setQuotePrice] = useState('');

  const [selectedLot, setSelectedLot] = useState('');

  const [defaultBool, setDefaultBool] = useState({
    front: true,
    back: true,
    bagGrass: false,
  });

  const disableBagGrass =
    selectedLot.name === 'Very large' ||
    selectedLot.name === 'Enormous' ||
    selectedLot.name === 'Jumbo';

  const frequencies = {
    f1: 'Weekly',
    f2: 'Bi-Weekly',
    f3: '3 Weekly',
    f4: 'Drought Schedule',
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const paddings = [
    {
      padding: '5%',
    },
    {
      padding: '9%',
    },
    {
      padding: '12%',
    },
    {
      padding: '20%',
    },
    {
      padding: '30%',
    },
    {
      padding: '33%',
    },
    {
      padding: '36%',
    },
  ];

  const getLotSizes = useCallback(async () => {
    try {
      blockUI.current.open(true);
      const r1 = await businessLogicService.list();
      const serviceFrequency = [];
      _mapValues(r1.data, (value) => {
        return serviceFrequency.push(JSON.parse(value.serviceFrequency));
      });
      _mapValues(r1.data, (value, index) => {
        _unset(value, 'serviceFrequency');
        _set(value, 'serviceFrequency', serviceFrequency[index]);
        _set(value, 'surface', () => {
          return {
            __html: `${
              value.turfSizeStart === 0
                ? `${parseInt(value.turfSizeEnd).toLocaleString()} + sq ft turf`
                : `${parseInt(value.turfSizeStart).toLocaleString()} - ${parseInt(
                    value.turfSizeEnd
                  ).toLocaleString()} <br> sq ft turf`
            }`,
          };
        });
        _set(value, 'padding', paddings[index].padding);
      });
      setLotSize(r1.data);
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockUI, businessLogicService]);

  const getExceptions = useCallback(
    async (code) => {
      try {
        blockUI.current.open(true);
        const r1 = await srvService.zipCode(`zip-code/${code}`);
        const r2 = await webService.getState(code);
        dispatch({
          type: 'setExceptions',
          payload: r1.data.exceptions,
        });
        dispatch({
          type: 'setMarketId',
          payload: r1.data.serviceMarketsId,
        });
        dispatch({
          type: 'setLocationInfo',
          payload: {
            city: r2.data[0].id,
            state: r2.data[0].stateId,
            cityName: r2.data[0].city,
          },
        });
        blockUI.current.open(false);
        return r1.data;
      } catch (e) {
        blockUI.current.open(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [blockUI, srvService]
  );

  const getTaxRate = useCallback(
    async (id) => {
      try {
        blockUI.current.open(true);
        const r1 = await webService.taxRate(id);
        dispatch({
          type: 'setTaxRate',
          payload: r1.data.taxRate,
        });
        blockUI.current.open(false);
      } catch (e) {
        blockUI.current.open(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [blockUI, srvService]
  );

  const getQuotePrice = (size, svrFrequency, bagGrass, front, back) => {
    const lotS = _find(lotSize, (lot) => {
      return lot.id === size.id;
    });
    const frequency = `f${svrFrequency}`;
    let frequencyPrice =
      lotS && frequency && parseInt(lotS.serviceFrequency[frequency].split('$').join(''));
    dispatch({
      type: 'setBasePrice',
      payload: { basePrice: `${frequencyPrice}.00` },
    });

    if ((!front && front !== undefined) || (!back && back !== undefined)) {
      if (bagGrass) {
        if (frequency === 'f1') {
          frequencyPrice += 10;
        } else {
          frequencyPrice += 20;
        }
      }
      frequencyPrice -= 5;
    } else if (front && back) {
      if (bagGrass) {
        if (frequency === 'f1') {
          frequencyPrice += 10;
        } else {
          frequencyPrice += 20;
        }
      }
    }
    dispatch({
      type: 'setMowingPrice',
      payload: { subtotalMowing: `${frequencyPrice}.00` },
    });
    dispatch({
      type: 'setTotalPrice',
      payload: { totalMowing: `${(frequencyPrice + frequencyPrice * (taxRate / 100)).toFixed(2)}` },
    });
    return frequencyPrice;
  };

  // eslint-disable-next-line no-unused-vars
  const saveZipCode = (zip) => {
    dispatch({
      type: 'setZipCode',
      payload: `${zip}`,
    });
  };

  const handleFrequencySelect = (e) => {
    const { value } = e.target;
    dispatch({
      type: 'setsrvFrecuency',
      payload: parseInt(value.slice(1)),
    });
  };

  const handleSubmit = () => {
    setQuotePrice(getQuotePrice(selectedLot, srvFrecuency, _includes(mowCut, 'BagGrass')));
    setNextStep(true);
    scroller.scrollTo('nextStep', {
      smooth: true,
    });
  };

  const validationSchema = Yup.object({
    selectedLot: Yup.string().required(`Please select a lot size from the option menu.`),
  });

  useEffect(() => {
    getLotSizes();
    getExceptions(zipcode).then((res) => getTaxRate(res.serviceMarketsId));
    saveZipCode(zipcode);
    dispatch({
      type: 'setServiceAddres',
      payload: address,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const props = {
    lotSize,
    quotePrice,
    defaultBool,
    selectedLot,
    disableBagGrass,
    frequencies,
    setSelectedLot,
    handleFrequencySelect,
    setQuotePrice,
    getQuotePrice,
    setDefaultBool,
  };

  return (
    <Container maxWidth='lg' className={classes.container} disableGutters>
      <Box className={classes.topText}>
        <Typography className={classes.lucky}>You&apos;re in luck</Typography>
        <Typography className='headerText'>Mow Managers Services Your Area</Typography>
      </Box>
      <Formik
        initialValues={{
          selectedLot: '',
          front: 'Front',
          back: 'Back',
          flowerBedCleaning: '',
          bushTrimming: '',
        }}
        validationSchema={validationSchema}
        validateOnChange={false}
        onSubmit={() => {
          handleSubmit();
        }}
      >
        <Form className={classes.formControl}>
          <Step1 {...props} />
          {nextStep && (
            <Element name='nextStep' className='scroll_element'>
              <div className='divider'>
                <hr />
              </div>
            </Element>
          )}
          {nextStep && <Step2 {...props} />}
        </Form>
      </Formik>
    </Container>
  );
};

export default Quote;
