import React from 'react';
import { Formik, Form } from 'formik';
import {
  Grid,
  Button,
  TextField,
  Box,
} from '../../../components/shared/MaterialUI';
import { validationSchema } from './validationSchema';

const ZipForm = ({ initialValues, onSubmit, classes, setOpenModal }) => (
  <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
    enableReinitialize
  >
    {({ values, touched, errors, handleChange }) => (
      <Form autoComplete="off">
        <Grid container spacing={3}>
          <Grid item xs={3} className={classes.grdItem}>
            <label>Zipcode:</label>
          </Grid>
          <Grid item xs={9}>
            <TextField
              variant="outlined"
              autoFocus
              fullWidth
              id="zip"
              name="zip"
              value={values.zip}
              onChange={(e) => {
                const re = /^[0-9\b]+$/;
                if (e.target.value === '' || re.test(e.target.value)) {
                  handleChange(e);
                }
              }}
              error={touched.zip && Boolean(errors.zip)}
              helperText={touched.zip && errors.zip}
              className={classes.inputCustom}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 5 }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={3} className={classes.grdItem}>
            <label>Exceptions:</label>
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              minRows={4}
              variant="outlined"
              fullWidth
              name="exceptions"
              value={values.exceptions}
              onChange={handleChange}
              error={touched.exceptions && Boolean(errors.exceptions)}
              helperText={touched.exceptions && errors.exceptions}
            />
          </Grid>
        </Grid>
        <Box pb={2} />
        <Grid container justifyContent="center">
          <Button
            variant="contained"
            size="large"
            className={classes.button}
            onClick={() => setOpenModal(false)}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            className={classes.button}
          >
            Save
          </Button>
        </Grid>
      </Form>
    )}
  </Formik>
);

export default ZipForm;
