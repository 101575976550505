import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  externalCalendarContainer: {
    backgroundColor: '#10AA39',
    padding: '27px',
    color: 'white'
  },
  formControl: {
    margin: theme.spacing(1),
    backgroundColor: 'white',
    '@media (max-width: 600px)': {
      minWidth: '95%',
    },
    '@media (min-width: 601px)': {
      minWidth: '38%',
    },
  },
  infoWeekDay: {
    '@media (max-width: 600px)': {
      paddingLeft: '0px',
      textAlign: 'center',
      paddingRight: '31px',
    },
    '@media (min-width: 601px)': {
      paddingLeft: '9px',
      textAlign: 'left',
    }
  },
  infoData: {
    '@media (max-width: 600px)': {
      marginBottom: '15px',
      textAlign: 'left',
      paddingLeft: '9px',
    },
    '@media (min-width: 601px)': {
      paddingLeft: '29px',
      textAlign: 'left',
      marginBottom: '10px'
    }
  },
  wrapperDataInfo: {
    marginTop: '17px', 
    textAlign: 'center',
  },
  weekDayInfo: {
    '@media (max-width: 600px)': {
      paddingLeft: '0px',
    },
    '@media (min-width: 601px)': {
      paddingLeft: '12px',
    }
  },
  wrapperExternalCalendar: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#fafafa',
  },
  wrapperGeneral: {
    padding: '10px',
    overflowY: 'auto',
    maxHeight: '100%',
  },
  wrapperContainerCalendar: {
    backgroundColor: 'white',
    borderRadius: '12px',
    marginTop: '30px',
    '@media (max-width: 600px)': {
      padding: '0px',
    },
    '@media (min-width: 601px)': {
      padding: '27px 70px',
    },
  },
  wrapperInfoCalendar: {
    '@media (max-width: 600px)': {
      height: 580, 
      width: "166%",
      overflowX: 'scroll',
      marginLeft: '13px',
      marginBottom: '130px'
    },
    '@media (min-width: 601px)': {
      height: 580, 
      width: "100%",
    },
  },
  titleCalendar: {
    padding: '30px 0px 25px 23px',
    fontWeight: 'bold',
  },
  titleAddress: {
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 600px)': {
      justifyContent: 'flex-start',
      marginBottom: '9px'
    },
    '@media (min-width: 601px)': {
      justifyContent: 'center',
    },
  },
  wrapperClose: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  dataGrid: {
    "& .MuiCheckbox-root": {
      color: "#10AA39",
    },
    "& .MuiDataGrid-columnSeparator": {
      color: "transparent",
    },
    "& .MuiDataGrid-colCellTitle": {
      color: "#2F3A4A",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '25px',
    },
    "& .MuiDataGrid-cell": {
      color: "#2F3A4A",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: '14px',
      lineHeight: '16px',
    },
  },
}));

export default useStyles;
