import { makeStyles } from '@material-ui/core/styles';

export const ReferralStyle = makeStyles((theme) => ({
  h2: {
    fontWeight: 'bold',
    fontSize: '28px',
    margin: '0px',
    fontFamily: 'sans-serif'
  },
  container: {
    width: '100%',
    height: '100vh',
    padding: '20px 44px',
    backgroundColor: 'white',
    marginTop: '40px',
    paddingTop: '24px',
    "& p": {
      fontSize: '15px',
      fontWeight: 'bold',
    },
    "& .market": {
      margin: '18px 0px',
      fontSize: '15px',
    },
    "& .infoWeek": {
      fontSize: '13px',
      marginTop: '16px'
    }
  },
  wrapperTable: {
    padding: '10px',
    "& table": {
      borderCollapse: 'collapse',
      border: '1px solid #E8E8E8',
      width: '100%',
      lineHeight: '37px',
      textAlign: 'center',
      fontSize: '14px',
      "& caption":{
        backgroundColor: '#F2F2F2',
        border: '1px solid #E8E8E8',
        fontWeight: 'bold'
      },
      "& th":{
        border: 'solid 1px #e8e8e8'
      },
      "& td": {
        border: '1px solid #E8E8E8'
      }
    }
  },
  buttonEditDisabled: {
    color: 'black',
    backgroundColor: 'white',
    height: '28px',
    width: '97px',
    fontSize: '10px',
    "& .MuiSvgIcon-root": {
      fontSize: '13px'
    }, 
    "&:hover": {
      backgroundColor: '#10AA39',
      color: 'white'
    }
  },
  buttonEditEnabled: {
    color: 'white',
    backgroundColor: 'black',
    height: '28px',
    width: '97px',
    fontSize: '15px',
    "& .MuiSvgIcon-root": {
      fontSize: '13px'
    },
    "&:hover": {
      backgroundColor: '#10AA39',
      color: 'white'
    }
  },
  buttonUpdate: {
    marginTop: '34px',
    width: '33%',
    height: '35px',
    fontSize: '16px',
    backgroundColor: 'black',
    marginBottom: '50px',
    fontWeight: '700'
  },
  paperModal:{
    position: 'absolute',
    width: 550,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflowY: 'scroll'
  }
}));
