import PaymentList from "../pages/web/payment/PaymentList";
import PaymentPage from "../pages/web/payment/PaymentPage";

const RoutesWeb = [
  {
    path: "/web/account/payment-list",
    exact: true,
    component: PaymentList
  },
  {
    path: "/web/account/payment-configuration",
    exact: true,
    component: PaymentPage
  }
];

export default RoutesWeb;
