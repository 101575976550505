import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, Grid } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import AddIcon from '@material-ui/icons/Add';

import { useFormikContext } from 'formik';
import { WizardStyles } from '../../../assets/css';
import { NoteService } from '../../../services';
import { useUI } from '../../../app/context/ui';
import NoteModal from '../../../components/note/crud/NoteModal';
import { isNull as _isNull } from 'lodash';
import AppHelper from '../../../helpers/AppHelper';
import moment from "moment";

let columns = [
  {
    field: 'serviceDate',
    headerName: 'Date',
    width: 130,
  },
  {
    field: 'serviceHour',
    headerName: 'Hour',
    width: 120,
  },
  {
    field: 'description',
    headerName: 'Description',
    flex: 1,
    minWidth: 340,
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 110,
    disableClickEventBubbling: true,
    renderCell: () => {},
  },
];

const Step6 = () => {
  const [rows, setRows] = useState([]);
  const [openNote, setOpenNote] = useState(false);
  const [selectedNote, setSelectedNote] = useState({});
  const [loading, setLoading] = useState(false);
  const wizardStyle = WizardStyles();
  const {dialogUI, snackbarUI} = useUI();
  const {values: formValues} = useFormikContext();

  const noteService = useMemo(() => new NoteService(), []);
  noteService.getAccessToken();

  let queryForListNotes = 'filter[customer_attributes_id]=CA_ID&filter[type_id]=3&render=paginate&sort=-id';
  queryForListNotes = queryForListNotes.replace('CA_ID', formValues.id);
  columns[3].renderCell = (params) => (
    <Button onClick={() => onSeeMore(params.row)} color='secondary' style={{ marginLeft: 16, textTransform: 'none' }}>
      {' '}
      see more
    </Button>
  );

  const getListNotes = useCallback(
    async (search) => {
      try {
        setLoading(true);
        const r = await noteService.list(search);
        setRows(r.data.data);
        setLoading(false);
      } catch (e) {
        AppHelper.checkError(e, snackbarUI);
      }
    },
    [noteService, snackbarUI]
  );

  const onAfterSend = useCallback(
    async (title, message) => {
      setOpenNote(false);
      await getListNotes(queryForListNotes);
      dialogUI.current.open(title, message);
    },
    [getListNotes, dialogUI, queryForListNotes]
  );

  const onSeeMore = (row) => {
    let thisRow = row;
    thisRow['disabled'] = true;
    thisRow['serviceDate'] = thisRow['serviceDate'].replace(/-/g, '/');
    if (!_isNull(thisRow['serviceHour'])) {
      const time = moment(thisRow['serviceDate'] + ' '+ thisRow['serviceHour'], 'MM/DD/YYYY hh:mm A');
      thisRow = {...thisRow, 'serviceHour': time.format('MM/DD/YYYY hh:mm A')};
    }
    setSelectedNote(thisRow);
    setOpenNote(true);
  };

  useEffect(() => {
    (async function init() {
      await getListNotes(queryForListNotes);
    })();
  }, [getListNotes, formValues.id]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            variant='contained'
            color='primary'
            size='small'
            startIcon={<AddIcon className='pin-icon' />}
            onClick={() => {
              setSelectedNote({});
              setOpenNote(true);
            }}
            style={{ marginLeft: 16, textTransform: 'none' }}
          >
            New note
          </Button>
        </Grid>
        <Grid item xs={12}>
          <div style={{ width: '100%' }}>
            <DataGrid
              autoHeight={true}
              headerHeight={0}
              rows={rows}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              className={wizardStyle.dataGrid}
              loading={loading}
              disableSelectionOnClick={true}
            />
          </div>
        </Grid>
      </Grid>
      <NoteModal
        open={openNote}
        setOpen={setOpenNote}
        onAfterSend={onAfterSend}
        entity={selectedNote}
        type={'special_request'}
      />
    </>
  );
};

export default Step6;
