import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Grid, Switch, Tooltip } from '@material-ui/core';
import { AlarmsStyle } from './AlarmsStyle';
import { useUI } from '../../../../app/context/ui';
import { AlarmService } from '../../../../services';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const alarmService = new AlarmService();

const ViewBasic = (props) => {
  const alarmsStyle = AlarmsStyle();
  const alarmsAvailable = useSelector(({mm}) => mm.alarm) || {date: null, options: []};
  const dateDay = new Date().toLocaleDateString();
  const [alarmsToday, setAlarmsToday] = useState([]);
  const [statusAlarm, setStatusAlarm] = React.useState([]);
  const {blockUI} = useUI();
  
  const getAlarmsToday = () => {
    const resultFinalAlarm = alarmsAvailable.options.filter((e)=>(new Date(e.alarmDate).getTime() == new Date(dateDay).getTime()));
    setAlarmsToday(resultFinalAlarm);
    const statusAlarm = resultFinalAlarm.map((e)=>({
      active: e.active
    }));
    setStatusAlarm(statusAlarm);
  }

  const handleChangeStatusAlarm = async (event, pos, alarmId) => {
    try {
      blockUI.current.open(true);
      alarmService.getAccessToken();
      let newStatus = statusAlarm.map((e,index)=>{
      if(index === pos){
          return {active: event.target.checked}
        }else{
          return {...e}
        }
      });
      setStatusAlarm(newStatus);
      let alarmsUpdated = alarmsAvailable.options.map((e)=>{
        if(e.id == alarmId){
          return {
            ...e,
            active: event.target.checked
          }
        }else{
          return { ...e }
        }
      });
      await props.handleUpdateStatusAlarm({active: event.target.checked}, alarmsToday[pos].id, alarmsUpdated);
      blockUI.current.open(false);
    } catch (error) {
      blockUI.current.open(false);
    }
  };

  const handleOpenDetail = (alarm) => {
    props.setOpenAlarmView(true);
    props.setdataAlarmDetail(alarm);
  }

  useEffect(() => {
    getAlarmsToday();
  }, [alarmsAvailable]);

  return (
    <div>
      {
        (alarmsToday.length > 0)
          ?
            alarmsToday?.map((alarm, index)=>(
              <Grid key={alarm.id} className={alarmsStyle.wrapper} container>
                <Grid xs={4} className="date">
                  {alarm.alarmDate}
                </Grid>
                <Grid xs={4} className="hour">
                  {alarm.alarmHour}
                </Grid>
                <Grid xs={4} className="switch">
                  <Switch
                    checked={statusAlarm[index].active}
                    onChange={(e)=>{handleChangeStatusAlarm(e, index, alarm.id)}}
                    name="status"
                  />
                </Grid>
                <Grid xs={12} className="detail">
                  {alarm.detail}
                </Grid>
                <Grid xs={12} className="view">
                  <Tooltip title="Open" placement="bottom">
                    <OpenInNewIcon fontSize="small" onClick={()=>{handleOpenDetail(alarm)}}/>
                  </Tooltip>
                </Grid>
              </Grid>
            ))
          :
            (
              <div className={alarmsStyle.wrapperNotAlarms}>
                No alarms logged for today
              </div>
            )
      }
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    mm: state.mm
  };
};

export default connect(mapStateToProps)(ViewBasic);
