import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { DataGrid } from "@material-ui/data-grid";
import { Edit } from "@material-ui/icons";
import clsx from "clsx";
import {
  Typography,
  Button,
  Alert,
  Collapse,
  Container,
  Menu,
  MenuItem,
  Box,
} from "../../components/shared/MaterialUI";
import store from "../../redux/store";
import UserService from "../../services/UserService";
import { ListStyles } from "../../assets/css";
import { useUI } from "../../app/context/ui";
import AppHelper from "../../helpers/AppHelper";
import UploadDocuments from "./UploadDocuments";
import template from "../../assets/files/template.xlsx";

const columns = [
  { field: "email", headerName: "User name", flex: 0.3 },
  { field: "crewNumber", headerName: "Crew number", width: 180 },
  { field: "joinDate", headerName: "Join date", width: 150 },
  { field: "firstName", headerName: "First name", width: 160 },
  { field: "lastName", headerName: "Last name", width: 160 },
  { field: "role", headerName: "Role", flex: 0.3 },
];

const userService = new UserService();

const CrewMemberList = () => {
  const [open, setOpen] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [rows, setRows] = useState([]);
  const [select, setSelect] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { blockUI, snackbarUI } = useUI();
  const history = useHistory();
  const state = store.getState();
  const accessToken = state.user.accessToken;
  if (!accessToken) {
    history.push("/login");
  }

  const location = useLocation();
  const classes = useStyles();
  const listStyle = ListStyles();

  const getCrewMembersList = async () => {
    try {
      blockUI.current.open(true);
      userService.init(accessToken);
      const res = await userService.list(`?filter[role]=crew&sort=-id`);
      setRows(res.data);
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const downloadTemplate = () => {
    const link = document.createElement("a");
    link.href = template;
    link.setAttribute("download", "template.xlsx");
    document.body.appendChild(link);
    link.click();
  };

  useEffect(() => {
    getCrewMembersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container component="main" maxWidth="lg">
      {location.state && (
        <Collapse in={open}>
          <Alert
            severity="success"
            color="info"
            style={{ align: "center" }}
            onClose={() => {
              setOpen(false);
            }}
          >
            {location.state.message}
          </Alert>
        </Collapse>
      )}
      <Typography variant="h4">Crews</Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={5}>
        <div>
        <Button
          variant="contained"
          color="primary"
          size="large"
          className={classes.button}
          startIcon={<AddIcon />}
          onClick={() => {
            history.push("create-crew-member", { id: 0 });
          }}
        >
          Create crew
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          className={classes.button}
          startIcon={<Edit />}
          onClick={() => {
            history.push("create-crew-member", { id: select[0] });
          }}
          disabled={select <= 0}
        >
          Edit crew
        </Button>
        </div>
         <div>
          <Button
              id="basic-button"
              className={classes.button}
              variant="contained"
              color="primary"
              size="large"
              aria-controls={openMenu ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openMenu ? 'true' : undefined}
              onClick={handleClick}
          >
            Payments
          </Button>
          <Menu
             id="basic-menu"
             anchorEl={anchorEl}
             open={openMenu}
             onClose={handleClose}
             MenuListProps={{
               'aria-labelledby': 'basic-button',
             }}
             anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            style={{ marginTop: 50 }}
          >
            <MenuItem onClick={() => {
              setOpenModal(true);
              handleClose();
            }}
            >
              Upload Payments
            </MenuItem>
            <MenuItem
              onClick={() => {
                downloadTemplate();
                handleClose();
              }}
            >
              Download Template
            </MenuItem>
            <MenuItem onClick={() => {
              history.push("payments");
            }}>
              View List
            </MenuItem>
        </Menu>
        </div>
      </Box>
      <div style={{ height: 580, width: "100%" }}>
        <DataGrid
          className={clsx(listStyle.dataGrid, classes.root)}
          rows={rows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          checkboxSelection
          selectionModel={select}
          onSelectionModelChange={(e) => {
            const newSelectionModel = e ?? [];
            if (newSelectionModel.length > 1) {
              const selectionSet = new Set(select);
              const result = newSelectionModel.filter(
                (s) => !selectionSet.has(s)
              );
              setSelect(result);
            } else {
              setSelect(newSelectionModel);
            }
          }}
        />
      </div>
      {
        openModal && (
          <UploadDocuments open={openModal} setOpen={setOpenModal} />
        )
      }
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(CrewMemberList);

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    textTransform: "none",
  },
  root: {
    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
      {
        display: "none",
      },
  },
}));
