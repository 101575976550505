import * as Yup from 'yup';

import alertMessages from '../messages/alertMessages.json';

export const eCheckSchema = Yup.object().shape({
  accountNumber: Yup.string('').min(6, 'Minimum 6 characters').required('Account number is required'),
  routingNumber: Yup.string('').min(9, 'Minimum 9 characters').required('Routing number is required'),
  verifyAccountNumber: Yup.string('Confirm your account number')
    .oneOf([Yup.ref('accountNumber')], "Account numbers don't match")
    .min(6, 'Minimum 6 characters')
    .required('Confirm your account number'),
});

export const cardSchema = Yup.object().shape({
  cardNumber: Yup.string().when('cardType', {
    is: (val) => val === 3,
    then: Yup.string('').min(15, 'Invalid card').max(15, 'Invalid Card').required('This field is required'),
    otherwise: Yup.string('').min(16, 'Invalid card').max(16, 'Invalid Card').required('This field is required'),
  }),
});

export const customerSchema = Yup.object().shape({
  contactNumber: Yup.string().required('Contact number is required'),
  email: Yup.string().email('Invalid email').required('This field is required'),
  isFencePlastic: Yup.string().oneOf(['Yes', 'No']).required('This checkox is required'),
  isAboveGroundPool: Yup.string().oneOf(['Yes', 'No']).required('This checkbox is required'),
  isTrampoline: Yup.string().oneOf(['Yes', 'No']).required('This checkbox is required'),
  scheduleService: Yup.string().notOneOf(['oneTime']).typeError('We do not offer 1 time service.'),
  disclaimerFencePlastic: Yup.string().when('isFencePlastic', {
    is: (val) => val === 'Yes',
    then: Yup.string().oneOf(['Yes'], alertMessages.disclaimerLiability).required(alertMessages.disclaimerLiability),
  }),
  disclaimerAboveGroundPool: Yup.string().when('isAboveGroundPool', {
    is: (val) => val === 'Yes',
    then: Yup.string().oneOf(['Yes'], alertMessages.disclaimerLiability).required(alertMessages.disclaimerLiability),
  }),
  disclaimerTrampoline: Yup.string().when('isTrampoline', {
    is: (val) => val === 'Yes',
    then: Yup.string().oneOf(['Yes'], alertMessages.disclaimerLiability).required(alertMessages.disclaimerLiability),
  }),
});
