import {styled} from "@material-ui/core/styles";
import {Dialog} from "@material-ui/core";

export const DialogCreate = styled(Dialog)({
  '& .MuiPaper-root': {
    width: '620px',
    maxWidth: '900px',
    padding: '40px 50px',
  },
});
