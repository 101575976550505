const statusDb = [
  {
    value: 1,
    label: 'Active Customer'
  },
  {
    value: 2,
    label: 'Card declined'
  },
  {
    value: 3,
    label: 'No mailer'
  },
  {
    value: 4,
    label: 'NO RESIGN'
  },
  {
    value: 5,
    label: 'Past Customer'
  },
  {
    value: 6,
    label: 'Stewart'
  },
  {
    value: 7,
    label: 'Too Tall'
  }
];

export default statusDb;
