import React from 'react';
import {
  Checkbox,
  FormControl, FormControlLabel, FormHelperText,
} from '@material-ui/core';

export const CheckboxControl = ({field, form, label, ...rest}) => {
  const {name, value: formikValue} = field;
  const {setFieldValue, errors, touched} = form;

  const handleChange = () => {
    const values = formikValue || [];
    const index = values.indexOf(rest.value);
    if (index === -1) {
      values.push(rest.value);
    } else {
      values.splice(index, 1);
    }
    setFieldValue(name, values);
  };

  return (
    <div>
      <FormControl {...rest}>
        <FormControlLabel
          checked={formikValue.indexOf(rest.value) !== -1}
          control={<Checkbox {...field} onChange={handleChange} color="secondary"/>}
          label={label}
          {...rest}
        />
      </FormControl>
      {touched[field.name] && errors[field.name]
      && <FormHelperText error={true}>{errors[field.name]}</FormHelperText>}
    </div>

  );
};
