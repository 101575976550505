import React, { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Box, Grid, Modal, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';

import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { has as _has } from 'lodash';
import clsx from 'clsx';

import { WizardStyles } from '../../../assets/css';
import { AppStyle } from '../../../assets/css/app/AppStyle';
import AppButton from '../../../components/forms/AppButton';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const formState = {
  nameDoc: '',
};


const BillingNoteModal = (props) => {
  const { open, setOpen, handleGenerateSvg } = props;
  const [error] = useState(false);
  const [loading] = useState({ state: false, message: 'Saving...' });
  const [modalStyle] = useState(getModalStyle);
  const appStyle = AppStyle();
  const wizardStyle = WizardStyles();

  const validationSchema = Yup.object({
    nameDoc: Yup.string().nullable().required('Field is required'),
  });

  const onSend = (values) => {
		setOpen(false);
		handleGenerateSvg(values.nameDoc);
  };

  useEffect(() => {
    if (_has(props, 'open')) {
      setOpen(props.open);
    }
  }, [props, setOpen]);
  
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
      disableEscapeKeyDown={true}
    >
      <div style={modalStyle} className={wizardStyle.paperModalSmall}>
      <Formik
          initialValues={formState}
          validationSchema={validationSchema}
          onSubmit={(values) => onSend(values)}
          enableReinitialize={true}
        >
          {(props) => {
            const { values, handleSubmit, errors, handleChange } = props;
            return (
              <Form autoComplete="off">
                <Grid container alignItems="center" spacing={0}>
                  <Grid container item xs={12}>
                    <Grid item xs={12}>
										<h2 className={clsx(appStyle.mrg0V)} style={{ textAlign: 'center' }}> Reports </h2>
                    </Grid>
                  </Grid>
									<div style={{ width: '100%', marginTop: '16px' }}>
										<Box display='flex' flexDirection='column' mb={2}>
											<Box>
												<TextField
													error={_has(errors, 'nameDoc')}
													name='nameDoc'
													value={values.description}
													onChange={handleChange}
													variant='outlined'
													helperText={_has(errors, 'nameDoc') ? errors.description : ''}
													style={{ width: '100%' }}
													fullWidth={true}
													disabled={values.disabled}
													placeholder="Name your report here"
												/>
											</Box>
										</Box>
									</div>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {loading.state && (
                        <Alert variant='outlined' icon={<HourglassEmptyIcon fontSize='inherit' />} severity='warning'>
                          {loading.message}
                        </Alert>
                      )}
                      {error && <Alert severity='warning'>{error}</Alert>}
                    </Grid>
                    <Grid item xs={12}>
                      <AppButton
                        onPress={handleSubmit}
                        label={'Save'}
                        type={'main'}
                        color={'primary'}
                        disabled={values.disabled || loading.state}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    mm: state.mm,
  };
};
export default connect(mapStateToProps)(memo(BillingNoteModal));
