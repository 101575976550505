import { makeStyles } from '@material-ui/core/styles';

export const WeekStyle = makeStyles(() => ({
  ctnBlockWeek: {
    float: 'left',
    borderRadius: '6px',
    fontSize: '9px',
    textAlign: 'center',
    width: '48.6px',
    margin: '2px',
    '@media (min-width: 1100px) and (max-width: 1275px)': {
      width: '48.6px',
    },
    '@media (min-width: 1275px) and (max-width: 1600px)': {
      width: '52.6px',
    },
    '& .div1': {
      backgroundColor: '#E8E8E8',
      borderRadius: '5px 5px 0px 0px',
      padding: '1px 6px',
      marginBottom: '1px',
    },
    '& .div2': {
      backgroundColor: '#E8E8E8',
      marginBottom: '1px',
    },
    '& .div3': {
      fontSize: '18px',
      backgroundColor: '#E8E8E8',
      borderRadius: '0px 0px 5px 5px',
    },
  },
  ctnWeekOfYear: {
    float: 'left',
    overflowY: 'scroll',
    paddingLeft: '19px',
    marginBottom: '20px',
  },
  ctnCustomerActive: {
    borderBottom: 'solid 6px #78bb42',
    height: '28.74px',
    borderRadius: '0px 0px 5px 5px',
  },
  popover: {
    '& .MuiPaper-root': {
      border: '1px solid',
      boxShadow: 'none'
    }
  },
  py2: {
    paddingTop: '0.2rem',
    paddingBottom: '0.2rem'
  },
  minW25: {
    minWidth: '2.5rem'
  }
}));
