import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Box,Grid,TextField } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { has as _has } from 'lodash';

import { InputField, SelectField } from '../../forms';
import { AppStyle } from '../../assets/css/app/AppStyle';
import AppHelper from '../../helpers/AppHelper';
import { useUI } from '../../app/context/ui';
import weekDays from '../../models/db/week-days-bb';
import { FormatWeekYear, Utils } from '../../helpers';
import { BbCrewService } from "../../services";

const statusDb = [
  {
    value: 1,
    label: 'Not'
  },
  {
    value: 2,
    label: 'Yes'
  },
  {
    value: 3,
    label: 'Cancelled'
  },
];


const ServiceForm = () => {
  const { values, handleChange, errors } = useFormikContext();
  const [crewDb, setCrewDb] = useState([]);
  const appStyle = AppStyle();
  const { snackbarUI, blockUI } = useUI();
  const bbCrewService = useMemo(() => new BbCrewService(), []);

  const getBushBedList = useCallback(async () => {
    try {
      blockUI.current.open(true);
      bbCrewService.getAccessToken();
      const r = await bbCrewService.list('?sort=-id');
      const arr = Utils.updateArrayObjectItem(
        r.data,
        [{ value: null, label: '-- Select --' }],
        'id',
        'fullName'
      );
      setCrewDb(arr);

      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  }, [bbCrewService, snackbarUI, blockUI]);


  useEffect(() => {
    (async function init() {
      await getBushBedList();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ width: '100%', marginTop: '16px' }}>
			<div>
				<Box display='flex' alignItems='center' mb={2}>
					<Box>
						<span>
              Bush and Bed Service. In Dallas, Fort Worth, Austin & San Antonio $100 per hour 2 guys
						</span>
					</Box>
				</Box>
				<Grid container alignItems='center' spacing={2}>
					<Grid item xs={6} sm={2}>
						<span className={appStyle.txtBold}>Week number: </span>
					</Grid>
					<Grid item xs={6} sm={4}>
						<InputField
              name="weekNumber"
              InputProps={{inputComponent: FormatWeekYear }}
              fullWidth
            />
					</Grid>
				<Grid item xs={6} sm={2}>
						<span className={appStyle.txtBold}>Crew: </span>
          </Grid>
            <Grid item xs={6} sm={4}>
						<SelectField
							name="crew"
							data={crewDb}
							autoFocus
							fullWidth
							/>
          </Grid>
					<Grid item xs={6} sm={2}>
						<span className={appStyle.txtBold}>Week day: </span>
					</Grid>
					<Grid item xs={6} sm={4}>
						<SelectField
							name="weekDay"
							data={weekDays}
							autoFocus
							fullWidth
							/>
					</Grid>
					<Grid item xs={6} sm={2}>
						<span className={appStyle.txtBold}>Status: </span>
					</Grid>
					<Grid item xs={6} sm={4}>
          <SelectField
              name="status"
              data={statusDb}
              autoFocus
              fullWidth
            />
					</Grid>
        <Grid item xs={12} sm={12}>
           <span className={appStyle.txtBold}>Instructions: </span>
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            error={_has(errors, 'instruction')}
            name='instruction'
            value={values.instruction}
            onChange={handleChange}
            multiline
            minRows={4}
            variant='outlined'
            helperText={_has(errors, 'instruction') ? errors.instruction : ''}
            style={{ width: '100%' }}
            fullWidth={true}
            placeholder="Write here"
          />
        </Grid>
				</Grid>
			</div>
    </div>
  );
};

export default ServiceForm;
