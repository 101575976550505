import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Divider, InputLabel } from '@material-ui/core';

import NumberFormat from 'react-number-format';
import { 
  first as _first, 
  includes as _includes, 
  toNumber as _toNumber, 
  mapKeys as _mapKeys 
} from 'lodash';

import {
  Container,
  Grid,
  Typography,
} from '../../../components/shared/MaterialUI';
import { WizardStyles } from '../../../assets/css';
import { InputField, RadioField, SelectField } from '../../../forms';
import { Utils } from '../../../helpers';
import { StateService } from '../../../services';
import { cardTypeDb, monthsDb } from '../../../models/db';
import AppHelper from '../../../helpers/AppHelper';
import { useUI } from '../../../app/context/ui';

const Step6 = (props) => {
  const [billing, setBilling] = useState({'city': [], 'state': []});
  const [mailing, setMailing] = useState({'city': [], 'state': []});
  const [cardYears, setCardYears] = useState([]);
  const [readonly, setReadonly] = useState(false);
  const [checkCreditOrNumberAccount, setCheckCreditOrNumberAccount] = useState(false);
  const {blockUI, snackbarUI} = useUI();
  const [limitCard, setLimitCard] = useState(16);

  const {
    formField: {
      cardNumber,
      cardMonth,
      cardYear,
      nameOnCard,
      cardType,
      isBillingAddress,
      billingName,
      billingZipCode,
      billingCity,
      billingState,
      billingAddress,
      isMailingAddress,
      mailingName,
      mailingZipCode,
      mailingCity,
      mailingState,
      mailingAddress,
      isAddressBillingService,
      bankAccount,
      bankRouting,
      billingNameBank,
      billingPoBox,
      billingPoBoxCity,
      billingPoBoxState,
      billingPoBoxZipcode,
      mailingPoBox,
      mailingPoBoxCity,
      mailingPoBoxState,
      mailingPoBoxZipcode,
    }
  } = props;
  const {formikRef} = props;
  const {values: formValues} = useFormikContext();
  const {
    firstName, 
    lastName, 
    zipcode, 
    city, 
    cityName,
    state, 
    serviceAddress
  } = formValues;

  const stateService = useMemo(() => new StateService(), []);
  stateService.getAccessToken();

  const wizardStyle = WizardStyles();

  const onKeyPress = (e) => {
    if (e.key === 'Enter' || e.target.value.length === 5) {
      (async function init() {
        await getZipCodeState(e.target.value, 1);
      })();
    }
  }

  const onKeyPressZipCode = (e) => {
    if (e.key === 'Enter' || e.target.value.length === 5) {
      (async function init() {
        await getZipCodeState(e.target.value, 2);
      })();
    }
  }

  const setFieldService = (field = 'billing', entries = {}) => {
    if (_includes(['billing', 'mailing'], field)) {
      if (formikRef.current) {
        _mapKeys(entries, function (value, key) {
          formikRef.current.setFieldValue(
              field + key, value
          );
        });
      }
    }
  }

  const getZipCodeState = useCallback(async (zipcode, option) => {
    try {
      blockUI.current.open(true);
      const r1 = await stateService.zip(zipcode);
      const arrCity = Utils.updateArrayObjectItem(r1.data, [], 'id', 'city');
      const arrState = Utils.updateArrayObjectItem(r1.data, [], 'stateId', 'stateName');
      const objCity = _first(arrCity);
      const objState = _first(arrState);
      const entries = {
        'City': objCity.value,
        'CityName': objCity.label,
        'State': objState.value
      };
      if (option === 1) {
        setBilling({'city': arrCity, 'state': arrState});
      } else {
        setMailing({'city': arrCity, 'state': arrState});
      }
      setFieldService((option === 1) ? 'billing' : 'mailing', entries);
      blockUI.current.open(false);

    } catch (e) {
      if (option === 1) {
        setBilling({'city': [], 'state': []});
      } else {
        setMailing({'city': [], 'state': []});
      }
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  }, [blockUI, snackbarUI, stateService, setFieldService]);

  const handleChangeNumCard = (event) => {
    const str = event.target.value;
    let value = str.substring(0, 1);
    if (!_includes([3, 4, 5, 6], _toNumber(value))) {
      value = null;
    }

    const limitCard = value === '3' ? 15 : 16;
    setLimitCard(limitCard);

    if (formikRef.current) {
      formikRef.current.setFieldValue(
        'cardNumber', str
      );
      formikRef.current.setFieldValue(
        'cardType', value
      );
      formikRef.current.setFieldValue(
        'paymentMethods', 1
      );
    }
  }

  const handleChangeBilling = (event) => {
    const service = (event.target.value !== 'No');
    if (!service) {
      setBilling({'city': [], 'state': []});
    } else {
      (async function init() {
        await getZipCodeState(zipcode, 1);
      })();
    }
    const entries = {
      'Name': service ? firstName + ' ' + lastName : '',
      'ZipCode': service ? zipcode : '',
      'City': service ? city : '',
      'CityName': service ? cityName : '',
      'State': service ? state : '',
      'Address': service ? serviceAddress : ''
    };
    setFieldService('billing', entries);
    if (formikRef.current) {
      formikRef.current.setFieldValue(
        'poBoxEnabled', false
      );
    }
  }

  const handleReset = (event, field) => {
    event.preventDefault();
    formikRef.current.setFieldValue(
      field, ''
    );
  }

  const handleChangeMailing = (event) => {
    setReadonly((event.target.value !== 'No'));
    setMailing({'city': [], 'state': []});
    if (formikRef.current) {
      formikRef.current.setFieldValue(
        'isAddressBillingService', ''
      );
    }
    const entries = {
      'Name': '',
      'ZipCode': '',
      'City': '',
      'CityName': '',
      'State': '',
      'Address': ''
    };
    setFieldService('mailing', entries);
  }

  const handleChangeWhich = (event) => {
    const str = _toNumber(event.target.value);
    if (formValues.isBillingAddress === 'No' && str === 1) {
      setMailing({'city': billing.city, 'state': billing.state});
    } else {
      (async function init() {
        await getZipCodeState(zipcode, 2);
      })();
    }
    const entries = {
      'Name': (str === 1) ? formValues.billingName : firstName + ' ' + lastName,
      'ZipCode': (str === 1) ? formValues.billingZipCode : zipcode,
      'City': (str === 1) ? formValues.billingCity : city,
      'CityName': (str === 1) ? formValues.billingCityName : cityName,
      'State': (str === 1) ? formValues.billingState : state,
      'Address': (str === 1) ? formValues.billingAddress : serviceAddress
    };
    setFieldService('mailing', entries);
  }

  const handleChangeCreditOrNumberAccount = (e) => {
    if (formikRef.current) {
      formikRef.current.setFieldValue(
        'paymentMethods', e.target.checked ? 2 : 1
      );
    }
    setCheckCreditOrNumberAccount(e.target.checked);
  }

  useEffect(() => {
    const year = new Date().getFullYear();
    let arrYear = [];
    for (let i = year; i < (year + 10); i++) {
      arrYear.push({value: i, label: i});
    }
    setCardYears(arrYear);
  }, []);

  return (
    <Container component="main" maxWidth="lg" className={wizardStyle.ctnInner}>

      <FormControlLabel
        control={<Switch checked={checkCreditOrNumberAccount} onChange={handleChangeCreditOrNumberAccount}/>}
        label={(checkCreditOrNumberAccount) ? 'E-Check' : 'Credit Card'}
      />
      <br/><br/>
      {
        (checkCreditOrNumberAccount)
          ?
          <>
            <Typography className={wizardStyle.title}>E-Check information</Typography>
            <Divider/>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <label className={wizardStyle.label}>{bankAccount.label}</label>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <InputField name={bankAccount.name}
                                InputProps={{inputComponent: NumberFormat}}
                                fullWidth/>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4}>
                <label className={wizardStyle.label}>{bankRouting.label}</label>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <InputField name={bankRouting.name}
                                InputProps={{inputComponent: NumberFormat}}
                                fullWidth/>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4}>
                <label className={wizardStyle.label}>{billingNameBank.label}</label>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <InputField name={billingNameBank.name}
                                fullWidth/>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
          :
          <>
            <Typography className={wizardStyle.title}>Card information</Typography>
            <Divider/>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <label className={wizardStyle.label}>Card number *</label>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <InputField
                      name={cardNumber.name}
                      InputProps={{
                        inputComponent: NumberFormat,
                        inputProps: { maxLength: limitCard }
                      }}
                      onChange={handleChangeNumCard}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={3}>
                <label className={wizardStyle.label}>Expiration date *</label>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <SelectField
                      name={cardMonth.name}
                      data={monthsDb}
                      autoFocus
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SelectField
                      name={cardYear.name}
                      data={cardYears}
                      autoFocus
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={3}>
                <label className={wizardStyle.label}>{nameOnCard.label}</label>
                <InputField name={nameOnCard.name} fullWidth/>
              </Grid>
              <Grid item xs={12} sm={3}>
                <label className={wizardStyle.label}>Card type *</label>
                <SelectField
                  name={cardType.name}
                  data={cardTypeDb}
                  autoFocus
                  fullWidth
                />
              </Grid>
            </Grid>
          </>
      }

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className={wizardStyle.title}>Billing address</Typography>
          <Divider/>
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputLabel className={wizardStyle.label}>Billing address is the same as service address?</InputLabel>
          <RadioField 
            row 
            name={isBillingAddress.name} 
            onChange={handleChangeBilling} 
            onClick={(e)=>handleReset(e, 'isBillingAddress')}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          {formValues.isBillingAddress === 'No' && (
            <>
              <label className={wizardStyle.label}>Zip*</label>
              <InputField name={billingZipCode.name} onKeyUp={onKeyPress}
                          inputProps={{min: 0, style: {textAlign: 'center'}, minLength: 1, maxLength: 5}}
                          InputProps={{inputComponent: NumberFormat}}
                          fullWidth/>
            </>
          )}
        </Grid>
        <Grid item xs={12} sm={4}>
          {formValues.isBillingAddress === 'No' && (
            <>
              <label className={wizardStyle.label}>Address*</label>
              <InputField name={billingAddress.name} fullWidth/>
            </>
          )}
        </Grid>
        <Grid item xs={12} sm={4}>
          {formValues.isBillingAddress === 'No' && (
            <>
              <label className={wizardStyle.label}>Name*</label>
              <InputField name={billingName.name} fullWidth/>
            </>
          )}
        </Grid>
        <Grid item xs={12} sm={4}>
          {formValues.isBillingAddress === 'No' && (
            <>
              <label className={wizardStyle.label}>City*</label>
              <SelectField
                name={billingCity.name}
                data={billing.city}
                autoFocus
                fullWidth
              />
            </>
          )}
        </Grid>
        <Grid item xs={12} sm={4}>
          {formValues.isBillingAddress === 'No' && (
            <>
              <label className={wizardStyle.label}>State*</label>
              <SelectField
                name={billingState.name}
                data={billing.state}
                autoFocus
                fullWidth
              />
            </>
          )}
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputLabel className={wizardStyle.label}>
            Mailing address is the same as billing address or service
            address? 
          </InputLabel>
          <RadioField 
            row 
            name={isMailingAddress.name} 
            onChange={handleChangeMailing} 
            onClick={(e)=>handleReset(e, 'isMailingAddress')}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          {formValues.isMailingAddress === 'Yes' && (
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <label className={wizardStyle.label}>Which one?</label>
                <RadioField row name={isAddressBillingService.name} onChange={handleChangeWhich}
                            options={[{'label': 'Billing address', 'value': '1'},
                              {'label': 'Service address', 'value': '2'}]}/>
              </Grid>
            </Grid>
          )}
          {(formValues.isMailingAddress === 'No'
            || readonly) && (
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4}>
                <label className={wizardStyle.label}>Zip*</label>
                <InputField name={mailingZipCode.name} onKeyUp={onKeyPressZipCode}
                            inputProps={{min: 0, style: {textAlign: 'center'}, minLength: 1, maxLength: 5}}
                            InputProps={{inputComponent: NumberFormat}}
                            disabled={readonly}
                            fullWidth/>
              </Grid>
              {!readonly && (
                <Grid item xs={12} sm={6}>
                  <label className={wizardStyle.label}>Name*</label>
                  <InputField name={mailingName.name} fullWidth/>
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <label className={wizardStyle.label}>Address*</label>
                <InputField name={mailingAddress.name} disabled={readonly} fullWidth/>
              </Grid>
              <Grid item xs={12} sm={4}>
                <label className={wizardStyle.label}>City*</label>
                <SelectField
                  name={mailingCity.name}
                  data={mailing.city}
                  autoFocus
                  disabled={readonly}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <label className={wizardStyle.label}>State*</label>
                <SelectField
                  name={mailingState.name}
                  data={mailing.state}
                  disabled={readonly}
                  autoFocus
                  fullWidth
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className={wizardStyle.title}>
            Billing PO Box
          </Typography>
          <Divider/>
        </Grid>
        <Grid item xs={12}>
          <Typography className={wizardStyle.label}>
            { billingPoBox.label }
          </Typography>
          <InputField 
            name={billingPoBox.name} 
            inputProps={{ maxLength: 10 }}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={4}>
              <Typography className={wizardStyle.label}>
                { billingPoBoxCity.label }
              </Typography>
              <InputField 
                name={billingPoBoxCity.name} 
                inputProps={{ maxLength: 80 }}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography className={wizardStyle.label}>
                { billingPoBoxState.label }
              </Typography>
              <InputField 
                name={billingPoBoxState.name} 
                inputProps={{ maxLength: 10 }}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography className={wizardStyle.label}>
                { billingPoBoxZipcode.label }
              </Typography>
              <InputField 
                name={billingPoBoxZipcode.name} 
                inputProps={{ maxLength: 10 }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{marginTop: 10}}>
        <Grid item xs={12}>
          <Typography className={wizardStyle.title}>
            Mailing PO Box
          </Typography>
          <Divider/>
        </Grid>
        <Grid item xs={12}>
          <Typography className={wizardStyle.label}>
            { mailingPoBox.label }
          </Typography>
          <InputField 
            name={mailingPoBox.name}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={4}>
              <Typography className={wizardStyle.label}>
                { mailingPoBoxCity.label }
              </Typography>
              <InputField 
                name={mailingPoBoxCity.name} 
                inputProps={{ maxLength: 10 }}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography className={wizardStyle.label}>
                { mailingPoBoxState.label }
              </Typography>
              <InputField 
                name={mailingPoBoxState.name} 
                inputProps={{ maxLength: 10 }}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography className={wizardStyle.label}>
                { mailingPoBoxZipcode.label }
              </Typography>
              <InputField 
                name={mailingPoBoxZipcode.name} 
                inputProps={{ maxLength: 10 }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Step6;
