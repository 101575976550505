import AppHelper from "../../../helpers/AppHelper";
import { ExceptionService } from "../../../services";

const exceptionService = new ExceptionService();

export const handleFormSubmit = async ({
  typeModal,
  dataEdit,
  marketId,
  values,
  blockUI,
  dialogUI,
  getListExceptions,
  setOpenModal,
  snackbarUI
}) => {
  blockUI.current.open(true);
  try {
    const payload = {
      service_markets_id: marketId,
      zip: values.zip,
      exceptions: values.exceptions,
    };
    exceptionService.getAccessToken();
    const response = typeModal === 'Add'
      ? await exceptionService.createException(payload)
      : await exceptionService.editException(dataEdit.id, payload);
    
    if (response.status === 200) {
      await getListExceptions();
      dialogUI.current.close();
      const corfimButton = { 
        confirm: false, 
        btn: { 
          close: 'Close' 
        } 
      };
      const message = `Service Zone Successfully ${typeModal === 'Add' ? 'Added' : 'Edited'}`;
      dialogUI.current.open('', '', corfimButton, message);
    }
    
    setOpenModal(false);
  } catch (error) {
    AppHelper.checkError(error, snackbarUI);
  } finally {
    blockUI.current.open(false);
  }
};
