import React from 'react';

import NumberFormat from 'react-number-format';

import { Grid } from '../../../../components/shared/MaterialUI';
import { InputField, SelectField } from '../../../../forms';
import DatePickerField from '../../../../forms/DatePickerField';
import { srvFrecuencysDb, statusDb } from '../../../../models/db';
import Labels from '../../../web/signUpProcess/signUpCustomer/atoms/Labels';
import { useStyles } from '../../style/lookup-style';

const Lookup1 = (props) => {
  
  const { formikRef, expandedCollapse } = props;
  const values = formikRef.current?.values || '';
  const classes = useStyles();

  return (
    <>
      <Grid 
        item xs={4} 
        className={`${classes.gridColumns} ${classes.noPaddingLeft}`}
        >
          <Grid container>

            <Grid item xs={12}>
              <Labels text="Customer name" className="labels"/>
              <InputField
                name={'fullName'}
                placeholder="Type here"
                autoComplete="off"
                fullWidth
              />
            </Grid>
            
            <Grid item xs={12}>
              <Labels text='Status' className='labels' />
              <SelectField name={'status'} data={statusDb} fullWidth placeholder={'-- Select --'} />
            </Grid>

            {
              (expandedCollapse)
                &&
                  <>
                    <Grid item xs={12}>
                      <Labels text='Frequency' className='labels' />
                      <SelectField
                        name={'srv_frecuency'}
                        data={srvFrecuencysDb.filter((freq) => freq.value !== null)}
                        fullWidth
                        placeholder={'-- Select --'}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Labels text='Crew Number' className='labels' />
                      <InputField
                        name={'crew'}
                        placeholder='Type here'
                        inputProps={{ min: 0, minLength: 1, maxLength: 5 }}
                        InputProps={{
                          inputComponent: NumberFormat,
                        }}
                        autoComplete='off'
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Labels text='Scheduled service' className='labels' />
                      <DatePickerField
                        name='schedule_service'
                        value={values.schedule_service}
                        format='MM/dd/yyyy'
                        maxDate={new Date('2050/12/31')}
                        variant={'inline'}
                        className='datePicker'
                        autoOk
                        inputProps={{ placeholder: 'Scheduled service date' }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Labels text='Service start date' className='labels' />
                      <DatePickerField
                        name='created_at'
                        value={values.created_at}
                        format='MM/dd/yyyy'
                        maxDate={new Date('2050/12/31')}
                        variant={'inline'}
                        className='datePicker'
                        autoOk
                        inputProps={{ placeholder: 'Creation date' }}
                      />
                    </Grid>
                  </>
            }
    
          </Grid>
      </Grid>
    </>
  );
};

export default Lookup1;
