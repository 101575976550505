import React, { useContext, useEffect } from 'react';

import { useFormikContext } from 'formik';
import {
  cloneDeep as _cloneDeep, find as _find, includes as _includes,
  toLower as _toLower, without as _without,
} from 'lodash';

import { useUI } from '../../../../../app/context/ui.js';
import { LotHouse } from '../../../../../assets/images/LotHouse.js';
import checkmark from '../../../../../assets/images/lotSize.png';
import {
  Box, Button, Checkbox, FormControl,
  FormControlLabel, FormGroup, HelpRoundedIcon, Select,
  Tooltip, Typography,
} from '../../../../../components/shared/MaterialUI';
import { SignUpCustomerContext } from '../../SignUpProcess.js';
import { QuoteStyles } from '../quote-style';

const Step1 = (props) => {
  const { state, dispatch } = useContext(SignUpCustomerContext);
  const { mowCut, srvFrecuency } = state;

  const classes = QuoteStyles();
  const { dialogUI } = useUI();

  const formik = useFormikContext();

  const {
    lotSize,
    setQuotePrice,
    getQuotePrice,
    handleFrequencySelect,
    defaultBool,
    selectedLot,
    setSelectedLot,
    setDefaultBool,
    disableBagGrass,
  } = props;

  const tooltipText =
    'We do not charge more because it is a corner lot, but go by the actual turf measurements which could be higher or lower due to the amount of turf of the street easements fronting 2 streets.';

  const handleLotSelection = (e) => {
    const { value } = e.target;
    setSelectedLot('');
    if (_includes(value, 'Very large', 'Jumbo', 'Enormous')) {
      const mowCutClone = _cloneDeep(mowCut);
      if (_includes(mowCutClone, 'BagGrass')) {
        let removeMow = _without(mowCutClone, 'BagGrass');
        dispatch({
          type: 'setMowCut',
          payload: removeMow,
        });
      }
    }
    dispatch({
      type: 'setLotSize',
      payload: _find(lotSize, (lot) => {
        return lot.name === value;
      })?.lotSizeEnd,
    });
    formik.setFieldValue('selectedLot', value);
    setSelectedLot(
      _find(lotSize, (lot) => {
        return _toLower(lot.name) === _toLower(value);
      })
    );
  };

  const handleCheckboxes = (e) => {
    const { name, value } = e.target;
    if (name === 'cornerLot') {
      if (!!value === formik.values[name]) {
        formik.setFieldValue(name, !value);
        dispatch({
          type: 'setCornerLot',
          payload: !value,
        });
      } else {
        formik.setFieldValue(name, !!value);
        dispatch({
          type: 'setCornerLot',
          payload: !!value,
        });
      }
    } else {
      const mowCutClone = _cloneDeep(mowCut);
      dispatch({
        type: 'setMowCut',
        payload: value,
      });
      if (name === 'front' || name === 'back' || name === 'bagGrass') {
        setDefaultBool((prev) => ({ ...prev, [name]: !defaultBool[name] }));
      }
      if (value === formik.values[name]) {
        let removeMow = _without(mowCutClone, value);
        formik.setFieldValue(name, '');
        dispatch({
          type: 'setMowCut',
          payload: removeMow,
        });
      } else {
        mowCutClone.push(value);
        formik.setFieldValue(name, value);
        dispatch({
          type: 'setMowCut',
          payload: mowCutClone,
        });
      }
    }
  };

  useEffect(() => {
    setQuotePrice(
      getQuotePrice(
        selectedLot,
        srvFrecuency,
        _includes(mowCut, 'BagGrass'),
        _includes(mowCut, 'Front'),
        _includes(mowCut, 'Back')
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLot, srvFrecuency, mowCut]);

  useEffect(() => {
    if (formik.isSubmitting) {
      if (!formik.isValid) {
        dialogUI.current.open('Sorry', formik.errors.selectedLot);
        formik.setErrors({});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.errors]);

  return (
    <Box style={{ width: '70%' }}>
      <Typography variant='body1' className='textLabels'>
        Choose your frequency <span>*</span>
      </Typography>
      <Select native className={classes.select} onChange={(e) => handleFrequencySelect(e)} value={`f${srvFrecuency}`}>
        <option value='f1'>Weekly</option>
        <option value='f2'>Bi Weekly</option>
        <option value='f3'>3 Weekly</option>
        <option value='f4'>Drought Schedule</option>
      </Select>

      <Typography variant='body1' className='textLabels'>
        Choose your lot size <span>*</span>
      </Typography>
      <Box className={classes.lotSizesCtn}>
        {lotSize &&
          React.Children.toArray(
            lotSize.map((lot, index) => {
              return (
                <Box className={classes.radioCtn} key={index}>
                  <input
                    type='radio'
                    id={lot.name}
                    name='lot_size'
                    value={lot.name}
                    onChange={handleLotSelection}
                    style={{ display: 'none' }}
                  />
                  <label htmlFor={lot.name} className='radioLabel'>
                    <Box className='boxButton'>
                      <img src={checkmark} alt='checkmark' className='checkedImg' />
                      <Box className='lotBox' style={{ padding: lot.padding }}>
                        <LotHouse />
                      </Box>
                    </Box>
                    <Box>
                      <Typography variant='body1'>{lot.name}</Typography>
                      <Typography variant='body2' dangerouslySetInnerHTML={lot.surface()} />
                    </Box>
                  </label>
                </Box>
              );
            })
          )}
      </Box>
      <Typography variant='body1' className='textLabels'>
        Areas to Mow <span>*</span>
      </Typography>
      <FormControl component='fieldset' style={{ marginBottom: '24px' }}>
        <FormGroup>
          <FormControlLabel
            onChange={(e) => handleCheckboxes(e)}
            className={classes.checkboxLabel}
            control={
              <Checkbox checked={defaultBool.front} name='front' value='Front' disableRipple disableFocusRipple />
            }
            label='Front'
          />
          <FormControlLabel
            onChange={(e) => handleCheckboxes(e)}
            className={classes.checkboxLabel}
            control={<Checkbox checked={defaultBool.back} name='back' value='Back' disableRipple disableFocusRipple />}
            label='Back'
          />
          <FormControlLabel
            onChange={(e) => handleCheckboxes(e)}
            className={classes.checkboxLabel}
            control={<Checkbox name='cornerLot' value={true} disableRipple disableFocusRipple />}
            label={
              <Box style={{ display: 'flex' }}>
                <Typography>Corner Lot</Typography>
                <Tooltip
                  title={tooltipText}
                  placement='right-end'
                  className={classes.tooltip}
                  PopperProps={{ className: classes.tooltipPopper }}
                >
                  <HelpRoundedIcon />
                </Tooltip>
              </Box>
            }
          />
        </FormGroup>
      </FormControl>
      <Typography variant='body1' className='textLabels'>
        Optional Services <span>*</span>
      </Typography>
      <FormControl component='fieldset' style={{ marginBottom: '24px' }}>
        <FormGroup>
          <FormControlLabel
            onChange={(e) => handleCheckboxes(e)}
            className={classes.checkboxLabel}
            disabled={disableBagGrass}
            control={
              <Checkbox
                checked={defaultBool.bagGrass}
                name='bagGrass'
                value='BagGrass'
                disableRipple
                disableFocusRipple
              />
            }
            label='Bag grass'
          />
        </FormGroup>
      </FormControl>
      <Box className={classes.submitButton}>
        <Button type='submit' /* onClick={formik.handleSubmit} */>Get Quote</Button>
      </Box>
    </Box>
  );
};

export default Step1;
