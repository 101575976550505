import AppService from './AppService';
import { isNull as _isNull } from 'lodash';

class CustomerService extends AppService {
  constructor() {
    super();
    this.path = this.getPath('customers');
  }

  create(data) {
    return this.http.post(`${this.path}`, data);
  }

  update(data, id) {
    return this.http.patch(`${this.path}/${id}`, data);
  }

  updateCellPhone(data, id) {
    return this.http.patch(`${this.path}/${id}/type-cellphone`, data);
  }

  read(id) {
    return this.http.get(`${this.path}/${id}`);
  }

  list(params = '') {
    const queryParams = params ? params : '';
    return this.http.get(`${this.path}${queryParams}`);
  }

  getCustomers({ filterValue, page = 1, limit = 50, filter = 'statusOrders', sort= '-id', include = null }) {
    if (_isNull(include)) {
      return this.http.get(
        `${this.path}?render=paginate&sort=${sort}&limit=${limit}&filter[${filter}]=${filterValue}&page=${page}`
      );
    } else {
      return this.http.get(
        `${this.path}?render=paginate&sort=${sort}&limit=${limit}&filter[${filter}]=${filterValue}&page=${page}&include=${include}`
      );
    }

  }

  pagination(url, page) {
    return this.http.get(`${this.path}?${url}&page=${page}`);
  }

  listInvoices(customer, params = '') {
    const queryParams = params ? params : '';
    return this.http.get(`${this.path}/${customer}/invoices${queryParams}`);
  }

  listJobs(customer, params = '') {
    const queryParams = params ? params : '';
    return this.http.get(`${this.path}/jobs?include=attachments,notes&filter[customer_attribute_id]=${customer}${queryParams}`);
  }

  getJob(customerId, jobId) {
    return this.http.get(`${this.path}/jobs?include=attachments,notes&filter[customer_attribute_id]=${customerId}&filter[id]=${jobId}`);
  }

  getInvoices({ page = 1, limit = 50, sort= '-id', customer }) {
    return this.http.get(
      `${this.path}/${customer}/invoices?render=paginate&sort=${sort}&limit=${limit}&page=${page}`
    );
  }

  paginationInvoices(url, page, customer) {
    return this.http.get(`${this.path}/${customer}/invoices?${url}&page=${page}`);
  }

  verifyEmail(search) {
    return this.http.get(`${this.path}/verify/email?${search}`);
  }

  download(params) {
    return this.http.get(`${this.path}/download`, { params, responseType: 'blob' });
  }

  calendar(id, year, filter = 'all') {
    return this.http.get(`${this.path}/${id}/calendar?year=${year}&filter=${filter}`);
  }

  bbCalendar(id, year, filter = 'all') {
    return this.http.get(`${this.path}/${id}/bbCalendar?year=${year}&filter=${filter}`);
  }

  activities(id, year) {
    return this.http.get(`${this.path}/${id}/calendar/activities?year=${year}`);
  }

  attributes(data, id) {
    return this.http.patch(`${this.path}/${id}/attributes`, data);
  }

  todos(data, id) {
    return this.http.patch(`${this.path}/${id}/todos`, data);
  }

  calendarAlertTodo(id) {
    return this.http.get(`${this.path}/${id}/calendar/todo`);
  }

  downloadPdf(params) {
    return this.http.get(`${this.path}/downloadReport?${params}`, { responseType: 'blob' });
  }

  verifyAccount(account) {
    return this.http.get(`${this.path}/verify/account?${account}`);
  }

  me() {
    return this.http.get(`${this.path}/me`);
  }

  updateCreditCard(data, id) {
    return this.http.patch(`${this.path}/${id}/update-credit-card`, data);
  }

  delete(id) {
    return this.http.delete(`${this.path}/${id}`);
  }

  verifyAccountNumber(account) {
    return this.http.get(`${this.path}/accountNumber?account=${account}`);
  }

  downloadSchedule(id) {
    return this.http.get(`${this.path}/downloadSchedule/${id}`, { responseType: 'blob' });
  }

  sendSchedule(id) {
    return this.http.get(`${this.path}/sendSchedule/${id}`);
  }

  getSkipsCount(id){
    return this.http.get(`${this.path}/${id}/skipsCount`);
  }

  getServiceNotes(id){
    return this.http.get(`${this.path}/${id}/service-notes`);
  }

  deleteServiceNotes(data, id){
    return this.http.patch(`${this.path}/${id}/service-notes`, data);
  }

  getCustomer5thMow(id) {
    return this.http.get(
      `${this.path}/${id}/fiveMow`
    );
  }
}

export default CustomerService;
