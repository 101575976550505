import React from 'react';
import {
  Grid,
  IconButton,
  Tooltip
} from "@material-ui/core";
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import { HeaderStyle } from '../calendar/styles/HeaderStyle';
import ColorSeason from "./ColorSeason";

const Header = (props) => {
  const classes = HeaderStyle();

  return (
    <Grid container className={classes.ctnCalendar} >
      <Grid item xs={6} className={classes.subtitle}>
        <Grid container spacing={1}>
          <Grid item xs={1}>
            <ColorSeason main={'#e8e8e8'}/>
          </Grid>
          <Grid item xs={3}>
            Regular Season
          </Grid>
          <Grid item xs={1}>
            <ColorSeason main={'#cafffe'}/>
          </Grid>
          <Grid item xs={3}>
            Winter Season
          </Grid>
          <Grid item xs={1}>
            <ColorSeason main={'#fb0f1c'}/>
          </Grid>
          <Grid item xs={3}>
            Red Weeks
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={3} className={classes.title}>
        All weeks {props.year}
      </Grid>
      <Grid item xs={2} className={classes.wrapperArrows}>
        <Tooltip title="Prev">
          <IconButton aria-label="delete" onClick={props.onPrevYear}>
            <NavigateBeforeIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Next">
          <IconButton aria-label="delete" onClick={props.onNextYear}>
            <NavigateNextIcon />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  )
}

export default Header;
