export function SignUpCustomerReducer(initialState, { type, payload }) {
  switch (type) {
    case 'setExceptions': {
      return {
        ...initialState,
        zipCodeValid: payload,
      };
    }
    case 'setZipCode': {
      return {
        ...initialState,
        zipcode: payload,
      };
    }
    case 'setServiceAddres': {
      return {
        ...initialState,
        serviceAddress: payload,
      };
    }
    case 'setLocationInfo': {
      return {
        ...initialState,
        ...payload,
      };
    }
    case 'setTaxRate': {
      return {
        ...initialState,
        taxRate: payload,
      };
    }
    case 'setMarketId': {
      return {
        ...initialState,
        market: payload,
      };
    }
    case 'setsrvFrecuency': {
      return {
        ...initialState,
        srvFrecuency: payload,
      };
    }
    case 'setLotSize': {
      return {
        ...initialState,
        lotSize: payload,
      };
    }
    case 'setBasePrice': {
      return {
        ...initialState,
        basePrice: payload.basePrice,
      };
    }
    case 'setMowingPrice': {
      return {
        ...initialState,
        totalMowing: payload.totalMowing,
        subtotalMowing: payload.subtotalMowing,
      };
    }
    case 'setTotalPrice': {
      return {
        ...initialState,
        totalMowing: payload.totalMowing,
      };
    }
    case 'setMowCut': {
      return {
        ...initialState,
        mowCut: payload,
      };
    }
    case 'setCornerLot': {
      return {
        ...initialState,
        useCornerLot: payload,
      };
    }
    case 'setCheckboxes': {
      return {
        ...initialState,
        ...payload,
      };
    }
    case 'setInfoCheckboxes': {
      return {
        ...initialState,
        ...payload,
      };
    }
    case 'setTextFields': {
      return {
        ...initialState,
        ...payload,
      };
    }
    case 'setServiceDate': {
      return {
        ...initialState,
        ...payload,
      };
    }
    default:
      return initialState;
  }
}

export const initialState = {
  useServiceHousesScheduled: true,
  useServiceHousesOccupied: true,

  zipcode: '',
  market: '',
  state: '',
  serviceAddress: '',
  cityName: '',
  city: '',
  serviceDate: '',
  zipCodeValid: '',
  taxRate: '0.00',
  agrees: 'Yes',
  weekDay: '',

  lotSize: '',
  useCornerLot: false,
  srvFrecuency: 1,
  mowCut: ['Front', 'Back'],
  isFlowerBedCleaning: '',
  isBushTrimming: '',
  basePrice: '',
  totalMowing: '',
  subtotalMowing: '',

  firstName: '',
  lastName: '',
  contactNumber: '',
  isCellphone: 'Yes',
  email: '',

  grassOvergrown: '',
  isCommunityGate: '',
  communityAnswer: '',
  communityCode: '',
  isBackyardGate: '',
  isItLocked: '',
  backyardAnswer: '',
  isbackyardComboLock: '',
  gateCode: '',
  isFlowerBeds: '',
  isLineTrimBeds: '',
  flowerAnswer: '',
  isFlowerBedsDamage: '',
  isPetsBackyard: '',
  isPetNoTrouble: '',
  isFencePlastic: '',
  disclaimerFencePlastic: '',
  isAboveGroundPool: '',
  disclaimerAboveGroundPool: '',
  isTrampoline: '',
  disclaimerTrampoline: '',
  scheduleService: '',
  specialReq: '',
  about: '',
  aboutOption: '',

  isBillingAddress: '',
  isMailingAddress: '',
  billingAddress: '',
  billingCityName: '',
  billingState: '',
  billingZipCode: '',
  mailingAddress: '',
  mailingCityName: '',
  mailingZipCode: '',
  mailingState: '',
  cardNumber: '',
  cardMonth: '',
  cardYear: '',
  nameOnCard: '',
  cardType: 4,
  accountNumber: '',
  verifyAccountNumber: '',
  routingNumber: '',
};
