import { withStyles } from '@material-ui/core/styles';
import { DataGrid } from '@mui/x-data-grid';

export const DataGridStyle = withStyles({
  root: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    '& .MuiDataGrid-renderingZone': {
      maxHeight: 'none !important',
      overflow: 'scroll',
    },
    '& .MuiDataGrid-cell': {
      lineHeight: "unset !important",
      maxHeight: "none !important",
      whiteSpace: "normal",
    },
    '& .MuiDataGrid-row': {
      maxHeight: 'none !important',
    }
  },
  virtualScrollerContent: {
    height: '100% !important',
    overflow: 'scroll'
  }
})(DataGrid);
