import React from 'react';
import {
  Checkbox,
  FormControlLabel, FormGroup, FormHelperText,
} from '@material-ui/core';
import { useField } from 'formik';
import { at as _at, isArray as _isArray, assign as _assign } from 'lodash';

export default function CheckboxArrayField(props) {

  const {
    options = [
      {'label': 'Front', 'value': 'Front', 'disabled': false},
      {'label': 'Back', 'value': 'Back' , 'disabled': false},
      {'label': 'Bag grass', 'value': 'BagGrass', 'disabled': props.disabled ? props.disabled : false},
      {'label': 'Duplex right', 'value': 'DuplexRight', 'disabled': false},
      {'label': 'Duplex left', 'value': 'DuplexLeft', 'disabled': false},
    ],
    ...rest
  } = props;

  const [field, meta, helper] = useField(props);
  const {value: formikValue} = field;
  const {setValue} = helper;

  function _renderHelperText() {
    const [touched, error] = _at(meta, 'touched', 'error');
    if (touched && error) {
      return <FormHelperText error={true}>{error}</FormHelperText>;
    }
  }

  const handleChange = (e) => {
    const values = !_isArray(formikValue) ? JSON.parse(formikValue) : _assign([], formikValue) || [];
    const index = values.indexOf(e.target.value);
    if (index === -1) {
      values.push(e.target.value);
    } else {
      values.splice(index, 1);
    }
    setValue(values);
    props.onCheckboxChange(values);
  };

  return (
    <>
      <FormGroup {...rest}>
        {options.map(({value, disabled, label, ...rest}) => {
          return (
            <FormControlLabel key={value}
                              value={value}
                              checked={formikValue ? formikValue.indexOf(value) !== -1: false}
                              disabled={disabled}
                              control={<Checkbox onChange={handleChange} color="secondary"/>}
                              label={label} {...rest}/>
          );
        })}
      </FormGroup>
      {_renderHelperText()}
    </>
  );
}
