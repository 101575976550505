import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
  btnSubmit: { 
    height: '37px', 
    fontSize: '14px', 
    width: '112px'
  },
  wrapperMessage: { 
    position: 'absolute', 
    top: '60%', 
    left: '50%', 
    transform: 'translate(-50%, -50%)' 
  },
  messageNotFound: { 
    background: 'white', 
    padding: '10px', 
    borderRadius: '10px', 
    fontSize: '15px' 
  }
}));
