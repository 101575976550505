import AppService from './AppService';

class QueriesService extends AppService {

  constructor() {
    super();
    this.path = this.getPath('queries');
  }

  update(data, id) {
    return this.http.patch(`${this.path}/${id}`, data);
  }

  delete(id) {
    return this.http.delete(`${this.path}/${id}`);
  }

  read(id) {
    return this.http.get(`${this.path}/${id}`);
  }

  list(params = '') {
    const queryParams = params ? params : '';
    return this.http.get(`${this.path}${queryParams}`);
  }

  process(id) {
    return this.http.get(`${this.path}/process/${id}`);
  }

}

export default QueriesService;
