import { FormControl, MenuItem, Select } from "@material-ui/core";
import { serialize } from "object-to-formdata";
import { useEffect } from "react";
import { useState } from "react";
import { useUI } from "../../../../app/context/ui";
import { WizardStyles } from "../../../../assets/css";
import AppHelper from "../../../../helpers/AppHelper";
import { NoteService } from "../../../../services";

const statusDb = [
  {
    value: 2,
    label: 'Completed',
  },{
    value: 1,
    label: 'Pending',
  }
];

const noteService = new NoteService();

export const SelectStatus = (params) => {
const [statusId, setStatusId] = useState(params.params.value);
const { blockUI, snackbarUI } = useUI();

const wizardStyle = WizardStyles();

const noteId = params.params.row.id;

const onStatusChange = (e) => {
	try {
		blockUI.current.open(true);
		const dataToUpdate = serialize({
			...params.row,
			status: e.target.value,
			_method : 'PATCH'
		})
		noteService.getAccessToken();
		noteService.update(dataToUpdate, noteId);
		blockUI.current.open(false);
	}	catch (e) {
		blockUI.current.open(false);
		AppHelper.checkError(e, snackbarUI);
	}
	setStatusId(e.target.value);
}

useEffect(() => {
	setStatusId(params.params.value);
}, [params.params.value]);

	return (
		<div style={{paddingLeft: '8px', fontSize: '12px', width: '100%'}}>
			<FormControl size="small" fullWidth>
				<Select
					className={ statusId === 2 ? wizardStyle.completed : wizardStyle.pending }
					name="status"
					value={statusId}
					fullWidth
					variant="outlined"
					onChange={(e) => onStatusChange(e)}
				>
					{statusDb.map((status) => (
						<MenuItem key={status.value} value={status.value}>
							{status.label}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
);
};