import GoogleMapReact from 'google-map-react';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import './map.css';
import {useState} from 'react';

const GoogleMap = (props) => {
  const {center, zoom, text} = props;
  const [mapType, setMapType] = useState('hybrid');
  const [mapMarkColor, setMapMarkColor] = useState('white');
  const [mapOptions, setMapOptions] = useState({
    mapTypeId: 'hybrid',
    gestureHandling: "none",
    zoomControl: false,
  });

  const LocationPin = ({text}) => (
    <div className="pin">
      <LocationOnRoundedIcon className="pin-icon" style={{color: mapMarkColor}} fontSize={'large'}/>
      <p className="pin-text" style={{color: mapMarkColor}}>{text}</p>
    </div>
  );

  const onChangeMapType = (type) => {
    if (type === 'hybrid') {
      setMapMarkColor('white');
    } else {
      setMapMarkColor('black');
    }
    setMapType(type);
    setMapOptions({
      mapTypeId: type,
      gestureHandling: "none",
      zoomControl: false,
    });
  };

  return (
    <div>
      <div className="google-map">
        <GoogleMapReact
          bootstrapURLKeys={{key: 'AIzaSyDYUv7rStrtu1uoQis-4SgihcLl-Iyi55w'}}
          center={center}
          zoom={zoom}
          options={mapOptions}
        >
          {text !== '' &&
          <LocationPin text={text}/>
          }
        </GoogleMapReact>
      </div>
      <div style={{marginTop: 3}}>
        <ButtonGroup disableElevation variant="contained" size={'small'}>
          <Button style={{textTransform: 'none'}} color={mapType === 'roadmap' ? 'secondary' : 'primary'}
                  onClick={() => onChangeMapType('roadmap')}>Map</Button>
          <Button style={{textTransform: 'none'}} color={mapType === 'hybrid' ? 'secondary' : 'primary'}
                  onClick={() => onChangeMapType('hybrid')}>Satellite</Button>
        </ButtonGroup>
      </div>
    </div>
  );
};

export default GoogleMap;
