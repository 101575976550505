import AppService from './AppService';

class BbCrewService extends AppService {
  
  constructor() {
    super();
    this.path = this.getPath('bb-crew');
  }

  list(params = '') {
    const queryParams = params ? params : '';
    return this.http.get(`${this.path}${queryParams}`);
  }

  create(data) {
    return this.http.post(`${this.path}`, data);
  }

  update(data, id) {
    return this.http.patch(`${this.path}/${id}`, data);
  }

  read(id) {
    return this.http.get(`${this.path}/${id}`);
  }

}

export default BbCrewService;
