import {makeStyles} from "@material-ui/core/styles";

export const AdminPanelStyle = makeStyles((theme) => ({
  h2: {
    fontWeight: 'bold',
    fontSize: '28px',
    margin: '0px',
    fontFamily: 'sans-serif',
  },
  container: {
    width: '100%',
    height: '100vh',
    padding: '20px 44px',
    backgroundColor: 'white',
    marginTop: '40px',
    '& p': {
      fontSize: '15px',
      fontWeight: 'bold',
    },
    '& .market': {
      margin: '18px 0px',
      fontSize: '15px',
    },
    '& .infoWeek': {
      fontSize: '13px',
      marginTop: '16px',
    },
  },
  formControl: {
    minWidth: 358,
    minHeight: 32,
    '& .MuiSelect-select': {
      minHeight: '9px',
      maxHeight: '9px',
      fontSize: '15px',
    },
  },
  label: {
    fontStyle: 'normal !important',
    fontWeight: '400 !important',
    fontSize: '14px !important',
    lineHeight: '16px !important',
    paddingBottom: '10px !important',
  },
  inputLabel: {
    fontSize: '13px',
  },
  wrapperThisWeek: {
    textAlign: 'center',
    fontSize: '12px',
    marginTop: '16px',
    display: 'grid',
    gridTemplateColumns: 'repeat(8, 1fr)',
    gridTemplateRows: 'repeat(3, 1fr)',
    gridColumnGap: '7px',
    gridRowGap: '7px',
    '& .div1': {
      gridArea: '1 / 1 / 2 / 2',
      height: '65px',
      backgroundColor: '#E8E8E8',
      borderRadius: '5px',
    },
    '& .div2': {
      gridArea: '1 / 2 / 2 / 3',
      height: '65px',
      backgroundColor: '#E8E8E8',
      borderRadius: '5px',
    },
    '& .div3': {
      gridArea: '1 / 3 / 2 / 4',
      height: '65px',
      backgroundColor: '#E8E8E8',
      borderRadius: '5px',
    },
    '& .div4': {
      gridArea: '1 / 4 / 2 / 5',
      height: '65px',
      backgroundColor: '#E8E8E8',
      borderRadius: '5px',
    },
    '& .div5': {
      gridArea: '1 / 5 / 2 / 6',
      height: '65px',
      backgroundColor: '#E8E8E8',
      borderRadius: '5px',
    },
    '& .div6': {
      gridArea: '1 / 6 / 2 / 7',
      height: '65px',
      backgroundColor: '#E8E8E8',
      borderRadius: '5px',
    },
    '& .div7': {
      gridArea: '1 / 7 / 2 / 8',
      height: '65px',
      backgroundColor: '#E8E8E8',
      borderRadius: '5px',
    },
    '& .div8': {
      gridArea: '1 / 8 / 2 / 9',
      height: '65px',
      backgroundColor: '#E8E8E8',
      borderRadius: '5px',
    },
    '& .div9': {
      gridArea: '2 / 1 / 3 / 2',
      height: '65px',
      backgroundColor: '#E8E8E8',
      borderRadius: '5px',
    },
    '& .div10': {
      gridArea: '2 / 2 / 3 / 3',
      height: '65px',
      borderRadius: '5px',
      border: 'solid 1px #E8E8E8',
    },
    '& .div11': {
      gridArea: '2 / 3 / 3 / 4',
      height: '65px',
      borderRadius: '5px',
      border: 'solid 1px #E8E8E8',
    },
    '& .div12': {
      gridArea: '2 / 4 / 3 / 5',
      height: '65px',
      borderRadius: '5px',
      border: 'solid 1px #E8E8E8',
    },
    '& .div13': {
      gridArea: '2 / 5 / 3 / 6',
      height: '65px',
      borderRadius: '5px',
      border: 'solid 1px #E8E8E8',
    },
    '& .div14': {
      gridArea: '2 / 6 / 3 / 7',
      height: '65px',
      borderRadius: '5px',
      border: 'solid 1px #E8E8E8',
    },
    '& .div15': {
      gridArea: '2 / 7 / 3 / 8',
      height: '65px',
      backgroundColor: '#E8E8E8',
      borderRadius: '5px',
    },
    '& .div16': {
      gridArea: '2 / 8 / 3 / 9',
      height: '65px',
      backgroundColor: '#E8E8E8',
      borderRadius: '5px',
    },
    '& .div17': {
      gridArea: '3 / 1 / 4 / 2',
      height: '65px',
      backgroundColor: '#E8E8E8',
      borderRadius: '5px',
    },
    '& .div18': {
      gridArea: '3 / 2 / 4 / 3',
      height: '65px',
      borderRadius: '5px',
      border: 'solid 1px #E8E8E8',
    },
    '& .div19': {
      gridArea: '3 / 3 / 4 / 4',
      height: '65px',
      borderRadius: '5px',
      border: 'solid 1px #E8E8E8',
    },
    '& .div20': {
      gridArea: '3 / 4 / 4 / 5',
      height: '65px',
      borderRadius: '5px',
      border: 'solid 1px #E8E8E8',
    },
    '& .div21': {
      gridArea: '3 / 5 / 4 / 6',
      height: '65px',
      borderRadius: '5px',
      border: 'solid 1px #E8E8E8',
    },
    '& .div22': {
      gridArea: '3 / 6 / 4 / 7',
      height: '65px',
      borderRadius: '5px',
      border: 'solid 1px #E8E8E8',
    },
    '& .div23': {
      gridArea: '3 / 7 / 4 / 8',
      height: '65px',
      backgroundColor: '#E8E8E8',
      borderRadius: '5px',
    },
    '& .div24': {
      gridArea: '3 / 8 / 4 / 9',
      height: '65px',
      backgroundColor: '#E8E8E8',
      borderRadius: '5px',
    },
  },
  infoTitle: {
    fontWeight: 'bold',
    marginTop: '11px',
    marginBottom: '6px',
  },
  infoTitleCol: {
    textAlign: 'center',
    marginTop: '23px',
    fontWeight: 'bold',
  },
  dataWrap: {
    marginTop: '23px',
  },
  buttonEdit: {
    color: '#fff',
    backgroundColor: '#000000',
    height: '28px',
    width: '97px',
    fontSize: '10px',
    '& .MuiSvgIcon-root': {
      fontSize: '13px',
    },
  },
  buttonUpdate: {
    marginTop: '34px',
    width: '33%',
    height: '35px',
    fontSize: '16px',
    backgroundColor: 'black',
    fontWeight: '700',
    lineHeight: '18.75px',
    marginBottom: '32px',
  },
  buttonEditEnabled: {
    color: 'white',
    backgroundColor: 'black',
    height: '28px',
    width: '97px',
    fontSize: '15px',
    '& .MuiSvgIcon-root': {
      fontSize: '13px',
    },
    '&:hover': {
      backgroundColor: '#10AA39',
      color: 'white',
    },
  },
  buttonEditDisabled: {
    color: 'black',
    backgroundColor: 'white',
    height: '28px',
    width: '97px',
    fontSize: '10px',
    '& .MuiSvgIcon-root': {
      fontSize: '13px',
    },
    '&:hover': {
      backgroundColor: '#10AA39',
      color: 'white',
    },
  },
  paperModal: {
    position: 'absolute',
    width: 550,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflowY: 'scroll',
  },
  wrapperDates: {
    display: 'flex',
    justifyContent: 'center',
    margin: '7px',
  },
  blockWeekDays: {
    float: 'left',
    borderRadius: '6px',
    fontSize: '9px',
    textAlign: 'center',
    width: '52px',
    margin: '2px',
    cursor: 'pointer',
    borderWidth: '1px',
    borderStyle: 'solid',

    '@media (min-width: 1100px) and (max-width: 1275px)': {
      width: '70.6px',
    },
    '@media (min-width: 1275px) and (max-width: 1600px)': {
      width: '75.6px',
    },
    '& .div1': {
      fontSize: '14px',
      fontWeight: 'bold',
      borderRadius: '5px 5px 0px 0px',
      padding: '5px 6px',
      marginBottom: '1px',
    },
    '& .div2': {
      fontSize: '12px',
      borderRadius: '0px 0px 5px 5px',
      padding: '5px 5px',
    },
  },
  errorCustom: {
    fontSize: '13px',
    color: 'red',
    margin: '9px 0px',
    textAlign: 'center'
  },
  wrapperAddZip: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '27px',
  },
  btnAddZip: {
    marginLeft: '10px',
    width: '147px',
    backgroundColor: 'black !important'
  },
}));
