import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  values: {
    pageSize: 0,
    record: 0,
    rowCount: 0,
    searchCustom: '',
    filterCustom: '',
  }
};

const scrollCustomerSlice = createSlice({
  name: 'scroll',
  initialState,
  reducers: {
    updateScrollCustomer: (state, action) => {
      state.values = {
        ...state.values,
        ...action.payload
      };
    }
  }
});

export const { updateScrollCustomer } = scrollCustomerSlice.actions;

export default scrollCustomerSlice.reducer;
