import React, { useCallback, useContext, useEffect } from 'react';

import { Form, useFormikContext } from 'formik';
import { isEqual as _isEqual, keys as _keys } from 'lodash';
import moment from 'moment-business-days';
import NumberFormat from 'react-number-format';
import { Element, scroller } from 'react-scroll';

import DateFnsUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import { useUI } from '../../../../../app/context/ui.js';
import {
  Box, Button, Select, TextareaAutosize,
  TextField, Typography,
} from '../../../../../components/shared/MaterialUI.js';
import { SignUpCustomerContext } from '../../SignUpProcess.js';
import { SignUpStyles } from '../styles/signUp-style';
import HearBoutUs from './components/HearBoutUs.js';
import alertMessages from './messages/alertMessages.json';
import YesNoQuestions from './YesNoQuestions';

const CustomerInfo = () => {
  const { state, dispatch } = useContext(SignUpCustomerContext);
  const { scheduleService, serviceDate } = state;
  const classes = SignUpStyles();

  const { dialogUI } = useUI();

  const formik = useFormikContext();
  const { errors, touched } = formik;

  const todays = moment(new Date()).format('MM/DD/yyyy');
  const [date, setDate] = React.useState(moment(todays, 'MM/DD/yyyy').nextBusinessDay()._d);

  const disableWeekends = useCallback((date) => {
    return date.day() === 0 || date.day() === 6;
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    formik.setFieldValue(name, value);
    dispatch({
      type: 'setTextFields',
      payload: { [name]: value },
    });
  };
  const handlePhoneNumber = (val) => {
    const { value } = val;
    formik.setFieldValue('contactNumber', value);
    dispatch({
      type: 'setTextFields',
      payload: { contactNumber: value },
    });
  };

  useEffect(() => {
    if (_isEqual(scheduleService, 'oneTime')) {
      dialogUI.current.open('Sorry', alertMessages.schedule);
    }
    dispatch({
      type: 'setServiceDate',
      payload: { serviceDate: moment(date).format('MM/DD/yyyy'), weekDay: date.getDay()},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, scheduleService]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCalendarChange = (e) => {
    setDate(e._d);
    dispatch({
      type: 'setServiceDate',
      payload: { serviceDate: moment(e._d).format('MM/DD/yyyy'), weekDay: date.getDay()},
    });
  };

  const handleScroll = () => {
    let formikErrors = errors ? _keys(errors) : null;
    if (formikErrors) {
      scroller.scrollTo(formikErrors[0], {
        smooth: true,
      });
    }
  };

  return (
    <>
      <Box className={classes.text}>
        <Typography>
          This page is for new customers only. To make changes to an existing or past customer account please call our
          office at 972-430-4626. Office hours are Monday-Friday, 9am–5pm.
        </Typography>
      </Box>
      <Box style={{ display: 'flex', alignItems: 'center', marginTop: '32px' }}>
        <img src='https://www.mowmanagers.com/wp-content/uploads/2020/08/secure-trust-logo.png' alt='' width='165px' />
        <Typography className={classes.trustwaves}>
          This site is protected by Trustwave&apos;s Trusted Commerce Program
        </Typography>
      </Box>
      <Form className={classes.formCtn}>
        <Typography className={classes.titles}>Customer Info</Typography>
        <Typography variant='body1' className='textLabels'>
          First name <span>*</span>
        </Typography>
        <TextField name='firstName' onChange={(e) => handleChange(e)} variant='filled' required />
        <Typography variant='body1' className='textLabels'>
          Last name <span>*</span>
        </Typography>
        <TextField name='lastName' onChange={(e) => handleChange(e)} variant='filled' required />
        <Element style={{ position: 'relative', width: '90%' }} name='contactNumber'>
          <Typography variant='body1' className='textLabels'>
            Contact Number <span>*</span>
          </Typography>
          <NumberFormat
            format='+1 (###) ###-####'
            mask='_'
            onValueChange={(val) => handlePhoneNumber(val)}
            className='phoneNumber'
            name='contactNumber'
            isNumericString
          />
          {errors.contactNumber && touched.contactNumber && (
            <>
              <Typography
                style={{ position: 'absolute', left: '10px', bottom: '0', color: '#FF0060', fontSize: '14px' }}
              >
                {errors.contactNumber}
              </Typography>
            </>
          )}
        </Element>
        <Typography variant='body1' className='textLabels'>
          Is it a cellphone? <span>*</span>
        </Typography>
        <Select
          native
          className={classes.select}
          onChange={(e) => handleChange(e)}
          inputProps={{ name: 'isCellphone' }}
        >
          <option value='Yes' defaultValue>
            Yes
          </option>
          <option value='No'>No</option>
        </Select>
        <Box style={{ position: 'relative' }}>
          <Typography variant='body1' className='textLabels'>
            Email
          </Typography>
          <TextField name='email' onChange={(e) => handleChange(e)} variant='filled' required />
          {errors.email && touched.email && (
            <>
              <Typography
                style={{ position: 'absolute', left: '10px', bottom: '0', color: '#FF0060', fontSize: '14px' }}
              >
                {errors.email}
              </Typography>
            </>
          )}
        </Box>
        <Typography variant='body1' className='textLabels'>
          Do you want one-time service or scheduled service? <span>*</span>
        </Typography>
        <Select
          native
          className={classes.select}
          onChange={(e) => handleChange(e)}
          inputProps={{ name: 'scheduleService' }}
        >
          <option value='' defaultValue>
            -- Select --
          </option>
          <option value='oneTime'>One-time</option>
          <option value='schedule'>Schedule</option>
        </Select>
        <Typography variant='body1' className='textLabels'>
          Service Start Date <span>*</span>
        </Typography>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            value={date}
            inputValue={serviceDate}
            inputVariant='filled'
            variant='inline'
            disableToolbar
            format='MM/DD/yyyy'
            shouldDisableDate={disableWeekends}
            minDate={moment(todays, 'MM/DD/yyyy').nextBusinessDay()._d}
            maxDate={new Date('2050/12/31')}
            disablePast
            autoOk
            onChange={(e) => handleCalendarChange(e)}
            minDateMessage={null}
          ></DatePicker>
        </MuiPickersUtilsProvider>
        <YesNoQuestions />
        <Typography variant='body1' className='textLabels'>
          Special Requests
        </Typography>
        <TextareaAutosize
          className={classes.textArea}
          aria-label='maximum height'
          name='specialReq'
          onChange={(e) => handleChange(e)}
        />
        <Typography variant='body1' className='textLabels'>
          How did you hear about us?
        </Typography>
        <HearBoutUs />
        <Button type='submit' className={classes.button} onClick={() => handleScroll()}>
          Proceed
        </Button>
      </Form>
    </>
  );
};

export default CustomerInfo;
