import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFormikContext } from 'formik';
import { NoteStyles } from './NoteStyles';

const style = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  width: '545px',
};

const AttachmentForm = () => {
  const { values, setFieldValue } = useFormikContext();
  const [files, setFiles] = useState(values.attachments);
  const [disabled, setDisabled] = useState(false);
  const filesRef = useRef(files);
  const noteStyles = NoteStyles();

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        const found = filesRef.current.find((file) => file.name === acceptedFiles[0].name);
        if (!found) {
          filesRef.current = filesRef.current.concat(acceptedFiles);
          setFieldValue('attachments', filesRef.current);
          setFiles(filesRef.current);
        }
      }
    },
    [setFieldValue]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/jpeg, image/png, audio/*', disabled });

  const onRemove = (name) => {
    filesRef.current = filesRef.current.filter((file) => file.name !== name);
    setFiles(filesRef.current);
    setFieldValue('attachments', filesRef.current);
  };


  const proposedFiles = files.map((file) => (
    <li key={file.filename}>
      {file.filename ?? file.path}
      <a className={noteStyles.removeAttachment} onClick={() => onRemove(file.name)}>
        (Remove)
      </a>
    </li>
  ));

  useEffect(() => {
    setFiles(values.attachments);
    setDisabled(values.disabled);
    filesRef.current = values.attachments;
  }, [values]);

  return (
    <section>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>Drag drop some files here, or click to select files</p>
      </div>
      <aside>
        <h4>Selected files:</h4>
        <ul>{proposedFiles}</ul>
      </aside>
    </section>
  );
};

export default AttachmentForm;
