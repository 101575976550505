import React, { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Box, Grid, Modal, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';

import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { has as _has } from 'lodash';
import clsx from 'clsx';

import { WizardStyles } from '../../../assets/css';
import { AppStyle } from '../../../assets/css/app/AppStyle';
import AppButton from '../../../components/forms/AppButton';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const formState = {
  email: '',
};

const ReportEmailModal = (props) => {
  const { open, setOpen, handleSendEmail } = props;
  const [error] = useState(false);
  const [loading] = useState({ state: false, message: 'Saving...' });
  const [emails, setEmails] = useState([]);
  const [modalStyle] = useState(getModalStyle);
  const appStyle = AppStyle();
  const wizardStyle = WizardStyles();

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email').required('Field is required'),
  });

  const emailsList = emails.map((email) => email).join(', ');

  const addEmailToList = (values) => {
    setEmails([...emails, values.email]);
  };

  useEffect(() => {
    if (_has(props, 'open')) {
      setOpen(props.open);
    }
  }, [props, setOpen]);
  
  const handleClose = () => {
    setEmails([]);
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
      disableEscapeKeyDown={true}
    >
      <div style={modalStyle} className={wizardStyle.paperModalSmall}>
      <Formik
          initialValues={formState}
          validationSchema={validationSchema}
          enableReinitialize={true}
        >
          {(props) => {
            const { values, handleSubmit, errors, handleChange, resetForm } = props;
            return (
              <Form autoComplete="off">
                <Grid container alignItems="center" spacing={0}>
                  <Grid container item xs={12}>
                    <Grid item xs={12}>
										<h2 className={clsx(appStyle.mrg0V)} style={{ textAlign: 'center' }}>Emails </h2>
                    </Grid>
                  </Grid>
									<div style={{ width: '100%', marginTop: '16px' }}>
										<Box display='flex' flexDirection='column' mb={2}>
											<Box>
												<TextField
													error={_has(errors, 'email')}
													name='email'
													value={values.email}
													onChange={handleChange}
													variant='outlined'
													helperText={_has(errors, 'email') ? errors.email : ''}
													style={{ width: '100%' }}
													fullWidth={true}
													disabled={values.disabled}
													placeholder="Write a email"
												/>
											</Box>
										</Box>
									</div>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      Emails:{' '}
                     {emailsList}
                    </Grid>
                    <Grid item xs={12}>
                      {loading.state && (
                        <Alert variant='outlined' icon={<HourglassEmptyIcon fontSize='inherit' />} severity='warning'>
                          {loading.message}
                        </Alert>
                      )}
                      {error && <Alert severity='warning'>{error}</Alert>}
                    </Grid>
                    <Grid item xs={12}>
                      <AppButton
                        onPress={() => {
                          handleSubmit(addEmailToList(values));
                          resetForm();
                        }}
                        label={'Add email'}
                        type={'main'}
                        color={'primary'}
                        disabled={values.disabled || loading.state}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <AppButton
                        label={'Send'}
                        type={'main'}
                        color={'primary'}
                        disabled={values.disabled || loading.state}
                        onPress={() => {
                          handleSendEmail(emails);
                          handleClose();
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    mm: state.mm,
  };
};
export default connect(mapStateToProps)(memo(ReportEmailModal));
