import React, {
  useCallback, useEffect, useRef, useState, useContext
} from 'react';

import { Form, Formik, useFormikContext } from 'formik';
import {
  first as _first, replace as _replace, toNumber as _toNumber,
} from 'lodash';
import NumberFormat from 'react-number-format';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  Divider, 
  FormControlLabel, 
  Grid, 
  InputLabel, 
  Switch, 
  TextField, 
  Typography 
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { useUI } from '../../../app/context/ui';
import { updateProperty } from '../../../app/store/mm/customerSlice';
import { WizardStyles } from '../../../assets/css';
import GoogleAutocomplete from '../../../components/shared/GoogleAutocomplete';
import { Box } from '../../../components/shared/MaterialUI';
import { InputField, SelectField } from '../../../forms';
import AutoSave from '../../../forms/AutoSave';
import { Utils } from '../../../helpers';
import AppHelper from '../../../helpers/AppHelper';
import { cardTypeDb, srvFrecuencysDb } from '../../../models/db';
import ValidationFields from '../../../models/validationFields';
import { BusinessLogicService, StateService, LogicService, NoteService } from '../../../services';
import CustomerService from '../../../services/CustomerService';
import MapsService from '../../../services/MapsService';
import { PreviousCustomerContext } from '../EditCustomer';
import format from 'date-fns/format';

const mapsService = new MapsService();
const stateService = new StateService();
const customerService = new CustomerService();
const logicService = new LogicService();

const cardTypeMap = cardTypeDb.reduce((acc, item) => {
  acc[item.value] = item.label;
  return acc;
}, {});

const noteService = new NoteService();

const Step4 = (props) => {
  const [isValidZip, setIsValidZip] = useState(null);
  const { setBagrass } = useContext(PreviousCustomerContext);
  const [options, setOptions] = useState({
    citys: [],
    states: [],
  });
  const { formikRef } = props;
  const [srvCost, setSrvCost] = useState({});
  const [note, setNote] = useState('');
  const [formIsFocused, setFormIsFocused] = useState(false);

  const [openDialog5thMow, setOpenDialog5thMow] = useState(false);

  const {
    formField: {
      billingName,
      cardType,
      costService,
      totalMowing,
      taxRate,
      billingZipCode,
      billingAddress,
      billingState,
      srvFrecuency,
    },
  } = props;
  const { blockUI, snackbarUI } = useUI();
  const formikBilling = useRef();
  const dispatch = useDispatch();
  const { values: formValues } = useFormikContext();
  const { id } = formValues;
  const wizardStyle = WizardStyles();
  const history = useHistory();
  const businessLogicService = new BusinessLogicService();

  const [customer5thMow, setCustomer5thMow] = useState([]);
  const [autoSave, setautoSave] = useState(true);

  const initialValues = {
    billingName: formValues?.billingName,
    cardType: formValues?.cardType ? cardTypeMap[formValues?.cardType] : 'E-Check',
    costService: formValues?.costService,
    totalMowing: Number(formValues?.totalMowing).toFixed(2),
    taxRate: formValues?.taxRate,
    billingZipCode: formValues?.billingZipCode ?? '',
    billingAddress: formValues?.billingAddress,
    billingCity: formValues?.billingCity,
    billingCityName: formValues?.billingCityName ?? '',
    billingState: formValues?.billingState ?? '',
    srvFrecuency: formValues?.srvFrecuency,
    poBoxEnabled: formValues?.poBoxEnabled,
    billingPoBox: formValues?.billingPoBox,
    billingPoBoxCity: formValues?.billingPoBoxCity ?? '',
    billingPoBoxState: formValues?.billingPoBoxState ?? '',
    billingPoBoxZipcode: formValues?.billingPoBoxZipcode ?? '',
  };
  const validationSchema = Yup.object({
    billingName: ValidationFields.billingName(),
    cardType: ValidationFields.cardType(),
    costService: Yup.number().required(`Field is required`),
    totalMowing: Yup.number().required(`Field is required`),
    billingZipCode: Yup.string()
      .required(`${billingZipCode.requiredErrorMsg}`)
      .matches(/^[0-9]{5}$/, `${billingZipCode.invalidErrorMsg}`)
      .test('len', `${billingZipCode.invalidErrorMsg}`, (val) => val && val !== isValidZip),
    billingAddress: ValidationFields.address(),
    billingCityName: ValidationFields.city(),
    billingState: ValidationFields.state(),
  });

  const propsZipCode = {
    min: 0,
    style: { textAlign: 'center' },
    minLength: 1,
    maxLength: 5,
  };

  const onKeyPress = (e) => {
    if (e.key === 'Enter' || e.target.value.length === 5) {
      (async function init() {
        await getZipCodeState(e.target.value, true);
      })();
    }
  };

  const getZipCodeState = useCallback(
    async (zip, option = false) => {
      try {
        if (formikBilling.current?.values.poBoxEnabled) {
          return null;
        }
        stateService.getAccessToken();
        const r1 = await stateService.zip(zip);
        const arrCity = Utils.updateArrayObjectItem(r1.data, [], 'id', 'city');
        const arrState = Utils.updateArrayObjectItem(r1.data, [], 'stateId', 'stateName');
        setOptions({ ...options, citys: arrCity, states: arrState });
        if (option) {
          const objCity = _first(arrCity);
          const objState = _first(arrState);
          if (formikBilling.current) {
            formikBilling.current?.setFieldValue('billingCity', objCity.value);
            formikBilling.current?.setFieldValue('billingCityName', objCity.label);
            formikBilling.current?.setFieldValue('billingState', objState.value);
          }
        }
      } catch (e) {
        setIsValidZip(zip);
        AppHelper.checkError(e, snackbarUI);
      }
    },
    [options, snackbarUI]
  );

  const setUpdatePrice = useCallback(
    async (taxRate, costService, srvFrecuency) => {

      if (costService && formValues.srvFrecuency) {
        logicService.getAccessToken();
        let find = '';
        find += `costService=${Number(costService)}`;
        find += `&lotSize=${_toNumber(formValues.lotSize)}`;
        find += `&turfSize=${_toNumber(formValues.turfSize)}`;
        find += `&taxRate=${_toNumber(taxRate)}`;
        find += `&srvFrequency=${srvFrecuency}`;
        find += `&isBagging=${formValues.mowCut.includes('BagGrass')}`;
        find += `&businesslogicId=${_toNumber(formValues.billingLotSize)}`;

        const { data: dataBaggrass } = await logicService.proccessBaggrass(find);

        formikBilling.current?.setFieldValue('subtotalMowing', dataBaggrass.newSubtotal);
        formikBilling.current?.setFieldValue('totalMowing', dataBaggrass.total);
        formikBilling.current?.setFieldValue('calculatedTax', dataBaggrass.tax);
        srvFrecuency === 3 || srvFrecuency === 4 ? setBagrass(true) : setBagrass(false);

      }
    },
    [formValues, formikRef, setBagrass]
  );

  const setServiceCost = useCallback(
    async (srv, key) => {
      if (formikBilling.current) {
        const costService = _toNumber(_replace(srv[`f${key}`], '$', ''));
        formikBilling.current?.setFieldValue('costService', costService);
        await setUpdatePrice(initialValues.taxRate, costService, key);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialValues.taxRate]
  );




  const getFindLotSize = useCallback(
    async (value, turfSize = true, isLoad = false) => {
      try {
        blockUI.current.open(true);
        businessLogicService.getAccessToken();

        const r1 = turfSize
          ? await businessLogicService.findTurfSizeCustomer(value, id)
          : await businessLogicService.findLotSizeCustomer(value, id);
        setSrvCost(JSON.parse(r1.data.serviceFrequency)); //aqui
        if (!isLoad) {
          await setServiceCost(JSON.parse(r1.data.serviceFrequency), formValues.srvFrecuency);
        }

        blockUI.current.open(false);
      } catch (e) {
        blockUI.current.open(true);
        AppHelper.checkError(e, snackbarUI);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [blockUI, setServiceCost, formValues.srvFrecuency, snackbarUI]
  );

  const handleSubmit = useCallback(
    async (values) => {
      try {
        blockUI.current.open(true);
        customerService.getAccessToken();
        if (srvCost.current?.initialValues?.billingAddress !== values.billingAddress) {
          await updateAddress(values.billingAddress).then((res) => {
            customerService.update(
              {
                ...values,
                billingCityName: res.city,
                state: res.state,
                billingZipCode: res.zipCode,
              },
              id
            );
          });
        } else {
          await customerService.update(values, id);
        }

        blockUI.current.open(false);
        dispatch(updateProperty(values));
      } catch (e) {
        blockUI.current.open(false);
        AppHelper.checkError(e, snackbarUI);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [blockUI, dispatch, id, snackbarUI]
  );

  const handleUpdate = useCallback(
    async (values) => {
      try {
        blockUI.current.open(true);
        customerService.getAccessToken();
        if (values) {
          await customerService.update(values, id);
        }

        blockUI.current.open(false);
        dispatch(updateProperty(values));
      } catch (e) {
        blockUI.current.open(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [blockUI, dispatch, id]
  );


  const setFields = useCallback(
    async (key, value) => {
      try {
        blockUI.current.open(true);
        customerService.getAccessToken();

        let payload = null
        switch (key) {
          case 'costService':
            payload = { costService: value }
            break;
          case 'totalMowing':
            payload = { totalMowing: value }
            break;
          case 'taxRate':
            payload = { taxRate: value }
            break;
          default:
            break;
        }


        if (value && payload) {
          await handleUpdate(payload).then(async () => {
            const { data: dataFields } = await customerService.read(
              id
            );
            formikBilling.current?.setFieldValue('taxRate', _toNumber(dataFields.taxRate));
            formikBilling.current?.setFieldValue('totalMowing', _toNumber(dataFields.totalMowing));
            formikBilling.current?.setFieldValue('costService', Number(dataFields.costService));
            dispatch(updateProperty({'totalMowing': _toNumber(dataFields.totalMowing)}));
            dispatch(updateProperty({'subtotalMowing': _toNumber(dataFields.subtotalMowing)}));
            setTimeout(() => {
              setautoSave(true);
            }, 500);

          });
        }
        blockUI.current.open(false);

      } catch (e) {
        blockUI.current.open(false);
        AppHelper.checkError(e, snackbarUI);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formValues, formikRef]
  );


  const handleChangeFields = (key, e) => {
    setautoSave(false);
    if (e.key === 'Enter' || e.target.value.length > 1 || key === 'taxRate' &&
        (initialValues?.taxRate !== formikBilling.current.values?.taxRate ||
            initialValues?.costService !== formikBilling.current.values?.costService ||
            initialValues?.totalMowing !== formikBilling.current.values?.totalMowing)
    ) {
      (function init() {
        setTimeout(async () => {
          await setFields(key, e.target.value);
        }, 500);

      })();
    }

  };

  const updateAddress = async (data) => {
    mapsService.getAccessToken();
    const r1 = await mapsService.getAddress(data);
    formikBilling.current?.setFieldValue('billingCityName', r1.data.city);
    formikBilling.current?.setFieldValue('billingZipCode', r1.data.zipCode);
    await getZipCodeState(r1.data.zipCode, true);
    return r1.data;
  };

  const handleRedirectUpdatePayment = () => {
    history.push('/payment-list', { id: id });
  };

  const handleMowCutWithoutBagGrass = () => {
    const mowCutWithoutBagGrass = formValues.mowCut.filter(
      (item) => item !== "BagGrass"
    );
    formikBilling.current?.setFieldValue("mowCut", mowCutWithoutBagGrass);
    dispatch(updateProperty({ mowCut: mowCutWithoutBagGrass }));
  };

  useEffect(() => {
    (async function init() {
      if(formValues.billingZipCode){
        await getZipCodeState(formValues.billingZipCode);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.billingZipCode]);

  const handleChange = (event) => {
    if (event.target.value > 0) {
      if (Number(event.target.value) === 4) {
        handleMowCutWithoutBagGrass();
      }
      (async function init() {
        await setServiceCost(srvCost, event.target.value);
      })();
    }
  };

  useEffect(() => {
    (async function init() {
      if (formValues?.turfSize) {
        const value = formValues?.turfSize > 0 ? formValues?.turfSize : formValues?.lotSize;
        const turfSize = formValues?.turfSize > 0;
        await getFindLotSize(value, turfSize, true);
      }
    })();
  }, [formValues?.lotSize, formValues?.turfSize, getFindLotSize]);

  const getCustomer5thMow = async () => {
    try {
      blockUI.current.open(true);
      customerService.getAccessToken();
      const r1 = await customerService.getCustomer5thMow(id);
      setCustomer5thMow(r1.data);
      setNote(r1.data.note?.description ?? '');
      blockUI.current.open(false);
      return r1.data;
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  useEffect(() => {
    getCustomer5thMow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const onSend = async () => {
    try {
      blockUI.current.open(true);
      const serviceDate = new Date();
      const serviceHour = ('0' + serviceDate.getHours()).slice(-2) + ':' + ('0' + serviceDate.getMinutes()).slice(-2);
      const typeId = 5;
      const customerAttributesId = id;

      const formData = {
        serviceDate: format(serviceDate, 'yyyy-MM-dd'),
        serviceHour: serviceHour,
        typeId: typeId,
        customerAttributesId: customerAttributesId,
        description: note === '' ? '\u00A0' : note,
      };
      noteService.getAccessToken();
      await noteService.create(formData);
      const r1 = await customerService.getCustomer5thMow(id);
      setCustomer5thMow(r1.data);
      setNote(r1.data.note?.description ?? '');
      setOpenDialog5thMow(false)
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const handleChangeStatusPoBox = (e) => {
    if (formikBilling.current) {
      formikBilling.current.setFieldValue(
        'poBoxEnabled', e.target.checked
      );
    }
  }

  const renderZipCodeField = (name) => (
    <Grid item xs={12} sm={4}>
      <InputLabel className={wizardStyle.label}>Zip code:</InputLabel>
      <InputField
        name={name}
        onKeyUp={name !== 'billingPoBoxZipcode' ? onKeyPress : undefined}
        inputProps={propsZipCode}
        InputProps={{ inputComponent: NumberFormat }}
        fullWidth
      />
    </Grid>
  );

  const renderCityField = (name) => (
    <Grid item xs={12} sm={4}>
      <InputLabel className={wizardStyle.label}>City:</InputLabel>
      <InputField 
        name={name}
        inputProps={{ maxLength: 80 }}
        autoFocus 
        fullWidth 
      />
    </Grid>
  );

  const renderStateField = (name, isSelectField = false, options = []) => (
    <Grid item xs={12} sm={4}>
      <InputLabel className={wizardStyle.label}>State:</InputLabel>
      {
        isSelectField ? (
          <SelectField name={name} data={options} autoFocus fullWidth />
        ) : (
          <InputField name={name} autoFocus fullWidth />
        )
      }
    </Grid>
  );

  return (
    <>
      <Formik
        innerRef={formikBilling}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({values}) => (
          <Form autoComplete='off'  onFocus={() => setFormIsFocused(true)} onBlur={() => setFormIsFocused(false)}>
            <Divider />
            <Box my={2} />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <InputLabel className={wizardStyle.label}>Billing Name:</InputLabel>
                <InputField name={billingName.name} fullWidth />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel className={wizardStyle.label}>Payment type *</InputLabel>
                <InputField
                  name={cardType.name}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4} className={wizardStyle.mt0}>
                <div className={wizardStyle.labelIcon}>
                  <FormControlLabel
                    control={
                      <Switch 
                        size="small"
                        checked={values.poBoxEnabled} 
                        onChange={handleChangeStatusPoBox}
                      />
                    }
                    label={
                      (values.poBoxEnabled) 
                      ? <span className={wizardStyle.labelStreet} >PO box</span> 
                      : <span className={wizardStyle.labelStreet}>Street address</span>
                    }
                  />
                </div>

                {
                  (values.poBoxEnabled)
                    ?
                      <InputField
                        name="billingPoBox"
                        style={{marginTop: '8px'}}
                        fullWidth
                      />
                    : 
                      <GoogleAutocomplete 
                        formikRef={formikBilling} 
                        name={billingAddress.name} 
                        style={{marginTop: '8px'}}
                      />
                }
                
              </Grid>

              {
                (values.poBoxEnabled)
                  ?
                    <>
                      { renderZipCodeField("billingPoBoxZipcode") }
                      { renderCityField("billingPoBoxCity") }
                      { renderStateField("billingPoBoxState") }
                    </>
                  :
                    <>
                      { renderZipCodeField(billingZipCode.name) }
                      { renderCityField("billingCityName") }
                      { renderStateField(billingState.name, true, options.states) }
                    </>
              }
              
              <Grid item xs={12} sm={4}>
                <InputLabel className={wizardStyle.label}>Base Price:</InputLabel>
                <InputField
                  onBlur={(event) => { handleChangeFields('costService', event) }}
                  name={costService.name}
                  InputProps={{ inputComponent: NumberFormat }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel className={wizardStyle.label}>Price:</InputLabel>
                <InputField
                  onBlur={(event) => { handleChangeFields('totalMowing', event) }}
                  name={totalMowing.name}
                  InputProps={{ inputComponent: NumberFormat }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel className={wizardStyle.label}>Tax rate:</InputLabel>
                <InputField
                  onBlur={(event) => { handleChangeFields('taxRate', event) }}
                  name={taxRate.name}
                  InputProps={{ inputComponent: NumberFormat }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel className={wizardStyle.label}>Frequency:</InputLabel>
                <SelectField
                  name={srvFrecuency.name}
                  data={srvFrecuencysDb}
                  onClick={handleChange}
                  autoFocus
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Button
                  variant='contained'
                  color='primary'
                  endIcon={<SendIcon />}
                  onClick={handleRedirectUpdatePayment}
                >
                  Update Payment
                </Button>
                <Button
                  variant='contained'
                  color='secondary'
                  endIcon={<SendIcon />}
                  onClick={() => {
                    setOpenDialog5thMow(true);
                  }}
                  style={{ marginLeft: '15px' }}
                >
                  5th Mow
                </Button>
              </Grid>
              <Grid item xs={12}>
                {autoSave && (
                  <>
                    <AutoSave flag={autoSave} debounceMs={500} id={id} isFocused={formIsFocused}/>
                  </>
                )}
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <Dialog
        open={openDialog5thMow}
        onClose={() => setOpenDialog5thMow(false)}>
        <DialogTitle className={wizardStyle.dialogTitle} >5th Mow</DialogTitle>
        <DialogContent>
          <Typography variant='h7' className={wizardStyle.dialogContentTitle}>
            Requirements
          </Typography>
          <Box className={wizardStyle.boxField}>
            {customer5thMow?.newCustomer ?
              (<CheckBoxIcon color="secondary" />) :
              (<CheckBoxOutlineBlankIcon color="secondary" />)
            }
            <TextField
              className={wizardStyle.labelDialog}
              defaultValue="New Customer"
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
            />
          </Box>
          <Box className={wizardStyle.boxField}>
            {customer5thMow?.eChecks ?
              (<CheckBoxIcon color="secondary" />) :
              (<CheckBoxOutlineBlankIcon color="secondary" />)
            }
            <TextField
              className={wizardStyle.labelDialog}
              defaultValue="Payment Type: eChecks"
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
            />
          </Box>
          <Box className={wizardStyle.boxField}>
            {!customer5thMow?.fiveThNow ?
              (<CheckBoxOutlineBlankIcon color="secondary" />) :
              (<CheckBoxIcon color="secondary" />)
            }
            <TextField
              className={wizardStyle.labelDialog}
              defaultValue="Has not received discount yet"
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
            />
          </Box>
          <Box className={wizardStyle.discountStatusBox}>
            <Typography variant='h7' style={{ fontWeight: 'bold' }}>
              Discount status:
            </Typography>
            <Typography variant='h7' className={wizardStyle.discountStatusText}>
              {customer5thMow?.newCustomer && customer5thMow?.eChecks && customer5thMow?.fiveThNow ?
                ('Qualifies') :
                ('Does not Qualify')
              }
            </Typography>
          </Box>
          <Box>
            <Typography variant='h7' style={{ display: 'block' }}>
              Note
            </Typography>
            <TextField
              defaultValue=""
              variant="outlined"
              multiline
              minRows={2}
              maxRows={Infinity}
              disabled={customer5thMow?.newCustomer && customer5thMow?.eChecks && customer5thMow?.fiveThNow ? false : true}
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button className={wizardStyle.buttonDialog} onClick={() => setOpenDialog5thMow(false)}>Cancel</Button>
          <Button

            variant="contained"
            color="secondary"
            disabled={customer5thMow?.newCustomer && customer5thMow?.eChecks && customer5thMow?.fiveThNow ? false : true}
            onClick={onSend}
          >
            Apply 5th Mow discount
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Step4;
