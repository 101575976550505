import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  values: {
    count: 0,
  }
};

const skipsSlice = createSlice({
  name: 'skips',
  initialState,
  reducers: {
    updateSkipCount: (state, action) => {
      state.values = {
        ...state.values,
        ...action.payload
      };
    }
  }
});

export const { updateSkipCount } = skipsSlice.actions;

export default skipsSlice.reducer;
