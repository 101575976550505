import { makeStyles } from '../../../components/shared/MaterialUI';

export const useStyles = makeStyles(() => ({
  tabs: {
    position: 'relative',
    '&::after': {
      position: 'absolute',
      bottom: '3px',
      content: '""',
      backgroundColor: 'rgba(232, 232, 232, 1)',
      width: '100%',
      height: '1px',
    },
    '& .MuiTabs-flexContainer': {
      justifyContent: 'center',
      transition: 'all 1s ease',
      '& .MuiTab-textColorInherit': {
        fontFamily: 'Open Sans',
        color: '#2F3A4A',
      },
      '& .MuiTab-textColorInherit.Mui-selected': {
        fontWeight: 700,
        color: '#5887D6',
      },
    },
    '& .MuiTab-root': {
      minWidth: '170px',
    },
    '& .MuiTabs-indicator': {
      display: 'block',
      margin: '0 35px',
      maxWidth: 100,
      width: '100%',
      height: '3px',
      backgroundColor: '#11aa39',
      color: '#11aa39 !important',
      textAlign: 'center',
    },
    '& .Mui-selected':{
      color: '#11aa39 !important'
    }
  },
  titleLookup:{
    marginBottom: '36px', 
    fontSize: '28px', 
    fontWeight: 700, 
    fontFamily: 'Open Sans'
  }
  
}));
