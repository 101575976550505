import { Avatar, FormControl, Grid, IconButton, Menu, MenuItem, Select } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useStyles from './ExternalCalendarStyles';
import { useUI } from '../../app/context/ui';
import ExternalCalendarService from '../../services/ExternalCalendarService';
import WeeksOfYear from '../../components/calendar/WeeksOfYear';
import logo from '../../assets/images/logo.png';
import {
  ExpandMore,
} from '@material-ui/icons';
import { DataGrid } from '@material-ui/data-grid';
import clsx from 'clsx';
import AppHelper from "../../helpers/AppHelper";

const externalCalendarService = new ExternalCalendarService();

const columns = [
  {
    field: "accountNumber",
    headerName: "Account Number",
    width: 210,
    renderCell: (params) => (params?.row?.customerId),
  },
  {
    field: "date",
    headerName: "Date",
    width: 180,
    renderCell: (params) => (params?.row?.createdAt),
  },
  {
    field: "mowWeek",
    headerName: "Mow Week",
    width: 200,
    renderCell: (params) => (params?.row?.crewService?.week),
  },
  {
    field: "mowDay",
    headerName: "Mow Day",
    width: 160,
    renderCell: (params) => (params?.row?.crewService?.weekDay),
  },
  {
    field: "pricingPerService",
    headerName: "Pricing per Service",
    width: 260,
    renderCell: (params) => (`$${Number(params?.row?.amount).toFixed(2)}`),
  },
  {
    field: "paymentType",
    headerName: "Payment Type",
    width: 210,
    renderCell: (params) => (params?.row?.customerPayment?.paymentTypeName),
  },
];

const ExternalCalendar = () => {

  const location = useLocation();
  const classes = useStyles();
  const data = location?.state ? Object.values(location.state) : [];
  const history = useHistory();
  const [addressCurrent, setAddressCurrent] = useState('');
  const { blockUI, snackbarUI } = useUI();
  const [dataRender, setDataRender] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const [myNumber, setMyNumber] = useState('');
  const [weekDay, setWeekDay] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [costService, setCostService] = useState('');
  const [isIOS, setIsIOS] = useState(false);

  const [rows, setRows] = useState([]);

  const handleChangeAddressCurrent = (event) => {
    setAddressCurrent(event.target.value);
  };

  const getCalendar = async () => {
    try {
      blockUI.current.open(true);
      const { data: dataCalendar } = await externalCalendarService.getCalendarWithToken(addressCurrent);
      const { data: dataPayment } = await externalCalendarService.getPayments(addressCurrent);
      setRows(dataPayment.data);
      setDataRender(dataCalendar.calendar.data);
      setYear(dataCalendar.year);
      const dataCurrent = data.find((e)=>e.token?.accessToken === addressCurrent);
      setAddressCurrent(dataCurrent?.token?.accessToken || '');
      setMyNumber(dataCurrent?.contactNumber || '')
      setWeekDay(dataCurrent?.weekDay || '');
      setAccountNumber(dataCurrent?.accountNumber || '');
      setCostService(dataCurrent?.costService || '');
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  }

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        onClick={() => {
          history.push("/external-search-phone");
        }}
      >
        Logout
      </MenuItem>
    </Menu>
  );

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    const userAgent = navigator.userAgent;
    setIsIOS(/iPad|iPhone|iPod/.test(userAgent));
    if(!data || data.length === 0){
      history.push("/external-search-phone");
    } else {
      setAddressCurrent(data[0]?.token?.accessToken || '');
      setMyNumber(data[0]?.contactNumber || '')
      setWeekDay(data[0]?.weekDay || '');
      setAccountNumber(data[0]?.accountNumber || '');
      setCostService(data[0]?.costService || '');
    }
  }, []);

  useEffect(() => {
    if(addressCurrent){
      getCalendar();
    }
  }, [addressCurrent]);

  return (
    <div className={classes.wrapperExternalCalendar}>
      <Grid container className={classes.externalCalendarContainer}>
        <Grid item md={6} xs={1}>
          <Avatar alt='Mow Managers' src={logo} />
        </Grid>
        <Grid item md={6} xs={11} className={classes.wrapperClose}>
          Hi { myNumber }
          <IconButton
            edge='end'
            aria-label='account of current user'
            aria-haspopup='true'
            onClick={handleProfileMenuOpen}
            color='inherit'
          >
            <ExpandMore />
          </IconButton>
        </Grid>
      </Grid>
      <div className={classes.wrapperGeneral}>
        <h3>
          Service Schedule
        </h3>
        <Grid container style={isIOS ? { fontSize: '15px' } : null}>
          <Grid item md={1} xs={12} className={classes.titleAddress}>
            Address
          </Grid>
          <Grid item md={11} xs={12}>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={addressCurrent}
                onChange={handleChangeAddressCurrent}
                fullWidth
              >
                {
                  data.map((dataContact)=>(
                    <MenuItem
                      key={dataContact?.token?.accessToken}
                      value={dataContact?.token?.accessToken}
                    >
                      { dataContact?.serviceAddress}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid container className={classes.wrapperDataInfo}>
            <Grid item md={4} xs={12} className={classes.infoData}>
              <Grid container>
                <Grid item md={4} xs={12} style={{fontWeight: 'bold'}}>
                  Account Number:
                </Grid>
                <Grid item md={8} xs={12}>
                  { accountNumber }
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={4} xs={12} className={classes.infoData}>
              <Grid container>
                <Grid item md={2} xs={12} style={{fontWeight: 'bold'}}>
                  Weekday:
                </Grid>
                <Grid item md={7} xs={12} className={classes.weekDayInfo}>
                  { weekDay }
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={4} xs={12} className={classes.infoData}>
              <Grid container>
                <Grid item md={4} xs={12} style={{fontWeight: 'bold'}}>
                  Price per service:
                </Grid>
                <Grid item md={8} xs={12}>
                  { `$${Number(costService).toFixed(2)}` }
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.wrapperContainerCalendar}>
          <Grid item md={12} className={classes.titleCalendar}>
            Calendar {year}
          </Grid>
          <Grid item md={6}>
            <WeeksOfYear
              dataRender={dataRender}
              setDataRender={setDataRender}
              year={year}
              type="external"
            />
          </Grid>
          <Grid item md={6}/>
          <Grid item md={12}>
            <div className={classes.wrapperInfoCalendar}>
              <DataGrid
                className={clsx(classes.dataGrid, classes.root)}
                rows={rows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      {
        renderMenu
      }
    </div>
  )
}

export default ExternalCalendar;
