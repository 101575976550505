import AppService from './AppService';

class SrvService extends AppService {
  constructor() {
    super();
    this.path = this.getPath('web');
  }

  zipCode(code) {
    return this.http.get(`${this.path}/${code}`);
  }
}

export default SrvService;
