import { makeStyles } from '@material-ui/core/styles';

export const AlarmsStyle = makeStyles(() => ({
  wrapper:{
    border: 'solid 1.5px #eeeeee',
    borderRadius: '3px',
    margin: '25px 0px',
    padding: '18px 18px',
    '& .date': {
      fontSize: '18px',
      fontWeight: '700',
      paddingTop: '5px'
    },
    '& .hour': {
      fontSize: '18px',
      fontWeight: '700',
      paddingLeft: '37px',
      paddingTop: '5px'
    },
    '& .switch': {
      paddingLeft: '49px'
    },
    '& .detail': {
      fontSize: '13px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      marginTop: '8px',
    },
    '& .view': {
      textAlign: 'center',
      marginTop: '10px',
      color: '#0080007a',
    }
  },
  wrapperNotAlarms: {
    textAlign: 'center',
    padding: '27px',
    fontSize: '14px',
    border: 'solid 1px #8080805c',
    margin: '17px 0px',
    borderRadius: '8px',
    color: 'gray',
  },
  btnViewAll: {
    textAlign: 'center',
    marginTop: '23px'
  },
  containerCreateAlarm: {
    padding: '0px',
    marginTop: '51px',
    '& .title': {
      paddingTop: '23px',
      fontWeight: 'bold',
    },
    '& .wrapperForm': {
      marginTop: '15px'
    },
    '& .infoIcon': {
      color: 'green'
    },
    '& .regarding': {
      fontWeight: '700',
    }
  }
}));
