import React, { useEffect } from 'react'
import { useState } from 'react'
import { AppStyle } from '../../../assets/css/app/AppStyle';
import { WizardStyles } from '../../../assets/css';
import { 
  Box, 
  Grid, 
  IconButton, 
  Modal 
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const ModalNote = (props) => {

  const { nameModal, notes, status } = props;
  const appStyle = AppStyle();
  const [open, setOpen] = useState(false);
  const [modalStyle] = useState(getModalStyle);
  const wizardStyle = WizardStyles();

  useEffect(() => {
    setOpen(status);
  }, []);
  
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableEscapeKeyDown={true}
      style={{ zIndex: 1250 }}
      >
      <div style={modalStyle} className={wizardStyle.paperModal}>
        <Grid container alignItems="center" spacing={2}>
          <Grid container item xs={12}>
            <Grid item xs={10}>
              <h2 className={clsx(appStyle.mrg0V)}>{nameModal}</h2>
            </Grid>
            <Grid item xs={2}>
            <IconButton
              color="primary"
              aria-label="close"
              className={clsx(appStyle.pdg0, appStyle.btnRight)}
              onClick={() => setOpen(false)}
            >
              <CloseIcon/>
            </IconButton>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {
              notes.map((note, index) => {
                return (
                <Box key={`note${index}`} marginTop={2} display="flex" flexDirection="row" gridGap={10}>
                  <p className={appStyle.mrg0V}>{note.serviceDate}</p>
                  <p className={appStyle.mrg0V}>{note.description}</p>
                </Box>
              )})
            }
          </Grid>
        </Grid>
      </div>
    </Modal>
  )
}

export default ModalNote;
