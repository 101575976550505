import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Grid, IconButton, TextField } from "@material-ui/core";
import { useFormikContext } from "formik";
import { WizardStyles } from "../../../assets/css";
import { useUI } from "../../../app/context/ui";
import { CustomerService } from "../../../services";
import { connect } from "react-redux";
import { PreviousCustomerContext } from "../EditCustomer";
import AppHelper from "../../../helpers/AppHelper";
import DeleteIcon from '@material-ui/icons/Delete';
import {InputField} from "../../../forms";

let dlgSettings = {
  confirm: true,
  btn: {
    close: 'Cancel',
    confirm: 'Yes',
  },
  onConfirm: () => {},
};

const Step2ReadOnly = (props) => {

  const { blockUI, dialogUI, snackbarUI } = useUI();
  const {
    formField: {
      swrInstruction
    }
  } = props;
  const { values: formValues } = useFormikContext();
  const { id } = formValues;
  const [ dataServiceNote, setDataServiceNote] = useState({
    redo: [],
    skips: [],
    write: [],
  });

  const { updateServiceNote, setUpdateServiceNote } = useContext(PreviousCustomerContext);
  const customerService = useMemo(() => new CustomerService(), []);
  const wizardStyle = WizardStyles();

  const handleSetterIdDelete = (data, type) => {
    dlgSettings = {
      ...dlgSettings,
      onConfirm: () => {
        onDelete(data, type);
      },
    };
    dialogUI.current.open(
      'This action cannot be undone, are you sure to proceed?',
      'You are about to delete the service.',
      dlgSettings
    );
  };

  const onDelete = async (data, type) => {
    try {
      blockUI.current.open(true);
      customerService.getAccessToken();
      const dataDelete = {
        type,
        week: data.week
      };
      const { status } = await customerService.deleteServiceNotes(dataDelete, id);
      if (status === 200) {
        await getServiceNotes();
        const confirmSettings = {
          confirm: false,
          btn: {
            close: 'Close',
          },
        };
        dialogUI.current.close();
        dialogUI.current.open('', '', confirmSettings, 'Service Successfully deleted');
        setUpdateServiceNote(new Date());
      }
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const getServiceNotes = (async () => {
    try {
      blockUI.current.open(true);
      customerService.getAccessToken();
      setDataServiceNote({ redo: [], skips: [], write: []});
      const { data } = await customerService.getServiceNotes(id);
      setDataServiceNote(data);
      blockUI.current.open(false);
    } catch (error) {
      blockUI.current.open(false);
    }
  });

  useEffect(() => {
    (async function init() {
      await  getServiceNotes();
    })();
  }, [updateServiceNote]);

  return (
    <Grid container justifyContent="center" alignItems="flex-start" spacing={5}>
      <Grid item xs={4}>
        <Grid container>
          <Grid item xs={5}>
            <span className={wizardStyle.title}>Skips</span>
          </Grid>
          <Grid item xs={6}>
            Counter: {dataServiceNote.skips?.[0]?.count ? dataServiceNote.skips[0]?.count || 0 : 0}
          </Grid>
          <Grid item xs={1}>
            <IconButton
              style={dataServiceNote.skips?.length === 0  || !dataServiceNote.skips?.[0].workday ? {} : {color: '#0daa3a'}}
              size="small"
              disabled={dataServiceNote.skips?.length === 0 || !dataServiceNote.skips?.[0].workday}
              onClick={
                ()=>{
                  handleSetterIdDelete(dataServiceNote.skips?.[0], "skips")
                }
              }
            >
              <DeleteIcon/>
            </IconButton>
          </Grid>
          <Grid item xs={5}>
            <span className={wizardStyle.label}>Service day</span>
          </Grid>
          <Grid item xs={7}>
            <TextField
              value={dataServiceNote.skips?.[0]?.workday ? dataServiceNote.skips[0].workday || '' : ''}
              disabled
              fullWidth
            />
          </Grid>
          <Grid item xs={5}>
            <span className={wizardStyle.label}>Customer manager</span>
          </Grid>
          <Grid item xs={7}>
            <TextField
              value={dataServiceNote.skips?.[0]?.firstName ? `${dataServiceNote.skips[0]?.firstName ?? ''} ${dataServiceNote.skips[0]?.lastName ?? ''}` : ''}
              disabled
              fullWidth
            />
          </Grid>
          <Grid item xs={5}>
            <span className={wizardStyle.label}>Reason</span>
          </Grid>
          <Grid item xs={7}>
            <TextField
              value={dataServiceNote.skips?.[0]?.message ? `${dataServiceNote.skips[0]?.message || ''}` : ''}
              disabled
              multiline
              minRows={4}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={4}>
        <Grid container>
          <Grid item xs={11}>
            <span className={wizardStyle.title}>Write</span>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              style={dataServiceNote.write?.length === 0  || !dataServiceNote.write?.[0].workday ? {} : {color: '#0daa3a'}}
              size="small"
              disabled={dataServiceNote.write?.length === 0  || !dataServiceNote.write?.[0].workday}
              onClick={
                ()=>{
                  handleSetterIdDelete(dataServiceNote.write?.[0], "write")
                }
              }

            >
              <DeleteIcon/>
            </IconButton>
          </Grid>
          <Grid item xs={5}>
            <span className={wizardStyle.label}>Service day</span>
          </Grid>
          <Grid item xs={7}>
            <TextField
              value={dataServiceNote.write?.[0]?.workday ? `${dataServiceNote.write[0]?.workday || ''}` : ''}
              disabled
              fullWidth
            />
          </Grid>
          <Grid item xs={5}>
            <span className={wizardStyle.label}>Customer manager</span>
          </Grid>
          <Grid item xs={7}>
            <TextField
              value={dataServiceNote.write?.[0]?.firstName ? `${dataServiceNote.write[0]?.firstName ?? ''} ${dataServiceNote.write[0]?.lastName ?? ''}` : ''}
              disabled
              fullWidth
            />
          </Grid>
          <Grid item xs={5}>
            <span className={wizardStyle.label}>Reason</span>
          </Grid>
          <Grid item xs={7}>
            <TextField
              value={dataServiceNote.write?.[0]?.message ? `${dataServiceNote.write[0]?.message || ''}` : ''}
              disabled
              multiline
              minRows={4}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={4}>
        <Grid container>
          <Grid item xs={11}>
            <span className={wizardStyle.title}>Redo</span>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              style={dataServiceNote.redo?.length === 0  || !dataServiceNote.redo?.[0].workday ? {} : {color: '#0daa3a'}}
              size="small"
              disabled={dataServiceNote.redo?.length === 0 || !dataServiceNote.redo?.[0].workday}
              onClick={
                ()=>{
                  handleSetterIdDelete(dataServiceNote.redo?.[0], "redo")
                }
              }
            >
              <DeleteIcon/>
            </IconButton>
          </Grid>
          <Grid item xs={5}>
            <span className={wizardStyle.label}>Service day</span>
          </Grid>
          <Grid item xs={7}>
            <TextField
              value={dataServiceNote.redo?.[0]?.workday ? `${dataServiceNote.redo[0]?.workday || ''}` : ''}
              disabled
              fullWidth
            />
          </Grid>
          <Grid item xs={5}>
            <span className={wizardStyle.label}>Customer manager</span>
          </Grid>
          <Grid item xs={7}>
            <TextField
              value={dataServiceNote.redo?.[0]?.firstName ? `${dataServiceNote.redo[0]?.firstName ?? ''} ${dataServiceNote.redo[0]?.lastName ?? ''}` : ''}
              disabled
              fullWidth
            />
          </Grid>
          <Grid item xs={5}>
            <span className={wizardStyle.label}>Reason</span>
          </Grid>
          <Grid item xs={7}>
            <TextField
              value={dataServiceNote.redo?.[0]?.message ? `${dataServiceNote.redo[0]?.message || ''}` : ''}
              disabled
              multiline
              minRows={4}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <span className={wizardStyle.title}>Instructions</span>
      </Grid>
      <Grid item xs={12}>
        <InputField  name={swrInstruction.name} inputProps={{ readOnly: true }} fullWidth/>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    mm: state.mm.customer.options,
  };
};

export default connect(mapStateToProps)(Step2ReadOnly);
