import React from 'react';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Button } from '@material-ui/core';
import { NotificationsStyle } from '../../styles/NotificationsStyle';
import { useSelector } from 'react-redux';
import { useUI } from '../../../../app/context/ui';
import AppHelper from '../../../../helpers/AppHelper';
import { AlarmService } from '../../../../services';

let dlgSettings = {
  confirm: true,
  btn: {
    close: 'Cancel',
    confirm: 'Delete',
  },
  onConfirm: () => {},
};

const alarmService = new AlarmService();

const AlarmDelete = (props) => {

  const alarmsAvailable = useSelector(({mm}) => mm.alarm) || [];
  const { dialogUI, blockUI, snackbarUI } = useUI();
  const { alarmsDelete, handleRemoveAlarm, customStatusAlarm } = props;
  const notificationsStyle = NotificationsStyle();

  const handleDeleteAlarms = () => {
    try {
      blockUI.current.open(true);
      let idsDelete = [];
      alarmsDelete.map((e, index)=>{
        if(e.active){
          idsDelete = [ ...idsDelete, alarmsAvailable.options[index]?.id];
        }
      });
      let stringDelete = '';
      if(idsDelete.length === 0){
        dialogUI.current.open('oops', 'Please select at least one alarm.');
      }else{
        idsDelete.map((id,index)=>{
          stringDelete = `${stringDelete}&ids[${index}]=${id}`;
        });
        dlgSettings = {
          ...dlgSettings,
          confirm: true,
          onConfirm: () => {
            onDeleteAlarm(stringDelete, idsDelete);
          },
        };
        dialogUI.current.open(
          'Wait!',
          'Are you sure you want to delete this alarm?',
          dlgSettings,
          'This process cannot be reverserd'
        );
      }
      blockUI.current.open(false);
    } catch (e) {
      dialogUI.current.open('oops', 'Has had an error, try again later.');
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  }

  const onDeleteAlarm = async (stringDelete,idsDelete) => {
    try {
      blockUI.current.open(true);
      alarmService.getAccessToken();
      await alarmService.delete(stringDelete);
      dialogUI.current.close();
      blockUI.current.open(false);
      dlgSettings = {
        ...dlgSettings,
        confirm: false,
        btn: {
          close: 'Close',
        },
      };
      dialogUI.current.open('', '', dlgSettings, 'Successfully deleted');
      handleRemoveAlarm(alarmsAvailable, idsDelete);
      customStatusAlarm();
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  };

  return (
    <div>
      <Button
        variant='contained'
        color='primary'
        size='small'
        startIcon={<DeleteForeverIcon/>}
        onClick={() => {handleDeleteAlarms()}}
        className={notificationsStyle.buttonActions}
      >
       Clear alarm
      </Button>
    </div>
  )
}

export default AlarmDelete;
