import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  Container,
  CssBaseline,
  Typography,
  Button,
  CardMedia,
} from "../../components/shared/MaterialUI";

import { useHistory } from "react-router-dom";
import { useUI } from "../../app/context/ui";
import logo from "../../assets/images/login-logo.png";
import ExternalCalendarService from "../../services/ExternalCalendarService";
import { InputField } from "../../forms";
import { FormatPhoneNumber } from "../../helpers";
import {LoginStyles} from '../../assets/css';
  
const externalCalendarService = new ExternalCalendarService();

const ExternalSearchPhone = () => {

  const [hasError, setHasError] = useState({});
  const [requestFailed, setRequestFailed] = useState(false);
  const loginStyle = LoginStyles();
  const { blockUI } = useUI();
  const history = useHistory();

  useEffect(() => {
    if (!setRequestFailed) {
      setHasError("");
    }
  }, [requestFailed]);

  const ExternalCalendarSchema = Yup.object().shape({
    contact_number: Yup.string()
      .matches(/^\d{10}$/, 'Number is invalid. Please try again.')
      .required("Number is required")
  });

  const onSubmit = async (values) => {
    try {
      blockUI.current.open(true);
      setRequestFailed(false);
      const { data } = await externalCalendarService.searchPhone(values);
      blockUI.current.open(false);
      history.push("/external-calendar", {...data});
    } catch (e) {
      blockUI.current.open(false);
      setRequestFailed(true);
      setHasError({ message: e.response.data.message });
    }
  };

  return (
    <Container component="main" maxWidth="lg" className={loginStyle.bgMain}>
      <Typography component="div" className={loginStyle.panForm} style={{width: '90%'}}>
        <CssBaseline />
        <Typography component="div" className={loginStyle.alignItemsAndJustifyContent}>
          <CardMedia
            className={loginStyle.cardLogo}
            component="img"
            image={logo}
            title="Mow Manager"
          />
        </Typography>
        <Typography component="div">
          {requestFailed && (
            <p className={loginStyle.formError}>{hasError.message}</p>
          )}
        </Typography>

        <Formik
          initialValues={{
            contact_number: "",
          }}
          onSubmit={(values,{resetForm}) => {
            onSubmit(values, resetForm);
          }}
          validationSchema={ExternalCalendarSchema}
        >
          {() => {
            return (
              <Form className={loginStyle.formMain} style={{top: '90px'}}>
                <InputField
                  name={'contact_number'}
                  InputProps={{inputComponent: FormatPhoneNumber}}
                  placeholder="Enter your phone number here"
                  autoComplete="off"
                  fullWidth
                  style={{marginTop: '53px'}}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classNames(loginStyle.btnSubmit, loginStyle.btnSendReset)}
                  style={{marginTop: '36px', marginBottom: '22px'}}
                >
                  Continue
                </Button>
              </Form>
            );
          }}
        </Formik>
      </Typography>
    </Container>
  );
};

export default ExternalSearchPhone;
