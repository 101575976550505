import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  Container,
  CssBaseline,
  Grid,
  Typography,
  Button,
  IconButton,
  Input,
  InputAdornment,
  Visibility,
  VisibilityOff,
  CardMedia,
  Box,
} from '../../../components/shared/MaterialUI';

import { useHistory, Link } from 'react-router-dom';

import { useUI } from '../../../app/context/ui';


import logo from '../../../assets/images/login-logo.png';
import { LoginStyles } from '../../../assets/css';
import AuthWebService from '../../../services/AuthWebService';

const authWebService = new AuthWebService();

const ResetPasswordPage = (props) => {
  const [hasError, setHasError] = useState({});
  const [requestFailed, setRequestFailed] = useState(false);

  const loginStyle = LoginStyles();

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const { blockUI } = useUI();
  const history = useHistory();
  const query = new URLSearchParams(props.location.search);
  const token = query.get('token') ?? '';


  useEffect(() => {
    if (!setRequestFailed) {
      setHasError('');
    }
  }, [requestFailed]);

  const LoginSchema = Yup.object().shape({
    password: Yup.string().min(8, 'Minimum 8 characters').required('Password is required'),
    password_confirmation: Yup.string()
      .min(8, 'Minimum 8 characters')
      .required('Password confirmation is required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });

  const onSubmit = async (values) => {
    try {
      blockUI.current.open(true);
      setRequestFailed(false);

      // change password
      const r1 = await authWebService.passwordChange(values);
      blockUI.current.open(false);
      history.push('/web/login', { ...r1.data });
    } catch (e) {
      blockUI.current.open(false);
      setRequestFailed(true);
      setHasError({ message: e.response.data.message });
    }
  };

  return (
    <Container component='main' maxWidth='lg' className={loginStyle.bgMain}>
      <Typography component='div' className={loginStyle.panForm}>
        <CssBaseline />
        <Typography component='div' className={loginStyle.alignItemsAndJustifyContent}>
          <CardMedia className={loginStyle.cardLogo} component='img' image={logo} title='Mow Manager' />
        </Typography>
        <Typography component='div'>
          {requestFailed && (
            <p className={loginStyle.formError}>
              {hasError.message}
            </p>
          )}
        </Typography>
        <Typography component='div' className={loginStyle.formMain}>
          <Formik
            initialValues={{
              password: '',
              showPassword: false,
              password_confirmation: '',
              password_token: token,
            }}
            onSubmit={(values, { resetForm }) => {
              onSubmit(values, resetForm);
            }}
            validationSchema={LoginSchema}
          >
            {(props) => {
              const { values, touched, errors, handleBlur, handleChange, setFieldValue } = props;
              return (
                <Form>
                  <Input
                    margin='none'
                    className={classNames(loginStyle.inputPassword, loginStyle.inputRecover)}
                    required
                    fullWidth
                    autoFocus={true}
                    name='password'
                    id='standard-adornment-password'
                    type={values.showPassword ? 'text' : 'password'}
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder='Password'
                    error={!!(errors.password && touched.password)}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          className={loginStyle.icoShowPassword}
                          aria-label='toggle password visibility'
                          onClick={() => {
                            setFieldValue('showPassword', !values.showPassword);
                          }}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {values.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {errors.password && touched.password ? (
                    <p className={classNames(loginStyle.formError)}>{errors.password}</p>
                  ) : null}
                  <Box pb={2} />
                  <Input
                    margin='none'
                    className={classNames(loginStyle.inputPassword)}
                    required
                    fullWidth
                    name='password_confirmation'
                    id='standard-adornment-password-confirmation'
                    type={values.showPassword ? 'text' : 'password'}
                    value={values.password_confirmation}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder='Confirm password'
                    error={!!(errors.password_confirmation && touched.password_confirmation)}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          className={loginStyle.icoShowPassword}
                          aria-label='toggle password visibility'
                          onClick={() => {
                            setFieldValue('showPassword', !values.showPassword);
                          }}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {values.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {errors.password_confirmation && touched.password_confirmation ? (
                    <p className={classNames(loginStyle.formError)}>{errors.password_confirmation}</p>
                  ) : null}
                  <Box pb={3} />
                  <Button type='submit' fullWidth variant='contained' color='primary' className={loginStyle.btnSubmit}>
                    RESET MY PASSWORD
                  </Button>

                  <Grid container justify='flex-end' className={loginStyle.gridForgotPassword}>
                    <Grid item>
                      <Link to='/login' variant='body2' className={loginStyle.link}>
                        Go back to login
                      </Link>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Typography>
      </Typography>
    </Container>
  );
};

export default ResetPasswordPage;
