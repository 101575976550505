import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Box, Divider, Grid, Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import BorderColorIcon from '@material-ui/icons/BorderColor';

import { useFormikContext } from 'formik';
import { isNull as _isNull, size as _size } from 'lodash';

import NoteModal from '../../../components/note/crud/NoteModal';
import {  WizardStyles } from '../../../assets/css';
import { NoteService } from '../../../services';
import { useUI } from '../../../app/context/ui';
import AppHelper from '../../../helpers/AppHelper';
import { DataGridStyle } from '../../../assets/css/data-grid-style';
import { AttachFileOutlined } from '@material-ui/icons';
import moment from "moment";

let columns = [
  {
    field: 'serviceDate',
    headerName: 'Date',
    width: 100,
    sortable: false,
    renderHeader: () => {
      return (<div style={{paddingLeft: '15px'}}>Date</div>);
    },
    renderCell: (params) => {
      return (<div style={{paddingLeft: '15px', fontSize: '12px'}}>{params.row.serviceDate}</div>);
    }
  },
  {
    field: 'serviceHour',
    headerName: 'Time',
    width: 80,
    sortable: false,
    renderHeader: () => {
      return (<div style={{paddingLeft: '8px'}}>Time</div>);
    },
    renderCell: (params) => {
      return (<div style={{paddingLeft: '8px', fontSize: '12px'}}>{params.value}</div>);
    }
  },
  {
    field: 'description',
    headerName: 'Regarding',
    flex: 1,
    minWidth: 450,
    sortable: false,
    renderHeader: () => {
      return (<div style={{paddingLeft: '181px'}}>Regarding</div>);
    },
    renderCell: (params) => {
      return (<span style={{padding: '25px 0px', fontSize: '12px', textAlign: 'justify'}}>{_isNull(params.value) ? '' : params.value}</span>)
    }
  },
  {
    field: 'attachments',
    headerName: 'Attachments',
    flex: 0.2,
    minWidth: 65,
    sortable: false,
    renderHeader: () => {
      return (<IconButton style={{paddingLeft: '6px'}}><AttachFileOutlined/></IconButton>);
    },
  },
  {
    field: 'users',
    headerName: 'Record Manager',
    flex: 1,
    minWidth: 100,
    sortable: false,
    renderHeader: () => {
      return (<div style={{paddingLeft: '20px'}}>Record Manager</div>);
    },
    renderCell: (params) => {
      return (<span style={{paddingLeft: '28px', fontSize: '12px'}}>{_isNull(params.value) ? '' : params.value.fullName}</span>)
    }
  },
  {
    field: 'action',
    headerName: 'Actions',
    sortable: false,
    width: 100,
    disableClickEventBubbling: true,
    renderHeader: () => {
      return (<IconButton style={{paddingLeft: '18px'}}><EditIcon/></IconButton>);
    },
  },
];

let dlgSettings = {
  confirm: true,
  btn: {
    close: 'Cancel',
    confirm: 'Yes',
  },
  onConfirm: () => {},
};

const Step1 = () => {
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const wizardStyle = WizardStyles();
  const {dialogUI, snackbarUI} = useUI();
  const [loading, setLoading] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [allEdit] = useState(false);
  const {values: formValues} = useFormikContext();

  const [openNote, setOpenNote] = useState(false);
  const [selectedNote, setSelectedNote] = useState({});

  const pageSize = 5;

  const noteService = useMemo(() => new NoteService(), []);
  noteService.getAccessToken();

  let queryForListNotes = 'filter[customer_attributes_id]=CA_ID&filter[type_id]=1&render=paginate&sort=-id' +
    '&include=attachments,users' + '&limit=' + pageSize;
  queryForListNotes = queryForListNotes.replace('CA_ID', formValues.id);
  dlgSettings.onConfirm = () => onDelete();
  columns[3].renderCell = (params) => (
    <>
      {_size(params.row.attachments) > 0 && (
        <IconButton onClick={() => onEdit(params.row)}>
          <AttachFileOutlined/>
        </IconButton>
      )}
    </>
  );
  columns[5].renderCell = (params) => (
    <IconButton onClick={() => onEdit(params.row)}>
      <EditIcon/>
    </IconButton>
  );

  const onEdit = (row) => {
    let thisRow = row;
    thisRow['disabled'] = true;
    thisRow['serviceDate'] = thisRow['serviceDate'].replace(/-/g, '/');
    if (!_isNull(thisRow['serviceHour'])) {
      const time = moment(thisRow['serviceDate'] + ' ' + thisRow['serviceHour'], 'MM/DD/YYYY hh:mm A');
      thisRow = {...thisRow, 'serviceHour': time.format('MM/DD/YYYY hh:mm A')};
    }
    setSelectedNote(thisRow);
    setOpenNote(true);
  };

  const onDelete = async () => {
    try {
      const queryParams = selectedIds.map(value => 'ids[]=' + value).join('&');
      dialogUI.current.close();
      await noteService.deleteByIds(queryParams);
      await getNotes();
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const getNotes = useCallback(async (newPage = 0) => {
      try {
        const search = queryForListNotes;
        const filterPage = (newPage > 0) ? '&page=' + (newPage + 1) : '';
        const find = search + filterPage;
        setLoading(true);
        const r = await noteService.list(find);
        setRows(r.data.data);
        setRowCount(r.data.total);
        setPage(newPage);
        setLoading(false);
      } catch (e) {
        AppHelper.checkError(e, snackbarUI);
      }
    },
    [noteService, snackbarUI, queryForListNotes]
  );

  const onAfterSend = useCallback(
    async (title, message) => {
      setOpenNote(false);
      await getNotes();
      dialogUI.current.open(title, message);
    },
    [getNotes, dialogUI]
  );

  useEffect(() => {
    (async function init() {
      await getNotes();
    })();
  }, [getNotes, formValues.id]);

  return (
    <>
      <Grid container direction='row' justifyContent='center' alignItems='flex-start'>
        <Grid item xs={12}>
          <Grid container direction='row' justifyContent='flex-end' alignItems='center' spacing={2} className={wizardStyle.wrapperBtnsStep1}>
            {allEdit && (
            <Grid item xs={12} sm={3}>
              <Button
                variant='contained'
                color='primary'
                size='small'
                className={wizardStyle.buttonTransform}
                startIcon={<BorderColorIcon />}
                fullWidth
              >
                All notes
              </Button>
            </Grid>
            )}
            <Grid item xs={12} sm={2}>
              <Button
                variant='contained'
                color='primary'
                size='small'
                className={wizardStyle.buttonTransform}
                startIcon={<DeleteIcon />}
                onClick={() => {
                  if (selectedIds.length > 0) {
                    dialogUI.current.open('Notes', 'Are you sure to delete this note(s)?', dlgSettings);
                  }
                }}
                fullWidth
              >
                Delete
              </Button>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button
                variant='contained'
                color='primary'
                size='small'
                className={wizardStyle.buttonTransform}
                startIcon={<AddIcon />}
                onClick={() => {
                  setSelectedNote({});
                  setOpenNote(true);
                }}
                fullWidth
              >
                New Note
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Box height={'310px'}>
            <DataGridStyle
              disableVirtualization
              columns={columns}
              rows={rows}
              pageSize={pageSize}
              page={page}
              disableColumnMenu
              loading={loading}
              checkboxSelection
              onSelectionModelChange={ids => setSelectedIds(ids)}
              paginationMode="server"
              onPageChange={(newPage) => {
                (async function init() {
                  await getNotes(newPage);
                })();
              }}
              rowCount={rowCount}
            />
          </Box>
        </Grid>
      </Grid>
      <NoteModal
        open={openNote}
        setOpen={setOpenNote}
        onAfterSend={onAfterSend}
        entity={selectedNote}
        type={'general'}
      />
    </>
  );
}

export default Step1;
