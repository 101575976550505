import AppService from './AppService';

class MapsService extends AppService {
  constructor() {
    super();
    this.path = this.getPath('maps');
  }

  getAddress(address) {
    return this.http.get(`${this.path}?address=${address}`);
  }
}

export default MapsService;
